import React from "react";
import { useState ,useEffect} from "react";

import happiness from '../../../../Components/Images/adoption.png'
import ProductQualityExperienceCardAdoption from "./ProductQualityExperienceCardAdoption";

const Adoption = (props) => {
  const {ProductData}= props 
  const [ AppRating, setAppRating ] = useState([])
  const [ NPS, setNPS ] = useState([])
  const [ complaints, setComplaints ] = useState([])

  const LoadFilter = () => {

  const filtercomplaints = ProductData.filter(item => item.metric_name === ("new installs"))
  setComplaints(filtercomplaints)

  const filternps = ProductData.filter(item => item.metric_name === ("new registrations"))
  setNPS(filternps)

  const filterUserbounce = ProductData.filter(item => item.metric_name === ("new subs"))
  setAppRating(filterUserbounce)
  }

  console.log(complaints,"-----new installs-----------------------------------------------");
  console.log(NPS,"-----new registrations}}}}}}}}}}}}}}}}-----------------------------------------------");
  console.log(AppRating,"-----new subs-----------------------------------------------");


  useEffect(()=>{
    LoadFilter()
  },[ProductData])
  return (
    <div>
      <div className="Product_card_executive_summary adoption_color ">
      <div className="Product_card_executive_summary_img"><img src={happiness} /></div>
      <p className="Product_card_executive_summary_card_title">Adoption</p>
      <p className="Product_card_executive_summary_card_title_para">User Onboarding</p>
      </div>
      {complaints.length > 0 && NPS.length > 0 && AppRating.length > 0 && <div className="Product_card_executive_summary_card_margin">
        <div>
          <ProductQualityExperienceCardAdoption
            diifrence="24.35"
            mydata={["New Installs (Mn)", ((complaints[0].l30_metric_value)/1000000).toFixed(2), (((complaints[0].l30_metric_value-complaints[0].l60_metric_value)/complaints[0].l60_metric_value)*100).toFixed(2) , "up"]}
            toggleData={[((complaints[1].l30_metric_value)/1000000).toFixed(2),((complaints[2].l30_metric_value)/1000000).toFixed(2),0,0,0,0,0]}
            overAllData={complaints}
            IiconText={"This metric tracks the number of new installations of an app in the last 30 days. "}

          />
        </div>

        <div>
          <ProductQualityExperienceCardAdoption
            diifrence="24.35"
            isMelian={true}
            mydata={["New Registrations (Mn)", ((NPS[0].l30_metric_value)/1000000).toFixed(2), (((NPS[0].l30_metric_value-NPS[0].l60_metric_value)/NPS[0].l60_metric_value)*100).toFixed(2) , "down"]}
            toggleData={[((NPS[1].l30_metric_value)/1000000).toFixed(2),((NPS[2].l30_metric_value)),((NPS[4].l30_metric_value)/1000000).toFixed(2),0,0,0,0, ((NPS[3].l30_metric_value))]}
            overAllData={NPS}
            IiconText={"This metric tracks the number of new user registrations in the last 30 days."}

          />
        </div>

        <div>
          <ProductQualityExperienceCardAdoption
            diifrence="24.35"
            mydata={["New Subscriptions", AppRating[0].l30_metric_value, (((AppRating[0].l30_metric_value-AppRating[0].l60_metric_value)/AppRating[0].l60_metric_value)*100).toFixed(2), "up"]}
            toggleData={[AppRating[1].l30_metric_value,AppRating[2].l30_metric_value, AppRating[3].l30_metric_value,0,0,0,0,AppRating[4].l30_metric_value]}
            overAllData={AppRating}
            IiconText={"This metric tracks the number of new subscriptions to an app's paid services in the last 30 days."}

          />
        </div>
      </div>}
    </div>
  );
};

export default Adoption;
