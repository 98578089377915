import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import StackedBarChart from './Components/Charts/StackedBarChart/StackedBarChart';
import ILTTwentySummary from './Pages/ILTTwentySummary/ILTTwentySummary';
import ILTTdataTowers from './Pages/ILTTdataTowers/ILTTdataTowers';
import ILTTloginPage from './Pages/ILTTloginPage/ILTTloginPage';
import Product from './Pages/ProductPage/Product';
// import ExecutiveSummaryContent from './Components/ExecutiveSummaryTab/ExecutiveSummaryContent/ExecutiveSummaryContent';
import ExecutiveSummaryTab from './Components/ExecutiveSummaryTab/ExecutiveSummaryTab';
import AreaChartWithToolTips from './Components/Charts/AreaChartWithToolTips/AreaChartWithToolTips';
import HSbar from './Components/Charts/HSbar/HSbar';
import DivergingSB from './Components/Charts/DivergingSB/DivergingSB';
import Links from './Components/Charts/Links/Links';
import Monochrome from './Components/Charts/Monochrome/Monochrome';
// import HeatMap from './Components/Charts/HeatMap/HeatMap';
import ContentSchedulingWithHeatMapAndDropdowns from './Components/ContentTab/ContentSchedulingWithHeatMapAndDropdowns/ContentSchedulingWithHeatMapAndDropdowns';
import Content from './Components/ContentTab/Content';
import CatalogueEnrichmentStackBarChart from './Components/AllDaysStackedBarChartPopUpModal/StackedBarChartForAllDays/CatalogueEnrichmentStackBarChart';
import ScatterplotConnected from './Components/Charts/ScatterplotConnected/ScatterplotConnected';
import StackAreaChart from './Components/Charts/StackAreaChart/FunnelChart';
import KeyContributionPieChart from './Components/Charts/KeyContributionPieChart/KeyContributionPieChart';
import OverlapedBarChart from './Components/Charts/BarChart/OverlapedBarChart';
import HorizontalBarChartExSumCustomer from './Components/Charts/HorizontalProgressBarChartForTable/HorizontalBarChartExSumCustomer';
import ContentTabStackBarChart from './Components/Charts/BarChart/ContentTabStackBarChart';
import PlotChart from './Components/Charts/PlotChart/PlotChart';
import AdvertiserAndBrandsRankingHeatmapPopupModal from './Components/ExecutiveSummaryTab/Customer/AdvertiserAndBrandsRankingHeatmapPopupModal';
import MultiGroupLineChart from './Components/Charts/MultiGroupLineChart/MultiGroupLineChart';
import { HeatMapWithDropDown } from './Components/Charts/SLHeatMap/HeatMap';
import ExecutiveSummContLinSlotLeadership from './Components/ExecutiveSummaryTab/ExecutiveSummaryContent/ExecutiveSummContLinSlotLeadership';
import OverAllBarChart from './Components/ExecutiveSummaryTab/ExecutiveSummaryProduct/ExecutiveSummaryProductNewCard/OverAllBarChart'
import ContentMarketingOttLineChart from './Components/ContentTab/ContentMarketing/ContentMarketingOTT/ContentMarketingOttLineChart';

function App() {
  // background-image: url('./Components/Images/new-background.jpg');
  return (

    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<ILTTloginPage />} />
          <Route path='/home' element={<ILTTdataTowers />} />
          <Route path='/ilt20-summary' element={<ILTTwentySummary />} />
          <Route path='/product' element={<Product />} />
          <Route path='/executive-summary' element={<ExecutiveSummaryTab />} />
          <Route path='content' element={<Content />} />
          <Route path='/area-chart-with-tooltips' element={<AreaChartWithToolTips />} />
          <Route path='/hs-bar' element={<HSbar />} />
          <Route path='/new' element={<DivergingSB />} />
          <Route path='/links' element={<Links />} />
          <Route path='/CatalogueEnrichmentStackBarChart' element={<CatalogueEnrichmentStackBarChart />} />
          <Route path='/ScatterplotConnected' element={<ScatterplotConnected color={'red'} />} />
          <Route path='/mono' element={<Monochrome />} />
          <Route path='/heatmap' element={<ContentSchedulingWithHeatMapAndDropdowns />} />
          <Route path='/OverlapedBarChart' element={<OverlapedBarChart />} />
          <Route path='/pie' element={<KeyContributionPieChart propsWidth={150} population={[{ name: "5-9", value: 50 }, { name: "<5", value: 20 }, { name: "<5", value: 10 }, { name: "<5", value: 30 },]} />} />
          <Route path='/StackAreaChart' element={<StackAreaChart />} />
          <Route path='/HorizontalBarChartExSumCustomer' element={<HorizontalBarChartExSumCustomer />} />
          <Route path='/ContentTabStackBarChart' element={<ContentTabStackBarChart />} />
          {/* <Route path='/heatmap-for-competetive-share-analysis' element={<AdvertiserAndBrandsRankingHeatmapPopupModal />} /> */}
          <Route path='/multi-group-line-chart' element={<MultiGroupLineChart />} />

          <Route path='/plot' element={<PlotChart />} />
          <Route path='/heat' element={<ExecutiveSummContLinSlotLeadership />} />
          <Route path='/OverAllBarChart' element={<OverlapedBarChart data={73.20} width={200} height={15}/>} />
          <Route path='/Content-Marketing' element={<ContentMarketingOttLineChart color={"#402177"}
          yLabel={"Watch time till date (Mn)"} />} />
          {/* <Route path='/cont-lin-bar' element = { <ContentTabStackBarChart dataSetNeteorkShare={dataSetNeteorkShareDisney15} />} /> */}

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;