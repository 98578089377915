import React, { useState } from "react";
import ProductHeader from "../../Components/Product/ProductHeader/ProductHeader";
import ProductAcquisitions from "../../Components/Product/ProductAcquisitions/ProductAcquisitions";
import "./Product.css";
import ProductDiscovery from "../../Components/Product/ProductDiscovery/ProductDiscovery";
import ProductSubscriptions from "../../Components/Product/ProductSubscriptions/ProductSubscriptions";
import ProductEngagement from "../../Components/Product/ProductEngagement/ProductEngagement";
import ProductAdvertisement from "../../Components/Product/ProductAdvertisement/ProductAdvertisement";


const Product = () => {
  const [activeTab, setActiveTab] = useState(null);

  const [classed, setClassed] = useState("my-minuse-button");
  const handlePluseButtonClick = () => {
    setClassed("my-pluse-button");
  };
  const handleMinuseButtonClick = () => {
    setClassed("my-minuse-button");
  };
  return (
    <div>
      <div>
        <ProductHeader header="Product" />
      </div>
      <div className="contents">
        <div className="product-engagement">
          <div>
            <div className="product-tabs">
              <button className={activeTab === 'acquisitions' ? ('product-active-tab') : ("product-tab")} onClick={() => setActiveTab('acquisitions')}>Acquisitions</button>
              <button className={activeTab === 'discovery' ? ('product-active-tab') : ("product-tab")} onClick={() => setActiveTab('discovery')}>Discovery</button>
              <button className={activeTab === 'advertisement' ? ('product-active-tab') : ("product-tab")} onClick={() => setActiveTab('advertisement')}>Advertisement</button>
              <button className={activeTab === 'subscriptions' ? ('product-active-tab') : ("product-tab")} onClick={() => setActiveTab('subscriptions')}>Subscriptions</button>
              <button className={activeTab === 'engagement' ? ('product-active-tab') : ("product-tab")} onClick={() => setActiveTab('engagement')}>Engagement</button>
              <button className={activeTab === 'loyalty' ? ('product-active-tab') : ("product-tab")} onClick={() => setActiveTab('loyalty')}>Loyalty</button>
            </div>
            {activeTab === 'acquisitions' && <ProductAcquisitions />}
            {activeTab === 'discovery' && <ProductDiscovery />}
            {activeTab === 'advertisement' && <ProductAdvertisement />}
              {activeTab === 'subscriptions' &&  <ProductSubscriptions />}
              {activeTab === 'engagement' &&  <ProductEngagement />}
            {/* {activeTab === 'advertisement' && <ExecutiveSummaryProduct />}
              {activeTab === 'subscriptions' && <ExecutiveSummaryProduct />}
              {activeTab === 'engagement' && <ExecutiveSummaryProduct />}
              {activeTab === 'loyalty' && <ExecutiveSummaryProduct />} */}
          </div>

          {/* <div className="product-engagement-indicators-and-experiance d-flex flex-row">
            <div className="">
              <p className="">Key Performance Indicators</p>
              <div className="d-flex flex-row">
                <div className="product-engagement-card">
                  <p>Watch Time</p>
                  <div className={classed}>
                    <h2>322.6 Minutes</h2>
                    <p>6 % from last period</p>
                    <div className="product-icons">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      fill="#37b363"
                      class="bi bi-android2"
                      viewBox="0 0 16 16"
                    >
                      <path d="m10.213 1.471.691-1.26c.046-.083.03-.147-.048-.192-.085-.038-.15-.019-.195.058l-.7 1.27A4.832 4.832 0 0 0 8.005.941c-.688 0-1.34.135-1.956.404l-.7-1.27C5.303 0 5.239-.018 5.154.02c-.078.046-.094.11-.049.193l.691 1.259a4.25 4.25 0 0 0-1.673 1.476A3.697 3.697 0 0 0 3.5 5.02h9c0-.75-.208-1.44-.623-2.072a4.266 4.266 0 0 0-1.664-1.476ZM6.22 3.303a.367.367 0 0 1-.267.11.35.35 0 0 1-.263-.11.366.366 0 0 1-.107-.264.37.37 0 0 1 .107-.265.351.351 0 0 1 .263-.11c.103 0 .193.037.267.11a.36.36 0 0 1 .112.265.36.36 0 0 1-.112.264Zm4.101 0a.351.351 0 0 1-.262.11.366.366 0 0 1-.268-.11.358.358 0 0 1-.112-.264c0-.103.037-.191.112-.265a.367.367 0 0 1 .268-.11c.104 0 .19.037.262.11a.367.367 0 0 1 .107.265c0 .102-.035.19-.107.264ZM3.5 11.77c0 .294.104.544.311.75.208.204.46.307.76.307h.758l.01 2.182c0 .276.097.51.292.703a.961.961 0 0 0 .7.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h1.343v2.182c0 .276.097.51.292.703a.972.972 0 0 0 .71.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h.76c.291 0 .54-.103.749-.308.207-.205.311-.455.311-.75V5.365h-9v6.404Zm10.495-6.587a.983.983 0 0 0-.702.278.91.91 0 0 0-.293.685v4.063c0 .271.098.501.293.69a.97.97 0 0 0 .702.284c.28 0 .517-.095.712-.284a.924.924 0 0 0 .293-.69V6.146a.91.91 0 0 0-.293-.685.995.995 0 0 0-.712-.278Zm-12.702.283a.985.985 0 0 1 .712-.283c.273 0 .507.094.702.283a.913.913 0 0 1 .293.68v4.063a.932.932 0 0 1-.288.69.97.97 0 0 1-.707.284.986.986 0 0 1-.712-.284.924.924 0 0 1-.293-.69V6.146c0-.264.098-.491.293-.68Z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      fill="gray"
                      class="bi bi-apple "
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z" />
                      <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      fill="#328ee9"
                      class="bi bi-calendar-event"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                    </svg>
                    </div>
                  </div>

                  <div className="my-pluse-minus-buttons">
                    {classed !== "my-minuse-button" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-circle  my-button"
                        viewBox="0 0 16 16"
                        onClick={handleMinuseButtonClick}
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                      </svg>
                    )}
                    {classed === "my-minuse-button" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-dash-circle  my-button"
                        viewBox="0 0 16 16"
                        onClick={handlePluseButtonClick}
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    )}
                  </div>
                </div>
                <div className="product-engagement-card">
                  <p>Watch Time per Unique Viewer</p>
                  <h2>42.8 Minutes</h2>
                  <p>40% from last period</p>
                </div>
              </div>
            </div>
            <div className="">
              <p>Start-up Experience</p>
              <div className="d-flex flex-row">
                <div className="product-engagement-card">
                  <p>Attempts (Mn)</p>
                  <h2>58.48 Mn</h2>
                  <p>31% from last period</p>
                </div>
                <div className="product-engagement-card">
                  <p>Plays %</p>
                  <h2>76.95%</h2>
                  <p>16% from last period</p>
                </div>
                <div className="product-engagement-card">
                  <p>Plays (Mn)</p>
                  <h2>Plays (Mn)</h2>
                  <p>11% from last period</p>
                </div>
              </div>
            </div>
            <div>
              <p>Playback Experience</p>
              <div className="d-flex flex-row">
                <div className="product-engagement-card">
                  <p>Watch Time</p>
                  <h2>322.6 Minutes</h2>
                  <p>6 % from last period</p>
                </div>
                <div className="product-engagement-card">
                  <p>Watch Time per Unique Viewer</p>
                  <h2>42.8 Minutes</h2>
                  <p>40% from last period</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

    </div>
  );
};

export default Product;
