import { useCallback, useEffect, useState } from 'react';
import React from 'react';

import './ILTTdataTowersHeader.css';
// import logo from 'ZEE_LOGO.jpg'


const ILTTdataTowersHeader = (props) => {

    return (
        <div>
       <div className="login-page-background">
       <video id="background-video"  autoPlay loop muted >
            <source src="/Login-background-video.mp4" type='video/mp4' />
        </video>
        <div className="data-tower-head">
            <div className="logo-datatowers">
                <img src='/static/images/logos/zee-logo-final.svg'alt='Viewers Logo' className='logo-image' />
            </div>
            {/* <div className="logo-datatowers">
       
                <img src='/static/images/logos/background-image-final.png' alt='Viewers Logo' className='datatower-image' />
            </div> */}
           
        </div>

        <div className='loginUserDiv'>
        <div className='loginUser'>
                <img src='/static/images/logos/profile.svg' className="profile" height={40} alt='profile Logo' />
                <p className='username'>Hello Arun</p>
                <a href='/'><img src='/static/images/logos/logout-icon.svg' className="logout" height={40} alt='logoutLogo' /></a>
            </div>

        </div>

        </div>
        </div>
    )
}

export default ILTTdataTowersHeader;