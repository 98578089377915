import axios from "axios";

export default {

    getAdRevenueForKeyImpactMetrics: async function () {
        try {
            const response = await axios.get('/api/executive-summary-customer-ott/key-impact-revenue');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getAdvertisersForKeyImpactMetrics: async function () {
        try {
            const response = await axios.get('/api/executive-summary-customer-ott/key-impact-advertisers');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getAdViewerseForKeyImpactMetrics: async function () {
        try {
            const response = await axios.get('/api/executive-summary-customer-ott/key-impact-ad-viewers');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getImpressionsForKeyImpactMetrics: async function () {
        try {
            const response = await axios.get('/api/executive-summary-customer-ott/key-impact-impressions');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getEASForKeyImpactMetrics: async function () {
        try {
            const response = await axios.get('/api/executive-summary-customer-ott/key-impact-eas');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getDataForTrendForTargetAchievementKPIExectSumCustomerLiner: async function () {
        try {
            const response = await axios.get('/api/executive-summary-customer-ott/target-achievement-trend');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getAdvertiserAndBrandRanking: async function () {
        try {
            const response = await axios.get('/api/executive-summary-customer-ott/advertiser-and-brand-ranking');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getKeyContriAnalysisImpressionsExectSumCustomerOTT: async function () {
        try {
            const response = await axios.get('/api/executive-summary-customer-ott/key-contribution-analysis-impressions');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getKeyContriAnalysisClickExectSumCustomerOTT: async function () {
        try {
            const response = await axios.get('/api/executive-summary-customer-ott/key-contribution-analysis-clicks');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getKeyContriAnalysisRevenueExectSumCustomerOTT: async function () {
        try {
            const response = await axios.get('/api/executive-summary-customer-ott/key-contribution-analysis-revenue');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getCompetetitiveAdShareAnalysisAdvertiserFilters: async function () {
        try {
            const response = await axios.get('/api/executive-summary-customer-ott/competitive-ad-share-analysis-advertisers');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getCompetetitiveAdShareAnalysis: async function (advertiserName) {
        try {
            const response = await axios.get('/api/executive-summary-customer-ott/competitive-ad-share-analysis-data', { params: { advertiserName } });
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getAdInventoryFillRateDisplay: async function () {
        try {
            const response = await axios.get('api/executive-summary-customer-ott/inventory-fill-rate-display');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getAdInventoryFillRateVideo: async function () {
        try {
            const response = await axios.get('api/executive-summary-customer-ott/inventory-fill-rate-video');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },


}