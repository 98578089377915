// let dataForOverAll = {
//     dau: {
//         lastThirtyDays: {
//             kpi: 'dau',
//             currentPeriodValue: 6281266.722222222,
//             previousPeriodValue: 7613801.714285714
//         },
//         lastNintyDays: {
//             kpi: 'dau',
//             currentPeriodValue: 6943019.7733333325,
//             previousPeriodValue: 7327007.477777778
//         }
//     },
//     mau: {
//         lastThirtyDays: {
//             kpi: 'mau',
//             currentPeriodValue: 38086404,
//             previousPeriodValue: 74163273
//         },
//         lastNintyDays: {
//             kpi: 'mau',
//             currentPeriodValue: 116959434,
//             previousPeriodValue: 143179559
//         }
//     },
//     paidTraffic: {
//         lastThirtyDays: {
//             kpi: 'paidTraffic',
//             currentPeriodValue: 18485579,
//             previousPeriodValue: 47550147
//         },
//         lastNintyDays: {
//             kpi: 'paidTraffic',
//             currentPeriodValue: 69426259,
//             previousPeriodValue: 84229900
//         }
//     },
//     organicTraffic: {
//         lastThirtyDays: {
//             kpi: 'organicTraffic',
//             currentPeriodValue: 19600825,
//             previousPeriodValue: 26613126
//         },
//         lastNintyDays: {
//             kpi: 'organicTraffic',
//             currentPeriodValue: 47533175,
//             previousPeriodValue: 58949659
//         }
//     },
//     newUsers: {
//         lastThirtyDays: {
//             kpi: 'newUsers',
//             currentPeriodValue: 15935450,
//             previousPeriodValue: 47242510
//         },
//         lastNintyDays: {
//             kpi: 'newUsers',
//             currentPeriodValue: 81960734,
//             previousPeriodValue: 108770434
//         }
//     },
//     returningUsers: {
//         lastThirtyDays: {
//             kpi: 'returningUsers',
//             currentPeriodValue: 22150954,
//             previousPeriodValue: 26920763
//         },
//         lastNintyDays: {
//             kpi: 'returningUsers',
//             currentPeriodValue: 34998700,
//             previousPeriodValue: 34409125
//         }
//     },
//     guestUsers: {
//         lastThirtyDays: {
//             kpi: 'anonymous',
//             currentPeriodValue: 25848239,
//             previousPeriodValue: 58890191
//         },
//         lastNintyDays: {
//             kpi: 'anonymous',
//             currentPeriodValue: 93829772,
//             previousPeriodValue: 120739682
//         }
//     },
//     signUps: {
//         lastThirtyDays: {
//             kpi: 'signUps',
//             currentPeriodValue: 12558795,
//             previousPeriodValue: 15767799
//         },
//         lastNintyDays: {
//             kpi: 'signUps',
//             currentPeriodValue: 24558667,
//             previousPeriodValue: 24841980
//         }
//     },
//     search: {
//         lastThirtyDays: {
//             kpi: 'search',
//             currentPeriodValue: 7384622,
//             previousPeriodValue: 10343179
//         },
//         lastNintyDays: {
//             kpi: 'search',
//             currentPeriodValue: 21282756,
//             previousPeriodValue: 26345733
//         }
//     },
//     explore: {
//         lastThirtyDays: {
//             kpi: 'explore',
//             currentPeriodValue: 26171398,
//             previousPeriodValue: 56395758
//         },
//         lastNintyDays: {
//             kpi: 'explore',
//             currentPeriodValue: 84011770,
//             previousPeriodValue: 96430811
//         }
//     },
//     guestExit: {
//         lastThirtyDays: {
//             kpi: 'anonymousExit',
//             currentPeriodValue: 15502935.9600368,
//             previousPeriodValue: 21519627.663888387
//         },
//         lastNintyDays: {
//             kpi: 'anonymousExit',
//             currentPeriodValue: 33507354.972012535,
//             previousPeriodValue: 41353541.382348344
//         }
//     },
//     signUpExit: {
//         lastThirtyDays: {
//             kpi: 'signUpExit',
//             currentPeriodValue: 27113852.0399632,
//             previousPeriodValue: 60067981.336111605
//         },
//         lastNintyDays: {
//             kpi: 'signUpExit',
//             currentPeriodValue: 95116987.02798745,
//             previousPeriodValue: 122229032.61765166
//         }
//     },
//     subscribers: {
//         lastThirtyDays: {
//             kpi: 'subscribers',
//             currentPeriodValue: 4525753,
//             previousPeriodValue: 5408088
//         },
//         lastNintyDays: {
//             kpi: 'subscribers',
//             currentPeriodValue: 7874733,
//             previousPeriodValue: 8268890
//         }
//     },
//     adViewers: {
//         lastThirtyDays: {
//             kpi: 'watchedAds',
//             currentPeriodValue: 20573128,
//             previousPeriodValue: 39724158
//         },
//         lastNintyDays: {
//             kpi: 'watchedAds',
//             currentPeriodValue: 64987578,
//             previousPeriodValue: 44670619
//         }
//     },
//     exploreExit: {
//         lastThirtyDays: {
//             kpi: 'exploreExit',
//             currentPeriodValue: 18510995.35482581,
//             previousPeriodValue: 36025607.97527137
//         },
//         lastNintyDays: {
//             kpi: 'exploreExit',
//             currentPeriodValue: 58824486.76256745,
//             previousPeriodValue: 101599957.38805205
//         }
//     },
//     searchExit: {
//         lastThirtyDays: {
//             kpi: 'searchExit',
//             currentPeriodValue: 32562931.64517419,
//             previousPeriodValue: 67168692.02472864
//         },
//         lastNintyDays: {
//             kpi: 'searchExit',
//             currentPeriodValue: 102232070.23743255,
//             previousPeriodValue: 131819651.61194795
//         }
//     },
//     watchedFreeContent: {
//         lastThirtyDays: {
//             kpi: 'watchedFreeContent',
//             currentPeriodValue: 33845031,
//             previousPeriodValue: 49874045
//         },
//         lastNintyDays: {
//             kpi: 'watchedFreeContent',
//             currentPeriodValue: 80118026,
//             previousPeriodValue: 93982164
//         }
//     },
//     watchedPremiumContent: {
//         lastThirtyDays: {
//             kpi: 'watchedPremiumContent',
//             currentPeriodValue: 35070391,
//             previousPeriodValue: 51019487
//         },
//         lastNintyDays: {
//             kpi: 'watchedPremiumContent',
//             currentPeriodValue: 81853383,
//             previousPeriodValue: 97253914
//         }
//     },
//     watchedAdsExit: {
//         lastThirtyDays: {
//             kpi: 'watchedAdsExit',
//             currentPeriodValue: 13813356,
//             previousPeriodValue: 20817251
//         },
//         lastNintyDays: {
//             kpi: 'watchedAdsExit',
//             currentPeriodValue: 39082384,
//             previousPeriodValue: 54806040
//         }
//     },
//     dav: {
//         lastThirtyDays: {
//             kpi: 'dav',
//             currentPeriodValue: 4302863.555555555,
//             previousPeriodValue: 5344842.142857144
//         },
//         lastNintyDays: {
//             kpi: 'dav',
//             currentPeriodValue: 4696522.373333332,
//             previousPeriodValue: 4602231.12222222
//         }
//     },
//     mav: {
//         lastThirtyDays: {
//             kpi: 'mav',
//             currentPeriodValue: 24273048,
//             previousPeriodValue: 53346022
//         },
//         lastNintyDays: {
//             kpi: 'mav',
//             currentPeriodValue: 77877050,
//             previousPeriodValue: 88373519
//         }
//     },
//     vtur: {
//         lastThirtyDays: {
//             kpi: 'vtur',
//             currentPeriodValue: 0.6850311801491632,
//             previousPeriodValue: 0.70199387158043
//         },
//         lastNintyDays: {
//             kpi: 'vtur',
//             currentPeriodValue: 0.6764379948004295,
//             previousPeriodValue: 0.6281187969550208
//         }
//     },
//     qualityViewers: {
//         lastThirtyDays: {
//             kpi: 'qualityViewers',
//             currentPeriodValue: 25102816,
//             previousPeriodValue: 30593012
//         },
//         lastNintyDays: {
//             kpi: 'qualityViewers',
//             currentPeriodValue: 51103816,
//             previousPeriodValue: 63994599
//         }
//     },
//     watchTime: {
//         lastThirtyDays: {
//             kpi: 'watchTime',
//             currentPeriodValue: 38407264,
//             previousPeriodValue: 74658447
//         },
//         lastNintyDays: {
//             kpi: 'watchTime',
//             currentPeriodValue: 118389704,
//             previousPeriodValue: 145610731
//         }
//     },
//     adImpressions: {
//         lastThirtyDays: {
//             kpi: 'adImpressions',
//             currentPeriodValue: 1179609752,
//             previousPeriodValue: 1620998476
//         },
//         lastNintyDays: {
//             kpi: 'adImpressions',
//             currentPeriodValue: 4537629648,
//             previousPeriodValue: 1621922990
//         }
//     },
//     perNewSubscriptions: {
//         lastThirtyDays: {
//             kpi: 'perNewSubscriptions',
//             currentPeriodValue: 1234071,
//             previousPeriodValue: 1260210
//         },
//         lastNintyDays: {
//             kpi: 'perNewSubscriptions',
//             currentPeriodValue: 3667585,
//             previousPeriodValue: 4427776
//         }
//     },
//     perRepeatSubscriptions: {
//         lastThirtyDays: {
//             kpi: 'perRepeatSubscriptions',
//             currentPeriodValue: 5625838,
//             previousPeriodValue: 7916246
//         },
//         lastNintyDays: {
//             kpi: 'perRepeatSubscriptions',
//             currentPeriodValue: 18840558,
//             previousPeriodValue: 15425694
//         }
//     },
//     ecsViewers: {
//         lastThirtyDays: {
//             kpi: 'ecsViewers',
//             currentPeriodValue: 416,
//             previousPeriodValue: 441
//         },
//         lastNintyDays: {
//             kpi: 'ecsViewers',
//             currentPeriodValue: 1250,
//             previousPeriodValue: 1217
//         }
//     }
//     ,
//     ecsWatchTime: {
//         lastThirtyDays: {
//             kpi: 'ecsWatchTime',
//             currentPeriodValue: 8123795538.0495663,
//             previousPeriodValue: 5908772839.6842508,
//         },
//         lastNintyDays: {
//             kpi: 'ecsWatchTime',
//             currentPeriodValue: 20576560609.4253,
//             previousPeriodValue: 18416728476.743954,
//         }
//     }
// }

let dataForOverAll = {
    dau: {
        lastThirtyDays: {
            kpi: 'dau',
            currentPeriodValue: 6281266.722222222,
            previousPeriodValue: 7613801.714285714
        },
        lastNintyDays: {
            kpi: 'dau',
            currentPeriodValue: 6943019.773333333,
            previousPeriodValue: 7327007.477777775
        }
    },
    mau: {
        lastThirtyDays: {
            kpi: 'mau',
            currentPeriodValue: 38086404,
            previousPeriodValue: 74163273
        },
        lastNintyDays: {
            kpi: 'mau',
            currentPeriodValue: 116959434,
            previousPeriodValue: 143179559
        }
    },
    paidTraffic: {
        lastThirtyDays: {
            kpi: 'paidTraffic',
            currentPeriodValue: 18485579,
            previousPeriodValue: 47550147
        },
        lastNintyDays: {
            kpi: 'paidTraffic',
            currentPeriodValue: 69426259,
            previousPeriodValue: 84229900
        }
    },
    organicTraffic: {
        lastThirtyDays: {
            kpi: 'organicTraffic',
            currentPeriodValue: 19600825,
            previousPeriodValue: 26613126
        },
        lastNintyDays: {
            kpi: 'organicTraffic',
            currentPeriodValue: 47533175,
            previousPeriodValue: 58949659
        }
    },
    newUsers: {
        lastThirtyDays: {
            kpi: 'newUsers',
            currentPeriodValue: 15935450,
            previousPeriodValue: 47242510
        },
        lastNintyDays: {
            kpi: 'newUsers',
            currentPeriodValue: 81960734,
            previousPeriodValue: 108770434
        }
    },
    returningUsers: {
        lastThirtyDays: {
            kpi: 'returningUsers',
            currentPeriodValue: 22150954,
            previousPeriodValue: 26920763
        },
        lastNintyDays: {
            kpi: 'returningUsers',
            currentPeriodValue: 34998700,
            previousPeriodValue: 34409125
        }
    },
    guestUsers: {
        lastThirtyDays: {
            kpi: 'anonymous',
            currentPeriodValue: 25848239,
            previousPeriodValue: 58890191
        },
        lastNintyDays: {
            kpi: 'anonymous',
            currentPeriodValue: 93829772,
            previousPeriodValue: 120739682
        }
    },
    signUps: {
        lastThirtyDays: {
            kpi: 'signUps',
            currentPeriodValue: 12558795,
            previousPeriodValue: 15767799
        },
        lastNintyDays: {
            kpi: 'signUps',
            currentPeriodValue: 24558667,
            previousPeriodValue: 24841980
        }
    },
    search: {
        lastThirtyDays: {
            kpi: 'search',
            currentPeriodValue: 7384622,
            previousPeriodValue: 10343179
        },
        lastNintyDays: {
            kpi: 'search',
            currentPeriodValue: 21282756,
            previousPeriodValue: 26345733
        }
    },
    explore: {
        lastThirtyDays: {
            kpi: 'explore',
            currentPeriodValue: 26171398,
            previousPeriodValue: 56395758
        },
        lastNintyDays: {
            kpi: 'explore',
            currentPeriodValue: 84011770,
            previousPeriodValue: 96430811
        }
    },
    guestExit: {
        lastThirtyDays: {
            kpi: 'anonymousExit',
            currentPeriodValue: 3048984.422847544,
            previousPeriodValue: 5856313.103100901
        },
        lastNintyDays: {
            kpi: 'anonymousExit',
            currentPeriodValue: 9245122.81170441,
            previousPeriodValue: 16921455.00400476
        }
    },
    signUpExit: {
        lastThirtyDays: {
            kpi: 'signUpExit',
            currentPeriodValue: 1481399.5771524562,
            previousPeriodValue: 1568022.8968990995
        },
        lastNintyDays: {
            kpi: 'signUpExit',
            currentPeriodValue: 2419785.18829559,
            previousPeriodValue: 3481559.9959952375
        }
    },
    subscribers: {
        lastThirtyDays: {
            kpi: 'subscribers',
            currentPeriodValue: 4525753,
            previousPeriodValue: 5408088
        },
        lastNintyDays: {
            kpi: 'subscribers',
            currentPeriodValue: 7874733,
            previousPeriodValue: 8268890
        }
    },
    adViewers: {
        lastThirtyDays: {
            kpi: 'watchedAds',
            currentPeriodValue: 20573128,
            previousPeriodValue: 39724158
        },
        lastNintyDays: {
            kpi: 'watchedAds',
            currentPeriodValue: 64987578,
            previousPeriodValue: 44670619
        }
    },
    exploreExit: {
        lastThirtyDays: {
            kpi: 'exploreExit',
            currentPeriodValue: 10129378.676826214,
            previousPeriodValue: 24531807.768881094
        },
        lastNintyDays: {
            kpi: 'exploreExit',
            currentPeriodValue: 35183950.16221175,
            previousPeriodValue: 70876088.56444559
        }
    },
    searchExit: {
        lastThirtyDays: {
            kpi: 'searchExit',
            currentPeriodValue: 2858144.323173785,
            previousPeriodValue: 4499219.231118904
        },
        lastNintyDays: {
            kpi: 'searchExit',
            currentPeriodValue: 8913172.837788245,
            previousPeriodValue: 19363961.43555442
        }
    },
    watchedFreeContent: {
        lastThirtyDays: {
            kpi: 'watchedFreeContent',
            currentPeriodValue: 33845031,
            previousPeriodValue: 49874045
        },
        lastNintyDays: {
            kpi: 'watchedFreeContent',
            currentPeriodValue: 80118026,
            previousPeriodValue: 93982164
        }
    },
    watchedPremiumContent: {
        lastThirtyDays: {
            kpi: 'watchedPremiumContent',
            currentPeriodValue: 35070391,
            previousPeriodValue: 51019487
        },
        lastNintyDays: {
            kpi: 'watchedPremiumContent',
            currentPeriodValue: 81853383,
            previousPeriodValue: 97253914
        }
    },
    watchedAdsExit: {
        lastThirtyDays: {
            kpi: 'watchedAdsExit',
            currentPeriodValue: 13813356,
            previousPeriodValue: 20817251
        },
        lastNintyDays: {
            kpi: 'watchedAdsExit',
            currentPeriodValue: 39082384,
            previousPeriodValue: 54806040
        }
    },
    dav: {
        lastThirtyDays: {
            kpi: 'dav',
            currentPeriodValue: 4302863.555555556,
            previousPeriodValue: 5344842.142857144
        },
        lastNintyDays: {
            kpi: 'dav',
            currentPeriodValue: 4696522.373333334,
            previousPeriodValue: 4602231.122222223
        }
    },
    mav: {
        lastThirtyDays: {
            kpi: 'mav',
            currentPeriodValue: 24273048,
            previousPeriodValue: 53346022
        },
        lastNintyDays: {
            kpi: 'mav',
            currentPeriodValue: 77877050,
            previousPeriodValue: 88373519
        }
    },
    vtur: {
        lastThirtyDays: {
            kpi: 'vtur',
            currentPeriodValue: 0.6850311801491634,
            previousPeriodValue: 0.70199387158043
        },
        lastNintyDays: {
            kpi: 'vtur',
            currentPeriodValue: 0.6764379948004297,
            previousPeriodValue: 0.6281187969550216
        }
    },
    qualityViewers: {
        lastThirtyDays: {
            kpi: 'qualityViewers',
            currentPeriodValue: 25102816,
            previousPeriodValue: 30593012
        },
        lastNintyDays: {
            kpi: 'qualityViewers',
            currentPeriodValue: 51103816,
            previousPeriodValue: 63994599
        }
    },
    watchTime: {
        lastThirtyDays: {
            kpi: 'watchTime',
            currentPeriodValue: 38407264,
            previousPeriodValue: 74658447
        },
        lastNintyDays: {
            kpi: 'watchTime',
            currentPeriodValue: 118389704,
            previousPeriodValue: 145610731
        }
    },
    adImpressions: {
        lastThirtyDays: {
            kpi: 'adImpressions',
            currentPeriodValue: 1179609752,
            previousPeriodValue: 1620998476
        },
        lastNintyDays: {
            kpi: 'adImpressions',
            currentPeriodValue: 4537629648,
            previousPeriodValue: 1621922990
        }
    },
    perNewSubscriptions: {
        lastThirtyDays: {
            kpi: 'perNewSubscriptions',
            currentPeriodValue: 1234071,
            previousPeriodValue: 1260210
        },
        lastNintyDays: {
            kpi: 'perNewSubscriptions',
            currentPeriodValue: 3667585,
            previousPeriodValue: 4427776
        }
    },
    perRepeatSubscriptions: {
        lastThirtyDays: {
            kpi: 'perRepeatSubscriptions',
            currentPeriodValue: 5625838,
            previousPeriodValue: 7916246
        },
        lastNintyDays: {
            kpi: 'perRepeatSubscriptions',
            currentPeriodValue: 18840558,
            previousPeriodValue: 15425694
        }
    },
    ecsViewers: {
        lastThirtyDays: {
            kpi: 'ecsViewers',
            currentPeriodValue: 416,
            previousPeriodValue: 441
        },
        lastNintyDays: {
            kpi: 'ecsViewers',
            currentPeriodValue: 1250,
            previousPeriodValue: 1217
        }
    }
    ,
    ecsWatchTime: {
        lastThirtyDays: {
            kpi: 'ecsWatchTime',
            currentPeriodValue: 8123795538.0495663,
            previousPeriodValue: 5908772839.6842508,
        },
        lastNintyDays: {
            kpi: 'ecsWatchTime',
            currentPeriodValue: 20576560609.4253,
            previousPeriodValue: 18416728476.743954,
        }
    },
    perViewersRetainedMoneTwo: {
        lastThirtyDays: {
            kpi: 'perViewersRetainedMoneTwo',
            currentPeriodValue: 0.084,
            previousPeriodValue: 5908772839.6842508,
        },
        lastNintyDays: {
            kpi: 'perViewersRetainedMoneTwo',
            currentPeriodValue: 20576560609.4253,
            previousPeriodValue: 18416728476.743954,
        }
    }
}

let dataForAVOD = {
    dau: {
        lastThirtyDays: {
            kpi: 'dau',
            currentPeriodValue: 5039920.05882353,
            previousPeriodValue: 6400295.000000001
        },
        lastNintyDays: {
            kpi: 'dau',
            currentPeriodValue: 5738575.9459459465,
            previousPeriodValue: 6090277.000000002
        }
    },
    mau: {
        lastThirtyDays: {
            kpi: 'mau',
            currentPeriodValue: 31792598,
            previousPeriodValue: 71735568
        },
        lastNintyDays: {
            kpi: 'mau',
            currentPeriodValue: 111635558,
            previousPeriodValue: 138950245
        }
    },
    paidTraffic: {
        lastThirtyDays: {
            kpi: 'paidTraffic',
            currentPeriodValue: 14897465,
            previousPeriodValue: 47507807
        },
        lastNintyDays: {
            kpi: 'paidTraffic',
            currentPeriodValue: 66921089,
            previousPeriodValue: 82199601
        }
    },
    organicTraffic: {
        lastThirtyDays: {
            kpi: 'organicTraffic',
            currentPeriodValue: 16895133,
            previousPeriodValue: 24227761
        },
        lastNintyDays: {
            kpi: 'organicTraffic',
            currentPeriodValue: 44714469,
            previousPeriodValue: 56750644
        }
    },
    newUsers: {
        lastThirtyDays: {
            kpi: 'newUsers',
            currentPeriodValue: 13590633,
            previousPeriodValue: 48572355
        },
        lastNintyDays: {
            kpi: 'newUsers',
            currentPeriodValue: 80719047,
            previousPeriodValue: 108507855
        }
    },
    returningUsers: {
        lastThirtyDays: {
            kpi: 'returningUsers',
            currentPeriodValue: 18201965,
            previousPeriodValue: 23163213
        },
        lastNintyDays: {
            kpi: 'returningUsers',
            currentPeriodValue: 30916511,
            previousPeriodValue: 30442390
        }
    },
    guestUsers: {
        lastThirtyDays: {
            kpi: 'anonymous',
            currentPeriodValue: 23452216,
            previousPeriodValue: 60448727
        },
        lastNintyDays: {
            kpi: 'anonymous',
            currentPeriodValue: 93222629,
            previousPeriodValue: 120790028
        }
    },
    signUps: {
        lastThirtyDays: {
            kpi: 'signUps',
            currentPeriodValue: 8436777,
            previousPeriodValue: 11511697
        },
        lastNintyDays: {
            kpi: 'signUps',
            currentPeriodValue: 19293745,
            previousPeriodValue: 20058807
        }
    },
    search: {
        lastThirtyDays: {
            kpi: 'search',
            currentPeriodValue: 5652358,
            previousPeriodValue: 8433096
        },
        lastNintyDays: {
            kpi: 'search',
            currentPeriodValue: 18213052,
            previousPeriodValue: 23284934
        }
    },
    explore: {
        lastThirtyDays: {
            kpi: 'explore',
            currentPeriodValue: 20698256,
            previousPeriodValue: 54229428
        },
        lastNintyDays: {
            kpi: 'explore',
            currentPeriodValue: 78928052,
            previousPeriodValue: 91932931
        }
    },
    guestExit: {
        lastThirtyDays: {
            kpi: 'anonymousExit',
            currentPeriodValue: 4002214.313777296,
            previousPeriodValue: 7621605.451004402
        },
        lastNintyDays: {
            kpi: 'anonymousExit',
            currentPeriodValue: 12009017.530191347,
            previousPeriodValue: 20352563.4039262
        }
    },
    signUpExit: {
        lastThirtyDays: {
            kpi: 'signUpExit',
            currentPeriodValue: 1439769.686222704,
            previousPeriodValue: 1451438.548995598
        },
        lastNintyDays: {
            kpi: 'signUpExit',
            currentPeriodValue: 2485436.469808652,
            previousPeriodValue: 3379816.596073798
        }
    },
    subscribers: {
        lastThirtyDays: {
            kpi: 'subscribers',
            currentPeriodValue: 0,
            previousPeriodValue: 0
        },
        lastNintyDays: {
            kpi: 'subscribers',
            currentPeriodValue: 0,
            previousPeriodValue: 0
        }
    },
    adViewers: {
        lastThirtyDays: {
            kpi: 'watchedAds',
            currentPeriodValue: 20102920,
            previousPeriodValue: 39778948
        },
        lastNintyDays: {
            kpi: 'watchedAds',
            currentPeriodValue: 64852774,
            previousPeriodValue: 44829992
        }
    },
    exploreExit: {
        lastThirtyDays: {
            kpi: 'exploreExit',
            currentPeriodValue: 9182174.950517965,
            previousPeriodValue: 27655911.584623687
        },
        lastNintyDays: {
            kpi: 'exploreExit',
            currentPeriodValue: 38011447.84453724,
            previousPeriodValue: 75099037.1567078
        }
    },
    searchExit: {
        lastThirtyDays: {
            kpi: 'searchExit',
            currentPeriodValue: 2507503.049482035,
            previousPeriodValue: 4300708.41537631
        },
        lastNintyDays: {
            kpi: 'searchExit',
            currentPeriodValue: 8771336.155462759,
            previousPeriodValue: 19021215.843292203
        }
    },
    watchedFreeContent: {
        lastThirtyDays: {
            kpi: 'watchedFreeContent',
            currentPeriodValue: 26243115,
            previousPeriodValue: 44123618
        },
        lastNintyDays: {
            kpi: 'watchedFreeContent',
            currentPeriodValue: 70831152,
            previousPeriodValue: 84850878
        }
    },
    watchedPremiumContent: {
        lastThirtyDays: {
            kpi: 'watchedPremiumContent',
            currentPeriodValue: 1659722,
            previousPeriodValue: 1677939
        },
        lastNintyDays: {
            kpi: 'watchedPremiumContent',
            currentPeriodValue: 3710211,
            previousPeriodValue: 5954716
        }
    },
    watchedAdsExit: {
        lastThirtyDays: {
            kpi: 'watchedAdsExit',
            currentPeriodValue: 12827716,
            previousPeriodValue: 20455279
        },
        lastNintyDays: {
            kpi: 'watchedAdsExit',
            currentPeriodValue: 38782908,
            previousPeriodValue: 55074900
        }
    },
    dav: {
        lastThirtyDays: {
            kpi: 'dav',
            currentPeriodValue: 3351018.8823529407,
            previousPeriodValue: 4468089.571428572
        },
        lastNintyDays: {
            kpi: 'dav',
            currentPeriodValue: 3803333.648648649,
            previousPeriodValue: 3715773.777777777
        }
    },
    mav: {
        lastThirtyDays: {
            kpi: 'mav',
            currentPeriodValue: 18964882,
            previousPeriodValue: 51280289
        },
        lastNintyDays: {
            kpi: 'mav',
            currentPeriodValue: 72852650,
            previousPeriodValue: 83875345
        }
    },
    vtur: {
        lastThirtyDays: {
            kpi: 'vtur',
            currentPeriodValue: 0.6648952450121144,
            previousPeriodValue: 0.6981068171746101
        },
        lastNintyDays: {
            kpi: 'vtur',
            currentPeriodValue: 0.6627661086084499,
            previousPeriodValue: 0.6101157267194539
        }
    },
    qualityViewers: {
        lastThirtyDays: {
            kpi: 'qualityViewers',
            currentPeriodValue: 24691532,
            previousPeriodValue: 30940663
        },
        lastNintyDays: {
            kpi: 'qualityViewers',
            currentPeriodValue: 51007661,
            previousPeriodValue: 64042616
        }
    },
    watchTime: {
        lastThirtyDays: {
            kpi: 'watchTime',
            currentPeriodValue: 31888993,
            previousPeriodValue: 71960424
        },
        lastNintyDays: {
            kpi: 'watchTime',
            currentPeriodValue: 112516374,
            previousPeriodValue: 140848835
        }
    },
    adImpressions: {
        lastThirtyDays: {
            kpi: 'adImpressions',
            currentPeriodValue: 1189097110,
            previousPeriodValue: 1589846797
        },
        lastNintyDays: {
            kpi: 'adImpressions',
            currentPeriodValue: 4526722000,
            previousPeriodValue: 1678002739
        }
    },
    perNewSubscriptions: {
        lastThirtyDays: {
            kpi: 'perNewSubscriptions',
            currentPeriodValue: 1217563,
            previousPeriodValue: 1263291
        },
        lastNintyDays: {
            kpi: 'perNewSubscriptions',
            currentPeriodValue: 3659186,
            previousPeriodValue: 4422048
        }
    },
    perRepeatSubscriptions: {
        lastThirtyDays: {
            kpi: 'perRepeatSubscriptions',
            currentPeriodValue: 5615035,
            previousPeriodValue: 7902636
        },
        lastNintyDays: {
            kpi: 'perRepeatSubscriptions',
            currentPeriodValue: 18839534,
            previousPeriodValue: 15452039
        }
    },
    ecsViewers: {
        lastThirtyDays: {
            kpi: 'ecsViewers',
            currentPeriodValue: 218,
            previousPeriodValue: 272
        },
        lastNintyDays: {
            kpi: 'ecsViewers',
            currentPeriodValue: 783,
            previousPeriodValue: 842
        }
    },
    ecsWatchTime: {
        lastThirtyDays: {
            kpi: 'ecsWatchTime',
            currentPeriodValue: 3195609938.436933,
            previousPeriodValue: 2874164981.518733,
        },
        lastNintyDays: {
            kpi: 'ecsWatchTime',
            currentPeriodValue: 9112155257.3846836,
            previousPeriodValue: 8139180650.53885,
        }
    },
}

let dataForSVOD = {
    dau: {
        lastThirtyDays: {
            kpi: 'dau',
            currentPeriodValue: 1179199.705882353,
            previousPeriodValue: 1263247.6785714286
        },
        lastNintyDays: {
            kpi: 'dau',
            currentPeriodValue: 1234720.0270270274,
            previousPeriodValue: 1268893.3000000003
        }
    },
    mau: {
        lastThirtyDays: {
            kpi: 'mau',
            currentPeriodValue: 4436579,
            previousPeriodValue: 5414655
        },
        lastNintyDays: {
            kpi: 'mau',
            currentPeriodValue: 7830595,
            previousPeriodValue: 8282112
        }
    },
    paidTraffic: {
        lastThirtyDays: {
            kpi: 'paidTraffic',
            currentPeriodValue: 1652093,
            previousPeriodValue: 2086238
        },
        lastNintyDays: {
            kpi: 'paidTraffic',
            currentPeriodValue: 3090482,
            previousPeriodValue: 3350726
        }
    },
    organicTraffic: {
        lastThirtyDays: {
            kpi: 'organicTraffic',
            currentPeriodValue: 2784486,
            previousPeriodValue: 3328417
        },
        lastNintyDays: {
            kpi: 'organicTraffic',
            currentPeriodValue: 4740113,
            previousPeriodValue: 4931386
        }
    },
    newUsers: {
        lastThirtyDays: {
            kpi: 'newUsers',
            currentPeriodValue: 573258,
            previousPeriodValue: 1012030
        },
        lastNintyDays: {
            kpi: 'newUsers',
            currentPeriodValue: 2567481,
            previousPeriodValue: 3183939
        }
    },
    returningUsers: {
        lastThirtyDays: {
            kpi: 'returningUsers',
            currentPeriodValue: 3863321,
            previousPeriodValue: 4402625
        },
        lastNintyDays: {
            kpi: 'returningUsers',
            currentPeriodValue: 5263114,
            previousPeriodValue: 5098173
        }
    },
    guestUsers: {
        lastThirtyDays: { kpi: 'anonymous', currentPeriodValue: 0, previousPeriodValue: 0 },
        lastNintyDays: { kpi: 'anonymous', currentPeriodValue: 0, previousPeriodValue: 0 }
    },
    signUps: {
        lastThirtyDays: {
            kpi: 'signUps',
            currentPeriodValue: 4436579,
            previousPeriodValue: 5414655
        },
        lastNintyDays: {
            kpi: 'signUps',
            currentPeriodValue: 7830595,
            previousPeriodValue: 8282112
        }
    },
    search: {
        lastThirtyDays: {
            kpi: 'search',
            currentPeriodValue: 1534379,
            previousPeriodValue: 1996654
        },
        lastNintyDays: {
            kpi: 'search',
            currentPeriodValue: 3376471,
            previousPeriodValue: 3702015
        }
    },
    explore: {
        lastThirtyDays: {
            kpi: 'explore',
            currentPeriodValue: 3687326,
            previousPeriodValue: 4534265
        },
        lastNintyDays: {
            kpi: 'explore',
            currentPeriodValue: 6688578,
            previousPeriodValue: 7230479
        }
    },
    guestExit: {
        lastThirtyDays: {
            kpi: 'anonymousExit',
            currentPeriodValue: -0,
            previousPeriodValue: -0
        },
        lastNintyDays: {
            kpi: 'anonymousExit',
            currentPeriodValue: -0,
            previousPeriodValue: -0
        }
    },
    signUpExit: {
        lastThirtyDays: {
            kpi: 'signUpExit',
            currentPeriodValue: -785126,
            previousPeriodValue: -1116264
        },
        lastNintyDays: {
            kpi: 'signUpExit',
            currentPeriodValue: -2234454,
            previousPeriodValue: -2650382
        }
    },
    subscribers: {
        lastThirtyDays: {
            kpi: 'subscribers',
            currentPeriodValue: 4436579,
            previousPeriodValue: 5414655
        },
        lastNintyDays: {
            kpi: 'subscribers',
            currentPeriodValue: 7830595,
            previousPeriodValue: 8282112
        }
    },
    adViewers: {
        lastThirtyDays: {
            kpi: 'watchedAds',
            currentPeriodValue: 20102920,
            previousPeriodValue: 39778948
        },
        lastNintyDays: {
            kpi: 'watchedAds',
            currentPeriodValue: 64852774,
            previousPeriodValue: 44829992
        }
    },
    exploreExit: {
        lastThirtyDays: {
            kpi: 'exploreExit',
            currentPeriodValue: 14195750.160516536,
            previousPeriodValue: 27617597.409066014
        },
        lastNintyDays: {
            kpi: 'exploreExit',
            currentPeriodValue: 43096942.44065498,
            previousPeriodValue: 29649439.16055824
        }
    },
    searchExit: {
        lastThirtyDays: {
            kpi: 'searchExit',
            currentPeriodValue: 5907169.839483464,
            previousPeriodValue: 12161350.590933988
        },
        lastNintyDays: {
            kpi: 'searchExit',
            currentPeriodValue: 21755831.559345014,
            previousPeriodValue: 15180552.83944176
        }
    },
    watchedFreeContent: {
        lastThirtyDays: {
            kpi: 'watchedFreeContent',
            currentPeriodValue: 6578767,
            previousPeriodValue: 6663374
        },
        lastNintyDays: {
            kpi: 'watchedFreeContent',
            currentPeriodValue: 9152069,
            previousPeriodValue: 9120751
        }
    },
    watchedPremiumContent: {
        lastThirtyDays: {
            kpi: 'watchedPremiumContent',
            currentPeriodValue: 6935835,
            previousPeriodValue: 6830935
        },
        lastNintyDays: {
            kpi: 'watchedPremiumContent',
            currentPeriodValue: 9612794,
            previousPeriodValue: 9817156
        }
    },
    watchedAdsExit: {
        lastThirtyDays: {
            kpi: 'watchedAdsExit',
            currentPeriodValue: 875801,
            previousPeriodValue: 1014884
        },
        lastNintyDays: {
            kpi: 'watchedAdsExit',
            currentPeriodValue: 1264670,
            previousPeriodValue: 1226938
        }
    },
    dav: {
        lastThirtyDays: {
            kpi: 'dav',
            currentPeriodValue: 878659.1176470589,
            previousPeriodValue: 924609.1428571428
        },
        lastNintyDays: {
            kpi: 'dav',
            currentPeriodValue: 910961.2972972973,
            previousPeriodValue: 909543.1111111111
        }
    },
    mav: {
        lastThirtyDays: {
            kpi: 'mav',
            currentPeriodValue: 3560778,
            previousPeriodValue: 4399771
        },
        lastNintyDays: {
            kpi: 'mav',
            currentPeriodValue: 6565925,
            previousPeriodValue: 7055174
        }
    },
    vtur: {
        lastThirtyDays: {
            kpi: 'vtur',
            currentPeriodValue: 0.7451317306677835,
            previousPeriodValue: 0.7319302133234532
        },
        lastNintyDays: {
            kpi: 'vtur',
            currentPeriodValue: 0.7377877392097705,
            previousPeriodValue: 0.7168003102476078
        }
    },
    qualityViewers: {
        lastThirtyDays: {
            kpi: 'qualityViewers',
            currentPeriodValue: 24691532,
            previousPeriodValue: 30940663
        },
        lastNintyDays: {
            kpi: 'qualityViewers',
            currentPeriodValue: 51007661,
            previousPeriodValue: 64042616
        }
    },
    watchTime: {
        lastThirtyDays: {
            kpi: 'watchTime',
            currentPeriodValue: 4436579,
            previousPeriodValue: 5414655
        },
        lastNintyDays: {
            kpi: 'watchTime',
            currentPeriodValue: 7830595,
            previousPeriodValue: 8282112
        }
    },
    adImpressions: {
        lastThirtyDays: {
            kpi: 'adImpressions',
            currentPeriodValue: 1189097110,
            previousPeriodValue: 1589846797
        },
        lastNintyDays: {
            kpi: 'adImpressions',
            currentPeriodValue: 4526722000,
            previousPeriodValue: 1678002739
        }
    },
    perNewSubscriptions: {
        lastThirtyDays: {
            kpi: 'perNewSubscriptions',
            currentPeriodValue: 1217563,
            previousPeriodValue: 1263291
        },
        lastNintyDays: {
            kpi: 'perNewSubscriptions',
            currentPeriodValue: 3659186,
            previousPeriodValue: 4422048
        }
    },
    perRepeatSubscriptions: {
        lastThirtyDays: {
            kpi: 'perRepeatSubscriptions',
            currentPeriodValue: 5615035,
            previousPeriodValue: 7902636
        },
        lastNintyDays: {
            kpi: 'perRepeatSubscriptions',
            currentPeriodValue: 18839534,
            previousPeriodValue: 15452039
        }
    },
    ecsViewers: {
        lastThirtyDays: {
            kpi: 'ecsViewers',
            currentPeriodValue: 472,
            previousPeriodValue: 455
        },
        lastNintyDays: {
            kpi: 'ecsViewers',
            currentPeriodValue: 1377,
            previousPeriodValue: 1325
        }
    },
    ecsWatchTime: {
        lastThirtyDays: {
            kpi: 'ecsWatchTime',
            currentPeriodValue: 3195609938.436933,
            previousPeriodValue: 2874164981.518733,
        },
        lastNintyDays: {
            kpi: 'ecsWatchTime',
            currentPeriodValue: 11554811433.1547,
            previousPeriodValue: 18416728476.743954,
        }
    },
}

export {
    dataForOverAll,
    dataForAVOD,
    dataForSVOD,
}