import React, { useState } from "react";
import './CatalogueEnrichment.css'
import CatalogueEnrichmentOtt from "./CatalogueEnrichmentOtt";


const CatalogueEnrichment = () => {

    
    const [buttonTab, setButtonTab] = useState('ott');
   
    return (
        <div>
            <div className="catalogue-enrichment-ott-and-comingsoon-container-syn">
                <div onClick={() => setButtonTab('linear')} className={buttonTab === 'linear' ? ("catalogue-enrichment-active-button-tab-syn"):("catalogue-enrichment-button-tab-syn")} >Linear</div>
                <div onClick={() => setButtonTab('ott')} className={buttonTab === 'ott' ? ("catalogue-enrichment-active-button-tab-syn"):("catalogue-enrichment-button-tab-syn")}>OTT</div>
            </div>
            
            {buttonTab === 'ott' && 
                <CatalogueEnrichmentOtt />
            }
            
        </div>
    )
}

export default CatalogueEnrichment;