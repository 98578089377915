import React, { useEffect } from "react";
import * as d3 from 'd3';
import './FunnelChart.css'
import FunnelGraph from "funnel-graph-js";

const StackAreaChart = () => {

    console.log(window.innerWidth);
    console.log(window.innerHeight);

    useEffect(() => {
        const data = {
          labels: [
            "Perfil Moral",
            "Info Personal",
            "Info Negocio",
            "Firma Contrato"
          ],
          subLabels: ["Persona Moral"],
          colors: [["#E9EFFF"]],
          values: [[100], [74], [25], [3]]
        };
    
        const graph = new FunnelGraph({
          container: ".funnel-chart",
          gradientDirection: "horizontal",
          data: data,
          displayPercent: true,
          direction: "Horizontal",
          width: window.innerWidth,
          height: 250,
          subLabelValue: "values"
        });
    
        graph.draw();
      }, []);

    return(
        <div className="funnel-chart" />
    )
}

export default StackAreaChart;