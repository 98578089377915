import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const ProgressBarChart = ({ data, width, height }) => {
  const chartRef = useRef();

  useEffect(() => {
    const svg = d3.select(chartRef.current)
      .append("svg")
      .attr("width", width).classed('my-prograss-chart-svg-syn', true)
      .attr("height", height)
      .style('background', '#E5E5E5')

    const bar = svg.append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 0)
      .attr("height", height)
      .attr("fill", "#402177");

    bar.transition()
      .duration(1000)
      .attr("width", `${data}%`);

    return () => {
      svg.remove();
    }
  }, [data, height, width]);

  return (
    <>
   
    <div ref={chartRef} className="productCardAllChart_session_line bac"></div>
  
    </>
  );
}

export default ProgressBarChart;
