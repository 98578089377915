import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import SankeyChartForConsumer from "../../Charts/SankeyChart/SankeyChartForConsumer/SankeyChartForConsumer";
import ExecutiveSummaryServicesConsumer from "../../../Services/ExecutiveSummaryServices/ExecutiveSummaryServicesConsumer";
import { dataForOverAll, dataForAVOD, dataForSVOD } from "../../Charts/SankeyChart/SankeyChartForConsumer/data";
// import Sankey from "../../Charts/SankeyChart/RND";
// import Sankey from "../../Charts/SankeyChart/NewSankey/NewSankey";
import "./ExecutiveSummaryConsumer.css"


const ExecutiveSummaryConsumer = (props) => {

  const [data, setData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [activeKPICharter, setActiveKPICharter] = useState('aquisition');
  const [activeConsumerJourneyFilter, setActiveConsumerJourneyFilter] = useState('overall');
  const [isShowForThirtyDays, setIsShowForThirtyDays] = useState(true);
  const [dauData, setDAUData] = useState(0);
  const [mauData, setMAUData] = useState(0);
  const [executiveSummaryConsumerAPIData, setExecutiveSummaryConsumerAPIData] = useState([]);

  const [rawDataOverAll, setrawDataOverAll] = useState([]);

  // let rawDataOverAll = [
  //   {
  //     kpi: 'dau',
  //     currentPeriodValue: 6683699.137931034,
  //     previousPeriodValue: 6863357.666666666,
  //   },
  //   {
  //     kpi: 'mau',
  //     currentPeriodValue: 47027705,
  //     previousPeriodValue: 49100988,
  //   },
  //   {
  //     kpi: 'paidTraffic',
  //     currentPeriodValue: 19659822,
  //     previousPeriodValue: 19581706,
  //   },
  //   {
  //     kpi: 'organicTraffic',
  //     currentPeriodValue: 27367883,
  //     previousPeriodValue: 29519282,
  //   },
  //   {
  //     kpi: 'newUsers',
  //     currentPeriodValue: 19522484,
  //     previousPeriodValue: 20813372,
  //   },
  //   {
  //     kpi: 'returningUsers',
  //     currentPeriodValue: 27505221,
  //     previousPeriodValue: 2287616,
  //   },
  //   {
  //     kpi: 'anonymous',
  //     currentPeriodValue: 31811752,
  //     previousPeriodValue: 33738409,
  //   },
  //   {
  //     kpi: 'signUps',
  //     currentPeriodValue: 15782071,
  //     previousPeriodValue: 16183747,
  //   },
  //   {
  //     kpi: 'dav',
  //     currentPeriodValue: 4391305.275862069,
  //     previousPeriodValue: 4274506.866666666,
  //   },
  //   // {
  //   //   kpi: 'mav',
  //   //   currentPeriodValue: 22543622,
  //   //   previousPeriodValue: 22903927,
  //   // },
  //   {
  //     kpi: 'mav',
  //     currentPeriodValue: 26702517,
  //     previousPeriodValue: 26382830,
  //   },
  //   {
  //     kpi: 'watchTime',
  //     currentPeriodValue: 31811752,
  //     previousPeriodValue: 33738409,
  //   },
  //   {
  //     kpi: 'watchTimePerViewer',
  //     currentPeriodValue: 9100.816856265865,
  //     previousPeriodValue: 9355.847357187065,
  //   },
  //   {
  //     kpi: 'search',
  //     currentPeriodValue: 10505649,
  //     previousPeriodValue: 11064644,
  //   },
  //   {
  //     kpi: 'explore',
  //     currentPeriodValue: 29874922,
  //     previousPeriodValue: 30224781,
  //   },
  //   {
  //     kpi: 'subscribers',
  //     currentPeriodValue: 5379907,
  //     previousPeriodValue: 5488459,
  //   },
  //   {
  //     kpi: 'watchedFreeContent',
  //     currentPeriodValue: 34018072,
  //     previousPeriodValue: 34213170,
  //   },
  //   {
  //     kpi: 'watchedPremiumContent',
  //     currentPeriodValue: 8515782,
  //     previousPeriodValue: 8918584,
  //   },
  //   {
  //     kpi: 'anonymousExit',
  //     currentPeriodValue: 4520000,
  //     previousPeriodValue: 5000000,
  //   },
  //   {
  //     kpi: 'signUpExit',
  //     currentPeriodValue: 2260000,
  //     previousPeriodValue: 2560000,
  //   },
  //   {
  //     kpi: 'exploreExit',
  //     currentPeriodValue: 29870000,
  //     previousPeriodValue: 49870000,
  //   },
  //   {
  //     kpi: 'watchedAdsExit',
  //     currentPeriodValue: 5194310,
  //     previousPeriodValue: 5748019,
  //   },
  //   {
  //     kpi: 'preCentageReturningUsersAVOD',
  //     currentPeriodValue: 55200000,
  //     previousPeriodValue: 54310000,
  //   },
  //   {
  //     kpi: 'preCentageReturningUsersSVOD',
  //     currentPeriodValue: 55200000,
  //     previousPeriodValue: 54310000,
  //   },
  // ]

  useEffect(() => {
    if (activeConsumerJourneyFilter === 'overall') {
      rawData = [];
      for (let item in dataForOverAll) {
        // console.log('item', item);
        let obj = {};
        if (isShowForThirtyDays) {
          obj = dataForOverAll[item].lastThirtyDays;
        } else {
          obj = dataForOverAll[item].lastNintyDays;
        }
        rawData.push(obj);
      }
      setrawDataOverAll(rawData);
    } else if (activeConsumerJourneyFilter === 'avod') {
      rawData = [];
      for (let item in dataForAVOD) {
        // console.log('item', item);
        let obj = {};
        if (isShowForThirtyDays) {
          obj = dataForAVOD[item].lastThirtyDays;
        } else {
          obj = dataForAVOD[item].lastNintyDays;
        }
        rawData.push(obj);
      }
      setrawDataOverAll(rawData);

    } else {
      rawData = [];
      for (let item in dataForSVOD) {
        // console.log('item', item);
        let obj = {};
        if (isShowForThirtyDays) {
          obj = dataForSVOD[item].lastThirtyDays;
        } else {
          obj = dataForSVOD[item].lastNintyDays;
        }
        rawData.push(obj);
      }
      setrawDataOverAll(rawData);
    }
    // console.log('rawDataOverAll after use Effect', rawDataOverAll);
  }, [isShowForThirtyDays, activeConsumerJourneyFilter]);


  const kpiCharters = [
    {
      id: "aquisition",
      kpiCards: [
        {
          kpi: 'dau',
          title: "DAU",
          mainValue: dauData,
          difference: 4,
          isActive: true,
        },
        {
          kpi: 'mau',
          title: "MAU",
          mainValue: mauData,
          difference: 8,
          isActive: true,
        },
      ]
    },
    {
      id: "discovery",
      kpiCards: [
        {
          kpi: 'vtur',
          title: "VTUR",
          mainValue: '--',
          difference: '--',
          isActive: true,
        },
        // {
        //   kpi: 'vtur',
        //   title: "VTUR",
        //   mainValue: 89,
        //   difference: -4,
        // },
        // {
        //   kpi: 'longSearchCTR',
        //   title: "Long Search CTR",
        //   mainValue: '--',
        //   difference: '--',
        //   isActive: false,
        // },
        // {
        //   kpi: 'impressionsRecomendedRails',
        //   title: "Impressions (Reco. Rails)",
        //   mainValue: '--',
        //   difference: '--',
        //   isActive: false,
        // }
      ]
    },
    {
      id: "engagement",
      kpiCards: [
        {
          kpi: 'dav',
          title: "DAV",
          mainValue: 89,
          difference: -4,
        },
        {
          kpi: 'mav',
          title: "MAV",
          mainValue: '65',
          difference: '8',
        },
        {
          kpi: 'watchTime',
          title: "Total Watch Time (min.)",
          mainValue: 222,
          difference: 14,
        },
        {
          kpi: 'qualityViewers',
          title: "Quality Viewers",
          mainValue: '--',
          difference: '--',
          isActive: true,
        },
        {
          kpi: 'ecsViewers',
          title: "ECS - Viewers",
          mainValue: '--',
          difference: '--',
          isActive: true,
        }
        ,

        {
          kpi: 'ecsWatchTime',
          title: "ECS - Watch Time",
          mainValue: '--',
          difference: '--',
          isActive: true,
        }
      ]
    },
    {
      id: "monetizatoin",
      kpiCards: [
        {
          kpi: 'perNewSubscriptions',
          title: "% New Subscriptions",
          mainValue: 42,
          difference: 14,
          isActive: true,
        },
        {
          kpi: 'perRepeatSubscriptions',
          title: "% Repeat Subscription",
          mainValue: 65,
          difference: 8,
          isActive: true,
        },
        {
          kpi: 'adImpressions',
          title: "Ad Impressions",
          mainValue: 89,
          difference: -4,
          isActive: true,
        },
      ]
    },
    {
      id: "retention",
      kpiCards: [
        {
          kpi: 'perViewersRetainedMoneTwo',
          title: "% Viewers retained (M1- M2)",
          mainValue: '--',
          difference: '--',
          isActive: false,
        }
        ,
        {
          kpi: 'perViewersRetainedMoneMoneTwo',
          title: "% Viewers retained (M1-M12)",
          mainValue: '--',
          difference: '--',
          isActive: false,
        }
      ]
    },
  ]

  let rawData = [
    {
      dau: '6678661.620689655',
      mau: '46480311',
      paidTraffic: '19094659',
      organicTraffic: '27385652',
      newUsers: '19116654',
      returningUsers: '27363657',
      anonymous: '31286011',
      anonymousExit: '4480057',
      signUps: '15769137',
      signUpExit: '2175980',
      dav: '4375236.137931034',
      mav: '26135290',
      watchTime: '15769137',
      watchTimePerViewer: '9298.41809291591',
      search: '10509709',
      explore: '29314745',
      subscribers: '5365698',
      watchedContentExit: '20345021'
    }

    //6655857

  ]

  let rawDataAVOD = [
    {
      kpi: 'dau',
      currentPeriodValue: 5460847.413793105,
      previousPeriodValue: 5629021.566666667,
    },
    {
      kpi: 'mau',
      currentPeriodValue: 43104484,
      previousPeriodValue: 45306069,
    },
    {
      kpi: 'paidTraffic',
      currentPeriodValue: 17987862,
      previousPeriodValue: 17966237,
    },
    {
      kpi: 'organicTraffic',
      currentPeriodValue: 25116622,
      previousPeriodValue: 27339832,
    },
    {
      kpi: 'newUsers',
      currentPeriodValue: 19282636,
      previousPeriodValue: 20701096,
    },
    {
      kpi: 'returningUsers',
      currentPeriodValue: 23821848,
      previousPeriodValue: 24604973,
    },
    {
      kpi: 'anonymous',
      currentPeriodValue: 31811752,
      previousPeriodValue: 33738409,
    },
    {
      kpi: 'signUps',
      currentPeriodValue: 11601022,
      previousPeriodValue: 12147673,
    },
    {
      kpi: 'dav',
      currentPeriodValue: 3469914.5172413797,
      previousPeriodValue: 3378999.333333334,
    },
    // {
    //   kpi: 'mav',
    //   currentPeriodValue: 22543622,
    //   previousPeriodValue: 22903927,
    // },
    {
      kpi: 'mav',
      currentPeriodValue: 3483574.3103448274,
      previousPeriodValue: 3374371.0333333337,
    },
    {
      kpi: 'watchTime',
      currentPeriodValue: 31811752,
      previousPeriodValue: 33738409,
    },
    {
      kpi: 'watchTimePerViewer',
      currentPeriodValue: 7316.077079112819,
      previousPeriodValue: 7566.722618384638,
    },
    {
      kpi: 'search',
      currentPeriodValue: 8700000,
      previousPeriodValue: 9000000,
    },
    {
      kpi: 'explore',
      currentPeriodValue: 17500000,
      previousPeriodValue: 17890000,
    },
    {
      kpi: 'subscribers',
      currentPeriodValue: 0,
      previousPeriodValue: 0,
    },
    {
      kpi: 'watchedFreeContent',
      currentPeriodValue: 27403764,
      previousPeriodValue: 27630616,
    },
    {
      kpi: 'watchedPremiumContent',
      currentPeriodValue: 1702349,
      previousPeriodValue: 2071791,
    },
    {
      kpi: 'anonymousExit',
      currentPeriodValue: 12490000,
      previousPeriodValue: 16490000,
    },
    {
      kpi: 'signUpExit',
      currentPeriodValue: 4390000,
      previousPeriodValue: 4790000,
    },
    // {
    //   kpi: 'exploreExit',
    //   currentPeriodValue: 29870000,
    //   previousPeriodValue: 49870000,
    // },
    {
      kpi: 'watchedAdsExit',
      currentPeriodValue: 5194310,
      previousPeriodValue: 5748019,
    },
    {
      kpi: 'preCentageReturningUsersAVOD',
      currentPeriodValue: 55200000,
      previousPeriodValue: 54310000,
    },
  ]

  let rawDataSVOD = [
    {
      kpi: 'dau',
      currentPeriodValue: 1249146.6896551724,
      previousPeriodValue: 1268283.933333333,
    },
    {
      kpi: 'mau',
      currentPeriodValue: 5379907,
      previousPeriodValue: 5488459,
    },
    {
      kpi: 'paidTraffic',
      currentPeriodValue: 2050258,
      previousPeriodValue: 2020210,
    },
    {
      kpi: 'organicTraffic',
      currentPeriodValue: 3329649,
      previousPeriodValue: 3468249,
    },
    {
      kpi: 'newUsers',
      currentPeriodValue: 1035679,
      previousPeriodValue: 1037278,
    },
    {
      kpi: 'returningUsers',
      currentPeriodValue: 4344228,
      previousPeriodValue: 4451181,
    },
    {
      kpi: 'anonymous',
      currentPeriodValue: 0,
      previousPeriodValue: 0,
    },
    {
      kpi: 'signUps',
      currentPeriodValue: 5379907,
      previousPeriodValue: 5488459,
    },
    {
      kpi: 'dav',
      currentPeriodValue: 920250.3448275861,
      previousPeriodValue: 917791.9333333335,
    },
    // {
    //   kpi: 'mav',
    //   currentPeriodValue: 22543622,
    //   previousPeriodValue: 22903927,
    // },
    {
      kpi: 'mav',
      currentPeriodValue: 4395692,
      previousPeriodValue: 4542484,
    },
    {
      kpi: 'watchTime',
      currentPeriodValue: 5379907,
      previousPeriodValue: 5488459,
    },
    {
      kpi: 'watchTimePerViewer',
      currentPeriodValue: 16075.937135640188,
      previousPeriodValue: 15755.262037222828,
    },
    {
      kpi: 'search',
      currentPeriodValue: 1970000,
      previousPeriodValue: 1980000,
    },
    {
      kpi: 'explore',
      currentPeriodValue: 2550000,
      previousPeriodValue: 2560000,
    },
    {
      kpi: 'subscribers',
      currentPeriodValue: 5379907,
      previousPeriodValue: 5488459,
    },
    {
      kpi: 'watchedFreeContent',
      currentPeriodValue: 6601483,
      previousPeriodValue: 6846792,
    },
    {
      kpi: 'watchedPremiumContent',
      currentPeriodValue: 6813424,
      previousPeriodValue: 6568038,
    },
    {
      kpi: 'anonymousExit',
      currentPeriodValue: 0,
      previousPeriodValue: 0,
    },
    {
      kpi: 'signUpExit',
      currentPeriodValue: 8600,
      previousPeriodValue: 8800,
    },
    {
      kpi: 'watchedAdsExit',
      currentPeriodValue: 5194310,
      previousPeriodValue: 5748019,
    },
  ]

  const [kpiChartersData, setKPIChartersData] = useState(kpiCharters);
  const [rawDataGetFromAPI, setRawDataGetFromAPI] = useState(rawDataOverAll);


  const prepareDataForKPICharters = (rawDataGetFromAPI) => {
    let updatedKPIChartersData = kpiCharters.map((item, index) => {
      // let newObject = item;
      let kpiCardArray = item.kpiCards.map((card) => {
        let cardObject = card;
        rawDataGetFromAPI.map((element, i) => {
          if (card.kpi === element.kpi) {
            cardObject = {
              ...card,
              mainValue: (element.currentPeriodValue / 1000000).toFixed(0),
              difference: (100 - ((element.previousPeriodValue / element.currentPeriodValue) * 100).toFixed(0))
            }
          }
        })
        return cardObject;
      })
      return {
        ...item,
        kpiCards: kpiCardArray,
      };
    });

    return updatedKPIChartersData;
  }

  // useEffect

  //12104419 1,32,82,016
  let updatedKPIChartersData = kpiCharters.map((item, index) => {
    let newObject = item;
    let kpiCardArray = item.kpiCards.map((card) => {
      let cardObject = card;
      rawDataGetFromAPI.map((element, i) => {
        if (card.kpi === element.kpi) {
          cardObject = {
            ...card,
            mainValue: (element.currentPeriodValue / 1000000).toFixed(0),
            difference: (100 - ((element.previousPeriodValue / element.currentPeriodValue) * 100).toFixed(0))
          }
        }
      })
      return cardObject
    })
    return {
      ...item,
      kpiCards: kpiCardArray,
    };
  });

  useEffect(() => {
    setKPIChartersData(prepareDataForKPICharters(rawDataOverAll));
  }, [rawDataOverAll])

  // //console.log('updatedKPIChartersData', updatedKPIChartersData)


  const getData = async () => {
    setExecutiveSummaryConsumerAPIData(await ExecutiveSummaryServicesConsumer.getDataForExecutiveSummaryConsumer())
  }

  // useEffect(() => {
  //   getData();
  // }, [])

  useEffect(() => {



  }, [])

  const handleOnKPICharterButtonClick = (event, id) => {
    setActiveKPICharter(id);
  }

  const handleOnConsumerJourneyButtonClick = (event, id) => {
    setActiveConsumerJourneyFilter(id);
    if (id === "overall") {
      setRawDataGetFromAPI(rawDataOverAll)
    } else if (id === "avod") {
      setRawDataGetFromAPI(rawDataAVOD)
    } else {
      setRawDataGetFromAPI(rawDataSVOD)
    }
  }


  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="executive-summary-consumer-journey-container">
      <div className="executive-summary-consumer-journey-filters-and-note-container">
        <div className="consumer-journey-filters">
          <button
            className={activeConsumerJourneyFilter === "overall" ? "consumer-journey-button-active" : "consumer-journey-button"}
            onClick={event => handleOnConsumerJourneyButtonClick(event, "overall")}
          >
            All
          </button>
          <button
            className={activeConsumerJourneyFilter === "avod" ? "consumer-journey-button-active" : "consumer-journey-button"}
            onClick={event => handleOnConsumerJourneyButtonClick(event, "avod")}
          >
            AVOD
          </button>
          <button
            className={activeConsumerJourneyFilter === "svod" ? "consumer-journey-button-active" : "consumer-journey-button"}
            onClick={event => handleOnConsumerJourneyButtonClick(event, "svod")}
          >
            SVOD
          </button>
          <button
            className={activeConsumerJourneyFilter === "tvod" ? "consumer-journey-button-active consumer-journey-button-disabled" : "consumer-journey-button-disabled"}
          // onClick={event => handleOnConsumerJourneyButtonClick(event, "tvod")}
          >
            TVOD
          </button>
        </div>

        <p className="executive-journey-note-item">All Numbers reported in INR Mn. </p>
      </div>
      <div className="kpi-cards-container">
        <div className="kpi-cards-buttons">
          <button
            className={activeKPICharter === "aquisition" ? "kpi-card-button-active" : "kpi-card-button"}
            onClick={event => handleOnKPICharterButtonClick(event, "aquisition")}
            style={{ width: "20%" }}
          >
            Acquisition
          </button>
          <button
            className={activeKPICharter === "discovery" ? "kpi-card-button-active" : "kpi-card-button"}
            onClick={event => handleOnKPICharterButtonClick(event, "discovery")}
            style={{ width: "20%" }}
          >
            Discovery
          </button>
          <button
            className={activeKPICharter === "engagement" ? "kpi-card-button-active" : "kpi-card-button"}
            onClick={event => handleOnKPICharterButtonClick(event, "engagement")}
            style={{ width: "20%" }}
          >
            Engagement
          </button>
          <button
            className={activeKPICharter === "monetizatoin" ? "kpi-card-button-active" : "kpi-card-button"}
            onClick={event => handleOnKPICharterButtonClick(event, "monetizatoin")}
            style={{ width: "20%" }}
          >
            Monetization
          </button>
          <button
            className={activeKPICharter === "retention" ? "kpi-card-button-active" : "kpi-card-button"}
            onClick={event => handleOnKPICharterButtonClick(event, "retention")}
            style={{ width: "20%" }}
          >
            Retention
          </button>
        </div>
        <div className="kpi-cards-values">
          {/* {
            //console.log(kpiCharters.filter((item, index) => item.id === activeKPICharter)[0].kpiCards

              .filter((d, i) => {
                if (activeConsumerJourneyFilter === "avod" && (d.title === "Ad Impressions" || d.title === "AVOD Revenue" || d.title === "% Renewals- AVOD")) {
                  return d;
                } else if (activeConsumerJourneyFilter === "svod" && (d.title === "SVOD Revenue" || d.title === "Subs Split" || d.title === "% Renewals- SVOD")) {
                  return d;
                }
              })
            )} */}
          {kpiChartersData.length > 0 &&
            kpiChartersData.filter((item, index) => item.id === activeKPICharter)[0].kpiCards
              .filter((d, i) => {
                // if (
                //   activeConsumerJourneyFilter === "avod" &&
                //   (activeKPICharter === "monetizatoin" || activeKPICharter === "retention") &&
                //   (d.title === "Ad Impressions" || d.title === "% Returning Users- AVOD")) {
                //   return d;
                // } else if (
                //   activeConsumerJourneyFilter === "svod" &&
                //   (activeKPICharter === "monetizatoin" || activeKPICharter === "retention") &&
                //   (d.title === "SVOD Revenue" || d.title === "New & Win back Subscriptions" || d.title === "Manual" || d.title === "Auto & Advance Renewal" || d.title === "% Renewals- SVOD" ||
                //     d.title === "Manual Subscriptions" || d.title === "Auto & Advance Subscriptions")
                // ) {
                //   return d;
                // } else if (
                //   activeConsumerJourneyFilter === "avod" &&
                //   !(activeKPICharter === "monetizatoin" || activeKPICharter === "retention")) {
                //   return d;
                // } else if (
                //   activeConsumerJourneyFilter === "svod" &&
                //   !(activeKPICharter === "monetizatoin" || activeKPICharter === "retention")) {
                //   return d;
                // } else if (
                //   activeConsumerJourneyFilter === "overall" &&
                //   activeKPICharter === "monetizatoin" &&
                //   !(d.title === "AVOD Revenue" || d.title === "Subs Split")
                // ) {
                //   return d;
                // } else if (
                //   activeConsumerJourneyFilter === "overall" &&
                //   activeKPICharter !== "monetizatoin"
                // ) {
                //   return d;
                // }
                if ((activeConsumerJourneyFilter === "avod" && activeKPICharter === "monetizatoin") && d.title === "Ad Impressions") {
                  return d;
                } else if ((activeConsumerJourneyFilter === "avod" && activeKPICharter !== "monetizatoin")) {
                  return d;
                } else if ((activeConsumerJourneyFilter === "svod" && activeKPICharter === "monetizatoin") && d.title !== "Ad Impressions") {
                  return d;
                } else if ((activeConsumerJourneyFilter === "svod" && activeKPICharter !== "monetizatoin")) {
                  return d;
                } else if ((activeConsumerJourneyFilter === "overall")) {
                  return d;
                }

              })
              .map((item, index) => <KPICard
                title={item.title}
                mainValue={item.mainValue}
                difference={item.difference}
                isActive={item.isActive}
                activeConsumerJourneyFilter={activeConsumerJourneyFilter}
              />)


            // kpiCharters.filter((item, index) => item.id === activeKPICharter)[0]
            //   .kpiCards
            //   .map((mapItem, index) => {
            //     return <KPICard 
            //     mapItem
            //   })
            // .kpiCards.map((item, index) => console.log(item, item))
          }
          {/* <div className="kpi-card">
            <p className="kpi-card-title">Total Ads Requested</p>
            <div className="kpi-card-value">
              <p className="kpi-card-value-main">7500</p>
              <p className="kpi-card-perdiff-pos">
                8%
                <svg width="10" height="12" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.35355 0.646407C4.15829 0.451145 3.84171 0.451145 3.64645 0.646407L0.464467 3.82839C0.269204 4.02365 0.269204 4.34023 0.464466 4.53549C0.659729 4.73076 0.976311 4.73076 1.17157 4.53549L4 1.70707L6.82843 4.53549C7.02369 4.73076 7.34027 4.73076 7.53553 4.53549C7.7308 4.34023 7.7308 4.02365 7.53553 3.82839L4.35355 0.646407ZM4.5 7.69043L4.5 0.99996L3.5 0.99996L3.5 7.69043L4.5 7.69043Z" fill="#00C48C" />
                </svg>
              </p>
            </div>
          </div>

          <div className="kpi-card">
            <p className="kpi-card-title">Total Ads Requested</p>
            <div className="kpi-card-value">
              <p className="kpi-card-value-main">7500</p>
              <p className="kpi-card-perdiff-pos">
                8%
                <svg width="10" height="12" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.35355 0.646407C4.15829 0.451145 3.84171 0.451145 3.64645 0.646407L0.464467 3.82839C0.269204 4.02365 0.269204 4.34023 0.464466 4.53549C0.659729 4.73076 0.976311 4.73076 1.17157 4.53549L4 1.70707L6.82843 4.53549C7.02369 4.73076 7.34027 4.73076 7.53553 4.53549C7.7308 4.34023 7.7308 4.02365 7.53553 3.82839L4.35355 0.646407ZM4.5 7.69043L4.5 0.99996L3.5 0.99996L3.5 7.69043L4.5 7.69043Z" fill="#00C48C" />
                </svg>
              </p>
            </div>
          </div>

          <div className="kpi-card">
            <p className="kpi-card-title">Total Ads Requested</p>
            <div className="kpi-card-value">
              <p className="kpi-card-value-main">7500</p>
              <p className="kpi-card-perdiff-pos">
                8%
                <svg width="10" height="12" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.35355 0.646407C4.15829 0.451145 3.84171 0.451145 3.64645 0.646407L0.464467 3.82839C0.269204 4.02365 0.269204 4.34023 0.464466 4.53549C0.659729 4.73076 0.976311 4.73076 1.17157 4.53549L4 1.70707L6.82843 4.53549C7.02369 4.73076 7.34027 4.73076 7.53553 4.53549C7.7308 4.34023 7.7308 4.02365 7.53553 3.82839L4.35355 0.646407ZM4.5 7.69043L4.5 0.99996L3.5 0.99996L3.5 7.69043L4.5 7.69043Z" fill="#00C48C" />
                </svg>
              </p>
            </div>
          </div>

          <div className="kpi-card">
            <p className="kpi-card-title">Total Ads Requested</p>
            <div className="kpi-card-value">
              <p className="kpi-card-value-main">7500</p>
              <p className="kpi-card-perdiff-pos">
                8%
                <svg width="10" height="12" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.35355 0.646407C4.15829 0.451145 3.84171 0.451145 3.64645 0.646407L0.464467 3.82839C0.269204 4.02365 0.269204 4.34023 0.464466 4.53549C0.659729 4.73076 0.976311 4.73076 1.17157 4.53549L4 1.70707L6.82843 4.53549C7.02369 4.73076 7.34027 4.73076 7.53553 4.53549C7.7308 4.34023 7.7308 4.02365 7.53553 3.82839L4.35355 0.646407ZM4.5 7.69043L4.5 0.99996L3.5 0.99996L3.5 7.69043L4.5 7.69043Z" fill="#00C48C" />
                </svg>
              </p>
            </div>
          </div> */}

        </div>
      </div>
      <div className="sankey-chart-parent-container">
        <div className="executive-summary-consumer-sankey-chart-title-and-toggle-filter-container">
          <p className="executive-summary-consumer-sankey-chart-title">
            Consumer Engagement on Zee5 &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-ifo-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path></svg>
          </p>
          <div className="executive-summary-consumer-sankey-chart-toggle-filter-container">
            <label className={isShowForThirtyDays ? 'active-toggle' : 'in-active-toggle'}>Last 30 Days</label>
            <input type="checkbox" onClick={() => setIsShowForThirtyDays(!isShowForThirtyDays)}></input>
            <label className={!isShowForThirtyDays ? 'active-toggle' : 'in-active-toggle'}>Last 90 Days</label>
          </div>
        </div>
        {/* <p className="executive-journey-note-item">All numbers in Millions</p> */}
        <div className="sankey">
          {
            rawDataOverAll.length > 0 &&
            <SankeyChartForConsumer
              dataForSankeyChart={rawDataOverAll}
              useTypeFilter={activeConsumerJourneyFilter}
              activeKPICharter={activeKPICharter}
            />
          }
        </div>
      </div>

    </div>
  );
}

export default ExecutiveSummaryConsumer;

const KPICard = (props) => {

  const { title, mainValue, difference, activeConsumerJourneyFilter, isActive = true } = props

  let valuForVisulisation = mainValue, differenceForVisulisation = difference, isActiveClassForVisulisation = isActive;

  if (activeConsumerJourneyFilter === "overall" && (title === "% Viewers retained (M1- M2)" || title === "% Viewers retained (M1-M12)")) {
    valuForVisulisation = '--';
    differenceForVisulisation = '--';
    isActiveClassForVisulisation = false
  }

  return (
    <div className={isActiveClassForVisulisation ? "kpi-card" : "kpi-card-disabled"}>
      <p className="kpi-card-title">{title}</p>
      <div className="kpi-card-value">
        <p className="kpi-card-value-main">{valuForVisulisation}</p>
        <p className={differenceForVisulisation >= 0 ? "kpi-card-perdiff-pos" : "kpi-card-perdiff-neg"}>
          {differenceForVisulisation}%
          {
            differenceForVisulisation >= 0 ?
              <svg width="10" height="12" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.35355 0.646407C4.15829 0.451145 3.84171 0.451145 3.64645 0.646407L0.464467 3.82839C0.269204 4.02365 0.269204 4.34023 0.464466 4.53549C0.659729 4.73076 0.976311 4.73076 1.17157 4.53549L4 1.70707L6.82843 4.53549C7.02369 4.73076 7.34027 4.73076 7.53553 4.53549C7.7308 4.34023 7.7308 4.02365 7.53553 3.82839L4.35355 0.646407ZM4.5 7.69043L4.5 0.99996L3.5 0.99996L3.5 7.69043L4.5 7.69043Z" fill="#00C48C" />
              </svg> :
              <svg width="10" height="12" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.20699 7.38777C4.40226 7.58303 4.71884 7.58303 4.9141 7.38777L8.09608 4.20579C8.29134 4.01053 8.29134 3.69395 8.09608 3.49869C7.90082 3.30342 7.58424 3.30342 7.38897 3.49869L4.56055 6.32711L1.73212 3.49869C1.53686 3.30342 1.22028 3.30342 1.02501 3.49869C0.829751 3.69395 0.829751 4.01053 1.02501 4.20579L4.20699 7.38777ZM4.06055 0.34375L4.06055 7.03422L5.06055 7.03422L5.06055 0.34375L4.06055 0.34375Z" fill="#FF647C" />
              </svg>
          }
        </p>
      </div>
    </div>
  )
}
