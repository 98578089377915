let productJourneyFilterOptions = [
    {
        id: 'platform',
        label: 'Platform',
        isMendatory: false,
        options: [
            'All',
            'Android',
            'IOS',
            'WEB',
            'Android TV',
            'HTML 5',
            'AFS',
            'Apple TV',
            'AOSP',
        ],
    },
    {
        id: 'usertype',
        label: 'User Type',
        isMendatory: false,
        options: [
            'All',
            'Guest',
            'Registered',
            'Premium',
            'AVOD',
        ],
    },
    {
        id: 'region',
        label: 'Region',
        isMendatory: true,
        options: [
            'All',
            'Maharashtra',
            'National Capital Territory',
            'Telangana',
            'Karnataka',
            'Tamil Nadu',
            'West Bengal',
            'Uttar Pradesh',
            'Gujarat',
            'Bihar',
            'Madhya Pradesh'
        ],
    },
    {
        id: 'tier',
        label: 'Tier',
        options: [
            'All',
            1,
            2,
            3,
        ],
    },
    {
        id: 'gender',
        label: 'Gender',
        options: [
            'All',
            'Male',
            'Female',
            'undefined',
            'other'
        ],
    }
]


export default productJourneyFilterOptions;