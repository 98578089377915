import { useRef, useLayoutEffect, useEffect, useState } from 'react';
import * as d3 from 'd3';
import './HorizontalProgressBarChartForTable.css';
import ResizeObserver from 'resize-observer-polyfill';

import { useMediaQuery } from "@material-ui/core";

const HorizontalProgressBarChartForTable = (props) => {

    const { value = 50, maxValue = 500, barHeight = 25, width = 200, fillColor = '#A47CD0' } = props;

    const svgRef = useRef();
    const wrapperRef = useRef();

    const dimensions = useResizeObserver(wrapperRef);

    console.log(dimensions, 'dimensions');

    // let parentDimensions;

    // let parentDimensions = document.querySelector('.horizontal-bar-chart-container').getBoundingClientRect();
    // console.log('parentDimensions', parentDimensions);

    useLayoutEffect(() => {
        const svg = d3.select(svgRef.current);

        const xScale = d3.scaleLinear()
            .domain([0, maxValue])
            .range([0, maxValue == 500 ? 140 : 500])

        svg
            .append('rect')
            .attr('class', 'bar')
            .attr('width', xScale(value))
            .attr('fill', fillColor)
            .attr('height', barHeight);

        d3.selectAll('.remaining-amount')
            .attr('x', xScale(value) + 150);

        d3.selectAll('.remaining-portion')
            .attr('width', xScale(value) + 150);

        d3.select('.budget-bar-group')
            .data([value])
            .enter()
            .append('text')
            .attr('x', xScale(value) + 20)
            .html('20')

        svg.selectAll(".budget-bar-group")
            .data([value])
            .enter()
            .append("text")
            .attr("x", d => xScale(d) + 8)
            .attr("y", 18)
            .attr("fill", "#333333")
            .attr("font-size", "12px")
            .html(d => d)

    }, []);

    return (
        <div className='horizontal-bar-chart-container' ref={wrapperRef}>
            <svg
                id={value}
                // width={width}
                height={barHeight}
                className="prograss-svg"
                viewBox='0 0 490 20'
            >
                <g className="budget-bar-group">
                    <rect className="remaining-portion" x="0" y="0" height={barHeight} rx="0" ry="0" opacity="0.2" fill='none' />
                </g>
                <g
                    ref={svgRef}
                    className="expenditure-bar-group"
                >
                </g>
            </svg>
        </div>
    )
}


const useResizeObserver = (ref) => {
    const [dimensions, setDimensions] = useState(null);
    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                setDimensions(entry.contentRect);
            });
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);
    return dimensions;
};

export default HorizontalProgressBarChartForTable;