import { useRef, useLayoutEffect } from 'react';

import * as d3 from 'd3';
import { set, nest, json, mouse, bisect } from 'd3v4';
import './MultiGroupLineChart.css';


import { competitiveIntellingenceLinechartData, competitiveIntelligenceAgGRP, advertisersForCompetitiveIntelligence } from '../../ExecutiveSummaryTab/Customer/data';
import { useEffect } from 'react';
import { useState } from 'react';

const MultiGroupLineChart = (props) => {

    const { data, isShowForAdvertisers, selectedSelectOptions } = props;

    // console.log('isShowForAdvertisers', isShowForAdvertisers);
    console.log('selectedSelectOptions', selectedSelectOptions);

    const svgRef = useRef();
    let preparedDataArrayForNewData = prepareDataForMultigroupLineChart(isShowForAdvertisers ? advertisersForCompetitiveIntelligence : competitiveIntelligenceAgGRP, selectedSelectOptions);



    console.log('preparedDataArrayForNewData', preparedDataArrayForNewData);

    useLayoutEffect(() => {

        const margin = { top: 20, right: 20, bottom: 20, left: 20 };
        const width = 1200 - margin.left - margin.right;
        const height = 500 - margin.top - margin.bottom;

        d3.select(svgRef.current).selectAll("*").remove();

        const svg = d3.select(svgRef.current)
            .attr("viewBox", `0 0 1200 350`)
            .append("g")
            .attr("transform", `translate(60,20)`)

        const xScale = d3.scaleTime()
            .domain(d3.extent(preparedDataArrayForNewData.currentYear[0].values, d => d.xAxisDomain))
            .range([0, 1100]);

        let xAxisDomains = preparedDataArrayForNewData.currentYear[0].values.map(element => xScale(element.xAxisDomain));

        const yScale = d3.scaleLinear()
            .domain(d3.extent(isShowForAdvertisers ? advertisersForCompetitiveIntelligence : competitiveIntelligenceAgGRP, d => +(d.value)))
            .range([300, 0]);

        // let previousPushValue;
        // let distinctNetworks = competitiveIntellingenceLinechartData.filter((obj, index) => {
        //     let pushValue;
        //     if (index === 0) {
        //         pushValue = obj.network
        //         previousPushValue = obj.network
        //     } else if (previousPushValue !== obj.network) {
        //         pushValue = obj.network;
        //         previousPushValue = obj.network;
        //     }
        //     return pushValue;
        // }).map((obj, index) => obj.network)

        // console.log('arr', arr);

        let colorScale = d3.scaleOrdinal()
            .domain(["Sun", "Viacom", "Star Network Ent", "ZEEL", "Sony Network Ent"])
            .range(['#9B181E', '#59CC54', '#FF8C00', '#402177', '#28282A']);

        svg.append("g")
            .attr("transform", `translate(0,300)`)
            .call(d3.axisBottom(xScale)
                .tickFormat(d => {
                    const date = new Date(d)
                    const year = date.getFullYear();
                    const month = date.toLocaleString('default', { month: 'short' });
                    const formattedDate = `${year}-${month}`;
                    return formattedDate
                }))
            .attr('class', 'multigroup-line-chart-xaxis')

        svg.append("g")
            .call(d3.axisLeft(yScale))
            .attr("transform", `translate(0,0)`)
            .attr('class', 'multigroup-line-chart-yaxis');

        console.log("preparedDataArrayForNewData---", preparedDataArrayForNewData);

        svg.selectAll(".line")
            .data(preparedDataArrayForNewData.currentYear)
            .enter()
            .append("path")
            .attr("fill", "none")
            .attr("stroke", function (d) { return colorScale(d.key) })
            .attr("stroke-width", 1)
            .attr("d", function (d) {
                return d3.line()
                    .x(function (d) { return xScale(d.xAxisDomain); })
                    .y(function (d) { return yScale(+d.value); })
                    (d.values)
            });

        svg.selectAll(".line")
            .data(preparedDataArrayForNewData.lastYearData)
            .enter()
            .append("path")
            .attr("fill", "none")
            .classed("trend-line", true)
            .attr("stroke", function (d) { return colorScale(d.key) })
            .attr("stroke-width", 1)
            .attr("stroke-dasharray", 5)
            .attr("d", function (d) {
                return d3.line()
                    .x(function (d) { return xScale(d.xAxisDomain); })
                    .y(function (d) { return yScale(+d.value); })
                    (d.values)
            })

        var focus = svg.append('g')
            .attr('class', 'focus')
            .style('visibility', 'hidden');

        focus.append('line')
            .attr('class', 'x-hover-line hover-line')
            .attr('y1', 0)
            .attr('y2', 300)

        const tooltip = d3.select(".multi-group-line-chart-container")
            .append("div")
            .style('visibility', 'hidden')
            .classed('multi-group-line-chart-tooltip', true);

        tooltip.selectAll("*").remove();

        function mouseover() {
            // focus.style("display", null);
            d3.selectAll('.points text').style("display", null);
            focus.style("visibility", "visible");
        }
        function mouseout() {
            // focus.style("display", "none");
            d3.selectAll('.points text').style("display", "none");
            tooltip.style('visibility', 'hidden');
            focus.style("visibility", "hidden");
        }

        d3.select(svgRef.current)
            .on("mousemove", (event) => {
                let i = d3.bisect(xAxisDomains, d3.pointer(event)[0], 1)
                focus.select('line').attr("x1", xAxisDomains[i - 1]);
                focus.select('line').attr("x2", xAxisDomains[i - 1]);
                tooltip
                    .style('visibility', 'visible')
                    .html(
                        ` <div class='multigroup-line-chart-content-container'>
                            <div class='multigroup-line-chart-first-line'>
                                <p class='multigroup-line-chart-content-column-title'>${formateDate(preparedDataArrayForNewData.currentYear[0].values[i - 1].date)}</p>
                                <div class='multigroup-line-chart-first-line-column-content'>
                                    <div class='multigroup-line-chart-first-line-column-legend' style="background-color:${colorScale("ZEEL")};">&nbsp;</div>
                                    <p class='multigroup-line-chart-content-column-content'>ZEE</p>
                                </div>
                                <div class='multigroup-line-chart-first-line-column-content'>
                                    <div class='multigroup-line-chart-first-line-column-legend' style="background-color:${colorScale("Star Network Ent")};">&nbsp;</div>
                                    <p class='multigroup-line-chart-content-column-content'>Star</p>
                                </div>
                                <div class='multigroup-line-chart-first-line-column-content'>
                                    <div class='multigroup-line-chart-first-line-column-legend' style="background-color:${colorScale("Viacom")};">&nbsp;</div>
                                    <p class='multigroup-line-chart-content-column-content'>Viacom</p>
                                </div>
                                <div class='multigroup-line-chart-first-line-column-content'>
                                    <div class='multigroup-line-chart-first-line-column-legend' style="background-color:${colorScale("Sony Network Ent")};">&nbsp;</div>
                                    <p class='multigroup-line-chart-content-column-content'>Sony</p>
                                </div>
                                <div class='multigroup-line-chart-first-line-column-content'>
                                    <div class='multigroup-line-chart-first-line-column-legend' style="background-color:${colorScale("Sun")};">&nbsp;</div>
                                    <p class='multigroup-line-chart-content-column-content'>Sun</p>
                                </div>
                            </div>
                            <div class='multigroup-line-chart-content'>
                                <p>Current</p>
                                <div class='multigroup-line-chart-first-line-column-content'>
                                <p class='multigroup-line-chart-content-column-content'>${getPeriodValueForTooltip('ZEEL', preparedDataArrayForNewData.currentYear, i)}</p>
                                </div>
                                <div class='multigroup-line-chart-first-line-column-content'>
                                <p class='multigroup-line-chart-content-column-content'>${getPeriodValueForTooltip('Star Network Ent', preparedDataArrayForNewData.currentYear, i)}
                                </p>
                                </div>
                                <div class='multigroup-line-chart-first-line-column-content'>
                                <p class='multigroup-line-chart-content-column-content'>${getPeriodValueForTooltip('Viacom', preparedDataArrayForNewData.currentYear, i)}</p>
                                </div>
                                <div class='multigroup-line-chart-first-line-column-content'>
                                <p class='multigroup-line-chart-content-column-content'>${getPeriodValueForTooltip('Sony Network Ent', preparedDataArrayForNewData.currentYear, i)}</p>
                                </div>
                                <div class='multigroup-line-chart-first-line-column-content'>
                                <p class='multigroup-line-chart-content-column-content'>${getPeriodValueForTooltip('Sun', preparedDataArrayForNewData.currentYear, i)}</p>
                                </div>
                                
                            </div>
                            <div class='multigroup-line-chart-content'>
                                <p>Last</p>
                                <div class='multigroup-line-chart-first-line-column-content'>
                                <p class='multigroup-line-chart-content-column-content'>${getPeriodValueForTooltip('ZEEL', preparedDataArrayForNewData.lastYearData, i)}</p>
                                </div>
                                <div class='multigroup-line-chart-first-line-column-content'>
                                <p class='multigroup-line-chart-content-column-content'>${getPeriodValueForTooltip('Star Network Ent', preparedDataArrayForNewData.lastYearData, i)}
                                </p>
                                </div>
                                <div class='multigroup-line-chart-first-line-column-content'>
                                <p class='multigroup-line-chart-content-column-content'>${getPeriodValueForTooltip('Viacom', preparedDataArrayForNewData.lastYearData, i)}</p>
                                </div>
                                <div class='multigroup-line-chart-first-line-column-content'>
                                <p class='multigroup-line-chart-content-column-content'>${getPeriodValueForTooltip('Sony Network Ent', preparedDataArrayForNewData.lastYearData, i)}</p>
                                </div>
                                <div class='multigroup-line-chart-first-line-column-content'>
                                <p class='multigroup-line-chart-content-column-content'>${getPeriodValueForTooltip('Sun', preparedDataArrayForNewData.lastYearData, i)}</p>
                                </div>
                            </div>
                        </div>`
                    )
                    .style("left", xAxisDomains[i] + "px")
                    .style("top", 250 + "px");
            })
            .on("mouseover", mouseover)
            .on("mouseout", mouseout);

        svg.select('.multigroup-line-chart-xaxis')
            .selectAll('line')
            .attr('y2', -296)
            .attr("stroke", "#D8D8D8")
            .attr("stroke-width", "0.3")
            .attr("opacity", "1")

        svg.select('.multigroup-line-chart-xaxis')
            .append('line')
            .attr('y2', -320)
            .attr('x1', 0)
            .attr("stroke", "#D8D8D8")
            .attr("stroke-width", "0.3")
            .attr("opacity", "1")

        svg.select('.multigroup-line-chart-yaxis')
            .selectAll('line')
            .attr('x2', 1100)
            .attr('x1', -40)
            .attr("stroke", "#D8D8D8")
            .attr("stroke-width", "0.3")
            .attr("stroke-dasharray", 2)
            .attr("opacity", "1")

        svg.select('.multigroup-line-chart-yaxis')
            .selectAll('text')
            .attr('x', -10)
            .attr('y', -9)
            .attr("opacity", "1")

        // svg
        //     .append('text')
        //     .classed('multigroup-line-chart-yaxis-note', true)
        //     .attr('x', 10)
        //     .attr('y', -5)
        //     .attr("fill", "#808080")
        //     .text("Value in Mn.");

    }, [isShowForAdvertisers, selectedSelectOptions]);

    return (
        <div className='multi-group-line-chart-container'>
            <svg ref={svgRef}></svg>
        </div>
    )
}


function prepareDataForMultigroupLineChart(data, selectedSelectOptions) {

    let currentYearFilter = [], lastYearFilter = [], isAll = false;


    for (let item in selectedSelectOptions) {
        // console.log('item.slice(-1, -3)', item.slice(item.length - 3));
        if (selectedSelectOptions['All']) {
            isAll = true;
        } else if (item.slice(item.length - 2) === "CY" && selectedSelectOptions[item] === true) {
            currentYearFilter.push(item.slice(0, item.length - 5));
            // console.log('selectedSelectOptions[item]', selectedSelectOptions[item]);
        } else if (item.slice(item.length - 2) === "LY" && selectedSelectOptions[item] === true) {
            lastYearFilter.push(item.slice(0, item.length - 5))
        }
    }

    console.log(currentYearFilter, lastYearFilter);

    // data = JSON.parse(data);

    var parseDate = d3.timeParse("%Y-%m");
    data.forEach(function (d) {
        d.date = parseDate(d.year + "-" + d.month);
    });

    // console.log('data after date parsing', data);

    data.sort(function (a, b) {
        return a.date - b.date;
    });

    // console.log('data after date sorting', data);

    var networks = set(data.map(function (d) { return d.Network; })).values();

    // console.log('networks', networks);

    var currentDate = new Date();
    var lastYearDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth());
    var currentData = data.filter(function (d) {
        return d.date >= lastYearDate && d.date <= currentDate;
    });
    var lastYearData = data.filter(function (d) {
        return d.date >= new Date(currentDate.getFullYear() - 2, currentDate.getMonth()) && d.date <= new Date(currentDate.getFullYear() - 1, currentDate.getMonth() - 1);
    });

    var currentDataByNetwork = nest()
        .key(function (d) { return d.Network; })
        .entries(currentData)
        .map(element => {
            if (isAll) {
                let values = element.values.map(item => {
                    // console.log('item', item);
                    item = {
                        ...item,
                        xAxisDomain: item.date,
                    }
                    return item;
                })
                element = {
                    ...element,
                    values: values,
                }

                return {
                    key: element.key,
                    values,
                };
            } else {
                currentYearFilter.forEach(i => {
                    let values = element.values.map(item => {
                        // console.log('item', item);
                        if (i === item.Network) {
                            item = {
                                ...item,
                                xAxisDomain: item.date,
                            }
                            return item;
                        } else return
                    })
                    if (values) {
                        element = {
                            ...element,
                            values: values,
                        }
                        return {
                            key: element.key,
                            values,
                        };
                    }
                })
            }

        })

    // console.log('resultCurrentDataByNetwork', currentDataByNetwork);

    var lastYearDataByNetwork = nest()
        .key(function (d) { return d.Network; })
        .entries(lastYearData)
        .map(element => {
            let values = element.values.map(item => {
                let month = item.date.getMonth() + 1;
                let year = item.date.getFullYear() + 1;
                let updatedDate = new Date(`${year}-${month}`)
                item = {
                    ...item,
                    xAxisDomain: updatedDate
                }
                return item;
            })
            element = {
                ...element,
                values: values,
            }

            return {
                key: element.key,
                values,
            };
        })

    // console.log('currentDataByNetwork', currentDataByNetwork, 'lastYearDataByNetwork', lastYearDataByNetwork);

    // let today = new Date();
    // let yearValueForSecondPreviousYear = today.getFullYear() - 2;
    // console.log('yearValueForSecondPreviousYear', yearValueForSecondPreviousYear);
    // let monthValueForSecondPreviousYear = today.getMonth() + 2;
    // let currentYear = today.getFullYear();
    // let currentMonth = today.getMonth() + 1;

    // let preparedArray = array.map((obj, index) => {
    //     // let isPrevious = false;
    //     let network = obj.network;
    //     // console.log(new Date(obj.yearMonth).getFullYear());
    //     console.log(' new Date(obj.yearMonth).getFullYear >= yearValueForSecondPreviousYear', new Date(obj.yearMonth).getFullYear() >= yearValueForSecondPreviousYear && new Date(obj.yearMonth).getFullYear() <= currentYear - 1)

    //     if (
    //         new Date(obj.yearMonth).getFullYear >= yearValueForSecondPreviousYear &&
    //         // new Date(obj.yearMonth).getFullYear <= currentYear - 1 &&
    //         new Date(obj.yearMonth).getMonth >= monthValueForSecondPreviousYear &&
    //         new Date(obj.yearMonth).getMonth <= currentMonth
    //     ) {
    //         obj = {
    //             ...obj,
    //             isPrevious: true,
    //             network: network + 'previous'
    //         }
    //     } else {
    //         obj = {
    //             ...obj,
    //             isPrevious: false,
    //             network: network
    //         }
    //     }
    //     return obj;
    // }
    // )

    // console.log('preparedArray', preparedArray);


    return {
        currentYear: currentDataByNetwork,
        lastYearData: lastYearDataByNetwork,
    }

}

function formateDate(d) {
    const date = new Date(d)
    // const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'long' });
    const formattedDate = `${month}`;
    return formattedDate
}

function getPeriodValueForTooltip(key, periodArray, i) {
    return parseInt(periodArray.find(element => element.key === key).values[i - 1].value)
}


export default MultiGroupLineChart;