import { useRef } from 'react';

import * as d3 from 'd3';

import { sampleDataForStackedAreaChart, keys } from './data';
import HorizontalProgressBarChartForTable from '../HorizontalProgressBarChartForTable/HorizontalProgressBarChartForTable';

import './StackedAreaChart.css';
import { useLayoutEffect } from 'react';

const StackedAreaChart = (props) => {

    const svgRef = useRef();

    useLayoutEffect(() => {

        const svg = d3
            .select(svgRef.current)
            .attr("viewBox", "0 0 600 205")
            .attr('preserveAspectRatio', 'xMinYMin')
            .append("g")
            .attr("transform",
                "translate(" + 20 + "," + 35 + ")");

        const color = d3
            .scaleOrdinal()
            .domain(keys)
            .range(['#FF8B6C', '#945ED2', '#FFBF65', '#B4229D']);

        const stackedData = d3.stack()
            .keys(keys)(sampleDataForStackedAreaChart);

        // console.log('stackedData', stackedData);

        const xScale = d3
            .scaleBand()
            .domain(sampleDataForStackedAreaChart.map(d => d.week))
            .range([0, 600]);

        const xAxis = d3.axisBottom(xScale).tickSize(0)

        const yScale = d3.scaleLinear()
            .domain([0, 18])
            .range([150, 0]);

        const yAxis = d3.axisLeft(yScale)
            .ticks(6)
            .tickValues([0, 3, 6, 9, 12, 15, 18])
            .tickSize(-550)

        const area = d3.area()
            .x(d => xScale(d.data.week))
            .y0(d => yScale(d[0]))
            .y1(d => yScale(d[1]))
            .curve(d3.curveNatural);

        svg
            .selectAll("layers")
            .data(stackedData)
            .enter()
            .append('path')
            .style("fill", d => color(d.key))
            .attr('d', area);

        svg
            .append("g")
            .call(xAxis)
            .classed('stacked-area-chart-x-axis', true)
            .attr("transform", "translate(-25," + 150 + ")");

        svg.append("text")
            .attr("text-anchor", "middle")
            .attr("x", 280)
            .attr("y", 170)
            .classed('stacked-area-chart-x-axis-label', true)
            .attr('fill', "#808080")
            .attr('margin-top', '20')
            .text("Week");

        svg.append("text")
            .attr("text-anchor", "start")
            .attr("x", -20)
            .attr("y", -20)
            .classed('stacked-area-chart-title', true)
            .attr('fill', "#333333")
            .text("Title");

        svg.append("text")
            .attr("text-anchor", "middle")
            .attr("x", 40)
            .attr("y", -3)
            .classed('stacked-area-chart-x-axis-label', true)
            .attr('fill', "#808080")
            // .attr('margin-top', '20')
            .text("value in xxxxx");

        svg
            .selectAll("stacked-area-chart-legend-rect")
            .data(keys)
            .enter()
            .append("rect")
            .attr('x', (d, i) => 220 + i * 90)
            .attr('y', -30)
            .attr('width', 10)
            .attr('height', 10)
            .style('fill', (d, i) => color(keys[3 - i]));

        svg
            .selectAll("stacked-area-chart-legend-text")
            .data(keys)
            .enter()
            .append("text")
            .classed('stacked-area-chart-legend-text', true)
            .attr('x', (d, i) => 235 + i * 90)
            .attr('y', -25)
            .attr('width', 10)
            .attr('height', 10)
            .style('fill', (d, i) => color(keys[3 - i]))
            .text((d, i) => {
                if (i == 0) {
                    return 'Attempts'
                } else if (i == 1) {
                    return 'Code Served'
                } else if (i == 2) {
                    return 'Ad Impressions'
                } else if (i == 3) {
                    return 'Vast Error'
                }
            })
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")


        svg
            .append("g")
            .call(yAxis)
            .classed('stacked-area-chart-y-axis', true);

        svg
            .select('.stacked-area-chart-y-axis')
            .selectAll('.tick')
            .select('line')
            .attr("stroke", "#D8D8D8")
            .attr('x1', '-18')
            .attr("stroke-width", "0.8")
            .attr("stroke-dasharray", "3.5");

        svg
            .select('.stacked-area-chart-y-axis')
            .selectAll('.tick')
            .select('text')
            .attr('dy', '-3')
            .attr('dx', '-3')

        svg
            .append('line')
            .attr("y1", -10)
            .attr("y2", 150)
            .attr("x1", 0)
            .attr("x2", 0)
            .attr("fill", "none")
            .attr("stroke", "#D8D8D8")
            .attr("stroke-width", "1");

        svg
            .append('line')
            .attr("y1", 150)
            .attr("y2", 150)
            .attr("x1", -15)
            .attr("x2", 550)
            .attr("fill", "none")
            .attr("stroke", "#D8D8D8")
            .attr("stroke-width", "1");


        // svg.on("mousemove", (event) => console.log(event))

    }, []);

    return (
        <div className='stacked-area-chart-container'>
                <svg ref={svgRef} className='stacked-area-chart-svg'></svg>
            {/* <PreMidRollProductAdvertisementFunnelMetric /> */}
        </div>
    );
}

export default StackedAreaChart;