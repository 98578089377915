import axios from "axios";

export default {

    getValueOverallKPI: async function () {
        try {
            const response = await axios.get('/api/executive-summary-content-ott/overall');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getValueOriginalKPI: async function () {
        try {
            const response = await axios.get('/api/executive-summary-content-ott/original');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getValueMoviesKPI: async function () {
        try {
            const response = await axios.get('/api/executive-summary-content-ott/movies');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getValueTvShowsKPI: async function () {
        try {
            const response = await axios.get('/api/executive-summary-content-ott/tv-shows');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    
} 
