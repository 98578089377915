import React, { Component } from 'react';
import './PlotChart.css';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

class   PlotChart extends Component {
  
  componentDidMount() {

    this.divRef = React.createRef();

    var root = am5.Root.new("chartdiva");


    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);


    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      // wheelX: "panX",
      // wheelY: "zoomX",
      pinchZoomX: true
    }));

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursory = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursory.lineY.set("visible", false);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 0.5
    });
    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: 0,
    });

    xRenderer.grid.template.setAll({
      visible: true,
      location: 1
    })

    var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0,
      categoryField: "chennal",
      renderer: xRenderer,
      visible: true,
      // bullet: function (root, axis, dataItem) {
      //   return am5xy.AxisBullet.new(root, {
      //     // location: 0.5,
      //     sprite: am5.Circle.new(root, {
      //       radius: 5,
      //       // fill: dataItem.dataContext.close >= dataItem.dataContext.open ?
      //       //   am5.color("#00C48C")
      //       //   :
      //       //   am5.color("#FF647C")
      //       fill: "#FF647C"
      //     })
      //   });
      // }
      //   tooltip: am5.Tooltip.new(root, {})
    }));

    var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      visible: true,
      maxDeviation: 0,
      renderer: am5xy.AxisRendererY.new(root, {})
    }));

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    var series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Series 1",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "close",
      openValueYField: "open",
      categoryXField: "chennal",
      // fillField: "#808080",
      // stroke: "blue",
      // templateField: "bulletSettings",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{chennal} :  CW {openValueY} - LW {valueY}"
      })
    }));

    series.columns.template.setAll({
      width: 0.8,
    });


    series.columns.template.adapters.add("fill", function (fill, target) {
      if (target._dataItem.dataContext.close >= target._dataItem.dataContext.open) {
        return am5.color("#FF647C");
      }
      else {
        return am5.color("#00C48C");
      }
    });

    series.columns.template.adapters.add("stroke", function (fill, target) {
      if (target._dataItem.dataContext.close >= target._dataItem.dataContext.open) {
        return am5.color("#FF647C");
      }
      else {
        return am5.color("#00C48C");
      }
    });


    // series.adapters.add("fill", (fill, target) => {
    // return "#000000"
    // });

    // console.log('series.get("fill")', series.get("valueYField"))

    series.bullets.push(function (root, dataItem) {

      // console.log('dataItem', dataItem);

      return am5.Bullet.new(root, {
        locationY: 0,
        sprite: am5.Circle.new(root, {
          radius: 5,
          // fill: 'green',
          templateField: "bulletSettings"
          // fill: data[0].fill
        }, bulletTemplate)
      })
    })

    // series.bulletsContainer.template.adapters.add("fill", function (fill, target) {
    //   if (target._dataItem.dataContext.close >= target._dataItem.dataContext.open) {
    //     return am5.color("#00C48C");
    //   }
    //   else {
    //     return am5.color("#FF647C");
    //   }
    // })

    var bulletTemplate = am5.Template.new({
      // fill: "black",
      // stroke: "pink"
    })

    var nextColor = chart.get("colors").getIndex(1);

    // var data = this.props.data;

    series.bullets.push(function () {
      // console.log('push')
      return am5.Bullet.new(root, {
        locationY: 1,
        sprite: am5.Circle.new(root, {
          radius: 5,
          // fill: 'green',
          templateField: "bulletSettings",

          // fill: data[0].fill
        }, bulletTemplate)
      })
    })


    // Set data
    var data = this.props.data;

    console.log('DATA', data)
    xAxis.data.setAll(data);
    series.data.setAll(data);


    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);
    chart.appear(1000, 100);

  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  render() {
    return (
      <>
        <div id="chartdiva1"> </div>
        <div id="chartdiva2"> </div>
        <div id="chartdiva3"> </div>
        <div id="hideLogo"> </div>

        <div id="chartdiva" ref={this.divRef} style={{ width: "100%", height: "500px" }}></div>
      </>
    );
  }
}

export default PlotChart;