import React from "react";
import { useState, useEffect } from "react";
import Happiness from "./ExecutiveSummaryProductNewCard/Happiness";
import Engagement from "./ExecutiveSummaryProductNewCard/Engagement";
import Adoption from "./ExecutiveSummaryProductNewCard/Adoption";
import Retention from "./ExecutiveSummaryProductNewCard/Retention";
import TaskSuccess from "./ExecutiveSummaryProductNewCard/TaskSuccess";
import ProductCardOverAllChart from './ExecutiveSummaryProductNewCard/ProductCardOverAllChart'
import data from './ExecutiveSummaryProductNewCard/ExecutiveSummaryData';
import loader from '../ExecutiveSummaryContent/LoderSpinner';

import web from "../../../Components/Images/productweb.png";
import ar from "../../../Components/Images/productar.png";
import avod from "../../../Components/Images/productavod.png";
import svod from "../../../Components/Images/productsvod.png";
import mr from "../../../Components/Images/productmr.png";
import apple from "../../../Components/Images/apple.png";
import android from "../../../Components/Images/android.png";


import "./ExecutiveSummaryProductNew.css";
import { From } from "devextreme-react/autocomplete";
import ExecutiveSummaryServicesProduct from "../../../Services/ExecutiveSummaryServices/ExecutiveSummaryServicesProduct";


const ExecutiveSummaryProductNew = () => {
  const [overData, setoverData] = useState(false)
  const [OverallDataName, setOverallDataName] = useState("")
  const [ProductData, setProductData] = useState([])

  //set filter value of each card
  const [AppRating, setAppRating] = useState([])
  const [NPS, setNPS] = useState([])
  const [complaints, setComplaints] = useState([])
  const [watchtime, setwatchtime] = useState([])
  const [bounce, setbounce] = useState([])
  const [installs, setinstalls] = useState([])
  const [registrations, setregistrations] = useState([])
  const [subs, setsubs] = useState([])
  const [retention, setretention] = useState([])
  const [sessions, setsessions] = useState([])
  const [crashfreerate, setcrashfreerate] = useState([])
  const [loadtime, setloadtime] = useState([])
  const [videoexperience, setvideoexperience] = useState([])
  const [videoexperienceSVOD, setvideoexperienceSVOD] = useState([])
  const [videoexperienceAVOD, setvideoexperienceAVOD] = useState([])
  // cpnst [ , set ] = useState([])
  // cpnst [ , set ] = useState([])

  const runsetOverall = (e) => {
    console.log(e)
  }
  const handleChildData = (data) => {
    console.log("button clickes");
    setoverData(true)

  };

  const onClickCloseButton = (value) => {
    setoverData(value)
  }

  const LoadProductData = async () => {
  setProductData(data.data)
    // const result = await ExecutiveSummaryServicesProduct.productData();
    // setProductData(result)

  }

  const [yesterday, setyesterday] = useState([])
  const [thirtyDaysAgoDate, setthirtyDaysAgoDate] = useState([])

  const [goodVideoExperience, setgoodVideoExperience] = useState([])
  const [AVOD, setAVOD] = useState([])
  const [SVOD, setSVOD] = useState([])

  useEffect(() => {

    if(ProductData.length > 0 ){
    const filterUserbounce = ProductData.filter(item => item.metric_name === ("good video experience"))
    setgoodVideoExperience(filterUserbounce)


    const filterAVOD = ProductData.filter(item => item.metric_name === ("avod good video experience"))
    setAVOD(filterAVOD)


    const filterSVOD = ProductData.filter(item => item.metric_name === ("svod good video experience"))
    setSVOD(filterSVOD)
    console.log(filterSVOD, "filterSVOD-----------", filterAVOD, "filterAVOD-----------------------", filterUserbounce, "filterUserbounce----------------");
    }

  }, [ProductData])


  useEffect(() => {
    const result = ExecutiveSummaryServicesProduct.productData();
    setProductData(result)
    LoadProductData()


    // Get yesterday's date
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const formattedDates = `${yesterday.getDate().toString().padStart(2, '0')}-${(yesterday.getMonth() + 1).toString().padStart(2, '0')}-${yesterday.getFullYear()}`;
    setyesterday(formattedDates)

    // Get yesterday's date minus 30 days
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 31);
    const formattedDate = `${thirtyDaysAgo.getDate().toString().padStart(2, '0')}-${(thirtyDaysAgo.getMonth() + 1).toString().padStart(2, '0')}-${thirtyDaysAgo.getFullYear()}`;
    setthirtyDaysAgoDate(formattedDate)


  }, [])

  if (SVOD.length > 0) {
    console.log(goodVideoExperience, "goodvideoexperience==----------------");
    console.log(AVOD, "AVOD===-------------------");
    console.log(SVOD, "SVOD==------------------------");
  }




  return (
    <div className="ExecutiveSummaryProductNew_main_div">
      {overData === true && goodVideoExperience.length > 0 && AVOD.length > 0 && SVOD.length > 0 && <div className="product-popup-overlay-syn">
        <ProductCardOverAllChart onClickCloseButton={onClickCloseButton} OverallDataName={OverallDataName}
          goodVideoExperience={goodVideoExperience}
          AVOD={AVOD}
          SVOD={SVOD}
        />
      </div>}

      <div className="ExecutiveSummaryProductNew_data_div">

        <div className="ExecutiveSummaryProductNew_data_div_text">All metrics are computed for the period between <i>{yesterday}</i> & <i> {thirtyDaysAgoDate}</i></div>

        <p className="ExecutiveSummaryProductNew_data_index">
          <span className='ExecutiveSummaryProductNew_data_index_img'>
            <img src={android} className="ExecutiveSummaryProductNew_data_index_img_size" />
          </span >
          Android
          <span className='ExecutiveSummaryProductNew_data_index_img'>
            <img src={apple} className="ExecutiveSummaryProductNew_data_index_img_size" />
          </span> IOS
          <span className='ExecutiveSummaryProductNew_data_index_img'>
            <img src={web} className="ExecutiveSummaryProductNew_data_index_img_size" />
          </span>
          Web
          <span>
            <svg style={{ marginRight: '6px', marginLeft: '14px' }} width="16" height="16" viewBox="0 0 16 12" fill="#402177" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.6067 0.85H1.39326C1.0902 0.85 0.85 1.0902 0.85 1.39326V8.50349C0.85 8.80656 1.0902 9.04676 1.39326 9.04676H6.43436V9.98942H4.94832C4.64526 9.98942 4.40506 10.2296 4.40506 10.5327C4.40506 10.8357 4.64526 11.0759 4.94832 11.0759H11.0358C11.3388 11.0759 11.579 10.8357 11.579 10.5327C11.579 10.2296 11.3388 9.98942 11.0358 9.98942H9.56564V9.04676H14.6067C14.9098 9.04676 15.15 8.80656 15.15 8.50349V1.39326C15.15 1.09027 14.9099 0.850119 14.607 0.85C14.6069 0.85 14.6068 0.85 14.6067 0.85ZM8.47933 10.0052H7.52097V9.06253L8.47933 9.06245V10.0052ZM14.0637 7.9759H1.93653V1.93647H14.0637V7.9759Z" fill="#402177" stroke="#402177" stroke-width="0.3" />
            </svg>
          </span>Connected Device
          <span className='ExecutiveSummaryProductNew_data_index_img'>
            <img src={avod} className="ExecutiveSummaryProductNew_data_index_img_size" />
          </span>

          AVOD

          <span className='ExecutiveSummaryProductNew_data_index_img'>
            <img src={svod} className="ExecutiveSummaryProductNew_data_index_img_size" />
          </span>
          SVOD
          <span className="ExecutiveSummaryProductNew_data_index_img">
            <img src={ar} className="ExecutiveSummaryProductNew_data_index_img_size" />
          </span>
          AR
          <span className="ExecutiveSummaryProductNew_data_index_img">
            <img src={mr} className="ExecutiveSummaryProductNew_data_index_img_size" />
          </span>
          MR
        </p>

        {ProductData.length > 0 &&
          <>
            <div className="ExecutiveSummaryProductNew_data_child">
              <Happiness ProductData={ProductData} />
            </div>
            <div className="ExecutiveSummaryProductNew_data_child">
              <Engagement ProductData={ProductData} />
            </div>
            <div className="ExecutiveSummaryProductNew_data_child">
              <Adoption ProductData={ProductData} />
            </div>
            <div className="ExecutiveSummaryProductNew_data_child">
              <Retention ProductData={ProductData} />
            </div>
            <div className="ExecutiveSummaryProductNew_data_child">
              <TaskSuccess ProductData={ProductData} onButtonClick={handleChildData} />
            </div>
          </>
        }

        {/* { complaints.length < 0  &&
          <>
            <loader/>
          </>

        } */}
      </div>
    </div>
  );
};

export default ExecutiveSummaryProductNew;
