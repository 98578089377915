import React, { useEffect, useRef } from "react";
import './ContentLinearViewershipLineChart.css'
import * as d3 from 'd3';

const ContentLinearViewershipLineChartKids = (props) => {
    const ViewershipLineChart = useRef();
    const {chartLabel, backColor, chartData } = props;

    //min value findout
    const chartDataArray = [chartData[0].CW_AVG_GRP,chartData[0].LW_AVG_GRP,chartData[0].L4W_AVG_GRP,chartData[0].L13W_AVG_GRP,chartData[0].L52W_AVG_GRP]
    const minValue = Math.min(...chartDataArray)
    console.log(minValue,"minvalue of SouthRegionalGECs");
    
    const sales = [
        {date: new Date('2009-01-01'), fruit: "", sales: 100},
        {date: new Date('2010-01-01'), fruit: "", sales: 200},
        {date: new Date('2011-01-01'), fruit: "", sales: 300},
        {date: new Date('2012-01-01'), fruit: "", sales: 200},
        {date: new Date('2013-01-01'), fruit: "", sales: 100},
      ]

      function findMinimumSales(sales) {
        let minSales = sales[0].sales;
        for (let i = 1; i < sales.length; i++) {
          if (sales[i].sales < minSales) {
            minSales = sales[i].sales;
          }
        }
        return minSales;
      }

    useEffect(() => {
        InlineChart(sales, {
            x: d => d.date,
            y: d => d.sales,
            z: d => d.fruit,
            width: 600,
            height: 70
          })
    }, [])

    function InlineChart(data, {
        x = ([x]) => x, // given d in data, returns the (temporal) x-value
        y = ([, y]) => y, // given d in data, returns the (quantitative) y-value
        z = () => 1, // given d in data, returns the (categorical) z-value
        defined, // for gaps in data
        curve = d3.curveLinear, // method of interpolation between points
        marginTop = 30, // top margin, in pixels
        marginRight = 50, // right margin, in pixels
        marginBottom = 30, // bottom margin, in pixels
        marginLeft = 30, // left margin, in pixels
        width = 640, // outer width, in pixels
        height = 400, // outer height, in pixels
        xType = d3.scaleUtc, // type of x-scale
        xDomain, // [xmin, xmax]
        xRange = [marginLeft, width - marginRight], // [left, right]
        yType = d3.scaleLinear, // type of y-scale
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        zDomain, // array of z-values
        yFormat, // a format specifier string for the labels
        colors = d3.schemeCategory10, // stroke color of line
        strokeLinecap = "square", // stroke line cap of the line
        strokeLinejoin = "square", // stroke line join of the line
        strokeWidth = 1.5, // stroke width of line, in pixels
        strokeOpacity = 1, // stroke opacity of line
        halo = "red", // color of label halo 
        haloWidth = 6 // padding around the labels
      } = {}) {
        // Compute values.
        const X = d3.map(data, x);
        const Y = d3.map(data, y);
        const Z = d3.map(data, z);
        if (defined === undefined) defined = (d, i) => !isNaN(X[i]) && !isNaN(Y[i]);
        const D = d3.map(data, defined);
      
        // Compute default domains, and unique the z-domain.
        if (xDomain === undefined) xDomain = d3.extent(X);
        if (yDomain === undefined) yDomain = [findMinimumSales(sales), d3.max(Y)];
        if (zDomain === undefined) zDomain = Z;
        zDomain = new d3.InternSet(zDomain);
      
        // Omit any data not present in the z-domain.
        const I = d3.range(X.length).filter(i => zDomain.has(Z[i]));
      
        // Construct scales and axes.
        const xScale = xType(xDomain, xRange);
        const yScale = yType(yDomain, yRange);
        const color = d3.scaleOrdinal(zDomain, colors);
        const xAxis = d3.axisBottom(xScale).ticks(width / 80).tickSizeOuter(0);
      
        // Construct formats.
        yFormat = yScale.tickFormat(null, yFormat);
      
        // Construct a line generator.
        const line = d3.line()
            .defined(i => D[i])
            .curve(curve)
            .x(i => xScale(X[i]))
            .y(i => yScale(Y[i]));
      
        const svg = d3.select(ViewershipLineChart.current)
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [-25, 0, width+50, height])
            .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      
        // svg.append("g")
        //     .attr("transform", `translate(0,${height - marginBottom})`)
        //     .call(xAxis);
      
        const serie = svg.append("g")
          .selectAll("g")
          .data(d3.group(I, i => Z[i]))
          .join("g");
        
        serie.append("g")
            .attr("font-family", "GraphikRegular")
            .attr("font-size", 20)
            .attr("text-anchor", "middle")
            .attr("stroke-linejoin", "square")
            .attr("stroke-linecap", "square")
          .selectAll("text")
          .data(([, I]) => I)
          .join("text")
            .attr("dy", "0.35em")
            .attr("x", i => xScale(X[i]))
            .attr("y", i => yScale(Y[i]))
            .text(i => yFormat(Y[i]))
            .call(text => text
              .filter((_, j, I) => j === I.length - 1)
              .append("tspan")
                .attr("font-weight", "bold")
                .text(i => ` ${Z[i]}`))
            .call(text => text.clone(true))
            .attr("fill", "red")
            .attr("stroke", (d, i) => {
              if (i === 0) {
                if (chartData[0].CW_AVG_GRP > chartData[0].LW_AVG_GRP) {
                  return "#00C48C";
                } else {
                  return "#FF647C";
                }
              }
              if (i === 1) {
                return "#E1E4FC";
              }
              if (i === 2) {
                return "#E1E4FC";
              }
              if (i === 3) {
                return "#E1E4FC";
              }
              if (i === 4) {
                return "#E1E4FC";
              }
              if (i === 5) {
                return "#E1E4FC";
              }
            }).text(i => `-`)
            .attr("stroke-width", 110);

            const path = serie.append("path")
            .attr("fill", "none")
            .attr("stroke", '#402177')
            .attr("stroke-width", strokeWidth)
            .style("z-index", 10)
            // .attr("stroke-linecap", strokeLinecap)
            // .attr("stroke-linejoin", strokeLinejoin)
            .attr("stroke-opacity", strokeOpacity)
            .style("mix-blend-mode", "multiply")
            .attr("d", ([, I]) => line(I));

            serie.append("g")
            .attr("font-family", "GraphikRegular")
            .attr("font-size", 10)
            .attr("text-anchor", "middle")
            .attr("stroke-linejoin", "square")
            .attr("stroke-linecap", "square")
            
          .selectAll("text")
          .data(([, I]) => I)
          .join("text").classed('line-chart-viwer-summ', true)
            .attr("dy", "0.35em")
            .attr("x", i => xScale(X[i]))
            .attr("y", i => yScale(Y[i]))
            .text(i => yFormat(Y[i]))
            .call(text => text
              .filter((_, j, I) => j === I.length - 1)
              .append("tspan")
                .attr("font-weight", "bold")
                .text(i => ` ${Z[i]}`))
            .call(text => text.clone(true))
            .attr("fill", "red")
            .attr("stroke", (d, i) => {
              if (i === 0) {
                if (chartData[0].CW_AVG_GRP > chartData[0].LW_AVG_GRP) {
                  return "#00C48C";
                } else {
                  return "#FF647C";
                }
              }
              if (i === 1) {
                return "#E1E4FC";
              }
              if (i === 2) {
                return "#E1E4FC";
              }
              if (i === 3) {
                return "#E1E4FC";
              }
              if (i === 4) {
                return "#E1E4FC";
              }
              if (i === 5) {
                return "#E1E4FC";
              }
            }).text(i => `-`)
            .attr("stroke-width", 50);
      
        return Object.assign(svg.node(), {scales: {color}});
      }

    return(
        <div>
            <div className="executive-summ-lin-con-viewer-summary-line-label-and-line-chart-syn" style={{backgroundColor:backColor}}>
                <label className="exe-sum-linear-con-viewer-summ-line-chart-label-heading-syn">{chartLabel}</label>
                <svg className="exe-sum-linear-con-viewer-summ-line-chart-svg-syn" ref={ViewershipLineChart}></svg>
            </div>
            {/* <div className="exe-sum-linear-con-viewer-summ-line-chart-all-genre-boxs-syn">
                <label className="exe-sum-linear-con-viewer-summ-line-chart-label-green-box-syn"></label>
                <label className="exe-sum-linear-con-viewer-summ-line-chart-label-red-box-syn"></label>
                <label className="exe-sum-linear-con-viewer-summ-line-chart-label-green-box-syn"></label>
                <label className="exe-sum-linear-con-viewer-summ-line-chart-label-red-box-syn"></label>
                <label className="exe-sum-linear-con-viewer-summ-line-chart-label-green-box-syn"></label>
            </div> */}

            <div className=""></div>
                
        </div>
        
    )
}

export default ContentLinearViewershipLineChartKids;