import React from "react";
import "./ExecutiveSummaryContent.css"
import { useState, useEffect } from "react";
import BubbleChart from "../../Charts/BubbleChart/BubbleChart";
import ExecutiveSummaryContentLinear from "./ExecutiveSummaryContentLinear";
import ExecutiveSummaryContentOtt from "./ExecutiveSummaryContentOtt";
import ExecutiveSummaryServicesContent from "../../../Services/ExecutiveSummaryServices/ExecutiveSummaryServicesContent";
import LoderSpinner from "./LoderSpinner";
import LoderLinear from "./LoaderLinear"
import { a } from "react-spring";
// import LinearBarchart from "../../Charts/BubbleChart/LinearBarchart/LinearBarchart"  

const ExecutiveSummaryContent = () => {
  const [activeTab, setActiveTab] = useState('linear');
  const [filterSelectValueTarget, setFilterSelectValueTarget] = useState('15');
  
  
  const [loadingLinear, setLoadingLinear] = useState(true);
  function handleChildPropChange(newProp) {
   // setLoading(newProp);
  }


  //NETWORK SHARE CONST
  const [zeeCW, setzeeCW] = useState([])
  const [zeeLW, setzeeLW] = useState([])
  const [starCW, setstarCW] = useState([])
  const [starLW, setstarLW] = useState([])
  const [sunCW, setsunCW] = useState([])
  const [sunLW, setsunLW] = useState([])
  const [viacomCW, setviacomCW] = useState([])
  const [viacomLW, setviacomLW] = useState([])
  const [sonyCW, setsonyCW] = useState([])
  const [sonyLW, setsonyLW] = useState([])
  const [disneyCW, setdisneyCW] = useState([])
  const [disneyLW, setdisneyLW] = useState([])

  const [zeeCW15, setzeeCW15] = useState([])
  const [zeeLW15, setzeeLW15] = useState([])
  const [starCW15, setstarCW15] = useState([])
  const [starLW15, setstarLW15] = useState([])
  const [sunCW15, setsunCW15] = useState([])
  const [sunLW15, setsunLW15] = useState([])
  const [viacomCW15, setviacomCW15] = useState([])
  const [viacomLW15, setviacomLW15] = useState([])
  const [sonyCW15, setsonyCW15] = useState([])
  const [sonyLW15, setsonyLW15] = useState([])
  const [disneyCW15, setdisneyCW15] = useState([])
  const [disneyLW15, setdisneyLW15] = useState([])

  const [cWeekAndDate, setCWeekAndDate] = useState(null);

  const [networkShareData, setNetworkShareData] = useState([]);

    //Network share data
    const LoadNetworkShareData = async () => {

      //now call the api here
      // const ZEEResultCW = await ExecutiveSummaryServicesContent.zeeCW(filterSelectValueTarget)
      // const ZEEResultLW = await ExecutiveSummaryServicesContent.zeeLW(filterSelectValueTarget)
      // setzeeCW(ZEEResultCW[0])
      // setzeeLW(ZEEResultLW[0])

      // const starResult = await ExecutiveSummaryServicesContent.starCW(filterSelectValueTarget)
      // const starLWResult = await ExecutiveSummaryServicesContent.starLW(filterSelectValueTarget)
      // setstarCW(starResult[0])
      // setstarLW(starLWResult[0])

      // const sunCWResult = await ExecutiveSummaryServicesContent.sunCW(filterSelectValueTarget)
      // const sunLWResult = await ExecutiveSummaryServicesContent.sunLW(filterSelectValueTarget)
      // setsunCW(sunCWResult[0])
      // setsunLW(sunLWResult[0])

      // const viacomCWResult = await ExecutiveSummaryServicesContent.viacomCW(filterSelectValueTarget)
      // const viacomLWResult = await ExecutiveSummaryServicesContent.viacomLW(filterSelectValueTarget)
      // setviacomCW(viacomCWResult[0])
      // setviacomLW(viacomLWResult[0])

      // const sonyCWResult = await ExecutiveSummaryServicesContent.sonyCW(filterSelectValueTarget)
      // const sonyLWResult = await ExecutiveSummaryServicesContent.sonyLW(filterSelectValueTarget)
      // setsonyCW(sonyCWResult[0])
      // setsonyLW(sonyLWResult[0])

      // const disneyCWResult = await ExecutiveSummaryServicesContent.disneyCW(filterSelectValueTarget)
      // const disneyLWResult = await ExecutiveSummaryServicesContent.disneyLW(filterSelectValueTarget)
      // setdisneyCW(disneyCWResult[0])
      // setdisneyLW(disneyLWResult[0])
      setLoadingLinear(false)

      const networkShare = await ExecutiveSummaryServicesContent.getNetworkShareData()
      setNetworkShareData(networkShare)
     
      setzeeCW(networkShare.zeeCW2[0])
      setzeeLW(networkShare.zeeLW2[0])
      setstarCW(networkShare.starCW2[0])
      setstarLW(networkShare.starLW2[0])
      setsunCW(networkShare.sunCW2[0])
      setsunLW(networkShare.sunLW2[0])
      setviacomCW(networkShare.viacomCW2[0])
      setviacomLW(networkShare.viacomLW2[0])
      setsonyCW(networkShare.sonyCW2[0])
      setsonyLW(networkShare.sonyLW2[0])
      setdisneyCW(networkShare.disneyCW2[0])
      setdisneyLW(networkShare.disneyLW2[0])

      setzeeCW15(networkShare.zeeCW15[0])
      setzeeLW15(networkShare.zeeLW15[0])
      setstarCW15(networkShare.starCW15[0])
      setstarLW15(networkShare.starLW15[0])
      setsunCW15(networkShare.sunCW15[0])
      setsunLW15(networkShare.sunLW15[0])
      setviacomCW15(networkShare.viacomCW15[0])
      setviacomLW15(networkShare.viacomLW15[0])
      setsonyCW15(networkShare.sonyCW15[0])
      setsonyLW15(networkShare.sonyLW15[0])
      setdisneyCW15(networkShare.disneyCW15[0])
      setdisneyLW15(networkShare.disneyLW15[0])


    }

    const getTheChartData = (value) => {
      
    }
 

  useEffect(() => {
    // LoadNetworkShareData()
  }, [filterSelectValueTarget]);


    
    var dataSetNeteorkShareZee2 = [{
      data: [
      {
        value: 'CW',
        count: 2,
        people: `South GEC & Movie - 2}`,
      },
      {
        value: 'LW',
        count: 1,
        people: `South GEC & Movie - 1}`,
      },
    ]
    },
    {
      data: [{
        value: 'CW',
        count: 2,
        people: `Hindi Movie - 2`,
      }, {
        value: 'LW',
        count: 3,
        people: `Hindi Movie - 3`,
      },]
    }, {
      data: [{
        value: 'CW',
        count: 1,
        people: `Others - 1`,
        
      }, {
        value: 'LW',
        count: 1,
        people: `Others - 1`,
      },]
    }, {
      data: [{
        value: 'CW',
        count: 2,
        people: `RHSM GEC & Movie -  2`,
      }, {
        value: 'LW',
        count: 2,
        people: `RHSM GEC & Movie - 2`,
        
      },]
    }, {
      data: [{
        value: 'CW',
        count: 1,
        people: `Hindi GEC - 1`,
        
      }, {
        value: 'LW',
        count: 1,
        people: `Hindi GEC - 1`,
      },]
    },
    ];
  var dataSetNeteorkShareZee2 = [{
    data: [
    {
      value: 'CW',
      count: 2,
      people: `South GEC & Movie - 2`,
    },
    {
      value: 'LW',
      count: 1,
      people: `South GEC & Movie - 1`,
    },
  ]
  },
  {
    data: [{
      value: 'CW',
      count: 1,
      people: `Hindi Movie - 1`,
    }, {
      value: 'LW',
      count: 3,
      people: `Hindi Movie - 3`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1,
      people: `Others - 1`,
      
    }, {
      value: 'LW',
      count: 1.4,
      people: `Others - 1.4`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2.1,
      people: `RHSM GEC & Movie -  2.1`,
    }, {
      value: 'LW',
      count: 1,
      people: `RHSM GEC & Movie - 1`,
      
    },]
  }, {
    data: [{
      value: 'CW',
      count: 3,
      people: `Hindi GEC - 3`,
      
    }, {
      value: 'LW',
      count: 1,
      people: `Hindi GEC - 1`,
    },]
  },
  ];

  var dataSetNeteorkShareZee15 = [{
    data: [
    {
      value: 'CW',
      count: 1.8,
      people: `South GEC & Movie - 1.8`,
    },
    {
      value: 'LW',
      count: 1,
      people: `South GEC & Movie - 1`,
    },
  ]
  },
  {
    data: [{
      value: 'CW',
      count: 2.5,
      people: `Hindi Movie - 2.5`,
    }, {
      value: 'LW',
      count: 1,
      people: `Hindi Movie - 1`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2,
      people: `Others - 2`,
      
    }, {
      value: 'LW',
      count: 1,
      people: `Others - 1`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1.2,
      people: `RHSM GEC & Movie -  1.2`,
    }, {
      value: 'LW',
      count: 1,
      people: `RHSM GEC & Movie - 1`,
      
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2.3,
      people: `Hindi GEC - 2.3`,
      
    }, {
      value: 'LW',
      count: 2.2,
      people: `Hindi GEC - 2.2`,
    },]
  },
  ];


  var dataSetNeteorkShareStar2 = [{
    data: [{
      value: 'CW',
      count: 1,
      people: `South GEC & Movie - 1`,
    }, {
      value: 'LW',
      count: 2,
      people: `South GEC & Movie - 2`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1.9,
      people: `Hindi Movie - 1.9`,
    }, {
      value: 'LW',
      count: 1,
      people: `Hindi Movie - 1`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2,
      people: `Others - 2`,
 
    }, {
      value: 'LW',
      count: 1.2,
      people: `Others - 1.2`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 3,
      people: `RHSM GEC & Movie - 3`,
    }, {
      value: 'LW',
      count: 2.1,
      people: `RHSM GEC & Movie - 2.1`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1.1,
      people: `Hindi GEC - 1.1`,

    }, {
      value: 'LW',
      count: 1.8,
      people: `Hindi GEC - 1.8`,
    },]
  },
  ];

  var dataSetNeteorkShareStar15 = [{
    data: [{
      value: 'CW',
      count: 1.2,
      people: `South GEC & Movie - 1.2`,
    }, {
      value: 'LW',
      count: 2.2,
      people: `South GEC & Movie - 2.2`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1,
      people: `Hindi Movie - 1`,
    }, {
      value: 'LW',
      count: 2,
      people: `Hindi Movie - 2`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 3.1,
      people: `Others - 3.1`,
 
    }, {
      value: 'LW',
      count: 2,
      people: `Others - 2`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2,
      people: `RHSM GEC & Movie - 2`,
    }, {
      value: 'LW',
      count: 1.4,
      people: `RHSM GEC & Movie - 1.4`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2,
      people: `Hindi GEC - 2`,

    }, {
      value: 'LW',
      count: 1.3,
      people: `Hindi GEC - 1.3`,
    },]
  },
  ];

  var dataSetNeteorkShareSuntv2 = [{
    data: [{
      value: 'CW',
      count: 1,
      people: `South GEC & Movie - 1`,

    }, {
      value: 'LW',
      count: 2,
      people: `South GEC & Movie - 2`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 3,
      people: `Hindi Movie - 3`,

    }, {
      value: 'LW',
      count: 1,
      people: `Hindi Movie - 1`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2,
      people: `Others - 2`,

    }, {
      value: 'LW',
      count: 3,
      people: `Others - 3`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1,
      people: `RHSM GEC & Movie - 1`,

    }, {
      value: 'LW',
      count: 2.1,
      people: `RHSM GEC & Movie - 2.1`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1.1,
      people: `Hindi GEC - 1.1`,
    }, {
      value: 'LW',
      count: 1,
      people: `Hindi GEC - 1`,
    },]
  },
  ];

  var dataSetNeteorkShareSuntv15 = [{
    data: [{
      value: 'CW',
      count: 1,
      people: `South GEC & Movie - 1`,

    }, {
      value: 'LW',
      count: 1.5,
      people: `South GEC & Movie - 1.5`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1,
      people: `Hindi Movie - 1`,

    }, {
      value: 'LW',
      count: 2,
      people: `Hindi Movie - 2`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2,
      people: `Others - 2`,

    }, {
      value: 'LW',
      count: 1,
      people: `Others - 1`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2.5,
      people: `RHSM GEC & Movie - 2.5 `,

    }, {
      value: 'LW',
      count: 1,
      people: `RHSM GEC & Movie - 1`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2.9,
      people: `Hindi GEC - 2.9`,
    }, {
      value: 'LW',
      count: 1,
      people: `Hindi GEC - 1`,
    },]
  },
  ];

  var dataSetNeteorkShareViaCom2 = [{
    data: [{
      value: 'CW',
      count: 1,
      people: `South GEC & Movie - 1`,
 
    }, {
      value: 'LW',
      count: 2,
      people: `South GEC & Movie - 2`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2.3,
      people: `Hindi Movie - 2.3`,

    }, {
      value: 'LW',
      count: 1.2,
      people: `Hindi Movie - 1.2`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1,
      people: `Others - 1`,
    }, {
      value: 'LW',
      count: 2,
      people: `Others - 2`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2,
      people: `RHSM GEC & Movie - 2 `,
    }, {
      value: 'LW',
      count: 1,
      people: `RHSM GEC & Movie - 1`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2.3,
      people: `Hindi GEC - 2.3`,
    }, {
      value: 'LW',
      count: 1.2,
      people: `Hindi GEC - 1.2`,
    },]
  },
  ];

  var dataSetNeteorkShareViaCom15 = [{
    data: [{
      value: 'CW',
      count: 1,
      people: `South GEC & Movie - 1`,
 
    }, {
      value: 'LW',
      count: 2.3,
      people: `South GEC & Movie - 2.3`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1,
      people: `Hindi Movie - 1`,

    }, {
      value: 'LW',
      count: 1,
      people: `Hindi Movie - 1`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1,
      people: `Others - 1`,
    }, {
      value: 'LW',
      count: 2.3,
      people: `Others - 2.3`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1,
      people: `RHSM GEC & Movie - 1 `,
    }, {
      value: 'LW',
      count: 3,
      people: `RHSM GEC & Movie - 3`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1.3,
      people: `Hindi GEC - 1.3`,
    }, {
      value: 'LW',
      count: 1,
      people: `Hindi GEC - 1`,
    },]
  },
  ];

  var dataSetNeteorkShareSony2 = [{
    data: [{
      value: 'CW',
      count: 1,
      people: `South GEC & Movie - 1`,
    }, {
      value: 'LW',
      count: 2,
      people: `South GEC & Movie - 2`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2,
      people: `Hindi Movie - 2`,
    }, {
      value: 'LW',
      count: 1,
      people: `Hindi Movie - 1`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1,
      people: `Others - 1`,
    }, {
      value: 'LW',
      count: 2,
      people: `Others - 2`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1,
      people: `RHSM GEC & Movie - 1 `,
    }, {
      value: 'LW',
      count: 2,
      people: `RHSM GEC & Movie - 2`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1.7,
      people: `Hindi GEC - 1.7`,
    }, {
      value: 'LW',
      count: 2,
      people: `Hindi GEC - 2`,
    },]
  },
  ];

  var dataSetNeteorkShareSony15 = [{
    data: [{
      value: 'CW',
      count: 1,
      people: `South GEC & Movie - 1`,
    }, {
      value: 'LW',
      count: 1,
      people: `South GEC & Movie - 1`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2.3,
      people: `Hindi Movie - 2.3`,
    }, {
      value: 'LW',
      count: 3,
      people: `Hindi Movie - 3`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1,
      people: `Others - 1`,
    }, {
      value: 'LW',
      count: 1,
      people: `Others - 1`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2,
      people: `RHSM GEC & Movie - 2`,
    }, {
      value: 'LW',
      count: 1,
      people: `RHSM GEC & Movie - 1`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2,
      people: `Hindi GEC - 2`,
    }, {
      value: 'LW',
      count: 1,
      people: `Hindi GEC - 1`,
    },]
  },
  ];

  var dataSetNeteorkShareDisney2 = [{
    data: [{
      value: 'CW',
      count: 2,
      people: `South GEC & Movie - 2`,
    }, {
      value: 'LW',
      count: 1,
      people: `South GEC & Movie - 1`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2,
      people: `Hindi Movie - 2`,
    }, {
      value: 'LW',
      count: 1,
      people: `Hindi Movie - 1`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1,
      people: `Others - 1`,
    }, {
      value: 'LW',
      count: 3,
      people: `Others - 3`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2,
      people: `RHSM GEC & Movie - 2 `,
    }, {
      value: 'LW',
      count: 2,
      people: `RHSM GEC & Movie - 2`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2,
      people: `Hindi GEC - 2`,
  }, {
    value: 'LW',
    count: 1,
    people: `Hindi GEC - 1`,
    },]
  },
  ];

  var dataSetNeteorkShareDisney15 = [{
    data: [{
      value: 'CW',
      count: 1.3,
      people: `South GEC & Movie - 1.3`,
    }, {
      value: 'LW',
      count: 1,
      people: `South GEC & Movie - 1`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1,
      people: `Hindi Movie - 1`,
    }, {
      value: 'LW',
      count: 3,
      people: `Hindi Movie - 3`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2.3,
      people: `Others - 2.3`,
    }, {
      value: 'LW',
      count: 1.2,
      people: `Others - 1.2`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 1,
      people: `RHSM GEC & Movie - 1 `,
    }, {
      value: 'LW',
      count: 2,
      people: `RHSM GEC & Movie - 2`,
    },]
  }, {
    data: [{
      value: 'CW',
      count: 2,
      people: `Hindi GEC - 2`,
  }, {
    value: 'LW',
    count: 1,
    people: `Hindi GEC - 1`,
    },]
  },
  ];


  const getWeekAndDate = (value) => {
    setCWeekAndDate(value);

  }


  return (
    <div className="loader_container">
      <div className="executive-summary-content-tabs">
        <div onClick={() => setActiveTab('linear')} className={activeTab === 'linear' ? ("executive-summary-content-active-tab") : ("executive-summary-content-tab")}>Linear</div>
        <div onClick={() => setActiveTab('ott')} className={activeTab === 'ott' ? ("executive-summary-content-active-tab") : ("executive-summary-content-tab")}>OTT</div>
      </div>

      <div style={{ position: 'relative', top: '150px' }}>
        {activeTab === 'linear' && <ExecutiveSummaryContentLinear
          WeekAndDate={getWeekAndDate}
          loadingLinear = {loadingLinear}
          passTheData={getTheChartData}
          dataSetNeteorkShareZee={dataSetNeteorkShareZee2} 
          dataSetNeteorkShareStar={dataSetNeteorkShareStar2} 
          dataSetNeteorkShareSuntv={dataSetNeteorkShareSuntv2}
          dataSetNeteorkShareViaCom={dataSetNeteorkShareViaCom2} 
          dataSetNeteorkShareSony={dataSetNeteorkShareSony2} 
          dataSetNeteorkShareDisney={dataSetNeteorkShareDisney2} 

          dataSetNeteorkShareZee15={dataSetNeteorkShareZee15} 
          dataSetNeteorkShareStar15={dataSetNeteorkShareStar15} 
          dataSetNeteorkShareSuntv15={dataSetNeteorkShareSuntv15}
          dataSetNeteorkShareViaCom15={dataSetNeteorkShareViaCom15} 
          dataSetNeteorkShareSony15={dataSetNeteorkShareSony15} 
          dataSetNeteorkShareDisney15={dataSetNeteorkShareDisney15}
        />}

      {/* {activeTab === 'linear' && disneyLW && disneyCW.total && NetworkShareWeek === "15" && <ExecutiveSummaryContentLinear
          WeekAndDate={getWeekAndDate}
          loadingLinear = {loadingLinear}
          passTheData={getTheChartData}
          dataSetNeteorkShareZee={dataSetNeteorkShareZee} zeeCWTotal={zeeCW.total.toFixed()} zeeLWTotal={zeeLW.total.toFixed()}
          dataSetNeteorkShareStar={dataSetNeteorkShareStar} starCWTotal = {starCW.total.toFixed()} starLWTotal = {starLW.total.toFixed()}
          dataSetNeteorkShareSuntv={dataSetNeteorkShareSuntv} sunCWTotal={sunCW.total.toFixed()} sunLWTotal = {sunLW.total.toFixed()}
          dataSetNeteorkShareViaCom={dataSetNeteorkShareViaCom} viaComCWTotal = {viacomCW.total.toFixed()} viaComLWTotal = {viacomLW.total.toFixed()}
          dataSetNeteorkShareSony={dataSetNeteorkShareSony} sonyCWTotal = {sonyCW.total.toFixed()} sonyWTotal = {sonyLW.total.toFixed()}
          dataSetNeteorkShareDisney={dataSetNeteorkShareDisney} disneyCWTotal = {disneyCW.total.toFixed()} disneyLWTotal = {disneyLW.total.toFixed()}
        />} */}
        {activeTab === 'ott' &&  <ExecutiveSummaryContentOtt cWeekAndDate={cWeekAndDate} />}
      </div>


      {/* <div>
        {
          loadingLinear === true ? <LoderLinear/> : <></>
        }

      </div> */}
    </div>
  )
}

export default ExecutiveSummaryContent;