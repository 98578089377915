import React, { useState } from "react";
import './ProductQualityExperienceCardNew.css'
import Iicon from  '../../../../Components/Images/Iicon.svg'
import ProductQualityExperienceToggleAdoption from "./ProductQualityExperienceToggleAdoption";
import ExecutiveSummaryTextWindow from "../ExecutiveSummaryTextWindow";

const ProductQualityExperienceCard = (props) => {
  const { mydata, myData, PlatformData, Range, diifrence, toggleData,overAllData, isMelian, extraText ,IiconText} = props;
  const [ IiconMsg, setIiconMsg ] = useState(false)

  const IiconVisibility = () => {
    setIiconMsg(true)
  }
  const IiconVisibilityOff = () => {
    setIiconMsg(false)
  }
//  console.log(overAllData[0].l30_metric_value,"for every product");
  return (
    <div>
      <div className="product-qual-experience-subdiv-tile">
        <div className="product-data">
          <p className="product-qual-experience-subdiv-tile-title new_product_exp_width">
            <p className="product-qual-experience-title">{mydata[0]}</p>
            <div onMouseEnter={IiconVisibility} onMouseLeave={IiconVisibilityOff}> <span className="product_qual_experience_Iicon_span" ><img src={Iicon}/></span></div>
           
            {IiconMsg === true ?
            <span className="product_qual_experience_Iicon_span_Iicon">{IiconText}</span>:<></>
            }
            

            <div className="product-experience">
              <span className="product_qual_experience_current_data">
                {mydata[1]}
              </span>
              <span className="product_qual_experience_last_data">
                
                {mydata[2] > 0 ? (
                  <span className="up-line">{mydata[2]} ↑ </span>
                ) : (
                  <span className="down-line">{mydata[2]} ↓     </span>
                )}
              </span>
            </div>
{extraText === true ? (
  <div class="text-product">Deep dive view</div>
):(
  <div class="text-product"></div>

)}
          </p>
        </div>

        <ProductQualityExperienceToggleAdoption myData = {mydata[0]} icon={overAllData} isMelian ={isMelian} toggleData={toggleData} />
      </div>
    </div>
  );
};

export default ProductQualityExperienceCard;
