import React from "react";
import { useState } from "react";
import './ExecutiveSummaryConLinearZeeProgramsCard.css';
import zeeLogo from '../../Images/zee-image.svg'
import { width } from "@mui/system";
import { useMediaQuery } from "@material-ui/core";


const ExecutiveSummaryConLinearZeeProgramsCard = (props) => {
    const {img, index, name, grp, Reach, dataLength, logo, tsv,Channel_Name, isMobile, number} = props
    const [mouseEnter, setMouseEnter] = useState(false);
    // const [mouseLeave, setMouseLeave] = useState();
    
    let imgWidth = ''; 
    if(dataLength >= 11){
        imgWidth = 148;
    }
    if(isMobile){
        imgWidth = 148;
    }


    const handleMouseEnter = () => {
        setMouseEnter(true)
    }

    const handleMouseLeave = () => {
        setMouseEnter(false)
    }
   
    return(
        <div className="executive-summ-content-linear-content-cards-syn" style={{width: imgWidth+'px'}}>
                <div className="executive-summary-con-linear-zee-programs-card-syn" style={{width: imgWidth+'px'}}>
                {/* {serieseImage.map((ele, index) => {
                    return( */}
                    <div className="executive-summary-content-linearzee-prog-cards-syn">
                        <div className="executive-summary-content-linear-zee-prog-cards-circle-and-img-syn">
                            <div className="executive-sum-cont-lin-circle-design-syn">
                                <div className="executive-sum-content-lin-circle-chiled-design-syn">
                                    {index+1}
                                </div> 
                                <label className="executive-summary-content-cards-div-span-logo">{Channel_Name}</label>
                                <lable className="executive-summary-content-cards-div-span-name">{name}</lable>
                            </div>
                            <img onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} className='executive-summary-linear-cont-zee-advertiser-card-syn' src={img}></img>
                            {mouseEnter && <div onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} style={{width: imgWidth -5+'px'}} className="executive-summ-lin-content-card-logo-and-descr-syn">
                                <div className="execu-summ-lin-con-card-zee-logo-and-heading-syn">
                                    {/* <img src={logo}/> */}
                                    <p className="executive-summ-cont-lin-con-card-head-syn">{name}</p>
                                </div>
                                <div className="execu-summ-line-cont-card-descr-syn">
                                    <div><label><span className="execut-summ-lin-con-des-keys-syn">CW GRP</span><span className="dot">:</span><span className="execut-summ-lin-con-des-colon-syn">{Number(grp).toFixed(2)}</span></label></div>
                                    <div><label><span className="execut-summ-lin-con-des-keys-syn">CW Reach</span><span className="dot">:</span> <span className="execut-summ-lin-con-des-colon-syn">{Number(Reach).toFixed(2)}</span></label></div>
                                    <div><label><span className="execut-summ-lin-con-des-keys-syn">CW TSV</span><span className="dot">:</span><span className="execut-summ-lin-con-des-colon-syn">{Number(tsv).toFixed(2)}</span></label></div>
                                </div>
                            </div>}
                        </div>
                    </div>
                    {/* )
                    })} */}
                </div>

            {/* <div className="">
                <h6 className="executive-summary-con-linear-zee-programs-heading-card-syn ">Competition Network</h6>
                <div className="executive-summary-con-linear-zee-programs-card-syn d-flex">
                {serieseImage.map((ele, index) => {
                    return(
                    <div key={index} className="executive-summary-content-linearzee-prog-cards-syn">
                        <div className="executive-summary-content-linear-zee-prog-cards-circle-and-img-syn">
                            <div className="executive-sum-cont-lin-circle-design-syn">
                                <div className="executive-sum-content-lin-circle-chiled-design-syn">
                                    {index+1}
                                </div>
                            </div>
                            <img onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} className='executive-summary-linear-cont-zee-advertiser-card-syn' src={ele.img}></img>
                        </div>
                    </div>
                    )
                    })}
                </div>
            </div> */}
        </div>
        
    )
}

export default ExecutiveSummaryConLinearZeeProgramsCard;