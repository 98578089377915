import React from "react";
import { useState } from "react";
import web from '../../../../Components/Images/productweb.png'
import ar from '../../../../Components/Images/productar.png'
import avod from '../../../../Components/Images/productavod.png'
import svod from '../../../../Components/Images/productsvod.png'
import mr from '../../../../Components/Images/productmr.png'

const ProductQualityExperienceToggle = (props) => {
  const toggleData = props.toggleData;
  const {myData, isMelian} = props 
  console.log(toggleData, "toggleData ");
  //  const {PlatformData} = props
  //  console.log(PlatformData,"data get in togggle")

  // const total = PlatformData[0].android + PlatformData[0].iOS + PlatformData[0].Others + PlatformData[0].Web

  //  const android =  ( PlatformData[0].android  / total )  * 100
  //  const iOS = (PlatformData[0].iOS / total ) * 100
  //  const Others = (PlatformData[0].Others / total ) * 100
  //  const Web = (PlatformData[0].Web / total ) * 100

  //  console.log('ttl', android,iOS,Others,Web , total)

  const [classed, setClassed] = useState("my-minuse-button");

  const handlePluseButtonClick = () => {
    setClassed("my-pluse-button");
  };

  const handleMinuseButtonClick = () => {
    setClassed("my-minuse-button");
  };

  return (
    <>
      <div className={classed}>
        <div className="Executive_summary_product_icons ">
          {toggleData[0] > 0 && (
            <div className="product-icons-logo product_margin">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#402177"
                class="bi bi-android2"
                viewBox="0 0 16 16"
              >
                <path d="m10.213 1.471.691-1.26c.046-.083.03-.147-.048-.192-.085-.038-.15-.019-.195.058l-.7 1.27A4.832 4.832 0 0 0 8.005.941c-.688 0-1.34.135-1.956.404l-.7-1.27C5.303 0 5.239-.018 5.154.02c-.078.046-.094.11-.049.193l.691 1.259a4.25 4.25 0 0 0-1.673 1.476A3.697 3.697 0 0 0 3.5 5.02h9c0-.75-.208-1.44-.623-2.072a4.266 4.266 0 0 0-1.664-1.476ZM6.22 3.303a.367.367 0 0 1-.267.11.35.35 0 0 1-.263-.11.366.366 0 0 1-.107-.264.37.37 0 0 1 .107-.265.351.351 0 0 1 .263-.11c.103 0 .193.037.267.11a.36.36 0 0 1 .112.265.36.36 0 0 1-.112.264Zm4.101 0a.351.351 0 0 1-.262.11.366.366 0 0 1-.268-.11.358.358 0 0 1-.112-.264c0-.103.037-.191.112-.265a.367.367 0 0 1 .268-.11c.104 0 .19.037.262.11a.367.367 0 0 1 .107.265c0 .102-.035.19-.107.264ZM3.5 11.77c0 .294.104.544.311.75.208.204.46.307.76.307h.758l.01 2.182c0 .276.097.51.292.703a.961.961 0 0 0 .7.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h1.343v2.182c0 .276.097.51.292.703a.972.972 0 0 0 .71.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h.76c.291 0 .54-.103.749-.308.207-.205.311-.455.311-.75V5.365h-9v6.404Zm10.495-6.587a.983.983 0 0 0-.702.278.91.91 0 0 0-.293.685v4.063c0 .271.098.501.293.69a.97.97 0 0 0 .702.284c.28 0 .517-.095.712-.284a.924.924 0 0 0 .293-.69V6.146a.91.91 0 0 0-.293-.685.995.995 0 0 0-.712-.278Zm-12.702.283a.985.985 0 0 1 .712-.283c.273 0 .507.094.702.283a.913.913 0 0 1 .293.68v4.063a.932.932 0 0 1-.288.69.97.97 0 0 1-.707.284.986.986 0 0 1-.712-.284.924.924 0 0 1-.293-.69V6.146c0-.264.098-.491.293-.68Z" />
              </svg>
              {/* <p>{android.toFixed()+"%"}</p> */}
              <p className="product_toggle_icon_data">{toggleData[0] + ""}</p>
              

              {/* <p>10</p> */}
            </div>
          )}
          {toggleData[1] > 0 && (
            <div className="product-icons-logo product_margin">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#402177"
                class="bi bi-apple "
                viewBox="0 0 16 16"
              >
                <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z" />
                <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z" />
              </svg>
              {isMelian ? (<p className="product_toggle_icon_data">{(toggleData[1]/1000000).toFixed(2)}</p>): (<p className="product_toggle_icon_data">{toggleData[1] + ""}</p>)}

              {/* <p>20</p> */}
            </div>
          )}

          {toggleData[2] > 0 && (
            <div className="product-icons-logo product_margin">
              <img src={web} />
              <p className="product_toggle_icon_data">{toggleData[2] + ""}</p>
              
            </div>
          )}

          {toggleData[3] > 0 && (
            <div className="product-icons-logo product_margin">
              <img src={avod} />
              <p className="product_toggle_icon_data">{toggleData[3] + ""}</p>
              
            </div>
          )}
          {toggleData[4] > 0 && (
            <div className="product-icons-logo product_margin">
              <img src={svod} />
              <p className="product_toggle_icon_data">{toggleData[4] + ""}</p>
              
            </div>
          )}
          {toggleData[5] > 0 && (
            <div className="product-icons-logo product_margin">
              <img src={ar} />
              <p className="product_toggle_icon_data">{toggleData[5] + ""}</p>
              
            </div>
          )}
           {toggleData[6] > 0 && (
            <div className="product-icons-logo product_margin">
              <img src={mr} />
              <p className="product_toggle_icon_data">{toggleData[6] + ""}</p>
              
            </div>
          )}

        { toggleData[7] > 0 && (
            <div className="product-icons-logo product_margin">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#402177"
                class="bi bi-apple "
                viewBox="0 0 16 16"
              >
                <path d="M14.6067 0.85H1.39326C1.0902 0.85 0.85 1.0902 0.85 1.39326V8.50349C0.85 8.80656 1.0902 9.04676 1.39326 9.04676H6.43436V9.98942H4.94832C4.64526 9.98942 4.40506 10.2296 4.40506 10.5327C4.40506 10.8357 4.64526 11.0759 4.94832 11.0759H11.0358C11.3388 11.0759 11.579 10.8357 11.579 10.5327C11.579 10.2296 11.3388 9.98942 11.0358 9.98942H9.56564V9.04676H14.6067C14.9098 9.04676 15.15 8.80656 15.15 8.50349V1.39326C15.15 1.09027 14.9099 0.850119 14.607 0.85C14.6069 0.85 14.6068 0.85 14.6067 0.85ZM8.47933 10.0052H7.52097V9.06253L8.47933 9.06245V10.0052ZM14.0637 7.9759H1.93653V1.93647H14.0637V7.9759Z" fill="#402177" stroke="#402177" stroke-width="0.3"></path></svg>
                {isMelian ? (<p className="product_toggle_icon_data">{(toggleData[7]/1000000).toFixed(2)}</p>):(<p className="product_toggle_icon_data">{toggleData[7] + ""}</p>)}
              
            </div>
          )}
        </div>
      </div>
      {myData !== 'NPS' && myData !== 'Complaints' && <div className="toggle-button">
        {classed === "my-minuse-button" ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-dash-circle  my-button2"
            viewBox="0 0 16 16"
            onClick={handlePluseButtonClick}
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-plus-circle  my-button2"
            viewBox="0 0 16 16"
            onClick={handleMinuseButtonClick}
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
          </svg>
        )}
      </div> } 
    </>
  );
};

export default ProductQualityExperienceToggle;
