import React, { useEffect, useRef, useLayoutEffect } from "react";
import './KeyContributionPieChart.css'
import * as d3 from "d3";
import { transition } from 'd3-transition';
import { scaleLinear } from 'd3-scale';

const KeyContributionPieChart = (props) => {

    const { data, total, toggleValue, percent, color } = props
    console.log('data, total, toggleValue, percent, color', data, total, toggleValue, percent, color);
    const ref = useRef();
    // console.log(toggleValue);
    const width = 550;
    const xDomain = [-1, 1];
    const xRange = [10, width ]
    const xType = d3.scaleLinear;
    const xScale = xType(xDomain, xRange);
    const xAxis = d3.axisTop(xScale).ticks(width / 80);
    const margin = {
        top: 45,
        right: 0,
        bottom: 70,
        left: 0,
    };

    const barHeight = 35;
    const svgHeight = 140;
    
    // const parentWidth = 500;

    // const height = svgHeight - margin.top - margin.bottom;

    // const { xScale, barHeight } = props;


    useEffect(() => {

        const xScale = scaleLinear()
            .domain([0, total])
            .range([0, width])

        const node = ref.current;
        d3.select(node)
            .append('rect')
            .attr('class', 'bar')
            // .attr('x', 0)
            // .attr('y', 0)
            // .attr('rx', 4)
            // .attr('ry', 4)
            .attr('width', xScale(data))
            .attr('fill', color)
            .attr('height', barHeight)
            
            
            // .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

            d3.select(node).append("g")
            .attr("font-size", "20px")

                .attr("transform", `translate(0,${10})`)
                .call(xAxis)
                // .call(g => g.select(".domain").remove())
                .call(g => g.selectAll(".tick line").clone()
                    .attr("y2", 200 )
                    .attr("stroke-opacity", 0.1))
                // .call(g => g.append("text")
                //     .attr("x", xScale(0))
                //     .attr("y", -22)
                //     .attr("fill", "currentColor")
                //     .attr("text-anchor", "middle")
                //     .text('xLabel'));

        // #C996EB
        d3.select(node)
            .append('text')
            .attr('class', 'customer-amount')
            .attr('y', 23)
            .attr('x', 10)
            .text((data));

        d3.select(node)
            .selectAll(".remaining-amount").remove()

        d3.select(node)
            .append('text')
            .attr('class', 'remaining-amount')
            .attr('y', 23)
            .attr('x', 500)
            .text(percent ? (total - data) : (total - data + '%'));

        // // .attr('y', barHeight)
        // .attr('dx', -10)
        // .attr('dy', 2)
        //   const { data, xScale } = props;
        const t = transition().duration(100);

        // d3.select('.bar')
        //     .transition(t).attr('width', xScale(data));

        d3.select('.customer-amount')
            .transition(t)
        // .attr('x', xScale(30))

        d3.selectAll('.remaining-portion').attr('fill', '#e6e6e679')

    }, [toggleValue])
    // ↓↓
    return (
        <>
            <svg
                id={data}
                width={width}
                height={barHeight}
                className="prograss-svg"
            >
                <g >
                    <g className="budget-bar-group">
                        <rect className="remaining-portion" x="0" y="0" width={width} height={barHeight} rx="0" ry="0" opacity="0.2" />
                        <text className="customer-remaining-amount" x={width} y="32" dy="-18" dx="-30">
                            {/* { toggleValue ===true ? (total + '%') : (total) } */}
                        </text>
                    </g>
                    {/* <FrontBar
                                // {...{
                                //     xScale,
                                //     barHeight,
                                //     data,
                                // }}
                            /> */}
                    <g
                        ref={ref}
                        className="expenditure-bar-group"
                        x="10" y="10" width="20" height="10" rx="0" ry="0" 
                    />
                </g>
            </svg>
        </>
    )
};

export default KeyContributionPieChart;
