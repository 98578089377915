export default{
    data:
        [{
            "metric_name": "app rating",
            "platform": "android",
            "user_type": "overall",
            "l30_metric_value": "4.48",
            "l60_metric_value": "4.49"
          }, {
            "metric_name": "app rating",
            "platform": "ios",
            "user_type": "overall",
            "l30_metric_value": "4.7",
            "l60_metric_value": "4.7"
          }, {
            "metric_name": "nps",
            "platform": "overall",
            "user_type": "overall",
            "l30_metric_value": "-4.0",
            "l60_metric_value": "-4.0"
          }, {
            "metric_name": "complaints",
            "platform": "overall",
            "user_type": "overall",
            "l30_metric_value": "9072.0",
            "l60_metric_value": "11103.0"
          }, {
            "metric_name": "watchtime per viewer",
            "platform": "overall",
            "user_type": "overall",
            "l30_metric_value": "104.7",
            "l60_metric_value": "84.1"
          }, {
            "metric_name": "watchtime per viewer",
            "platform": "android",
            "user_type": "overall",
            "l30_metric_value": "93.4",
            "l60_metric_value": "91.6"
          }, {
            "metric_name": "watchtime per viewer",
            "platform": "ios",
            "user_type": "overall",
            "l30_metric_value": "98.8",
            "l60_metric_value": "95.8"
          }, {
            "metric_name": "watchtime per viewer",
            "platform": "web",
            "user_type": "overall",
            "l30_metric_value": "15.3",
            "l60_metric_value": "7.2"
          }, {
            "metric_name": "watchtime per viewer",
            "platform": "connected tv",
            "user_type": "overall",
            "l30_metric_value": "236.3",
            "l60_metric_value": "224.9"
          }, {
            "metric_name": "User bounce",
            "platform": "overall",
            "user_type": "overall",
            "l30_metric_value": "33.5",
            "l60_metric_value": "26.6"
          }, {
            "metric_name": "User bounce",
            "platform": "android",
            "user_type": "overall",
            "l30_metric_value": "40.5",
            "l60_metric_value": "40.1"
          }, {
            "metric_name": "User bounce",
            "platform": "ios",
            "user_type": "overall",
            "l30_metric_value": "37.6",
            "l60_metric_value": "39.0"
          }, {
            "metric_name": "User bounce",
            "platform": "web",
            "user_type": "overall",
            "l30_metric_value": "20.2",
            "l60_metric_value": "12.0"
          }, {
            "metric_name": "User bounce",
            "platform": "connected tv",
            "user_type": "overall",
            "l30_metric_value": "28.0",
            "l60_metric_value": "29.0"
          }, {
            "metric_name": "new installs",
            "platform": "overall",
            "user_type": "overall",
            "l30_metric_value": "2225900.0",
            "l60_metric_value": "2115800.0"
          }, {
            "metric_name": "new installs",
            "platform": "android",
            "user_type": "overall",
            "l30_metric_value": "2190000.0",
            "l60_metric_value": "2080000.0"
          }, {
            "metric_name": "new installs",
            "platform": "ios",
            "user_type": "overall",
            "l30_metric_value": "35900.0",
            "l60_metric_value": "35800.0"
          }, {
            "metric_name": "new registrations",
            "platform": "overall",
            "user_type": "overall",
            "l30_metric_value": "496336.0",
            "l60_metric_value": "497572.0"
          }, {
            "metric_name": "new registrations",
            "platform": "android",
            "user_type": "overall",
            "l30_metric_value": "461979.0",
            "l60_metric_value": "463489.0"
          }, {
            "metric_name": "new registrations",
            "platform": "ios",
            "user_type": "overall",
            "l30_metric_value": "887.0",
            "l60_metric_value": "887.0"
          }, {
            "metric_name": "new registrations",
            "platform": "connected tv",
            "user_type": "overall",
            "l30_metric_value": "2288.0",
            "l60_metric_value": "2101.0"
          }, {
            "metric_name": "new registrations",
            "platform": "web",
            "user_type": "overall",
            "l30_metric_value": "31182.0",
            "l60_metric_value": "31095.0"
          }, {
            "metric_name": "new subs",
            "platform": "overall",
            "user_type": "overall",
            "l30_metric_value": "46328.0",
            "l60_metric_value": "45823.0"
          }, {
            "metric_name": "new subs",
            "platform": "android",
            "user_type": "overall",
            "l30_metric_value": "30073.0",
            "l60_metric_value": "30557.0"
          }, {
            "metric_name": "new subs",
            "platform": "ios",
            "user_type": "overall",
            "l30_metric_value": "3336.0",
            "l60_metric_value": "3324.0"
          }, {
            "metric_name": "new subs",
            "platform": "web",
            "user_type": "overall",
            "l30_metric_value": "5872.0",
            "l60_metric_value": "5697.0"
          }, {
            "metric_name": "new subs",
            "platform": "connected tv",
            "user_type": "overall",
            "l30_metric_value": "7026.0",
            "l60_metric_value": "6199.0"
          }, {
            "metric_name": "subs retention rate",
            "platform": "overall",
            "user_type": "overall",
            "l30_metric_value": "0.28",
            "l60_metric_value": "0.28"
          }, {
            "metric_name": "subs retention rate",
            "platform": "ar",
            "user_type": "overall",
            "l30_metric_value": "0.51",
            "l60_metric_value": "0.55"
          }, {
            "metric_name": "subs retention rate",
            "platform": "mr",
            "user_type": "overall",
            "l30_metric_value": "0.22",
            "l60_metric_value": "0.23"
          }, {
            "metric_name": "sessions per user",
            "platform": "overall",
            "user_type": "overall",
            "l30_metric_value": "1.6",
            "l60_metric_value": "1.5"
          }, {
            "metric_name": "sessions per user",
            "platform": "android",
            "user_type": "overall",
            "l30_metric_value": "1.5",
            "l60_metric_value": "1.5"
          }, {
            "metric_name": "sessions per user",
            "platform": "ios",
            "user_type": "overall",
            "l30_metric_value": "1.7",
            "l60_metric_value": "1.6"
          }, {
            "metric_name": "sessions per user",
            "platform": "web",
            "user_type": "overall",
            "l30_metric_value": "1.2",
            "l60_metric_value": "1.0"
          }, {
            "metric_name": "sessions per user",
            "platform": "connected tv",
            "user_type": "overall",
            "l30_metric_value": "2.1",
            "l60_metric_value": "2.1"
          }, {
            "metric_name": "crash free rate",
            "platform": "overall",
            "user_type": "overall",
            "l30_metric_value": "99.25",
            "l60_metric_value": "99.22"
          }, {
            "metric_name": "crash free rate",
            "platform": "android",
            "user_type": "overall",
            "l30_metric_value": "99.25",
            "l60_metric_value": "99.22"
          }, {
            "metric_name": "crash free rate",
            "platform": "ios",
            "user_type": "overall",
            "l30_metric_value": "99.35",
            "l60_metric_value": "99.49"
          }, {
            "metric_name": "app load time",
            "platform": "android",
            "user_type": "overall",
            "l30_metric_value": "9.33",
            "l60_metric_value": "8.15"
          }, {
            "metric_name": "good video experience",
            "platform": "Good Session",
            "user_type": "overall",
            "l30_metric_value": "70.55",
            "l60_metric_value": "67.7"
          }, {
            "metric_name": "good video experience",
            "platform": "vst",
            "user_type": "overall",
            "l30_metric_value": "5.38",
            "l60_metric_value": "5.99"
          }, {
            "metric_name": "good video experience",
            "platform": "vsf",
            "user_type": "overall",
            "l30_metric_value": "0.93",
            "l60_metric_value": "1.54"
          }, {
            "metric_name": "good video experience",
            "platform": "avg bitrate",
            "user_type": "overall",
            "l30_metric_value": "3.0",
            "l60_metric_value": "2.97"
          }, {
            "metric_name": "good video experience",
            "platform": "rr",
            "user_type": "overall",
            "l30_metric_value": "1.55",
            "l60_metric_value": "1.6"
          }, {
            "metric_name": "svod good video experience",
            "platform": "Good Session",
            "user_type": "overall",
            "l30_metric_value": "81.84",
            "l60_metric_value": "81.13"
          }, {
            "metric_name": "svod good video experience",
            "platform": "vst",
            "user_type": "overall",
            "l30_metric_value": "3.34",
            "l60_metric_value": "3.3"
          }, {
            "metric_name": "svod good video experience",
            "platform": "vsf",
            "user_type": "overall",
            "l30_metric_value": "0.84",
            "l60_metric_value": "1.91"
          }, {
            "metric_name": "svod good video experience",
            "platform": "avg bitrate",
            "user_type": "overall",
            "l30_metric_value": "3.53",
            "l60_metric_value": "3.51"
          }, {
            "metric_name": "svod good video experience",
            "platform": "rr",
            "user_type": "overall",
            "l30_metric_value": "0.82",
            "l60_metric_value": "0.85"
          }, {
            "metric_name": "avod good video experience",
            "platform": "Good Session",
            "user_type": "overall",
            "l30_metric_value": "64.57",
            "l60_metric_value": "60.75"
          }, {
            "metric_name": "avod good video experience",
            "platform": "vst",
            "user_type": "overall",
            "l30_metric_value": "6.65",
            "l60_metric_value": "7.62"
          }, {
            "metric_name": "avod good video experience",
            "platform": "vsf",
            "user_type": "overall",
            "l30_metric_value": "0.89",
            "l60_metric_value": "1.32"
          }, {
            "metric_name": "avod good video experience",
            "platform": "avg bitrate",
            "user_type": "overall",
            "l30_metric_value": "2.23",
            "l60_metric_value": "2.22"
          }, {
            "metric_name": "avod good video experience",
            "platform": "rr",
            "user_type": "overall",
            "l30_metric_value": "2.6",
            "l60_metric_value": "2.63"
          }]
    
}