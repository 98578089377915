import axios from "axios";
import { async } from "q";

const baseURL = 'http://localhost:3000/';
const baseURLProduct = '/api/product/';
// const baseURLProd = 'http://20.219.91.228:5000';

export default {

    getCSVData: async function () {
        try {
            const response = await axios.get(baseURL + '/executive-summary/Customer');
            console.log('data fetch from reading csv', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    getViewers: async function () {
        try {
            const response = await axios.get(baseURL + 'viewersApi');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getWatchTime: async function () {
        try {
            const response = await axios.get(baseURL + 'watchTimeApi');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getAdImpressions: async function () {
        try {
            const response = await axios.get(baseURL + 'adApi');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getLinearReachApi: async function () {
        try {
            const response = await axios.get(baseURL + 'linearReachApi')
            return response.data
        } catch (error) {
            console.log(error);
            throw (error);
        }
    },
    getLinearWatchTime: async () => {
        try {
            const response = await axios.get(baseURL + 'linearWatchTimeApi')
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    getLinearUpdateDates: async () => {
        try {
            const response = await axios.get(baseURL + 'executiveupdateapi')
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    getDigitalViewers: async () => {
        try {
            const response = await axios.get(baseURL + 'ottViewersApi')
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    getDigitalWatchTime: async () => {
        try {
            const response = await axios.get(baseURL + 'ottWatchTimeApi')
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    getSocialListening: async () => {
        try {
            const response = await axios.get(baseURL + 'sociallisteningapi')
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    getCumulativePerformanceData: async () => {
        try {
            const response = await axios.get(baseURL + 'ottGraphApi')
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    getUpdateNextUpdate: async () => {
        try {
            const response = await axios.get(baseURL + 'update-next-update');
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },



        //product api
        ProductPlay: async () => {
            try {
                const response = await axios.get('api/product/Play');
                return response.data
            } catch (error) {
                console.log(error);
                throw (error)
            }
        },
        Video_startup_failure_tech: async () => {
            try {
                const response = await axios.get('api/product/Video_startup_failure_tech');
                return response.data
            } catch (error) {
                console.log(error);
                throw (error)
            }
        },

        video_start_up_time: async () => {
            try {
                const response = await axios.get('api/product/video_start_up_time');
                return response.data
            } catch (error) {
                console.log(error);
                throw (error)
            }
        },

        EBVS: async () => {
            try {
                const response = await axios.get('api/product/EBVS');
                return response.data
            } catch (error) {
                console.log(error);
                throw (error)
            }
        },
        RebufferingRatio: async () => {
            try {
                const response = await axios.get('api/product/Rebuffering_Ratio');
                return response.data
            } catch (error) {
                console.log(error);
                throw (error)
            }
        },

        VPF_Tech: async () => {
            try {
                const response = await axios.get("api/product/VPF_Tech");
                return response.data
            } catch (error) {
                console.log(error);
                throw (error)
            }
        },
        Platform: async () => {
            try {
                const response = await axios.get("api/product/platform-Data");
                return response.data
            } catch (error) {
                console.log(error);
                throw (error)
            }
        },
        
    

}