import { set } from "d3v4"

const progressBarChartSampleData = [
    { trailer: 'North', viewers: 500 },
    { trailer: 'East', viewers: 400 },
    { trailer: 'South', viewers: 200 },
    { trailer: 'West', viewers: 300 },

]

const targetTrendSampleData = {
    "trendForTarget": [
        [
            {
                "year": 2023,
                "month": 1,
                "value": 4222.12
            },
            {
                "year": 2023,
                "month": 2,
                "value": 3828.48
            },
            {
                "year": 2023,
                "month": 3,
                "value": 4432.11
            },
            {
                "year": 2022,
                "month": 4,
                "value": 3853.02
            },
            {
                "year": 2022,
                "month": 5,
                "value": 3912.32
            },
            {
                "year": 2022,
                "month": 6,
                "value": 3789.61
            },
            {
                "year": 2022,
                "month": 7,
                "value": 3898.85
            },
            {
                "year": 2022,
                "month": 8,
                "value": 4173.36
            },
            {
                "year": 2022,
                "month": 9,
                "value": 4824.07
            },
            {
                "year": 2022,
                "month": 10,
                "value": 5150.66
            },
            {
                "year": 2022,
                "month": 11,
                "value": 4091.84
            },
            {
                "year": 2022,
                "month": 12,
                "value": 4210.11
            }
        ]
    ],
    "trendForAchievement": [
        [
            {
                "year": 2023,
                "month": 1,
                "value": 2614.09
            },
            {
                "year": 2023,
                "month": 2,
                "value": 2540.19
            },
            {
                "year": 2023,
                "month": 3,
                "value": 1167.9
            },
            {
                "year": 2022,
                "month": 1,
                "value": 3340.8
            },
            {
                "year": 2022,
                "month": 2,
                "value": 3302.58
            },
            {
                "year": 2022,
                "month": 3,
                "value": 3999.67
            },
            {
                "year": 2022,
                "month": 4,
                "value": 3039.75
            },
            {
                "year": 2022,
                "month": 5,
                "value": 3132.28
            },
            {
                "year": 2022,
                "month": 6,
                "value": 2859.12
            },
            {
                "year": 2022,
                "month": 7,
                "value": 2664.01
            },
            {
                "year": 2022,
                "month": 8,
                "value": 3165.93
            },
            {
                "year": 2022,
                "month": 9,
                "value": 3406.02
            },
            {
                "year": 2022,
                "month": 10,
                "value": 3944.78
            },
            {
                "year": 2022,
                "month": 11,
                "value": 2324.10
            },
            {
                "year": 2022,
                "month": 12,
                "value": 2557.70
            },
            {
                "year": 2021,
                "month": 4,
                "value": 3878.7
            },
            {
                "year": 2021,
                "month": 5,
                "value": 3045.44
            },
            {
                "year": 2021,
                "month": 6,
                "value": 2874.14
            },
            {
                "year": 2021,
                "month": 7,
                "value": 3405.57
            },
            {
                "year": 2021,
                "month": 8,
                "value": 3852.65
            },
            {
                "year": 2021,
                "month": 9,
                "value": 4306.98
            },
            {
                "year": 2021,
                "month": 10,
                "value": 5157.02
            },
            {
                "year": 2021,
                "month": 11,
                "value": 4009.68
            },
            {
                "year": 2021,
                "month": 12,
                "value": 3322.81
            },
        ]
    ]
}

const advertiserAndBrandRankingRailsForOnePeriod = [
    {
        "BrandValue": "167426010.20600003",
        "Brand": "SENSODYNE",
        "AdvertiserValue": "1100",
        "AdvertiserShare": "15.03",
        "Advertiser": "Hindustan Unilevers Ltd",
        "Agency": "TLG INDIA PVT LTD (HAR)"
    },
    {
        "BrandValue": "123065233.0",
        "Brand": "LUX TOILET SOAP",
        "AdvertiserValue": "247.3",
        "AdvertiserShare": "3.38",
        "Advertiser": "Coca Cola India Pvt. Ltd",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "BrandValue": "93420932.0",
        "Brand": "PARLE - G - PBPL",
        "AdvertiserValue": "215.6",
        "AdvertiserShare": "2.95",
        "Advertiser": "Mondelez India Foods Ltd",
        "Agency": "TLG INDIA PVT LTD (MUM)"
    },
    {
        "BrandValue": "85689631.859900013",
        "Brand": "THUMS UP.",
        "AdvertiserValue": "183.3",
        "AdvertiserShare": "2.50",
        "Advertiser": "Nestle India Ltd (Del)",
        "Agency": "MEDIACOM COMM (HAR)"
    },
    {
        "BrandValue": "81549704.0",
        "Brand": "CLOSE UP TOOTHPASTE",
        "AdvertiserValue": "179.6",
        "AdvertiserShare": "2.45",
        "Advertiser": "HGodrej Consumer Product",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "BrandValue": "77509377.0",
        "Brand": "EVEREST  MASALA",
        "AdvertiserValue": "162.4",
        "AdvertiserShare": "2.22",
        "Advertiser": "GlaxoSmithKline Asia",
        "Agency": "EVEREST FOOD PRODUCTS PVT LTD"
    },
    {
        "BrandValue": "68600224.5",
        "Brand": "STANDARD HORLICKS",
        "AdvertiserValue": "162.1",
        "AdvertiserShare": "2.21",
        "Advertiser": "Pepsico India Holdi(Del)",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "BrandValue": "64216010.0",
        "Brand": "SUNRISE",
        "AdvertiserValue": "147",
        "AdvertiserShare": "2.01",
        "Advertiser": "ITC Limited(Blr)",
        "Agency": "STRATEGY ADVERTISING & MKTG. (P) LTD."
    },
    {
        "BrandValue": "62039131.559899993",
        "Brand": "MAAZA",
        "AdvertiserValue": "138.4",
        "AdvertiserShare": "1.89",
        "Advertiser": "L'Oreal India Pvt. Ltd",
        "Agency": "MEDIACOM COMM (HAR)"
    },
    {
        "BrandValue": "61038311.5",
        "Brand": "DOVE SHAMPOO",
        "AdvertiserValue": "94.7",
        "AdvertiserShare": "1.29",
        "Advertiser": "Parle Biscuits Private Ltd(.",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    }
]

const totalAdvertiserAndBrandRankingRevenue = [
    {
        "AdRevenue": "11707324495.646538"
    }
]

const keyImpactMetricsDataForLinear = [
    { title: 'Ad Revenue (Mn.)', mtdValue: 1167.99, ytdValue: 33415.97, mtdPercentageComparision: 0.21, ytdPercentageComparision: -20.65 },
    { title: 'Advertisers', mtdValue: 791, ytdValue: 2429, mtdPercentageComparision: 6.17, ytdPercentageComparision: 1.59 },
    { title: 'Ad GRP (K)', mtdValue: 3.370, ytdValue: 379.14, mtdPercentageComparision: 4.34, ytdPercentageComparision: -8.00 },
]

const keyImpactMetricsDataForOTT = [
    { title: 'Ad Revenue (Mn.)', mtdValue: 1167.99, ytdValue: 33415.97, mtdPercentageComparision: 0.21, ytdPercentageComparision: -20.65 },
    { title: 'Advertisers', mtdValue: 791, ytdValue: 2429, mtdPercentageComparision: 6.17, ytdPercentageComparision: 1.59 },
    { title: 'Ad GRP (K)', mtdValue: 3.370, ytdValue: 379.14, mtdPercentageComparision: 4.34, ytdPercentageComparision: -8.00 },
]

const valueCoOrdinatesForSBConvergence = [
    [{
        key: 'north',
        x: 295,
        y: 23,
        value: 6
    }],
    [{
        key: 'east',
        x: 135,
        y: 23,
        value: -39
    }],
    [{
        key: 'south',
        x: 320,
        y: 23,
        value: 15
    }],
    [{
        key: 'west',
        x: 235,
        y: 23,
        value: -1
    }],
]

const politifact = {
    chart1: {
        data: [
            [
                { speaker: "North", ruling: "Mostly false", proportion: -0 },
                { speaker: "data", ruling: "Pants on fire!", proportion: 6 },
            ],
            [
                { speaker: "East", ruling: "Pants on fire!", proportion: -39 },
                { speaker: "data", ruling: "Mostly false", proportion: 0 },
            ],
            [
                { speaker: "South", ruling: "Mostly false", proportion: -0 },
                { speaker: "data", ruling: "Pants on fire!", proportion: 15 },
            ],
            [
                { speaker: "West", ruling: "Mostly false", proportion: -1 },
                { speaker: "data", ruling: "Pants on fire!", proportion: 0 },
            ],

        ],


        label: [
            {
                dd: 'North', dd: 'East', dd: 'South', dd: 'West'
            },
        ],
    },

}

const filterOptions = {
    metricKeyContributionAnalysis: {
        id: 'metricKeyContributionAnalysis',
        label: 'Metric',
        isMendatory: false,
        options: [
            'Revenue',
            'AD GRP',
            'FCT',
        ],
    },

    filterTypeKeyContributionAnalysis: {
        id: 'filterTypeKeyContributionAnalysis',
        label: 'Filter Type',
        isMendatory: false,
        options: [
            'Zones',
            'Channel Genre',
            'Channels'
        ],
    }
}

const filterOptionsForKeyContributionAnalysisCustomerOTT = {
    metricKeyContributionAnalysis: {
        id: 'metricKeyContributionAnalysis',
        label: 'Metric',
        isMendatory: false,
        options: [
            'Revenue',
            'Impressions',
            'Clicks',
        ],
    },

    filterTypeKeyContributionAnalysis: {
        id: 'filterTypeKeyContributionAnalysis',
        label: 'Filter Type',
        isMendatory: false,
        options: [
            'Advertiser Type',
            'Campaign Type',
            'Inventory Type',
            'Zones',
            'Agency',
        ],
    }
}

const filterOptionsForTargetAchievement = {
    id: 'channel',
    label: '',
    isMendatory: false,
    options: [
        'All'
    ],
}

const competetiveAdShareAnalysisSampleDataAdx = [{
    "AdGRP": "282.67180039991217",
    "Network": "Sun",
    "Channel_Name": "Surya Movies",
    "monthYear": "2022-10"
}, {
    "AdGRP": "92.18240019762743",
    "Network": "Sun",
    "Channel_Name": "Surya Music",
    "monthYear": "2022-10"
}, {
    "AdGRP": "327.00390075644827",
    "Network": "Viacom",
    "Channel_Name": "Colors HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "614.47430061399064",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1.9654999972553924",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic HD(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1.1105000053212279",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild HD(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.13739999957761029",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life HD(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "647.59970137482014",
    "Network": "ZEEL",
    "Channel_Name": "Zee Picchar",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1767.0476017930632",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Bollywood",
    "monthYear": "2022-10"
}, {
    "AdGRP": "6963.5819988393341",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa",
    "monthYear": "2022-10"
}, {
    "AdGRP": "5089.0127000422217",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB",
    "monthYear": "2022-10"
}, {
    "AdGRP": "20.336800050121383",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies",
    "monthYear": "2022-10"
}, {
    "AdGRP": "472.00920107145066",
    "Network": "Sun",
    "Channel_Name": "Udaya Comedy",
    "monthYear": "2022-10"
}, {
    "AdGRP": "4.7719999854962225",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pix HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "312.02820062136743",
    "Network": "Sun",
    "Channel_Name": "Gemini Music",
    "monthYear": "2022-10"
}, {
    "AdGRP": "592.27630129960744",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Music",
    "monthYear": "2022-10"
}, {
    "AdGRP": "567.68450117072643",
    "Network": "Sun",
    "Channel_Name": "Sun Music",
    "monthYear": "2022-10"
}, {
    "AdGRP": "465.57890104662511",
    "Network": "Viacom",
    "Channel_Name": "Colors Rishtey",
    "monthYear": "2022-10"
}, {
    "AdGRP": "568.50220101512969",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats",
    "monthYear": "2022-10"
}, {
    "AdGRP": "398.09580095041747",
    "Network": "Sun",
    "Channel_Name": "Sun Marathi",
    "monthYear": "2022-10"
}, {
    "AdGRP": "369.54780096434115",
    "Network": "Sun",
    "Channel_Name": "Gemini Comedy",
    "monthYear": "2022-10"
}, {
    "AdGRP": "970.11030101370125",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav",
    "monthYear": "2022-10"
}, {
    "AdGRP": "688.76250160939526",
    "Network": "Sun",
    "Channel_Name": "Gemini life",
    "monthYear": "2022-10"
}, {
    "AdGRP": "70.876600124036486",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "432.42110117630364",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla Cinema",
    "monthYear": "2022-10"
}, {
    "AdGRP": "16.976100057909207",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.063000001187901944",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "91.845200193209166",
    "Network": "Sun",
    "Channel_Name": "Surya Comedy",
    "monthYear": "2022-10"
}, {
    "AdGRP": "21.101100042833423",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "68.15800010599196",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "441.93980099117471",
    "Network": "Sun",
    "Channel_Name": "Sun Bangla",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1973.7678005683701",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies",
    "monthYear": "2022-10"
}, {
    "AdGRP": "513.5288011013472",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Aath",
    "monthYear": "2022-10"
}, {
    "AdGRP": "842.01590182125074",
    "Network": "Sun",
    "Channel_Name": "Sun Life",
    "monthYear": "2022-10"
}, {
    "AdGRP": "3118.5248025661567",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada",
    "monthYear": "2022-10"
}, {
    "AdGRP": "181.44690020827693",
    "Network": "Star Network Ent",
    "Channel_Name": "Pravah Picture",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1799.480001328513",
    "Network": "ZEEL",
    "Channel_Name": "Big Magic",
    "monthYear": "2022-10"
}, {
    "AdGRP": "105.04700022682664",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "3.2283000102252117",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies HD(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "171.58540045510745",
    "Network": "Viacom",
    "Channel_Name": "Colors Oriya",
    "monthYear": "2022-10"
}, {
    "AdGRP": "26.110300102831388",
    "Network": "Sun",
    "Channel_Name": "Surya TV HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1.4366000017544138",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "621.0315007356694",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Gold",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1361.2197011635872",
    "Network": "Viacom",
    "Channel_Name": "NICK(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1578.1600012779236",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna",
    "monthYear": "2022-10"
}, {
    "AdGRP": "649.37590125115094",
    "Network": "ZEEL",
    "Channel_Name": "Zee Action",
    "monthYear": "2022-10"
}, {
    "AdGRP": "10.963700015978247",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "552.88540134790674",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna Plus",
    "monthYear": "2022-10"
}, {
    "AdGRP": "961.727900944963",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex",
    "monthYear": "2022-10"
}, {
    "AdGRP": "101.92410002349061",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "324.31610063749395",
    "Network": "Sun",
    "Channel_Name": "Kushi TV",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1736.7563014827901",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Movies",
    "monthYear": "2022-10"
}, {
    "AdGRP": "774.94170126971585",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX 2",
    "monthYear": "2022-10"
}, {
    "AdGRP": "25.660100040135148",
    "Network": "Sun",
    "Channel_Name": "Sun Music HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "104.60500021793996",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX HD(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.021900000632740557",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1576.5464017779959",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1352.8214013657998",
    "Network": "Sun",
    "Channel_Name": "Udaya Movies",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1.8187000045363675",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "2330.2797007671688",
    "Network": "Sun",
    "Channel_Name": "Gemini TV",
    "monthYear": "2022-10"
}, {
    "AdGRP": "975.14260121967527",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony YAY(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.3974999965284951",
    "Network": "Star Network Ent",
    "Channel_Name": "Pravah Picture HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "5183.3695004513138",
    "Network": "Viacom",
    "Channel_Name": "Colors",
    "monthYear": "2022-10"
}, {
    "AdGRP": "633.82890113126632",
    "Network": "Viacom",
    "Channel_Name": "Sonic Nickelodeon(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "696.70030167652294",
    "Network": "Viacom",
    "Channel_Name": "NICK Junior(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.47610000095301075",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "80.193900171514542",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "32.778500058506324",
    "Network": "Sun",
    "Channel_Name": "Udaya TV HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1.3081000053716707",
    "Network": "Viacom",
    "Channel_Name": "MTV HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "2054.8842013717513",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Superhits",
    "monthYear": "2022-10"
}, {
    "AdGRP": "22.382500048493966",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Kiran",
    "monthYear": "2022-10"
}, {
    "AdGRP": "2396.0806002781756",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1522.0127009511925",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi",
    "monthYear": "2022-10"
}, {
    "AdGRP": "238.92450014031056",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "238.47440044543328",
    "Network": "ZEEL",
    "Channel_Name": "Zee Yuva",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.83780000876140548",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix",
    "monthYear": "2022-10"
}, {
    "AdGRP": "468.19970089181879",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.00039999998989515007",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Kiran HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "738.96370154659962",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV",
    "monthYear": "2022-10"
}, {
    "AdGRP": "118.4779003556323",
    "Network": "Sun",
    "Channel_Name": "KTV HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "563.68910050971317",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "2003.04070252573",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema",
    "monthYear": "2022-10"
}, {
    "AdGRP": "10.104600052087335",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "8.933400030102348",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "4.33610002133355",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.061100000377336983",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central",
    "monthYear": "2022-10"
}, {
    "AdGRP": "994.52200103548239",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold 2",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1118.5435014698669",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures",
    "monthYear": "2022-10"
}, {
    "AdGRP": "56.594100190704921",
    "Network": "Sun",
    "Channel_Name": "Gemini TV HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1870.0561017629225",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu",
    "monthYear": "2022-10"
}, {
    "AdGRP": "515.51900146811386",
    "Network": "ZEEL",
    "Channel_Name": "Zee Thirai",
    "monthYear": "2022-10"
}, {
    "AdGRP": "451.6509010882437",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada Cinema",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.11760000184585806",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World",
    "monthYear": "2022-10"
}, {
    "AdGRP": "646.57290168175678",
    "Network": "ZEEL",
    "Channel_Name": "Zing",
    "monthYear": "2022-10"
}, {
    "AdGRP": "105.19620022609161",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "33.117500060841849",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.049199999950360507",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Prive HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "27.613200066996797",
    "Network": "Viacom",
    "Channel_Name": "History TV 18(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "2535.51440029731",
    "Network": "Sun",
    "Channel_Name": "KTV",
    "monthYear": "2022-10"
}, {
    "AdGRP": "4461.63819835239",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah",
    "monthYear": "2022-10"
}, {
    "AdGRP": "3447.210400498705",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.58780000212573214",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth HD(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "228.85960067636915",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "35.694600091148459",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "49.343700108474877",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa Movies HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1929.7137016576598",
    "Network": "Sun",
    "Channel_Name": "Udaya TV",
    "monthYear": "2022-10"
}, {
    "AdGRP": "11.527100021048682",
    "Network": "Viacom",
    "Channel_Name": "VH1",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.67720000945701031",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies Select HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1.0718000070846756",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "472.6372006534657",
    "Network": "ZEEL",
    "Channel_Name": "Zee Chitramandir",
    "monthYear": "2022-10"
}, {
    "AdGRP": "479.90180126039922",
    "Network": "Sun",
    "Channel_Name": "Surya TV",
    "monthYear": "2022-10"
}, {
    "AdGRP": "4774.26640068047",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV",
    "monthYear": "2022-10"
}, {
    "AdGRP": "2339.6074014626793",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav Movies",
    "monthYear": "2022-10"
}, {
    "AdGRP": "29.92340005980077",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "693.23460163885466",
    "Network": "ZEEL",
    "Channel_Name": "Zee Biskope",
    "monthYear": "2022-10"
}, {
    "AdGRP": "5142.5085027996683",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada",
    "monthYear": "2022-10"
}, {
    "AdGRP": "5511.0297013266827",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.015400000302179251",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cafe HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "275.68890040281258",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "169.36530044321989",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "284.50710057929246",
    "Network": "Sun",
    "Channel_Name": "Chutti TV",
    "monthYear": "2022-10"
}, {
    "AdGRP": "104.45380021017627",
    "Network": "Viacom",
    "Channel_Name": "MTV",
    "monthYear": "2022-10"
}, {
    "AdGRP": "3016.8385021763315",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol Cinema",
    "monthYear": "2022-10"
}, {
    "AdGRP": "14.401500033214688",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Suvarna HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "322.32350076477451",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla",
    "monthYear": "2022-10"
}, {
    "AdGRP": "256.7579004036379",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol",
    "monthYear": "2022-10"
}, {
    "AdGRP": "24.554600039737124",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.67440000228816643",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "5.2942000083712628",
    "Network": "Sun",
    "Channel_Name": "Gemini Music HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "478.0165009660268",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.868800005002413",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select SD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "2439.25940256333",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1702.7305014078738",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.57920000474405242",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1.4134000111953355",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "974.01770187967486",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bollywood",
    "monthYear": "2022-10"
}, {
    "AdGRP": "50.287100119821844",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "387.94650116789853",
    "Network": "Viacom",
    "Channel_Name": "Colors Super",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1070.2343015389924",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay Super",
    "monthYear": "2022-10"
}, {
    "AdGRP": "723.16050089430064",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Marathi",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.28190000136964954",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Xplor HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.12690000076690922",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest HD(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1088.5733991161687",
    "Network": "ZEEL",
    "Channel_Name": "Zee Sarthak",
    "monthYear": "2022-10"
}, {
    "AdGRP": "386.34150083427085",
    "Network": "ZEEL",
    "Channel_Name": "Zee Classic",
    "monthYear": "2022-10"
}, {
    "AdGRP": "656.82370116873062",
    "Network": "Sun",
    "Channel_Name": "Sun TV HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "249.50170057541982",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati",
    "monthYear": "2022-10"
}, {
    "AdGRP": "588.51330126040557",
    "Network": "Sun",
    "Channel_Name": "Udaya Music",
    "monthYear": "2022-10"
}, {
    "AdGRP": "3378.5453009190824",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla",
    "monthYear": "2022-10"
}, {
    "AdGRP": "378.98470078697574",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Movies",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1979.2802013753244",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Wah",
    "monthYear": "2022-10"
}, {
    "AdGRP": "69.958900106605142",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "102.08430021190725",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "132.74680031477328",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "155.8834003857919",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati Cinema",
    "monthYear": "2022-10"
}, {
    "AdGRP": "7807.7195013500168",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1830.9611012158348",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1323.0201023113914",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat",
    "monthYear": "2022-10"
}, {
    "AdGRP": "846.38280109578045",
    "Network": "ZEEL",
    "Channel_Name": "Zee Punjabi",
    "monthYear": "2022-10"
}, {
    "AdGRP": "765.74520166276488",
    "Network": "Sun",
    "Channel_Name": "Adithya TV",
    "monthYear": "2022-10"
}, {
    "AdGRP": "480.16270083424752",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies",
    "monthYear": "2022-10"
}, {
    "AdGRP": "798.463801532751",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies",
    "monthYear": "2022-10"
}, {
    "AdGRP": "1226.5950016007409",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pal",
    "monthYear": "2022-10"
}, {
    "AdGRP": "125.25320032773016",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.0089000001244130544",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity SD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.21100000158185139",
    "Network": "Viacom",
    "Channel_Name": "Nick HD+(v)",
    "monthYear": "2022-10"
}, {
    "AdGRP": "9589.8370036724955",
    "Network": "Sun",
    "Channel_Name": "Sun TV",
    "monthYear": "2022-10"
}, {
    "AdGRP": "5125.9406995639438",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay",
    "monthYear": "2022-10"
}, {
    "AdGRP": "545.24170146872348",
    "Network": "ZEEL",
    "Channel_Name": "Zee Ganga",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.26080000320507679",
    "Network": "Viacom",
    "Channel_Name": "VH1 HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "400.61580084663",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla Cinema",
    "monthYear": "2022-10"
}, {
    "AdGRP": "123.39870029933809",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Plus",
    "monthYear": "2022-10"
}, {
    "AdGRP": "241.49770053810789",
    "Network": "Sun",
    "Channel_Name": "Chintu TV",
    "monthYear": "2022-10"
}, {
    "AdGRP": "179.26280040163692",
    "Network": "Sun",
    "Channel_Name": "Kochu TV",
    "monthYear": "2022-10"
}, {
    "AdGRP": "15.853300036098517",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony PIX",
    "monthYear": "2022-10"
}, {
    "AdGRP": "3334.9727026369364",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.00050000002374872565",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World Premiere HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.0017000000734697096",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "7.8453000277731917",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "53.829100013390416",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "0.27100000156497117",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla HD",
    "monthYear": "2022-10"
}, {
    "AdGRP": "8.3951000140586984",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "26.249800036435772",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "2.2716000167711172",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic HD(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.19710000298073282",
    "Network": "Viacom",
    "Channel_Name": "VH1 HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.6740000060890452",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "228.25430042790686",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "5400.9326008404605",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay",
    "monthYear": "2022-11"
}, {
    "AdGRP": "800.4816010047216",
    "Network": "ZEEL",
    "Channel_Name": "Zee Punjabi",
    "monthYear": "2022-11"
}, {
    "AdGRP": "15.843700033445202",
    "Network": "Sun",
    "Channel_Name": "Sun Music HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.036599999606551137",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1.1805000119202305",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild HD(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "326.21060085319186",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla Cinema",
    "monthYear": "2022-11"
}, {
    "AdGRP": "4.067600004360429",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "27.989000023939298",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "72.634000149730127",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "6548.0173012920422",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa",
    "monthYear": "2022-11"
}, {
    "AdGRP": "5.3019000124622835",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony PIX",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1.7390000022714958",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "5366.0285975440347",
    "Network": "Viacom",
    "Channel_Name": "Colors",
    "monthYear": "2022-11"
}, {
    "AdGRP": "196.39070045891276",
    "Network": "Sun",
    "Channel_Name": "Kochu TV",
    "monthYear": "2022-11"
}, {
    "AdGRP": "287.60330063735455",
    "Network": "Sun",
    "Channel_Name": "Kushi TV",
    "monthYear": "2022-11"
}, {
    "AdGRP": "893.7320015448131",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX 2",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1.4011000048267306",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pix HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "311.54360048435774",
    "Network": "Sun",
    "Channel_Name": "Gemini Music",
    "monthYear": "2022-11"
}, {
    "AdGRP": "2096.180801176466",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1112.2313016771441",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1.459300002650707",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies HD(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.23750000110885594",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "378.82620052815764",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol",
    "monthYear": "2022-11"
}, {
    "AdGRP": "46.973800145322457",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "2750.6752004749142",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television",
    "monthYear": "2022-11"
}, {
    "AdGRP": "2120.8371006358939",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1370.3113019401208",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pal",
    "monthYear": "2022-11"
}, {
    "AdGRP": "9468.3069992509554",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1225.12840171501",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold 2",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.0010000000183936208",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "485.52630099421367",
    "Network": "Sun",
    "Channel_Name": "Surya TV",
    "monthYear": "2022-11"
}, {
    "AdGRP": "2632.065101279557",
    "Network": "Sun",
    "Channel_Name": "Gemini TV",
    "monthYear": "2022-11"
}, {
    "AdGRP": "2142.301302315158",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema",
    "monthYear": "2022-11"
}, {
    "AdGRP": "439.3153012405528",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil",
    "monthYear": "2022-11"
}, {
    "AdGRP": "77.589400234595814",
    "Network": "Sun",
    "Channel_Name": "Surya Comedy",
    "monthYear": "2022-11"
}, {
    "AdGRP": "147.41090026695747",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "447.757101179508",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna Plus",
    "monthYear": "2022-11"
}, {
    "AdGRP": "807.04320126041421",
    "Network": "Sun",
    "Channel_Name": "Gemini life",
    "monthYear": "2022-11"
}, {
    "AdGRP": "483.51570131603512",
    "Network": "Sun",
    "Channel_Name": "Sun Music",
    "monthYear": "2022-11"
}, {
    "AdGRP": "3652.5414030278989",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1786.3714021166961",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "5324.2868008101359",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada",
    "monthYear": "2022-11"
}, {
    "AdGRP": "2015.4111005588202",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi",
    "monthYear": "2022-11"
}, {
    "AdGRP": "799.90660176085657",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Marathi",
    "monthYear": "2022-11"
}, {
    "AdGRP": "433.73200087518489",
    "Network": "Viacom",
    "Channel_Name": "Colors Rishtey",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.066500000102678314",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central",
    "monthYear": "2022-11"
}, {
    "AdGRP": "696.03620163002051",
    "Network": "Viacom",
    "Channel_Name": "NICK Junior(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "24.265100045908184",
    "Network": "Viacom",
    "Channel_Name": "History TV 18(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "2906.0693018047386",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol Cinema",
    "monthYear": "2022-11"
}, {
    "AdGRP": "512.70640120189637",
    "Network": "ZEEL",
    "Channel_Name": "Zee Thirai",
    "monthYear": "2022-11"
}, {
    "AdGRP": "600.837801075555",
    "Network": "ZEEL",
    "Channel_Name": "Zee Picchar",
    "monthYear": "2022-11"
}, {
    "AdGRP": "71.75530016245466",
    "Network": "Sun",
    "Channel_Name": "Gemini TV HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.0",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Kiran HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "7.183000027158414",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1575.478402458597",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat",
    "monthYear": "2022-11"
}, {
    "AdGRP": "369.02770070936822",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Movies",
    "monthYear": "2022-11"
}, {
    "AdGRP": "245.94790049356379",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati",
    "monthYear": "2022-11"
}, {
    "AdGRP": "715.03440072808735",
    "Network": "ZEEL",
    "Channel_Name": "Zee Chitramandir",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.68710000246210257",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select SD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "5429.5363990489859",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB",
    "monthYear": "2022-11"
}, {
    "AdGRP": "259.22160074761632",
    "Network": "ZEEL",
    "Channel_Name": "Zee Yuva",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1818.4719016198069",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold",
    "monthYear": "2022-11"
}, {
    "AdGRP": "541.80150090425741",
    "Network": "Sun",
    "Channel_Name": "Adithya TV",
    "monthYear": "2022-11"
}, {
    "AdGRP": "51.25890014765173",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.09380000049713999",
    "Network": "Star Network Ent",
    "Channel_Name": "Pravah Picture HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.000800000037997961",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World Premiere HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1953.3949016644619",
    "Network": "Sun",
    "Channel_Name": "KTV",
    "monthYear": "2022-11"
}, {
    "AdGRP": "723.52420145391079",
    "Network": "ZEEL",
    "Channel_Name": "Zing",
    "monthYear": "2022-11"
}, {
    "AdGRP": "9245.50080470182",
    "Network": "Sun",
    "Channel_Name": "Sun TV",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1002.7196018298273",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony YAY(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1174.1227012535019",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav",
    "monthYear": "2022-11"
}, {
    "AdGRP": "5627.6043971935287",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1209.83460151311",
    "Network": "ZEEL",
    "Channel_Name": "Zee Sarthak",
    "monthYear": "2022-11"
}, {
    "AdGRP": "190.00170034485927",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "97.811400285230775",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.098200000698852818",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest HD(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.67780001645587618",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.00930000033986289",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.070900000377150718",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Prive HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "373.94120092065714",
    "Network": "Sun",
    "Channel_Name": "Gemini Comedy",
    "monthYear": "2022-11"
}, {
    "AdGRP": "6.58660000605596",
    "Network": "Sun",
    "Channel_Name": "Gemini Music HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1899.224102100241",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Bollywood",
    "monthYear": "2022-11"
}, {
    "AdGRP": "835.80820157076232",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV",
    "monthYear": "2022-11"
}, {
    "AdGRP": "3414.2690012282692",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil",
    "monthYear": "2022-11"
}, {
    "AdGRP": "519.64060087634425",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam",
    "monthYear": "2022-11"
}, {
    "AdGRP": "25.29180005195667",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "705.7566004882101",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1313.7728015198954",
    "Network": "Sun",
    "Channel_Name": "Udaya Movies",
    "monthYear": "2022-11"
}, {
    "AdGRP": "103.95510025994736",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1.9946000035342877",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "101.60220025667513",
    "Network": "Viacom",
    "Channel_Name": "MTV",
    "monthYear": "2022-11"
}, {
    "AdGRP": "3.2971000042452943",
    "Network": "Viacom",
    "Channel_Name": "VH1",
    "monthYear": "2022-11"
}, {
    "AdGRP": "2104.6361012767884",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Wah",
    "monthYear": "2022-11"
}, {
    "AdGRP": "496.46440064098715",
    "Network": "Sun",
    "Channel_Name": "Sun Bangla",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1832.4292015687388",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna",
    "monthYear": "2022-11"
}, {
    "AdGRP": "91.4616002152834",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX HD(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "86.540100159443682",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "68.480700131956837",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1900.0200009180699",
    "Network": "ZEEL",
    "Channel_Name": "Big Magic",
    "monthYear": "2022-11"
}, {
    "AdGRP": "515.64630104997923",
    "Network": "ZEEL",
    "Channel_Name": "Zee Ganga",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.39910000297095394",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "43.896000161534175",
    "Network": "Sun",
    "Channel_Name": "Udaya TV HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.0071999998617684469",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity SD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "662.01510146597866",
    "Network": "ZEEL",
    "Channel_Name": "Zee Action",
    "monthYear": "2022-11"
}, {
    "AdGRP": "772.93780125398189",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies",
    "monthYear": "2022-11"
}, {
    "AdGRP": "145.04510022677277",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Plus",
    "monthYear": "2022-11"
}, {
    "AdGRP": "408.43060068056366",
    "Network": "Sun",
    "Channel_Name": "Udaya Comedy",
    "monthYear": "2022-11"
}, {
    "AdGRP": "40.059300109773176",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "609.7275012768223",
    "Network": "Viacom",
    "Channel_Name": "Sonic Nickelodeon(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1945.4655008762202",
    "Network": "Sun",
    "Channel_Name": "Udaya TV",
    "monthYear": "2022-11"
}, {
    "AdGRP": "658.12450134072424",
    "Network": "ZEEL",
    "Channel_Name": "Zee Biskope",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1381.3674021153711",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Movies",
    "monthYear": "2022-11"
}, {
    "AdGRP": "6.4889999954611994",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1688.6051009275325",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.25249999939842382",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix",
    "monthYear": "2022-11"
}, {
    "AdGRP": "427.66920113041851",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla Cinema",
    "monthYear": "2022-11"
}, {
    "AdGRP": "480.71710081715719",
    "Network": "Sun",
    "Channel_Name": "Sun TV HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "491.58840105133277",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Aath",
    "monthYear": "2022-11"
}, {
    "AdGRP": "648.18030068544613",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "669.11080057126674",
    "Network": "Sun",
    "Channel_Name": "Sun Marathi",
    "monthYear": "2022-11"
}, {
    "AdGRP": "112.76380023607635",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.419500006333692",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "21.752700045035454",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Suvarna HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "2495.1799020505277",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav Movies",
    "monthYear": "2022-11"
}, {
    "AdGRP": "2173.526200691791",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Superhits",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.46690000411035726",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "7.20150001658476",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "220.7399004907129",
    "Network": "Sun",
    "Channel_Name": "Chutti TV",
    "monthYear": "2022-11"
}, {
    "AdGRP": "259.96250050071831",
    "Network": "Viacom",
    "Channel_Name": "Colors HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.18540000048233196",
    "Network": "Viacom",
    "Channel_Name": "Nick HD+(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "585.96930130993132",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Music",
    "monthYear": "2022-11"
}, {
    "AdGRP": "735.04490159353008",
    "Network": "Sun",
    "Channel_Name": "Sun Life",
    "monthYear": "2022-11"
}, {
    "AdGRP": "146.41120032897743",
    "Network": "Sun",
    "Channel_Name": "Chintu TV",
    "monthYear": "2022-11"
}, {
    "AdGRP": "65.204300132383651",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.0069000000803498551",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cafe HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1660.8916021354962",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1044.8569023390664",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bollywood",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1283.9062012811191",
    "Network": "Viacom",
    "Channel_Name": "NICK(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1203.1604024007611",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures",
    "monthYear": "2022-11"
}, {
    "AdGRP": "116.30890020336665",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "433.09580111024115",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada Cinema",
    "monthYear": "2022-11"
}, {
    "AdGRP": "5239.3964003973524",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV",
    "monthYear": "2022-11"
}, {
    "AdGRP": "573.31510078205611",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Gold",
    "monthYear": "2022-11"
}, {
    "AdGRP": "76.763000107530388",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.227500001565204",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth HD(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.21049999930255581",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Xplor HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "129.57620035808941",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Kiran",
    "monthYear": "2022-11"
}, {
    "AdGRP": "101.23300023873162",
    "Network": "Sun",
    "Channel_Name": "Surya Music",
    "monthYear": "2022-11"
}, {
    "AdGRP": "492.96500113591173",
    "Network": "Sun",
    "Channel_Name": "Udaya Music",
    "monthYear": "2022-11"
}, {
    "AdGRP": "451.88140120133176",
    "Network": "ZEEL",
    "Channel_Name": "Zee Classic",
    "monthYear": "2022-11"
}, {
    "AdGRP": "261.39150032589532",
    "Network": "Sun",
    "Channel_Name": "Surya Movies",
    "monthYear": "2022-11"
}, {
    "AdGRP": "3207.1373992417648",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.14430000125139486",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life HD(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "87.743600223933754",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1064.5931021830766",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay Super",
    "monthYear": "2022-11"
}, {
    "AdGRP": "133.56240027489548",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati Cinema",
    "monthYear": "2022-11"
}, {
    "AdGRP": "263.54270033301145",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "21.924000045466528",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "77.190700256745913",
    "Network": "Sun",
    "Channel_Name": "KTV HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "5.2551000098028453",
    "Network": "Viacom",
    "Channel_Name": "MTV HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "372.99880084302276",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.12320000046020141",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World",
    "monthYear": "2022-11"
}, {
    "AdGRP": "4332.2064009222668",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah",
    "monthYear": "2022-11"
}, {
    "AdGRP": "182.86170043738821",
    "Network": "Star Network Ent",
    "Channel_Name": "Pravah Picture",
    "monthYear": "2022-11"
}, {
    "AdGRP": "25.151300066296244",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "4.9677000206356752",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth(v)",
    "monthYear": "2022-11"
}, {
    "AdGRP": "39.098900089440576",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa Movies HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "3820.2205023444258",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha",
    "monthYear": "2022-11"
}, {
    "AdGRP": "26.347400071572338",
    "Network": "Sun",
    "Channel_Name": "Surya TV HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "111.13770024258702",
    "Network": "Viacom",
    "Channel_Name": "Colors Oriya",
    "monthYear": "2022-11"
}, {
    "AdGRP": "404.167900974644",
    "Network": "Viacom",
    "Channel_Name": "Colors Super",
    "monthYear": "2022-11"
}, {
    "AdGRP": "355.4259008789013",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla",
    "monthYear": "2022-11"
}, {
    "AdGRP": "407.91600106909027",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies",
    "monthYear": "2022-11"
}, {
    "AdGRP": "0.28350000194768654",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies Select HD",
    "monthYear": "2022-11"
}, {
    "AdGRP": "1436.7125009244774",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Movies",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1466.5892015501158",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna",
    "monthYear": "2022-12"
}, {
    "AdGRP": "286.196200465507",
    "Network": "Sun",
    "Channel_Name": "Udaya Comedy",
    "monthYear": "2022-12"
}, {
    "AdGRP": "476.00930089667236",
    "Network": "ZEEL",
    "Channel_Name": "Zee Action",
    "monthYear": "2022-12"
}, {
    "AdGRP": "555.64980108152668",
    "Network": "ZEEL",
    "Channel_Name": "Zing",
    "monthYear": "2022-12"
}, {
    "AdGRP": "637.85770107463759",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX 2",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1316.0125009803742",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies",
    "monthYear": "2022-12"
}, {
    "AdGRP": "4592.6857999269851",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah",
    "monthYear": "2022-12"
}, {
    "AdGRP": "378.1698008973035",
    "Network": "Sun",
    "Channel_Name": "Sun Music",
    "monthYear": "2022-12"
}, {
    "AdGRP": "681.69120119867148",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV",
    "monthYear": "2022-12"
}, {
    "AdGRP": "2005.9246021099389",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Wah",
    "monthYear": "2022-12"
}, {
    "AdGRP": "60.246500140994613",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "117.35700020920194",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1392.9256018431188",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold",
    "monthYear": "2022-12"
}, {
    "AdGRP": "942.30600147566292",
    "Network": "Sun",
    "Channel_Name": "Udaya Movies",
    "monthYear": "2022-12"
}, {
    "AdGRP": "560.83850031917973",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.0014000000082887709",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World Premiere HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "36.3927001075499",
    "Network": "Sun",
    "Channel_Name": "Gemini TV HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1.1286000047330162",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild HD(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "166.5399003179773",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "499.60970122813887",
    "Network": "ZEEL",
    "Channel_Name": "Zee Biskope",
    "monthYear": "2022-12"
}, {
    "AdGRP": "168.10240030231216",
    "Network": "Sun",
    "Channel_Name": "Kochu TV",
    "monthYear": "2022-12"
}, {
    "AdGRP": "7290.22130414471",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus",
    "monthYear": "2022-12"
}, {
    "AdGRP": "429.04210076206073",
    "Network": "Sun",
    "Channel_Name": "Surya TV",
    "monthYear": "2022-12"
}, {
    "AdGRP": "13.761400052790123",
    "Network": "Sun",
    "Channel_Name": "Surya TV HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "19.55960005839006",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "74.642300089188211",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "3074.5508985741035",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.51379999709752155",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1421.0099018978945",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Bollywood",
    "monthYear": "2022-12"
}, {
    "AdGRP": "87.9252001128043",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.14930000089225359",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "322.34020064963261",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada Cinema",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.10530000063590705",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest HD(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "460.10680081653845",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Music",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1333.4509017693345",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "396.53470060434483",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Movies",
    "monthYear": "2022-12"
}, {
    "AdGRP": "20.279600044559629",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.22960000046441564",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.17749999964144081",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central",
    "monthYear": "2022-12"
}, {
    "AdGRP": "93.17660027070815",
    "Network": "Viacom",
    "Channel_Name": "MTV",
    "monthYear": "2022-12"
}, {
    "AdGRP": "69.4079001916325",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "4.0357000049189082",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "964.909600689658",
    "Network": "ZEEL",
    "Channel_Name": "Zee Sarthak",
    "monthYear": "2022-12"
}, {
    "AdGRP": "453.5878009370208",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna Plus",
    "monthYear": "2022-12"
}, {
    "AdGRP": "518.30390091368463",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies",
    "monthYear": "2022-12"
}, {
    "AdGRP": "172.565000390794",
    "Network": "ZEEL",
    "Channel_Name": "Zee Yuva",
    "monthYear": "2022-12"
}, {
    "AdGRP": "43.959000131675566",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "90.340300216928881",
    "Network": "Viacom",
    "Channel_Name": "Colors Oriya",
    "monthYear": "2022-12"
}, {
    "AdGRP": "23.376600043367944",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Suvarna HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "190.58420026733074",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati",
    "monthYear": "2022-12"
}, {
    "AdGRP": "721.73510122322477",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony YAY(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.62160000107542146",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select SD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "86.622800239769276",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "280.06490073330497",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla",
    "monthYear": "2022-12"
}, {
    "AdGRP": "212.97380032301589",
    "Network": "Viacom",
    "Channel_Name": "Colors HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "42.425400105683366",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.37970000271161553",
    "Network": "Viacom",
    "Channel_Name": "VH1 HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "346.63450077050948",
    "Network": "Sun",
    "Channel_Name": "Udaya Music",
    "monthYear": "2022-12"
}, {
    "AdGRP": "2.4395000072108814",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "16.53050003683893",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.021100000114529394",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity SD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "186.11370039421308",
    "Network": "Sun",
    "Channel_Name": "Surya Movies",
    "monthYear": "2022-12"
}, {
    "AdGRP": "277.10820045189757",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol",
    "monthYear": "2022-12"
}, {
    "AdGRP": "564.48690115025965",
    "Network": "Viacom",
    "Channel_Name": "NICK Junior(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.0033000000912579708",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1771.9863012059941",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav Movies",
    "monthYear": "2022-12"
}, {
    "AdGRP": "861.96090178884333",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay Super",
    "monthYear": "2022-12"
}, {
    "AdGRP": "26.406400072650285",
    "Network": "Sun",
    "Channel_Name": "Udaya TV HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.0",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Kiran HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "4266.8319982951507",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu",
    "monthYear": "2022-12"
}, {
    "AdGRP": "72.120000163260556",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "556.5509009079251",
    "Network": "Sun",
    "Channel_Name": "Sun Life",
    "monthYear": "2022-12"
}, {
    "AdGRP": "912.93850067825406",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pal",
    "monthYear": "2022-12"
}, {
    "AdGRP": "5.579300027267891",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.56600000253820326",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "367.82860080394312",
    "Network": "Sun",
    "Channel_Name": "Sun Bangla",
    "monthYear": "2022-12"
}, {
    "AdGRP": "451.70490092237014",
    "Network": "Sun",
    "Channel_Name": "Adithya TV",
    "monthYear": "2022-12"
}, {
    "AdGRP": "226.47930047025875",
    "Network": "Sun",
    "Channel_Name": "Kushi TV",
    "monthYear": "2022-12"
}, {
    "AdGRP": "54.07620012381085",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1565.2166007830965",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema",
    "monthYear": "2022-12"
}, {
    "AdGRP": "2597.4817002043128",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil",
    "monthYear": "2022-12"
}, {
    "AdGRP": "347.34150054903876",
    "Network": "ZEEL",
    "Channel_Name": "Zee Ganga",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.712800005923782",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix",
    "monthYear": "2022-12"
}, {
    "AdGRP": "190.49820043412183",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla Cinema",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1406.1093010010372",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1202.0809014851693",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu",
    "monthYear": "2022-12"
}, {
    "AdGRP": "298.8872005657322",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats",
    "monthYear": "2022-12"
}, {
    "AdGRP": "404.5114007180091",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.22850000255857594",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Xplor HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.33980000520386966",
    "Network": "Star Network Ent",
    "Channel_Name": "Pravah Picture HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "4183.4034028892929",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada",
    "monthYear": "2022-12"
}, {
    "AdGRP": "289.21230067149008",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla Cinema",
    "monthYear": "2022-12"
}, {
    "AdGRP": "62.188200163116562",
    "Network": "Sun",
    "Channel_Name": "Surya Comedy",
    "monthYear": "2022-12"
}, {
    "AdGRP": "332.650800749514",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Aath",
    "monthYear": "2022-12"
}, {
    "AdGRP": "296.45730078702036",
    "Network": "ZEEL",
    "Channel_Name": "Zee Classic",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.93740000127581879",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies HD(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "491.92820083558763",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1984.4203000643392",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1.7453000120149227",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pix HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "37.435100093498477",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "98.62900017498032",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1.7120000101422193",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic HD(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "4253.7999015236273",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay",
    "monthYear": "2022-12"
}, {
    "AdGRP": "170.91390041037812",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "64.813700159545988",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX HD(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.016000000374333467",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "6439.90040397644",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa",
    "monthYear": "2022-12"
}, {
    "AdGRP": "13.989500022827997",
    "Network": "Sun",
    "Channel_Name": "Sun Music HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "7.25000002807792",
    "Network": "Viacom",
    "Channel_Name": "MTV HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1550.8704014627147",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Superhits",
    "monthYear": "2022-12"
}, {
    "AdGRP": "126.25220026238821",
    "Network": "Sun",
    "Channel_Name": "Chintu TV",
    "monthYear": "2022-12"
}, {
    "AdGRP": "3792.5197996174684",
    "Network": "Viacom",
    "Channel_Name": "Colors",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1390.3695011069067",
    "Network": "Sun",
    "Channel_Name": "KTV",
    "monthYear": "2022-12"
}, {
    "AdGRP": "565.21130098313006",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Gold",
    "monthYear": "2022-12"
}, {
    "AdGRP": "938.81670152310107",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold 2",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.02749999984371243",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World",
    "monthYear": "2022-12"
}, {
    "AdGRP": "831.68330153789429",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures",
    "monthYear": "2022-12"
}, {
    "AdGRP": "64.264700156170875",
    "Network": "Sun",
    "Channel_Name": "Surya Music",
    "monthYear": "2022-12"
}, {
    "AdGRP": "12.746500028872106",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "70.287700171298638",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1.701900003485207",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "368.71810030892811",
    "Network": "ZEEL",
    "Channel_Name": "Zee Chitramandir",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.10619999947812175",
    "Network": "Viacom",
    "Channel_Name": "Nick HD+(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "618.58470086190209",
    "Network": "ZEEL",
    "Channel_Name": "Zee Punjabi",
    "monthYear": "2022-12"
}, {
    "AdGRP": "142.84360036616272",
    "Network": "Sun",
    "Channel_Name": "Chutti TV",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.1057000005239388",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies Select HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "46.499800131685333",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa Movies HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "493.71630059613381",
    "Network": "Sun",
    "Channel_Name": "Sun Marathi",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1715.9483007629606",
    "Network": "Sun",
    "Channel_Name": "Gemini TV",
    "monthYear": "2022-12"
}, {
    "AdGRP": "23.427900044494891",
    "Network": "Viacom",
    "Channel_Name": "History TV 18(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "3651.6500003347173",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB",
    "monthYear": "2022-12"
}, {
    "AdGRP": "838.99240131404076",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.018300000221643131",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cafe HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "582.22680160478194",
    "Network": "Sun",
    "Channel_Name": "Gemini life",
    "monthYear": "2022-12"
}, {
    "AdGRP": "4.1074000186636113",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "575.49510120348714",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Marathi",
    "monthYear": "2022-12"
}, {
    "AdGRP": "244.46550056815613",
    "Network": "Sun",
    "Channel_Name": "Gemini Comedy",
    "monthYear": "2022-12"
}, {
    "AdGRP": "2009.089000931941",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television",
    "monthYear": "2022-12"
}, {
    "AdGRP": "5.1849000097208773",
    "Network": "Viacom",
    "Channel_Name": "VH1",
    "monthYear": "2022-12"
}, {
    "AdGRP": "3467.3093000144581",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV",
    "monthYear": "2022-12"
}, {
    "AdGRP": "94.55560024407896",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Kiran",
    "monthYear": "2022-12"
}, {
    "AdGRP": "111.49660025483172",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Plus",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1212.3185005984269",
    "Network": "ZEEL",
    "Channel_Name": "Big Magic",
    "monthYear": "2022-12"
}, {
    "AdGRP": "815.88640133426088",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.24710000365303131",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth HD(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "352.492400916708",
    "Network": "Viacom",
    "Channel_Name": "Colors Rishtey",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.012100000254577026",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "378.6901009590365",
    "Network": "ZEEL",
    "Channel_Name": "Zee Thirai",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1.2381000037494232",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1055.7891015054774",
    "Network": "Viacom",
    "Channel_Name": "NICK(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "3561.0706995149958",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1220.1718016452651",
    "Network": "Sun",
    "Channel_Name": "Udaya TV",
    "monthYear": "2022-12"
}, {
    "AdGRP": "275.63850063207065",
    "Network": "Viacom",
    "Channel_Name": "Colors Super",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.051600000173493754",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life HD(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "752.364001613365",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bollywood",
    "monthYear": "2022-12"
}, {
    "AdGRP": "102.40350023350766",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati Cinema",
    "monthYear": "2022-12"
}, {
    "AdGRP": "444.72490086836478",
    "Network": "Viacom",
    "Channel_Name": "Sonic Nickelodeon(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.073000000214960892",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Prive HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "425.6988008889748",
    "Network": "ZEEL",
    "Channel_Name": "Zee Picchar",
    "monthYear": "2022-12"
}, {
    "AdGRP": "222.55400053079211",
    "Network": "Sun",
    "Channel_Name": "Gemini Music",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.15830000105779618",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "183.89400035719154",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "57.526500107211177",
    "Network": "Sun",
    "Channel_Name": "KTV HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "2018.340501290877",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol Cinema",
    "monthYear": "2022-12"
}, {
    "AdGRP": "4.0328000039589824",
    "Network": "Sun",
    "Channel_Name": "Gemini Music HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "198.0732004373931",
    "Network": "Star Network Ent",
    "Channel_Name": "Pravah Picture",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1386.2270014302339",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi",
    "monthYear": "2022-12"
}, {
    "AdGRP": "56.91420013098832",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild(v)",
    "monthYear": "2022-12"
}, {
    "AdGRP": "342.0262007893034",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies",
    "monthYear": "2022-12"
}, {
    "AdGRP": "2183.9196008837171",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada",
    "monthYear": "2022-12"
}, {
    "AdGRP": "1299.4552016197704",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat",
    "monthYear": "2022-12"
}, {
    "AdGRP": "7.5809000058361562",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "28.034600049104483",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "0.53140000557323219",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "6.0868000182745163",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony PIX",
    "monthYear": "2022-12"
}, {
    "AdGRP": "6.9866000236288528",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies",
    "monthYear": "2022-12"
}, {
    "AdGRP": "6857.8933013379574",
    "Network": "Sun",
    "Channel_Name": "Sun TV",
    "monthYear": "2022-12"
}, {
    "AdGRP": "439.19760081899585",
    "Network": "Sun",
    "Channel_Name": "Sun TV HD",
    "monthYear": "2022-12"
}, {
    "AdGRP": "350.84610060623527",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil",
    "monthYear": "2022-12"
}, {
    "AdGRP": "472.591001933557",
    "Network": "ZEEL",
    "Channel_Name": "Zing",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.10600000340491533",
    "Network": "Viacom",
    "Channel_Name": "Nick HD+(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "487.854001430911",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies",
    "monthYear": "2022-4"
}, {
    "AdGRP": "199.06500140030403",
    "Network": "Sun",
    "Channel_Name": "Surya Movies",
    "monthYear": "2022-4"
}, {
    "AdGRP": "45.967000592150725",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "97.279000537120737",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.0030000001424923539",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "62.309000285691582",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "43.369000584469177",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.064000001642853022",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "15.599000232527032",
    "Network": "Viacom",
    "Channel_Name": "History TV 18(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.052000001189298928",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity SD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "356.67400131933391",
    "Network": "Sun",
    "Channel_Name": "Sun Music",
    "monthYear": "2022-4"
}, {
    "AdGRP": "936.45800221664831",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat",
    "monthYear": "2022-4"
}, {
    "AdGRP": "11.575000294484198",
    "Network": "Sun",
    "Channel_Name": "Surya TV HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "421.81500104558654",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Music",
    "monthYear": "2022-4"
}, {
    "AdGRP": "178.76600141753443",
    "Network": "Sun",
    "Channel_Name": "Kochu TV",
    "monthYear": "2022-4"
}, {
    "AdGRP": "547.14300216909032",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX 2",
    "monthYear": "2022-4"
}, {
    "AdGRP": "3882.0370008074678",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu",
    "monthYear": "2022-4"
}, {
    "AdGRP": "540.47900158120319",
    "Network": "ZEEL",
    "Channel_Name": "Zee Punjabi",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1255.666002629092",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna",
    "monthYear": "2022-4"
}, {
    "AdGRP": "48.803000507643446",
    "Network": "Sun",
    "Channel_Name": "KTV HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "396.64200161537156",
    "Network": "Sun",
    "Channel_Name": "Kushi TV",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1006.5900025038281",
    "Network": "ZEEL",
    "Channel_Name": "Big Magic",
    "monthYear": "2022-4"
}, {
    "AdGRP": "285.3070007635979",
    "Network": "Sun",
    "Channel_Name": "Sun TV HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "112.3000004888745",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "461.53900194121525",
    "Network": "Sun",
    "Channel_Name": "Gemini life",
    "monthYear": "2022-4"
}, {
    "AdGRP": "744.953002146096",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony YAY(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "29.463000402553007",
    "Network": "Sun",
    "Channel_Name": "Udaya TV HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.044000001740641892",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "6.65400011325255",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "4.2770000598393381",
    "Network": "Viacom",
    "Channel_Name": "VH1",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1000.3690029702848",
    "Network": "Sun",
    "Channel_Name": "Udaya Movies",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.13700000592507422",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life HD(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1.6600000459002331",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies HD(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "3179.4330008754041",
    "Network": "Viacom",
    "Channel_Name": "Colors",
    "monthYear": "2022-4"
}, {
    "AdGRP": "4237.0689981509931",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB",
    "monthYear": "2022-4"
}, {
    "AdGRP": "66.884000445366837",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.0050000002374872565",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World Premiere HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "784.22600219515152",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures",
    "monthYear": "2022-4"
}, {
    "AdGRP": "396.25400207960047",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.023000000044703484",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World",
    "monthYear": "2022-4"
}, {
    "AdGRP": "317.09200113592669",
    "Network": "Sun",
    "Channel_Name": "Udaya Comedy",
    "monthYear": "2022-4"
}, {
    "AdGRP": "630.11400191846769",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bollywood",
    "monthYear": "2022-4"
}, {
    "AdGRP": "189.91500099084806",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla Cinema",
    "monthYear": "2022-4"
}, {
    "AdGRP": "25.194000339368358",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "5568.4660010766238",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa",
    "monthYear": "2022-4"
}, {
    "AdGRP": "50.533000263385475",
    "Network": "Sun",
    "Channel_Name": "Gemini TV HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.0320000012870878",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "131.38100060645957",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1.2060000259662047",
    "Network": "Viacom",
    "Channel_Name": "MTV HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "6.2180001210654154",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony PIX",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.02300000109244138",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cafe HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "4.4840001376578584",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "100.04300041892566",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "29.336000301525928",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "104.98200101719704",
    "Network": "Viacom",
    "Channel_Name": "Colors Oriya",
    "monthYear": "2022-4"
}, {
    "AdGRP": "3.918000110774301",
    "Network": "Sun",
    "Channel_Name": "Gemini Music HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "11.182000106666237",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "432.80100168054923",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna Plus",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.31400001142174006",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select SD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1527.5210031014867",
    "Network": "Sun",
    "Channel_Name": "KTV",
    "monthYear": "2022-4"
}, {
    "AdGRP": "73.332000769325532",
    "Network": "Viacom",
    "Channel_Name": "MTV",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.633000020054169",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pix HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "504.23000129230786",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Marathi",
    "monthYear": "2022-4"
}, {
    "AdGRP": "26.405000317608938",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Suvarna HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "349.93300133466255",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Aath",
    "monthYear": "2022-4"
}, {
    "AdGRP": "513.09600131865591",
    "Network": "ZEEL",
    "Channel_Name": "Zee Biskope",
    "monthYear": "2022-4"
}, {
    "AdGRP": "742.20400107791647",
    "Network": "ZEEL",
    "Channel_Name": "Zee Sarthak",
    "monthYear": "2022-4"
}, {
    "AdGRP": "11.369000299135223",
    "Network": "Sun",
    "Channel_Name": "Sun Music HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "55.580000367364846",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "13.021000235923566",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "2423.4870024503907",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla",
    "monthYear": "2022-4"
}, {
    "AdGRP": "35.382000237819739",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "13.119000115548261",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1068.7120016710833",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi",
    "monthYear": "2022-4"
}, {
    "AdGRP": "2242.7890041870996",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol Cinema",
    "monthYear": "2022-4"
}, {
    "AdGRP": "5.0650000983150676",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "657.27300151088275",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.21100000862497836",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "3562.7570031024516",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada",
    "monthYear": "2022-4"
}, {
    "AdGRP": "438.47400173544884",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies",
    "monthYear": "2022-4"
}, {
    "AdGRP": "565.26500178338028",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Gold",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.358000016072765",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic HD(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1586.8630017524119",
    "Network": "Sun",
    "Channel_Name": "Udaya TV",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.23500000906642526",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "2.0080000538146123",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "4.16600013372954",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "56.140000312239863",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "732.077001983067",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex",
    "monthYear": "2022-4"
}, {
    "AdGRP": "301.65000151994172",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada Cinema",
    "monthYear": "2022-4"
}, {
    "AdGRP": "383.71500137553085",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "212.3340009568492",
    "Network": "ZEEL",
    "Channel_Name": "Zee Classic",
    "monthYear": "2022-4"
}, {
    "AdGRP": "283.49600127735175",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam",
    "monthYear": "2022-4"
}, {
    "AdGRP": "456.2490013204515",
    "Network": "Viacom",
    "Channel_Name": "NICK Junior(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1089.0770025008824",
    "Network": "Viacom",
    "Channel_Name": "NICK(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "3038.4940040104557",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.22200000949669629",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central",
    "monthYear": "2022-4"
}, {
    "AdGRP": "244.48800081701484",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla Cinema",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.0710000031394884",
    "Network": "Viacom",
    "Channel_Name": "VH1 HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "2991.71300313517",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV",
    "monthYear": "2022-4"
}, {
    "AdGRP": "5992.5370014685905",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus",
    "monthYear": "2022-4"
}, {
    "AdGRP": "6704.2829979769886",
    "Network": "Sun",
    "Channel_Name": "Sun TV",
    "monthYear": "2022-4"
}, {
    "AdGRP": "385.66400146123488",
    "Network": "ZEEL",
    "Channel_Name": "Zee Action",
    "monthYear": "2022-4"
}, {
    "AdGRP": "150.00800075998995",
    "Network": "Sun",
    "Channel_Name": "Chintu TV",
    "monthYear": "2022-4"
}, {
    "AdGRP": "171.39000101177953",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1047.6530020178761",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold 2",
    "monthYear": "2022-4"
}, {
    "AdGRP": "111.31500122870784",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Plus",
    "monthYear": "2022-4"
}, {
    "AdGRP": "254.63300121459179",
    "Network": "Sun",
    "Channel_Name": "Gemini Music",
    "monthYear": "2022-4"
}, {
    "AdGRP": "366.13300146488473",
    "Network": "Viacom",
    "Channel_Name": "Colors Rishtey",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.094000004115514457",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies Select HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "2388.9460011884803",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.010000000474974513",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Prive HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "163.33600063319318",
    "Network": "Viacom",
    "Channel_Name": "Colors HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "2308.1620027400786",
    "Network": "Sun",
    "Channel_Name": "Gemini TV",
    "monthYear": "2022-4"
}, {
    "AdGRP": "50.598000903846696",
    "Network": "Sun",
    "Channel_Name": "Surya Music",
    "monthYear": "2022-4"
}, {
    "AdGRP": "425.74800106289331",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "615.43200175371021",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1287.0480019449024",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.3940000138245523",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.0040000001899898052",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "987.2440032903105",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pal",
    "monthYear": "2022-4"
}, {
    "AdGRP": "302.90000090119429",
    "Network": "Sun",
    "Channel_Name": "Chutti TV",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1130.6440031237435",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu",
    "monthYear": "2022-4"
}, {
    "AdGRP": "3.13000006636139",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.15400000661611557",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest HD(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1755.2860035564518",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Superhits",
    "monthYear": "2022-4"
}, {
    "AdGRP": "327.6480013562832",
    "Network": "Sun",
    "Channel_Name": "Sun Bangla",
    "monthYear": "2022-4"
}, {
    "AdGRP": "105.33300046413206",
    "Network": "Sun",
    "Channel_Name": "Sun Marathi",
    "monthYear": "2022-4"
}, {
    "AdGRP": "246.25600099924486",
    "Network": "Sun",
    "Channel_Name": "Gemini Comedy",
    "monthYear": "2022-4"
}, {
    "AdGRP": "104.71100051898975",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1965.509003169369",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada",
    "monthYear": "2022-4"
}, {
    "AdGRP": "17.187000329606235",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "250.24400115548633",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.27400001022033393",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.11800000455696136",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth HD(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.34400000644382089",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix",
    "monthYear": "2022-4"
}, {
    "AdGRP": "577.98900123452768",
    "Network": "Sun",
    "Channel_Name": "Sun Life",
    "monthYear": "2022-4"
}, {
    "AdGRP": "2646.2210015084129",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha",
    "monthYear": "2022-4"
}, {
    "AdGRP": "39.600000337115489",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "27.942000384558924",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa Movies HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "546.99200154584832",
    "Network": "Viacom",
    "Channel_Name": "Sonic Nickelodeon(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "4289.021002214402",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay",
    "monthYear": "2022-4"
}, {
    "AdGRP": "19.228000354021788",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "62.79400045494549",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "337.94700147153344",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Movies",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1225.307003044989",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Movies",
    "monthYear": "2022-4"
}, {
    "AdGRP": "10.965000147465616",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "347.43100098229479",
    "Network": "ZEEL",
    "Channel_Name": "Zee Picchar",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.41000001644715667",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "127.3490009115776",
    "Network": "ZEEL",
    "Channel_Name": "Zee Yuva",
    "monthYear": "2022-4"
}, {
    "AdGRP": "490.71800143877044",
    "Network": "ZEEL",
    "Channel_Name": "Zee Ganga",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1871.6200026682345",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil",
    "monthYear": "2022-4"
}, {
    "AdGRP": "477.56800143315922",
    "Network": "Sun",
    "Channel_Name": "Adithya TV",
    "monthYear": "2022-4"
}, {
    "AdGRP": "282.60300129104871",
    "Network": "Viacom",
    "Channel_Name": "Colors Super",
    "monthYear": "2022-4"
}, {
    "AdGRP": "239.189000593964",
    "Network": "ZEEL",
    "Channel_Name": "Zee Chitramandir",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1773.7470029052347",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television",
    "monthYear": "2022-4"
}, {
    "AdGRP": "0.16900000569876283",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Xplor HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1482.827003402519",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1.0330000381218269",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild HD(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "349.28500117862131",
    "Network": "Sun",
    "Channel_Name": "Surya TV",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1417.3300021166215",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema",
    "monthYear": "2022-4"
}, {
    "AdGRP": "240.34900091541931",
    "Network": "ZEEL",
    "Channel_Name": "Zee Thirai",
    "monthYear": "2022-4"
}, {
    "AdGRP": "452.73000148567371",
    "Network": "Sun",
    "Channel_Name": "Udaya Music",
    "monthYear": "2022-4"
}, {
    "AdGRP": "325.98300130653661",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil",
    "monthYear": "2022-4"
}, {
    "AdGRP": "52.108001022250392",
    "Network": "Sun",
    "Channel_Name": "Surya Comedy",
    "monthYear": "2022-4"
}, {
    "AdGRP": "107.77500104147475",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati Cinema",
    "monthYear": "2022-4"
}, {
    "AdGRP": "41.722000381094404",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX HD(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "790.34200219076592",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay Super",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1755.4160028245533",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav Movies",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1259.2870034911903",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Bollywood",
    "monthYear": "2022-4"
}, {
    "AdGRP": "16.487000226858072",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV HD",
    "monthYear": "2022-4"
}, {
    "AdGRP": "859.439002330415",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Wah",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1329.896002265159",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1217.6850029326743",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX(v)",
    "monthYear": "2022-4"
}, {
    "AdGRP": "201.55800120730419",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla",
    "monthYear": "2022-4"
}, {
    "AdGRP": "1713.4189029773697",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television",
    "monthYear": "2022-5"
}, {
    "AdGRP": "504.8219015179202",
    "Network": "Viacom",
    "Channel_Name": "NICK Junior(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.055500001879408956",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies Select HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "563.56280178017914",
    "Network": "Sun",
    "Channel_Name": "Adithya TV",
    "monthYear": "2022-5"
}, {
    "AdGRP": "295.96390122569574",
    "Network": "Sun",
    "Channel_Name": "Gemini Comedy",
    "monthYear": "2022-5"
}, {
    "AdGRP": "276.876601291282",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla Cinema",
    "monthYear": "2022-5"
}, {
    "AdGRP": "21.682700251585629",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "87.80530097054725",
    "Network": "Viacom",
    "Channel_Name": "MTV",
    "monthYear": "2022-5"
}, {
    "AdGRP": "406.33190113702585",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "39.726700254090247",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "66.77710037437646",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "27.266500314457517",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa Movies HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "2.217900059040403",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "2886.7423032156657",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah",
    "monthYear": "2022-5"
}, {
    "AdGRP": "875.44890232488979",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav",
    "monthYear": "2022-5"
}, {
    "AdGRP": "437.01440125360386",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna Plus",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.015200000314507633",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity SD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "4.4543001044876291",
    "Network": "Sun",
    "Channel_Name": "Gemini Music HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1192.1283032554202",
    "Network": "ZEEL",
    "Channel_Name": "Big Magic",
    "monthYear": "2022-5"
}, {
    "AdGRP": "497.966001468536",
    "Network": "ZEEL",
    "Channel_Name": "Zee Action",
    "monthYear": "2022-5"
}, {
    "AdGRP": "36.072700319266005",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "121.81220114081225",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Plus",
    "monthYear": "2022-5"
}, {
    "AdGRP": "249.09240125117503",
    "Network": "ZEEL",
    "Channel_Name": "Zee Classic",
    "monthYear": "2022-5"
}, {
    "AdGRP": "515.84660161603824",
    "Network": "ZEEL",
    "Channel_Name": "Zee Ganga",
    "monthYear": "2022-5"
}, {
    "AdGRP": "2075.27860175143",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.18960000586230308",
    "Network": "Viacom",
    "Channel_Name": "Nick HD+(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "260.1654011105129",
    "Network": "Viacom",
    "Channel_Name": "Colors Super",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.58480001918360358",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild HD(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "39.973500242886075",
    "Network": "Sun",
    "Channel_Name": "Gemini TV HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "2467.7097030886216",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol Cinema",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.099500003299908713",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth HD(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "436.73590176645666",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Aath",
    "monthYear": "2022-5"
}, {
    "AdGRP": "104.77030033600022",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "2288.6868026762022",
    "Network": "Sun",
    "Channel_Name": "Gemini TV",
    "monthYear": "2022-5"
}, {
    "AdGRP": "465.73560119431932",
    "Network": "Sun",
    "Channel_Name": "Kushi TV",
    "monthYear": "2022-5"
}, {
    "AdGRP": "51.174500551045639",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1.7131000274894177",
    "Network": "Viacom",
    "Channel_Name": "VH1",
    "monthYear": "2022-5"
}, {
    "AdGRP": "4163.6006038757041",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada",
    "monthYear": "2022-5"
}, {
    "AdGRP": "189.02760106440837",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.0090000001946464181",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1271.608403496095",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.16940000568138203",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "58.705800922325579",
    "Network": "Sun",
    "Channel_Name": "Surya Comedy",
    "monthYear": "2022-5"
}, {
    "AdGRP": "4.0272001074336",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.0020000000949949026",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World Premiere HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "561.01500143872545",
    "Network": "Viacom",
    "Channel_Name": "Sonic Nickelodeon(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "444.63190168044821",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies",
    "monthYear": "2022-5"
}, {
    "AdGRP": "104.92370095403021",
    "Network": "Viacom",
    "Channel_Name": "Colors Oriya",
    "monthYear": "2022-5"
}, {
    "AdGRP": "303.81970096894656",
    "Network": "Sun",
    "Channel_Name": "Sun TV HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "14.729400151467416",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "551.23840186349116",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Marathi",
    "monthYear": "2022-5"
}, {
    "AdGRP": "186.75390105892438",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla Cinema",
    "monthYear": "2022-5"
}, {
    "AdGRP": "2723.717700580135",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet",
    "monthYear": "2022-5"
}, {
    "AdGRP": "10.183200114275678",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies",
    "monthYear": "2022-5"
}, {
    "AdGRP": "456.01530176925007",
    "Network": "Sun",
    "Channel_Name": "Sun Music",
    "monthYear": "2022-5"
}, {
    "AdGRP": "383.78830115497112",
    "Network": "Sun",
    "Channel_Name": "Surya TV",
    "monthYear": "2022-5"
}, {
    "AdGRP": "423.36600136180641",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Movies",
    "monthYear": "2022-5"
}, {
    "AdGRP": "137.18730095309729",
    "Network": "ZEEL",
    "Channel_Name": "Zee Yuva",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1350.5346031953231",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "29.641100354732771",
    "Network": "Sun",
    "Channel_Name": "Udaya TV HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "127.12840064177726",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "4646.3376009657513",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB",
    "monthYear": "2022-5"
}, {
    "AdGRP": "879.38590219337493",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony YAY(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "13.502400038327323",
    "Network": "Star Network Ent",
    "Channel_Name": "Vijay Music",
    "monthYear": "2022-5"
}, {
    "AdGRP": "213.38130111354985",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla",
    "monthYear": "2022-5"
}, {
    "AdGRP": "461.71870143699925",
    "Network": "Sun",
    "Channel_Name": "Gemini life",
    "monthYear": "2022-5"
}, {
    "AdGRP": "660.26370195241179",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.23560000555880833",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "158.60200097895722",
    "Network": "Sun",
    "Channel_Name": "Chintu TV",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1839.1347032249905",
    "Network": "Sun",
    "Channel_Name": "KTV",
    "monthYear": "2022-5"
}, {
    "AdGRP": "516.84680201738956",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.01490000041667372",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Prive HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "8.4265001484964159",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony PIX",
    "monthYear": "2022-5"
}, {
    "AdGRP": "2475.0757999670604",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla",
    "monthYear": "2022-5"
}, {
    "AdGRP": "17.342700309709471",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1020.8828020901128",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay Super",
    "monthYear": "2022-5"
}, {
    "AdGRP": "398.49050138308667",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam",
    "monthYear": "2022-5"
}, {
    "AdGRP": "3231.6238022161997",
    "Network": "Viacom",
    "Channel_Name": "Colors",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.20900000748952152",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "58.365300561868935",
    "Network": "Sun",
    "Channel_Name": "KTV HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "937.76460242737085",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Wah",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.21150000701891258",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "29.101100360974669",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Suvarna HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1538.1097032022371",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.012499999953433871",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World",
    "monthYear": "2022-5"
}, {
    "AdGRP": "410.79930138494819",
    "Network": "Sun",
    "Channel_Name": "Udaya Music",
    "monthYear": "2022-5"
}, {
    "AdGRP": "4070.9357013450935",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu",
    "monthYear": "2022-5"
}, {
    "AdGRP": "42.666900435637217",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "52.769800434543868",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.57740001625643345",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "343.48250164085766",
    "Network": "Sun",
    "Channel_Name": "Sun Bangla",
    "monthYear": "2022-5"
}, {
    "AdGRP": "547.50530187131517",
    "Network": "ZEEL",
    "Channel_Name": "Zee Biskope",
    "monthYear": "2022-5"
}, {
    "AdGRP": "322.10670110459614",
    "Network": "Sun",
    "Channel_Name": "Udaya Comedy",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1016.3056025917176",
    "Network": "Sun",
    "Channel_Name": "Udaya Movies",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.41040000638895435",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "224.78880115560605",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati",
    "monthYear": "2022-5"
}, {
    "AdGRP": "5600.4376019281335",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa",
    "monthYear": "2022-5"
}, {
    "AdGRP": "2709.359102196293",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.048400001542177051",
    "Network": "Viacom",
    "Channel_Name": "VH1 HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "4.7560000935409334",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.68710001139697852",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix",
    "monthYear": "2022-5"
}, {
    "AdGRP": "213.7037005086313",
    "Network": "ZEEL",
    "Channel_Name": "Zee Chitramandir",
    "monthYear": "2022-5"
}, {
    "AdGRP": "23.715700289816596",
    "Network": "Viacom",
    "Channel_Name": "History TV 18(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1530.1339021039312",
    "Network": "Sun",
    "Channel_Name": "Udaya TV",
    "monthYear": "2022-5"
}, {
    "AdGRP": "571.42450139533321",
    "Network": "ZEEL",
    "Channel_Name": "Zing",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.0013000000617466867",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cafe HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1.2674000343249645",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pix HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "10.971400296199135",
    "Network": "Sun",
    "Channel_Name": "Surya TV HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.0547000019432744",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest HD(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1147.6606024167268",
    "Network": "Viacom",
    "Channel_Name": "NICK(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.40390001292689703",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1307.3621030303184",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Bollywood",
    "monthYear": "2022-5"
}, {
    "AdGRP": "151.48290091744275",
    "Network": "Viacom",
    "Channel_Name": "Colors HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "14.715500108824926",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "941.65450247051194",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat",
    "monthYear": "2022-5"
}, {
    "AdGRP": "112.62270097601868",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati Cinema",
    "monthYear": "2022-5"
}, {
    "AdGRP": "170.70050063519011",
    "Network": "Sun",
    "Channel_Name": "Sun Marathi",
    "monthYear": "2022-5"
}, {
    "AdGRP": "977.04040113231167",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi",
    "monthYear": "2022-5"
}, {
    "AdGRP": "519.21600139822112",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.96680003429355565",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic HD(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "123.90780057327356",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "14.086500215285923",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "7054.9517957866192",
    "Network": "Sun",
    "Channel_Name": "Sun TV",
    "monthYear": "2022-5"
}, {
    "AdGRP": "962.21650249184313",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures",
    "monthYear": "2022-5"
}, {
    "AdGRP": "917.73600239654479",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pal",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1.3448000370335649",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies HD(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "354.91400092400727",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "4.3740000814650557",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "213.81970119648759",
    "Network": "Sun",
    "Channel_Name": "Surya Movies",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1305.4778025827836",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna",
    "monthYear": "2022-5"
}, {
    "AdGRP": "2204.8147019954631",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil",
    "monthYear": "2022-5"
}, {
    "AdGRP": "476.28760145301203",
    "Network": "Viacom",
    "Channel_Name": "Colors Rishtey",
    "monthYear": "2022-5"
}, {
    "AdGRP": "2064.14660342352",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav Movies",
    "monthYear": "2022-5"
}, {
    "AdGRP": "297.06340092139726",
    "Network": "ZEEL",
    "Channel_Name": "Zee Thirai",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1808.10630360106",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Superhits",
    "monthYear": "2022-5"
}, {
    "AdGRP": "555.82150151261885",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Gold",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1391.204903460457",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Movies",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1644.9192031408311",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema",
    "monthYear": "2022-5"
}, {
    "AdGRP": "600.00190202868544",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX 2",
    "monthYear": "2022-5"
}, {
    "AdGRP": "5.241100153325533",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.38190000934991986",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select SD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "905.55670137854759",
    "Network": "ZEEL",
    "Channel_Name": "Zee Sarthak",
    "monthYear": "2022-5"
}, {
    "AdGRP": "17.367600370271248",
    "Network": "Sun",
    "Channel_Name": "Sun Music HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1.126400042201567",
    "Network": "Viacom",
    "Channel_Name": "MTV HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "505.48520180708874",
    "Network": "ZEEL",
    "Channel_Name": "Zee Punjabi",
    "monthYear": "2022-5"
}, {
    "AdGRP": "393.71930125259678",
    "Network": "ZEEL",
    "Channel_Name": "Zee Picchar",
    "monthYear": "2022-5"
}, {
    "AdGRP": "518.41610178392148",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Music",
    "monthYear": "2022-5"
}, {
    "AdGRP": "66.702100702561438",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "581.86910149001051",
    "Network": "Sun",
    "Channel_Name": "Sun Life",
    "monthYear": "2022-5"
}, {
    "AdGRP": "846.75670298497425",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.095200004143407568",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life HD(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "785.16300234288065",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bollywood",
    "monthYear": "2022-5"
}, {
    "AdGRP": "4663.5628001714358",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1673.288401989179",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi",
    "monthYear": "2022-5"
}, {
    "AdGRP": "3082.9614008242497",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.006100000289734453",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.023100000631529838",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "103.01940055567684",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "376.08440129479277",
    "Network": "Sun",
    "Channel_Name": "Chutti TV",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.071800002362579107",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1487.7628038241528",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies",
    "monthYear": "2022-5"
}, {
    "AdGRP": "86.0050002696953",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "366.25180154014379",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil",
    "monthYear": "2022-5"
}, {
    "AdGRP": "0.25600001034035813",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Xplor HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "1010.1304023832199",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold 2",
    "monthYear": "2022-5"
}, {
    "AdGRP": "6200.9335039096186",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus",
    "monthYear": "2022-5"
}, {
    "AdGRP": "62.688200890544977",
    "Network": "Sun",
    "Channel_Name": "Surya Music",
    "monthYear": "2022-5"
}, {
    "AdGRP": "43.682900395644538",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "293.76880107761826",
    "Network": "Sun",
    "Channel_Name": "Gemini Music",
    "monthYear": "2022-5"
}, {
    "AdGRP": "40.4878002605692",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX HD(v)",
    "monthYear": "2022-5"
}, {
    "AdGRP": "2.80060006025451",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "11.457400230545318",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "114.68910056306049",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "196.06460119316762",
    "Network": "Sun",
    "Channel_Name": "Kochu TV",
    "monthYear": "2022-5"
}, {
    "AdGRP": "130.63290059893916",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "35.466900302992144",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah HD",
    "monthYear": "2022-5"
}, {
    "AdGRP": "321.08370105987706",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada Cinema",
    "monthYear": "2022-5"
}, {
    "AdGRP": "3209.1761997634603",
    "Network": "Viacom",
    "Channel_Name": "Colors",
    "monthYear": "2022-6"
}, {
    "AdGRP": "385.39220049929281",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "452.29220086451824",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats",
    "monthYear": "2022-6"
}, {
    "AdGRP": "52.370700079562084",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "573.34030105281272",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX 2",
    "monthYear": "2022-6"
}, {
    "AdGRP": "4.4676000118197408",
    "Network": "Viacom",
    "Channel_Name": "VH1",
    "monthYear": "2022-6"
}, {
    "AdGRP": "32.604800082124711",
    "Network": "Sun",
    "Channel_Name": "Udaya TV HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "17.82720002039423",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.15379999988363124",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest HD(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1.627000009342737",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic HD(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.28390000153012807",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "328.50680070823728",
    "Network": "Sun",
    "Channel_Name": "Surya TV",
    "monthYear": "2022-6"
}, {
    "AdGRP": "542.18980091955746",
    "Network": "ZEEL",
    "Channel_Name": "Zee Biskope",
    "monthYear": "2022-6"
}, {
    "AdGRP": "2132.2326012039266",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol Cinema",
    "monthYear": "2022-6"
}, {
    "AdGRP": "6345.6170980930328",
    "Network": "Sun",
    "Channel_Name": "Sun TV",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.14450000055512646",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies Select HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1.45200001323974",
    "Network": "Viacom",
    "Channel_Name": "MTV HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "203.99640050914604",
    "Network": "Sun",
    "Channel_Name": "Surya Movies",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1128.8048017341644",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1280.3362016729079",
    "Network": "ZEEL",
    "Channel_Name": "Big Magic",
    "monthYear": "2022-6"
}, {
    "AdGRP": "305.4924005951907",
    "Network": "Sun",
    "Channel_Name": "Sun TV HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.56289999900036491",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "373.63070088948007",
    "Network": "ZEEL",
    "Channel_Name": "Zee Thirai",
    "monthYear": "2022-6"
}, {
    "AdGRP": "37.746300099846849",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "388.56490082009987",
    "Network": "Viacom",
    "Channel_Name": "Sonic Nickelodeon(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "92.564300215613912",
    "Network": "Viacom",
    "Channel_Name": "MTV",
    "monthYear": "2022-6"
}, {
    "AdGRP": "268.13940057110449",
    "Network": "ZEEL",
    "Channel_Name": "Zee Classic",
    "monthYear": "2022-6"
}, {
    "AdGRP": "405.6582008474943",
    "Network": "ZEEL",
    "Channel_Name": "Zee Picchar",
    "monthYear": "2022-6"
}, {
    "AdGRP": "866.95410081755108",
    "Network": "ZEEL",
    "Channel_Name": "Zee Sarthak",
    "monthYear": "2022-6"
}, {
    "AdGRP": "551.07650142081548",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Marathi",
    "monthYear": "2022-6"
}, {
    "AdGRP": "302.89420085419988",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada Cinema",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1289.0729015078396",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Bollywood",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1674.4128008124171",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1.0663000013009878",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild HD(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "47.269800093199592",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "464.93430085927685",
    "Network": "ZEEL",
    "Channel_Name": "Zing",
    "monthYear": "2022-6"
}, {
    "AdGRP": "58.264200154306309",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "117.75190023085452",
    "Network": "Sun",
    "Channel_Name": "Chintu TV",
    "monthYear": "2022-6"
}, {
    "AdGRP": "5.7436000217858236",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1236.2593009984121",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu",
    "monthYear": "2022-6"
}, {
    "AdGRP": "493.21900111895957",
    "Network": "Viacom",
    "Channel_Name": "Colors Rishtey",
    "monthYear": "2022-6"
}, {
    "AdGRP": "294.15400071474869",
    "Network": "Sun",
    "Channel_Name": "Gemini Music",
    "monthYear": "2022-6"
}, {
    "AdGRP": "21.220500042203639",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "336.03450079945469",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna Plus",
    "monthYear": "2022-6"
}, {
    "AdGRP": "52.2374000990676",
    "Network": "Sun",
    "Channel_Name": "KTV HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "574.777000874361",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony YAY(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "208.13040053944133",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla Cinema",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.0077000001911073923",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World Premiere HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "13.639000022260007",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "77.202100227419578",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "2068.9422006759341",
    "Network": "Sun",
    "Channel_Name": "Gemini TV",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.022099999958300032",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World",
    "monthYear": "2022-6"
}, {
    "AdGRP": "665.34750150557375",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV",
    "monthYear": "2022-6"
}, {
    "AdGRP": "2657.708702015836",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.17410000099334866",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life HD(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "106.07250014621241",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati Cinema",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.4738999992623576",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "296.06050069152116",
    "Network": "Viacom",
    "Channel_Name": "Colors Super",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1279.5619015670381",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Movies",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1012.4997014440596",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay Super",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.38790000273002079",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "36.994800093001686",
    "Network": "Sun",
    "Channel_Name": "Gemini TV HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "23.397300063443254",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Suvarna HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "109.19180036487523",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1.5639000133814989",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pix HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1905.2331018442055",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav Movies",
    "monthYear": "2022-6"
}, {
    "AdGRP": "461.47590095188934",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies",
    "monthYear": "2022-6"
}, {
    "AdGRP": "66.039600196229",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "181.85750038741389",
    "Network": "ZEEL",
    "Channel_Name": "Zee Yuva",
    "monthYear": "2022-6"
}, {
    "AdGRP": "9.06740002031438",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony PIX",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1139.4432005246053",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.21570000093925046",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1031.0759014884679",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pal",
    "monthYear": "2022-6"
}, {
    "AdGRP": "483.03740097577247",
    "Network": "Sun",
    "Channel_Name": "Gemini life",
    "monthYear": "2022-6"
}, {
    "AdGRP": "4.20290001599642",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1547.109300586977",
    "Network": "Sun",
    "Channel_Name": "KTV",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.50359999897773378",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select SD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "5.05860002276313",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.051200001224060543",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity SD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "280.68490060103795",
    "Network": "Sun",
    "Channel_Name": "Gemini Comedy",
    "monthYear": "2022-6"
}, {
    "AdGRP": "713.51890151491534",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bollywood",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.74620000447612256",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1302.2554012673791",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna",
    "monthYear": "2022-6"
}, {
    "AdGRP": "122.13030023730244",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "534.42370086585288",
    "Network": "ZEEL",
    "Channel_Name": "Zee Punjabi",
    "monthYear": "2022-6"
}, {
    "AdGRP": "235.20700047164428",
    "Network": "Sun",
    "Channel_Name": "Udaya Comedy",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1755.133501757402",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television",
    "monthYear": "2022-6"
}, {
    "AdGRP": "136.06690030639584",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "4532.2691032780567",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu",
    "monthYear": "2022-6"
}, {
    "AdGRP": "991.52450130891521",
    "Network": "Sun",
    "Channel_Name": "Udaya Movies",
    "monthYear": "2022-6"
}, {
    "AdGRP": "168.94810041417804",
    "Network": "Sun",
    "Channel_Name": "Kochu TV",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.1051000004663365",
    "Network": "Viacom",
    "Channel_Name": "Nick HD+(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "4477.3497003103839",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay",
    "monthYear": "2022-6"
}, {
    "AdGRP": "61.052700146872667",
    "Network": "Sun",
    "Channel_Name": "Surya Comedy",
    "monthYear": "2022-6"
}, {
    "AdGRP": "285.68820050046634",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla Cinema",
    "monthYear": "2022-6"
}, {
    "AdGRP": "322.51050070414931",
    "Network": "Sun",
    "Channel_Name": "Kushi TV",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1501.4079012777656",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.911400001423317",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "2.3723000048776157",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "3514.9633020029869",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV",
    "monthYear": "2022-6"
}, {
    "AdGRP": "409.89400071988348",
    "Network": "Sun",
    "Channel_Name": "Sun Music",
    "monthYear": "2022-6"
}, {
    "AdGRP": "429.81180068715912",
    "Network": "ZEEL",
    "Channel_Name": "Zee Action",
    "monthYear": "2022-6"
}, {
    "AdGRP": "557.64650089787756",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Gold",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1.0381000050911098",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies HD(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "88.516100094180729",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "19.379500059585553",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1393.4554015697213",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "5.29070002915978",
    "Network": "Sun",
    "Channel_Name": "Gemini Music HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "235.99990050427004",
    "Network": "Sun",
    "Channel_Name": "Sun Marathi",
    "monthYear": "2022-6"
}, {
    "AdGRP": "387.19040115966345",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam",
    "monthYear": "2022-6"
}, {
    "AdGRP": "826.02190135628189",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures",
    "monthYear": "2022-6"
}, {
    "AdGRP": "6161.8263022194733",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus",
    "monthYear": "2022-6"
}, {
    "AdGRP": "364.74790080805542",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Movies",
    "monthYear": "2022-6"
}, {
    "AdGRP": "2375.0374004363184",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla",
    "monthYear": "2022-6"
}, {
    "AdGRP": "3344.6901006978005",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah",
    "monthYear": "2022-6"
}, {
    "AdGRP": "43.429700120883354",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "9.4027000522473827",
    "Network": "Sun",
    "Channel_Name": "Surya TV HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "492.72980121755973",
    "Network": "Sun",
    "Channel_Name": "Adithya TV",
    "monthYear": "2022-6"
}, {
    "AdGRP": "512.49980065482669",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies",
    "monthYear": "2022-6"
}, {
    "AdGRP": "94.4305001850953",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "15.758000042660569",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "440.43840125211864",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Music",
    "monthYear": "2022-6"
}, {
    "AdGRP": "5927.996098832693",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1587.8053019508443",
    "Network": "Sun",
    "Channel_Name": "Udaya TV",
    "monthYear": "2022-6"
}, {
    "AdGRP": "225.75600045955798",
    "Network": "ZEEL",
    "Channel_Name": "Zee Chitramandir",
    "monthYear": "2022-6"
}, {
    "AdGRP": "101.51200025679282",
    "Network": "Viacom",
    "Channel_Name": "Colors Oriya",
    "monthYear": "2022-6"
}, {
    "AdGRP": "28.010200051183347",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa Movies HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "5.9230000243915129",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.24180000415071845",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Xplor HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "538.585100791126",
    "Network": "Sun",
    "Channel_Name": "Sun Life",
    "monthYear": "2022-6"
}, {
    "AdGRP": "4248.1965002354409",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada",
    "monthYear": "2022-6"
}, {
    "AdGRP": "343.61310063318524",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil",
    "monthYear": "2022-6"
}, {
    "AdGRP": "6.3166000287019415",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "431.54910090495832",
    "Network": "Viacom",
    "Channel_Name": "NICK Junior(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.099600000474310946",
    "Network": "Viacom",
    "Channel_Name": "VH1 HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1028.5365010151",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Wah",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1000.4005001680925",
    "Network": "Viacom",
    "Channel_Name": "NICK(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.0074000001623062417",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "320.61110066099354",
    "Network": "Sun",
    "Channel_Name": "Sun Bangla",
    "monthYear": "2022-6"
}, {
    "AdGRP": "235.36910051812447",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.025800000577874016",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cafe HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "769.75100128209306",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex",
    "monthYear": "2022-6"
}, {
    "AdGRP": "2503.75630127755",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet",
    "monthYear": "2022-6"
}, {
    "AdGRP": "832.940001779134",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav",
    "monthYear": "2022-6"
}, {
    "AdGRP": "201.41300038762711",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol",
    "monthYear": "2022-6"
}, {
    "AdGRP": "59.997200124293158",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX HD(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "33.258900071668904",
    "Network": "Viacom",
    "Channel_Name": "History TV 18(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "6.4976000102833495",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies",
    "monthYear": "2022-6"
}, {
    "AdGRP": "39.453300045490323",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "229.75030054127274",
    "Network": "Sun",
    "Channel_Name": "Chutti TV",
    "monthYear": "2022-6"
}, {
    "AdGRP": "62.309099889855133",
    "Network": "Star Network Ent",
    "Channel_Name": "Pravah Picture",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.055700000273645855",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1747.2202012988564",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Superhits",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.058100000045669731",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Prive HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.012900000074296258",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1399.0664016481023",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold",
    "monthYear": "2022-6"
}, {
    "AdGRP": "2103.0526995431865",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil",
    "monthYear": "2022-6"
}, {
    "AdGRP": "68.922700174596685",
    "Network": "Sun",
    "Channel_Name": "Surya Music",
    "monthYear": "2022-6"
}, {
    "AdGRP": "88.272300143478788",
    "Network": "Star Network Ent",
    "Channel_Name": "Vijay Music",
    "monthYear": "2022-6"
}, {
    "AdGRP": "23.048100075888215",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "61.3119001051964",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "114.75430028274423",
    "Network": "Viacom",
    "Channel_Name": "Colors HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "13.59240003357263",
    "Network": "Sun",
    "Channel_Name": "Sun Music HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "116.24550029321108",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Plus",
    "monthYear": "2022-6"
}, {
    "AdGRP": "69.309200155374128",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.339099999757309",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth HD(v)",
    "monthYear": "2022-6"
}, {
    "AdGRP": "110.7398002804548",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "430.58950104212272",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Aath",
    "monthYear": "2022-6"
}, {
    "AdGRP": "914.35510160499689",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold 2",
    "monthYear": "2022-6"
}, {
    "AdGRP": "2135.106401755882",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada",
    "monthYear": "2022-6"
}, {
    "AdGRP": "4682.1699004536495",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.51230000575742451",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "228.25500043791544",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla",
    "monthYear": "2022-6"
}, {
    "AdGRP": "376.02600067990716",
    "Network": "Sun",
    "Channel_Name": "Udaya Music",
    "monthYear": "2022-6"
}, {
    "AdGRP": "0.0073999999731313437",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "378.21260041423375",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa HD",
    "monthYear": "2022-6"
}, {
    "AdGRP": "1629.0262011947925",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi",
    "monthYear": "2022-6"
}, {
    "AdGRP": "419.52020105660631",
    "Network": "ZEEL",
    "Channel_Name": "Zee Ganga",
    "monthYear": "2022-6"
}, {
    "AdGRP": "970.81580157484859",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold 2",
    "monthYear": "2022-7"
}, {
    "AdGRP": "308.63450052291591",
    "Network": "Viacom",
    "Channel_Name": "Colors Super",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1.6287000127922511",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild HD(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "458.39660084801289",
    "Network": "ZEEL",
    "Channel_Name": "Zee Picchar",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1596.2348011923023",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies",
    "monthYear": "2022-7"
}, {
    "AdGRP": "122.25940017429093",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Plus",
    "monthYear": "2022-7"
}, {
    "AdGRP": "348.35040052183467",
    "Network": "ZEEL",
    "Channel_Name": "Zee Ganga",
    "monthYear": "2022-7"
}, {
    "AdGRP": "40.377600084793812",
    "Network": "Viacom",
    "Channel_Name": "History TV 18(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.45330000323883723",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select SD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.6065000020316802",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix",
    "monthYear": "2022-7"
}, {
    "AdGRP": "6261.9955004379153",
    "Network": "Sun",
    "Channel_Name": "Sun TV",
    "monthYear": "2022-7"
}, {
    "AdGRP": "3756.2067024069838",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV",
    "monthYear": "2022-7"
}, {
    "AdGRP": "13.219500017941755",
    "Network": "Sun",
    "Channel_Name": "Sun Music HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "724.29960156045854",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV",
    "monthYear": "2022-7"
}, {
    "AdGRP": "60.187000058707781",
    "Network": "Sun",
    "Channel_Name": "Surya Comedy",
    "monthYear": "2022-7"
}, {
    "AdGRP": "64.227500142522331",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "5.1598000248413882",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.026200000334938522",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "55.540600094253023",
    "Network": "Sun",
    "Channel_Name": "KTV HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "67.767100143981224",
    "Network": "Sun",
    "Channel_Name": "Surya Music",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1381.0476013247389",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu",
    "monthYear": "2022-7"
}, {
    "AdGRP": "452.00710105309554",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies",
    "monthYear": "2022-7"
}, {
    "AdGRP": "33.374800053548825",
    "Network": "Sun",
    "Channel_Name": "Gemini TV HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "323.57320054117736",
    "Network": "Sun",
    "Channel_Name": "Surya TV",
    "monthYear": "2022-7"
}, {
    "AdGRP": "79.382200119260233",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1441.4135019223249",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Bollywood",
    "monthYear": "2022-7"
}, {
    "AdGRP": "467.74600087165891",
    "Network": "Viacom",
    "Channel_Name": "Sonic Nickelodeon(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "33.539700057059235",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Suvarna HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "84.53260026062344",
    "Network": "Viacom",
    "Channel_Name": "MTV",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1406.2319005532772",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna",
    "monthYear": "2022-7"
}, {
    "AdGRP": "690.595401532235",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX 2",
    "monthYear": "2022-7"
}, {
    "AdGRP": "8.6196000223790179",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony PIX",
    "monthYear": "2022-7"
}, {
    "AdGRP": "44.551300080871442",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "319.83940056635038",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla Cinema",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.74290001448389376",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.28080000156478491",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Xplor HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "2391.1448001205208",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol Cinema",
    "monthYear": "2022-7"
}, {
    "AdGRP": "366.22820057090576",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam",
    "monthYear": "2022-7"
}, {
    "AdGRP": "5.7011000083148247",
    "Network": "Sun",
    "Channel_Name": "Gemini Music HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "5.1446000124269631",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1792.4432010997407",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Superhits",
    "monthYear": "2022-7"
}, {
    "AdGRP": "334.29090073315456",
    "Network": "ZEEL",
    "Channel_Name": "Zee Classic",
    "monthYear": "2022-7"
}, {
    "AdGRP": "79.681800089885655",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1298.1439020161051",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat",
    "monthYear": "2022-7"
}, {
    "AdGRP": "2230.7912008407293",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada",
    "monthYear": "2022-7"
}, {
    "AdGRP": "2170.8201012618665",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav Movies",
    "monthYear": "2022-7"
}, {
    "AdGRP": "391.21810028954496",
    "Network": "ZEEL",
    "Channel_Name": "Zee Chitramandir",
    "monthYear": "2022-7"
}, {
    "AdGRP": "80.719200160630862",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "131.79320029647351",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.44730000315757934",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "607.68760098551138",
    "Network": "ZEEL",
    "Channel_Name": "Zee Punjabi",
    "monthYear": "2022-7"
}, {
    "AdGRP": "254.61220064160443",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol",
    "monthYear": "2022-7"
}, {
    "AdGRP": "605.77620098298212",
    "Network": "ZEEL",
    "Channel_Name": "Zee Action",
    "monthYear": "2022-7"
}, {
    "AdGRP": "879.25810115495551",
    "Network": "ZEEL",
    "Channel_Name": "Zee Sarthak",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1441.2926014487748",
    "Network": "ZEEL",
    "Channel_Name": "Big Magic",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.0890000007202616",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity SD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "230.66260057647014",
    "Network": "Sun",
    "Channel_Name": "Gemini Music",
    "monthYear": "2022-7"
}, {
    "AdGRP": "346.6928008855466",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil",
    "monthYear": "2022-7"
}, {
    "AdGRP": "7.2544999993042438",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.047700001217890531",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cafe HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "4085.5733007809613",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1250.1827009866247",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi",
    "monthYear": "2022-7"
}, {
    "AdGRP": "263.48190043840441",
    "Network": "Sun",
    "Channel_Name": "Udaya Comedy",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.1030000007522176",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.00590000013471581",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "2543.8140998948511",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1436.397901885357",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1563.0982997150713",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi",
    "monthYear": "2022-7"
}, {
    "AdGRP": "908.12980129541393",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav",
    "monthYear": "2022-7"
}, {
    "AdGRP": "251.80360034616024",
    "Network": "Sun",
    "Channel_Name": "Sun Marathi",
    "monthYear": "2022-7"
}, {
    "AdGRP": "26.708800034277374",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "366.996700642223",
    "Network": "ZEEL",
    "Channel_Name": "Zee Thirai",
    "monthYear": "2022-7"
}, {
    "AdGRP": "474.51180120860226",
    "Network": "Sun",
    "Channel_Name": "Adithya TV",
    "monthYear": "2022-7"
}, {
    "AdGRP": "599.58230107920826",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Gold",
    "monthYear": "2022-7"
}, {
    "AdGRP": "144.01510043117742",
    "Network": "Sun",
    "Channel_Name": "Chintu TV",
    "monthYear": "2022-7"
}, {
    "AdGRP": "274.92510045193194",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla",
    "monthYear": "2022-7"
}, {
    "AdGRP": "63.48180013927049",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "4458.8608001589309",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay",
    "monthYear": "2022-7"
}, {
    "AdGRP": "10.57950005656312",
    "Network": "Sun",
    "Channel_Name": "Surya TV HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "4.0156000075148768",
    "Network": "Viacom",
    "Channel_Name": "VH1",
    "monthYear": "2022-7"
}, {
    "AdGRP": "329.28100052905938",
    "Network": "Sun",
    "Channel_Name": "Sun Bangla",
    "monthYear": "2022-7"
}, {
    "AdGRP": "460.93600083681667",
    "Network": "ZEEL",
    "Channel_Name": "Zing",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1211.0973009791633",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Wah",
    "monthYear": "2022-7"
}, {
    "AdGRP": "19.139000058334204",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1006.7183008651482",
    "Network": "Viacom",
    "Channel_Name": "NICK(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "288.72100063061225",
    "Network": "Sun",
    "Channel_Name": "Kushi TV",
    "monthYear": "2022-7"
}, {
    "AdGRP": "621.52490088698687",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Marathi",
    "monthYear": "2022-7"
}, {
    "AdGRP": "174.3306004246042",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati Cinema",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.23840000097698066",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth HD(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "2835.350500578119",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1414.1544014427345",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Movies",
    "monthYear": "2022-7"
}, {
    "AdGRP": "19.936000019682979",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "83.28590017894021",
    "Network": "Star Network Ent",
    "Channel_Name": "Vijay Music",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.088199999445350841",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Prive HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "2.4274000038421946",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.048400000072433613",
    "Network": "Viacom",
    "Channel_Name": "VH1 HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "424.45530096959556",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Aath",
    "monthYear": "2022-7"
}, {
    "AdGRP": "86.992000185033248",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1014.2935014643735",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pal",
    "monthYear": "2022-7"
}, {
    "AdGRP": "216.529400525651",
    "Network": "ZEEL",
    "Channel_Name": "Zee Yuva",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.058800000202609226",
    "Network": "Viacom",
    "Channel_Name": "Nick HD+(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.40130000401404686",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.0085000000835862011",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World Premiere HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "160.43030025025655",
    "Network": "Viacom",
    "Channel_Name": "Colors HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "404.19210091924469",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats",
    "monthYear": "2022-7"
}, {
    "AdGRP": "395.30520078106929",
    "Network": "Viacom",
    "Channel_Name": "Colors Rishtey",
    "monthYear": "2022-7"
}, {
    "AdGRP": "17.526800040133821",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "139.33750033169053",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "849.5240019582634",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bollywood",
    "monthYear": "2022-7"
}, {
    "AdGRP": "233.04220052666642",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla Cinema",
    "monthYear": "2022-7"
}, {
    "AdGRP": "2032.8244001563726",
    "Network": "Sun",
    "Channel_Name": "Gemini TV",
    "monthYear": "2022-7"
}, {
    "AdGRP": "496.78960118360555",
    "Network": "Sun",
    "Channel_Name": "Gemini life",
    "monthYear": "2022-7"
}, {
    "AdGRP": "6.5872000215531443",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "8.2449000289852847",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "157.15970039928652",
    "Network": "Sun",
    "Channel_Name": "Kochu TV",
    "monthYear": "2022-7"
}, {
    "AdGRP": "218.12270042427554",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.5408000039824401",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "3700.6116012960556",
    "Network": "Viacom",
    "Channel_Name": "Colors",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1598.8858999061777",
    "Network": "Sun",
    "Channel_Name": "Udaya TV",
    "monthYear": "2022-7"
}, {
    "AdGRP": "286.294000592723",
    "Network": "Sun",
    "Channel_Name": "Gemini Comedy",
    "monthYear": "2022-7"
}, {
    "AdGRP": "36.997900054564525",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "330.21360058317077",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada Cinema",
    "monthYear": "2022-7"
}, {
    "AdGRP": "760.2819009659579",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies",
    "monthYear": "2022-7"
}, {
    "AdGRP": "107.63150025409414",
    "Network": "Viacom",
    "Channel_Name": "Colors Oriya",
    "monthYear": "2022-7"
}, {
    "AdGRP": "116.30190030400991",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1539.4563011494465",
    "Network": "Sun",
    "Channel_Name": "KTV",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1835.9325016143994",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema",
    "monthYear": "2022-7"
}, {
    "AdGRP": "517.1929009713931",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony YAY(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "375.58260056438303",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "354.0796006027449",
    "Network": "Sun",
    "Channel_Name": "Udaya Music",
    "monthYear": "2022-7"
}, {
    "AdGRP": "41.725200071494328",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa Movies HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "84.066600174759515",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "2402.0215012308327",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil",
    "monthYear": "2022-7"
}, {
    "AdGRP": "4259.7287997649983",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu",
    "monthYear": "2022-7"
}, {
    "AdGRP": "848.84500174022833",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex",
    "monthYear": "2022-7"
}, {
    "AdGRP": "538.51500079099787",
    "Network": "Sun",
    "Channel_Name": "Sun Life",
    "monthYear": "2022-7"
}, {
    "AdGRP": "413.93830103326763",
    "Network": "Sun",
    "Channel_Name": "Sun Music",
    "monthYear": "2022-7"
}, {
    "AdGRP": "70.697100147997844",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "2.3564000093319919",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic HD(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "15.856400043710892",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies",
    "monthYear": "2022-7"
}, {
    "AdGRP": "473.4950004758939",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "5807.3487024478673",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.93320000238600187",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.018100000015692785",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "4629.8443016698584",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB",
    "monthYear": "2022-7"
}, {
    "AdGRP": "72.974300159061386",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX HD(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "136.83230036171153",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "21.871700046111073",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "2281.6075012846341",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet",
    "monthYear": "2022-7"
}, {
    "AdGRP": "953.43640145844256",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures",
    "monthYear": "2022-7"
}, {
    "AdGRP": "361.20060067935265",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Movies",
    "monthYear": "2022-7"
}, {
    "AdGRP": "380.70090068390709",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Music",
    "monthYear": "2022-7"
}, {
    "AdGRP": "211.57930049009155",
    "Network": "Sun",
    "Channel_Name": "Surya Movies",
    "monthYear": "2022-7"
}, {
    "AdGRP": "31.314800006664882",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "40.323000065647648",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "2.1598000004159985",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies HD(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "53.90790017152176",
    "Network": "Star Network Ent",
    "Channel_Name": "Pravah Picture",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1.2485000123269856",
    "Network": "Viacom",
    "Channel_Name": "MTV HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.50870000163558871",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "20.858800052104925",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.16270000135409646",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "575.786501121227",
    "Network": "ZEEL",
    "Channel_Name": "Zee Biskope",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1918.9611005696934",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television",
    "monthYear": "2022-7"
}, {
    "AdGRP": "212.4126004217469",
    "Network": "Sun",
    "Channel_Name": "Chutti TV",
    "monthYear": "2022-7"
}, {
    "AdGRP": "2.1644000118612894",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pix HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.22190000353293726",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life HD(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.033500000223284587",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1478.6078019430133",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold",
    "monthYear": "2022-7"
}, {
    "AdGRP": "332.53710065223277",
    "Network": "Sun",
    "Channel_Name": "Sun TV HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "444.048601007089",
    "Network": "Viacom",
    "Channel_Name": "NICK Junior(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "34.276300068093406",
    "Network": "Sun",
    "Channel_Name": "Udaya TV HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.37590001013450092",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest HD(v)",
    "monthYear": "2022-7"
}, {
    "AdGRP": "4220.5269007068127",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada",
    "monthYear": "2022-7"
}, {
    "AdGRP": "977.83480117318686",
    "Network": "Sun",
    "Channel_Name": "Udaya Movies",
    "monthYear": "2022-7"
}, {
    "AdGRP": "1005.9876016957569",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay Super",
    "monthYear": "2022-7"
}, {
    "AdGRP": "413.87990100956085",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna Plus",
    "monthYear": "2022-7"
}, {
    "AdGRP": "6785.7031001626747",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa",
    "monthYear": "2022-7"
}, {
    "AdGRP": "0.3389000060633407",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies Select HD",
    "monthYear": "2022-7"
}, {
    "AdGRP": "3416.7449002088979",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha",
    "monthYear": "2022-8"
}, {
    "AdGRP": "458.0961005715144",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.79630000418546842",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select SD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "222.96090043360891",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol",
    "monthYear": "2022-8"
}, {
    "AdGRP": "22.9384000179125",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "240.63140045417822",
    "Network": "Sun",
    "Channel_Name": "Surya Movies",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1444.5304016183",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu",
    "monthYear": "2022-8"
}, {
    "AdGRP": "650.47640118910931",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Marathi",
    "monthYear": "2022-8"
}, {
    "AdGRP": "24.103200050180021",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.30960000114282593",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix",
    "monthYear": "2022-8"
}, {
    "AdGRP": "6734.9686031807214",
    "Network": "Sun",
    "Channel_Name": "Sun TV",
    "monthYear": "2022-8"
}, {
    "AdGRP": "471.44350100782322",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Music",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.65100000209349673",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "9.03290001712594",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "12.067200025856437",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1709.1014009546489",
    "Network": "Sun",
    "Channel_Name": "KTV",
    "monthYear": "2022-8"
}, {
    "AdGRP": "255.922400583353",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla",
    "monthYear": "2022-8"
}, {
    "AdGRP": "306.16880077436508",
    "Network": "Sun",
    "Channel_Name": "Kushi TV",
    "monthYear": "2022-8"
}, {
    "AdGRP": "3976.4461008503567",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah",
    "monthYear": "2022-8"
}, {
    "AdGRP": "3.9302999992432888",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1005.5829010442249",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav",
    "monthYear": "2022-8"
}, {
    "AdGRP": "13.687699991183763",
    "Network": "Sun",
    "Channel_Name": "Surya TV HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "22.727800052387465",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "414.14290089251881",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam",
    "monthYear": "2022-8"
}, {
    "AdGRP": "903.43700169830117",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay Super",
    "monthYear": "2022-8"
}, {
    "AdGRP": "36.800500081968494",
    "Network": "Sun",
    "Channel_Name": "Gemini TV HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "859.96330161679361",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex",
    "monthYear": "2022-8"
}, {
    "AdGRP": "104.76620022696443",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "154.25360035475751",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Plus",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.24250000008760253",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life HD(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1449.0833004158922",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Movies",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1645.6309009221732",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Wah",
    "monthYear": "2022-8"
}, {
    "AdGRP": "219.06990045436396",
    "Network": "Sun",
    "Channel_Name": "Gemini Music",
    "monthYear": "2022-8"
}, {
    "AdGRP": "2021.1365004275867",
    "Network": "Sun",
    "Channel_Name": "Gemini TV",
    "monthYear": "2022-8"
}, {
    "AdGRP": "5.4616000209571212",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.008600000204751268",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "391.8253007650128",
    "Network": "Sun",
    "Channel_Name": "Sun TV HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "2482.199901957043",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet",
    "monthYear": "2022-8"
}, {
    "AdGRP": "4372.1423005862162",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay",
    "monthYear": "2022-8"
}, {
    "AdGRP": "2253.3839011865202",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil",
    "monthYear": "2022-8"
}, {
    "AdGRP": "921.21980155209894",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bollywood",
    "monthYear": "2022-8"
}, {
    "AdGRP": "266.18810064029094",
    "Network": "Sun",
    "Channel_Name": "Sun Marathi",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1531.208701305557",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies",
    "monthYear": "2022-8"
}, {
    "AdGRP": "84.735600160194736",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "177.89620041911257",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "337.42140066305728",
    "Network": "ZEEL",
    "Channel_Name": "Zee Thirai",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.48909999761963263",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1.5492000081139849",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies HD(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.10380000089935493",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central",
    "monthYear": "2022-8"
}, {
    "AdGRP": "481.04100106731494",
    "Network": "ZEEL",
    "Channel_Name": "Zing",
    "monthYear": "2022-8"
}, {
    "AdGRP": "95.837600170139922",
    "Network": "Star Network Ent",
    "Channel_Name": "Vijay Music",
    "monthYear": "2022-8"
}, {
    "AdGRP": "328.43460087462154",
    "Network": "Sun",
    "Channel_Name": "Udaya Comedy",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1.1973000031939591",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild HD(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "380.61070076691976",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats",
    "monthYear": "2022-8"
}, {
    "AdGRP": "723.15070128231309",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV",
    "monthYear": "2022-8"
}, {
    "AdGRP": "226.12060034992464",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati",
    "monthYear": "2022-8"
}, {
    "AdGRP": "8.7057000159693416",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony PIX",
    "monthYear": "2022-8"
}, {
    "AdGRP": "570.59710111173627",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Gold",
    "monthYear": "2022-8"
}, {
    "AdGRP": "24.410100085697195",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "88.939300103927962",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "431.6174003911583",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "517.65980103844049",
    "Network": "Sun",
    "Channel_Name": "Gemini life",
    "monthYear": "2022-8"
}, {
    "AdGRP": "328.66320032279327",
    "Network": "ZEEL",
    "Channel_Name": "Zee Chitramandir",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.007299999961105641",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cafe HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "286.67180064684362",
    "Network": "Sun",
    "Channel_Name": "Gemini Comedy",
    "monthYear": "2022-8"
}, {
    "AdGRP": "321.34050079236476",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla Cinema",
    "monthYear": "2022-8"
}, {
    "AdGRP": "76.6312001803235",
    "Network": "Sun",
    "Channel_Name": "Surya Music",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1362.8812013547285",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna",
    "monthYear": "2022-8"
}, {
    "AdGRP": "32.928700064854638",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "33.555500072769064",
    "Network": "Viacom",
    "Channel_Name": "History TV 18(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1953.4432009269949",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1197.3108011304867",
    "Network": "Viacom",
    "Channel_Name": "NICK(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.027100001156213693",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity SD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "972.72230165098154",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1725.2121010908158",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Superhits",
    "monthYear": "2022-8"
}, {
    "AdGRP": "118.00180031719356",
    "Network": "Star Network Ent",
    "Channel_Name": "Pravah Picture",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.20839999995223479",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Xplor HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "4362.0716011328623",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB",
    "monthYear": "2022-8"
}, {
    "AdGRP": "900.39910101616988",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pal",
    "monthYear": "2022-8"
}, {
    "AdGRP": "60.67670009790163",
    "Network": "Sun",
    "Channel_Name": "KTV HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "2190.9890011635871",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav Movies",
    "monthYear": "2022-8"
}, {
    "AdGRP": "195.59670041537174",
    "Network": "ZEEL",
    "Channel_Name": "Zee Yuva",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1.1174000023165718",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.46560000351746567",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth HD(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "362.07360057369078",
    "Network": "Sun",
    "Channel_Name": "Surya TV",
    "monthYear": "2022-8"
}, {
    "AdGRP": "359.26670072389243",
    "Network": "ZEEL",
    "Channel_Name": "Zee Ganga",
    "monthYear": "2022-8"
}, {
    "AdGRP": "72.568100145013887",
    "Network": "Sun",
    "Channel_Name": "Surya Comedy",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.37910000072588446",
    "Network": "Viacom",
    "Channel_Name": "MTV HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.27309999902354321",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.052599999151425436",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Prive HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "3709.1564999323455",
    "Network": "Viacom",
    "Channel_Name": "Colors",
    "monthYear": "2022-8"
}, {
    "AdGRP": "893.368101579661",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold 2",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1161.9804020188749",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat",
    "monthYear": "2022-8"
}, {
    "AdGRP": "369.3274005182684",
    "Network": "Sun",
    "Channel_Name": "Sun Bangla",
    "monthYear": "2022-8"
}, {
    "AdGRP": "2636.851100931257",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla",
    "monthYear": "2022-8"
}, {
    "AdGRP": "4633.1481008818373",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu",
    "monthYear": "2022-8"
}, {
    "AdGRP": "3683.2305993651389",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV",
    "monthYear": "2022-8"
}, {
    "AdGRP": "762.76630097665475",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX 2",
    "monthYear": "2022-8"
}, {
    "AdGRP": "158.81950036341732",
    "Network": "Sun",
    "Channel_Name": "Chintu TV",
    "monthYear": "2022-8"
}, {
    "AdGRP": "36.294300071800535",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.52899999953660881",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.6712000075422111",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "399.97150093351229",
    "Network": "Sun",
    "Channel_Name": "Sun Music",
    "monthYear": "2022-8"
}, {
    "AdGRP": "591.67890104537219",
    "Network": "ZEEL",
    "Channel_Name": "Zee Action",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1.7740000047633657",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pix HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "6.1879000261178589",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "2383.9426008402224",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1334.8055009797681",
    "Network": "ZEEL",
    "Channel_Name": "Big Magic",
    "monthYear": "2022-8"
}, {
    "AdGRP": "667.46640106817358",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.1037000009091571",
    "Network": "Viacom",
    "Channel_Name": "VH1 HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1489.3894006290357",
    "Network": "Sun",
    "Channel_Name": "Udaya TV",
    "monthYear": "2022-8"
}, {
    "AdGRP": "313.37480074453197",
    "Network": "ZEEL",
    "Channel_Name": "Zee Classic",
    "monthYear": "2022-8"
}, {
    "AdGRP": "153.6414002924721",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "18.375500045600347",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "35.86170012333605",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1379.7470009701792",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi",
    "monthYear": "2022-8"
}, {
    "AdGRP": "138.1141003514349",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "99.929600146795565",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.19039999920642003",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest HD(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "160.70230045812787",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "311.81650067291048",
    "Network": "Viacom",
    "Channel_Name": "Colors Super",
    "monthYear": "2022-8"
}, {
    "AdGRP": "335.904400700776",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.01029999993625097",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "76.985700193159573",
    "Network": "Viacom",
    "Channel_Name": "MTV",
    "monthYear": "2022-8"
}, {
    "AdGRP": "16.042800043716852",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "440.76350078497489",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Aath",
    "monthYear": "2022-8"
}, {
    "AdGRP": "354.12160083838535",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada Cinema",
    "monthYear": "2022-8"
}, {
    "AdGRP": "521.31110111097223",
    "Network": "ZEEL",
    "Channel_Name": "Zee Punjabi",
    "monthYear": "2022-8"
}, {
    "AdGRP": "5.8915000118504395",
    "Network": "Sun",
    "Channel_Name": "Gemini Music HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "29.182800056361884",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Suvarna HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "2.1598000067533576",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "100.34160019954288",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "370.25360082722909",
    "Network": "Viacom",
    "Channel_Name": "Colors Rishtey",
    "monthYear": "2022-8"
}, {
    "AdGRP": "241.76640057880286",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla Cinema",
    "monthYear": "2022-8"
}, {
    "AdGRP": "45.242700114344188",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa Movies HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.0080000000889413059",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1534.2875016497492",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1984.7914009922606",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema",
    "monthYear": "2022-8"
}, {
    "AdGRP": "125.4407003242959",
    "Network": "Viacom",
    "Channel_Name": "Colors Oriya",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.64170000324520515",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "26.483700096941902",
    "Network": "Sun",
    "Channel_Name": "Udaya TV HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.44380000249657314",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies Select HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.0010000000183936208",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World Premiere HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.0",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1423.6412006994287",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold",
    "monthYear": "2022-8"
}, {
    "AdGRP": "435.18950076144392",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Movies",
    "monthYear": "2022-8"
}, {
    "AdGRP": "586.05580129649024",
    "Network": "Sun",
    "Channel_Name": "Sun Life",
    "monthYear": "2022-8"
}, {
    "AdGRP": "570.68110115831951",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony YAY(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "171.25410033050866",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati Cinema",
    "monthYear": "2022-8"
}, {
    "AdGRP": "161.52170037211181",
    "Network": "Sun",
    "Channel_Name": "Kochu TV",
    "monthYear": "2022-8"
}, {
    "AdGRP": "18.508200017473428",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "50.839000082720304",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "0.042499999901338015",
    "Network": "Viacom",
    "Channel_Name": "Nick HD+(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "594.49540148072992",
    "Network": "ZEEL",
    "Channel_Name": "Zee Biskope",
    "monthYear": "2022-8"
}, {
    "AdGRP": "501.085501098496",
    "Network": "ZEEL",
    "Channel_Name": "Zee Picchar",
    "monthYear": "2022-8"
}, {
    "AdGRP": "2.2945000130639528",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic HD(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "6404.1413992677117",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus",
    "monthYear": "2022-8"
}, {
    "AdGRP": "84.630800182276289",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX HD(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "2540.1424007117166",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol Cinema",
    "monthYear": "2022-8"
}, {
    "AdGRP": "514.2598007536144",
    "Network": "Sun",
    "Channel_Name": "Adithya TV",
    "monthYear": "2022-8"
}, {
    "AdGRP": "467.94470104112406",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna Plus",
    "monthYear": "2022-8"
}, {
    "AdGRP": "30.995500074794109",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1.2942000069961068",
    "Network": "Viacom",
    "Channel_Name": "VH1",
    "monthYear": "2022-8"
}, {
    "AdGRP": "6557.4868010044447",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1033.6626017807212",
    "Network": "Sun",
    "Channel_Name": "Udaya Movies",
    "monthYear": "2022-8"
}, {
    "AdGRP": "549.72950101690367",
    "Network": "Viacom",
    "Channel_Name": "NICK Junior(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1593.3901013667419",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi",
    "monthYear": "2022-8"
}, {
    "AdGRP": "14.915400050485914",
    "Network": "Sun",
    "Channel_Name": "Sun Music HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "4019.1557002256159",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada",
    "monthYear": "2022-8"
}, {
    "AdGRP": "151.20190033572726",
    "Network": "Viacom",
    "Channel_Name": "Colors HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "1466.1187017741904",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Bollywood",
    "monthYear": "2022-8"
}, {
    "AdGRP": "204.95160048727121",
    "Network": "Sun",
    "Channel_Name": "Chutti TV",
    "monthYear": "2022-8"
}, {
    "AdGRP": "491.56620082764857",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies",
    "monthYear": "2022-8"
}, {
    "AdGRP": "72.586000190174673",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "934.21229998813942",
    "Network": "ZEEL",
    "Channel_Name": "Zee Sarthak",
    "monthYear": "2022-8"
}, {
    "AdGRP": "539.32880089776882",
    "Network": "Viacom",
    "Channel_Name": "Sonic Nickelodeon(v)",
    "monthYear": "2022-8"
}, {
    "AdGRP": "6.8247000280462089",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies HD",
    "monthYear": "2022-8"
}, {
    "AdGRP": "436.69140103945392",
    "Network": "Sun",
    "Channel_Name": "Udaya Music",
    "monthYear": "2022-8"
}, {
    "AdGRP": "977.03660114514059",
    "Network": "Sun",
    "Channel_Name": "Udaya Movies",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1342.5919020158981",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu",
    "monthYear": "2022-9"
}, {
    "AdGRP": "95.887200180797663",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "76.529500185250072",
    "Network": "Sun",
    "Channel_Name": "Surya Comedy",
    "monthYear": "2022-9"
}, {
    "AdGRP": "367.19750086430577",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.00030000001424923539",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "2011.4262006305289",
    "Network": "Sun",
    "Channel_Name": "Gemini TV",
    "monthYear": "2022-9"
}, {
    "AdGRP": "3870.0125018730396",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV",
    "monthYear": "2022-9"
}, {
    "AdGRP": "332.20560073297383",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada Cinema",
    "monthYear": "2022-9"
}, {
    "AdGRP": "2.72850000015751",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "483.78950096904737",
    "Network": "Viacom",
    "Channel_Name": "Sonic Nickelodeon(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "17.117700033260917",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "2376.3778995978646",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada",
    "monthYear": "2022-9"
}, {
    "AdGRP": "4.4104000071456539",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.0082000000693369657",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity SD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "4125.1479013348",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB",
    "monthYear": "2022-9"
}, {
    "AdGRP": "202.66790041114291",
    "Network": "Viacom",
    "Channel_Name": "Colors HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "825.22810157788626",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay Super",
    "monthYear": "2022-9"
}, {
    "AdGRP": "28.910500031575793",
    "Network": "Sun",
    "Channel_Name": "Udaya TV HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "33.007200067688245",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa Movies HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "316.75610073171265",
    "Network": "ZEEL",
    "Channel_Name": "Zee Thirai",
    "monthYear": "2022-9"
}, {
    "AdGRP": "6563.0145989239682",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1830.2069008930121",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Wah",
    "monthYear": "2022-9"
}, {
    "AdGRP": "381.0786007702045",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1177.0422001571205",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi",
    "monthYear": "2022-9"
}, {
    "AdGRP": "9.5151000317273429",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.12440000076458091",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "411.94740032867412",
    "Network": "Sun",
    "Channel_Name": "Sun TV HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "350.60000071309332",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.24740000120073091",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "304.8650005948075",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla",
    "monthYear": "2022-9"
}, {
    "AdGRP": "255.30150063290057",
    "Network": "Sun",
    "Channel_Name": "Kushi TV",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1304.5876014207024",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna",
    "monthYear": "2022-9"
}, {
    "AdGRP": "4576.2938022245653",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu",
    "monthYear": "2022-9"
}, {
    "AdGRP": "440.21390084963059",
    "Network": "ZEEL",
    "Channel_Name": "Zee Ganga",
    "monthYear": "2022-9"
}, {
    "AdGRP": "81.524100234462821",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "15.415200044830272",
    "Network": "Sun",
    "Channel_Name": "Sun Music HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "694.62450152300153",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex",
    "monthYear": "2022-9"
}, {
    "AdGRP": "23.336500089062611",
    "Network": "Sun",
    "Channel_Name": "Surya TV HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1599.6994001152343",
    "Network": "Sun",
    "Channel_Name": "Udaya TV",
    "monthYear": "2022-9"
}, {
    "AdGRP": "396.79960088353255",
    "Network": "Viacom",
    "Channel_Name": "Colors Rishtey",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.10690000028989743",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life HD(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "48.078600130873383",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "521.96710099453048",
    "Network": "ZEEL",
    "Channel_Name": "Zing",
    "monthYear": "2022-9"
}, {
    "AdGRP": "62.503000167263963",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "516.07210111618042",
    "Network": "Viacom",
    "Channel_Name": "NICK Junior(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "53.66930008889176",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "276.3230004666184",
    "Network": "Sun",
    "Channel_Name": "Sun Marathi",
    "monthYear": "2022-9"
}, {
    "AdGRP": "852.11280205201183",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold 2",
    "monthYear": "2022-9"
}, {
    "AdGRP": "441.93640100346965",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Aath",
    "monthYear": "2022-9"
}, {
    "AdGRP": "2006.4933012106922",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1.6784000050538452",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pix HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1566.7150010592959",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi",
    "monthYear": "2022-9"
}, {
    "AdGRP": "202.36940047715325",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.015800000066519715",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "36.383500127158186",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "286.49240076425485",
    "Network": "Viacom",
    "Channel_Name": "Colors Super",
    "monthYear": "2022-9"
}, {
    "AdGRP": "6.2279000107664615",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1.9155000058162841",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.075300000069546513",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central",
    "monthYear": "2022-9"
}, {
    "AdGRP": "690.04400125704706",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV",
    "monthYear": "2022-9"
}, {
    "AdGRP": "72.315100200816232",
    "Network": "Sun",
    "Channel_Name": "KTV HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "119.73240019231889",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.10080000114248833",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest HD(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "281.51400058033323",
    "Network": "Sun",
    "Channel_Name": "Gemini Comedy",
    "monthYear": "2022-9"
}, {
    "AdGRP": "338.79850093371351",
    "Network": "ZEEL",
    "Channel_Name": "Zee Classic",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.026900000288151205",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cafe HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "913.49610110627691",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures",
    "monthYear": "2022-9"
}, {
    "AdGRP": "15.2744000352468",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.52920000743324636",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1335.6611004349834",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Superhits",
    "monthYear": "2022-9"
}, {
    "AdGRP": "150.65320035472541",
    "Network": "Sun",
    "Channel_Name": "Chintu TV",
    "monthYear": "2022-9"
}, {
    "AdGRP": "3799.5832010455706",
    "Network": "Viacom",
    "Channel_Name": "Colors",
    "monthYear": "2022-9"
}, {
    "AdGRP": "68.672100175914238",
    "Network": "Viacom",
    "Channel_Name": "MTV",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1.572700005468505",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic HD(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "608.00490089057712",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Marathi",
    "monthYear": "2022-9"
}, {
    "AdGRP": "100.43710007044865",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX HD(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "2485.6727011611219",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol Cinema",
    "monthYear": "2022-9"
}, {
    "AdGRP": "38.535800052319246",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1082.4878997432534",
    "Network": "ZEEL",
    "Channel_Name": "Zee Sarthak",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1609.2321018291914",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema",
    "monthYear": "2022-9"
}, {
    "AdGRP": "120.72450037002272",
    "Network": "Viacom",
    "Channel_Name": "Colors Oriya",
    "monthYear": "2022-9"
}, {
    "AdGRP": "219.25950062909396",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "84.4066001276733",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "572.85070116888528",
    "Network": "ZEEL",
    "Channel_Name": "Zee Biskope",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.0",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Kiran HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.028900000274006743",
    "Network": "Viacom",
    "Channel_Name": "Nick HD+(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "7.0424000228667865",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1.0078000092107686",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild HD(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "150.79420038862736",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Plus",
    "monthYear": "2022-9"
}, {
    "AdGRP": "486.43350094146444",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Gold",
    "monthYear": "2022-9"
}, {
    "AdGRP": "578.56170125749486",
    "Network": "ZEEL",
    "Channel_Name": "Zee Action",
    "monthYear": "2022-9"
}, {
    "AdGRP": "88.145300190954",
    "Network": "Sun",
    "Channel_Name": "Surya Music",
    "monthYear": "2022-9"
}, {
    "AdGRP": "464.99230077845277",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam",
    "monthYear": "2022-9"
}, {
    "AdGRP": "265.01290056182188",
    "Network": "ZEEL",
    "Channel_Name": "Zee Chitramandir",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1100.0783015920315",
    "Network": "Viacom",
    "Channel_Name": "NICK(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "384.80990091349668",
    "Network": "Sun",
    "Channel_Name": "Sun Music",
    "monthYear": "2022-9"
}, {
    "AdGRP": "369.35160067473771",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla Cinema",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1238.7387016238645",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.12670000081561739",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Xplor HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1337.2661007398274",
    "Network": "ZEEL",
    "Channel_Name": "Big Magic",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1585.0759017460514",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies",
    "monthYear": "2022-9"
}, {
    "AdGRP": "3865.76879892929",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha",
    "monthYear": "2022-9"
}, {
    "AdGRP": "7124.32049747929",
    "Network": "Sun",
    "Channel_Name": "Sun TV",
    "monthYear": "2022-9"
}, {
    "AdGRP": "711.27970151467161",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX 2",
    "monthYear": "2022-9"
}, {
    "AdGRP": "557.80240123078693",
    "Network": "Sun",
    "Channel_Name": "Adithya TV",
    "monthYear": "2022-9"
}, {
    "AdGRP": "193.59880037333642",
    "Network": "Sun",
    "Channel_Name": "Chutti TV",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.00050000002374872565",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World Premiere HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "188.47830046862509",
    "Network": "ZEEL",
    "Channel_Name": "Zee Yuva",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.70120000027964124",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "102.5472001889284",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "19.975100011637551",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Suvarna HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "506.82770091490238",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Music",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.058400001158588566",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World",
    "monthYear": "2022-9"
}, {
    "AdGRP": "436.01800081707188",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna Plus",
    "monthYear": "2022-9"
}, {
    "AdGRP": "86.949800178314035",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "559.31600094103487",
    "Network": "Sun",
    "Channel_Name": "Sun Life",
    "monthYear": "2022-9"
}, {
    "AdGRP": "3.7806000144337304",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "4005.0325015317649",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay",
    "monthYear": "2022-9"
}, {
    "AdGRP": "390.188400737803",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Movies",
    "monthYear": "2022-9"
}, {
    "AdGRP": "2024.2564010046481",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav Movies",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.011000000056810677",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "866.69180180910189",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bollywood",
    "monthYear": "2022-9"
}, {
    "AdGRP": "46.890600159204041",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "27.450900093070231",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.41440000066359062",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "357.68510055797378",
    "Network": "Sun",
    "Channel_Name": "Sun Bangla",
    "monthYear": "2022-9"
}, {
    "AdGRP": "378.25780067693267",
    "Network": "ZEEL",
    "Channel_Name": "Zee Picchar",
    "monthYear": "2022-9"
}, {
    "AdGRP": "159.89060038808384",
    "Network": "Star Network Ent",
    "Channel_Name": "Pravah Picture",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1282.1232013569679",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Movies",
    "monthYear": "2022-9"
}, {
    "AdGRP": "39.256500101808342",
    "Network": "Sun",
    "Channel_Name": "Gemini TV HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.26720000072236871",
    "Network": "Viacom",
    "Channel_Name": "VH1 HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "112.89440018284949",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati Cinema",
    "monthYear": "2022-9"
}, {
    "AdGRP": "22.874700069893152",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Kiran",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1452.1776015555952",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold",
    "monthYear": "2022-9"
}, {
    "AdGRP": "298.60080055541039",
    "Network": "Sun",
    "Channel_Name": "Udaya Comedy",
    "monthYear": "2022-9"
}, {
    "AdGRP": "2278.3943007824128",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.35660000025382033",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix",
    "monthYear": "2022-9"
}, {
    "AdGRP": "266.60790058796556",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla Cinema",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1694.6983008312527",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "7.0278000213220366",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony PIX",
    "monthYear": "2022-9"
}, {
    "AdGRP": "184.77360035163292",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "182.18050022646639",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati",
    "monthYear": "2022-9"
}, {
    "AdGRP": "420.55420077932649",
    "Network": "Sun",
    "Channel_Name": "Udaya Music",
    "monthYear": "2022-9"
}, {
    "AdGRP": "857.94690086189075",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pal",
    "monthYear": "2022-9"
}, {
    "AdGRP": "4.8214000028892769",
    "Network": "Sun",
    "Channel_Name": "Gemini Music HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "416.22820083721308",
    "Network": "Sun",
    "Channel_Name": "Surya TV",
    "monthYear": "2022-9"
}, {
    "AdGRP": "677.95260122593027",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies",
    "monthYear": "2022-9"
}, {
    "AdGRP": "20.771000056163757",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.50850000174250454",
    "Network": "Viacom",
    "Channel_Name": "MTV HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "155.47940031889448",
    "Network": "Sun",
    "Channel_Name": "Kochu TV",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1693.2937008515",
    "Network": "Sun",
    "Channel_Name": "KTV",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1.0103000059898477",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies HD(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.11530000025959453",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies Select HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "120.96780020133156",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "83.324600200889108",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "5509.5557003170252",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1005.4770016641851",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav",
    "monthYear": "2022-9"
}, {
    "AdGRP": "3036.1476000426337",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla",
    "monthYear": "2022-9"
}, {
    "AdGRP": "4443.0109990499914",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.03639999949518824",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Prive HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "27.904600032226881",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "2349.26950113997",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet",
    "monthYear": "2022-9"
}, {
    "AdGRP": "530.45920098183706",
    "Network": "Sun",
    "Channel_Name": "Gemini life",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.45970000445959158",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth HD(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "648.89030098064541",
    "Network": "ZEEL",
    "Channel_Name": "Zee Punjabi",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.29070000442152377",
    "Network": "Star Network Ent",
    "Channel_Name": "Pravah Picture HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "18.303700015872892",
    "Network": "Star Network Ent",
    "Channel_Name": "Vijay Music",
    "monthYear": "2022-9"
}, {
    "AdGRP": "4.9663000141154043",
    "Network": "Viacom",
    "Channel_Name": "VH1",
    "monthYear": "2022-9"
}, {
    "AdGRP": "469.3015011063253",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies",
    "monthYear": "2022-9"
}, {
    "AdGRP": "18.662200026810751",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "509.31960057726246",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "609.05410098051652",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony YAY(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "5.7416000189623446",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "249.77910052480729",
    "Network": "Sun",
    "Channel_Name": "Gemini Music",
    "monthYear": "2022-9"
}, {
    "AdGRP": "22.361400043359026",
    "Network": "Viacom",
    "Channel_Name": "History TV 18(v)",
    "monthYear": "2022-9"
}, {
    "AdGRP": "3620.8233981619123",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.3009000021556858",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select SD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "1445.2465016397036",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Bollywood",
    "monthYear": "2022-9"
}, {
    "AdGRP": "241.87230063269817",
    "Network": "Sun",
    "Channel_Name": "Surya Movies",
    "monthYear": "2022-9"
}, {
    "AdGRP": "0.28730000217910856",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla HD",
    "monthYear": "2022-9"
}, {
    "AdGRP": "386.49420079553238",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna Plus",
    "monthYear": "2023-1"
}, {
    "AdGRP": "11.733700048971514",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "55.47600007661822",
    "Network": "Sun",
    "Channel_Name": "Gemini TV HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.798499997916224",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "62.975100091185595",
    "Network": "Sun",
    "Channel_Name": "Surya Comedy",
    "monthYear": "2023-1"
}, {
    "AdGRP": "14.426700078976864",
    "Network": "Sun",
    "Channel_Name": "Surya TV HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1234.6752004924201",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi",
    "monthYear": "2023-1"
}, {
    "AdGRP": "260.83650072417367",
    "Network": "Star Network Ent",
    "Channel_Name": "Pravah Picture",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.0090000000564032234",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cafe HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "481.31800135882077",
    "Network": "ZEEL",
    "Channel_Name": "Zing",
    "monthYear": "2023-1"
}, {
    "AdGRP": "51.975300124096975",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1338.9537010439672",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies",
    "monthYear": "2023-1"
}, {
    "AdGRP": "381.34170076640294",
    "Network": "Sun",
    "Channel_Name": "Udaya Music",
    "monthYear": "2023-1"
}, {
    "AdGRP": "412.8609008481435",
    "Network": "ZEEL",
    "Channel_Name": "Zee Picchar",
    "monthYear": "2023-1"
}, {
    "AdGRP": "166.09000017363724",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "425.01470016014355",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.49850000331207411",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "115.10960023924417",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati Cinema",
    "monthYear": "2023-1"
}, {
    "AdGRP": "423.04900045389513",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.3374000015319325",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Xplor HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "3781.5514003064018",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah",
    "monthYear": "2023-1"
}, {
    "AdGRP": "86.946800118254032",
    "Network": "Viacom",
    "Channel_Name": "MTV",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1286.4739005194278",
    "Network": "ZEEL",
    "Channel_Name": "Big Magic",
    "monthYear": "2023-1"
}, {
    "AdGRP": "273.204300331563",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.034000000356172677",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity SD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.46010000505339121",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth HD(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "3799.4413002584042",
    "Network": "Viacom",
    "Channel_Name": "Colors",
    "monthYear": "2023-1"
}, {
    "AdGRP": "368.29550098519394",
    "Network": "Viacom",
    "Channel_Name": "Colors Rishtey",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1747.6407008601818",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada",
    "monthYear": "2023-1"
}, {
    "AdGRP": "24.618000057613244",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "109.77440018741618",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Plus",
    "monthYear": "2023-1"
}, {
    "AdGRP": "6.115300011340878",
    "Network": "Viacom",
    "Channel_Name": "VH1",
    "monthYear": "2023-1"
}, {
    "AdGRP": "230.8124004942365",
    "Network": "Sun",
    "Channel_Name": "Udaya Comedy",
    "monthYear": "2023-1"
}, {
    "AdGRP": "3925.1605991851538",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada",
    "monthYear": "2023-1"
}, {
    "AdGRP": "545.38450111610291",
    "Network": "Sun",
    "Channel_Name": "Gemini life",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.012200000033772085",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.0035000000134459697",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World Premiere HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1482.229601177336",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol Cinema",
    "monthYear": "2023-1"
}, {
    "AdGRP": "22.564100027258974",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Suvarna HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1333.4422012859577",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Bollywood",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1814.3011000081169",
    "Network": "Sun",
    "Channel_Name": "Gemini TV",
    "monthYear": "2023-1"
}, {
    "AdGRP": "823.45430099325313",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures",
    "monthYear": "2023-1"
}, {
    "AdGRP": "182.32720036824321",
    "Network": "Viacom",
    "Channel_Name": "Colors HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "2406.5265009331924",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Wah",
    "monthYear": "2023-1"
}, {
    "AdGRP": "48.489700136597094",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX HD(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "34.780600052115915",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1774.9898023039641",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav Movies",
    "monthYear": "2023-1"
}, {
    "AdGRP": "26.815300076006679",
    "Network": "Viacom",
    "Channel_Name": "History TV 18(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "311.89800057327375",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada Cinema",
    "monthYear": "2023-1"
}, {
    "AdGRP": "92.558700173110992",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "10.645700020679215",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1229.4648015337298",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1.8952999995308346",
    "Network": "Viacom",
    "Channel_Name": "MTV HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "194.95480040460097",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla Cinema",
    "monthYear": "2023-1"
}, {
    "AdGRP": "67.535600156690634",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1340.2678000472224",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi",
    "monthYear": "2023-1"
}, {
    "AdGRP": "591.33950122725219",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies",
    "monthYear": "2023-1"
}, {
    "AdGRP": "32.574000103864819",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa Movies HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "4.0185000045603374",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony PIX",
    "monthYear": "2023-1"
}, {
    "AdGRP": "210.90010056282335",
    "Network": "Sun",
    "Channel_Name": "Gemini Music",
    "monthYear": "2023-1"
}, {
    "AdGRP": "2780.6968992285547",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla",
    "monthYear": "2023-1"
}, {
    "AdGRP": "5766.38790030207",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa",
    "monthYear": "2023-1"
}, {
    "AdGRP": "135.40940026793396",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "29.395800129241252",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "157.23170025589934",
    "Network": "Sun",
    "Channel_Name": "Chutti TV",
    "monthYear": "2023-1"
}, {
    "AdGRP": "192.88100045153988",
    "Network": "Sun",
    "Channel_Name": "Kochu TV",
    "monthYear": "2023-1"
}, {
    "AdGRP": "764.37170115718618",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav",
    "monthYear": "2023-1"
}, {
    "AdGRP": "4.9795000061567407",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.005300000004353933",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "537.84890081506455",
    "Network": "Sun",
    "Channel_Name": "Sun TV HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "136.27930023394583",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "3.6930000109205139",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "676.34580098509468",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Gold",
    "monthYear": "2023-1"
}, {
    "AdGRP": "581.86120116448728",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Marathi",
    "monthYear": "2023-1"
}, {
    "AdGRP": "368.83630084976903",
    "Network": "Viacom",
    "Channel_Name": "Sonic Nickelodeon(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1449.7441008305177",
    "Network": "Sun",
    "Channel_Name": "KTV",
    "monthYear": "2023-1"
}, {
    "AdGRP": "356.44900050161959",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Movies",
    "monthYear": "2023-1"
}, {
    "AdGRP": "23.974900055014587",
    "Network": "Sun",
    "Channel_Name": "Udaya TV HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "445.69330058283958",
    "Network": "Sun",
    "Channel_Name": "Sun Marathi",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.41310000297380611",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select SD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.0",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Kiran HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "401.30050065967953",
    "Network": "Sun",
    "Channel_Name": "Surya TV",
    "monthYear": "2023-1"
}, {
    "AdGRP": "127.65380024036131",
    "Network": "Viacom",
    "Channel_Name": "Colors Oriya",
    "monthYear": "2023-1"
}, {
    "AdGRP": "320.57270070350933",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil",
    "monthYear": "2023-1"
}, {
    "AdGRP": "258.70280074289622",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla Cinema",
    "monthYear": "2023-1"
}, {
    "AdGRP": "19.580200052296277",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "15.588300057424931",
    "Network": "Sun",
    "Channel_Name": "Sun Music HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "497.41540112717485",
    "Network": "ZEEL",
    "Channel_Name": "Zee Biskope",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1025.9149014197465",
    "Network": "ZEEL",
    "Channel_Name": "Zee Sarthak",
    "monthYear": "2023-1"
}, {
    "AdGRP": "3837.0128995664418",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.35860000357934041",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1213.9285011727334",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1214.141201921273",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat",
    "monthYear": "2023-1"
}, {
    "AdGRP": "547.80870056204731",
    "Network": "ZEEL",
    "Channel_Name": "Zee Punjabi",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.63920000773941865",
    "Network": "Star Network Ent",
    "Channel_Name": "Pravah Picture HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "67.648400164449413",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "49.78090011428867",
    "Network": "Sun",
    "Channel_Name": "KTV HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "3.9851000119524542",
    "Network": "Sun",
    "Channel_Name": "Gemini Music HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1.2568000028550159",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pix HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.034300000341318082",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Prive HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "3585.9436993960408",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1162.7561008740377",
    "Network": "Sun",
    "Channel_Name": "Udaya TV",
    "monthYear": "2023-1"
}, {
    "AdGRP": "973.90420081726188",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pal",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.12120000140566844",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies Select HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "792.05980072790408",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.82870000026014168",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.0061000000496278517",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World",
    "monthYear": "2023-1"
}, {
    "AdGRP": "324.99940074069309",
    "Network": "ZEEL",
    "Channel_Name": "Zee Ganga",
    "monthYear": "2023-1"
}, {
    "AdGRP": "827.04500083363382",
    "Network": "Sun",
    "Channel_Name": "Udaya Movies",
    "monthYear": "2023-1"
}, {
    "AdGRP": "852.82040102634346",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold 2",
    "monthYear": "2023-1"
}, {
    "AdGRP": "7022.5586981661618",
    "Network": "Sun",
    "Channel_Name": "Sun TV",
    "monthYear": "2023-1"
}, {
    "AdGRP": "330.139400794229",
    "Network": "ZEEL",
    "Channel_Name": "Zee Classic",
    "monthYear": "2023-1"
}, {
    "AdGRP": "2469.6697018203558",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil",
    "monthYear": "2023-1"
}, {
    "AdGRP": "157.79540028590418",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "47.437700089380087",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "491.83360103389714",
    "Network": "Sun",
    "Channel_Name": "Adithya TV",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.11090000117837917",
    "Network": "Viacom",
    "Channel_Name": "Nick HD+(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1701.8713009781204",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.46260000195616158",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild HD(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1898.9975009016634",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet",
    "monthYear": "2023-1"
}, {
    "AdGRP": "305.53500068096037",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla",
    "monthYear": "2023-1"
}, {
    "AdGRP": "581.73500143003184",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX 2",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.91950000280485256",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies HD(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "440.30500097409822",
    "Network": "Viacom",
    "Channel_Name": "NICK Junior(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "420.98500114015042",
    "Network": "Sun",
    "Channel_Name": "Sun Music",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1231.9194004939054",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "5907.0434982921",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1166.0376013286805",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema",
    "monthYear": "2023-1"
}, {
    "AdGRP": "190.0036005884831",
    "Network": "ZEEL",
    "Channel_Name": "Zee Yuva",
    "monthYear": "2023-1"
}, {
    "AdGRP": "334.30330024240538",
    "Network": "ZEEL",
    "Channel_Name": "Zee Chitramandir",
    "monthYear": "2023-1"
}, {
    "AdGRP": "114.58670029690984",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "69.012700159895758",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.56330000411981018",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "4.4382000167315709",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies",
    "monthYear": "2023-1"
}, {
    "AdGRP": "263.76930061469466",
    "Network": "Sun",
    "Channel_Name": "Gemini Comedy",
    "monthYear": "2023-1"
}, {
    "AdGRP": "4424.205598983448",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu",
    "monthYear": "2023-1"
}, {
    "AdGRP": "295.42030071705813",
    "Network": "ZEEL",
    "Channel_Name": "Zee Thirai",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.005200000072363764",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "368.15330070981872",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.061000000838248525",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life HD(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.11080000110086985",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest HD(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "326.46410065171949",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.3118000024187495",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "353.21390088139742",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Aath",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1431.2787010357715",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna",
    "monthYear": "2023-1"
}, {
    "AdGRP": "24.663200042530661",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "384.9044004842508",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "228.54860062740772",
    "Network": "Sun",
    "Channel_Name": "Kushi TV",
    "monthYear": "2023-1"
}, {
    "AdGRP": "3458.4322999927681",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB",
    "monthYear": "2023-1"
}, {
    "AdGRP": "292.67050064421346",
    "Network": "Viacom",
    "Channel_Name": "Colors Super",
    "monthYear": "2023-1"
}, {
    "AdGRP": "347.37720083683234",
    "Network": "Sun",
    "Channel_Name": "Sun Bangla",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1453.5475009360816",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Movies",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1.2331000071644667",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic HD(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "4.5558000084347441",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "498.11880113917869",
    "Network": "ZEEL",
    "Channel_Name": "Zee Action",
    "monthYear": "2023-1"
}, {
    "AdGRP": "617.72590096876957",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony YAY(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "213.46600044280785",
    "Network": "Sun",
    "Channel_Name": "Surya Movies",
    "monthYear": "2023-1"
}, {
    "AdGRP": "3304.3988987663688",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha",
    "monthYear": "2023-1"
}, {
    "AdGRP": "188.09290033033176",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.24040000068634981",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.3241000017078477",
    "Network": "Viacom",
    "Channel_Name": "VH1 HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.240100002316467",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "700.63030157005414",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV",
    "monthYear": "2023-1"
}, {
    "AdGRP": "12.563500042248052",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "69.631100199148932",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "17.572200044174679",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "1498.1204008330606",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Superhits",
    "monthYear": "2023-1"
}, {
    "AdGRP": "91.942300224516657",
    "Network": "Sun",
    "Channel_Name": "Chintu TV",
    "monthYear": "2023-1"
}, {
    "AdGRP": "759.96650085132569",
    "Network": "Viacom",
    "Channel_Name": "NICK(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "61.998600115359295",
    "Network": "Sun",
    "Channel_Name": "Surya Music",
    "monthYear": "2023-1"
}, {
    "AdGRP": "444.17630101079703",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Music",
    "monthYear": "2023-1"
}, {
    "AdGRP": "2.7542000096145784",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "32.6266000291871",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "711.101201154408",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bollywood",
    "monthYear": "2023-1"
}, {
    "AdGRP": "790.01270121392736",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay Super",
    "monthYear": "2023-1"
}, {
    "AdGRP": "30.246600078025949",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild(v)",
    "monthYear": "2023-1"
}, {
    "AdGRP": "138.08410023120814",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Kiran",
    "monthYear": "2023-1"
}, {
    "AdGRP": "557.21390086025349",
    "Network": "Sun",
    "Channel_Name": "Sun Life",
    "monthYear": "2023-1"
}, {
    "AdGRP": "4.8854000235296553",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex HD",
    "monthYear": "2023-1"
}, {
    "AdGRP": "0.24250000106258085",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central",
    "monthYear": "2023-1"
}, {
    "AdGRP": "66.719100164918927",
    "Network": "Sun",
    "Channel_Name": "Surya Music",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.96819999869330786",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "25.761000086087734",
    "Network": "Sun",
    "Channel_Name": "Udaya TV HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "2.5290999991775607",
    "Network": "Viacom",
    "Channel_Name": "MTV HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "2156.4564996480767",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Wah",
    "monthYear": "2023-2"
}, {
    "AdGRP": "525.30370063020382",
    "Network": "ZEEL",
    "Channel_Name": "Zee Punjabi",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1.6549000064405845",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies HD(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.20100000039383303",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select SD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "405.74850064995553",
    "Network": "ZEEL",
    "Channel_Name": "Zee Action",
    "monthYear": "2023-2"
}, {
    "AdGRP": "85.963200174999656",
    "Network": "Sun",
    "Channel_Name": "Chintu TV",
    "monthYear": "2023-2"
}, {
    "AdGRP": "844.75470064184628",
    "Network": "Viacom",
    "Channel_Name": "NICK(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "423.87190085550537",
    "Network": "ZEEL",
    "Channel_Name": "Zee Picchar",
    "monthYear": "2023-2"
}, {
    "AdGRP": "249.64820035667071",
    "Network": "ZEEL",
    "Channel_Name": "Zee Chitramandir",
    "monthYear": "2023-2"
}, {
    "AdGRP": "14.102800057196873",
    "Network": "Sun",
    "Channel_Name": "Surya TV HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1114.3429009440588",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold",
    "monthYear": "2023-2"
}, {
    "AdGRP": "570.66610132041387",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX 2",
    "monthYear": "2023-2"
}, {
    "AdGRP": "246.35440031166945",
    "Network": "ZEEL",
    "Channel_Name": "Zee Classic",
    "monthYear": "2023-2"
}, {
    "AdGRP": "460.34940027061384",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "230.6156005000521",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1596.4620015227119",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.085100000658712815",
    "Network": "Viacom",
    "Channel_Name": "Nick HD+(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1616.2788018287538",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet",
    "monthYear": "2023-2"
}, {
    "AdGRP": "167.0302002975659",
    "Network": "Sun",
    "Channel_Name": "Chutti TV",
    "monthYear": "2023-2"
}, {
    "AdGRP": "559.00280090479646",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony YAY(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "378.32260060764384",
    "Network": "Viacom",
    "Channel_Name": "Sonic Nickelodeon(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "298.0710006874433",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada Cinema",
    "monthYear": "2023-2"
}, {
    "AdGRP": "12.155900031211786",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "24.846600044904335",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa Movies HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "13.328300023589691",
    "Network": "Sun",
    "Channel_Name": "Sun Music HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.14340000141237397",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "369.94890069247049",
    "Network": "Sun",
    "Channel_Name": "Surya TV",
    "monthYear": "2023-2"
}, {
    "AdGRP": "270.88270053109591",
    "Network": "ZEEL",
    "Channel_Name": "Zee Thirai",
    "monthYear": "2023-2"
}, {
    "AdGRP": "90.879800223796337",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1579.5966011806668",
    "Network": "Sun",
    "Channel_Name": "Gemini TV",
    "monthYear": "2023-2"
}, {
    "AdGRP": "2370.2596002315258",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1342.9196011773311",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna",
    "monthYear": "2023-2"
}, {
    "AdGRP": "104.58620025646815",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.41030000399769051",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.13470000174129382",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Prive HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.0011000000085914508",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World Premiere HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "336.72210050217109",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam",
    "monthYear": "2023-2"
}, {
    "AdGRP": "72.723200195767276",
    "Network": "Viacom",
    "Channel_Name": "MTV",
    "monthYear": "2023-2"
}, {
    "AdGRP": "338.98500057183264",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Movies",
    "monthYear": "2023-2"
}, {
    "AdGRP": "883.848100891686",
    "Network": "Sun",
    "Channel_Name": "Udaya Movies",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.019100000456091948",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1643.1119009784306",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav Movies",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1678.2992010726011",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Superhits",
    "monthYear": "2023-2"
}, {
    "AdGRP": "998.22760047470365",
    "Network": "Sun",
    "Channel_Name": "Udaya TV",
    "monthYear": "2023-2"
}, {
    "AdGRP": "3582.8557990789413",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay",
    "monthYear": "2023-2"
}, {
    "AdGRP": "112.17020016798779",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Plus",
    "monthYear": "2023-2"
}, {
    "AdGRP": "63.272100207090261",
    "Network": "Sun",
    "Channel_Name": "Surya Comedy",
    "monthYear": "2023-2"
}, {
    "AdGRP": "2016.3646012046374",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1210.014500958845",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Movies",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.52620000124443322",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "891.93440031441423",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pal",
    "monthYear": "2023-2"
}, {
    "AdGRP": "155.61950027768762",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "28.661300046835095",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "34.532800102737383",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "291.3872005501762",
    "Network": "ZEEL",
    "Channel_Name": "Zee Ganga",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1.5224000082962448",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic HD(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "174.426600153216",
    "Network": "Viacom",
    "Channel_Name": "Colors HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "124.21580035342777",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati Cinema",
    "monthYear": "2023-2"
}, {
    "AdGRP": "386.125700978213",
    "Network": "Sun",
    "Channel_Name": "Adithya TV",
    "monthYear": "2023-2"
}, {
    "AdGRP": "308.52110058907419",
    "Network": "Sun",
    "Channel_Name": "Sun Bangla",
    "monthYear": "2023-2"
}, {
    "AdGRP": "18.942700028674153",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "32.426500065892469",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.35640000193961896",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1118.6539009277985",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema",
    "monthYear": "2023-2"
}, {
    "AdGRP": "734.08550109776843",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold 2",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1482.6108000266249",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1.8278000148493447",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pix HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.0072000000145635568",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1393.6695003173227",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Bollywood",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1243.603200885409",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi",
    "monthYear": "2023-2"
}, {
    "AdGRP": "24.11560006444779",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.008600000073784031",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.3482000008225441",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Xplor HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "3433.0802014415385",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV",
    "monthYear": "2023-2"
}, {
    "AdGRP": "5493.3148009800352",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa",
    "monthYear": "2023-2"
}, {
    "AdGRP": "101.40780011288734",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Kiran",
    "monthYear": "2023-2"
}, {
    "AdGRP": "2894.1025013055478",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha",
    "monthYear": "2023-2"
}, {
    "AdGRP": "315.4451007435855",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies",
    "monthYear": "2023-2"
}, {
    "AdGRP": "9.9644000397893251",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "59.379900174833892",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "385.97780052999587",
    "Network": "Viacom",
    "Channel_Name": "Colors Rishtey",
    "monthYear": "2023-2"
}, {
    "AdGRP": "6.2629000154629466",
    "Network": "Viacom",
    "Channel_Name": "VH1",
    "monthYear": "2023-2"
}, {
    "AdGRP": "412.25250072482595",
    "Network": "ZEEL",
    "Channel_Name": "Zing",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1109.632300290803",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi",
    "monthYear": "2023-2"
}, {
    "AdGRP": "223.73940033504914",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati",
    "monthYear": "2023-2"
}, {
    "AdGRP": "346.72110034362413",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla",
    "monthYear": "2023-2"
}, {
    "AdGRP": "363.47280059908371",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna Plus",
    "monthYear": "2023-2"
}, {
    "AdGRP": "128.97380019810225",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "30.434300057553628",
    "Network": "Viacom",
    "Channel_Name": "History TV 18(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "513.11700089900114",
    "Network": "Sun",
    "Channel_Name": "Gemini life",
    "monthYear": "2023-2"
}, {
    "AdGRP": "837.80630111310165",
    "Network": "ZEEL",
    "Channel_Name": "Zee Sarthak",
    "monthYear": "2023-2"
}, {
    "AdGRP": "104.12830027300515",
    "Network": "Viacom",
    "Channel_Name": "Colors Oriya",
    "monthYear": "2023-2"
}, {
    "AdGRP": "5.9028000150283333",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "516.13690091646276",
    "Network": "Viacom",
    "Channel_Name": "NICK Junior(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "154.34710023018852",
    "Network": "ZEEL",
    "Channel_Name": "Zee Yuva",
    "monthYear": "2023-2"
}, {
    "AdGRP": "241.2319004158926",
    "Network": "Sun",
    "Channel_Name": "Udaya Comedy",
    "monthYear": "2023-2"
}, {
    "AdGRP": "10.339900032915466",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1073.0423015634879",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu",
    "monthYear": "2023-2"
}, {
    "AdGRP": "47.833700101145951",
    "Network": "Sun",
    "Channel_Name": "KTV HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.32650000230205478",
    "Network": "Star Network Ent",
    "Channel_Name": "Pravah Picture HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.57390000162558863",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.57260000214591855",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth HD(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "446.17360070673749",
    "Network": "Sun",
    "Channel_Name": "Sun TV HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "11.399500030936906",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "389.27470083657681",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Music",
    "monthYear": "2023-2"
}, {
    "AdGRP": "199.72780048123241",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla Cinema",
    "monthYear": "2023-2"
}, {
    "AdGRP": "46.422400032221049",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "517.04610115710238",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Marathi",
    "monthYear": "2023-2"
}, {
    "AdGRP": "3.5204000125813764",
    "Network": "Sun",
    "Channel_Name": "Gemini Music HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.42770000243035611",
    "Network": "Viacom",
    "Channel_Name": "VH1 HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1026.9655015065655",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex",
    "monthYear": "2023-2"
}, {
    "AdGRP": "241.09900056006154",
    "Network": "Sun",
    "Channel_Name": "Gemini Comedy",
    "monthYear": "2023-2"
}, {
    "AdGRP": "972.92660185089335",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat",
    "monthYear": "2023-2"
}, {
    "AdGRP": "6.4032000225488446",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies",
    "monthYear": "2023-2"
}, {
    "AdGRP": "376.5075007280102",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.3185999996640021",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies Select HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.00480000012612436",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1120.2096011191534",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "2623.7731996996736",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla",
    "monthYear": "2023-2"
}, {
    "AdGRP": "445.0464006129987",
    "Network": "Sun",
    "Channel_Name": "Sun Marathi",
    "monthYear": "2023-2"
}, {
    "AdGRP": "196.02270033155219",
    "Network": "Sun",
    "Channel_Name": "Gemini Music",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.72100000363570871",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild HD(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "362.71090064216696",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats",
    "monthYear": "2023-2"
}, {
    "AdGRP": "510.8492011151975",
    "Network": "Sun",
    "Channel_Name": "Sun Life",
    "monthYear": "2023-2"
}, {
    "AdGRP": "530.89980102027766",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies",
    "monthYear": "2023-2"
}, {
    "AdGRP": "52.041900234078639",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.52360000433691312",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix",
    "monthYear": "2023-2"
}, {
    "AdGRP": "5.3395000101299956",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.1597000011097407",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity SD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "546.09810088762606",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV",
    "monthYear": "2023-2"
}, {
    "AdGRP": "4306.6137016173452",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu",
    "monthYear": "2023-2"
}, {
    "AdGRP": "3280.3087008008733",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada",
    "monthYear": "2023-2"
}, {
    "AdGRP": "218.33970041584689",
    "Network": "Sun",
    "Channel_Name": "Kushi TV",
    "monthYear": "2023-2"
}, {
    "AdGRP": "706.55210118272225",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay Super",
    "monthYear": "2023-2"
}, {
    "AdGRP": "175.05620045945398",
    "Network": "Sun",
    "Channel_Name": "Kochu TV",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.0",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Kiran HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "6209.8797958227806",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus",
    "monthYear": "2023-2"
}, {
    "AdGRP": "58.662800131642143",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "76.634700193317258",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1330.4182010544464",
    "Network": "Sun",
    "Channel_Name": "KTV",
    "monthYear": "2023-2"
}, {
    "AdGRP": "2.7170000120968325",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.010999999845807906",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cafe HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "208.64060032334964",
    "Network": "Sun",
    "Channel_Name": "Surya Movies",
    "monthYear": "2023-2"
}, {
    "AdGRP": "3391.6954002195271",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah",
    "monthYear": "2023-2"
}, {
    "AdGRP": "329.846900615652",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil",
    "monthYear": "2023-2"
}, {
    "AdGRP": "536.09870113294164",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Gold",
    "monthYear": "2023-2"
}, {
    "AdGRP": "610.03050127499591",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bollywood",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.092100000714708585",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest HD(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.27209999940532725",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "55.230600084461912",
    "Network": "Sun",
    "Channel_Name": "Gemini TV HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "32.0722000663518",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Suvarna HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "278.08130058913957",
    "Network": "Viacom",
    "Channel_Name": "Colors Super",
    "monthYear": "2023-2"
}, {
    "AdGRP": "354.85630078917893",
    "Network": "Sun",
    "Channel_Name": "Sun Music",
    "monthYear": "2023-2"
}, {
    "AdGRP": "3952.6153986625141",
    "Network": "Viacom",
    "Channel_Name": "Colors",
    "monthYear": "2023-2"
}, {
    "AdGRP": "703.87670095927751",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav",
    "monthYear": "2023-2"
}, {
    "AdGRP": "13.369600032601738",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1183.8298009927385",
    "Network": "ZEEL",
    "Channel_Name": "Big Magic",
    "monthYear": "2023-2"
}, {
    "AdGRP": "438.28480073848914",
    "Network": "ZEEL",
    "Channel_Name": "Zee Biskope",
    "monthYear": "2023-2"
}, {
    "AdGRP": "79.839100152006722",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "59.718400122583262",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "3.986200004532293",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "0.089600000821519643",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life HD(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "1337.3718011646342",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol Cinema",
    "monthYear": "2023-2"
}, {
    "AdGRP": "100.2125001365348",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "2.1335000092003611",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "312.50360062348045",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla Cinema",
    "monthYear": "2023-2"
}, {
    "AdGRP": "683.80890076873766",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures",
    "monthYear": "2023-2"
}, {
    "AdGRP": "140.07260027882148",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "6573.5337040293962",
    "Network": "Sun",
    "Channel_Name": "Sun TV",
    "monthYear": "2023-2"
}, {
    "AdGRP": "317.32420072859532",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Aath",
    "monthYear": "2023-2"
}, {
    "AdGRP": "380.69540081033483",
    "Network": "Sun",
    "Channel_Name": "Udaya Music",
    "monthYear": "2023-2"
}, {
    "AdGRP": "48.909300118779356",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX HD(v)",
    "monthYear": "2023-2"
}, {
    "AdGRP": "214.3408005147503",
    "Network": "Star Network Ent",
    "Channel_Name": "Pravah Picture",
    "monthYear": "2023-2"
}, {
    "AdGRP": "3327.1721997396089",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB",
    "monthYear": "2023-2"
}, {
    "AdGRP": "34.528000063146465",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies HD",
    "monthYear": "2023-2"
}, {
    "AdGRP": "6.2611000135948416",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony PIX",
    "monthYear": "2023-2"
}, {
    "AdGRP": "293.82440074293118",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats",
    "monthYear": "2023-3"
}, {
    "AdGRP": "400.78140104096383",
    "Network": "Sun",
    "Channel_Name": "Sun Life",
    "monthYear": "2023-3"
}, {
    "AdGRP": "51.632500050021918",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX HD(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1112.680700649973",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies",
    "monthYear": "2023-3"
}, {
    "AdGRP": "23.8042000370624",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.55660000088391826",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild HD(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "4.0739000191097148",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.017100000026402995",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "430.71470055768441",
    "Network": "ZEEL",
    "Channel_Name": "Zee Action",
    "monthYear": "2023-3"
}, {
    "AdGRP": "222.77360040873464",
    "Network": "Sun",
    "Channel_Name": "Kushi TV",
    "monthYear": "2023-3"
}, {
    "AdGRP": "30.423500031531148",
    "Network": "Sun",
    "Channel_Name": "Gemini TV HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.94800000062241452",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pix HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "3202.6707995270262",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1352.6669010396",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol Cinema",
    "monthYear": "2023-3"
}, {
    "AdGRP": "371.82420087767969",
    "Network": "ZEEL",
    "Channel_Name": "Zee Picchar",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1600.1087005173758",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada",
    "monthYear": "2023-3"
}, {
    "AdGRP": "276.74840053658409",
    "Network": "Sun",
    "Channel_Name": "Sun Music",
    "monthYear": "2023-3"
}, {
    "AdGRP": "374.76420066592254",
    "Network": "Viacom",
    "Channel_Name": "Colors Rishtey",
    "monthYear": "2023-3"
}, {
    "AdGRP": "303.56140057316225",
    "Network": "ZEEL",
    "Channel_Name": "Zee Biskope",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1.9361999986504088",
    "Network": "Viacom",
    "Channel_Name": "MTV HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "944.773201121483",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat",
    "monthYear": "2023-3"
}, {
    "AdGRP": "201.39610044813162",
    "Network": "Sun",
    "Channel_Name": "Gemini Comedy",
    "monthYear": "2023-3"
}, {
    "AdGRP": "302.56610042795364",
    "Network": "Sun",
    "Channel_Name": "Udaya Music",
    "monthYear": "2023-3"
}, {
    "AdGRP": "342.96700048393541",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam",
    "monthYear": "2023-3"
}, {
    "AdGRP": "979.176400563767",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1397.8854014343669",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet",
    "monthYear": "2023-3"
}, {
    "AdGRP": "787.46940069550328",
    "Network": "ZEEL",
    "Channel_Name": "Zee Sarthak",
    "monthYear": "2023-3"
}, {
    "AdGRP": "3290.7921012965962",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay",
    "monthYear": "2023-3"
}, {
    "AdGRP": "56.259800106541661",
    "Network": "Viacom",
    "Channel_Name": "Colors Oriya",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1110.1865008287132",
    "Network": "Sun",
    "Channel_Name": "KTV",
    "monthYear": "2023-3"
}, {
    "AdGRP": "5.6580000120811746",
    "Network": "Viacom",
    "Channel_Name": "VH1",
    "monthYear": "2023-3"
}, {
    "AdGRP": "265.3619006035733",
    "Network": "ZEEL",
    "Channel_Name": "Zee Thirai",
    "monthYear": "2023-3"
}, {
    "AdGRP": "236.36960042185092",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla",
    "monthYear": "2023-3"
}, {
    "AdGRP": "27.86000007486291",
    "Network": "Sun",
    "Channel_Name": "Gemini Movies HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.313700001766847",
    "Network": "Viacom",
    "Channel_Name": "Comedy Central",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.33749999888823368",
    "Network": "Viacom",
    "Channel_Name": "Nick HD+(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.0",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World Premiere HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1104.1095003320806",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna",
    "monthYear": "2023-3"
}, {
    "AdGRP": "2929.8876999906497",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada",
    "monthYear": "2023-3"
}, {
    "AdGRP": "11.819400017120643",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "27.875400089251343",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Suvarna HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "62.73960016194178",
    "Network": "Viacom",
    "Channel_Name": "MTV",
    "monthYear": "2023-3"
}, {
    "AdGRP": "5959.5164972939529",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus",
    "monthYear": "2023-3"
}, {
    "AdGRP": "4093.0398000665009",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu",
    "monthYear": "2023-3"
}, {
    "AdGRP": "62.444000145987957",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "293.24470048296644",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Movies",
    "monthYear": "2023-3"
}, {
    "AdGRP": "491.46680055824982",
    "Network": "ZEEL",
    "Channel_Name": "Zee Punjabi",
    "monthYear": "2023-3"
}, {
    "AdGRP": "13.613500032741285",
    "Network": "Sun",
    "Channel_Name": "Sun Music HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1294.4806006270155",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Superhits",
    "monthYear": "2023-3"
}, {
    "AdGRP": "2227.6222003354997",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla",
    "monthYear": "2023-3"
}, {
    "AdGRP": "48.812600152712548",
    "Network": "Sun",
    "Channel_Name": "Surya Comedy",
    "monthYear": "2023-3"
}, {
    "AdGRP": "147.40850028277055",
    "Network": "ZEEL",
    "Channel_Name": "Zee Yuva",
    "monthYear": "2023-3"
}, {
    "AdGRP": "16.961800063108967",
    "Network": "Star Network Ent",
    "Channel_Name": "Nat Geo Wild(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "400.64220096851932",
    "Network": "Viacom",
    "Channel_Name": "Sonic Nickelodeon(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "115.54250019954634",
    "Network": "Viacom",
    "Channel_Name": "Colors HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "92.822600211773533",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Kiran",
    "monthYear": "2023-3"
}, {
    "AdGRP": "19.161900068233081",
    "Network": "Sun",
    "Channel_Name": "Udaya TV HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1.6997000059054699",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies HD(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1136.9852012389165",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold",
    "monthYear": "2023-3"
}, {
    "AdGRP": "99.329700094320287",
    "Network": "ZEEL",
    "Channel_Name": "Zee Kannada HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "201.76250052120304",
    "Network": "ZEEL",
    "Channel_Name": "Zee Anmol",
    "monthYear": "2023-3"
}, {
    "AdGRP": "43.638700075272936",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1.0100000040765735",
    "Network": "Star Network Ent",
    "Channel_Name": "National Geographic HD(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1663.1845005284922",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Wah",
    "monthYear": "2023-3"
}, {
    "AdGRP": "169.85200015400187",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.28120000170747517",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "105.28140032394003",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet Plus",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1052.2075012559071",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Movies",
    "monthYear": "2023-3"
}, {
    "AdGRP": "423.22820037930796",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Plus HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1.9982000160453026",
    "Network": "ZEEL",
    "Channel_Name": "Zee Keralam HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "173.79740032365225",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla Cinema",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.0038999999305815436",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.11119999997754348",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1137.0137013248459",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex Bollywood",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.088600000301084947",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "655.85720115026925",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1251.1654002179348",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinema",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.12470000024040928",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest HD(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "76.166900201773387",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bangla HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "38.17910008814215",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "57.509600134246284",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "874.400000984584",
    "Network": "ZEEL",
    "Channel_Name": "Big Magic",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.25290000160748605",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Xplor HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "625.51720097553334",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay Super",
    "monthYear": "2023-3"
}, {
    "AdGRP": "549.16920074167137",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX 2",
    "monthYear": "2023-3"
}, {
    "AdGRP": "60.84670012738934",
    "Network": "ZEEL",
    "Channel_Name": "Zee Marathi HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1.7980000122333877",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.0028999999922234565",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR World HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "2417.7571006799117",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil",
    "monthYear": "2023-3"
}, {
    "AdGRP": "287.22880051683023",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies",
    "monthYear": "2023-3"
}, {
    "AdGRP": "575.99670118268114",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV",
    "monthYear": "2023-3"
}, {
    "AdGRP": "9.9960000368737383",
    "Network": "ZEEL",
    "Channel_Name": "Zee Tamil HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "340.92900074664067",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil",
    "monthYear": "2023-3"
}, {
    "AdGRP": "91.428900204919046",
    "Network": "Viacom",
    "Channel_Name": "Colors Gujarati Cinema",
    "monthYear": "2023-3"
}, {
    "AdGRP": "476.8080008472898",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies",
    "monthYear": "2023-3"
}, {
    "AdGRP": "726.93450103414943",
    "Network": "Sun",
    "Channel_Name": "Udaya Movies",
    "monthYear": "2023-3"
}, {
    "AdGRP": "2.40250001194363",
    "Network": "Sun",
    "Channel_Name": "Gemini Music HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "21.148500043687818",
    "Network": "Viacom",
    "Channel_Name": "History TV 18(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "2.4652999900936265",
    "Network": "Viacom",
    "Channel_Name": "Colors Tamil HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.013800000000628643",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cafe HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "55.810400082387787",
    "Network": "Star Network Ent",
    "Channel_Name": "Vijay Takkar",
    "monthYear": "2023-3"
}, {
    "AdGRP": "381.26380089473969",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Music",
    "monthYear": "2023-3"
}, {
    "AdGRP": "822.4332002333249",
    "Network": "Sun",
    "Channel_Name": "Udaya TV",
    "monthYear": "2023-3"
}, {
    "AdGRP": "188.42380045938626",
    "Network": "Sun",
    "Channel_Name": "Udaya Comedy",
    "monthYear": "2023-3"
}, {
    "AdGRP": "440.84730111459066",
    "Network": "Sun",
    "Channel_Name": "Gemini life",
    "monthYear": "2023-3"
}, {
    "AdGRP": "476.83690089585434",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Marathi",
    "monthYear": "2023-3"
}, {
    "AdGRP": "367.79520065586257",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Suvarna Plus",
    "monthYear": "2023-3"
}, {
    "AdGRP": "198.10990032763948",
    "Network": "Sun",
    "Channel_Name": "Sun Bangla",
    "monthYear": "2023-3"
}, {
    "AdGRP": "2769.434000216308",
    "Network": "Viacom",
    "Channel_Name": "Colors",
    "monthYear": "2023-3"
}, {
    "AdGRP": "148.54660022507596",
    "Network": "Sun",
    "Channel_Name": "Surya Movies",
    "monthYear": "2023-3"
}, {
    "AdGRP": "299.23150050423283",
    "Network": "Sun",
    "Channel_Name": "Surya TV",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.16330000126617961",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony BBC Earth HD(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "3579.005498283077",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB",
    "monthYear": "2023-3"
}, {
    "AdGRP": "286.26280072552618",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Aath",
    "monthYear": "2023-3"
}, {
    "AdGRP": "539.16400100430474",
    "Network": "Viacom",
    "Channel_Name": "NICK Junior(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "9.2270000284115667",
    "Network": "ZEEL",
    "Channel_Name": "\u0026TV HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "8.3630000252014725",
    "Network": "ZEEL",
    "Channel_Name": "Zee Talkies HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1291.0397004786355",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony MAX(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "822.3616011777558",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Pal",
    "monthYear": "2023-3"
}, {
    "AdGRP": "35.06840006942366",
    "Network": "Sun",
    "Channel_Name": "KTV HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "4.3229000089122565",
    "Network": "Viacom",
    "Channel_Name": "MTV Beats HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1177.5371008073344",
    "Network": "Sun",
    "Channel_Name": "Gemini TV",
    "monthYear": "2023-3"
}, {
    "AdGRP": "660.27550101955421",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony YAY(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "122.47360033680161",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Vijay HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "375.09490046893916",
    "Network": "Sun",
    "Channel_Name": "Sun Marathi",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.0",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Kiran HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.084600000402133446",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity SD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.056300000796909444",
    "Network": "Star Network Ent",
    "Channel_Name": "Fox Life HD(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "487.41180101018108",
    "Network": "Star Network Ent",
    "Channel_Name": "Star Maa Gold",
    "monthYear": "2023-3"
}, {
    "AdGRP": "25.939100056733878",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "173.463000462536",
    "Network": "Sun",
    "Channel_Name": "Chutti TV",
    "monthYear": "2023-3"
}, {
    "AdGRP": "133.0055002782683",
    "Network": "ZEEL",
    "Channel_Name": "Zee TV HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "14.462900024118426",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Bharat HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "241.11550050235383",
    "Network": "Viacom",
    "Channel_Name": "Colors Super",
    "monthYear": "2023-3"
}, {
    "AdGRP": "130.26100031443639",
    "Network": "Sun",
    "Channel_Name": "Kochu TV",
    "monthYear": "2023-3"
}, {
    "AdGRP": "186.81510041184083",
    "Network": "ZEEL",
    "Channel_Name": "Zee Ganga",
    "monthYear": "2023-3"
}, {
    "AdGRP": "363.71180101448408",
    "Network": "ZEEL",
    "Channel_Name": "Zing",
    "monthYear": "2023-3"
}, {
    "AdGRP": "2465.0970984201995",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Jalsha",
    "monthYear": "2023-3"
}, {
    "AdGRP": "5946.8660984709859",
    "Network": "Sun",
    "Channel_Name": "Sun TV",
    "monthYear": "2023-3"
}, {
    "AdGRP": "316.47430026560323",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "71.918700162561436",
    "Network": "Star Network Ent",
    "Channel_Name": "Asianet HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "977.22010138991755",
    "Network": "ZEEL",
    "Channel_Name": "Zee Cinemalu",
    "monthYear": "2023-3"
}, {
    "AdGRP": "337.89310084655881",
    "Network": "Sun",
    "Channel_Name": "Adithya TV",
    "monthYear": "2023-3"
}, {
    "AdGRP": "3.6308000079006888",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony PIX",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1053.2873004174326",
    "Network": "Viacom",
    "Channel_Name": "NICK(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "287.15890051650786",
    "Network": "Viacom",
    "Channel_Name": "Colors Kannada Cinema",
    "monthYear": "2023-3"
}, {
    "AdGRP": "44.600800100444758",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "733.42750110423367",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold 2",
    "monthYear": "2023-3"
}, {
    "AdGRP": "155.78370039345464",
    "Network": "Sun",
    "Channel_Name": "Gemini Music",
    "monthYear": "2023-3"
}, {
    "AdGRP": "55.945500146306586",
    "Network": "Sun",
    "Channel_Name": "Surya Music",
    "monthYear": "2023-3"
}, {
    "AdGRP": "216.45110056972044",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla Cinema",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.66970000782748684",
    "Network": "Star Network Ent",
    "Channel_Name": "Jalsha Movies HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.31560000339231919",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies Select HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1434.5932007273295",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Utsav Movies",
    "monthYear": "2023-3"
}, {
    "AdGRP": "275.44170055170252",
    "Network": "ZEEL",
    "Channel_Name": "Zee Classic",
    "monthYear": "2023-3"
}, {
    "AdGRP": "857.5246003625798",
    "Network": "Viacom",
    "Channel_Name": "Colors Marathi",
    "monthYear": "2023-3"
}, {
    "AdGRP": "1369.4576010964811",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television",
    "monthYear": "2023-3"
}, {
    "AdGRP": "182.95140042874846",
    "Network": "Star Network Ent",
    "Channel_Name": "Pravah Picture",
    "monthYear": "2023-3"
}, {
    "AdGRP": "107.21240024239523",
    "Network": "Sony Network Ent",
    "Channel_Name": "Sony Entertainment Television HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "4.3010000140202465",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Movies",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.5563000034308061",
    "Network": "ZEEL",
    "Channel_Name": "\u0026flix HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "3224.1320008209441",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Pravah",
    "monthYear": "2023-3"
}, {
    "AdGRP": "26.867400064598769",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa Movies HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.080700000740762334",
    "Network": "ZEEL",
    "Channel_Name": "\u0026Prive HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "51.1176001121712",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "95.402100197854452",
    "Network": "Sun",
    "Channel_Name": "Chintu TV",
    "monthYear": "2023-3"
}, {
    "AdGRP": "39.73770009996224",
    "Network": "Sony Network Ent",
    "Channel_Name": "SONY SAB HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.20490000274003251",
    "Network": "Viacom",
    "Channel_Name": "Colors Bangla HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "720.0952009553439",
    "Network": "Viacom",
    "Channel_Name": "Colors Cineplex",
    "monthYear": "2023-3"
}, {
    "AdGRP": "5222.2868971647695",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Maa",
    "monthYear": "2023-3"
}, {
    "AdGRP": "11.457800055621192",
    "Network": "Sun",
    "Channel_Name": "Surya TV HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "630.06290099611215",
    "Network": "ZEEL",
    "Channel_Name": "Zee Bollywood",
    "monthYear": "2023-3"
}, {
    "AdGRP": "671.55720082193147",
    "Network": "ZEEL",
    "Channel_Name": "\u0026pictures",
    "monthYear": "2023-3"
}, {
    "AdGRP": "243.76880011003232",
    "Network": "ZEEL",
    "Channel_Name": "Zee Chitramandir",
    "monthYear": "2023-3"
}, {
    "AdGRP": "2.0057000081942533",
    "Network": "ZEEL",
    "Channel_Name": "Zee Zest(v)",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.011900000165042002",
    "Network": "Viacom",
    "Channel_Name": "Colors Infinity HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.38410000146541279",
    "Network": "Viacom",
    "Channel_Name": "VH1 HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "418.24940080125816",
    "Network": "Sun",
    "Channel_Name": "Sun TV HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "97.568200218855054",
    "Network": "ZEEL",
    "Channel_Name": "Zee Telugu HD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.140699999712524",
    "Network": "Star Network Ent",
    "Channel_Name": "STAR Gold Select SD",
    "monthYear": "2023-3"
}, {
    "AdGRP": "0.20340000250871526",
    "Network": "Star Network Ent",
    "Channel_Name": "Pravah Picture HD",
    "monthYear": "2023-3"
}]

const filterOptionsForCompetetiveAdShareAnalysisPopUp = [
    {
        id: 'advertiser',
        label: 'Advertiser',
        isMendatory: false,
        // options: [
        //     'HUL',
        //     'Coca Cola',
        //     'Mondelez',
        // ],
        options: competetiveAdShareAnalysisSampleDataAdx.map(element => element.Advertiser_Name)
    },
    {
        id: 'network',
        label: 'Network',
        isMendatory: false,
        // options: [
        //     'ZEEL',
        //     'Sony',
        //     'Star',
        // ],
        options: competetiveAdShareAnalysisSampleDataAdx.map(element => element.Network).filter((item, i, ar) => ar.indexOf(item) === i)
    },
    {
        id: 'metric',
        label: 'Metric',
        isMendatory: false,
        options: [
            'AdGRP',
            // 'Revenue',
            // 'FCT',
        ],
        // options: competetiveAdShareAnalysisSampleDataAdx.map(element => 'A')
    },
]

const competetiveAdShareAnalysisSampleData = [
    {
        name: 'ZEE',
        value: [
            60, 70, 10, 43, 99, 34, 67, 55, 90, 32, 43, 45,
        ]
    },
    {
        name: 'Sony',
        value: [
            40, 70, 10, 43, 39, 78, 30, 55, 30, 67, 90, 34,
        ]
    },
    {
        name: 'Star',
        value: [
            60, 30, 20, 45, 97, 56, 40, 15, 80, 34, 90, 23,
        ]
    },
    {
        name: 'Viacom',
        value: [
            40, 70, 10, 43, 39, 78, 30, 55, 30, 67, 90, 34,
        ]
    },
    {
        name: 'Sun',
        value: [
            60, 70, 10, 43, 99, 34, 67, 55, 90, 32, 43, 45,
        ]
    },
]

const competetiveAdShareAnalysisSampleDataChanelWise = [
    {
        name: 'ZEE',
        data: [

            {
                name: 'Zee TV',
                value: [
                    60, 70, 10, 43, 99, 34, 67, 55, 90, 32, 43, 45,
                ]
            },
            {
                name: 'Big Magic',
                value: [
                    40, 70, 10, 43, 39, 78, 30, 55, 30, 67, 90, 34,
                ]
            },
            {
                name: 'Zee Telugu',
                value: [
                    60, 30, 20, 45, 97, 56, 40, 15, 80, 34, 90, 23,
                ]
            },
            {
                name: 'Zee Bangla',
                value: [
                    40, 70, 10, 43, 39, 78, 30, 55, 30, 67, 90, 34,
                ]
            },
            {
                name: 'Zee Anmol Cinema',
                value: [
                    60, 70, 10, 43, 99, 34, 67, 55, 90, 32, 43, 45,
                ]
            },

        ]
    },
    {
        name: 'Sony',
        data: [

            {
                name: 'Sony Sab',
                value: [
                    60, 70, 10, 43, 99, 34, 67, 55, 90, 32, 43, 45,
                ]
            },
            {
                name: 'Sony Wah',
                value: [
                    40, 70, 10, 43, 39, 78, 30, 55, 30, 67, 90, 34,
                ]
            },
            {
                name: 'Sony Pal',
                value: [
                    60, 30, 20, 45, 97, 56, 40, 15, 80, 34, 90, 23,
                ]
            },
            {
                name: 'Sony Entertainment',
                value: [
                    40, 70, 10, 43, 39, 78, 30, 55, 30, 67, 90, 34,
                ]
            },
            {
                name: 'Sony MAX(v)',
                value: [
                    60, 70, 10, 43, 99, 34, 67, 55, 90, 32, 43, 45,
                ]
            },

        ]
    }
]

const competitiveIntellingenceLinechartData = [
    {
        network: 'Zee-Previous',
        isPrevious: true,
        value: 80,
        yearMonth: "2022-04",
    },
    {
        network: 'Zee-Previous',
        isPrevious: true,
        value: 73,
        yearMonth: "2022-05",
    },
    {
        network: 'Zee-Previous',
        isPrevious: true,
        value: 76,
        yearMonth: "2022-06",
    },
    {
        network: 'Zee-Previous',
        isPrevious: true,
        value: 70,
        yearMonth: "2022-07",
    },
    {
        network: 'Zee-Previous',
        isPrevious: true,
        value: 65,
        yearMonth: "2022-08",
    },
    {
        network: 'Zee-Previous',
        isPrevious: true,
        value: 77,
        yearMonth: "2022-09",
    },
    {
        network: 'Zee-Previous',
        isPrevious: true,
        value: 80,
        yearMonth: "2022-10",
    },
    {
        network: 'Zee-Previous',
        isPrevious: true,
        value: 72,
        yearMonth: "2022-11",
    },
    {
        network: 'Zee-Previous',
        isPrevious: true,
        value: 75,
        yearMonth: "2022-12",
    },
    {
        network: 'Zee-Previous',
        isPrevious: true,
        value: 77,
        yearMonth: "2023-01",
    },
    {
        network: 'Zee-Previous',
        isPrevious: true,
        value: 65,
        yearMonth: "2023-02",
    },
    {
        network: 'Zee-Previous',
        isPrevious: true,
        value: 78,
        yearMonth: "2023-03",
    },
    {
        network: 'Zee',
        isPrevious: false,
        value: 75,
        yearMonth: "2022-04",
    },
    {
        network: 'Zee',
        isPrevious: false,
        value: 65,
        yearMonth: "2022-05",
    },
    {
        network: 'Zee',
        isPrevious: false,
        value: 77,
        yearMonth: "2022-06",
    },
    {
        network: 'Zee',
        isPrevious: false,
        value: 69,
        yearMonth: "2022-07",
    },
    {
        network: 'Zee',
        isPrevious: false,
        value: 70,
        yearMonth: "2022-08",
    },
    {
        network: 'Zee',
        isPrevious: false,
        value: 63,
        yearMonth: "2022-09",
    },
    {
        network: 'Zee',
        isPrevious: false,
        value: 67,
        yearMonth: "2022-10",
    },
    {
        network: 'Zee',
        isPrevious: false,
        value: 72,
        yearMonth: "2022-11",
    },
    {
        network: 'Zee',
        isPrevious: false,
        value: 79,
        yearMonth: "2022-12",
    },
    {
        network: 'Zee',
        isPrevious: false,
        value: 73,
        yearMonth: "2023-01",
    },
    {
        network: 'Zee',
        isPrevious: false,
        value: 70,
        yearMonth: "2023-02",
    },
    {
        network: 'Zee',
        isPrevious: false,
        value: 66,
        yearMonth: "2023-03",
    },


    {
        network: 'Star-Previous',
        value: 60,
        isPrevious: true,
        yearMonth: "2022-04",
    },
    {
        network: 'Star-Previous',
        value: 53,
        isPrevious: true,
        yearMonth: "2022-05",
    },
    {
        network: 'Star-Previous',
        value: 66,
        isPrevious: true,
        yearMonth: "2022-06",
    },
    {
        network: 'Star-Previous',
        value: 60,
        isPrevious: true,
        yearMonth: "2022-07",
    },
    {
        network: 'Star-Previous',
        value: 65,
        isPrevious: true,
        yearMonth: "2022-08",
    },
    {
        network: 'Star-Previous',
        value: 57,
        isPrevious: true,
        yearMonth: "2022-09",
    },
    {
        network: 'Star-Previous',
        value: 60,
        isPrevious: true,
        yearMonth: "2022-10",
    },
    {
        network: 'Star-Previous',
        value: 59,
        isPrevious: true,
        yearMonth: "2022-11",
    },
    {
        network: 'Star-Previous',
        value: 65,
        isPrevious: true,
        yearMonth: "2022-12",
    },
    {
        network: 'Star-Previous',
        value: 75,
        isPrevious: true,
        yearMonth: "2023-01",
    },
    {
        network: 'Star-Previous',
        value: 60,
        isPrevious: true,
        yearMonth: "2023-02",
    },
    {
        network: 'Star-Previous',
        value: 55,
        isPrevious: true,
        yearMonth: "2023-03",
    },
    {
        network: 'Star',
        value: 57,
        yearMonth: "2022-04",
    },
    {
        network: 'Star',
        value: 67,
        yearMonth: "2022-05",
    },
    {
        network: 'Star',
        value: 59,
        yearMonth: "2022-06",
    },
    {
        network: 'Star',
        value: 63,
        yearMonth: "2022-07",
    },
    {
        network: 'Star',
        value: 60,
        yearMonth: "2022-08",
    },
    {
        network: 'Star',
        value: 70,
        yearMonth: "2022-09",
    },
    {
        network: 'Star',
        value: 72,
        yearMonth: "2022-10",
    },
    {
        network: 'Star',
        value: 77,
        yearMonth: "2022-11",
    },
    {
        network: 'Star',
        value: 60,
        yearMonth: "2022-12",
    },
    {
        network: 'Star',
        value: 65,
        yearMonth: "2023-01",
    },
    {
        network: 'Star',
        value: 57,
        yearMonth: "2023-02",
    },
    {
        network: 'Star',
        value: 55,
        yearMonth: "2023-03",
    },


    // {
    //     network: 'Viacom',
    //     value: 50,
    //     yearMonth: "2022-04",
    // },
    // {
    //     network: 'Viacom',
    //     value: 45,
    //     yearMonth: "2022-05",
    // },
    // {
    //     network: 'Viacom',
    //     value: 40,
    //     yearMonth: "2022-06",
    // },
    // {
    //     network: 'Viacom',
    //     value: 42,
    //     yearMonth: "2022-07",
    // },
    // {
    //     network: 'Viacom',
    //     value: 44,
    //     yearMonth: "2022-08",
    // },
    // {
    //     network: 'Viacom',
    //     value: 47,
    //     yearMonth: "2022-09",
    // },
    // {
    //     network: 'Viacom',
    //     value: 40,
    //     yearMonth: "2022-10",
    // },
    // {
    //     network: 'Viacom',
    //     value: 59,
    //     yearMonth: "2022-11",
    // },
    // {
    //     network: 'Viacom',
    //     value: 53,
    //     yearMonth: "2022-12",
    // },
    // {
    //     network: 'Viacom',
    //     value: 52,
    //     yearMonth: "2023-01",
    // },
    // {
    //     network: 'Viacom',
    //     value: 43,
    //     yearMonth: "2023-02",
    // },
    // {
    //     network: 'Viacom',
    //     value: 44,
    //     yearMonth: "2023-03",
    // },
    {
        network: 'Viacom',
        value: 45,
        yearMonth: "2022-04",
    },
    {
        network: 'Viacom',
        value: 54,
        yearMonth: "2022-05",
    },
    {
        network: 'Viacom',
        value: 48,
        yearMonth: "2022-06",
    },
    {
        network: 'Viacom',
        value: 49,
        yearMonth: "2022-07",
    },
    {
        network: 'Viacom',
        value: 52,
        yearMonth: "2022-08",
    },
    {
        network: 'Viacom',
        value: 55,
        yearMonth: "2022-09",
    },
    {
        network: 'Viacom',
        value: 57,
        yearMonth: "2022-10",
    },
    {
        network: 'Viacom',
        value: 52,
        yearMonth: "2022-11",
    },
    {
        network: 'Viacom',
        value: 50,
        yearMonth: "2022-12",
    },
    {
        network: 'Viacom',
        value: 45,
        yearMonth: "2023-01",
    },
    {
        network: 'Viacom',
        value: 43,
        yearMonth: "2023-02",
    },
    {
        network: 'Viacom',
        value: 50,
        yearMonth: "2023-03",
    },


    // {
    //     network: 'Sony',
    //     value: 45,
    //     yearMonth: "2022-04",
    // },
    // {
    //     network: 'Sony',
    //     value: 30,
    //     yearMonth: "2022-05",
    // },
    // {
    //     network: 'Sony',
    //     value: 32,
    //     yearMonth: "2022-06",
    // },
    // {
    //     network: 'Sony',
    //     value: 33,
    //     yearMonth: "2022-07",
    // },
    // {
    //     network: 'Sony',
    //     value: 40,
    //     yearMonth: "2022-08",
    // },
    // {
    //     network: 'Sony',
    //     value: 37,
    //     yearMonth: "2022-09",
    // },
    // {
    //     network: 'Sony',
    //     value: 45,
    //     yearMonth: "2022-10",
    // },
    // {
    //     network: 'Sony',
    //     value: 30,
    //     yearMonth: "2022-11",
    // },
    // {
    //     network: 'Sony',
    //     value: 27,
    //     yearMonth: "2022-12",
    // },
    // {
    //     network: 'Sony',
    //     value: 37,
    //     yearMonth: "2023-01",
    // },
    // {
    //     network: 'Sony',
    //     value: 36,
    //     yearMonth: "2023-02",
    // },
    // {
    //     network: 'Sony',
    //     value: 26,
    //     yearMonth: "2023-03",
    // },
    {
        network: 'Sony',
        value: 25,
        yearMonth: "2022-04",
    },
    {
        network: 'Sony',
        value: 31,
        yearMonth: "2022-05",
    },
    {
        network: 'Sony',
        value: 33,
        yearMonth: "2022-06",
    },
    {
        network: 'Sony',
        value: 30,
        yearMonth: "2022-07",
    },
    {
        network: 'Sony',
        value: 34,
        yearMonth: "2022-08",
    },
    {
        network: 'Sony',
        value: 37,
        yearMonth: "2022-09",
    },
    {
        network: 'Sony',
        value: 30,
        yearMonth: "2022-10",
    },
    {
        network: 'Sony',
        value: 27,
        yearMonth: "2022-11",
    },
    {
        network: 'Sony',
        value: 36,
        yearMonth: "2022-12",
    },
    {
        network: 'Sony',
        value: 33,
        yearMonth: "2023-01",
    },
    {
        network: 'Sony',
        value: 29,
        yearMonth: "2023-02",
    },
    {
        network: 'Sony',
        value: 40,
        yearMonth: "2023-03",
    },



    // {
    //     network: 'Sun',
    //     value: 15,
    //     yearMonth: "2022-04",
    // },
    // {
    //     network: 'Sun',
    //     value: 20,
    //     yearMonth: "2022-05",
    // },
    // {
    //     network: 'Sun',
    //     value: 17,
    //     yearMonth: "2022-06",
    // },
    // {
    //     network: 'Sun',
    //     value: 16,
    //     yearMonth: "2022-07",
    // },
    // {
    //     network: 'Sun',
    //     value: 13,
    //     yearMonth: "2022-08",
    // },
    // {
    //     network: 'Sun',
    //     value: 9,
    //     yearMonth: "2022-09",
    // },
    // {
    //     network: 'Sun',
    //     value: 13,
    //     yearMonth: "2022-10",
    // },
    // {
    //     network: 'Sun',
    //     value: 19,
    //     yearMonth: "2022-11",
    // },
    // {
    //     network: 'Sun',
    //     value: 18,
    //     yearMonth: "2022-12",
    // },
    // {
    //     network: 'Sun',
    //     value: 15,
    //     yearMonth: "2023-01",
    // },
    // {
    //     network: 'Sun',
    //     value: 13,
    //     yearMonth: "2023-02",
    // },
    // {
    //     network: 'Sun',
    //     value: 19,
    //     yearMonth: "2023-03",
    // },
    {
        network: 'Sun',
        value: 20,
        yearMonth: "2022-04",
    },
    {
        network: 'Sun',
        value: 7,
        yearMonth: "2022-05",
    },
    {
        network: 'Sun',
        value: 17,
        yearMonth: "2022-06",
    },
    {
        network: 'Sun',
        value: 13,
        yearMonth: "2022-07",
    },
    {
        network: 'Sun',
        value: 12,
        yearMonth: "2022-08",
    },
    {
        network: 'Sun',
        value: 10,
        yearMonth: "2022-09",
    },
    {
        network: 'Sun',
        value: 11,
        yearMonth: "2022-10",
    },
    {
        network: 'Sun',
        value: 9,
        yearMonth: "2022-11",
    },
    {
        network: 'Sun',
        value: 8,
        yearMonth: "2022-12",
    },
    {
        network: 'Sun',
        value: 15,
        yearMonth: "2023-01",
    },
    {
        network: 'Sun',
        value: 16,
        yearMonth: "2023-02",
    },
    {
        network: 'Sun',
        value: 18,
        yearMonth: "2023-03",
    },
]

const competitiveIntelligenceAgGRP = [
    {
        "value": "11169.521205461555",
        "Network": "Sony Network Ent",
        "year": "2023",
        "month": "2"
    },
    {
        "value": "33047.718120290578",
        "Network": "ZEEL",
        "year": "2023",
        "month": "1"
    },
    {
        "value": "42064.9961186197",
        "Network": "Star Network Ent",
        "year": "2023",
        "month": "3"
    },
    {
        "value": "35336.787015112786",
        "Network": "Star Network Ent",
        "year": "2023",
        "month": "2"
    },
    {
        "value": "12126.0432081279",
        "Network": "Sony Network Ent",
        "year": "2023",
        "month": "1"
    },
    {
        "value": "13976.480206815162",
        "Network": "Sony Network Ent",
        "year": "2023",
        "month": "3"
    },
    {
        "value": "38095.388715875575",
        "Network": "Star Network Ent",
        "year": "2023",
        "month": "1"
    },
    {
        "value": "36078.576725834675",
        "Network": "ZEEL",
        "year": "2023",
        "month": "3"
    },
    {
        "value": "16213.448811526876",
        "Network": "Viacom",
        "year": "2023",
        "month": "2"
    },
    {
        "value": "19641.6745138947",
        "Network": "Sun",
        "year": "2023",
        "month": "1"
    },
    {
        "value": "18535.099320125759",
        "Network": "Sun",
        "year": "2023",
        "month": "2"
    },
    {
        "value": "29879.965022271819",
        "Network": "ZEEL",
        "year": "2023",
        "month": "2"
    },
    {
        "value": "19808.766315617548",
        "Network": "Sun",
        "year": "2023",
        "month": "3"
    },
    {
        "value": "16919.794214515692",
        "Network": "Viacom",
        "year": "2023",
        "month": "3"
    },
    {
        "value": "14966.92391372189",
        "Network": "Viacom",
        "year": "2023",
        "month": "1"
    },
    {
        "value": "17043.353311777442",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "11"
    },
    {
        "value": "12232.306020932614",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "5"
    },
    {
        "value": "36570.716053657234",
        "Network": "ZEEL",
        "year": "2022",
        "month": "2"
    },
    {
        "value": "42459.622828518972",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "12"
    },
    {
        "value": "36817.845527834863",
        "Network": "ZEEL",
        "year": "2022",
        "month": "9"
    },
    {
        "value": "20318.79203651601",
        "Network": "Sun",
        "year": "2022",
        "month": "4"
    },
    {
        "value": "36282.70232635604",
        "Network": "ZEEL",
        "year": "2022",
        "month": "8"
    },
    {
        "value": "33493.062153248495",
        "Network": "ZEEL",
        "year": "2022",
        "month": "5"
    },
    {
        "value": "45253.844036826151",
        "Network": "ZEEL",
        "year": "2022",
        "month": "10"
    },
    {
        "value": "39965.803319348022",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "9"
    },
    {
        "value": "14888.131035575178",
        "Network": "Viacom",
        "year": "2022",
        "month": "4"
    },
    {
        "value": "46601.829421388364",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "10"
    },
    {
        "value": "16015.530029091286",
        "Network": "Viacom",
        "year": "2022",
        "month": "2"
    },
    {
        "value": "38778.425749450609",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "5"
    },
    {
        "value": "46430.774039575714",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "3"
    },
    {
        "value": "38580.564725403827",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "6"
    },
    {
        "value": "14680.755017924705",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "3"
    },
    {
        "value": "41857.439721508585",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "8"
    },
    {
        "value": "36512.591046145419",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "4"
    },
    {
        "value": "41878.503043741221",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "2"
    },
    {
        "value": "19684.217315664479",
        "Network": "Sun",
        "year": "2022",
        "month": "6"
    },
    {
        "value": "47045.929435188613",
        "Network": "ZEEL",
        "year": "2022",
        "month": "11"
    },
    {
        "value": "16043.634614810369",
        "Network": "Viacom",
        "year": "2022",
        "month": "9"
    },
    {
        "value": "13628.860017713159",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "2"
    },
    {
        "value": "15914.285712320619",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "10"
    },
    {
        "value": "12479.049410724147",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "12"
    },
    {
        "value": "37871.956051973859",
        "Network": "ZEEL",
        "year": "2022",
        "month": "3"
    },
    {
        "value": "21557.503721715795",
        "Network": "Viacom",
        "year": "2022",
        "month": "10"
    },
    {
        "value": "43271.9490572646",
        "Network": "ZEEL",
        "year": "2022",
        "month": "1"
    },
    {
        "value": "16142.028315934214",
        "Network": "Viacom",
        "year": "2022",
        "month": "12"
    },
    {
        "value": "33652.104528273929",
        "Network": "ZEEL",
        "year": "2022",
        "month": "6"
    },
    {
        "value": "28086.123326644491",
        "Network": "Sun",
        "year": "2022",
        "month": "10"
    },
    {
        "value": "16338.530717171343",
        "Network": "Viacom",
        "year": "2022",
        "month": "7"
    },
    {
        "value": "20601.547220970067",
        "Network": "Sun",
        "year": "2022",
        "month": "8"
    },
    {
        "value": "15196.920014819676",
        "Network": "Viacom",
        "year": "2022",
        "month": "6"
    },
    {
        "value": "18781.567035095766",
        "Network": "Viacom",
        "year": "2022",
        "month": "1"
    },
    {
        "value": "18673.602035972872",
        "Network": "Sun",
        "year": "2022",
        "month": "2"
    },
    {
        "value": "40850.74702442839",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "7"
    },
    {
        "value": "19385.872519476055",
        "Network": "Sun",
        "year": "2022",
        "month": "12"
    },
    {
        "value": "15570.204035474671",
        "Network": "Viacom",
        "year": "2022",
        "month": "5"
    },
    {
        "value": "16914.956916024305",
        "Network": "Viacom",
        "year": "2022",
        "month": "8"
    },
    {
        "value": "29891.318053122028",
        "Network": "ZEEL",
        "year": "2022",
        "month": "4"
    },
    {
        "value": "21511.774135629385",
        "Network": "Sun",
        "year": "2022",
        "month": "5"
    },
    {
        "value": "49177.418028943415",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "11"
    },
    {
        "value": "13274.342610363019",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "9"
    },
    {
        "value": "27031.712725981197",
        "Network": "Sun",
        "year": "2022",
        "month": "11"
    },
    {
        "value": "20875.851033215993",
        "Network": "Sun",
        "year": "2022",
        "month": "3"
    },
    {
        "value": "12737.392711547924",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "7"
    },
    {
        "value": "21844.475515261613",
        "Network": "Viacom",
        "year": "2022",
        "month": "11"
    },
    {
        "value": "13151.018810491485",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "8"
    },
    {
        "value": "21108.957713918069",
        "Network": "Sun",
        "year": "2022",
        "month": "9"
    },
    {
        "value": "34527.664222855681",
        "Network": "ZEEL",
        "year": "2022",
        "month": "12"
    },
    {
        "value": "19617.169015404848",
        "Network": "Sun",
        "year": "2022",
        "month": "7"
    },
    {
        "value": "18174.564037188888",
        "Network": "Viacom",
        "year": "2022",
        "month": "3"
    },
    {
        "value": "16653.424021456158",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "1"
    },
    {
        "value": "11443.551018036087",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "4"
    },
    {
        "value": "49731.531051623169",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "1"
    },
    {
        "value": "35911.114026090647",
        "Network": "ZEEL",
        "year": "2022",
        "month": "7"
    },
    {
        "value": "12258.428211298422",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "6"
    },
    {
        "value": "22581.290038092993",
        "Network": "Sun",
        "year": "2022",
        "month": "1"
    },
    {
        "value": "14228.711025128025",
        "Network": "Sun",
        "year": "2021",
        "month": "4"
    },
    {
        "value": "18864.001028177794",
        "Network": "Sun",
        "year": "2021",
        "month": "6"
    },
    {
        "value": "25762.966025519068",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "7"
    },
    {
        "value": "20447.600041462923",
        "Network": "Viacom",
        "year": "2021",
        "month": "9"
    },
    {
        "value": "9815.2400092672324",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "7"
    },
    {
        "value": "47561.945041966159",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "9"
    },
    {
        "value": "39110.234057713882",
        "Network": "ZEEL",
        "year": "2021",
        "month": "9"
    },
    {
        "value": "50586.474051422789",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "10"
    },
    {
        "value": "31219.878033550456",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "4"
    },
    {
        "value": "18998.675021798117",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "8"
    },
    {
        "value": "11391.743024897645",
        "Network": "Viacom",
        "year": "2021",
        "month": "4"
    },
    {
        "value": "16490.19502103026",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "6"
    },
    {
        "value": "50058.738041570527",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "12"
    },
    {
        "value": "10922.728018260794",
        "Network": "Viacom",
        "year": "2021",
        "month": "7"
    },
    {
        "value": "33581.528056435054",
        "Network": "ZEEL",
        "year": "2021",
        "month": "6"
    },
    {
        "value": "16691.71102060203",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "11"
    },
    {
        "value": "22233.819037269917",
        "Network": "Sun",
        "year": "2021",
        "month": "9"
    },
    {
        "value": "24493.106042680331",
        "Network": "Sun",
        "year": "2021",
        "month": "10"
    },
    {
        "value": "19649.255036706571",
        "Network": "Viacom",
        "year": "2021",
        "month": "12"
    },
    {
        "value": "40270.216033513541",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "6"
    },
    {
        "value": "19358.073030626867",
        "Network": "ZEEL",
        "year": "2021",
        "month": "7"
    },
    {
        "value": "23061.372042052797",
        "Network": "Sun",
        "year": "2021",
        "month": "11"
    },
    {
        "value": "38101.1000576067",
        "Network": "ZEEL",
        "year": "2021",
        "month": "8"
    },
    {
        "value": "38266.928051924333",
        "Network": "ZEEL",
        "year": "2021",
        "month": "5"
    },
    {
        "value": "17840.547031296766",
        "Network": "Viacom",
        "year": "2021",
        "month": "6"
    },
    {
        "value": "20926.856038697297",
        "Network": "Viacom",
        "year": "2021",
        "month": "8"
    },
    {
        "value": "24174.140039032209",
        "Network": "ZEEL",
        "year": "2021",
        "month": "4"
    },
    {
        "value": "22634.573036932037",
        "Network": "Sun",
        "year": "2021",
        "month": "8"
    },
    {
        "value": "20325.99603238795",
        "Network": "Sun",
        "year": "2021",
        "month": "12"
    },
    {
        "value": "49320.25805257028",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "8"
    },
    {
        "value": "17135.983035290614",
        "Network": "Viacom",
        "year": "2021",
        "month": "5"
    },
    {
        "value": "16558.775016827392",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "12"
    },
    {
        "value": "17202.709017978515",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "9"
    },
    {
        "value": "17696.215020394651",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "5"
    },
    {
        "value": "47299.309051356162",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "11"
    },
    {
        "value": "42224.395070416154",
        "Network": "ZEEL",
        "year": "2021",
        "month": "10"
    },
    {
        "value": "12082.248015796882",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "4"
    },
    {
        "value": "18783.608033081633",
        "Network": "Viacom",
        "year": "2021",
        "month": "11"
    },
    {
        "value": "18127.359023031429",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "10"
    },
    {
        "value": "40084.891060078517",
        "Network": "ZEEL",
        "year": "2021",
        "month": "11"
    },
    {
        "value": "19477.356031361734",
        "Network": "Sun",
        "year": "2021",
        "month": "5"
    },
    {
        "value": "11307.902020367328",
        "Network": "Sun",
        "year": "2021",
        "month": "7"
    },
    {
        "value": "40706.350038291072",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "5"
    },
    {
        "value": "42267.84306605428",
        "Network": "ZEEL",
        "year": "2021",
        "month": "12"
    },
    {
        "value": "20545.850043613929",
        "Network": "Viacom",
        "year": "2021",
        "month": "10"
    }
]

const advertisersForCompetitiveIntelligence = [
    {
        "value": "346",
        "Network": "Sun",
        "year": "2023",
        "month": "3"
    }, {
        "value": "342",
        "Network": "Viacom",
        "year": "2023",
        "month": "3"
    }, {
        "value": "296",
        "Network": "Viacom",
        "year": "2023",
        "month": "2"
    }, {
        "value": "421",
        "Network": "ZEEL",
        "year": "2023",
        "month": "1"
    }, {
        "value": "407",
        "Network": "ZEEL",
        "year": "2023",
        "month": "2"
    }, {
        "value": "219",
        "Network": "Sony Network Ent",
        "year": "2023",
        "month": "2"
    }, {
        "value": "204",
        "Network": "Sony Network Ent",
        "year": "2023",
        "month": "1"
    }, {
        "value": "324",
        "Network": "Sun",
        "year": "2023",
        "month": "2"
    }, {
        "value": "362",
        "Network": "Sun",
        "year": "2023",
        "month": "1"
    }, {
        "value": "303",
        "Network": "Viacom",
        "year": "2023",
        "month": "1"
    }, {
        "value": "478",
        "Network": "Star Network Ent",
        "year": "2023",
        "month": "3"
    }, {
        "value": "463",
        "Network": "ZEEL",
        "year": "2023",
        "month": "3"
    }, {
        "value": "485",
        "Network": "Star Network Ent",
        "year": "2023",
        "month": "1"
    }, {
        "value": "239",
        "Network": "Sony Network Ent",
        "year": "2023",
        "month": "3"
    }, {
        "value": "423",
        "Network": "Star Network Ent",
        "year": "2023",
        "month": "2"
    }, {
        "value": "443",
        "Network": "ZEEL",
        "year": "2022",
        "month": "6"
    }, {
        "value": "382",
        "Network": "Sun",
        "year": "2022",
        "month": "5"
    }, {
        "value": "263",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "3"
    }, {
        "value": "558",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "4"
    }, {
        "value": "511",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "8"
    }, {
        "value": "317",
        "Network": "Viacom",
        "year": "2022",
        "month": "8"
    }, {
        "value": "493",
        "Network": "ZEEL",
        "year": "2022",
        "month": "8"
    }, {
        "value": "338",
        "Network": "Viacom",
        "year": "2022",
        "month": "12"
    }, {
        "value": "510",
        "Network": "ZEEL",
        "year": "2022",
        "month": "9"
    }, {
        "value": "325",
        "Network": "Viacom",
        "year": "2022",
        "month": "11"
    }, {
        "value": "439",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "11"
    }, {
        "value": "477",
        "Network": "ZEEL",
        "year": "2022",
        "month": "4"
    }, {
        "value": "256",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "4"
    }, {
        "value": "336",
        "Network": "Sun",
        "year": "2022",
        "month": "2"
    }, {
        "value": "474",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "2"
    }, {
        "value": "345",
        "Network": "Sun",
        "year": "2022",
        "month": "6"
    }, {
        "value": "230",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "6"
    }, {
        "value": "453",
        "Network": "ZEEL",
        "year": "2022",
        "month": "7"
    }, {
        "value": "470",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "7"
    }, {
        "value": "358",
        "Network": "Viacom",
        "year": "2022",
        "month": "3"
    }, {
        "value": "255",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "1"
    }, {
        "value": "217",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "12"
    }, {
        "value": "408",
        "Network": "Sun",
        "year": "2022",
        "month": "10"
    }, {
        "value": "222",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "11"
    }, {
        "value": "405",
        "Network": "ZEEL",
        "year": "2022",
        "month": "11"
    }, {
        "value": "316",
        "Network": "Viacom",
        "year": "2022",
        "month": "2"
    }, {
        "value": "564",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "5"
    }, {
        "value": "472",
        "Network": "ZEEL",
        "year": "2022",
        "month": "3"
    }, {
        "value": "338",
        "Network": "Sun",
        "year": "2022",
        "month": "7"
    }, {
        "value": "389",
        "Network": "Sun",
        "year": "2022",
        "month": "4"
    }, {
        "value": "461",
        "Network": "ZEEL",
        "year": "2022",
        "month": "1"
    }, {
        "value": "370",
        "Network": "Viacom",
        "year": "2022",
        "month": "10"
    }, {
        "value": "252",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "9"
    }, {
        "value": "300",
        "Network": "Sun",
        "year": "2022",
        "month": "11"
    }, {
        "value": "248",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "5"
    }, {
        "value": "511",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "1"
    }, {
        "value": "440",
        "Network": "ZEEL",
        "year": "2022",
        "month": "12"
    }, {
        "value": "527",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "9"
    }, {
        "value": "356",
        "Network": "Viacom",
        "year": "2022",
        "month": "9"
    }, {
        "value": "405",
        "Network": "Sun",
        "year": "2022",
        "month": "9"
    }, {
        "value": "330",
        "Network": "Viacom",
        "year": "2022",
        "month": "6"
    }, {
        "value": "313",
        "Network": "Viacom",
        "year": "2022",
        "month": "1"
    }, {
        "value": "367",
        "Network": "Sun",
        "year": "2022",
        "month": "1"
    }, {
        "value": "472",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "12"
    }, {
        "value": "524",
        "Network": "ZEEL",
        "year": "2022",
        "month": "10"
    }, {
        "value": "260",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "2"
    }, {
        "value": "445",
        "Network": "ZEEL",
        "year": "2022",
        "month": "2"
    }, {
        "value": "476",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "6"
    }, {
        "value": "340",
        "Network": "Viacom",
        "year": "2022",
        "month": "5"
    }, {
        "value": "210",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "7"
    }, {
        "value": "527",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "3"
    }, {
        "value": "262",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "10"
    }, {
        "value": "478",
        "Network": "ZEEL",
        "year": "2022",
        "month": "5"
    }, {
        "value": "330",
        "Network": "Viacom",
        "year": "2022",
        "month": "7"
    }, {
        "value": "350",
        "Network": "Sun",
        "year": "2022",
        "month": "3"
    }, {
        "value": "348",
        "Network": "Viacom",
        "year": "2022",
        "month": "4"
    }, {
        "value": "222",
        "Network": "Sony Network Ent",
        "year": "2022",
        "month": "8"
    }, {
        "value": "393",
        "Network": "Sun",
        "year": "2022",
        "month": "8"
    }, {
        "value": "332",
        "Network": "Sun",
        "year": "2022",
        "month": "12"
    }, {
        "value": "558",
        "Network": "Star Network Ent",
        "year": "2022",
        "month": "10"
    }, {
        "value": "304",
        "Network": "ZEEL",
        "year": "2021",
        "month": "5"
    }, {
        "value": "391",
        "Network": "Sun",
        "year": "2021",
        "month": "9"
    }, {
        "value": "279",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "9"
    }, {
        "value": "277",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "8"
    }, {
        "value": "615",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "10"
    }, {
        "value": "255",
        "Network": "Sun",
        "year": "2021",
        "month": "5"
    }, {
        "value": "340",
        "Network": "Viacom",
        "year": "2021",
        "month": "9"
    }, {
        "value": "328",
        "Network": "Viacom",
        "year": "2021",
        "month": "12"
    }, {
        "value": "443",
        "Network": "Sun",
        "year": "2021",
        "month": "10"
    }, {
        "value": "389",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "7"
    }, {
        "value": "509",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "8"
    }, {
        "value": "325",
        "Network": "Sun",
        "year": "2021",
        "month": "4"
    }, {
        "value": "468",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "4"
    }, {
        "value": "370",
        "Network": "Sun",
        "year": "2021",
        "month": "12"
    }, {
        "value": "528",
        "Network": "ZEEL",
        "year": "2021",
        "month": "11"
    }, {
        "value": "392",
        "Network": "Sun",
        "year": "2021",
        "month": "8"
    }, {
        "value": "401",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "5"
    }, {
        "value": "286",
        "Network": "Sun",
        "year": "2021",
        "month": "7"
    }, {
        "value": "456",
        "Network": "ZEEL",
        "year": "2021",
        "month": "12"
    }, {
        "value": "278",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "12"
    }, {
        "value": "462",
        "Network": "ZEEL",
        "year": "2021",
        "month": "8"
    }, {
        "value": "310",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "10"
    }, {
        "value": "289",
        "Network": "ZEEL",
        "year": "2021",
        "month": "6"
    }, {
        "value": "187",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "6"
    }, {
        "value": "274",
        "Network": "Viacom",
        "year": "2021",
        "month": "7"
    }, {
        "value": "209",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "4"
    }, {
        "value": "527",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "9"
    }, {
        "value": "496",
        "Network": "ZEEL",
        "year": "2021",
        "month": "9"
    }, {
        "value": "558",
        "Network": "ZEEL",
        "year": "2021",
        "month": "10"
    }, {
        "value": "191",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "5"
    }, {
        "value": "365",
        "Network": "ZEEL",
        "year": "2021",
        "month": "7"
    }, {
        "value": "518",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "12"
    }, {
        "value": "432",
        "Network": "Sun",
        "year": "2021",
        "month": "11"
    }, {
        "value": "549",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "11"
    }, {
        "value": "288",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "11"
    }, {
        "value": "399",
        "Network": "Viacom",
        "year": "2021",
        "month": "10"
    }, {
        "value": "218",
        "Network": "Viacom",
        "year": "2021",
        "month": "6"
    }, {
        "value": "232",
        "Network": "Viacom",
        "year": "2021",
        "month": "5"
    }, {
        "value": "226",
        "Network": "Sony Network Ent",
        "year": "2021",
        "month": "7"
    }, {
        "value": "378",
        "Network": "ZEEL",
        "year": "2021",
        "month": "4"
    }, {
        "value": "259",
        "Network": "Viacom",
        "year": "2021",
        "month": "4"
    }, {
        "value": "220",
        "Network": "Sun",
        "year": "2021",
        "month": "6"
    }, {
        "value": "316",
        "Network": "Star Network Ent",
        "year": "2021",
        "month": "6"
    }, {
        "value": "364",
        "Network": "Viacom",
        "year": "2021",
        "month": "11"
    }, {
        "value": "335",
        "Network": "Viacom",
        "year": "2021",
        "month": "8"
    }]

const horizontalBarchartAdGRPData =
{
    "Regions": {
        "ytdCurrentYear": [
            {
                "AdGRP": 2305924.3171268892,
                "Regions": "India"
            }
        ],
        "ytdPreviousYear": [
            {
                "AdGRP": 2160114.6210225043,
                "Regions": "India"
            }
        ],
        "mtdCurrentMonth": [
            {
                "AdGRP": 109341.44168900304,
                "Regions": "India"
            }
        ],
        "mtdPreviousMonth": [
            {
                "AdGRP": 173718.96335270823,
                "Regions": "India"
            }
        ]
    },
    "Channel_Genre": {
        "ytdCurrentYear": [
            {
                "AdGRP": 277745.4891961325,
                "Channel_Genre": "Hindi GEC - Paid"
            },
            {
                "AdGRP": 245506.0490310317,
                "Channel_Genre": null
            },
            {
                "AdGRP": 212345.5535035999,
                "Channel_Genre": "Hindi Movies - FTA"
            },
            {
                "AdGRP": 204987.66559462965,
                "Channel_Genre": "Telugu GEC"
            },
            {
                "AdGRP": 200458.64011011587,
                "Channel_Genre": "Tamil GEC"
            },
            {
                "AdGRP": 173568.60893512098,
                "Channel_Genre": "Hindi GEC - FTA"
            },
            {
                "AdGRP": 114496.5963710414,
                "Channel_Genre": "Kannada GEC"
            },
            {
                "AdGRP": 89473.14096323028,
                "Channel_Genre": "Bangla GEC"
            },
            {
                "AdGRP": 82009.17487043503,
                "Channel_Genre": "null"
            },
            {
                "AdGRP": 59934.517120074066,
                "Channel_Genre": "Bhojpuri GEC"
            },
            {
                "AdGRP": 59330.00841624598,
                "Channel_Genre": "Kids"
            },
            {
                "AdGRP": 53891.10917574907,
                "Channel_Genre": "Malayalam GEC"
            },
            {
                "AdGRP": 49111.51051463099,
                "Channel_Genre": "Sports"
            },
            {
                "AdGRP": 46391.45956831187,
                "Channel_Genre": "Hindi Movies - Paid"
            },
            {
                "AdGRP": 46204.16795013184,
                "Channel_Genre": "Marathi GEC"
            },
            {
                "AdGRP": 38271.30005887891,
                "Channel_Genre": "Tamil Movies"
            },
            {
                "AdGRP": 38171.45595045634,
                "Channel_Genre": "Telugu Movies"
            },
            {
                "AdGRP": 35344.40918315662,
                "Channel_Genre": "Music"
            },
            {
                "AdGRP": 33691.65956776831,
                "Channel_Genre": "Kannada Movies"
            },
            {
                "AdGRP": 33060.5082730974,
                "Channel_Genre": "Hindi Movies 2"
            },
            {
                "AdGRP": 26490.589427130762,
                "Channel_Genre": "Odhiya GEC"
            },
            {
                "AdGRP": 24356.87614959567,
                "Channel_Genre": "Marathi Movies"
            },
            {
                "AdGRP": 23585.363056422524,
                "Channel_Genre": "Hindi News"
            },
            {
                "AdGRP": 23410.2027620367,
                "Channel_Genre": "Punjabi GEC"
            },
            {
                "AdGRP": 16802.147621809352,
                "Channel_Genre": "Hindi Movies"
            }
        ],
        "ytdPreviousYear": [
            {
                "AdGRP": 288355.988190084,
                "Channel_Genre": "Hindi GEC - Paid"
            },
            {
                "AdGRP": 260327.9162093195,
                "Channel_Genre": "Hindi GEC - FTA"
            },
            {
                "AdGRP": 209731.83518940757,
                "Channel_Genre": "Telugu GEC"
            },
            {
                "AdGRP": 209190.19950934045,
                "Channel_Genre": null
            },
            {
                "AdGRP": 207330.14819752402,
                "Channel_Genre": "Tamil GEC"
            },
            {
                "AdGRP": 119220.98812349187,
                "Channel_Genre": "Kannada GEC"
            },
            {
                "AdGRP": 107528.77328828548,
                "Channel_Genre": "Hindi Movies - FTA"
            },
            {
                "AdGRP": 91274.30911834945,
                "Channel_Genre": "Bangla GEC"
            },
            {
                "AdGRP": 68532.7961941089,
                "Channel_Genre": "Kids"
            },
            {
                "AdGRP": 53435.385136702214,
                "Channel_Genre": "Malayalam GEC"
            },
            {
                "AdGRP": 53237.43929631985,
                "Channel_Genre": "null"
            },
            {
                "AdGRP": 51038.056027329294,
                "Channel_Genre": "Sports"
            },
            {
                "AdGRP": 48923.512116329744,
                "Channel_Genre": "Hindi Movies - Paid"
            },
            {
                "AdGRP": 46087.36507926416,
                "Channel_Genre": "Marathi GEC"
            },
            {
                "AdGRP": 40595.06711192767,
                "Channel_Genre": "Tamil Movies"
            },
            {
                "AdGRP": 35803.54110914061,
                "Channel_Genre": "Hindi Movies 2"
            },
            {
                "AdGRP": 34945.641160020954,
                "Channel_Genre": "Bhojpuri GEC"
            },
            {
                "AdGRP": 34121.10909062496,
                "Channel_Genre": "Telugu Movies"
            },
            {
                "AdGRP": 30140.555103222607,
                "Channel_Genre": "Kannada Movies"
            },
            {
                "AdGRP": 29860.427140595973,
                "Channel_Genre": "Music"
            },
            {
                "AdGRP": 21792.93003359274,
                "Channel_Genre": "Odhiya GEC"
            },
            {
                "AdGRP": 19707.51906144782,
                "Channel_Genre": "Marathi Movies"
            },
            {
                "AdGRP": 19375.16712935746,
                "Channel_Genre": "Punjabi GEC"
            },
            {
                "AdGRP": 11045.610045329668,
                "Channel_Genre": "Bangla Movies"
            },
            {
                "AdGRP": 10604.992040944286,
                "Channel_Genre": "Hindi GEC (HD)"
            }
        ],
        "mtdCurrentMonth": [
            {
                "AdGRP": 15208.605025832367,
                "Channel_Genre": null
            },
            {
                "AdGRP": 14265.725996577006,
                "Channel_Genre": "Hindi GEC - Paid"
            },
            {
                "AdGRP": 10202.355207578374,
                "Channel_Genre": "Hindi Movies - FTA"
            },
            {
                "AdGRP": 9863.052099420041,
                "Channel_Genre": "Telugu GEC"
            },
            {
                "AdGRP": 9861.680503816904,
                "Channel_Genre": "Tamil GEC"
            },
            {
                "AdGRP": 9091.009605391198,
                "Channel_Genre": "Hindi GEC - FTA"
            },
            {
                "AdGRP": 4860.688400428728,
                "Channel_Genre": "Kannada GEC"
            },
            {
                "AdGRP": 4119.023001410191,
                "Channel_Genre": "Bangla GEC"
            },
            {
                "AdGRP": 3102.352205296993,
                "Channel_Genre": "Kids"
            },
            {
                "AdGRP": 2724.363604628452,
                "Channel_Genre": "Bhojpuri GEC"
            },
            {
                "AdGRP": 2291.618003536205,
                "Channel_Genre": "Malayalam GEC"
            },
            {
                "AdGRP": 2186.76390192705,
                "Channel_Genre": "Hindi Movies - Paid"
            },
            {
                "AdGRP": 2119.1743019688365,
                "Channel_Genre": "Marathi GEC"
            },
            {
                "AdGRP": 1781.6560012569098,
                "Channel_Genre": "Sports"
            },
            {
                "AdGRP": 1702.9087016565973,
                "Channel_Genre": "Telugu Movies"
            },
            {
                "AdGRP": 1661.4885032991806,
                "Channel_Genre": "Music"
            },
            {
                "AdGRP": 1591.5222022317968,
                "Channel_Genre": "Tamil Movies"
            },
            {
                "AdGRP": 1588.2815026200988,
                "Channel_Genre": "Hindi Movies 2"
            },
            {
                "AdGRP": 1476.911003039604,
                "Channel_Genre": "Kannada Movies"
            },
            {
                "AdGRP": 1282.7692013775013,
                "Channel_Genre": "Odhiya GEC"
            },
            {
                "AdGRP": 1180.4127020015076,
                "Channel_Genre": "Marathi Movies"
            },
            {
                "AdGRP": 1082.6139022310745,
                "Channel_Genre": "Hindi News"
            },
            {
                "AdGRP": 1033.2215021920347,
                "Channel_Genre": "Punjabi GEC"
            },
            {
                "AdGRP": 779.2536010493059,
                "Channel_Genre": "Hindi Movies"
            },
            {
                "AdGRP": 558.9193006947535,
                "Channel_Genre": "Hindi GEC (HD)"
            }
        ],
        "mtdPreviousMonth": [
            {
                "AdGRP": 23429.35673696552,
                "Channel_Genre": null
            },
            {
                "AdGRP": 21307.309900148815,
                "Channel_Genre": "Hindi GEC - Paid"
            },
            {
                "AdGRP": 17574.550011058695,
                "Channel_Genre": "Hindi Movies - FTA"
            },
            {
                "AdGRP": 15369.464107951164,
                "Channel_Genre": "Telugu GEC"
            },
            {
                "AdGRP": 15148.244508151649,
                "Channel_Genre": "Tamil GEC"
            },
            {
                "AdGRP": 14999.514101625551,
                "Channel_Genre": "Hindi GEC - FTA"
            },
            {
                "AdGRP": 8050.487504567078,
                "Channel_Genre": "Kannada GEC"
            },
            {
                "AdGRP": 7104.682703964339,
                "Channel_Genre": "Bangla GEC"
            },
            {
                "AdGRP": 4958.094208177856,
                "Channel_Genre": "Bhojpuri GEC"
            },
            {
                "AdGRP": 4074.2514067665907,
                "Channel_Genre": "Kids"
            },
            {
                "AdGRP": 3784.013205212068,
                "Channel_Genre": "Malayalam GEC"
            },
            {
                "AdGRP": 3548.3083034313386,
                "Channel_Genre": "Marathi GEC"
            },
            {
                "AdGRP": 3338.512704287983,
                "Channel_Genre": "Hindi Movies - Paid"
            },
            {
                "AdGRP": 3032.5158037000074,
                "Channel_Genre": "Telugu Movies"
            },
            {
                "AdGRP": 2724.24860539363,
                "Channel_Genre": "Music"
            },
            {
                "AdGRP": 2653.836803629136,
                "Channel_Genre": "Tamil Movies"
            },
            {
                "AdGRP": 2557.554904311837,
                "Channel_Genre": "Kannada Movies"
            },
            {
                "AdGRP": 2273.182704511768,
                "Channel_Genre": "Hindi Movies 2"
            },
            {
                "AdGRP": 2089.569302758886,
                "Channel_Genre": "Odhiya GEC"
            },
            {
                "AdGRP": 2018.6002038029837,
                "Channel_Genre": "Marathi Movies"
            },
            {
                "AdGRP": 1827.077701389091,
                "Channel_Genre": "Sports"
            },
            {
                "AdGRP": 1820.4219033295885,
                "Channel_Genre": "Punjabi GEC"
            },
            {
                "AdGRP": 1603.560003212544,
                "Channel_Genre": "Hindi News"
            },
            {
                "AdGRP": 1393.6695003173227,
                "Channel_Genre": "Hindi Movies"
            },
            {
                "AdGRP": 955.9936010441525,
                "Channel_Genre": "Hindi GEC (HD)"
            }
        ]
    },
    "Channel_Name": {
        "ytdCurrentYear": [
            {
                "AdGRP": 83728.20640322939,
                "Channel_Name": "Sun TV"
            },
            {
                "AdGRP": 78037.5343032933,
                "Channel_Name": "STAR Plus"
            },
            {
                "AdGRP": 70899.82450595975,
                "Channel_Name": "STAR Maa"
            },
            {
                "AdGRP": 67984.05410254997,
                "Channel_Name": "Dangal"
            },
            {
                "AdGRP": 53010.76740514563,
                "Channel_Name": "Zee Telugu"
            },
            {
                "AdGRP": 50797.472607002244,
                "Channel_Name": "STAR Vijay"
            },
            {
                "AdGRP": 50225.89510143665,
                "Channel_Name": "SONY SAB"
            },
            {
                "AdGRP": 48580.68311278918,
                "Channel_Name": "Zee Kannada"
            },
            {
                "AdGRP": 44917.6786013443,
                "Channel_Name": "Colors"
            },
            {
                "AdGRP": 44435.04980919941,
                "Channel_Name": "Goldmines"
            },
            {
                "AdGRP": 43739.18980794758,
                "Channel_Name": "STAR Pravah"
            },
            {
                "AdGRP": 43711.10091063435,
                "Channel_Name": "Zee TV"
            },
            {
                "AdGRP": 36777.84600880826,
                "Channel_Name": "STAR Jalsha"
            },
            {
                "AdGRP": 32531.36040579228,
                "Channel_Name": "Zee Bangla"
            },
            {
                "AdGRP": 31388.327804801986,
                "Channel_Name": "ETV Telugu"
            },
            {
                "AdGRP": 29814.14279606247,
                "Channel_Name": "STAR Sports 1 Hindi"
            },
            {
                "AdGRP": 29116.00951246974,
                "Channel_Name": "Zee Tamil"
            },
            {
                "AdGRP": 26570.950013364767,
                "Channel_Name": "Colors Kannada"
            },
            {
                "AdGRP": 25954.87791888843,
                "Channel_Name": "Zee Anmol Cinema"
            },
            {
                "AdGRP": 25731.520612120694,
                "Channel_Name": "Asianet"
            },
            {
                "AdGRP": 24469.92352865108,
                "Channel_Name": "Manoranjan TV"
            },
            {
                "AdGRP": 23644.75881186317,
                "Channel_Name": "Gemini TV"
            },
            {
                "AdGRP": 23200.02622017688,
                "Channel_Name": "STAR Utsav Movies"
            },
            {
                "AdGRP": 22473.8843159214,
                "Channel_Name": "Sony Entertainment Television"
            },
            {
                "AdGRP": 21329.36382996015,
                "Channel_Name": "Manoranjan Grand"
            }
        ],
        "ytdPreviousYear": [
            {
                "AdGRP": 81297.51899910998,
                "Channel_Name": "Sun TV"
            },
            {
                "AdGRP": 78505.76500394102,
                "Channel_Name": "STAR Plus"
            },
            {
                "AdGRP": 73225.42401915509,
                "Channel_Name": "STAR Maa"
            },
            {
                "AdGRP": 68505.54500958556,
                "Channel_Name": "STAR Utsav"
            },
            {
                "AdGRP": 55420.81700306316,
                "Channel_Name": "STAR Vijay"
            },
            {
                "AdGRP": 55313.327996178064,
                "Channel_Name": "SONY SAB"
            },
            {
                "AdGRP": 52958.416018696036,
                "Channel_Name": "Zee Telugu"
            },
            {
                "AdGRP": 45763.26604135393,
                "Channel_Name": "Dangal"
            },
            {
                "AdGRP": 45390.36502298014,
                "Channel_Name": "Sony Pal"
            },
            {
                "AdGRP": 45280.6240251282,
                "Channel_Name": "Zee Kannada"
            },
            {
                "AdGRP": 44955.019027328584,
                "Channel_Name": "Colors"
            },
            {
                "AdGRP": 43835.7670296249,
                "Channel_Name": "Zee Anmol"
            },
            {
                "AdGRP": 41708.15003395919,
                "Channel_Name": "Zee TV"
            },
            {
                "AdGRP": 39186.33901683451,
                "Channel_Name": "STAR Sports 1 Hindi"
            },
            {
                "AdGRP": 36432.141023201635,
                "Channel_Name": "STAR Pravah"
            },
            {
                "AdGRP": 35390.758013514336,
                "Channel_Name": "Zee Bangla"
            },
            {
                "AdGRP": 35069.23702160304,
                "Channel_Name": "STAR Jalsha"
            },
            {
                "AdGRP": 32453.931010058615,
                "Channel_Name": "ETV Telugu"
            },
            {
                "AdGRP": 31671.40202808648,
                "Channel_Name": "Colors Rishtey"
            },
            {
                "AdGRP": 29819.722029248835,
                "Channel_Name": "Zee Tamil"
            },
            {
                "AdGRP": 27486.189031491405,
                "Channel_Name": "Sony Entertainment Television"
            },
            {
                "AdGRP": 26489.926024086773,
                "Channel_Name": "Asianet"
            },
            {
                "AdGRP": 26351.279022046598,
                "Channel_Name": "Dhinchaak"
            },
            {
                "AdGRP": 26307.53202297911,
                "Channel_Name": "Colors Kannada"
            },
            {
                "AdGRP": 25825.141026529716,
                "Channel_Name": "Gemini TV"
            }
        ],
        "mtdCurrentMonth": [
            {
                "AdGRP": 4224.561696195509,
                "Channel_Name": "STAR Plus"
            },
            {
                "AdGRP": 4212.746398366988,
                "Channel_Name": "Sun TV"
            },
            {
                "AdGRP": 3820.6079986868426,
                "Channel_Name": "Dangal"
            },
            {
                "AdGRP": 3738.976797248237,
                "Channel_Name": "STAR Maa"
            },
            {
                "AdGRP": 2920.0694994274527,
                "Channel_Name": "Zee Telugu"
            },
            {
                "AdGRP": 2587.4511985704303,
                "Channel_Name": "SONY SAB"
            },
            {
                "AdGRP": 2330.961601455696,
                "Channel_Name": "STAR Vijay"
            },
            {
                "AdGRP": 2312.0173990969197,
                "Channel_Name": "Zee TV"
            },
            {
                "AdGRP": 2226.643400502857,
                "Channel_Name": "STAR Pravah"
            },
            {
                "AdGRP": 2067.7673991080374,
                "Channel_Name": "Zee Kannada"
            },
            {
                "AdGRP": 1994.119699681527,
                "Channel_Name": "Colors"
            },
            {
                "AdGRP": 1940.8714997409843,
                "Channel_Name": "Goldmines"
            },
            {
                "AdGRP": 1731.9276988031343,
                "Channel_Name": "STAR Jalsha"
            },
            {
                "AdGRP": 1703.8320010799216,
                "Channel_Name": "Zee Tamil"
            },
            {
                "AdGRP": 1530.839700620454,
                "Channel_Name": "Zee Bangla"
            },
            {
                "AdGRP": 1380.4394011886325,
                "Channel_Name": "Manoranjan TV"
            },
            {
                "AdGRP": 1339.469299531076,
                "Channel_Name": "ETV Telugu"
            },
            {
                "AdGRP": 1179.8868001141454,
                "Channel_Name": "Sony Wah"
            },
            {
                "AdGRP": 1130.357200652812,
                "Channel_Name": "Colors Kannada"
            },
            {
                "AdGRP": 1125.331800930202,
                "Channel_Name": "The Q"
            },
            {
                "AdGRP": 1064.289700652902,
                "Channel_Name": "STAR Utsav Movies"
            },
            {
                "AdGRP": 1030.3732013409026,
                "Channel_Name": "Manoranjan Grand"
            },
            {
                "AdGRP": 1007.4935010783374,
                "Channel_Name": "Shemaroo TV"
            },
            {
                "AdGRP": 985.4094009840555,
                "Channel_Name": "Asianet"
            },
            {
                "AdGRP": 969.181600599084,
                "Channel_Name": "Sony Entertainment Television"
            }
        ],
        "mtdPreviousMonth": [
            {
                "AdGRP": 6573.533704029396,
                "Channel_Name": "Sun TV"
            },
            {
                "AdGRP": 6209.879795822781,
                "Channel_Name": "STAR Plus"
            },
            {
                "AdGRP": 6057.508894769009,
                "Channel_Name": "Dangal"
            },
            {
                "AdGRP": 5493.314800980035,
                "Channel_Name": "STAR Maa"
            },
            {
                "AdGRP": 4306.613701617345,
                "Channel_Name": "Zee Telugu"
            },
            {
                "AdGRP": 3952.615398662514,
                "Channel_Name": "Colors"
            },
            {
                "AdGRP": 3741.7701006336138,
                "Channel_Name": "Goldmines"
            },
            {
                "AdGRP": 3582.8557990789413,
                "Channel_Name": "STAR Vijay"
            },
            {
                "AdGRP": 3433.0802014415385,
                "Channel_Name": "Zee TV"
            },
            {
                "AdGRP": 3391.695400219527,
                "Channel_Name": "STAR Pravah"
            },
            {
                "AdGRP": 3327.172199739609,
                "Channel_Name": "SONY SAB"
            },
            {
                "AdGRP": 3280.3087008008733,
                "Channel_Name": "Zee Kannada"
            },
            {
                "AdGRP": 2894.102501305548,
                "Channel_Name": "STAR Jalsha"
            },
            {
                "AdGRP": 2623.7731996996736,
                "Channel_Name": "Zee Bangla"
            },
            {
                "AdGRP": 2370.259600231526,
                "Channel_Name": "Zee Tamil"
            },
            {
                "AdGRP": 2246.140001589665,
                "Channel_Name": "Manoranjan TV"
            },
            {
                "AdGRP": 2156.4564996480767,
                "Channel_Name": "Sony Wah"
            },
            {
                "AdGRP": 2154.2210005109664,
                "Channel_Name": "ETV Telugu"
            },
            {
                "AdGRP": 2016.3646012046374,
                "Channel_Name": "Colors Kannada"
            },
            {
                "AdGRP": 2003.7487005777657,
                "Channel_Name": "Shemaroo TV"
            },
            {
                "AdGRP": 1912.7768015452602,
                "Channel_Name": "Manoranjan Grand"
            },
            {
                "AdGRP": 1678.2992010726011,
                "Channel_Name": "Colors Cineplex Superhits"
            },
            {
                "AdGRP": 1643.1119009784306,
                "Channel_Name": "STAR Utsav Movies"
            },
            {
                "AdGRP": 1616.2788018287538,
                "Channel_Name": "Asianet"
            },
            {
                "AdGRP": 1596.462001522712,
                "Channel_Name": "Gemini Movies"
            }
        ]
    }
}

const railsData = [
    {
        "AdRevenue": 163879527.89780003,
        "Brand": "SENSODYNE",
        "Advertiser": "GLAXO SMITHKLINE ASIA",
        "Agency": "TLG INDIA PVT LTD (HAR)"
    },
    {
        "AdRevenue": 107468255,
        "Brand": "LUX TOILET SOAP",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 103720802.67990002,
        "Brand": "MAAZA",
        "Advertiser": "COCA COLA INDIA PVT. LTD",
        "Agency": "MEDIACOM COMM (HAR)"
    },
    {
        "AdRevenue": 102571927.10990001,
        "Brand": "THUMS UP.",
        "Advertiser": "COCA COLA INDIA PVT. LTD",
        "Agency": "MEDIACOM COMM (HAR)"
    },
    {
        "AdRevenue": 99974505.5,
        "Brand": "STAR SPORTS 2",
        "Advertiser": "STAR INDIA PVT.LTD.",
        "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
    },
    {
        "AdRevenue": 89667723,
        "Brand": "CLOSE UP TOOTHPASTE",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 83539880,
        "Brand": "PARLE - G - PBPL",
        "Advertiser": "PARLE BISCUITS PRIVATE LTD(MUM)",
        "Agency": "TLG INDIA PVT LTD (MUM)"
    },
    {
        "AdRevenue": 83168591,
        "Brand": "EVEREST  MASALA",
        "Advertiser": "EVEREST FOOD PRODUCTS PVT LTD",
        "Agency": "EVEREST FOOD PRODUCTS PVT LTD"
    },
    {
        "AdRevenue": 77902840,
        "Brand": "A23",
        "Advertiser": "HEAD DIGITAL WORKS PRIVATE LIMITED",
        "Agency": "MATRIX PUBLICITIES & MEDIA (KAR)"
    },
    {
        "AdRevenue": 72551092.5,
        "Brand": "SAMSUNG MOBILE",
        "Advertiser": "SAMSUNG INDIA ELECTRONICS PVT LTD",
        "Agency": "FCB ULKA ADVT(HAR)"
    },
    {
        "AdRevenue": 71986068.5,
        "Brand": "STANDARD HORLICKS",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 67133910,
        "Brand": "CLINIC PLUS SHAMPOO",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 64000021,
        "Brand": "MATRIMONY .COM",
        "Advertiser": "MATRIMONY. COM LTD",
        "Agency": "MATRIX PUBLICITIES & MEDIA (KAR)"
    },
    {
        "AdRevenue": 62971302,
        "Brand": "DOVE SHAMPOO",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 62358855.5,
        "Brand": "BOURNVITA",
        "Advertiser": "MONDELEZ INDIA FOODS LTD",
        "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
    },
    {
        "AdRevenue": 60169919.5,
        "Brand": "SURF EXCEL",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 57670625.5,
        "Brand": "SUNRISE",
        "Advertiser": "ITC LIMITED (BRAND - SUNRISE)",
        "Agency": "STRATEGY ADVTG & MKTG (KOLKATA)"
    },
    {
        "AdRevenue": 57318217.614999995,
        "Brand": "LAYS",
        "Advertiser": "PEPSICO INDIA HOLDI (DEL)",
        "Agency": "MARKETGATE DIMENSIONS RES. INDIA PV"
    },
    {
        "AdRevenue": 56637235,
        "Brand": "LIFEBUOY SOAP",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 54214080,
        "Brand": "BROOKE BOND RED LABEL",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 53001680,
        "Brand": "SUNLIGHT COLOUR DET PDR",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 52915410,
        "Brand": "INDIAN OIL RETAIL",
        "Advertiser": "INDIAN OIL CORPORATION LT",
        "Agency": "MADISON COMM (BOM)"
    },
    {
        "AdRevenue": 52176428,
        "Brand": "COMFORT",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 51711492,
        "Brand": "GRAND I10",
        "Advertiser": "HYUNDAI MOTOR INDIA LTD.(NEW DELHI)",
        "Agency": "HAVAS MEDIA INDIA PVT. LTD. (HAR)"
    },
    {
        "AdRevenue": 51179702,
        "Brand": "COLGATE DENTAL CREAM",
        "Advertiser": "COLGATE PALMOLIVE (I) LTD",
        "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
    },
    {
        "AdRevenue": 51056206,
        "Brand": "VICCO VAJRADANTI.",
        "Advertiser": "VICCO LABORATORIES (VICCO PRDTS, BOM) LI",
        "Agency": "MODERN ADVTG AGENCY (MUM)"
    },
    {
        "AdRevenue": 51021794,
        "Brand": "BERGER PAINTS",
        "Advertiser": "BERGER PAINTS INDIA LTD",
        "Agency": "GROUPM MEDIA INDIA PVT. LTD (KOL)"
    },
    {
        "AdRevenue": 50000000,
        "Brand": "CCL LIVE",
        "Advertiser": "CELEBRITY CRICKET LEAGUE PVT LTD",
        "Agency": "CELEBRITY CRICKET LEAGUE PVT LTD"
    },
    {
        "AdRevenue": 49074576.050000004,
        "Brand": "KURKURE",
        "Advertiser": "PEPSICO INDIA HOLDI (DEL)",
        "Agency": "MARKETGATE DIMENSIONS RES. INDIA PV"
    },
    {
        "AdRevenue": 48926063.5,
        "Brand": "GARNIER BRIGHT COMPLETE SERUM",
        "Advertiser": "L`OREAL INDIA PVT LTD",
        "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
    },
    {
        "AdRevenue": 48787228.04,
        "Brand": "PAYTM",
        "Advertiser": "ONE 97 COMMUNICATIONS LTD.",
        "Agency": "GROUPM MEDIA INDIA PVT LTD (HAR)"
    },
    {
        "AdRevenue": 48493059.199999996,
        "Brand": "NESTLE KITKAT",
        "Advertiser": "NESTLE INDIA LTD (DEL)",
        "Agency": "TLG INDIA PVT LTD (HAR)"
    },
    {
        "AdRevenue": 47987392.5,
        "Brand": "HEAD & SHOULDER",
        "Advertiser": "PROCTER & GAMBLE HOME PROD PVT LTD(",
        "Agency": "MEDIACOM COMM (MUM)"
    },
    {
        "AdRevenue": 47298892.194000006,
        "Brand": "IODEX",
        "Advertiser": "GLAXO SMITHKLINE ASIA",
        "Agency": "TLG INDIA PVT LTD (HAR)"
    },
    {
        "AdRevenue": 46825909,
        "Brand": "CORPORATE .",
        "Advertiser": "LIFE INSURANCE (MUM)",
        "Agency": "DDB MUDRA MAX PVT LTD (MUM)"
    },
    {
        "AdRevenue": 46120421.1,
        "Brand": "BRITANNIA PURE MAGIC CHOCOLUSH",
        "Advertiser": "BRITANNIA INDUSTRIES LTD",
        "Agency": "GROUPM MEDIA INDIA PVT LTD (BLR)"
    },
    {
        "AdRevenue": 46089369.595000006,
        "Brand": "CHARGED BY THUMS UP",
        "Advertiser": "COCA COLA INDIA PVT. LTD",
        "Agency": "MEDIACOM COMM (HAR)"
    },
    {
        "AdRevenue": 45721977.720000006,
        "Brand": "BINGO TEDHE MEDHE",
        "Advertiser": "ITC LIMITED (BLR)",
        "Agency": "INITIATIVE MEDIA (BLR)"
    },
    {
        "AdRevenue": 44528164,
        "Brand": "AMAZON.IN",
        "Advertiser": "AMAZON SELLER SERVICES PVT LTD",
        "Agency": "INITIATIVE MEDIA (BLR)"
    },
    {
        "AdRevenue": 44297398.5,
        "Brand": "DGIPR.",
        "Advertiser": "DGIPR MANTRALAYA (MUM)",
        "Agency": "DGIPR MANTRALAYA (MUM)"
    },
    {
        "AdRevenue": 43779020,
        "Brand": "MALABAR GOLD & DIAMONDS",
        "Advertiser": "MALABAR GOLD PVT LTD",
        "Agency": "IMC ADVERTISING PRIVATE LTD"
    },
    {
        "AdRevenue": 43268721.5,
        "Brand": "AP_EXTERIORS_MAIN",
        "Advertiser": "ASIAN PAINTS LIMITED",
        "Agency": "MADISON COMM (BOM)"
    },
    {
        "AdRevenue": 42736304,
        "Brand": "GOOD KNIGHT GOLD FLASH",
        "Advertiser": "GODREJ CONSUMER PRODUCTS",
        "Agency": "MADISON COMM (BOM)"
    },
    {
        "AdRevenue": 42189758,
        "Brand": "MPL GAMING",
        "Advertiser": "GALACTUS FUNWARE TECHNOLOGY PVT LTD",
        "Agency": "INITIATIVE MEDIA (BLR)"
    },
    {
        "AdRevenue": 42054969,
        "Brand": "RBI",
        "Advertiser": "RESERVE BANK OF I (MUM)",
        "Agency": "DENTSU ADVTG & MEDIA SER (I) P (MUM"
    },
    {
        "AdRevenue": 41200540,
        "Brand": "TRESEMME SHAMPOO",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 40026485,
        "Brand": "KISSAN TOMATO KETCHUP",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 39887002,
        "Brand": "BRU INSTANT",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 39679387,
        "Brand": "SUNSILK SHAMPOO",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 39494249,
        "Brand": "AMAZON PRIME VIDEO",
        "Advertiser": "AMAZON SELLER SERVICES PVT LTD",
        "Agency": "INITIATIVE MEDIA (BLR)"
    },
    {
        "AdRevenue": 39219085.024000004,
        "Brand": "LENSKART.COM(MUMBAI_NEW)",
        "Advertiser": "LENSKART SOLUTIONS PVT LTD (DELHI)",
        "Agency": "TLG INDIA PVT LTD (MUM)"
    },
    {
        "AdRevenue": 39176020,
        "Brand": "20-20 COOKIES PBPL",
        "Advertiser": "PARLE BISCUITS PRIVATE LTD(MUM)",
        "Agency": "TLG INDIA PVT LTD (MUM)"
    },
    {
        "AdRevenue": 39150581.5,
        "Brand": "CADBURY DAIRYMILK SILK",
        "Advertiser": "MONDELEZ INDIA FOODS LTD",
        "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
    },
    {
        "AdRevenue": 39091568,
        "Brand": "GARNIER BLACK NATURALS",
        "Advertiser": "L`OREAL INDIA PVT LTD",
        "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
    },
    {
        "AdRevenue": 38738055.5,
        "Brand": "KINDER JOY",
        "Advertiser": "FERRERO INDIA LTD (PUNE)",
        "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
    },
    {
        "AdRevenue": 38732438,
        "Brand": "MEESHO.COM - B2C",
        "Advertiser": "FASHNEAR TECHNOLOGIES PVT LTD(BANG)",
        "Agency": "MATRIX PUBLICITIES & MEDIA (KAR)"
    },
    {
        "AdRevenue": 38570502.5,
        "Brand": "SUNRISE",
        "Advertiser": "ITC LIMITED (BRAND - SUNRISE)",
        "Agency": "STRATEGY ADVERTISING & MKTG. (P) LTD."
    },
    {
        "AdRevenue": 38321778,
        "Brand": "SHAADI COM",
        "Advertiser": "PEOPLE INTERACTIVE (MUM)",
        "Agency": "MADISON COMM (BOM)"
    },
    {
        "AdRevenue": 38317498,
        "Brand": "L`OREAL PARIS HYALURON MOISTURE",
        "Advertiser": "L`OREAL INDIA PVT LTD",
        "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
    },
    {
        "AdRevenue": 37957109,
        "Brand": "LIPTON GREEN TEA",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 37737843.16,
        "Brand": "NESTLE MUNCH",
        "Advertiser": "NESTLE INDIA LTD (DEL)",
        "Agency": "TLG INDIA PVT LTD (HAR)"
    },
    {
        "AdRevenue": 37104516.5,
        "Brand": "PEDIASURE",
        "Advertiser": "ABBOTT HEALTHCARE PRIVATE LIMITED",
        "Agency": "TLG INDIA PVT LTD (MUM)"
    },
    {
        "AdRevenue": 36933808.5,
        "Brand": "GLOW & LOVELY MV SACHET",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 36435680,
        "Brand": "GLOW AND LOVELY",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 35775514,
        "Brand": "HORLICKS WOMENS PLUS",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 35374526,
        "Brand": "PROTINEX ORIGINAL",
        "Advertiser": "NUTRICIA INTERNATIONAL PRIVATE LTD",
        "Agency": "MATRIX PUBLICITIES & MEDIA (MUM)"
    },
    {
        "AdRevenue": 34922833.752000004,
        "Brand": "ENO",
        "Advertiser": "GLAXO SMITHKLINE ASIA",
        "Agency": "TLG INDIA PVT LTD (HAR)"
    },
    {
        "AdRevenue": 34396404.18000001,
        "Brand": "ZUPEE",
        "Advertiser": "CASHGRAIL PRIVATE LIMITED (HAR)",
        "Agency": "HAVAS MEDIA INDIA PVT. LTD. (HAR)"
    },
    {
        "AdRevenue": 34359476,
        "Brand": "NESCAFE CLASSIC",
        "Advertiser": "NESTLE INDIA LTD (DEL)",
        "Agency": "TLG INDIA PVT LTD (HAR)"
    },
    {
        "AdRevenue": 34343241,
        "Brand": "SURF EXCEL AUTOMATIC",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 34307629.5,
        "Brand": "PONDS BRIGHT BEAUTY",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 34017297.480000004,
        "Brand": "ALL OUT.",
        "Advertiser": "BRILLON CONSUMER PRODUCTS PRIVATE L",
        "Agency": "MCCANN ERICKSON (HARYANA)"
    },
    {
        "AdRevenue": 33992124,
        "Brand": "GODREJ AER POWER POCKET",
        "Advertiser": "GODREJ CONSUMER PRODUCTS",
        "Agency": "MADISON COMM (BOM)"
    },
    {
        "AdRevenue": 33853304,
        "Brand": "COLGATE MAX FRESH",
        "Advertiser": "COLGATE PALMOLIVE (I) LTD",
        "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
    },
    {
        "AdRevenue": 33396207.96,
        "Brand": "DOMINOS",
        "Advertiser": "JUBILANT FOODWORKS LTD.",
        "Agency": "HAVAS MEDIA INDIA PVT. LTD. (HAR)"
    },
    {
        "AdRevenue": 33395943,
        "Brand": "INDIRA  IVF",
        "Advertiser": "INDIRA IVF HOSPITAL PVT. LTD (MUM)",
        "Agency": "MADISON COMM (BOM)"
    },
    {
        "AdRevenue": 33014774.711200003,
        "Brand": "STING.",
        "Advertiser": "PEPSICO INDIA HOLDI (DEL)",
        "Agency": "MARKETGATE DIMENSIONS RES. INDIA PV"
    },
    {
        "AdRevenue": 32898848,
        "Brand": "PHONEPE INSURANCE",
        "Advertiser": "PHONEPE INSURANCE BROKING SERVICES(",
        "Agency": "FCB ULKA ADVT(BLR)"
    },
    {
        "AdRevenue": 32446128,
        "Brand": "HIMALAYA PURIFYING NEEM FACE WASH",
        "Advertiser": "HIMALAYA WELLNESS COMPANY (BANG)",
        "Agency": "GROUPM MEDIA INDIA PVT LTD (BLR)"
    },
    {
        "AdRevenue": 32419698.5,
        "Brand": "EMAMI HEALTHY & TASTY MUSTARD OIL",
        "Advertiser": "EMAMI AGROTECH LIMITED",
        "Agency": "GROUPM MEDIA INDIA PVT. LTD (KOL)"
    },
    {
        "AdRevenue": 32347044.5,
        "Brand": "RIN ADVANCED BAR",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 32298320,
        "Brand": "SOFIT SOYA MILK",
        "Advertiser": "HERSHEY INDIA PVT LTD",
        "Agency": "ORION MEDIA TRADING (INDIA) LLP"
    },
    {
        "AdRevenue": 32060372,
        "Brand": "CADBURY DAIRY MILK CHOCOLATE",
        "Advertiser": "MONDELEZ INDIA FOODS LTD",
        "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
    },
    {
        "AdRevenue": 31505830.825000003,
        "Brand": "MOUNTAIN DEW",
        "Advertiser": "PEPSICO INDIA HOLDI (DEL)",
        "Agency": "MARKETGATE DIMENSIONS RES. INDIA PV"
    },
    {
        "AdRevenue": 31450217.76,
        "Brand": "ALMOND BOARD OF CALIFORNIA.",
        "Advertiser": "TLG INDIA PVT LTD (HAR)",
        "Agency": "INITIATIVE MEDIA (HAR)"
    },
    {
        "AdRevenue": 31417810.5,
        "Brand": "WPL",
        "Advertiser": "BOARD OF CONTROL FOR CRICKET IN IND",
        "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
    },
    {
        "AdRevenue": 31191985,
        "Brand": "JUNGLEE GAMES",
        "Advertiser": "JUNGLEE GAMES INDIA PRIVATE LTD(HAR",
        "Agency": "TLG INDIA PVT LTD (HAR)"
    },
    {
        "AdRevenue": 30879482,
        "Brand": "GARNIER COLOUR NATURAL",
        "Advertiser": "L`OREAL INDIA PVT LTD",
        "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
    },
    {
        "AdRevenue": 30831280,
        "Brand": "HERSHEY KISSES",
        "Advertiser": "HERSHEY INDIA PVT LTD",
        "Agency": "MCCANN ERICKSON (BOM)"
    },
    {
        "AdRevenue": 30820767.82,
        "Brand": "MAMY POKO PANT",
        "Advertiser": "UNICHARM INDIA PVT LTD",
        "Agency": "DENTSU ADVTG & MEDIA SER (I) P (HAR"
    },
    {
        "AdRevenue": 30609198,
        "Brand": "PEARS SOAPS",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 30285770.840000007,
        "Brand": "S P R I T E",
        "Advertiser": "COCA COLA INDIA PVT. LTD",
        "Agency": "MEDIACOM COMM (HAR)"
    },
    {
        "AdRevenue": 30066183,
        "Brand": "COLGATE VISIBLE WHITE",
        "Advertiser": "COLGATE PALMOLIVE (I) LTD",
        "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
    },
    {
        "AdRevenue": 29991397.5,
        "Brand": "ACTIVE WHEEL",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 29860822.821000002,
        "Brand": "FRONX",
        "Advertiser": "MARUTI SUZUKI INDIA LTD.",
        "Agency": "DENTSU ADVTG & MEDIA SER (I) P (HAR"
    },
    {
        "AdRevenue": 29762415,
        "Brand": "SUHANA MASALE",
        "Advertiser": "PRAVIN MASALEWALE",
        "Agency": "PUBLICITY PARLOUR"
    },
    {
        "AdRevenue": 29694845,
        "Brand": "KFC",
        "Advertiser": "KFC INDIA MARKETING PRIVATE LTD(HAR",
        "Agency": "GROUPM MEDIA INDIA PVT LTD (HAR)"
    },
    {
        "AdRevenue": 29571023.36,
        "Brand": "GOOGLE SEARCH",
        "Advertiser": "GOOGLE INDIA PVT. LTD.",
        "Agency": "MEDIAEDGE CIA INDIA(HAR)"
    },
    {
        "AdRevenue": 29492910,
        "Brand": "PONDS BRIGHT BEAUTY FACEWASH",
        "Advertiser": "HINDUSTAN UNILEVERS LTD",
        "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
    },
    {
        "AdRevenue": 29357891,
        "Brand": "GODREJ NO.1",
        "Advertiser": "GODREJ CONSUMER PRODUCTS",
        "Agency": "MADISON COMM (BOM)"
    }
]

const barndAndAdvertiserRankingRails = {
    "brandsAndAdvertiserRankingByBrand": [
        {
            "AdRevenue": 118634085.59980002,
            "Brand": "SENSODYNE"
        },
        {
            "AdRevenue": 90149214,
            "Brand": "LUX TOILET SOAP"
        },
        {
            "AdRevenue": 79618991,
            "Brand": "PARLE - G - PBPL"
        },
        {
            "AdRevenue": 75920105,
            "Brand": "A23"
        },
        {
            "AdRevenue": 71031929.85,
            "Brand": "THUMS UP."
        },
        {
            "AdRevenue": 64000021,
            "Brand": "MATRIMONY .COM"
        },
        {
            "AdRevenue": 57325191,
            "Brand": "STANDARD HORLICKS"
        },
        {
            "AdRevenue": 54272200,
            "Brand": "EVEREST MASALA"
        },
        {
            "AdRevenue": 52731776.150000006,
            "Brand": "MAAZA"
        },
        {
            "AdRevenue": 52264334,
            "Brand": "INDIAN OIL RETAIL"
        },
        {
            "AdRevenue": 49251130,
            "Brand": "CLOSE UP TOOTHPASTE"
        },
        {
            "AdRevenue": 48979860,
            "Brand": "STAR SPORTS 2"
        },
        {
            "AdRevenue": 48010052,
            "Brand": "GRAND I10"
        },
        {
            "AdRevenue": 47619474,
            "Brand": "CLINIC PLUS SHAMPOO"
        },
        {
            "AdRevenue": 47487112.04,
            "Brand": "PAYTM"
        },
        {
            "AdRevenue": 46037347.5,
            "Brand": "DOVE SHAMPOO"
        },
        {
            "AdRevenue": 44757220,
            "Brand": "SUNLIGHT COLOUR DET PDR"
        },
        {
            "AdRevenue": 44354419.5,
            "Brand": "SAMSUNG MOBILE"
        },
        {
            "AdRevenue": 43347920,
            "Brand": "MALABAR GOLD & DIAMONDS"
        },
        {
            "AdRevenue": 42023115.730000004,
            "Brand": "KURKURE"
        },
        {
            "AdRevenue": 41762958.5,
            "Brand": "BOURNVITA"
        },
        {
            "AdRevenue": 40949409,
            "Brand": "BERGER PAINTS"
        },
        {
            "AdRevenue": 40837683.5,
            "Brand": "SUNRISE"
        },
        {
            "AdRevenue": 39483252,
            "Brand": "SURF EXCEL"
        },
        {
            "AdRevenue": 38947865,
            "Brand": "BROOKE BOND RED LABEL"
        },
        {
            "AdRevenue": 38861323.989999995,
            "Brand": "LAYS"
        },
        {
            "AdRevenue": 38838466.194,
            "Brand": "IODEX"
        },
        {
            "AdRevenue": 38117924,
            "Brand": "20-20 COOKIES PBPL"
        },
        {
            "AdRevenue": 37946081.5,
            "Brand": "SUNRISE"
        },
        {
            "AdRevenue": 37275389,
            "Brand": "LIFEBUOY SOAP"
        },
        {
            "AdRevenue": 36341879.1,
            "Brand": "BRITANNIA PURE MAGIC CHOCOLUSH"
        },
        {
            "AdRevenue": 35102630.4,
            "Brand": "NESTLE KITKAT"
        },
        {
            "AdRevenue": 33941127,
            "Brand": "MEESHO.COM - B2C"
        },
        {
            "AdRevenue": 33106538,
            "Brand": "MPL GAMING"
        },
        {
            "AdRevenue": 32494266,
            "Brand": "HEAD & SHOULDER"
        },
        {
            "AdRevenue": 31627109.720000003,
            "Brand": "BINGO TEDHE MEDHE"
        },
        {
            "AdRevenue": 30619200,
            "Brand": "SOFIT SOYA MILK"
        },
        {
            "AdRevenue": 30500440,
            "Brand": "VICCO VAJRADANTI."
        },
        {
            "AdRevenue": 30181189,
            "Brand": "BRU INSTANT"
        },
        {
            "AdRevenue": 29744984,
            "Brand": "COMFORT"
        },
        {
            "AdRevenue": 29583904,
            "Brand": "CORPORATE ."
        },
        {
            "AdRevenue": 29145010.5,
            "Brand": "AP_EXTERIORS_MAIN"
        },
        {
            "AdRevenue": 29052146,
            "Brand": "PIRAMAL FINANCE"
        },
        {
            "AdRevenue": 29050595,
            "Brand": "KISSAN TOMATO KETCHUP"
        },
        {
            "AdRevenue": 29019339,
            "Brand": "LIPTON GREEN TEA"
        },
        {
            "AdRevenue": 28763919.024,
            "Brand": "LENSKART.COM(MUMBAI_NEW)"
        },
        {
            "AdRevenue": 28689367,
            "Brand": "COLGATE DENTAL CREAM"
        },
        {
            "AdRevenue": 28238589,
            "Brand": "GARNIER BRIGHT COMPLETE SERUM"
        },
        {
            "AdRevenue": 27852360,
            "Brand": "TRESEMME SHAMPOO"
        },
        {
            "AdRevenue": 27765828,
            "Brand": "SHAADI COM"
        },
        {
            "AdRevenue": 27524856,
            "Brand": "NESCAFE CLASSIC"
        },
        {
            "AdRevenue": 27288675.5,
            "Brand": "KINDER JOY"
        },
        {
            "AdRevenue": 27162200,
            "Brand": "BERGER PAINTS"
        },
        {
            "AdRevenue": 27138644,
            "Brand": "L`OREAL PARIS HYALURON MOISTURE"
        },
        {
            "AdRevenue": 27026976,
            "Brand": "DALMIA CEMENT"
        },
        {
            "AdRevenue": 26947850,
            "Brand": "KFC"
        },
        {
            "AdRevenue": 26859618,
            "Brand": "KINDER CREAMY"
        },
        {
            "AdRevenue": 26791437.660000004,
            "Brand": "NESTLE MUNCH"
        },
        {
            "AdRevenue": 26657224,
            "Brand": "GARNIER BLACK NATURALS"
        },
        {
            "AdRevenue": 26480135,
            "Brand": "BANDHAN BANK"
        },
        {
            "AdRevenue": 26350229.688,
            "Brand": "MSDS"
        },
        {
            "AdRevenue": 26188592.76,
            "Brand": "ALMOND BOARD OF CALIFORNIA."
        },
        {
            "AdRevenue": 25762570.5,
            "Brand": "CADBURY DAIRYMILK SILK"
        },
        {
            "AdRevenue": 25363673,
            "Brand": "SLEEPWELL."
        },
        {
            "AdRevenue": 25359876,
            "Brand": "AMAZON PRIME VIDEO"
        },
        {
            "AdRevenue": 25308826.5,
            "Brand": "SUNSILK SHAMPOO"
        },
        {
            "AdRevenue": 25042226,
            "Brand": "PEDIASURE"
        },
        {
            "AdRevenue": 24849462.250000004,
            "Brand": "GERBER."
        },
        {
            "AdRevenue": 24691830,
            "Brand": "DOMINOS"
        },
        {
            "AdRevenue": 24543376,
            "Brand": "EMAMI HEALTHY & TASTY MUSTARD OIL"
        },
        {
            "AdRevenue": 24289606,
            "Brand": "HORLICKS WOMENS PLUS"
        },
        {
            "AdRevenue": 24233500,
            "Brand": "COOKME"
        },
        {
            "AdRevenue": 24103050,
            "Brand": "INDIA GATE"
        },
        {
            "AdRevenue": 23974660,
            "Brand": "HERSHEY KISSES"
        },
        {
            "AdRevenue": 23920011.597,
            "Brand": "ENO"
        },
        {
            "AdRevenue": 23787502,
            "Brand": "GODREJ AER POWER POCKET"
        },
        {
            "AdRevenue": 23672624,
            "Brand": "PHONEPE INSURANCE"
        },
        {
            "AdRevenue": 23627164.4,
            "Brand": "BOROLINE"
        },
        {
            "AdRevenue": 23547594.240000002,
            "Brand": "FRONX"
        },
        {
            "AdRevenue": 23393939,
            "Brand": "SUHANA MASALE"
        },
        {
            "AdRevenue": 23329976,
            "Brand": "INDIRA IVF"
        },
        {
            "AdRevenue": 23212910,
            "Brand": "TANISHQ RIVAAH"
        },
        {
            "AdRevenue": 23173408,
            "Brand": "SF SECURE COTTON XL"
        },
        {
            "AdRevenue": 23170126,
            "Brand": "ULTRA TECH"
        },
        {
            "AdRevenue": 22846548,
            "Brand": "PROTINEX ORIGINAL"
        },
        {
            "AdRevenue": 22216675.819999997,
            "Brand": "MAMY POKO PANT"
        },
        {
            "AdRevenue": 22178000,
            "Brand": "VASANTH & CO"
        },
        {
            "AdRevenue": 22094462,
            "Brand": "FORTUNE SOYA OIL"
        },
        {
            "AdRevenue": 22089795,
            "Brand": "EMAMI H&T MANTRA MASALA"
        },
        {
            "AdRevenue": 22089067,
            "Brand": "PONDS BRIGHT BEAUTY"
        },
        {
            "AdRevenue": 21622350,
            "Brand": "AP_EXTERIORS_MAIN"
        },
        {
            "AdRevenue": 20955330,
            "Brand": "OTRIVIN."
        },
        {
            "AdRevenue": 20928060,
            "Brand": "GLOW & LOVELY MV SACHET"
        },
        {
            "AdRevenue": 20848780,
            "Brand": "HAPPY HAPPY COOKIES-PBPL"
        },
        {
            "AdRevenue": 20759680,
            "Brand": "GOOD KNIGHT GOLD FLASH"
        },
        {
            "AdRevenue": 20431880,
            "Brand": "FORTUNE RICE BRAN HEALTH OIL"
        },
        {
            "AdRevenue": 20299840,
            "Brand": "GODREJ NO.1"
        },
        {
            "AdRevenue": 20217860,
            "Brand": "COLGATE MAX FRESH"
        },
        {
            "AdRevenue": 20159553,
            "Brand": "HIMALAYA PURIFYING NEEM FACE WASH"
        },
        {
            "AdRevenue": 20148165,
            "Brand": "COLGATE VISIBLE WHITE"
        }
    ],
    "brandsAndAdvertiserRankingByAdvertiser": [
        {
            "AdRevenue": 118634085.5998,
            "Advertiser": "GLAXO SMITHKLINE ASIA"
        },
        {
            "AdRevenue": 90149214,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 79618991,
            "Advertiser": "PARLE BISCUITS PRIVATE LTD(MUM)"
        },
        {
            "AdRevenue": 75920105,
            "Advertiser": "HEAD DIGITAL WORKS PRIVATE LIMITED"
        },
        {
            "AdRevenue": 71031929.85,
            "Advertiser": "COCA COLA INDIA PVT. LTD"
        },
        {
            "AdRevenue": 64000021,
            "Advertiser": "MATRIMONY. COM LTD"
        },
        {
            "AdRevenue": 57325191,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 54272200,
            "Advertiser": "EVEREST FOOD PRODUCTS PVT LTD"
        },
        {
            "AdRevenue": 52731776.150000006,
            "Advertiser": "COCA COLA INDIA PVT. LTD"
        },
        {
            "AdRevenue": 52264334,
            "Advertiser": "INDIAN OIL CORPORATION LT"
        },
        {
            "AdRevenue": 49251130,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 48979860,
            "Advertiser": "STAR INDIA PVT.LTD."
        },
        {
            "AdRevenue": 48010052,
            "Advertiser": "HYUNDAI MOTOR INDIA LTD.(NEW DELHI)"
        },
        {
            "AdRevenue": 47619474,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 47487112.04,
            "Advertiser": "ONE 97 COMMUNICATIONS LTD."
        },
        {
            "AdRevenue": 46037347.5,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 44757220,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 44354419.5,
            "Advertiser": "SAMSUNG INDIA ELECTRONICS PVT LTD"
        },
        {
            "AdRevenue": 43347920,
            "Advertiser": "MALABAR GOLD PVT LTD"
        },
        {
            "AdRevenue": 42023115.73,
            "Advertiser": "PEPSICO INDIA HOLDI (DEL)"
        },
        {
            "AdRevenue": 41762958.5,
            "Advertiser": "MONDELEZ INDIA FOODS LTD"
        },
        {
            "AdRevenue": 40949409,
            "Advertiser": "BERGER PAINTS INDIA LTD"
        },
        {
            "AdRevenue": 40837683.5,
            "Advertiser": "ITC LIMITED (BRAND - SUNRISE)"
        },
        {
            "AdRevenue": 39483252,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 38947865,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 38861323.989999995,
            "Advertiser": "PEPSICO INDIA HOLDI (DEL)"
        },
        {
            "AdRevenue": 38838466.194,
            "Advertiser": "GLAXO SMITHKLINE ASIA"
        },
        {
            "AdRevenue": 38117924,
            "Advertiser": "PARLE BISCUITS PRIVATE LTD(MUM)"
        },
        {
            "AdRevenue": 37946081.5,
            "Advertiser": "ITC LIMITED (BRAND - SUNRISE)"
        },
        {
            "AdRevenue": 37275389,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 36341879.1,
            "Advertiser": "BRITANNIA INDUSTRIES LTD"
        },
        {
            "AdRevenue": 35102630.4,
            "Advertiser": "NESTLE INDIA LTD (DEL)"
        },
        {
            "AdRevenue": 33941127,
            "Advertiser": "FASHNEAR TECHNOLOGIES PVT LTD(BANG)"
        },
        {
            "AdRevenue": 33106538,
            "Advertiser": "GALACTUS FUNWARE TECHNOLOGY PVT LTD"
        },
        {
            "AdRevenue": 32494266,
            "Advertiser": "PROCTER & GAMBLE HOME PROD PVT LTD("
        },
        {
            "AdRevenue": 31627109.720000003,
            "Advertiser": "ITC LIMITED (BLR)"
        },
        {
            "AdRevenue": 30619200,
            "Advertiser": "HERSHEY INDIA PVT LTD"
        },
        {
            "AdRevenue": 30500440,
            "Advertiser": "VICCO LABORATORIES (VICCO PRDTS, BOM) LI"
        },
        {
            "AdRevenue": 30181189,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 29744984,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 29583904,
            "Advertiser": "LIFE INSURANCE (MUM)"
        },
        {
            "AdRevenue": 29145010.5,
            "Advertiser": "ASIAN PAINTS LIMITED"
        },
        {
            "AdRevenue": 29052146,
            "Advertiser": "PIRAMAL CAPITAL & HOUSING FINANCE L"
        },
        {
            "AdRevenue": 29050595,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 29019339,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 28763919.024,
            "Advertiser": "LENSKART SOLUTIONS PVT LTD (DELHI)"
        },
        {
            "AdRevenue": 28689367,
            "Advertiser": "COLGATE PALMOLIVE (I) LTD"
        },
        {
            "AdRevenue": 28238589,
            "Advertiser": "L`OREAL INDIA PVT LTD"
        },
        {
            "AdRevenue": 27852360,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 27765828,
            "Advertiser": "PEOPLE INTERACTIVE (MUM)"
        },
        {
            "AdRevenue": 27524856,
            "Advertiser": "NESTLE INDIA LTD (DEL)"
        },
        {
            "AdRevenue": 27288675.5,
            "Advertiser": "FERRERO INDIA LTD (PUNE)"
        },
        {
            "AdRevenue": 27162200,
            "Advertiser": "BERGER PAINTS INDIA LTD"
        },
        {
            "AdRevenue": 27138644,
            "Advertiser": "L`OREAL INDIA PVT LTD"
        },
        {
            "AdRevenue": 27026976,
            "Advertiser": "DALMIA CEMENT (BHARAT) LTD (DELHI)"
        },
        {
            "AdRevenue": 26947850,
            "Advertiser": "KFC INDIA MARKETING PRIVATE LTD(HAR"
        },
        {
            "AdRevenue": 26859618,
            "Advertiser": "FERRERO INDIA LTD (PUNE)"
        },
        {
            "AdRevenue": 26791437.66,
            "Advertiser": "NESTLE INDIA LTD (DEL)"
        },
        {
            "AdRevenue": 26657224,
            "Advertiser": "L`OREAL INDIA PVT LTD"
        },
        {
            "AdRevenue": 26480135,
            "Advertiser": "BANDHAN BANK LTD (KOL)"
        },
        {
            "AdRevenue": 26350229.687999994,
            "Advertiser": "MARUTI SUZUKI INDIA LTD."
        },
        {
            "AdRevenue": 26188592.76,
            "Advertiser": "TLG INDIA PVT LTD (HAR)"
        },
        {
            "AdRevenue": 25762570.5,
            "Advertiser": "MONDELEZ INDIA FOODS LTD"
        },
        {
            "AdRevenue": 25363673,
            "Advertiser": "SHEELA FOAM LTD (UP)"
        },
        {
            "AdRevenue": 25359876,
            "Advertiser": "AMAZON SELLER SERVICES PVT LTD"
        },
        {
            "AdRevenue": 25308826.5,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 25042226,
            "Advertiser": "ABBOTT HEALTHCARE PRIVATE LIMITED"
        },
        {
            "AdRevenue": 24849462.25,
            "Advertiser": "NESTLE INDIA LTD (DEL)"
        },
        {
            "AdRevenue": 24691830,
            "Advertiser": "JUBILANT FOODWORKS LTD."
        },
        {
            "AdRevenue": 24543376,
            "Advertiser": "EMAMI AGROTECH LIMITED"
        },
        {
            "AdRevenue": 24289606,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 24233500,
            "Advertiser": "J.D. MARKETING P LTD(KOL)"
        },
        {
            "AdRevenue": 24103050,
            "Advertiser": "KRBL LTD"
        },
        {
            "AdRevenue": 23974660,
            "Advertiser": "HERSHEY INDIA PVT LTD"
        },
        {
            "AdRevenue": 23920011.597,
            "Advertiser": "GLAXO SMITHKLINE ASIA"
        },
        {
            "AdRevenue": 23787502,
            "Advertiser": "GODREJ CONSUMER PRODUCTS"
        },
        {
            "AdRevenue": 23672624,
            "Advertiser": "PHONEPE INSURANCE BROKING SERVICES("
        },
        {
            "AdRevenue": 23627164.400000002,
            "Advertiser": "G.D.PHARMACEUTICALS PVT LTD"
        },
        {
            "AdRevenue": 23547594.240000002,
            "Advertiser": "MARUTI SUZUKI INDIA LTD."
        },
        {
            "AdRevenue": 23393939,
            "Advertiser": "PRAVIN MASALEWALE"
        },
        {
            "AdRevenue": 23329976,
            "Advertiser": "INDIRA IVF HOSPITAL PVT. LTD (MUM)"
        },
        {
            "AdRevenue": 23212910,
            "Advertiser": "TITAN COMPANY LTD (BANG)"
        },
        {
            "AdRevenue": 23173408,
            "Advertiser": "JOHNSON & JOHNSON PRIVATE LIMITED"
        },
        {
            "AdRevenue": 23170126,
            "Advertiser": "ULTRATECH CEMENT LTD MUM"
        },
        {
            "AdRevenue": 22846548,
            "Advertiser": "NUTRICIA INTERNATIONAL PRIVATE LTD"
        },
        {
            "AdRevenue": 22216675.82,
            "Advertiser": "UNICHARM INDIA PVT LTD"
        },
        {
            "AdRevenue": 22178000,
            "Advertiser": "VASANTH & CO."
        },
        {
            "AdRevenue": 22094462,
            "Advertiser": "ADANI WILMAR LTD.(AHMED)"
        },
        {
            "AdRevenue": 22089795,
            "Advertiser": "EMAMI AGROTECH LIMITED"
        },
        {
            "AdRevenue": 22089067,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 21622350,
            "Advertiser": "ASIAN PAINTS LIMITED"
        },
        {
            "AdRevenue": 20955330,
            "Advertiser": "GLAXOSMITHKLINE CONSUMER PVT LTD(HA"
        },
        {
            "AdRevenue": 20928060,
            "Advertiser": "HINDUSTAN UNILEVERS LTD"
        },
        {
            "AdRevenue": 20848780,
            "Advertiser": "PARLE BISCUITS PRIVATE LTD(MUM)"
        },
        {
            "AdRevenue": 20759680,
            "Advertiser": "GODREJ CONSUMER PRODUCTS"
        },
        {
            "AdRevenue": 20431880,
            "Advertiser": "ADANI WILMAR LTD.(AHMED)"
        },
        {
            "AdRevenue": 20299840,
            "Advertiser": "GODREJ CONSUMER PRODUCTS"
        },
        {
            "AdRevenue": 20217860,
            "Advertiser": "COLGATE PALMOLIVE (I) LTD"
        },
        {
            "AdRevenue": 20159553,
            "Advertiser": "HIMALAYA WELLNESS COMPANY (BANG)"
        },
        {
            "AdRevenue": 20148165,
            "Advertiser": "COLGATE PALMOLIVE (I) LTD"
        }
    ],
    "brandsAndAdvertiserRankingByAgency": [
        {
            "AdRevenue": 118634085.59979999,
            "Agency": "TLG INDIA PVT LTD (HAR)"
        },
        {
            "AdRevenue": 90149214,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 79618991,
            "Agency": "TLG INDIA PVT LTD (MUM)"
        },
        {
            "AdRevenue": 75920105,
            "Agency": "MATRIX PUBLICITIES & MEDIA (KAR)"
        },
        {
            "AdRevenue": 71031929.85,
            "Agency": "MEDIACOM COMM (HAR)"
        },
        {
            "AdRevenue": 64000021,
            "Agency": "MATRIX PUBLICITIES & MEDIA (KAR)"
        },
        {
            "AdRevenue": 57325191,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 54272200,
            "Agency": "EVEREST FOOD PRODUCTS PVT LTD"
        },
        {
            "AdRevenue": 52731776.150000006,
            "Agency": "MEDIACOM COMM (HAR)"
        },
        {
            "AdRevenue": 52264334,
            "Agency": "MADISON COMM (BOM)"
        },
        {
            "AdRevenue": 49251130,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 48979860,
            "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
        },
        {
            "AdRevenue": 48010052,
            "Agency": "HAVAS MEDIA INDIA PVT. LTD. (HAR)"
        },
        {
            "AdRevenue": 47619474,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 47487112.04,
            "Agency": "GROUPM MEDIA INDIA PVT LTD (HAR)"
        },
        {
            "AdRevenue": 46037347.5,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 44757220,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 44354419.5,
            "Agency": "FCB ULKA ADVT(HAR)"
        },
        {
            "AdRevenue": 43347920,
            "Agency": "IMC ADVERTISING PRIVATE LTD"
        },
        {
            "AdRevenue": 42023115.73,
            "Agency": "MARKETGATE DIMENSIONS RES. INDIA PV"
        },
        {
            "AdRevenue": 41762958.5,
            "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
        },
        {
            "AdRevenue": 40949409,
            "Agency": "GROUPM MEDIA INDIA PVT. LTD (KOL)"
        },
        {
            "AdRevenue": 40837683.5,
            "Agency": "STRATEGY ADVTG & MKTG (KOLKATA)"
        },
        {
            "AdRevenue": 39483252,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 38947865,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 38861323.989999995,
            "Agency": "MARKETGATE DIMENSIONS RES. INDIA PV"
        },
        {
            "AdRevenue": 38838466.194000006,
            "Agency": "TLG INDIA PVT LTD (HAR)"
        },
        {
            "AdRevenue": 38117924,
            "Agency": "TLG INDIA PVT LTD (MUM)"
        },
        {
            "AdRevenue": 37946081.5,
            "Agency": "STRATEGY ADVERTISING & MKTG. (P) LTD."
        },
        {
            "AdRevenue": 37275389,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 36341879.1,
            "Agency": "GROUPM MEDIA INDIA PVT LTD (BLR)"
        },
        {
            "AdRevenue": 35102630.400000006,
            "Agency": "TLG INDIA PVT LTD (HAR)"
        },
        {
            "AdRevenue": 33941127,
            "Agency": "MATRIX PUBLICITIES & MEDIA (KAR)"
        },
        {
            "AdRevenue": 33106538,
            "Agency": "INITIATIVE MEDIA (BLR)"
        },
        {
            "AdRevenue": 32494266,
            "Agency": "MEDIACOM COMM (MUM)"
        },
        {
            "AdRevenue": 31627109.720000003,
            "Agency": "INITIATIVE MEDIA (BLR)"
        },
        {
            "AdRevenue": 30619200,
            "Agency": "ORION MEDIA TRADING (INDIA) LLP"
        },
        {
            "AdRevenue": 30500440,
            "Agency": "MODERN ADVTG AGENCY (MUM)"
        },
        {
            "AdRevenue": 30181189,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 29744984,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 29583904,
            "Agency": "DDB MUDRA MAX PVT LTD (MUM)"
        },
        {
            "AdRevenue": 29145010.5,
            "Agency": "MADISON COMM (BOM)"
        },
        {
            "AdRevenue": 29052146,
            "Agency": "VIBRANT ADVTG P LTD(MUM)"
        },
        {
            "AdRevenue": 29050595,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 29019339,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 28763919.024,
            "Agency": "TLG INDIA PVT LTD (MUM)"
        },
        {
            "AdRevenue": 28689367,
            "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
        },
        {
            "AdRevenue": 28238589,
            "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
        },
        {
            "AdRevenue": 27852360,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 27765828,
            "Agency": "MADISON COMM (BOM)"
        },
        {
            "AdRevenue": 27524856,
            "Agency": "TLG INDIA PVT LTD (HAR)"
        },
        {
            "AdRevenue": 27288675.5,
            "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
        },
        {
            "AdRevenue": 27162200,
            "Agency": "MATRIX PUBLICITIES & MEDIA (HAR)"
        },
        {
            "AdRevenue": 27138644,
            "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
        },
        {
            "AdRevenue": 27026976,
            "Agency": "ARENA INDIA PVT LTD (HAR)"
        },
        {
            "AdRevenue": 26947850,
            "Agency": "GROUPM MEDIA INDIA PVT LTD (HAR)"
        },
        {
            "AdRevenue": 26859618,
            "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
        },
        {
            "AdRevenue": 26791437.660000004,
            "Agency": "TLG INDIA PVT LTD (HAR)"
        },
        {
            "AdRevenue": 26657224,
            "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
        },
        {
            "AdRevenue": 26480135,
            "Agency": "MADISON COMM (KOL)"
        },
        {
            "AdRevenue": 26350229.687999994,
            "Agency": "DENTSU ADVTG & MEDIA SER (I) P (HAR"
        },
        {
            "AdRevenue": 26188592.759999998,
            "Agency": "INITIATIVE MEDIA (HAR)"
        },
        {
            "AdRevenue": 25762570.5,
            "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
        },
        {
            "AdRevenue": 25363673,
            "Agency": "HAVAS MEDIA INDIA PVT. LTD. (HAR)"
        },
        {
            "AdRevenue": 25359876,
            "Agency": "INITIATIVE MEDIA (BLR)"
        },
        {
            "AdRevenue": 25308826.5,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 25042226,
            "Agency": "TLG INDIA PVT LTD (MUM)"
        },
        {
            "AdRevenue": 24849462.249999996,
            "Agency": "TLG INDIA PVT LTD (HAR)"
        },
        {
            "AdRevenue": 24691830,
            "Agency": "HAVAS MEDIA INDIA PVT. LTD. (HAR)"
        },
        {
            "AdRevenue": 24543376,
            "Agency": "GROUPM MEDIA INDIA PVT. LTD (KOL)"
        },
        {
            "AdRevenue": 24289606,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 24233500,
            "Agency": "AD- MERCHANT (I) PVT LTD"
        },
        {
            "AdRevenue": 24103050,
            "Agency": "INITIATIVE MEDIA (HAR)"
        },
        {
            "AdRevenue": 23974660,
            "Agency": "MCCANN ERICKSON (BOM)"
        },
        {
            "AdRevenue": 23920011.597000003,
            "Agency": "TLG INDIA PVT LTD (HAR)"
        },
        {
            "AdRevenue": 23787502,
            "Agency": "MADISON COMM (BOM)"
        },
        {
            "AdRevenue": 23672624,
            "Agency": "FCB ULKA ADVT(BLR)"
        },
        {
            "AdRevenue": 23627164.399999995,
            "Agency": "FREQUENCY ADVERTISING (KOL)"
        },
        {
            "AdRevenue": 23547594.24,
            "Agency": "DENTSU ADVTG & MEDIA SER (I) P (HAR"
        },
        {
            "AdRevenue": 23393939,
            "Agency": "PUBLICITY PARLOUR"
        },
        {
            "AdRevenue": 23329976,
            "Agency": "MADISON COMM (BOM)"
        },
        {
            "AdRevenue": 23212910,
            "Agency": "MADISON COMM (BLR)"
        },
        {
            "AdRevenue": 23173408,
            "Agency": "MCCANN ERICKSON (BOM)"
        },
        {
            "AdRevenue": 23170126,
            "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
        },
        {
            "AdRevenue": 22846548,
            "Agency": "MATRIX PUBLICITIES & MEDIA (MUM)"
        },
        {
            "AdRevenue": 22216675.819999997,
            "Agency": "DENTSU ADVTG & MEDIA SER (I) P (HAR"
        },
        {
            "AdRevenue": 22178000,
            "Agency": "VASANTH & CO (NEW)"
        },
        {
            "AdRevenue": 22094462,
            "Agency": "DDB MUDRA MAX PVT LTD (MUM)"
        },
        {
            "AdRevenue": 22089795,
            "Agency": "GROUPM MEDIA INDIA PVT. LTD (KOL)"
        },
        {
            "AdRevenue": 22089067,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 21622350,
            "Agency": "OUT-SEL PROMOTIONS INDIA PRIVATE LT"
        },
        {
            "AdRevenue": 20955330,
            "Agency": "TLG INDIA PVT LTD (HAR)"
        },
        {
            "AdRevenue": 20928060,
            "Agency": "GROUPM MEDIA (I) PVT LTD (FULCRUM)"
        },
        {
            "AdRevenue": 20848780,
            "Agency": "TLG INDIA PVT LTD (MUM)"
        },
        {
            "AdRevenue": 20759680,
            "Agency": "MADISON COMM (BOM)"
        },
        {
            "AdRevenue": 20431880,
            "Agency": "DDB MUDRA MAX PVT LTD (MUM)"
        },
        {
            "AdRevenue": 20299840,
            "Agency": "MADISON COMM (BOM)"
        },
        {
            "AdRevenue": 20217860,
            "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
        },
        {
            "AdRevenue": 20159553,
            "Agency": "GROUPM MEDIA INDIA PVT LTD (BLR)"
        },
        {
            "AdRevenue": 20148165,
            "Agency": "GROUPM MEDIA INDIA PVT LTD(MUM)"
        }
    ]
}

const secodLevelRowsForChannel = [
    {
        "key": "Sun",
        "values": [
            {
                "key": "Sun TV",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 6704.282997976989
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 7054.951795786619
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 6345.617098093033
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 6261.995500437915
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 6734.968603180721
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 7124.32049747929
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 9589.837003672495
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 9245.50080470182
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 6857.893301337957
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 7022.558698166162
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 6573.533704029396
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 5946.866098470986
                    }
                ],
                "total": 85462.32610333338
            },
            {
                "key": "Gemini TV",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2308.1620027400786
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2288.686802676202
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2068.942200675934
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2032.8244001563726
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2021.1365004275867
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2011.426200630529
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2330.279700767169
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2632.065101279557
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1715.9483007629606
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1814.301100008117
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1579.5966011806668
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1177.5371008073344
                    }
                ],
                "total": 23980.906012112508
            },
            {
                "key": "KTV",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1527.5210031014867
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1839.1347032249905
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1547.109300586977
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1539.4563011494465
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1709.1014009546489
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1693.2937008515
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2535.51440029731
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1953.394901664462
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1390.3695011069067
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1449.7441008305177
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1330.4182010544464
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1110.1865008287132
                    }
                ],
                "total": 19625.244015651406
            },
            {
                "key": "Gemini Movies",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1482.827003402519
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1487.7628038241528
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1501.4079012777656
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1596.2348011923023
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1531.208701305557
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1585.0759017460514
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1973.7678005683701
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2096.180801176466
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1316.0125009803742
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1338.9537010439672
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1596.462001522712
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1112.680700649973
                    }
                ],
                "total": 18618.57461869021
            },
            {
                "key": "Udaya TV",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1586.863001752412
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1530.1339021039312
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1587.8053019508443
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1598.8858999061777
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1489.3894006290357
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1599.6994001152343
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1929.7137016576598
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1945.4655008762202
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1220.171801645265
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1162.7561008740377
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 998.2276004747036
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 822.4332002333249
                    }
                ],
                "total": 17471.544812218846
            },
            {
                "key": "Udaya Movies",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1000.3690029702848
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1016.3056025917176
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 991.5245013089152
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 977.8348011731869
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1033.6626017807212
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 977.0366011451406
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1352.8214013657998
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1313.7728015198954
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 942.3060014756629
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 827.0450008336338
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 883.848100891686
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 726.9345010341494
                    }
                ],
                "total": 12043.460918090794
            },
            {
                "key": "Sun Life",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 577.9890012345277
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 581.8691014900105
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 538.585100791126
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 538.5150007909979
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 586.0558012964902
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 559.3160009410349
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 842.0159018212507
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 735.0449015935301
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 556.5509009079251
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 557.2139008602535
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 510.8492011151975
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 400.78140104096383
                    }
                ],
                "total": 6984.786213883308
            },
            {
                "key": "Gemini life",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 461.53900194121525
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 461.71870143699925
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 483.03740097577247
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 496.78960118360555
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 517.6598010384405
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 530.4592009818371
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 688.7625016093953
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 807.0432012604142
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 582.2268016047819
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 545.3845011161029
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 513.1170008990011
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 440.84730111459066
                    }
                ],
                "total": 6528.585015162156
            },
            {
                "key": "Adithya TV",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 477.5680014331592
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 563.5628017801791
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 492.7298012175597
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 474.51180120860226
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 514.2598007536144
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 557.8024012307869
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 765.7452016627649
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 541.8015009042574
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 451.70490092237014
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 491.83360103389714
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 386.125700978213
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 337.8931008465588
                    }
                ],
                "total": 6055.538613971963
            },
            {
                "key": "Sun TV HD",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 285.3070007635979
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 303.81970096894656
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 305.4924005951907
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 332.53710065223277
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 391.8253007650128
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 411.9474003286741
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 656.8237011687306
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 480.7171008171572
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 439.19760081899585
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 537.8489008150646
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 446.1736007067375
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 418.24940080125816
                    }
                ],
                "total": 5009.939209201599
            }
        ]
    },
    {
        "key": "Viacom",
        "values": [
            {
                "key": "Colors",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3179.433000875404
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3231.6238022161997
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3209.1761997634603
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3700.6116012960556
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3709.1564999323455
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3799.5832010455706
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 5183.369500451314
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 5366.028597544035
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 3792.5197996174684
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3799.441300258404
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3952.615398662514
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2769.434000216308
                    }
                ],
                "total": 45692.99290187908
            },
            {
                "key": "Colors Kannada",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1965.509003169369
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2075.27860175143
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2135.106401755882
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2230.7912008407293
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2383.9426008402224
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2376.3778995978646
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 3118.5248025661567
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 3207.137399241765
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2183.919600883717
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1747.6407008601818
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2016.3646012046374
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1600.1087005173758
                    }
                ],
                "total": 27040.70151322933
            },
            {
                "key": "Colors Cineplex Superhits",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1755.2860035564518
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1808.10630360106
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1747.2202012988564
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1792.4432010997407
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1725.2121010908158
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1335.6611004349834
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2054.8842013717513
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2173.526200691791
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1550.8704014627147
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1498.1204008330606
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1678.2992010726011
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1294.4806006270155
                    }
                ],
                "total": 20414.109917140842
            },
            {
                "key": "Colors Cineplex Bollywood",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1259.2870034911903
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1307.3621030303184
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1289.0729015078396
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1441.413501922325
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1466.1187017741904
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1445.2465016397036
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1767.0476017930632
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1899.224102100241
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1421.0099018978945
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1333.4422012859577
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1393.6695003173227
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1137.013701324846
                    }
                ],
                "total": 17159.907722084892
            },
            {
                "key": "Colors Marathi",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1068.7120016710833
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 977.0404011323117
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1139.4432005246053
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1250.1827009866247
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1379.7470009701792
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1177.0422001571205
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1522.0127009511925
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1688.6051009275325
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1406.1093010010372
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1234.6752004924201
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1243.603200885409
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 857.5246003625798
                    }
                ],
                "total": 14944.697610062096
            },
            {
                "key": "NICK(v)",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1089.0770025008824
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1147.6606024167268
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1000.4005001680925
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1006.7183008651482
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1197.3108011304867
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1100.0783015920315
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1361.2197011635872
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1283.9062012811191
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1055.7891015054774
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 759.9665008513257
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 844.7547006418463
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1053.2873004174326
                    }
                ],
                "total": 12900.169014534156
            },
            {
                "key": "Colors Cineplex",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 732.077001983067
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 846.7567029849743
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 769.7510012820931
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 848.8450017402283
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 859.9633016167936
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 694.6245015230015
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 961.727900944963
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1112.231301677144
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 815.8864013342609
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 792.0598007279041
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1026.9655015065655
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 720.0952009553439
                    }
                ],
                "total": 10180.98361827634
            },
            {
                "key": "NICK Junior(v)",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 456.2490013204515
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 504.8219015179202
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 431.5491009049583
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 444.048601007089
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 549.7295010169037
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 516.0721011161804
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 696.7003016765229
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 696.0362016300205
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 564.4869011502597
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 440.3050009740982
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 516.1369009164628
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 539.1640010043047
                    }
                ],
                "total": 6355.299514235172
            },
            {
                "key": "Sonic Nickelodeon(v)",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 546.9920015458483
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 561.0150014387254
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 388.56490082009987
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 467.7460008716589
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 539.3288008977688
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 483.78950096904737
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 633.8289011312663
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 609.7275012768223
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 444.7249008683648
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 368.83630084976903
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 378.32260060764384
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 400.6422009685193
                    }
                ],
                "total": 5823.518612245534
            },
            {
                "key": "Colors Rishtey",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 366.1330014648847
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 476.28760145301203
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 493.21900111895957
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 395.3052007810693
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 370.2536008272291
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 396.79960088353255
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 465.5789010466251
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 433.7320008751849
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 352.492400916708
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 368.29550098519394
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 385.9778005299959
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 374.76420066592254
                    }
                ],
                "total": 4878.838811548318
            }
        ]
    },
    {
        "key": "Star Network Ent",
        "values": [
            {
                "key": "STAR Plus",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 5992.5370014685905
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 6200.933503909619
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 6161.826302219473
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 5807.348702447867
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 6404.141399267712
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 6563.014598923968
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 7807.719501350017
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 9468.306999250955
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 7290.22130414471
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 5907.0434982921
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 6209.879795822781
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 5959.516497293953
                    }
                ],
                "total": 79772.48910439175
            },
            {
                "key": "STAR Maa",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 5568.466001076624
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 5600.4376019281335
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 5927.996098832693
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 6785.703100162675
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 6557.486801004445
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 5509.555700317025
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 6963.581998839334
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 6548.017301292042
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 6439.90040397644
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 5766.38790030207
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 5493.314800980035
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 5222.2868971647695
                    }
                ],
                "total": 72383.13460587629
            },
            {
                "key": "STAR Vijay",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4289.021002214402
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4663.562800171436
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4477.349700310384
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4458.860800158931
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4372.142300586216
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4005.032501531765
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 5125.940699563944
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 5400.9326008404605
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 4253.799901523627
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3837.012899566442
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3582.8557990789413
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3290.7921012965962
                    }
                ],
                "total": 51757.303106843145
            },
            {
                "key": "STAR Pravah",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3038.4940040104557
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2886.7423032156657
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3344.6901006978005
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4085.5733007809613
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3976.4461008503567
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3620.8233981619123
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 4461.63819835239
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 4332.206400922267
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 4592.685799926985
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3781.551400306402
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3391.695400219527
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3224.132000820944
                    }
                ],
                "total": 44736.67840826567
            },
            {
                "key": "STAR Jalsha",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2646.221001508413
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2709.359102196293
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2657.708702015836
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2835.350500578119
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3416.744900208898
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3865.76879892929
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 3334.9727026369364
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 3820.2205023444258
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 3561.070699514996
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3304.398898766369
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2894.102501305548
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2465.0970984201995
                    }
                ],
                "total": 37511.01540842532
            },
            {
                "key": "Asianet",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2388.9460011884803
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2723.717700580135
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2503.75630127755
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2281.607501284634
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2482.199901957043
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2349.26950113997
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2396.0806002781756
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2120.837100635894
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1984.4203000643392
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1898.9975009016634
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1616.2788018287538
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1397.885401434367
                    }
                ],
                "total": 26143.996612571005
            },
            {
                "key": "STAR Utsav Movies",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1755.4160028245533
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2064.14660342352
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1905.2331018442055
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2170.8201012618665
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2190.989001163587
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2024.256401004648
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2339.6074014626793
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2495.1799020505277
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1771.986301205994
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1774.989802303964
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1643.1119009784306
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1434.5932007273295
                    }
                ],
                "total": 23570.329720251306
            },
            {
                "key": "STAR Gold",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1329.896002265159
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1538.1097032022371
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1399.0664016481023
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1478.6078019430133
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1423.6412006994287
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1452.1776015555952
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1576.546401777996
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1818.4719016198069
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1392.9256018431188
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1213.9285011727334
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1114.3429009440588
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1136.9852012389165
                    }
                ],
                "total": 16874.699219910166
            },
            {
                "key": "Star Suvarna",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1255.666002629092
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1305.4778025827836
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1302.255401267379
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1406.2319005532772
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1362.8812013547285
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1304.5876014207024
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1578.1600012779236
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1832.4292015687388
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1466.5892015501158
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1431.2787010357715
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1342.919601177331
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1104.1095003320806
                    }
                ],
                "total": 16692.586116749924
            },
            {
                "key": "Star Maa Movies",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1225.307003044989
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1391.204903460457
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1279.5619015670381
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1414.1544014427345
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1449.0833004158922
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1282.123201356968
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1736.7563014827901
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1381.367402115371
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1436.7125009244774
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1453.5475009360816
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1210.014500958845
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1052.2075012559071
                    }
                ],
                "total": 16312.040418961551
            }
        ]
    },
    {
        "key": "ZEEL",
        "values": [
            {
                "key": "Zee Telugu",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3882.037000807468
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4070.9357013450935
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4532.269103278057
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4259.728799764998
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4633.148100881837
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4576.293802224565
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 5511.029701326683
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 5627.604397193529
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 4266.831998295151
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4424.205598983448
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4306.613701617345
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4093.039800066501
                    }
                ],
                "total": 54183.737705784675
            },
            {
                "key": "Zee Kannada",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3562.7570031024516
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4163.600603875704
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4248.196500235441
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4220.526900706813
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4019.155700225616
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4443.010999049991
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 5142.508502799668
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 5324.286800810136
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 4183.403402889293
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3925.160599185154
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3280.3087008008733
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2929.8876999906497
                    }
                ],
                "total": 49442.80341367179
            },
            {
                "key": "Zee TV",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2991.71300313517
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3082.9614008242497
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3514.963302002987
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3756.206702406984
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3683.230599365139
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3870.0125018730396
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 4774.26640068047
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 5239.396400397352
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 3467.309300014458
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3585.9436993960408
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3433.0802014415385
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3202.6707995270262
                    }
                ],
                "total": 44601.754311064455
            },
            {
                "key": "Zee Bangla",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2423.4870024503907
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2475.0757999670604
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2375.0374004363184
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2543.814099894851
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2636.851100931257
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3036.1476000426337
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 3378.5453009190824
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 3652.541403027899
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 3074.5508985741035
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2780.6968992285547
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2623.7731996996736
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2227.6222003354997
                    }
                ],
                "total": 33228.142905507324
            },
            {
                "key": "Zee Tamil",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1871.6200026682345
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2204.814701995463
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2103.0526995431865
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2402.0215012308327
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2253.3839011865202
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2278.3943007824128
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 3447.210400498705
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 3414.269001228269
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2597.481700204313
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2469.669701820356
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2370.259600231526
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2417.7571006799117
                    }
                ],
                "total": 29829.93461206973
            },
            {
                "key": "Zee Anmol Cinema",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2242.7890041870996
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2467.7097030886216
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2132.2326012039266
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2391.144800120521
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2540.1424007117166
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2485.672701161122
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 3016.8385021763315
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2906.0693018047386
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2018.340501290877
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1482.229601177336
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1337.3718011646342
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1352.6669010396
                    }
                ],
                "total": 26373.207819126525
            },
            {
                "key": "Zee Cinema",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1417.3300021166215
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1644.919203140831
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1674.4128008124171
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1835.9325016143994
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1984.7914009922606
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1609.2321018291914
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2003.04070252573
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2142.301302315158
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1565.2166007830965
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1166.0376013286805
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1118.6539009277985
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1251.1654002179348
                    }
                ],
                "total": 19413.03351860412
            },
            {
                "key": "Zee Marathi",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1287.0480019449024
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1673.288401989179
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1629.0262011947925
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1563.0982997150713
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1593.3901013667419
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1566.715001059296
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1830.9611012158348
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2015.4111005588202
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1386.227001430234
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1340.2678000472224
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1109.632300290803
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 979.176400563767
                    }
                ],
                "total": 17974.241711376664
            },
            {
                "key": "Big Magic",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1006.5900025038281
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1192.1283032554202
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1280.336201672908
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1441.2926014487748
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1334.805500979768
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1337.2661007398274
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1799.480001328513
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1900.0200009180699
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1212.318500598427
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1286.4739005194278
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1183.8298009927385
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 874.400000984584
                    }
                ],
                "total": 15848.940915942287
            },
            {
                "key": "Zee Cinemalu",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1130.6440031237435
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1271.608403496095
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1236.259300998412
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1381.047601324739
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1444.5304016183
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1342.591902015898
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1870.0561017629225
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1660.8916021354962
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1202.0809014851693
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1229.4648015337298
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1073.0423015634879
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 977.2201013899175
                    }
                ],
                "total": 15819.43742244791
            }
        ]
    },
    {
        "key": "Sony Network Ent",
        "values": [
            {
                "key": "SONY SAB",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4237.068998150993
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4646.337600965751
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4682.1699004536495
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4629.844301669858
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4362.071601132862
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 4125.1479013348
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 5089.012700042222
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 5429.536399048986
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 3651.6500003347173
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3458.432299992768
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3327.172199739609
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 3579.005498283077
                    }
                ],
                "total": 51217.44940114929
            },
            {
                "key": "Sony Entertainment Television",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1773.7470029052347
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1713.4189029773697
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1755.133501757402
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1918.9611005696934
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1953.443200926995
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2006.4933012106922
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2439.25940256333
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2750.675200474914
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2009.089000931941
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1701.8713009781204
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1482.610800026625
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1369.457601096481
                    }
                ],
                "total": 22874.160316418798
            },
            {
                "key": "Sony Wah",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 859.439002330415
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 937.7646024273708
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1028.5365010151
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1211.0973009791633
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1645.6309009221732
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1830.206900893012
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1979.2802013753244
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2104.6361012767884
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 2005.9246021099389
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2406.5265009331924
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 2156.4564996480767
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1663.1845005284922
                    }
                ],
                "total": 19828.683614439047
            },
            {
                "key": "Sony MAX(v)",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1217.6850029326743
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1350.5346031953231
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1393.4554015697213
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1436.397901885357
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1534.2875016497492
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1694.6983008312527
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1702.7305014078738
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1786.3714021166961
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1333.4509017693345
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1231.9194004939054
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1120.2096011191534
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1291.0397004786355
                    }
                ],
                "total": 17092.780219449676
            },
            {
                "key": "Sony Pal",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 987.2440032903105
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 917.7360023965448
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1031.075901488468
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 1014.2935014643735
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 900.3991010161699
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 857.9469008618908
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1226.595001600741
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1370.3113019401208
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 912.9385006782541
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 973.9042008172619
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 891.9344003144142
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 822.3616011777558
                    }
                ],
                "total": 11906.740417046305
            },
            {
                "key": "Sony YAY(v)",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 744.953002146096
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 879.3859021933749
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 574.777000874361
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 517.1929009713931
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 570.6811011583195
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 609.0541009805165
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 975.1426012196753
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 1002.7196018298273
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 721.7351012232248
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 617.7259009687696
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 559.0028009047965
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 660.2755010195542
                    }
                ],
                "total": 8432.645515489909
            },
            {
                "key": "Sony MAX 2",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 547.1430021690903
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 600.0019020286854
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 573.3403010528127
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 690.595401532235
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 762.7663009766547
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 711.2797015146716
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 774.9417012697158
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 893.7320015448131
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 637.8577010746376
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 581.7350014300318
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 570.6661013204139
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 549.1692007416714
                    }
                ],
                "total": 7893.228316655433
            },
            {
                "key": "Sony Marathi",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 504.23000129230786
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 551.2384018634912
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 551.0765014208155
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 621.5249008869869
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 650.4764011891093
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 608.0049008905771
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 723.1605008943006
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 799.9066017608566
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 575.4951012034871
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 581.8612011644873
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 517.0461011571024
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 476.83690089585434
                    }
                ],
                "total": 7160.857514619376
            },
            {
                "key": "Sony Aath",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 349.93300133466255
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 436.73590176645666
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 430.5895010421227
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 424.45530096959556
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 440.7635007849749
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 441.93640100346965
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 513.5288011013472
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 491.58840105133277
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 332.650800749514
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 353.2139008813974
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 317.3242007285953
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 286.2628007255262
                    }
                ],
                "total": 4818.982512138995
            },
            {
                "key": "Sony Entertainment Television HD",
                "values": [
                    {
                        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 131.38100060645957
                    },
                    {
                        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 104.77030033600022
                    },
                    {
                        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 109.19180036487523
                    },
                    {
                        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 139.33750033169053
                    },
                    {
                        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 177.89620041911257
                    },
                    {
                        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 219.25950062909396
                    },
                    {
                        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 275.6889004028126
                    },
                    {
                        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 263.54270033301145
                    },
                    {
                        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
                        "value": 183.89400035719154
                    },
                    {
                        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 135.40940026793396
                    },
                    {
                        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 140.07260027882148
                    },
                    {
                        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                        "value": 107.21240024239523
                    }
                ],
                "total": 1987.6563045693983
            }
        ]
    }
]

const firstLevelRowsForNetwork = [
    {
        "key": "Sun",
        "values": [
            {
                "key": "Sat Oct 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 28086.12332664449
            },
            {
                "key": "Tue Nov 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 27031.712725981197
            },
            {
                "key": "Thu Dec 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 19385.872519476055
            },
            {
                "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 20318.79203651601
            },
            {
                "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 21511.774135629385
            },
            {
                "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 19684.21731566448
            },
            {
                "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 19617.16901540485
            },
            {
                "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 20601.547220970067
            },
            {
                "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 21108.95771391807
            },
            {
                "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                "value": 19641.6745138947
            },
            {
                "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                "value": 18535.09932012576
            },
            {
                "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                "value": 19808.766315617548
            }
        ],
        "total": 255331.7061598426
    },
    {
        "key": "ZEEL",
        "values": [
            {
                "key": "Sat Oct 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 45253.84403682615
            },
            {
                "key": "Tue Nov 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 47045.92943518861
            },
            {
                "key": "Thu Dec 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 34527.66422285568
            },
            {
                "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 29891.318053122028
            },
            {
                "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 33493.062153248495
            },
            {
                "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 33652.10452827393
            },
            {
                "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 35911.11402609065
            },
            {
                "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 36282.70232635604
            },
            {
                "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 36817.84552783486
            },
            {
                "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                "value": 33047.71812029058
            },
            {
                "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                "value": 29879.96502227182
            },
            {
                "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                "value": 36078.576725834675
            }
        ],
        "total": 431881.84417819354
    },
    {
        "key": "Sony Network Ent",
        "values": [
            {
                "key": "Sat Oct 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 15914.28571232062
            },
            {
                "key": "Tue Nov 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 17043.353311777442
            },
            {
                "key": "Thu Dec 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 12479.049410724147
            },
            {
                "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 11443.551018036087
            },
            {
                "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 12232.306020932614
            },
            {
                "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 12258.428211298422
            },
            {
                "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 12737.392711547924
            },
            {
                "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 13151.018810491485
            },
            {
                "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 13274.342610363019
            },
            {
                "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                "value": 12126.0432081279
            },
            {
                "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                "value": 11169.521205461555
            },
            {
                "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                "value": 13976.480206815162
            }
        ],
        "total": 157805.77243789635
    },
    {
        "key": "Viacom",
        "values": [
            {
                "key": "Sat Oct 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 21557.503721715795
            },
            {
                "key": "Tue Nov 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 21844.475515261613
            },
            {
                "key": "Thu Dec 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 16142.028315934214
            },
            {
                "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 14888.131035575178
            },
            {
                "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 15570.204035474671
            },
            {
                "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 15196.920014819676
            },
            {
                "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 16338.530717171343
            },
            {
                "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 16914.956916024305
            },
            {
                "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 16043.63461481037
            },
            {
                "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                "value": 14966.92391372189
            },
            {
                "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                "value": 16213.448811526876
            },
            {
                "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                "value": 16919.794214515692
            }
        ],
        "total": 202596.55182655161
    },
    {
        "key": "Star Network Ent",
        "values": [
            {
                "key": "Sat Oct 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 46601.829421388364
            },
            {
                "key": "Tue Nov 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 49177.418028943415
            },
            {
                "key": "Thu Dec 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 42459.62282851897
            },
            {
                "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 36512.59104614542
            },
            {
                "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 38778.42574945061
            },
            {
                "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 38580.56472540383
            },
            {
                "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 40850.74702442839
            },
            {
                "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 41857.439721508585
            },
            {
                "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
                "value": 39965.80331934802
            },
            {
                "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                "value": 38095.388715875575
            },
            {
                "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                "value": 35336.787015112786
            },
            {
                "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
                "value": 42064.9961186197
            }
        ],
        "total": 490281.61371474364
    }
]

const industrialTotalForNetworks = [
    {
        "key": "Sat Oct 01 2022 05:30:00 GMT+0530 (India Standard Time)",
        "value": 157413.58621889545
    },
    {
        "key": "Tue Nov 01 2022 05:30:00 GMT+0530 (India Standard Time)",
        "value": 162142.88901715222
    },
    {
        "key": "Thu Dec 01 2022 05:30:00 GMT+0530 (India Standard Time)",
        "value": 124994.23729750907
    },
    {
        "key": "Fri Apr 01 2022 00:00:00 GMT+0530 (India Standard Time)",
        "value": 113054.38318939472
    },
    {
        "key": "Sun May 01 2022 00:00:00 GMT+0530 (India Standard Time)",
        "value": 121585.77209473577
    },
    {
        "key": "Wed Jun 01 2022 00:00:00 GMT+0530 (India Standard Time)",
        "value": 119372.23479546033
    },
    {
        "key": "Fri Jul 01 2022 00:00:00 GMT+0530 (India Standard Time)",
        "value": 125454.9534946431
    },
    {
        "key": "Mon Aug 01 2022 00:00:00 GMT+0530 (India Standard Time)",
        "value": 128807.66499535053
    },
    {
        "key": "Thu Sep 01 2022 00:00:00 GMT+0530 (India Standard Time)",
        "value": 127210.5837862743
    },
    {
        "key": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
        "value": 117877.74847191066
    },
    {
        "key": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
        "value": 111134.82137449879
    },
    {
        "key": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
        "value": 99944.20005686005
    }
]

export {
    progressBarChartSampleData,
    targetTrendSampleData,
    advertiserAndBrandRankingRailsForOnePeriod,
    totalAdvertiserAndBrandRankingRevenue,
    keyImpactMetricsDataForLinear,
    keyImpactMetricsDataForOTT,
    valueCoOrdinatesForSBConvergence,
    politifact,
    filterOptions,
    filterOptionsForTargetAchievement,
    filterOptionsForCompetetiveAdShareAnalysisPopUp,
    competetiveAdShareAnalysisSampleData,
    competetiveAdShareAnalysisSampleDataChanelWise,
    competitiveIntellingenceLinechartData,
    horizontalBarchartAdGRPData,
    railsData,
    barndAndAdvertiserRankingRails,
    competetiveAdShareAnalysisSampleDataAdx,
    competitiveIntelligenceAgGRP,
    advertisersForCompetitiveIntelligence,
    secodLevelRowsForChannel,
    firstLevelRowsForNetwork,
    industrialTotalForNetworks,
    filterOptionsForKeyContributionAnalysisCustomerOTT,
}