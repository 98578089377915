import React from "react";
import { useState ,useEffect} from "react";
import happiness from '../../../../Components/Images/task.png'
import ProductQualityExperienceCardAdoption from './ProductQualityExperienceCardAdoption'
const TaskSuccess = (props) => {
  const {ProductData}= props 
  const [ AppRating, setAppRating ] = useState([])
  const [ NPS, setNPS ] = useState([])
  const [ complaints, setComplaints ] = useState([])

  const [ AVOD, setAVOD ] = useState([])
  const [ SVOD, setSVOD ] = useState([])

  const LoadFilter = (props) => {

  const filtercomplaints = ProductData.filter(item => item.metric_name === ("crash free rate"))
  setComplaints(filtercomplaints)

  const filternps = ProductData.filter(item => item.metric_name === ("app load time"))
  setNPS(filternps)

  const filterUserbounce = ProductData.filter(item => item.metric_name === ("good video experience"))
  setAppRating(filterUserbounce)


  const filterAVOD = ProductData.filter(item => item.metric_name === ("avod good video experience"))
  setAVOD(filterAVOD)
  console.log(filterAVOD,"filterAVOD=-=-=-=-=-=-=-=-=-=--=-=-=-=--=-=-=-=-=-=-=-=");


  const filterSVOD = ProductData.filter(item => item.metric_name === ("svod good video experience"))
  setSVOD(filterSVOD)


  }

  console.log(complaints,"crash free rate");
  console.log(NPS,"app load time");
  console.log(AppRating,"good video experience");

  console.log(AVOD,"AVOD");
  console.log(SVOD,"SVOD");
  console.log(ProductData,"ProductDataProductDataProductDataProductDataProductData");


  useEffect(()=>{
    LoadFilter()
  },[ProductData])
  const popOverAllWindow = (e) => {
   // props.cardName(e)
   props.onButtonClick(e);
  }
  return (
    <div className="Product_card_executive_summary_main_div">
      <div className="Product_card_executive_summary adoption_color TaskSuccess_color">
      <div className="Product_card_executive_summary_img"><img src={happiness} /></div>
      <p className="Product_card_executive_summary_card_title">Task Success</p>
      <p className="Product_card_executive_summary_card_title_para">User Accomplishment</p>
      </div>

      {complaints.length > 0 && NPS.length > 0 &&  AppRating.length > 0 &&
       <div className="Product_card_executive_summary_card_margin">

        <div>
          <ProductQualityExperienceCardAdoption
            diifrence="24.35"
            mydata={["Crash Free Rate",`${complaints[0].l30_metric_value}%` , (((complaints[0].l30_metric_value-complaints[0].l60_metric_value)/complaints[0].l60_metric_value)*100).toFixed(2), "up"]}
            toggleData={[complaints[1].l30_metric_value,complaints[2].l30_metric_value, 0, 0, 0, 0, 0]}
            overAllData={complaints}
            IiconText={"This metric measures the percentage of app sessions that did not result in a crash or error. "}

          />
        </div>
        

        <div>
          <ProductQualityExperienceCardAdoption
            diifrence="24.35"
            mydata={["App Load Time", NPS[0].l30_metric_value , (((NPS[0].l30_metric_value-NPS[0].l60_metric_value)/NPS[0].l60_metric_value)*100).toFixed(2), "up"]}
            toggleData={[NPS[0].l30_metric_value, 0, 0, 0, 0, 0, 0]}
            overAllData={NPS}
            IiconText={"This metric measures the time it takes for an app to load and become usable for the user."}


          />
        </div>

        <div onClick={()=>{popOverAllWindow("Good Video Experience")}}>
          <ProductQualityExperienceCardAdoption
            diifrence="24.35"
            mydata={["% Good Video Experience",`${AppRating[0].l30_metric_value}%` , (((AppRating[0].l30_metric_value-AppRating[0].l60_metric_value)/AppRating[0].l60_metric_value)*100).toFixed(2), "up"]}
            overAllData={AppRating}
            toggleData={[0, 0, 0, 0, 0, 0]}
            extraText = {true}
            IiconText={"This metric measures the percentage of app sessions where the user had a good video experience, typically defined as uninterrupted playback, good video quality, quick loading and no buffering or errors."}


          />
        </div >
      </div >
      }

      
    </div>
  );
};

export default TaskSuccess;
