import React, { useState, useEffect } from "react";
import './ExecutiveSummaryTab.css'
import ILTTwentrySummaryHeader from "../ILTTwentrySummaryHeader/ILTTwentrySummaryHeader";
import ExecutiveSummaryContent from "./ExecutiveSummaryContent/ExecutiveSummaryContent";
import ExecutiveSummaryProduct from "./ExecutiveSummaryProduct/ExecutiveSummaryProduct";
import ExecutiveSummaryConsumer from "./ExecutiveSummaryConsumer/ExecutiveSummaryConsumer";
import ContentSchedulingWithHeatMapAndDropdowns from "../ContentTab/ContentSchedulingWithHeatMapAndDropdowns/ContentSchedulingWithHeatMapAndDropdowns";
import Customer from "./Customer/Customer";
import LoderSpinner from "../ExecutiveSummaryTab/ExecutiveSummaryContent/LoderSpinner"
import ExecutiveSummaryProductNew from './ExecutiveSummaryProduct/ExecutiveSummaryProductNew'



const ExecutiveSummaryTab = () => {
    const [activeTab, setActiveTab] = useState('customer');
   
    return (
        <div>
            <div>
                <div className="executive-header-top">
                    <ILTTwentrySummaryHeader header="Executive Summary" />
                </div>

                <div className="executive-summary-tabs">
                    <button className={activeTab === 'customer' ? ('executive-summary-active-tab') : ("executive-summary-tab")} onClick={() => setActiveTab('customer')}>Customer</button>
                    <button className={activeTab === 'content' ? ('executive-summary-active-tab') : ("executive-summary-tab")} onClick={() => setActiveTab('content')}>Content</button>
                    <button className={activeTab === 'consumer' ? ('executive-summary-active-tab') : ("executive-summary-tab")} onClick={() => setActiveTab('consumer')}>Consumer</button>
                    <button className={activeTab === 'product' ? ('executive-summary-active-tab') : ("executive-summary-tab")} onClick={() => setActiveTab('product')}>Product</button>
                </div>
                {/* {activeTab && loading ? (
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                ):(
                    <div>
                        {activeTab === 'consumer' && <ExecutiveSummaryConsumer />}
                    </div>
                )}
                {activeTab && loading ? (
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                ):(
                    <div>
                        {activeTab === 'content' && <ExecutiveSummaryContent click={activeTab}/>}
                    </div>
                )}
                {activeTab && loading ? (
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                ):(
                    <div>
                        {activeTab === 'customer' && < Customer />}{activeTab === 'content' && <ExecutiveSummaryContent click={activeTab}/>}
                    </div>
                )}
                {activeTab && loading ? (
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                ):(
                    <div>
                        {activeTab === 'product' && <ExecutiveSummaryProduct />}
                    </div>
                )} */}
                <div className="executive-summary-tabs">
                    {/* <button className={activeTab === 'customer' ? ('executive-summary-active-tab') : ("executive-summary-tab")} onClick={() => setActiveTab('customer')}>Customer</button> */}
                    <button className={activeTab === 'content' ? ('executive-summary-active-tab') : ("executive-summary-tab")} onClick={() => setActiveTab('content')}>Content</button>
                    <button className={activeTab === 'consumer' ? ('executive-summary-active-tab') : ("executive-summary-tab")} onClick={() => setActiveTab('consumer')}>Consumer</button>
                    <button className={activeTab === 'product' ? ('executive-summary-active-tab') : ("executive-summary-tab")} onClick={() => setActiveTab('product')}>Product</button>
                </div>


                {activeTab === 'consumer' && <ExecutiveSummaryConsumer />}
                {activeTab === 'content' && <ExecutiveSummaryContent click={activeTab} />}
                {/* {activeTab === 'customer' && < Customer />} */}
                {activeTab === 'product' && <ExecutiveSummaryProductNew />}
            </div>


        </div>
    )
}

export default ExecutiveSummaryTab;