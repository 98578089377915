import React from "react";
import GuageChart from "./GuageChart";
import ProductQualityExperienceToggle from "./ProductQualityExperienceToggle";
import './GoodVideoExperienceAVODSVOD.css';
import ProductQualityExperienceToggleAVODSVOD from "./ProductQualityExperienceToggleAVODSVOD";

const GoodVideoExperienceAVODSVOD = (props) => {
  const { mydata, myData, PlatformData, Range, diifrence, Color, isPercentage } = props;

  return (
    <div className="ProductQualityExperienceCardNew_main_avod_svod__div_syn">
        <div className="product-qual-experience-subdiv-tile-avod_svod_syn">
            <p className="product-qual-experience-subdiv-tile-title-avod-svod-syn">
              {" "}
              <p className="title2-avod-svod-syn">{mydata[0]}</p>
              {isPercentage ? (<p className="product_exp_card_mydata">{myData}</p>):(<p className="product_exp_card_mydata">{myData}%</p>)}

              <div className="product-qual-experience-subdiv-tile-chart-value-valdiv">
                <p className="product-qual-experience-subdiv-tile-chart-value-date">
                Reporting basis Last 30D
                </p>
                <p className="product-qual-experience-subdiv-tile-chart-value-status-neg">
                  {mydata[2] > 0 ? (
                    <p className="up-line">{mydata[2]} ↑ from last 30 Days</p>
                  ) : (
                    <p className="down-line">
                      {mydata[2]} ↓ from last 30 Days{" "}
                    </p>
                  )}
                </p>
              </div>
            </p>
           
          {/* {PlatformData !== ["test"] && (
            <ProductQualityExperienceToggleAVODSVOD PlatformData={PlatformData} />
          )} */}
        </div>
    </div>
  );
};

export default GoodVideoExperienceAVODSVOD;
