import React, { Component } from 'react';
import './PlotChart.css';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

class PlotChartStar extends Component {
  componentDidMount() {

    console.log('classcompdata',this.props.data)
    var root = am5.Root.new("chartdivstar");
  
  
  // Set themes
  // https://www.amcharts.com/docs/v5/concepts/themes/
  root.setThemes([
    am5themes_Animated.new(root)
  ]);
  
  
  // Create chart
  // https://www.amcharts.com/docs/v5/charts/xy-chart/
  var chart = root.container.children.push(am5xy.XYChart.new(root, {
    panX: false,
    panY: false,
    // wheelX: "panX",
    // wheelY: "zoomX",
    pinchZoomX: true
  }));
  
  // Add cursor
  // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
  var cursory = chart.set("cursor", am5xy.XYCursor.new(root, {}));
  cursory.lineY.set("visible", false);

//   var cursorx = chart.set("cursor", am5xy.XYCursor.new(root, {}));
//   cursorx.lineX.set("visible", false);
  
  
  // Create axes
  // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
// Create axes
// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

var xRenderer = am5xy.AxisRendererX.new(root, {
  minGridDistance: 0.5
});
xRenderer.labels.template.setAll({
  rotation: -90,
  centerY: am5.p50,
  centerX: 0
});

xRenderer.grid.template.setAll({
  visible: true
})


  
  var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
    maxDeviation: 0,
    categoryField: "category",
    renderer: xRenderer,
    visible: true,
    tooltip: am5.Tooltip.new(root, {})
  }));
  
  var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
    visible: false,
    maxDeviation: 0,
    renderer: am5xy.AxisRendererY.new(root, {})
  }));
  
  
  // Create series
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

  

  var series = chart.series.push(am5xy.ColumnSeries.new(root, {
    name: "Series 1",
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: "close",
    openValueYField: "open",
    categoryXField: "category",
    fill: '#808080',
    tooltip: am5.Tooltip.new(root, {
      labelText: "CW {openValueY} - LW {valueY}"
    })
  }));

  
  series.columns.template.setAll({
    width: 0.5,
  });
  
  series.bullets.push(function() {
    return am5.Bullet.new(root, {
      locationY: 0,
      sprite: am5.Circle.new(root, {
        radius: 5,
        // fill: series.get("fill")
        fill: '#808080'

      })
    })
  })


  
  var nextColor = chart.get("colors").getIndex(1);
  
  series.bullets.push(function() {
    return am5.Bullet.new(root, {
      locationY: 1,
      sprite: am5.Circle.new(root, {
        radius: 5,
        fill: '#808080'
      })
    })
  })




  
  // Set data
  var data = this.props.data;

  console.log('DATA', data)
  xAxis.data.setAll(data);
  series.data.setAll(data);
  
  
  // Make stuff animate on load
  // https://www.amcharts.com/docs/v5/concepts/animations/
  series.appear(1000);
  chart.appear(1000, 100);

}

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  render() {
    return (
      <div id="chartdivstar" style={{ width: "100%", height: "500px" }}></div>
    );
  }
}

export default PlotChartStar;