import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import * as d3 from 'd3v3';
import * as d4 from 'd3v4';
import './ContentTabStackBarChart.css'
import { yellow } from "@material-ui/core/colors";


const ContentTabStackBarChart = (props) => {
    const viewStackBarSvg = useRef();
    const { dataSetNeteorkShare, img, total } = props
    useEffect(() => {
        var setOfValues = ["Value4", "Value5", "Value6"];
        var margins = {
            top: 0,
            left: 50,
            right: 20,
            bottom: 0
        };
        var legendPanel = {
            width: 0
        };
        var width = 800 - margins.left - margins.right - legendPanel.width;
        var height = 100 - margins.top - margins.bottom
        var dataset = dataSetNeteorkShare;

        dataset = dataset.map(function (d) {
            return d.data.map(function (o, i) {
                return {
                    people: o.people,
                    y: o.count,
                    x: o.value
                };
            });
        });
        var stack = d3.layout.stack();

        stack(dataset);

        var dataset = dataset.map(function (group) {
            return group.map(function (d) {
                return {
                    people: d.people,
                    x: d.y,
                    y: d.x,
                    x0: d.y0
                };
            });
        });

        var numberOfPeople = 20;
        var svg = d3.select(viewStackBarSvg.current)
            .attr('width', width + margins.left + margins.right + legendPanel.width)
            .attr('height', height + margins.top + margins.bottom)
            .attr("viewBox", [-170, 15, 1170, 170])
            .attr("style", "max-width: 100%; height: auto;")
            .append('g')
            .attr('transform', 'translate(' + margins.left + ',' + margins.top - 10 + ')')
            .attr();
        var xMax = numberOfPeople;

        var xScale = d3.scale.linear()
            .domain([0, xMax])
            .range([0, width]);


        var values = dataset[0].map(function (d) {
            return d.y;
        });

        var yScale = d3.scale.ordinal()
            .domain(values)
            .rangeRoundBands([0, 190], .2);

        var xAxis = d3.svg.axis()
            .scale(xScale)
            .orient('top')
            .tickFormat(function (d) { return parseInt(d, 10) })
            .ticks(xMax);

        var yAxis = d3.svg.axis()
            .scale(yScale)
            .outerTickSize(0)
            .orient('left');

        var colors = d3.scale.ordinal().range(["#945ED2", "#FFBF65", "#7343A9", '#FF8B6C', '#B4229D', 'red']);
        var groups = svg.selectAll('g')
            .data(dataset)
            .enter()
            .append('g').classed('my-g-elems-bar', true)
            .style('fill', function (d, i) {
                return colors(i);
            });


        var rects = groups.selectAll('rect')
            .data(function (d) { return d; })
            .enter()

        rects.append('rect')
            .attr('x', function (d) { return xScale(d.x0); })
            .attr('y', function (d, i) { return yScale(d.y); })
            .attr('height', function (d) { return yScale.rangeBand() + 7; })
            .attr('width', function (d) { return xScale(d.x); })
            .on('mouseover', function (d, event) {
                var xPos = parseFloat(d3.select(this).attr('x')) / 2 + width / 2;
                var yPos = parseFloat(d3.select(this).attr('y')) + yScale.rangeBand() / 2;
                d3.select('#tooltip')
                    .style('left', d3.event.pageX + 'px')
                    .style('top', d3.event.pageY - 190 + 'px')
                    .select('#value')
                    //Question 1: "How to show in tooltip names of people??"
                    .text(d.people);
                d3.select('#tooltip').classed('hidden', false);
            })
            .on('mouseout', function () { d3.select('#tooltip').classed('hidden', true); });

        svg.append('g')
            .attr('class', 'axis').style('left', 10 + 'px')
            //  .attr("stroke", "black")
            .call(yAxis);

        const x = d4.scaleLinear()
            .domain([0, width])
            .range([0, width]);

        const y = d4.scaleBand()
            .range([0, 175])
            .domain(dataset.map(d => d.value))
            .padding(0.2);

        rects.append("text")
            .attr("class", "bar-total")
            .attr("x", function (d, i) {
                console.log(d, i);
                return xScale(d.x0) + xScale(d.x) + 5; // add 5px gap between bar and text
            })
            .attr("y", function (d) {
                return yScale(d.y) + yScale.rangeBand() / 2 + 5; // add 5px shift to center align text vertically
            })
            .text(function (d, i) {
                console.log(d.people.slice(0, 9), i);
                if(d.people.slice(0, 9) === 'Hindi GEC'){
                    var total = d3.sum(dataset, function (group) {
                        return group.filter(function (obj) {
                            return obj.y === d.y;
                        })[0].x;
                    });
                    return total.toFixed(2);
                }else{
                    return ''
                }
                    
            });

        // svg.append('g')
        //     .attr('class', 'axis')
        //     .call(xAxis);
    }, []);

    return (
        <div>
            <div className="exe-summ-con-linear-con-viewer-summ-network-chart-syn">
                <div className="exe-summ-con-linear-con-viewer-summ-network-chart-img-class-syn">
                    <div><img src={img} className="networkLogo starlogo" /></div>
                    {/* <div> <p>LW</p><p>CW</p></div> */}


                </div>
                <div className="d-flex">

                    <svg ref={viewStackBarSvg}></svg>
                    {/* {total.length > 0 && <div className="total_network_share_chart">

                        <div className="exe-summ-con-linear-con-viewer-summ-stack-chart-value-one-syn">{total[0]}</div>
                        <div className="exe-summ-con-linear-con-viewer-summ-stack-chart-value-two-syn">{total[1]}</div>

                    </div>} */}

                </div>

            </div>
            <div id="tooltip" class="hidden">
                <p><span id="value"></span></p>
            </div>
        </div>

    )
}

export default ContentTabStackBarChart;

