import React from "react";
import { useState ,useEffect} from "react";

import happiness from '../../../../Components/Images/retantion.png'
import ProductQualityExperienceCardAdoption from "./ProductQualityExperienceCardAdoption";

const Retention = (props) => {
  const {ProductData}= props 
  const [ AppRating, setAppRating ] = useState([])
  const [ Complaints, setComplaints ] = useState([])


  const LoadFilter = () => {

  const filtercomplaints = ProductData.filter(item => item.metric_name === ("subs retention rate"))
  setComplaints(filtercomplaints)

  const filterUserbounce = ProductData.filter(item => item.metric_name === ("sessions per user"))
  setAppRating(filterUserbounce)
  }


  console.log(AppRating,"subs retention rate*************  ");
  console.log(Complaints,"sessions per user*************  ");


  useEffect(()=>{
    LoadFilter()
  },[ProductData])
  return (
    <div>
      <div className="Product_card_executive_summary Retention_color">
      <div className="Product_card_executive_summary_img"><img src={happiness} /></div>
      <p className="Product_card_executive_summary_card_title">Retention</p>
      <p className="Product_card_executive_summary_card_title_para">User Loyalty</p>
      </div>

      {AppRating.length > 0 && Complaints.length > 0 &&
      <div>


        <div className="Product_card_executive_summary_card_margin">
          <ProductQualityExperienceCardAdoption
            diifrence="24.35"
            mydata={["Subscription Retention Rate (Auto + Manual)", `${Complaints[0].l30_metric_value}%`, (((Complaints[0].l30_metric_value-Complaints[0].l60_metric_value)/Complaints[0].l60_metric_value)*100).toFixed(2), "up"]}
            toggleData={[0, 0 , 0, 0, 0, Complaints[1].l30_metric_value, Complaints[2].l30_metric_value]}
            overAllData={Complaints}
            IiconText={"This metric measures the percentage of subscribers who remain subscribed to an app's paid services over a specific period."}

          />
        </div>

        <div >
          <ProductQualityExperienceCardAdoption
            diifrence="24.35"
            mydata={["Sessions/User", `${AppRating[0].l30_metric_value}`, (((AppRating[0].l30_metric_value-AppRating[0].l60_metric_value)/AppRating[0].l60_metric_value)*100).toFixed(2) , "down"]}
            toggleData={[AppRating[1].l30_metric_value, AppRating[2].l30_metric_value, AppRating[3].l30_metric_value, 0, 0, 0,0 , AppRating[4].l30_metric_value]}
            overAllData={AppRating}
            IiconText={"This metric measures the average number of sessions per user in the last 30 days."}


          />
        </div>

      </div>
}
    </div>
  );
};

export default Retention;
