import axios from "axios";

export default {

    getDataForExecutiveSummaryConsumer: async function () {
        try {
            const response = await axios.get('/api/get-executive-summary-consumer-data');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getDAU: async function () {
        try {
            const response = await axios.get('/api/exect-sum-consumer-dau');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getMAU: async function () {
        try {
            const response = await axios.get('/api/exect-sum-consumer-mau');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getPaidTraffic: async function () {
        try {
            const response = await axios.get('/api/exect-sum-consumer-paid-traffic');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getNewUsers: async function () {
        try {
            const response = await axios.get('/api/exect-sum-consumer-new-users');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getGuestUsers: async function () {
        try {
            const response = await axios.get('/api/exect-sum-consumer-guest-users');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getSignUps: async function () {
        try {
            const response = await axios.get('/api/exect-sum-consumer-sign-ups');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getDAV: async function () {
        try {
            const response = await axios.get('/api/exect-sum-consumer-dav');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getMAV: async function () {
        try {
            const response = await axios.get('/api/exect-sum-consumer-mav');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getWatchTime: async function () {
        try {
            const response = await axios.get('/api/exect-sum-consumer-watch-time');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getWatchTimePerViewer: async function () {
        try {
            const response = await axios.get('/api/exect-sum-consumer-watch-time-per-viewer');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getSearch: async function () {
        try {
            const response = await axios.get('/api/exect-sum-consumer-search');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getExplore: async function () {
        try {
            const response = await axios.get('/api/exect-sum-consumer-explore');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
    getSubScribers: async function () {
        try {
            const response = await axios.get('/api/exect-sum-consumer-subscribers');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },
} 
