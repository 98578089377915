import { useEffect, useRef, useLayoutEffect } from 'react';

import * as d3 from 'd3';
// import * as d3v4 from 'd3v4';

import './SankeyChartForConsumer.css';
import { useState } from 'react';

const SankeyChartForConsumer = (props) => {

    const { dataForSankeyChart, useTypeFilter, activeKPICharter } = props;

    const xPosition = 125, yPosition = 400;
    const [nodeDataRaw, setNodeDataRaw] = useState();
    const [linkData, setLinkData] = useState();

    const svgRef = useRef();

    useEffect(() => {
        if (useTypeFilter === 'overall') {
            setNodeDataRaw(
                [
                    { id: "organicTraffic", x: xPosition - 100, y: yPosition - 375, value: 70.38, name: "Organic", absoluteValue: 66.3, percentageValue: 46, percentageChage: -2, parent: null, child: "retention", self: "aquisition" },
                    { id: "paidTraffic", x: xPosition + 125, y: yPosition - 375, value: 79.11, name: "Paid", absoluteValue: 77.9, percentageValue: 54, percentageChage: 6, parent: null, child: "retention", self: "aquisition" },
                    { id: "returningUsers", x: xPosition - 100, y: yPosition - 175, value: 12.91, name: "Returning", absoluteValue: 15, percentageValue: 25, percentageChage: 10, parent: "aquisition", child: "discovery", self: "retention" },
                    { id: "newUsers", x: xPosition + 125, y: yPosition - 175, value: 12.91, name: "New", absoluteValue: 51, percentageValue: 75, percentageChage: -3, parent: "aquisition", child: "discovery", self: "retention" },
                    { id: "anonymous", x: xPosition - 100, y: yPosition + 20, value: 86.71, name: "Anonymous", absoluteValue: 31, percentageValue: 82, percentageChage: -12, self: "retention", child: "discovery" },
                    { id: "signUps", x: xPosition + 125, y: yPosition + 20, value: 33.21, name: "Sign Up", absoluteValue: 66.3, percentageValue: 18, percentageChage: 7, parent: "", self: "retention", child: "discovery" },
                    { id: "explore", x: xPosition - 35, y: yPosition + 245, value: 86.71, name: "Explore", absoluteValue: 66.3, percentageValue: 50, percentageChage: 5, parent: "retention", child: "monetizatoin", self: "discovery" },
                    { id: "search", x: xPosition + 55, y: yPosition + 245, value: 33.21, name: "Search", absoluteValue: 66.3, percentageValue: 20, percentageChage: -20, parent: "retention", child: "monetizatoin", self: "discovery" },
                    { id: "signUpExit", x: xPosition + 125, y: yPosition + 245, value: 55.55, name: "Exit", absoluteValue: 66.3, percentageValue: 27, percentageChage: 13, parent: "retention", child: "monetizatoin", self: "discovery" },
                    { id: "anonymousExit", x: xPosition - 100, y: yPosition + 245, value: 55.55, name: "Exit", absoluteValue: 66.3, percentageValue: 3, percentageChage: -0, parent: "retention", child: "monetizatoin", self: "discovery" },
                    { id: "subscribers", x: xPosition - 25, y: yPosition + 455, value: 86.71, name: "Subscribed", absoluteValue: 54, percentageValue: "--", percentageChage: -2, parent: "discovery", child: "engagement", self: "monetizatoin" },
                    { id: "watchedAds", x: xPosition + 50, y: yPosition + 455, value: "--", name: "Watched Ads", absoluteValue: 66.3, percentageValue: "--", percentageChage: "--", parent: "discovery", child: "engagement", self: "monetizatoin" },
                    { id: "searchExit", x: xPosition + 125, y: yPosition + 455, value: "--", name: "Exit", absoluteValue: 66.3, percentageValue: "--", percentageChage: "--", parent: "discovery", child: "engagement", self: "monetizatoin" },
                    { id: "exploreExit", x: xPosition - 100, y: yPosition + 455, value: '--', name: "Exit", absoluteValue: 66.3, percentageValue: "--", percentageChage: "--", parent: "discovery", child: "engagement", self: "monetizatoin" },
                    { id: "watchedPremiumContent", x: xPosition - 25, y: yPosition + 625, value: "--", name: "Watch Premium Content", absoluteValue: 66.3, percentageValue: "--", percentageChage: -2, parent: "monetizatoin", child: null, self: "engagement" },
                    { id: "watchedFreeContent", x: xPosition + 50, y: yPosition + 625, value: "--", name: "Watch Free Content", absoluteValue: 66.3, percentageValue: "--", percentageChage: -2, parent: "monetizatoin", child: null, self: "engagement" },
                    { id: "watchedAdsExit", x: xPosition + 125, y: yPosition + 625, value: "", name: "Exit", absoluteValue: 66.3, percentageValue: "--", percentageChage: -2, parent: "monetizatoin", child: null, self: "engagement" },
                ]
            );
            setLinkData(
                [
                    { source: [xPosition - 100, yPosition - 365], target: [xPosition + 125, yPosition - 185], fill: "#74CDFF", parent: "", self: "aquisition", child: "retention" }, //Organic - New
                    { source: [xPosition - 100, yPosition - 365], target: [xPosition - 100, yPosition - 185], fill: "#74CDFF", parent: "", self: "aquisition", child: "retention" }, //Orgainc - Returning
                    { source: [xPosition + 125, yPosition - 365], target: [xPosition - 100, yPosition - 185], fill: "#768CFF", parent: "", self: "aquisition", child: "retention" }, //Paid - Returning
                    { source: [xPosition + 125, yPosition - 365], target: [xPosition + 125, yPosition - 185], fill: "#768CFF", parent: "", self: "aquisition", child: "retention" }, //Paid - New
                    { source: [xPosition - 100, yPosition - 165], target: [xPosition - 100, yPosition + 10], fill: "#FFB78E", parent: "", child: "discovery", self: "retention" },  //Returning - Anonymous
                    { source: [xPosition - 100, yPosition - 165], target: [xPosition + 125, yPosition + 10], fill: "#FFB78E", parent: "", child: "discovery", self: "retention" }, //Returning - Sign up
                    { source: [xPosition + 125, yPosition - 165], target: [xPosition - 100, yPosition + 10], fill: "#74CDFF", parent: "", child: "discovery", self: "retention" },  //New - Anonymous
                    { source: [xPosition + 125, yPosition - 165], target: [xPosition + 125, yPosition + 10], fill: "#74CDFF", parent: "", child: "discovery", self: "retention" }, //New - Sign up
                    { source: [xPosition - 100, yPosition + 30], target: [xPosition - 35, yPosition + 235], fill: "#768CFF", parent: "", child: "discovery", self: "retention" }, //Anonymous - Explore
                    { source: [xPosition - 100, yPosition + 30], target: [xPosition + 55, yPosition + 235], fill: "#768CFF", parent: "", child: "discovery", self: "retention" }, //Anonymous - Search
                    { source: [xPosition - 100, yPosition + 30], target: [xPosition - 100, yPosition + 235], fill: "#FF96A6", parent: "", child: "discovery", self: "retention" }, //Anonymous - Exit
                    { source: [xPosition - 100, yPosition + 30], target: [xPosition - 25, yPosition + 445], fill: "#768CFF", parent: "", child: "discovery", self: "retention" }, //Anonymous - Subscribed
                    { source: [xPosition + 125, yPosition + 30], target: [xPosition - 35, yPosition + 235], fill: "#74CDFF", parent: "", child: "discovery", self: "retention" }, //Sign up - Explore
                    { source: [xPosition + 125, yPosition + 30], target: [xPosition + 55, yPosition + 235], fill: "#74CDFF", parent: "", child: "discovery", self: "retention" }, //Sign up - Search
                    // { source: [xPosition + 125, yPosition - 5], target: [xPosition + 125, yPosition + 195], fill: "#D60F0F" }, //Sign up - Exit
                    { source: [xPosition + 125, yPosition + 30], target: [xPosition + 125, yPosition + 235], fill: "#FF96A6", parent: "", child: "discovery", self: "retention" }, //Sign up - Exit
                    { source: [xPosition - 35, yPosition + 255], target: [xPosition - 25, yPosition + 445], fill: "#768CFF", parent: "discovery", child: "monetizatoin" }, //Explore - Subscribed
                    { source: [xPosition - 35, yPosition + 255], target: [xPosition + 50, yPosition + 445], fill: "#768CFF", parent: "discovery", child: "monetizatoin" }, //Explore - WatchAds
                    { source: [xPosition - 35, yPosition + 255], target: [xPosition - 100, yPosition + 445], fill: "#FF96A6", parent: "", child: "monetizatoin", self: "discovery" }, //Explore - Exit
                    { source: [xPosition + 55, yPosition + 255], target: [xPosition - 25, yPosition + 445], fill: "#FFB78E", parent: "", child: "monetizatoin", self: "discovery" }, //Search - Subscribed
                    { source: [xPosition + 55, yPosition + 255], target: [xPosition + 50, yPosition + 445], fill: "#FFB78E", parent: "", child: "monetizatoin", self: "discovery" }, //Search - WatchAds
                    { source: [xPosition + 55, yPosition + 255], target: [xPosition + 125, yPosition + 445], fill: "#FF96A6", parent: "", child: "monetizatoin", self: "discovery" }, //Search - Exit
                    { source: [xPosition - 25, yPosition + 465], target: [xPosition - 25, yPosition + 615], fill: "#FFB78E", parent: "", self: "monetizatoin", child: "engagement" }, //Subscribed - Watch Premium Content
                    { source: [xPosition - 25, yPosition + 465], target: [xPosition + 50, yPosition + 615], fill: "#FFB78E", parent: "", self: "monetizatoin", child: "engagement" }, //Subscribed - Watch Free Content
                    // { source: [xPosition - 25, yPosition + 415], target: [xPosition + 5, yPosition + 565,], fill: "#FFB78E" }, //Subscribed - Watch Free Content
                    { source: [xPosition + 50, yPosition + 465], target: [xPosition + 50, yPosition + 615], fill: "#768CFF", parent: "", self: "monetizatoin", child: "engagement" }, //Watched Ads - Watch Free Content
                    { source: [xPosition + 50, yPosition + 465], target: [xPosition + 125, yPosition + 615], fill: "#FF96A6", parent: "", self: "monetizatoin", child: "engagement" }, //Watched Ads - Exit Watch Ads
                    { source: [xPosition + 50, yPosition + 465], target: [xPosition - 25, yPosition + 615], fill: "#768CFF", parent: "", self: "monetizatoin", child: "engagement" }, //Watched Ads - Watch Premium Content
                ]
            );

        } else if (useTypeFilter === 'svod') {
            setNodeDataRaw([
                { id: "organicTraffic", x: xPosition - 100, y: yPosition - 375, value: 70.38, name: "Organic", absoluteValue: 66.3, percentageValue: 46, percentageChage: -2, parent: null, child: "retention", self: "aquisition" },
                { id: "paidTraffic", x: xPosition + 125, y: yPosition - 375, value: 79.11, name: "Paid", absoluteValue: 77.9, percentageValue: 54, percentageChage: 6, parent: null, child: "retention", self: "aquisition" },
                { id: "returningUsers", x: xPosition - 100, y: yPosition - 175, value: 12.91, name: "Returning", absoluteValue: 15, percentageValue: 25, percentageChage: 10, parent: "aquisition", child: "discovery", parent: "aquisition", child: "discovery", self: "retention" },
                { id: "newUsers", x: xPosition + 125, y: yPosition - 175, value: 12.91, name: "New", absoluteValue: 51, percentageValue: 75, percentageChage: -3, parent: "aquisition", child: "discovery", parent: "aquisition", child: "discovery", self: "retention" },
                // { id: "anonymous", x: xPosition - 100, y: yPosition + 20, value: 86.71, name: "Anonymous", absoluteValue: 31, percentageValue: 82, percentageChage: -12 },
                { id: "signUps", x: xPosition, y: yPosition + 20, value: 33.21, name: "Sign Up", absoluteValue: 66.3, percentageValue: 18, percentageChage: 7, self: "retention", child: "discovery" },
                { id: "explore", x: xPosition - 100, y: yPosition + 245, value: 86.71, name: "Explore", absoluteValue: 66.3, percentageValue: 50, percentageChage: 5, parent: "retention", child: "monetizatoin", self: "discovery" },
                { id: "search", x: xPosition, y: yPosition + 245, value: 33.21, name: "Search", absoluteValue: 66.3, percentageValue: 20, percentageChage: -20, parent: "retention", child: "monetizatoin", self: "discovery" },
                { id: "signUpExit", x: xPosition + 125, y: yPosition + 245, value: 55.55, name: "Exit", absoluteValue: 66.3, percentageValue: 27, percentageChage: 13, parent: "retention", child: "monetizatoin", self: "discovery" },
                // { id: "anonymousExit", x: xPosition - 100, y: yPosition + 245, value: 55.55, name: "Exit", absoluteValue: 66.3, percentageValue: 3, percentageChage: -0 },
                { id: "subscribers", x: xPosition - 25, y: yPosition + 455, value: 86.71, name: "Subscribed", absoluteValue: 54, percentageValue: "--", percentageChage: -2, parent: "discovery", child: "engagement", self: "monetizatoin" },
                { id: "watchedAds", x: xPosition + 50, y: yPosition + 455, value: "--", name: "Watched Ads", absoluteValue: 66.3, percentageValue: "--", percentageChage: "--", parent: "discovery", child: "engagement", self: "monetizatoin" },
                { id: "searchExit", x: xPosition + 125, y: yPosition + 455, value: "--", name: "Exit", absoluteValue: 66.3, percentageValue: "--", percentageChage: "--", parent: "discovery", child: "engagement", self: "monetizatoin" },
                { id: "exploreExit", x: xPosition - 100, y: yPosition + 455, value: '--', name: "Exit", absoluteValue: 66.3, percentageValue: "--", percentageChage: "--", parent: "discovery", child: "engagement", self: "monetizatoin" },
                { id: "watchedPremiumContent", x: xPosition - 25, y: yPosition + 625, value: "--", name: "Watch Premium Content", absoluteValue: 66.3, percentageValue: "--", percentageChage: -2, parent: "monetizatoin", child: null, self: "engagement" },
                // { id: "Watch Premium Content", x: xPosition - 65, y: yPosition + 575, value: 86.71, name: "Watch Premium Content", absoluteValue: 66.3, percentageValue: 3, percentageChage: -2 },
                { id: "watchedFreeContent", x: xPosition + 50, y: yPosition + 625, value: "--", name: "Watch Free Content", absoluteValue: 66.3, percentageValue: "--", percentageChage: -2, parent: "monetizatoin", child: null, self: "engagement" },
                // { id: "Watch Free Content", x: xPosition + 5, y: yPosition + 575, value: 86.71, name: "Watch Free Content", absoluteValue: 66.3, percentageValue: 54, percentageChage: -2 },
                { id: "watchedAdsExit", x: xPosition + 125, y: yPosition + 625, value: "", name: "Exit", absoluteValue: 66.3, percentageValue: "--", percentageChage: -2, parent: "monetizatoin", child: null, self: "engagement" },
            ])
            setLinkData(
                [
                    { source: [xPosition - 100, yPosition - 365], target: [xPosition + 125, yPosition - 185], fill: "#74CDFF", self: "aquisition", child: "retention" }, //Organic - New
                    { source: [xPosition - 100, yPosition - 365], target: [xPosition - 100, yPosition - 185], fill: "#74CDFF", self: "aquisition", child: "retention" }, //Orgainc - Returning
                    { source: [xPosition + 125, yPosition - 365], target: [xPosition - 100, yPosition - 185], fill: "#768CFF", self: "aquisition", child: "retention" }, //Paid - Returning
                    { source: [xPosition + 125, yPosition - 365], target: [xPosition + 125, yPosition - 185], fill: "#768CFF", self: "aquisition", child: "retention" }, //Paid - New
                    // { source: [xPosition - 100, yPosition - 165], target: [xPosition - 100, yPosition + 10], fill: "#FFB78E" },  //Returning - Anonymous
                    { source: [xPosition - 100, yPosition - 165], target: [xPosition, yPosition + 10], fill: "#FFB78E", parent: "", child: "discovery", self: "retention" }, //Returning - Sign up
                    // { source: [xPosition + 125, yPosition - 165], target: [xPosition - 100, yPosition + 10], fill: "#74CDFF" },  //New - Anonymous
                    { source: [xPosition + 125, yPosition - 165], target: [xPosition, yPosition + 10], fill: "#74CDFF", parent: "", child: "discovery", self: "retention" }, //New - Sign up
                    // { source: [xPosition - 100, yPosition + 30], target: [xPosition - 35, yPosition + 235], fill: "#768CFF" }, //Anonymous - Explore
                    // { source: [xPosition - 100, yPosition + 30], target: [xPosition + 55, yPosition + 235], fill: "#768CFF" }, //Anonymous - Search
                    // { source: [xPosition - 100, yPosition + 30], target: [xPosition - 100, yPosition + 235], fill: "#FF96A6" }, //Anonymous - Exit
                    // { source: [xPosition - 100, yPosition + 30], target: [xPosition - 25, yPosition + 445], fill: "#768CFF" }, //Anonymous - Subscribed
                    { source: [xPosition, yPosition + 30], target: [xPosition - 100, yPosition + 235], fill: "#74CDFF", parent: "", child: "discovery", self: "retention" }, //Sign up - Explore
                    { source: [xPosition, yPosition + 30], target: [xPosition, yPosition + 235], fill: "#74CDFF", parent: "", child: "discovery", self: "retention" }, //Sign up - Search
                    // { source: [xPosition + 125, yPosition - 5], target: [xPosition + 125, yPosition + 195], fill: "#D60F0F" }, //Sign up - Exit
                    { source: [xPosition, yPosition + 30], target: [xPosition + 125, yPosition + 235], fill: "#FF96A6", parent: "", child: "discovery", self: "retention" }, //Sign up - Exit
                    { source: [xPosition - 100, yPosition + 255], target: [xPosition - 25, yPosition + 445], fill: "#768CFF", parent: "discovery", child: "monetizatoin" }, //Explore - Subscribed
                    { source: [xPosition - 100, yPosition + 255], target: [xPosition + 50, yPosition + 445], fill: "#768CFF", parent: "discovery", child: "monetizatoin" }, //Explore - WatchAds
                    { source: [xPosition - 100, yPosition + 255], target: [xPosition - 100, yPosition + 445], fill: "#FF96A6", parent: "", child: "monetizatoin", self: "discovery" }, //Explore - Exit
                    { source: [xPosition, yPosition + 255], target: [xPosition - 25, yPosition + 445], fill: "#FFB78E", parent: "", child: "monetizatoin", self: "discovery" }, //Search - Subscribed
                    { source: [xPosition, yPosition + 255], target: [xPosition + 50, yPosition + 445], fill: "#FFB78E", parent: "", child: "monetizatoin", self: "discovery" }, //Search - WatchAds
                    { source: [xPosition, yPosition + 255], target: [xPosition + 125, yPosition + 445], fill: "#FF96A6", parent: "", child: "monetizatoin", self: "discovery" }, //Search - Exit
                    { source: [xPosition - 25, yPosition + 465], target: [xPosition - 25, yPosition + 615], fill: "#FFB78E", parent: "", self: "monetizatoin", child: "engagement" }, //Subscribed - Watch Premium Content
                    // { source: [xPosition - 25, yPosition + 415], target: [xPosition - 65, yPosition + 565], fill: "#FFB78E" }, //Subscribed - Watch Premium Content
                    { source: [xPosition - 25, yPosition + 465], target: [xPosition + 50, yPosition + 615], fill: "#FFB78E", parent: "", self: "monetizatoin", child: "engagement" }, //Subscribed - Watch Free Content
                    // { source: [xPosition - 25, yPosition + 415], target: [xPosition + 5, yPosition + 565,], fill: "#FFB78E" }, //Subscribed - Watch Free Content
                    { source: [xPosition + 50, yPosition + 465], target: [xPosition + 50, yPosition + 615], fill: "#768CFF", parent: "", self: "monetizatoin", child: "engagement" }, //Watched Ads - Watch Free Content
                    { source: [xPosition + 50, yPosition + 465], target: [xPosition + 125, yPosition + 615], fill: "#FF96A6", parent: "", self: "monetizatoin", child: "engagement" }, //Watched Ads - Exit Watch Ads
                    { source: [xPosition + 50, yPosition + 465], target: [xPosition - 25, yPosition + 615], fill: "#768CFF", parent: "", self: "monetizatoin", child: "engagement" }, //Watched Ads - Watch Premium Content
                ]
            )
        } else {
            setNodeDataRaw(
                [
                    { id: "organicTraffic", x: xPosition - 100, y: yPosition - 375, value: 70.38, name: "Organic", absoluteValue: 66.3, percentageValue: 46, percentageChage: -2, parent: null, child: "retention", self: "aquisition" },
                    { id: "paidTraffic", x: xPosition + 125, y: yPosition - 375, value: 79.11, name: "Paid", absoluteValue: 77.9, percentageValue: 54, percentageChage: 6, parent: null, child: "retention", self: "aquisition" },
                    { id: "returningUsers", x: xPosition - 100, y: yPosition - 175, value: 12.91, name: "Returning", absoluteValue: 15, percentageValue: 25, percentageChage: 10, parent: "aquisition", child: "discovery", parent: "aquisition", child: "discovery", self: "retention" },
                    { id: "newUsers", x: xPosition + 125, y: yPosition - 175, value: 12.91, name: "New", absoluteValue: 51, percentageValue: 75, percentageChage: -3, parent: "aquisition", child: "discovery", parent: "aquisition", child: "discovery", self: "retention" },
                    { id: "anonymous", x: xPosition - 100, y: yPosition + 20, value: 86.71, name: "Anonymous", absoluteValue: 31, percentageValue: 82, percentageChage: -12, self: "retention", child: "discovery" },
                    { id: "signUps", x: xPosition + 125, y: yPosition + 20, value: 33.21, name: "Sign Up", absoluteValue: 66.3, percentageValue: 18, percentageChage: 7, self: "retention", child: "discovery" },
                    { id: "explore", x: xPosition - 35, y: yPosition + 245, value: 86.71, name: "Explore", absoluteValue: 66.3, percentageValue: 50, percentageChage: 5, parent: "retention", child: "monetizatoin", self: "discovery" },
                    { id: "search", x: xPosition + 55, y: yPosition + 245, value: 33.21, name: "Search", absoluteValue: 66.3, percentageValue: 20, percentageChage: -20, parent: "retention", child: "monetizatoin", self: "discovery" },
                    { id: "signUpExit", x: xPosition + 125, y: yPosition + 245, value: 55.55, name: "Exit", absoluteValue: 66.3, percentageValue: 27, percentageChage: 13, parent: "retention", child: "monetizatoin", self: "discovery" },
                    { id: "anonymousExit", x: xPosition - 100, y: yPosition + 245, value: 55.55, name: "Exit", absoluteValue: 66.3, percentageValue: 3, percentageChage: -0, parent: "retention", child: "monetizatoin", self: "discovery" },
                    // { id: "subscribers", x: xPosition - 25, y: yPosition + 455, value: 86.71, name: "Subscribed", absoluteValue: 54, percentageValue: "--", percentageChage: -2 },
                    { id: "watchedAds", x: xPosition, y: yPosition + 455, value: "--", name: "Watched Ads", absoluteValue: 66.3, percentageValue: "--", percentageChage: "--", parent: "discovery", child: "engagement", self: "monetizatoin" },
                    { id: "searchExit", x: xPosition + 125, y: yPosition + 455, value: "--", name: "Exit", absoluteValue: 66.3, percentageValue: "--", percentageChage: "--", parent: "discovery", child: "engagement", self: "monetizatoin" },
                    { id: "exploreExit", x: xPosition - 100, y: yPosition + 455, value: '--', name: "Exit", absoluteValue: 66.3, percentageValue: "--", percentageChage: "--", parent: "discovery", child: "engagement", self: "monetizatoin" },
                    { id: "watchedPremiumContent", x: xPosition - 25, y: yPosition + 625, value: "--", name: "Watch Premium Content", absoluteValue: 66.3, percentageValue: "--", percentageChage: -2, parent: "monetizatoin", child: null, self: "engagement" },
                    // { id: "Watch Premium Content", x: xPosition - 65, y: yPosition + 575, value: 86.71, name: "Watch Premium Content", absoluteValue: 66.3, percentageValue: 3, percentageChage: -2 },
                    { id: "watchedFreeContent", x: xPosition + 50, y: yPosition + 625, value: "--", name: "Watch Free Content", absoluteValue: 66.3, percentageValue: "--", percentageChage: -2, parent: "monetizatoin", child: null, self: "engagement" },
                    // { id: "Watch Free Content", x: xPosition + 5, y: yPosition + 575, value: 86.71, name: "Watch Free Content", absoluteValue: 66.3, percentageValue: 54, percentageChage: -2 },
                    { id: "watchedAdsExit", x: xPosition + 125, y: yPosition + 625, value: "", name: "Exit", absoluteValue: 66.3, percentageValue: "--", percentageChage: -2, parent: "monetizatoin", child: null, self: "engagement" },
                ]
            );
            setLinkData(
                [
                    { source: [xPosition - 100, yPosition - 365], target: [xPosition + 125, yPosition - 185], fill: "#74CDFF", self: "aquisition", child: "retention" }, //Organic - New
                    { source: [xPosition - 100, yPosition - 365], target: [xPosition - 100, yPosition - 185], fill: "#74CDFF", self: "aquisition", child: "retention" }, //Orgainc - Returning
                    { source: [xPosition + 125, yPosition - 365], target: [xPosition - 100, yPosition - 185], fill: "#768CFF", self: "aquisition", child: "retention" }, //Paid - Returning
                    { source: [xPosition + 125, yPosition - 365], target: [xPosition + 125, yPosition - 185], fill: "#768CFF", self: "aquisition", child: "retention" }, //Paid - New
                    { source: [xPosition - 100, yPosition - 165], target: [xPosition - 100, yPosition + 10], fill: "#FFB78E", parent: "", child: "discovery", self: "retention" },  //Returning - Anonymous
                    { source: [xPosition - 100, yPosition - 165], target: [xPosition + 125, yPosition + 10], fill: "#FFB78E", parent: "", child: "discovery", self: "retention" }, //Returning - Sign up
                    { source: [xPosition + 125, yPosition - 165], target: [xPosition - 100, yPosition + 10], fill: "#74CDFF", parent: "", child: "discovery", self: "retention" },  //New - Anonymous
                    { source: [xPosition + 125, yPosition - 165], target: [xPosition + 125, yPosition + 10], fill: "#74CDFF", parent: "", child: "discovery", self: "retention" }, //New - Sign up
                    { source: [xPosition - 100, yPosition + 30], target: [xPosition - 35, yPosition + 235], fill: "#768CFF", parent: "", child: "discovery", self: "retention" }, //Anonymous - Explore
                    { source: [xPosition - 100, yPosition + 30], target: [xPosition + 55, yPosition + 235], fill: "#768CFF", parent: "", child: "discovery", self: "retention" }, //Anonymous - Search
                    { source: [xPosition - 100, yPosition + 30], target: [xPosition - 100, yPosition + 235], fill: "#FF96A6", parent: "", child: "discovery", self: "retention" }, //Anonymous - Exit
                    // { source: [xPosition - 100, yPosition + 30], target: [xPosition - 25, yPosition + 445], fill: "#768CFF" }, //Anonymous - Subscribed
                    { source: [xPosition + 125, yPosition + 30], target: [xPosition - 35, yPosition + 235], fill: "#74CDFF", parent: "", child: "discovery", self: "retention" }, //Sign up - Explore
                    { source: [xPosition + 125, yPosition + 30], target: [xPosition + 55, yPosition + 235], fill: "#74CDFF", parent: "", child: "discovery", self: "retention" }, //Sign up - Search
                    // { source: [xPosition + 125, yPosition - 5], target: [xPosition + 125, yPosition + 195], fill: "#D60F0F" }, //Sign up - Exit
                    { source: [xPosition + 125, yPosition + 30], target: [xPosition + 125, yPosition + 235], fill: "#FF96A6", parent: "", child: "discovery", self: "retention" }, //Sign up - Exit
                    // { source: [xPosition - 35, yPosition + 255], target: [xPosition - 25, yPosition + 445], fill: "#768CFF" }, //Explore - Subscribed
                    { source: [xPosition - 35, yPosition + 255], target: [xPosition, yPosition + 445], fill: "#768CFF", parent: "discovery", child: "monetizatoin" }, //Explore - WatchAds
                    { source: [xPosition - 35, yPosition + 255], target: [xPosition - 100, yPosition + 445], fill: "#FF96A6", parent: "discovery", child: "monetizatoin"  }, //Explore - Exit
                    // { source: [xPosition + 55, yPosition + 255], target: [xPosition - 25, yPosition + 445], fill: "#FFB78E" }, //Search - Subscribed
                    { source: [xPosition + 55, yPosition + 255], target: [xPosition, yPosition + 445], fill: "#FFB78E", parent: "discovery", child: "monetizatoin"  }, //Search - WatchAds
                    { source: [xPosition + 55, yPosition + 255], target: [xPosition + 125, yPosition + 445], fill: "#FF96A6", parent: "discovery", child: "monetizatoin"  }, //Search - Exit
                    // { source: [xPosition - 25, yPosition + 465], target: [xPosition - 25, yPosition + 615], fill: "#FFB78E" }, //Subscribed - Watch Premium Content
                    // { source: [xPosition - 25, yPosition + 415], target: [xPosition - 65, yPosition + 565], fill: "#FFB78E" }, //Subscribed - Watch Premium Content
                    // { source: [xPosition - 25, yPosition + 465], target: [xPosition + 50, yPosition + 615], fill: "#FFB78E" }, //Subscribed - Watch Free Content
                    // { source: [xPosition - 25, yPosition + 415], target: [xPosition + 5, yPosition + 565,], fill: "#FFB78E" }, //Subscribed - Watch Free Content
                    { source: [xPosition, yPosition + 465], target: [xPosition + 50, yPosition + 615], fill: "#768CFF", parent: "", self: "monetizatoin", child: "engagement"  }, //Watched Ads - Watch Free Content
                    { source: [xPosition, yPosition + 465], target: [xPosition + 125, yPosition + 615], fill: "#FF96A6", parent: "", self: "monetizatoin", child: "engagement"  }, //Watched Ads - Exit Watch Ads
                    { source: [xPosition, yPosition + 465], target: [xPosition - 25, yPosition + 615], fill: "#768CFF" , parent: "", self: "monetizatoin", child: "engagement" }, //Watched Ads - Watch Premium Content
                ]
            );
        }
    }, [useTypeFilter])

    useLayoutEffect(() => {

        if (nodeDataRaw && linkData) {

            // var nodeDataRaw, linkData;
            let consumerJourneyTitles = [
                {
                    id: 'acquisition',
                    name: 'Acquisition',
                    x: 35,
                    y: 10,
                },
                {
                    id: 'retention',
                    name: 'Retention',
                    x: 225,
                    y: 10,
                },
                {
                    id: 'onboarding',
                    name: 'Onboarding',
                    x: 420,
                    y: 10,
                },
                {
                    id: 'discovery',
                    name: 'Discovery',
                    x: 645,
                    y: 10,
                },
                {
                    id: 'monetisation',
                    name: 'Monetisation',
                    x: 855,
                    y: 10,
                },
                {
                    id: 'engagement',
                    name: 'Engagement',
                    x: 1025,
                    y: 10,
                },
            ]

            let mau = dataForSankeyChart.filter((item, index) => item.kpi === "mau")[0];
            // console.log("****************mau**************", mau)
            var nodeData = nodeDataRaw.map((item, index) => {
                let newObject = item;
                // for (let obj in dataForSankeyChart[0]) {
                //     if (item.id === obj) {
                //         newObject = {
                //             ...item,
                //             value: (parseFloat(dataForSankeyChart[0][obj]) / 1000000).toFixed(2),
                //             percentageValue: (parseFloat(dataForSankeyChart[0][obj]) / mau * 100).toFixed(0)
                //         }
                //     }
                // }
                dataForSankeyChart.map((ele, index) => {
                    if (ele.kpi === item.id) {
                        newObject = {
                            ...item,
                            value: (ele.currentPeriodValue / 1000000).toFixed(2),
                            percentageValue: (ele.currentPeriodValue / mau.currentPeriodValue * 100).toFixed(0),
                            percentageChage: (100 - (ele.previousPeriodValue / ele.currentPeriodValue * 100)).toFixed(0)
                        }
                    }
                })
                return newObject;
            })

            console.log("nodeData", nodeData);

            var link = d3.linkHorizontal()
                .source(function (d) {
                    return [d.source[1], d.source[0]];
                })
                .target(function (d) {
                    return [d.target[1], d.target[0]];
                });

            var linkForShadow = d3.linkHorizontal()
                .source(function (d) {
                    return [d.source[1], d.source[0]];
                })
                .target(function (d) {
                    return [d.target[1], d.target[0]];
                });

            const curve = d3
                .line()
                .curve(d3.curveNatural)

            const coOrdinates = [
                // Organinc - New
                // {
                //     point: [[35, 25], [110, 75], [135, 160], [200, 240], [160, 215], [120, 170], [90, 75], [35, 25]],
                //     fill: "#74CDFF",
                // },
                // Organic - Returning
                // {
                //     point: createLineCoOrdinates([35, 25], [195, 25], 8),
                //     fill: "#74CDFF"
                // },
                // Paid - Returning
                // {
                //     point: [[35, 250], [110, 200], [130, 90], [195, 30], [178, 35], [120, 80], [90, 200], [35, 250]],
                //     fill: "#768CFF",
                // },
                // Paid - New
                // {
                //     point: createLineCoOrdinates([35, 250], [195, 250], -8),
                //     fill: "#768CFF",
                // },
                // Returning - Sign Up  [215, 25][375, 250]
                // {
                //     point: [[215, 25], [290, 75], [315, 160], [380, 240], [335, 215], [300, 170], [265, 75], [215, 25]],
                //     fill: "#FFB78E",
                // },
                // New - Anonymous  [215, 250][375, 25]
                // {
                //     point: [[215, 250], [270, 200], [305, 90], [375, 30], [358, 35], [290, 80], [250, 200], [215, 250]],
                //     fill: "#768CFF",
                // },
                //Returning - Anonymous
                // {
                //     point: createLineCoOrdinates([215, 25], [375, 25], 8),
                //     fill: "#FFB78E"
                // },
                // //New - Sign Up
                // {
                //     point: createLineCoOrdinates([215, 25], [375, 25], 8),
                //     fill: "#FFB78E"
                // },
                //New - Sign Up
                // {
                //     point: createLineCoOrdinates([215, 250], [375, 250], -8),
                //     fill: "#74CDFF"
                // }

            ]

            const svg = d3
                .select(svgRef.current)
                .attr("viewBox", "0 0 1100 305")
                // .attr('preserveAspectRatio', 'xMinYMin')
                .classed("sankey-chart-for-consumer-svg", true);

            svg.selectAll("*").remove();

            //Adding the link paths
            var filter = svg.append("filter")
                .attr("id", "drop-shadow")
                .attr("x", "-50%")
                .attr("y", "-50%")
                .attr("width", "200%")
                .attr("height", "200%");

            // Add a feGaussianBlur element to the filter
            filter.append("feGaussianBlur")
                .attr("in", "SourceAlpha")
                .attr("stdDeviation", 8)
                .attr("result", "blur");

            // Add a feOffset element to the filter
            filter.append("feOffset")
                .attr("in", "blur")
                .attr("dx", 10)
                .attr("dy", 10)
                .attr("result", "offsetBlur");

            // Combine the original source image with the offset blurred image
            filter.append("feMerge").selectAll("feMergeNode")
                .data(["offsetBlur", "SourceGraphic"])
                .enter().append("feMergeNode")
                .attr("in", function (d) { return d; });

            svg
                .selectAll("path")
                .data(linkData)
                .join("path")
                .attr("d", link)
                .attr("fill", "none")
                .attr("stroke", d => d.parent == activeKPICharter || d.child == activeKPICharter || d.self === activeKPICharter ? d.fill : "#e9e9e9")
                .attr("stroke-width", "11px")
                .attr("opacity", "0.6")
                .classed("link", true)

            var defs = svg.append("defs");

            // create filter with id #drop-shadow
            // height=130% so that the shadow is not clipped
            var filter = defs.append("filter")
                .attr("id", "drop-shadow")
                .attr("height", "130%");

            // SourceAlpha refers to opacity of graphic that this filter will be applied to
            // convolve that with a Gaussian with standard deviation 3 and store result
            // in blur
            filter.append("feGaussianBlur")
                .attr("in", "SourceAlpha")
                .attr("stdDeviation", 5)
                .attr("result", "blur");

            // translate output of Gaussian blur to the right and downwards with 2px
            // store result in offsetBlur
            filter.append("feOffset")
                .attr("in", "blur")
                .attr("dx", 5)
                .attr("dy", 5)
                .attr("result", "offsetBlur");

            // overlay original SourceGraphic over translated blurred opacity by using
            // feMerge filter. Order of specifying inputs is important!
            var feMerge = filter.append("feMerge");

            feMerge.append("feMergeNode")
                .attr("in", "offsetBlur")
            feMerge.append("feMergeNode")
                .attr("in", "SourceGraphic");



            const line = svg
                .append("g")
                .selectAll("path")
                .data(linkData)
                .join("path")
                .attr("d", linkForShadow)
                .attr("fill", "none")
                .attr("stroke", d => d.parent == activeKPICharter || d.child == activeKPICharter || d.self === activeKPICharter ? 'gray' : "none")
                .attr("stroke-width", "11px")
                .attr("opacity", "0.2")
                .classed("linkforshadow", true)
                .style("filter", "url(#drop-shadow)");

            // var defs = svg.append("defs");
            // var filter = defs.append("filter")
            //     .attr("id", "line-shadow")
            //     .attr("x", "-50%")
            //     .attr("y", "-50%")
            //     .attr("width", "200%")
            //     .attr("height", "200%");

            // // Add the shadow effect to the filter element
            // filter.append("feDropShadow")
            //     .attr("dx", 5)
            //     .attr("dy", 5)
            //     .attr("stdDeviation", 5)
            //     .attr("flood-color", "gray");

            // // Apply the shadow filter to the line
            // line.attr("filter", "url(#line-shadow)");

            //Adding the Circle nodes
            svg
                .selectAll("circle")
                .data(nodeData)
                .join("circle")
                .attr("r", d => d.name === "Exit" ? 0 : 5.5)
                .attr("cx", d => d.y)
                .attr("cy", d => d.x)
                .attr("fill", d => d.parent == activeKPICharter || d.child == activeKPICharter || d.self === activeKPICharter ? "#3E1F76" : "#e9e9e9")
                .attr("opacity", "0.8")
                .classed("circle", true)
            // .on("click", () => { console.log('Clicked') })

            //Adding the text labels
            svg
                .selectAll("text")
                .data(nodeData)
                .join("text")
                .attr("font-size", "16px")
                .attr("fill", d => d.parent == activeKPICharter || d.child == activeKPICharter || d.self === activeKPICharter ? "#333333" : "#e9e9e9")
                .attr("text-anchor", "middle")
                .attr("x", d => d.name === "Exit" ? d.y + 3 : d.y)
                .attr("y", d => d.name === "Exit" ? d.x + 2 : d.x + 17)
                .classed("node-title", true)
                .text(d => d.name);

            svg
                .selectAll("p")
                .data(nodeData)
                .join("text")
                .attr("text-anchor", "middle")
                .attr("x", d => d.name === "Exit" ? d.y + 3 : d.y)
                .attr("y", d => d.name === "Exit" ? d.x + 22 : d.x + 34)
                .attr("fill", d => d.parent == activeKPICharter || d.child == activeKPICharter || d.self === activeKPICharter ? "black" : "#e9e9e9")
                .classed("node-value", true)
                .text(d => d.value)

            svg
                .selectAll("p")
                .data(nodeData)
                .join("text")
                .attr("text-anchor", "middle")
                .attr("x", d => d.name === "Exit" ? d.y + 3 : d.y)
                .attr("y", d => d.name === "Exit" ? d.x + 33 : d.x + 46)
                .classed("node-percentage", true)
                .attr("fill", d => d.parent == activeKPICharter || d.child == activeKPICharter || d.self === activeKPICharter ? "#808080" : "#e9e9e9")
                .text(d => `(${d.percentageValue}%)`);

            svg
                .selectAll("p")
                .data(nodeData)
                .join("text")
                .attr("text-anchor", "middle")
                .attr("x", d => d.name === "Exit" ? d.y + 37 : d.y + 37)
                .attr("y", d => d.name === "Exit" ? d.x + 16 : d.x + 30)
                .text(d => `${(Math.abs(d.percentageChage)) ? d.percentageChage : "--"}%`)
                .attr("class", d =>
                    d.parent == activeKPICharter || d.child == activeKPICharter || d.self === activeKPICharter ?
                        (d.percentageChage >= 0 ? "node-percentage-change-pos" : "node-percentage-change-neg") : "node-percentage-change-pos-inactive"
                )

            // svg
            //     .append("g")
            //     .selectAll(".arrow")
            //     .data(nodeData)
            //     .join("path")
            //     .attr("d", "M4.35355 0.646407C4.15829 0.451145 3.84171 0.451145 3.64645 0.646407L0.464467 3.82839C0.269204 4.02365 0.269204 4.34023 0.464466 4.53549C0.659729 4.73076 0.976311 4.73076 1.17157 4.53549L4 1.70707L6.82843 4.53549C7.02369 4.73076 7.34027 4.73076 7.53553 4.53549C7.7308 4.34023 7.7308 4.02365 7.53553 3.82839L4.35355 0.646407ZM4.5 7.69043L4.5 0.99996L3.5 0.99996L3.5 7.69043L4.5 7.69043Z")
            //     .attr("fill", "#00C48C")
            //     .attr("x", d => d.name === "Exit" ? d.y + 40 : d.y + 40)
            //     .attr("y", d => d.name === "Exit" ? d.x + 16 : d.x + 30)

            svg
                .selectAll("p")
                .data(consumerJourneyTitles)
                .join("text")
                .attr("text-anchor", "middle")
                .attr("x", d => d.x)
                .attr("y", d => d.y)
                .classed("sankey-chart-sections", true)
                .text(d => `${d.name}`);

            svg
                .selectAll('.link-to-node')
                .data(coOrdinates)
                .join('path')
                .attr('d', d => curve(d.point))
                .attr("opacity", "0.5")
                .attr('fill', d => d.fill);
        }

    }, [dataForSankeyChart, nodeDataRaw, linkData, activeKPICharter]);

    return (
        <div className='sankey-chart-for-consumer-container'>
            <svg ref={svgRef}></svg>
        </div>
    )
}

export default SankeyChartForConsumer;