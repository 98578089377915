import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import ExecutiveSummaryConLinearZeeProgramsCard from "./ExecutiveSummaryConLinearZeeProgramsCard";
import './ExecutiveSummaryContentOtt.css'
import zeeLogo from '../../Images/zee-image.svg'
import ExecutiveSummaryServicesContentOtt from '../../../Services/ExecutiveSummaryServices/ExecutiveSummaryServicesContentOtt';
import serialLogo0 from '../../Images/Bhagya Lakshmi.png'

import lost from '../../Images/OTT images/Lost.png';
import vedha from '../../Images/OTT images/Vedha.png';
import Venky from '../../Images/OTT images/Venky.png';
import Jaanbaaz2 from '../../Images/OTT images/Jaanbaaz2.png';
import Chatrivali from '../../Images/OTT images/Chatrivali.png';
import Unchai from '../../Images/OTT images/Unchai.png';
import Jaanbaaz3 from '../../Images/OTT images/Jaanbaaz3.png';
import LastCoffee from '../../Images/OTT images/Last-coffee.png';

import ayali from '../../Images/OTT images/Ayali.png'
import jaanbaaz4 from '../../Images/OTT images/Jaanbaaz4.png'
import jaanbaaz from '../../Images/OTT images/Jaanbaaz.png'
import tamil from '../../Images/OTT images/Tamil.png'
import mukhbir from '../../Images/OTT images/Mukhbir.png'
import now from '../../Images/OTT images/Now.png'
import Jaanbaaz4 from '../../Images/OTT images/Jaanbaaz4.png'
import Pitchurs from '../../Images/OTT images/Pitchurs.png'
import shikharpur from '../../Images/OTT images/shikharpur.png'

import uri from '../../Images/OTT images/URI.png';
import operation from '../../Images/OTT images/operation.png';
import lockdown from '../../Images/OTT images/lockdown.png';
import tamil2 from '../../Images/OTT images/tamil2.png';
import vedha2 from '../../Images/OTT images/vedha2.png';
import ExecutiveSummaryServicesContent from "../../../Services/ExecutiveSummaryServices/ExecutiveSummaryServicesContent";
import ExecutiveSummaryConLinearZeeProgramsCardOtt from "./ExecutiveSummaryConLinearZeeProgramsCardOtt";

const ExecutiveSummaryContentOtt = (props) => {
  const [optionToggleMetric, setOptionToggleMetric] = useState();
  const [filterSelectValueMetric, setFilterSelectValueMetric] = useState('Watch Time');
  const {cWeekAndDate} = props;
  const [metric, setMetric] = useState([]);
  const [overall, setOverall] = useState([]);
  const [originals, setOriginals] = useState([]);
  const [movies, setMovies] = useState([]);
  let dummyData = {
    "viewer": [
      [
        {
          "number": 1642224,
          "Asset": 4146978,
          "Season_ID": "0-0-1z5334341",
          "Title_Season": "Mrs Undercover"
        },
        {
          "number": 1501956,
          "Asset": 10129292,
          "Season_ID": "0-2-kundalibhagya1",
          "Title_Season": "Kundali Bhagya {1}"
        },
        {
          "number": 1077614,
          "Asset": 6569936,
          "Season_ID": "0-2-3337",
          "Title_Season": "Bhagya Lakshmi {1}"
        },
        {
          "number": 893978,
          "Asset": 5028275,
          "Season_ID": "0-2-kumkumbhagya1",
          "Title_Season": "Kumkum Bhagya {1}"
        },
        {
          "number": 723608,
          "Asset": 4500413,
          "Season_ID": "0-2-5z5130243",
          "Title_Season": "Pyar Ka Pehla Naam: Radha Mohan {1}"
        },
        {
          "number": 657433,
          "Asset": 4558247,
          "Season_ID": "0-2-3391",
          "Title_Season": "Meet {1}"
        },
        {
          "number": 401047,
          "Asset": 2208886,
          "Season_ID": "0-2-2258",
          "Title_Season": "Prema Entha Maduram {1}"
        },
        {
          "number": 391973,
          "Asset": 1669841,
          "Season_ID": "0-2-5z5327137",
          "Title_Season": "United Kacche {1}"
        },
        {
          "number": 365165,
          "Asset": 2073238,
          "Season_ID": "0-2-2257",
          "Title_Season": "Trinayani {1}"
        },
        {
          "number": 313255,
          "Asset": 631316,
          "Season_ID": "0-0-1z5326731",
          "Title_Season": "Kanjoos Makhichoos "
        }
      ]
    ],
    "queryWatch_time": [
      [
        {
          "number": 2417305,
          "Asset": 4146978,
          "Season_ID": "0-0-1z5334341",
          "Title_Season": "Mrs Undercover"
        },
        {
          "number": 1317913,
          "Asset": 10129292,
          "Season_ID": "0-2-kundalibhagya1",
          "Title_Season": "Kundali Bhagya {1}"
        },
        {
          "number": 1288989,
          "Asset": 6569936,
          "Season_ID": "0-2-3337",
          "Title_Season": "Bhagya Lakshmi {1}"
        },
        {
          "number": 1228221,
          "Asset": 5028275,
          "Season_ID": "0-2-kumkumbhagya1",
          "Title_Season": "Kumkum Bhagya {1}"
        },
        {
          "number": 1413681,
          "Asset": 4500413,
          "Season_ID": "0-2-5z5130243",
          "Title_Season": "Pyar Ka Pehla Naam: Radha Mohan {1}"
        },
        {
          "number": 1216355,
          "Asset": 4558247,
          "Season_ID": "0-2-3391",
          "Title_Season": "Meet {1}"
        },
        {
          "number": 876815,
          "Asset": 2208886,
          "Season_ID": "0-2-2258",
          "Title_Season": "Prema Entha Maduram {1}"
        },
        {
          "number": 945688,
          "Asset": 1669841,
          "Season_ID": "0-2-5z5327137",
          "Title_Season": "United Kacche {1}"
        },
        {
          "number": 805237,
          "Asset": 2073238,
          "Season_ID": "0-2-2257",
          "Title_Season": "Trinayani {1}"
        },
        {
          "number": 527116,
          "Asset": 631316,
          "Season_ID": "0-0-1z5326731",
          "Title_Season": "Kanjoos Makhichoos "
        }
      ]
    ],
    "sawf": [
      [
        {
          "number": 71110,
          "Asset": 4143673,
          "Season_ID": "0-0-1z5334341",
          "Title_Season": "Mrs Undercover"
        },
        {
          "number": 2789,
          "Asset": 10116390,
          "Season_ID": "0-2-kundalibhagya1",
          "Title_Season": "Kundali Bhagya {1}"
        },
        {
          "number": 2396,
          "Asset": 6559088,
          "Season_ID": "0-2-3337",
          "Title_Season": "Bhagya Lakshmi {1}"
        },
        {
          "number": 1063,
          "Asset": 5021418,
          "Season_ID": "0-2-kumkumbhagya1",
          "Title_Season": "Kumkum Bhagya {1}"
        },
        {
          "number": 1188,
          "Asset": 4493311,
          "Season_ID": "0-2-5z5130243",
          "Title_Season": "Pyar Ka Pehla Naam: Radha Mohan {1}"
        },
        {
          "number": 1017,
          "Asset": 4552601,
          "Season_ID": "0-2-3391",
          "Title_Season": "Meet {1}"
        },
        {
          "number": 1177,
          "Asset": 2205694,
          "Season_ID": "0-2-2258",
          "Title_Season": "Prema Entha Maduram {1}"
        },
        {
          "number": 11993,
          "Asset": 1668374,
          "Season_ID": "0-2-5z5327137",
          "Title_Season": "United Kacche {1}"
        },
        {
          "number": 693,
          "Asset": 2070202,
          "Season_ID": "0-2-2257",
          "Title_Season": "Trinayani {1}"
        },
        {
          "number": 18878,
          "Asset": 630666,
          "Season_ID": "0-0-1z5326731",
          "Title_Season": "Kanjoos Makhichoos "
        }
      ]
    ]
  }
  const [ottOverAllData, setOttOverAllData] = useState(dummyData);
  const [ottOriginalData, setOttOriginalData] = useState(dummyData);
  const [ottMoviesData, setOttMoviesData] = useState(dummyData);
  const [ottTvShowData, setOttTvShowData] = useState(dummyData);


  const isMobile = useMediaQuery('(max-width: 765px)')

  const LoadNewOttData = async() => {
    console.log("yeah")
    const overAll = await ExecutiveSummaryServicesContentOtt.getValueOverallKPI();
    console.log('ottDataottDataottDataottDataottData  *****  overall ', overAll.viewer);
    console.log('ottDataottDataottDataottDataottData  *****  overall ', overAll.viewer.sawf);
    setOttOverAllData(overAll)

    const original = await ExecutiveSummaryServicesContentOtt.getValueOriginalKPI();
    console.log('ottDataottDataottDataottDataottData  ***** original', original);
    setOttOriginalData(original)

    const movies = await ExecutiveSummaryServicesContentOtt.getValueMoviesKPI();
    console.log('ottDataottDataottDataottDataottData  ***** movies', movies);
    setOttMoviesData(movies)

    const tv_show = await ExecutiveSummaryServicesContentOtt.getValueTvShowsKPI();
    console.log('ottDataottDataottDataottDataottData  ***** tv_show', tv_show);
    setOttTvShowData(tv_show)
  }

  useEffect(() => {

    LoadNewOttData()
     
    
    // loadDataOfOtt();
  }, [])

  let serieseImage2 = [
    {
      img: lost,
      name: 'Bhagya Lakshmi',
      logo: zeeLogo,
      grp: 1.62,
      Reach: 2.84,
      tsv: 17.5,
    },
    {
      img: vedha2,
      name: 'Kumkum Bhagya',
      logo: zeeLogo,
      grp: 1.6,
      Reach: 2.71,
      tsv: 18.1
    },
    {
      img: Venky,
      name: 'Kundali Bhagya',
      logo: zeeLogo,
      grp: 1.58,
      Reach: 2.53,
      tsv: 19
    },

    {
      img: Chatrivali,
      name: 'Pyaar Ka Pehla Naam Radha Mohan',
      logo: zeeLogo,
      grp: 1.4,
      Reach: 2.38,
      tsv: 17.9
    },
    {
      img: Unchai,
      name: 'Main Hoon Aparajita',
      logo: zeeLogo,
      grp: 0.9,
      Reach: 1.81,
      tsv: 15.2
    },
    {
      img: LastCoffee,
      name: 'Rabb Se Hai Dua',
      logo: zeeLogo,
      grp: 0.69,
      Reach: 1.7,
      tsv: 11.7
    },
    {
      img: uri,
      name: 'Maitree',
      logo: zeeLogo,
      grp: 0.59,
      Reach: 1.32,
      tsv: 13.6
    },
    {
      img: operation,
      name: 'Meet',
      logo: zeeLogo,
      grp: 0.5,
      Reach: 0.99,
      tsv: 14.4
    },
    {
      img: lockdown,
      logo: zeeLogo,
      name: 'Lag Ja Gale',
      grp: 0.41,
      Reach: 0.93,
      tsv: 13.6
    },
    {
      img: tamil2,
      name: 'Pyaar Ka Pehla Naam Radha Mohan',
      logo: zeeLogo,
      grp: 1.4,
      Reach: 2.38,
      tsv: 17.9
    },

  ]

  let serieseImage1 = [
    {
      img: lost,
      name: 'Bhagya Lakshmi',
      logo: zeeLogo,
      grp: 1.62,
      Reach: 2.84,
      tsv: 17.5,
    },
    {
      img: vedha,
      name: 'Kumkum Bhagya',
      logo: zeeLogo,
      grp: 1.6,
      Reach: 2.71,
      tsv: 18.1
    },
    {
      img: Venky,
      name: 'Kundali Bhagya',
      logo: zeeLogo,
      grp: 1.58,
      Reach: 2.53,
      tsv: 19
    },
    {
      img: ayali,
      name: 'Pyaar Ka Pehla Naam Radha Mohan',
      logo: zeeLogo,
      grp: 1.4,
      Reach: 2.38,
      tsv: 17.9
    },
    {
      img: Jaanbaaz2,
      name: 'Pyaar Ka Pehla Naam Radha Mohan',
      logo: zeeLogo,
      grp: 1.4,
      Reach: 2.38,
      tsv: 17.9
    },
    {
      img: Chatrivali,
      name: 'Main Hoon Aparajita',
      logo: zeeLogo,
      grp: 0.9,
      Reach: 1.81,
      tsv: 15.2
    },
    {
      img: Unchai,
      name: 'Rabb Se Hai Dua',
      logo: zeeLogo,
      grp: 0.69,
      Reach: 1.7,
      tsv: 11.7
    },
    {
      img: Jaanbaaz3,
      name: 'Maitree',
      logo: zeeLogo,
      grp: 0.59,
      Reach: 1.32,
      tsv: 13.6
    },
    {
      img: tamil,
      name: 'Meet',
      logo: zeeLogo,
      grp: 0.5,
      Reach: 0.99,
      tsv: 14.4
    },
    {
      img: LastCoffee,
      logo: zeeLogo,
      name: 'Lag Ja Gale',
      grp: 0.41,
      Reach: 0.93,
      tsv: 13.6
    },


  ]

  let serieseImage = [
    {
      img: ayali,
      name: 'Bhagya Lakshmi',
      logo: zeeLogo,
      grp: 1.62,
      Reach: 2.84,
      tsv: 17.5,
    },
    {
      img: jaanbaaz4,
      name: 'Kumkum Bhagya',
      logo: zeeLogo,
      grp: 1.6,
      Reach: 2.71,
      tsv: 18.1
    },
    {
      img: jaanbaaz,
      name: 'Kundali Bhagya',
      logo: zeeLogo,
      grp: 1.58,
      Reach: 2.53,
      tsv: 19
    },

    {
      img: tamil,
      name: 'Pyaar Ka Pehla Naam Radha Mohan',
      logo: zeeLogo,
      grp: 1.4,
      Reach: 2.38,
      tsv: 17.9
    },
    {
      img: mukhbir,
      name: 'Main Hoon Aparajita',
      logo: zeeLogo,
      grp: 0.9,
      Reach: 1.81,
      tsv: 15.2
    },
    {
      img: now,
      name: 'Rabb Se Hai Dua',
      logo: zeeLogo,
      grp: 0.69,
      Reach: 1.7,
      tsv: 11.7
    },
    {
      img: Jaanbaaz4,
      name: 'Maitree',
      logo: zeeLogo,
      grp: 0.59,
      Reach: 1.32,
      tsv: 13.6
    },
    {
      img: Pitchurs,
      name: 'Meet',
      logo: zeeLogo,
      grp: 0.5,
      Reach: 0.99,
      tsv: 14.4
    },
    {
      img: now,
      logo: zeeLogo,
      name: 'Lag Ja Gale',
      grp: 0.41,
      Reach: 0.93,
      tsv: 13.6
    },
    {
      img: shikharpur,
      name: 'Pyaar Ka Pehla Naam Radha Mohan',
      logo: zeeLogo,
      grp: 1.4,
      Reach: 2.38,
      tsv: 17.9
    },
  ]

  const handleOptionClickMetric = (value) => {
    setOptionToggleMetric(false)
    setFilterSelectValueMetric(value)
  }

  return (
    <div className="">
      <div className="executive-summary-content-linear-syn">
        <div className="exec-summ-ott-top-prog-and-per-and-metric-syn">
          <p className="exec-summ-lin-viewership-summ-active-class-syn">Top Programs & Performance</p>
          <div>
            <div className="d-flex">
              <p className="exe-summ-content-linear-viewership-channel-gener-head-syn">Metric</p>
              <div style={{ position: 'relative' }}>
                <div onClick={() => setOptionToggleMetric(true)} className="executive-summary-content-content-ranking-filter-select-options-syn select-arrow">
                  {filterSelectValueMetric}
                </div>
                {optionToggleMetric === true && <div className="executive-summary-content-content-ranking-filter-options-syn">
                  <p onClick={() => handleOptionClickMetric('No. of Viewers')} className="executive-summary-content-content-ranking-filter-option-syn">No. of Viewers</p>
                  <p onClick={() => handleOptionClickMetric('Watch Time')} className="executive-summary-content-content-ranking-filter-option-syn">Watch Time</p>
                  <p onClick={() => handleOptionClickMetric('SAWF')} className="executive-summary-content-content-ranking-filter-option-syn">SAWF</p>
                </div>}
              </div>
            
            </div>
           
          </div>
        </div>
        <div className="tagline-product">Reporting Top programs basis previous 7 days performance</div>
        <div className="executive-summary-content-linear-perent-div-syn">
        {ottOverAllData !== undefined && <div className="execu-summ-con-ott-zee-prog-div-syn">
            <div className="executive-summary-con-ott-zee-programs-heading-and-title-syn">
              <h6 className="executive-summary-con-ott-zee-programs-heading-card-syn">Overall</h6>
              {/* {cWeekAndDate !== null && <p className="execu-summ-con-ott-zee-prog-title-goes-syn">All charts reporting performance basis GRPs as on Week {cWeekAndDate[0]} ending {cWeekAndDate[1]}</p>} */}
            </div>
            <div className="execut-summ-lin-content-single-card-with-img-syn">
              {filterSelectValueMetric === 'No. of Viewers' && ottOverAllData.viewer[0].map((ele, index) => {
                console.log('no of viewer', ottOverAllData);
                let dataLength = serieseImage.length;
                return <ExecutiveSummaryConLinearZeeProgramsCardOtt name={ele.Title_Season} filterSelectValueMetric={filterSelectValueMetric} isMobile={isMobile} dataLength={dataLength} number={ele.number} grp={ele.grp} logo={ele.logo} Reach={ele.Reach} tsv={ele.tsv} key={index} index={index} img={ele.img} />
              })
              }
              {
                filterSelectValueMetric === 'Watch Time' && ottOverAllData.queryWatch_time[0].map((ele, index) => {
                  console.log('watchtime', ottOverAllData);
                  let dataLength = serieseImage.length;
                  return <ExecutiveSummaryConLinearZeeProgramsCardOtt name={ele.Title_Season} filterSelectValueMetric={filterSelectValueMetric} isMobile={isMobile} dataLength={dataLength} number={ele.number} grp={ele.grp} logo={ele.logo} Reach={ele.Reach} tsv={ele.tsv} key={index} index={index} img={ele.img} />
                })
              }
              {
                filterSelectValueMetric === 'SAWF' && ottOverAllData.sawf[0].map((ele, index) => {
                  console.log('sawss', ottOverAllData);
                  let dataLength = serieseImage.length;
                  return <ExecutiveSummaryConLinearZeeProgramsCardOtt  name={ele.Title_Season} filterSelectValueMetric={filterSelectValueMetric} isMobile={isMobile} dataLength={dataLength} number={ele.number} grp={ele.grp} logo={ele.logo} Reach={ele.Reach} tsv={ele.tsv} key={index} index={index} img={ele.img} />
                })
              }
            </div>
          </div>}
          {ottOriginalData !== undefined && <div className="execu-summ-con-ott-zee-prog-div-syn">
            <h6 className="executive-summary-con-linear-zee-programs-heading-card-syn  zee-network">Originals</h6>
            <div className="execut-summ-lin-content-single-card-with-img-syn">

              {filterSelectValueMetric === 'No. of Viewers' && ottOriginalData.viewer[0].map((ele, index) => {
                let dataLength = serieseImage.length;
                return <ExecutiveSummaryConLinearZeeProgramsCardOtt name={ele.Title_Season} filterSelectValueMetric={filterSelectValueMetric} number={ele.number} isMobile={isMobile} dataLength={dataLength} grp={ele.grp} logo={ele.logo} Reach={ele.Reach} tsv={ele.tsv} key={index} index={index} img={ele.img} />
              })}

              {filterSelectValueMetric === 'Watch Time' && ottOriginalData.queryWatch_time[0].map((ele, index) => {
                let dataLength = serieseImage.length;
                return <ExecutiveSummaryConLinearZeeProgramsCardOtt name={ele.Title_Season} filterSelectValueMetric={filterSelectValueMetric} number={ele.number} isMobile={isMobile} dataLength={dataLength} grp={ele.grp} logo={ele.logo} Reach={ele.Reach} tsv={ele.tsv} key={index} index={index} img={ele.img} />
              })}

              {filterSelectValueMetric === 'SAWF' && ottOriginalData.sawf[0].map((ele, index) => {
                let dataLength = serieseImage.length;
                return <ExecutiveSummaryConLinearZeeProgramsCardOtt name={ele.Title_Season} filterSelectValueMetric={filterSelectValueMetric} number={ele.number} isMobile={isMobile} dataLength={dataLength} grp={ele.grp} logo={ele.logo} Reach={ele.Reach} tsv={ele.tsv} key={index} index={index} img={ele.img} />
              })}

            </div>
          </div>}
          {ottMoviesData !== undefined && <div className="execu-summ-con-ott-zee-prog-div-syn">
            <h6 className="executive-summary-con-linear-zee-programs-heading-card-syn  zee-network">Movies</h6>
            <div className="execut-summ-lin-content-single-card-with-img-syn">

              {filterSelectValueMetric === 'No. of Viewers' && ottMoviesData.viewer[0].map((ele, index) => {
                let dataLength = serieseImage.length;
                return <ExecutiveSummaryConLinearZeeProgramsCardOtt name={ele.Title_Season} filterSelectValueMetric={filterSelectValueMetric} number={ele.number} isMobile={isMobile} dataLength={dataLength} grp={ele.grp} logo={ele.logo} Reach={ele.Reach} tsv={ele.tsv} key={index} index={index} img={ele.img} />
              })}

              {filterSelectValueMetric === 'Watch Time' && ottMoviesData.queryWatch_time[0].map((ele, index) => {
                let dataLength = serieseImage.length;
                return <ExecutiveSummaryConLinearZeeProgramsCardOtt name={ele.Title_Season} filterSelectValueMetric={filterSelectValueMetric} number={ele.number} isMobile={isMobile} dataLength={dataLength} grp={ele.grp} logo={ele.logo} Reach={ele.Reach} tsv={ele.tsv} key={index} index={index} img={ele.img} />
              })}

              {filterSelectValueMetric === 'SAWF' && ottMoviesData.sawf[0].map((ele, index) => {
                let dataLength = serieseImage.length;
                return <ExecutiveSummaryConLinearZeeProgramsCardOtt name={ele.Title_Season} filterSelectValueMetric={filterSelectValueMetric} number={ele.number} isMobile={isMobile} dataLength={dataLength} grp={ele.grp} logo={ele.logo} Reach={ele.Reach} tsv={ele.tsv} key={index} index={index} img={ele.img} />
              })}

            </div>
          </div>}

          {ottTvShowData !== undefined && <div className="execu-summ-con-ott-zee-prog-div-syn">
            <h6 className="executive-summary-con-linear-zee-programs-heading-card-syn  zee-network">TV Shows</h6>
            <div className="execut-summ-lin-content-single-card-with-img-syn">

              {filterSelectValueMetric === 'No. of Viewers' && ottTvShowData.viewer[0].map((ele, index) => {
                let dataLength = serieseImage.length;
                return <ExecutiveSummaryConLinearZeeProgramsCardOtt name={ele.Title_Season} filterSelectValueMetric={filterSelectValueMetric} number={ele.number} isMobile={isMobile} dataLength={dataLength} grp={ele.grp} logo={ele.logo} Reach={ele.Reach} tsv={ele.tsv} key={index} index={index} img={ele.img} />
              })}

              {filterSelectValueMetric === 'Watch Time' && ottTvShowData.queryWatch_time[0].map((ele, index) => {
                let dataLength = serieseImage.length;
                return <ExecutiveSummaryConLinearZeeProgramsCardOtt name={ele.Title_Season} filterSelectValueMetric={filterSelectValueMetric} number={ele.number} isMobile={isMobile} dataLength={dataLength} grp={ele.grp} logo={ele.logo} Reach={ele.Reach} tsv={ele.tsv} key={index} index={index} img={ele.img} />
              })}

              {filterSelectValueMetric === 'SAWF' && ottTvShowData.sawf[0].map((ele, index) => {
                let dataLength = serieseImage.length;
                return <ExecutiveSummaryConLinearZeeProgramsCardOtt name={ele.Title_Season} filterSelectValueMetric={filterSelectValueMetric} number={ele.number} isMobile={isMobile} dataLength={dataLength} grp={ele.grp} logo={ele.logo} Reach={ele.Reach} tsv={ele.tsv} key={index} index={index} img={ele.img} />
              })}

            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default ExecutiveSummaryContentOtt;
