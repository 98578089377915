import axios from "axios";
import { async } from "q";


const baseURLProduct = '/api/slotleadership';

// const baseURLProd = 'http://20.219.91.228:5000';

export default {

   

    sldata: async function () {
        try {
            const response = await axios.get(baseURLProduct + '/sldata');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    Channel_Genre: async function () {
        try {
            const response = await axios.get(baseURLProduct + '/channel_genre');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },


    Channel: async function () {
        try {
            const response = await axios.get(baseURLProduct + '/channel');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    targetGroup: async function () {
        try {
            const response = await axios.get(baseURLProduct + '/targetGroup');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    market: async function () {
        try {
            const response = await axios.get(baseURLProduct + '/market');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    periodofStudy: async function () {
        try {
            const response = await axios.get(baseURLProduct + '/periodofStudy');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    dayofStudy: async function () {
        try {
            const response = await axios.get(baseURLProduct + '/dayofStudy');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    grp: async function () {
        try {
            const response = await axios.get(baseURLProduct + '/grp');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    tsv: async function () {
        try {
            const response = await axios.get(baseURLProduct + '/tsv');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

}