import React, { useState, useEffect, useRef } from "react";
import { Calendar } from 'react-calendar';
import * as d3 from 'd3';
import { HeatMapWithDropDown } from "../../Charts/SLHeatMap/HeatMap";
import './ExecutiveSummContLinSlotLeadership.css'
import ExecutiveSummaryContentServices from '../../../Services/ExecutiveSummaryContentServices';
import axios from "axios";

const ExecutiveSummContLinSlotLeadership = () => {


    //For API filters
    const [channelGenre, setchannelGenre] = useState([''])

    const [channel, setchannel] = useState([''])
    const [channelFilteredValues, setChannelFilteredValues] = useState(['']);

    const [targetGroup, settargetGroup] = useState([''])
    const [targetGroupFilteredValues, settargetGroupFilteredValues] = useState(['']);

    const [sldata, setsldata] = useState([''])

    const [market, setmarket] = useState([''])
    const [marketFilteredValues, setmarketFilteredValues] = useState(['']);

    const [metricValue, setMetricValue] = useState('GRP');

    const [periodofStudy, setperiodofStudy] = useState()
    const [DayofStudy, setDay_Name] = useState()

    const [grp, setgrp] = useState()
    const [tsv, settsv] = useState()

    const [sldataDummy, setSldataDummy] = useState([]); 

    const [periodOfStudy, setPeriodOfStudy] = useState('');
    const [dayOfStudy, setDayOfStudy] = useState('');

    let defaultChannelGener = 'Hindi GEC - Paid';

    const LoadProductData = async () => {

        const channelGenreResult = await ExecutiveSummaryContentServices.Channel_Genre()
        const Channel_Genre = channelGenreResult.map(item => item.Channel_Genre)
        setchannelGenre(Channel_Genre.sort())

        const channelResult = await ExecutiveSummaryContentServices.Channel()
        // const Channel = channelResult.map(item => item.Channel_Name_Barc)
        setchannel(channelResult)

        const targetGroupResult = await ExecutiveSummaryContentServices.targetGroup()
        // const targetGroup = targetGroupResult.map(item => item.Target_Group)
        settargetGroup(targetGroupResult)

        const TGtype = targetGroupResult.filter((element) => element.Channel_Genre === defaultChannelGener)
            settargetGroupFilteredValues(TGtype.map(item => item.Target_Group))
        // const sldataResult = await ExecutiveSummaryContentServices.sldata()
        // setsldata(sldataResult)
        // setSldataDummy(sldataResult);

        const marketResult = await ExecutiveSummaryContentServices.market()
        // const market = marketResult.map(item => item.Regions)
        setmarket(marketResult)
        const Markettype = marketResult.filter((element) => element.Channel_Genre === defaultChannelGener)
            setmarketFilteredValues(Markettype.map(item => item.Regions))

        const periodofStudyResult = await ExecutiveSummaryContentServices.periodofStudy()
        const periodofStudy = periodofStudyResult.map(item => item.Date_Of_Telecast)
        //console.log('periodofStudy', periodofStudy)
        setperiodofStudy(periodofStudy)

        const DayofStudyResult = await ExecutiveSummaryContentServices.dayofStudy()
        const DayofStudy = DayofStudyResult.map(item => item.Day_Name)
        //console.log('DayofStudy', DayofStudy)
        setDay_Name(DayofStudy)

        const grpResult = await ExecutiveSummaryContentServices.grp()
        const grp = grpResult.map(item => item.GRP)
        //console.log('grp', grp)
        setgrp(grp)

        const tsvResult = await ExecutiveSummaryContentServices.tsv()
        const tsv = tsvResult.map(item => item.TSV)
        //console.log('tsv', tsv)
        settsv(tsv)



    }

    useEffect(() => {
        LoadProductData()
    }, [])

    //For API filters


    const myChanelValues = channelFilteredValues.map((ele) => ele.Channel_Name_Barc)

    const multiSelectDropDownOptions = myChanelValues
    multiSelectDropDownOptions.unshift("All")
    //console.log('ddbahrss', multiSelectDropDownOptions, myChanelValues)

    // var multiSelectDropDownOptionss = [
    //     "All",
    //     "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday",
    // ];

    let initialStateObject = {};

    let filteredData = multiSelectDropDownOptions.map(element => initialStateObject[element] = true);
    //console.log('filteredData',filteredData);

    const [selectedSelectOptions, setSelectedSelectOptions] = useState(initialStateObject);

    if (initialStateObject.length > 0) {
        setSelectedSelectOptions(initialStateObject)
    }

    const handleMultiDropDownSelectionChange = (action, state) => {
        //console.log('state', state);
        setSelectedSelectOptions(state)
    }

    const [selectedDropDownFilters, setSelectedDropDownFilters] = useState({
        channelGenre: '',
        channle: '',
        metric: '',
        tg: 'Viewers',
        market: 'Gujarat',
    });

    // Channel Genre : Hindi GEC - Paid
    // TG - IND 15+ YRS
    // market : HSM Urban   
    // Metric - GRP 
    //  Prev Week

    const [isClickOnDropDownFilterApply, setIsClickOnDropDownFilterApply] = useState(false);

    let dropDownMenuForChannelGenre = {
        channelGenre: {
            id: 'channelGenre',
            label: 'Channel Genre',
            isMendatory: true,
            options: channelGenre,
        },
        channle: {
            id: 'channle',
            label: 'Channel',
            isMendatory: true,
            options: [
                'GRP',
                'TVS',
            ],
        },
        metric: {
            id: 'metric',
            label: 'Metric',
            options: [
                'GRP',
                'TSV',
            ],
        },
        tg: {
            id: 'tg',
            label: 'TG',
            isMendatory: true,
            options: targetGroupFilteredValues,
        },
        market: {
            id: 'market',
            label: 'Market',
            options: marketFilteredValues,
        },
        PeriodofStudy: {
            id: 'period of study',
            label: 'Period of Study',
            options: [
                'Previous Week',
                'Previous Month',
                'Previous Quarter',
            ],
        },
        DayofStudy: {
            id: 'Day of Study',
            label: 'Day of Study',
            options: [
                'Mon',
                'Tues',
                'Wed',
                'Thurs',
                'Fri',
                'Sat',
                'Sun',
                'Mon-Fri',
                'All Days(Mon-Sun)',
                'Sat-Sun',
            ],
        },

    }

    const handleOnDropDownMenuSelectionChange = (filterName, value, event) => {
        setSelectedDropDownFilters({
            ...selectedDropDownFilters,
            [filterName]: value,
        });
    }

    // MAIN FUNCTION

    const getOptionValue = (value) => {
        // Hindi GEC - Paid
        setChannelFilteredValues(channel.filter((element) => element.Channel_Genre === value))
        //console.log('channnnnnnnnnnnnnnlFILTER', channelFilteredValues);

        const TGtype = targetGroup.filter((element) => element.Channel_Genre === value)
        settargetGroupFilteredValues(TGtype.map(item => item.Target_Group))

        const Markettype = market.filter((element) => element.Channel_Genre === value)
        setmarketFilteredValues(Markettype.map(item => item.Regions))

        // CALL API      

        axios.get(`/api/slotleadership/sldataCost?value=${value}`)
            .then(response => setsldata(response.data))
            .catch(error => console.log(error));

    }

    const getOptionValuetg = (value) => {

        const Markettype = sldata.filter((element) => element.Target_Group === value)
        setsldata(Markettype)
    }

    const getOptionValuemarket = (value) => {
        const Markettype = sldata.filter((element) => element.Regions === value)
        setsldata(Markettype)
    }

   
    const getOptionValuePeriodofStudy = (value) => {
        setPeriodOfStudy(value)
    }

    const getOptionValueDayofStudy = (value) => {
        setDayOfStudy(value)
    }

    const getOptionValueMetric = (value) => {
        console.log(value);
        setMetricValue(value)
        
    }


    useEffect(() => {
            setChannelFilteredValues(channel.filter((element) => element.Channel_Genre === defaultChannelGener));

            axios.get(`/api/slotleadership/sldataCost?value=${defaultChannelGener}`)
            .then(response => setsldata(response.data))
            .catch(error => console.log(error));
            console.log(sldata);

            setIsClickOnDropDownFilterApply(true)
    }, [channel])


    console.log('sldatttttttta dummyyyyy', sldataDummy)

    return (
        <div className='content-scheduling-with-heatmap-dropdowns-container'>
            <div style={{ "display": "flex", "flexDirection": "column" }}>
                <div className='content-scheduling-with-heatmap-dropdowns-dropdown-filters-container'>
                    <DropDownMenu
                        dropDownMenu={dropDownMenuForChannelGenre.channelGenre}
                        isMendatory={true}
                        onChageDropDownValue={getOptionValue}
                        handleOnDropDownMenuSelectionChange={handleOnDropDownMenuSelectionChange}
                        defaultValue = {defaultChannelGener}
                    />

                    <MultiSelectDropDownMenu
                        handleMultiDropDownSelectionChange={handleMultiDropDownSelectionChange}
                        channelFilteredValues={channelFilteredValues}
                        multiSelectDropDownConfigs={false}

                    />


                    <DropDownMenu
                        dropDownMenu={dropDownMenuForChannelGenre.tg}
                        onChageDropDownValue={getOptionValuetg}
                        isMendatory={true}
                        handleOnDropDownMenuSelectionChange={handleOnDropDownMenuSelectionChange}
                        defaultValue = {'IND 15+ YRS'}
                    />


                    <DropDownMenu
                        dropDownMenu={dropDownMenuForChannelGenre.market}
                        isMendatory={true}
                        onChageDropDownValue={getOptionValuemarket}
                        handleOnDropDownMenuSelectionChange={handleOnDropDownMenuSelectionChange}
                        defaultValue = {'HSM Urban'}
                    />

                    <DropDownMenu
                        dropDownMenu={dropDownMenuForChannelGenre.metric}
                        isMendatory={true}
                        onChageDropDownValue={getOptionValueMetric}
                        handleOnDropDownMenuSelectionChange={handleOnDropDownMenuSelectionChange}
                        defaultValue = {'GRP'}
                    />
                    <DropDownMenu
                        dropDownMenu={dropDownMenuForChannelGenre.PeriodofStudy}
                        isMendatory={true}
                        onChageDropDownValue={getOptionValuePeriodofStudy}
                        handleOnDropDownMenuSelectionChange={handleOnDropDownMenuSelectionChange}
                        defaultValue = {'Previous Week'}
                    />

                    <DropDownMenu
                        dropDownMenu={dropDownMenuForChannelGenre.DayofStudy}
                        onChageDropDownValue={getOptionValueDayofStudy}
                        isMendatory={true}
                        handleOnDropDownMenuSelectionChange={handleOnDropDownMenuSelectionChange}
                        defaultValue = {'All Days(Mon-Sun)'}
                    />


                    <button className='dropdown-filers-apply-button' onClick={(event) => {
                        // selectedDropDownFilters.channelGenre &&
                        //     selectedDropDownFilters.market &&
                        //     selectedDropDownFilters.metric &&
                        //     selectedDropDownFilters.tg ?
                        setIsClickOnDropDownFilterApply(true)
                        // :
                        // setIsClickOnDropDownFilterApply(false)
                    }}>Apply</button>
                </div>
                <div className='content-slot-leadership-with-heatmap-legend-syn'>
                    <p>Title goes here</p>
                    <svg width="252" height="21" viewBox="0 0 252 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M147.362 11L144.965 4.68164H144.926C144.939 4.8151 144.95 4.97786 144.96 5.16992C144.97 5.36198 144.978 5.57194 144.984 5.7998C144.991 6.02441 144.994 6.25391 144.994 6.48828V11H144.223V3.86133H145.453L147.714 9.79883H147.748L150.043 3.86133H151.264V11H150.443V6.42969C150.443 6.2181 150.447 6.00651 150.453 5.79492C150.46 5.58008 150.468 5.37826 150.478 5.18945C150.487 4.9974 150.497 4.83138 150.507 4.69141H150.468L148.041 11H147.362ZM153.905 5.64844V11H153.095V5.64844H153.905ZM153.51 3.64648C153.643 3.64648 153.757 3.69043 153.852 3.77832C153.949 3.86296 153.998 3.99642 153.998 4.17871C153.998 4.35775 153.949 4.49121 153.852 4.5791C153.757 4.66699 153.643 4.71094 153.51 4.71094C153.37 4.71094 153.253 4.66699 153.158 4.5791C153.067 4.49121 153.021 4.35775 153.021 4.17871C153.021 3.99642 153.067 3.86296 153.158 3.77832C153.253 3.69043 153.37 3.64648 153.51 3.64648ZM158.158 5.55078C158.793 5.55078 159.273 5.70703 159.599 6.01953C159.924 6.32878 160.087 6.82682 160.087 7.51367V11H159.286V7.56738C159.286 7.12142 159.184 6.78776 158.979 6.56641C158.777 6.34505 158.466 6.23438 158.046 6.23438C157.453 6.23438 157.037 6.40202 156.796 6.7373C156.555 7.07259 156.435 7.55924 156.435 8.19727V11H155.624V5.64844H156.278L156.4 6.41992H156.444C156.558 6.23112 156.703 6.07324 156.879 5.94629C157.055 5.81608 157.252 5.71842 157.47 5.65332C157.688 5.58496 157.917 5.55078 158.158 5.55078Z" fill="#333333" />
                        <path d="M236.116 11L233.719 4.68164H233.68C233.693 4.8151 233.704 4.97786 233.714 5.16992C233.724 5.36198 233.732 5.57194 233.738 5.7998C233.745 6.02441 233.748 6.25391 233.748 6.48828V11H232.977V3.86133H234.207L236.468 9.79883H236.502L238.797 3.86133H240.018V11H239.197V6.42969C239.197 6.2181 239.201 6.00651 239.207 5.79492C239.214 5.58008 239.222 5.37826 239.231 5.18945C239.241 4.9974 239.251 4.83138 239.261 4.69141H239.222L236.795 11H236.116ZM243.851 5.56055C244.489 5.56055 244.962 5.70378 245.271 5.99023C245.581 6.27669 245.735 6.73405 245.735 7.3623V11H245.145L244.988 10.209H244.949C244.799 10.4043 244.643 10.5687 244.48 10.7021C244.318 10.8324 244.129 10.9316 243.914 11C243.702 11.0651 243.442 11.0977 243.133 11.0977C242.807 11.0977 242.518 11.0407 242.264 10.9268C242.013 10.8128 241.814 10.6403 241.668 10.4092C241.525 10.1781 241.453 9.88509 241.453 9.53027C241.453 8.99642 241.665 8.58626 242.088 8.2998C242.511 8.01335 243.156 7.8571 244.021 7.83105L244.944 7.79199V7.46484C244.944 7.0026 244.845 6.67871 244.646 6.49316C244.448 6.30762 244.168 6.21484 243.807 6.21484C243.527 6.21484 243.26 6.25553 243.006 6.33691C242.752 6.41829 242.511 6.51432 242.283 6.625L242.034 6.00977C242.275 5.88607 242.552 5.78027 242.864 5.69238C243.177 5.60449 243.506 5.56055 243.851 5.56055ZM244.935 8.36328L244.119 8.39746C243.452 8.4235 242.981 8.53255 242.708 8.72461C242.435 8.91667 242.298 9.18848 242.298 9.54004C242.298 9.84603 242.391 10.0723 242.576 10.2188C242.762 10.3652 243.007 10.4385 243.313 10.4385C243.789 10.4385 244.178 10.3066 244.48 10.043C244.783 9.7793 244.935 9.38379 244.935 8.85645V8.36328ZM248.68 8.26074L246.829 5.64844H247.757L249.168 7.70898L250.574 5.64844H251.492L249.642 8.26074L251.595 11H250.667L249.168 8.81738L247.659 11H246.741L248.68 8.26074Z" fill="#333333" />
                        <rect width="109" height="3" transform="matrix(-1 0 0 1 252 14)" fill="url(#paint0_linear_10713_26503)" />
                        <defs>
                            <linearGradient id="paint0_linear_10713_26503" x1="0.5" y1="3" x2="109" y2="3.00004" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#757575" />
                                <stop offset="0.23435" stop-color="#9C9C9C" />
                                <stop offset="0.4875" stop-color="#B1B1B1" />
                                <stop offset="0.66521" stop-color="#BBBBBB" />
                                <stop offset="0.827563" stop-color="#D4D4D4" />
                                <stop offset="1" stop-color="#DADADA" />
                            </linearGradient>
                        </defs>
                    </svg>



                </div>
                <div className='heatmap-container'>
                    <div className='heatmap-child-container'>
                        {

                            isClickOnDropDownFilterApply ?
                                <HeatMapWithDropDown slData={sldata}
                                    selectedSelectOptions={selectedSelectOptions}
                                    metricValue = {metricValue}
                                    periodOfStudy= {periodOfStudy}
                                    dayOfStudy= {dayOfStudy}
                                /> :
                                <div className='empty-heatmap-container'>
                                    Start to select the filters
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const DropDownMenu = (props) => {

    const { defaultValue, onChageDropDownValue, dropDownMenu, handleOnDropDownMenuSelectionChange, classLists, isCalendar = false, isMendatory = false, labelClassName = 'drop-down-menu-label-light', selectedDropDownOptioValue = '' } = props;
    //console.log('isMendatory', dropDownMenu.isMendatory);
    //console.log('selectedDropDownOptioValue', selectedDropDownOptioValue);
    const dropDownMenuRef = useRef(null);

    const [isShowDropDownMenu, setIsShowDropDownMenu] = useState(false);
    const [selectedDropDownOption, setSelectedDropDownOption] = useState(defaultValue);
    const [selectedFilterDate, setSelectedFilterDate] = useState(new Date());



    let date = new Date();
    date.setDate(date.getDate() - 30);
    dropDownMenu.options.sort();
    useEffect(() => {
        const handleOnClickOutSide = (event) => {
            if (dropDownMenuRef.current && !dropDownMenuRef.current.contains(event.target)) {
                setIsShowDropDownMenu(false)
            }
        };

        document.addEventListener('click', handleOnClickOutSide, true);

        return () => document.removeEventListener('click', handleOnClickOutSide, true);
    }, [isShowDropDownMenu]);

    return (
        <div className={classLists ? classLists.dropDownMenuContainer : 'drop-down-menu-contaier'} ref={dropDownMenuRef} >
            {!isCalendar ?
                <label className={labelClassName}>{dropDownMenu.label}{isMendatory && '*'}</label> :
                <label className={labelClassName}>Date</label>
            }
            <div className='drop-down-menu'>
                {
                    !isCalendar ?
                        <div className='drop-down-menu-selection-box-main'
                            onClick={(event) => {
                                setIsShowDropDownMenu(!isShowDropDownMenu);
                            }}
                        >
                            {
                                selectedDropDownOption ?
                                    selectedDropDownOption :
                                    <p className='drop-down-menu-selection-box-placeholder'>Select</p>
                            }

                            <div className='drop-down-menu-selection-box-down-arrow-icon'>
                                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.00893 0L4 3.01432L0.991063 0L0 0.992838L4 5L8 0.992838L7.00893 0Z" fill="#808080" />
                                </svg>
                            </div>

                        </div>
                        :
                        <div className='drop-down-menu-selection-box'
                            onClick={(event) => {
                                setIsShowDropDownMenu(!isShowDropDownMenu);
                            }}
                        >

                            {
                                <p className='drop-down-menu-selection-box-placeholder'>{d3.timeFormat("%d/%m/%Y")(selectedFilterDate)}</p>
                            }
                            <div className='drop-down-menu-selection-box-down-arrow-icon'>
                                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.3922 1.45452H9.65777V0.545476C9.65777 0.244143 9.4113 0 9.10731 0C8.80322 0 8.55684 0.244143 8.55684 0.545476V1.45452H4.15432V0.545476C4.15432 0.244143 3.90785 0 3.60386 0C3.29977 0 3.05339 0.244143 3.05339 0.545476V1.45452H2.31907C1.20433 1.45462 0.30107 2.34962 0.300781 3.45452V10C0.301157 11.1048 1.20416 11.9996 2.31907 12H10.3922C11.5072 11.9996 12.4101 11.1048 12.4105 10.0001V3.45462C12.4102 2.34952 11.507 1.45471 10.3922 1.45443L10.3922 1.45452ZM2.31902 2.54529H3.05344V2.7273C3.05344 3.02854 3.29982 3.27277 3.60391 3.27277C3.9079 3.27277 4.15437 3.02854 4.15437 2.7273L4.15428 2.54529H8.55704V2.7273C8.55704 3.02854 8.80341 3.27277 9.1075 3.27277C9.4115 3.27277 9.65797 3.02854 9.65797 2.7273V2.54529H10.3922C10.8987 2.54631 11.3086 2.95248 11.3096 3.4546V3.99802L1.40169 3.99812V3.45469C1.40263 2.95236 1.8126 2.5464 2.31905 2.54528L2.31902 2.54529ZM10.3922 10.9093H2.31902C1.81258 10.9083 1.40243 10.502 1.40166 10.0003V5.08886H11.3096V10.0003C11.3085 10.502 10.8986 10.9082 10.3921 10.9093H10.3922Z" fill="#808080" />
                                </svg>
                            </div>
                        </div>

                }

                {
                    isShowDropDownMenu && !isCalendar ?
                        <div className={classLists ? classLists.dropDownOptionsContainer : 'exe-summ-con-linear-con-drop-down-menu-options-container-syn'}>
                            {
                                dropDownMenu.options.map((item, index) => {
                                    return (
                                        <div className='drop-down-menu-option' key={item}
                                            onClick={(event) => {
                                                setSelectedDropDownOption(item);
                                                onChageDropDownValue(item);
                                                setIsShowDropDownMenu(!isShowDropDownMenu)
                                                handleOnDropDownMenuSelectionChange(dropDownMenu.id, item)
                                            }}
                                        >
                                            {item}
                                        </div>
                                    )
                                })
                            }
                        </div> :
                        isShowDropDownMenu && isCalendar ?
                            <div className='dropdown-calender-container'>
                                <Calendar
                                    minDate={date}
                                    maxDate={new Date()}
                                    calendarType="US"
                                    locale="en"
                                    onChange={setSelectedFilterDate}
                                    value={selectedFilterDate}
                                />
                            </div>
                            : null
                }
            </div>
        </div>
    )

}

const MultiSelectDropDownMenu = (props) => {

    const {

        multiSelectDropDownConfigs,
        isMultiselectDropDownForProductAdvertisement = false,
        channelFilteredValues
    } = props;

    console.log('multiSelectDropDownConfigs realdata props though passed', channelFilteredValues)

    const multiSelectDropDownMenuRef = useRef(null);
    const [isShowDropDownMenu, setIsShowDropDownMenu] = useState(false);

    var initialStateObject = {};

    let myChanelValues = [];


    if (channelFilteredValues !== undefined && channelFilteredValues.length > 0) {
        initialStateObject = {}
        myChanelValues = []
        myChanelValues = channelFilteredValues.map((ele) => ele.Channel_Name_Barc)
        myChanelValues.sort();
        myChanelValues.unshift('All');

        // multiSelectDropDownOptions =
        //     [
        //         "All",
        //         "Channel name 1", "Channel name 2", "Channel name 3", "Channel name 4", "Channel name 5", 
        //     ]
        // myChanelValues

        myChanelValues.map(element => initialStateObject[element] = true);
    }


    console.log('mychannnelll vaaalluuueeeee', initialStateObject);


    const [selectedSelectOptions, setSelectedSelectOptions] = useState();
    const [previousSelectedSelectOptions, setPreviousSelectedSelectOptions] = useState();

    //console.log('selectedSelectOptions selectedSelectOptions selectedSelectOptions selectedSelectOptions',selectedSelectOptions);

    useEffect(() => {
        const handleOnClickOutSide = (event) => {
            //console.log(event.target);
            if (multiSelectDropDownMenuRef.current && !multiSelectDropDownMenuRef.current.contains(event.target)) {
                setIsShowDropDownMenu(false)
            }
        };

        document.addEventListener('click', handleOnClickOutSide, true);

        if(selectedSelectOptions == undefined){
            setSelectedSelectOptions(initialStateObject)
            setPreviousSelectedSelectOptions(initialStateObject)
        }else{
            if(selectedSelectOptions.undefined == true || selectedSelectOptions.undefined == false){
                setSelectedSelectOptions(initialStateObject)
                setPreviousSelectedSelectOptions(initialStateObject)
            }
        }
        
        return () => document.removeEventListener('click', handleOnClickOutSide, true);
    }, [isShowDropDownMenu]);

    console.log('selectedSelectOptions', selectedSelectOptions);
    console.log('selectedSelectOptions', previousSelectedSelectOptions);


    const handleSelection = (option, event) => {
        console.log('slecejkllllllllllllllllll', selectedSelectOptions);
        if (option === 'All') {
            let setStateObject = {}
            for (let item in selectedSelectOptions) {
                setStateObject[item] = !selectedSelectOptions.All;
            }
            setSelectedSelectOptions(setStateObject);
        } else if (selectedSelectOptions['All'] === true && selectedSelectOptions[option] === true) {

            setSelectedSelectOptions(previous => {
                return {
                    ...selectedSelectOptions,
                    ['All']: false,
                    [option]: !previous[option],
                }
            })
            console.log('//console.log(selectedSelectOptions);//console.log(selectedSelectOptions);//console.log(selectedSelectOptions);', selectedSelectOptions);
        } else if (selectedSelectOptions['All'] === false) {
            setSelectedSelectOptions(previous => {
                return {
                    ...selectedSelectOptions,
                    ['All']: false,
                    [option]: !previous[option],
                }
            })
            console.log('//console.log(selectedSelectOptions); //console.log(selectedSelectOptions);', selectedSelectOptions);
        }

    }

    const getPlaceholderForMultiselectMenu = () => {
        for (let item in selectedSelectOptions) {
            if (selectedSelectOptions.All === true) {
                return <p className='drop-down-menu-selection-box-placeholder'>All</p>
            } else if (selectedSelectOptions[item] === true) {
                return <p className='drop-down-menu-selection-box-placeholder'>Multiple Selected</p>
            }
        }
    }

    useEffect(() => {
        setPreviousSelectedSelectOptions(selectedSelectOptions);
        props.handleMultiDropDownSelectionChange("ok", selectedSelectOptions)
    }, [initialStateObject, selectedSelectOptions])

    return (
        <div className={isMultiselectDropDownForProductAdvertisement ? 'multiselect-dropwn-menu-container-for-product-advertisement' : 'multiselect-dropwn-menu-container'}>
            <label className='drop-down-menu-label-light'>{
                multiSelectDropDownConfigs ? 'Day of Study *' : 'Channel *'
            }</label>
            {
                <div className='drop-down-menu-selection-box'
                    onClick={(event) => {
                        setIsShowDropDownMenu(!isShowDropDownMenu);
                    }}
                >
                    {
                        getPlaceholderForMultiselectMenu()
                    }

                    <div className='drop-down-menu-selection-box-down-arrow-icon'>
                        <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.00893 0L4 3.01432L0.991063 0L0 0.992838L4 5L8 0.992838L7.00893 0Z" fill="#808080" />
                        </svg>

                    </div>
                </div>
            }

            {isShowDropDownMenu &&
                <div className={
                    isMultiselectDropDownForProductAdvertisement ?
                        'multiselect-drop-down-menu-options-parent-container-for-product-advertisement' :
                        'exe-summ-con-linear-con-multiselect-drop-down-menu-options-parent-container-syn'}
                    ref={multiSelectDropDownMenuRef}
                >

                    {myChanelValues.map((option, index) => {
                        return (
                            <div className='multiselect-dropwn-menu-option-container' onClick={(event) => handleSelection(option, event)} key={index}>
                                <div className='multiselect-dropwn-menu-option-checkbox'>
                                    {
                                        selectedSelectOptions[option] ?
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="12" height="12" rx="2" fill="#6979F8" />
                                                <path fill-rule="evenodd" clipRule="evenodd" d="M5.17695 9.10517L10.166 4.03856C10.3264 3.87573 10.3264 3.61174 10.166 3.44891L9.62018 2.89458C9.45984 2.73175 9.19988 2.73175 9.03954 2.89458L4.88663 7.11203L2.96157 5.15705C2.80123 4.99422 2.54127 4.99422 2.38094 5.15705L1.8351 5.71137C1.67476 5.8742 1.67476 6.1382 1.8351 6.30103L4.04877 8.54911C4.04933 8.54969 4.0499 8.55027 4.05047 8.55085L4.59629 9.10513L4.59631 9.10517C4.75665 9.268 5.01661 9.268 5.17695 9.10517Z" fill="white" />
                                            </svg>
                                            :
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="0.5" y="0.5" width="11" height="11" rx="1.5" fill="white" stroke="#D8D8D8" />
                                            </svg>
                                    }
                                </div>
                                <div className='multiselect-dropwn-menu-option-option'>
                                    {option}
                                </div>
                            </div>
                        )
                    })}
                    <div className='multiselect-dropdown-menu-options-action-container'>
                        <button className='multiselect-dropdown-menu-options-action-cancle-btn' onClick={
                            (event) => {
                                setSelectedSelectOptions(previousSelectedSelectOptions);
                                props.handleMultiDropDownSelectionChange("cancle", previousSelectedSelectOptions)
                                setIsShowDropDownMenu(!isShowDropDownMenu);
                            }
                        }>Cancel</button>
                        <button className='multiselect-dropdown-menu-options-action-ok-btn'
                            onClick={
                                (event) => {
                                    //console.log('selectedSelectOptions pressed ommmmm,,,,,mmmkkkkkk',selectedSelectOptions);
                                    event.preventDefault();
                                    setPreviousSelectedSelectOptions(selectedSelectOptions);
                                    props.handleMultiDropDownSelectionChange("ok", selectedSelectOptions)
                                    setIsShowDropDownMenu(!isShowDropDownMenu);
                                }}
                        >
                            Ok</button>
                    </div>
                </div>

            }

        </div>
    )
}

export default ExecutiveSummContLinSlotLeadership;