const sampleDataForStackedAreaChart = [
    {
        week: 'W1',
        vastError: 3,
        adImpressions: 4,
        codeServed: 2,
        attemps: 2,
    },
    {
        week: 'W2',
        vastError: 4,
        adImpressions: 6,
        codeServed: 1,
        attemps: 3,
    },
    {
        week: 'W3',
        vastError: 5,
        adImpressions: 3,
        codeServed: 1,
        attemps: 5,
    },
    {
        week: 'W4',
        vastError: 2,
        adImpressions: 7,
        codeServed: 5,
        attemps: 1,
    },
    {
        week: 'W5',
        vastError: 5,
        adImpressions: 5,
        codeServed: 3,
        attemps: 2,
    },
    {
        week: 'W6',
        vastError: 4,
        adImpressions: 2,
        codeServed: 3,
        attemps: 2,
    },
    {
        week: 'W7',
        vastError: 1,
        adImpressions: 2,
        codeServed: 5,
        attemps: 4,
    },
    {
        week: 'W8',
        vastError: 3,
        adImpressions: 6,
        codeServed: 4,
        attemps: 1,
    },
    {
        week: 'W9',
        vastError: 2,
        adImpressions: 2,
        codeServed: 5,
        attemps: 3,
    },
    {
        week: 'W10',
        vastError: 3,
        adImpressions: 5,
        codeServed: 4,
        attemps: 2,
    },
    {
        week: 'W11',
        vastError: 6,
        adImpressions: 3,
        codeServed: 4,
        attemps: 2,
    },
    {
        week: 'W12',
        vastError: 4,
        adImpressions: 6,
        codeServed: 2,
        attemps: 4,
    },

]

const keys = ['attemps', 'codeServed', 'adImpressions', 'vastError'];

export {
    sampleDataForStackedAreaChart,
    keys
}