import React, { useEffect, useRef, useLayoutEffect } from "react";
import * as d3 from 'd3v4';
import './OverlapedBarChart.css'

const OverlapedBarChart = (props) => {
  const { data } = props;
  // console.log('data', data);
  const refSvgBar = useRef();
  const overlappedBarChartRef = useRef();
  const overLappedBarChartYAxisRef = useRef();
  const overlappedBarChartXAxisAndBarsRef = useRef();

  // document.addEventListener("scroll", function () {
  //   let header = document.getElementById("y-axis-for-overlapped-barchart");
  //   header.classList.toggle("y-axis-for-overlapped-barchart-sticky", window.scrollX > 1);
  // })

  useLayoutEffect(() => {

    const achievement = [
        { letter: 'Apr ’21', frequency: 10 },
        { letter: 'May ’21', frequency: 40 },
        { letter: 'Jun ’21', frequency: 40 },
        { letter: 'Jul ’21', frequency: 40 },
        { letter: 'Aug ’21', frequency: 40 },
        { letter: 'Sep ’21', frequency: 70 },
        { letter: 'Oct ’21', frequency: 70 },
        { letter: 'Nov ’21', frequency: 70 },
        { letter: 'Dec ’21', frequency: 30 },
        { letter: 'Jan ’22', frequency: 30 },
        { letter: 'Feb ’22', frequency: 30 },
        { letter: 'Mar ’22', frequency: 60 },
        { letter: 'Apr ’22', frequency: 60 },
        { letter: 'May ’22', frequency: 60 },
        { letter: 'Jun ’22', frequency: 60 },
        { letter: 'Jul ’22', frequency: 60 },
        { letter: 'Aug ’22', frequency: 20 },
        { letter: 'Sep ’22', frequency: 20 },
        { letter: 'Oct ’22', frequency: 20 },
        { letter: 'Nov ’22', frequency: 20 },
        { letter: 'Dec ’22', frequency: 10 },
        { letter: 'Jan ’23', frequency: 15 },
        { letter: 'Feb ’23', frequency: 15 },
        { letter: 'Mar ’23', frequency: 10 },
      ];

      const target = [
        { letter: 'Apr ’21', frequency: 10 },
        { letter: 'May ’21', frequency: 40 },
        { letter: 'Jun ’21', frequency: 40 },
        { letter: 'Jul ’21', frequency: 40 },
        { letter: 'Aug ’21', frequency: 40 },
        { letter: 'Sep ’21', frequency: 70 },
        { letter: 'Oct ’21', frequency: 70 },
        { letter: 'Nov ’21', frequency: 70 },
        { letter: 'Dec ’21', frequency: 30 },
        { letter: 'Jan ’22', frequency: 30 },
        { letter: 'Feb ’22', frequency: 30 },
        { letter: 'Mar ’22', frequency: 60 },
        { letter: 'Apr ’22', frequency: 60 },
        { letter: 'May ’22', frequency: 60 },
        { letter: 'Jun ’22', frequency: 60 },
        { letter: 'Jul ’22', frequency: 60 },
        { letter: 'Aug ’22', frequency: 20 },
        { letter: 'Sep ’22', frequency: 20 },
        { letter: 'Oct ’22', frequency: 20 },
        { letter: 'Nov ’22', frequency: 20 },
        { letter: 'Dec ’22', frequency: 15 },
        { letter: 'Jan ’23', frequency: 15 },
        { letter: 'Feb ’23', frequency: 15 },
        { letter: 'Mar ’23', frequency: 15 },
      ];

    const margin = { top: 0, right: 20, bottom: 30, left: 30 };
    const width = 1560 - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;

    const xScale = d3.scaleBand()
      .range([0, width])
      .round(true)
      .paddingInner(0.1); // space between bars (it's a ratio)

    const yScale = d3.scaleLinear()
      .range([height, 0]);

    xScale
      .domain(achievement.map(d => d.monthYear));
    yScale
      .domain([0, d3.max(achievement, d => d.value) + d3.max(achievement, d => d.value) * 0.1]);

    const xAxis = d3.axisBottom()
      .scale(xScale).tickSize(-height)
      .tickFormat(d => {
        const date = new Date(d)
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'short' });
        const formattedDate = `${year}-${month}`;
        return formattedDate
      });

    const yAxis = d3.axisLeft()
      .scale(yScale)
      .tickSize(-width)
      .tickFormat(d => {
        return d.toFixed(0)
      })

    // const yAxisForSvg = d3.axisLeft()
    //   .scale(yScale)
    //   .tickSize(-width)
    //   .tickFormat(d => {
    //     return (d / 1000).toFixed(1)
    //   })


    const svg = d3.select(refSvgBar.current)
      // .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(0, 0)`);
    // .attr('transform', `translate(${margin.left}, ${margin.right})`);

    const yAxisSVG = d3.select(overLappedBarChartYAxisRef.current)
      .attr('width', 70)
      .attr('height', height + margin.top + margin.bottom)
      .attr('transform', `translate(0, 0)`);

    svg
      .selectAll(".tick")
      .select("line")
      .attr("stroke", "#D8D8D8")
      .attr("stroke-width", "1")
      .attr("opacity", "1")
      .attr("stroke-dasharray", "2");

    const tooltip = d3.select('.overlaped-chart').append('div')
      .attr('class', 'overlaped-bar-tooltip')
      .style('opacity', 0);

    svg.append('g')
      .attr('class', 'x-axis-for-overlapped-barchart')
      .attr('transform', `translate(0, ${height})`)
      .call(xAxis);

    svg.selectAll('.tick')
      .select('text')
      .attr("fill", "#808080")
      .attr("opacity", "1")
      .attr("font-size", "12px !important")

    yAxisSVG.append('g')
      .attr('class', 'y-axis-for-overlapped-barchart')
      .attr('id', 'y-axis-for-overlapped-barchart')
      // .attr("height", 300)
      .call(yAxis)
      .append('text')
      .attr("fill", "#808080")
      .attr("opacity", "1")
      .attr('y', 0)
      .attr('dy', '.91em')
      .style('text-anchor', 'end')
      .text('INR Bn.');

    svg.append('g')
      .attr('class', 'y-axis-for-overlapped-barchart')
      .attr('id', 'y-axis-for-overlapped-barchart')
      .call(yAxis)
      .append('text')
      .attr("fill", "#808080")
      .attr("opacity", "1")
      .attr('y', -5)
      .attr('dy', '.91em')
      .style('text-anchor', 'end')
      .text('INR Bn.');

    yAxisSVG
      .select('.y-axis-for-overlapped-barchart')
      .selectAll('.tick')
      .select('line')
      .attr("stroke", "#D8D8D8")
      .attr("stroke-width", "1")
      .attr("stroke-dasharray", "0.8");

    yAxisSVG.selectAll('.tick')
      .select('text')
      .attr("fill", "#808080")
      .attr("opacity", "1")
      .attr("font-size", "12px !important")

    yAxisSVG.select('.y-axis-for-overlapped-barchart')
      .selectAll('.tick')
      .select('text')
      .style('text-anchor', 'start')
      .attr('x', 0)
      .attr('dy', -2);

    svg.selectAll('.bare')
      .data(target)
      .enter()
      .append('rect')
      .attr('class', 'bare')
      .attr('x', d => xScale(d.monthYear) + 10)
      .attr('width', xScale.bandwidth() - 20)
      .attr('y', d => yScale(d.value))
      .attr('height', d => height - yScale(d.value))
      .on('mouseover', (d) => {

        const date = new Date(d.monthYear)
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'long' });
        const formattedDate = `${month} ${year}`;

        tooltip
          .transition()
          .duration(50)
          .style('opacity', 1);
        tooltip.html(`
           <p class="overlapped-bar-chart-tooltip-title">${formattedDate}</p>
           <p class="overlapped-bar-chart-tooltip-value">Target: ${(d.value).toFixed(2)}</p>
            `)
          .style("left", d3.event.view.innerWidth - d3.event.pageX <= 180 ? d3.event.pageX - 180 + "px" : d3.event.pageX + "px")
          .classed('overlapped-bar-chart-tooltip-container', true)
          .style("top", d3.event.pageY - 145 + "px");
      })
      .on('mouseout', () => tooltip.transition().duration(500).style('opacity', 0));

    svg.selectAll('.o-bar')
      .data(achievement)
      .enter()
      .append('rect')
      .attr('class', 'o-bar')
      .attr('x', d => xScale(d.monthYear) + 18)
      .attr('width', xScale.bandwidth() - 36)
      .attr('y', d => yScale(d.value))
      .attr('height', d => height - yScale(d.value))
      .style("fill", d => d.fill)
      .on('mouseover', (d, event) => {

        const date = new Date(d.monthYear)
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'long' });
        const formattedDate = `${month} ${year}`;

        tooltip
          .transition()
          .duration(50)
          .style('opacity', 1);
        tooltip.html(`
         <p class="overlapped-bar-chart-tooltip-title">${formattedDate}</p>
         <p class="overlapped-bar-chart-tooltip-value">Revenue Delievered: ${(d.value).toFixed(2)}</p>
          `)
          .style("left", d3.event.view.innerWidth - d3.event.pageX <= 180 ? d3.event.pageX - 180 + "px" : d3.event.pageX + "px")
          .classed('overlapped-bar-chart-tooltip-container', true)
          .style("top", d3.event.pageY - 145 + "px");
      })
      .on('mouseout', () => tooltip.transition().duration(500).style('opacity', 0));

    let overlappedBarChartScroll = d3.select(overlappedBarChartXAxisAndBarsRef.current);
    overlappedBarChartScroll.node().scrollLeft = overlappedBarChartScroll.node().scrollWidth

  }, [])
  return (
    <div className="overlaped-chart" id="overlaped-chart-container" ref={overlappedBarChartRef}>
      <text className="overlapped-bar-chart-yaxis-container-text">INR Mn.</text>
      <div className="overlapped-bar-chart-chart-container">
        <div className="overlapped-bar-chart-yaxis-container">
          <svg ref={overLappedBarChartYAxisRef}></svg>
          {/* <div className="overlapped-bar-chart-yaxis-container-paceholder"></div> */}
        </div>
        <div className="overlapped-bar-chart-bar-xaxis-container" ref={overlappedBarChartXAxisAndBarsRef}>
          <svg ref={refSvgBar}></svg>
        </div>
      </div>
    </div>
  )
}

export default OverlapedBarChart;