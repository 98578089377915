import React, { useState } from "react";
import ".\/ExecutiveSummaryContentLinear.css";
import BubbleChart from "../../Charts/BubbleChart/BubbleChart";
import FrontBar from "../../Charts/BubbleChart/LinearBarchart/FrontBar";
import ExecutiveSummaryContentLinearContents from "./ExecutiveSummaryContentLinearContents";
import ExecutiveSummaryContentLinearButtonsTabs from "./ExecutiveSummaryContentLinearButtonsTabs";
import { useEffect } from "react";

import Background from '..\/..\/Images\/white.jpg'
import LoderSpinner from '.\/LoderSpinner'
import noPreview from '..\/..\/Images\/noimage.jpg'
import serialLogo0 from '..\/..\/Images\/Bhagya Lakshmi.png'
import serialLogo1 from '..\/..\/Images\/image1.svg'
import serialLogo2 from '..\/..\/Images\/image2.svg'
import serialLogo3 from '..\/..\/Images\/Pyaar Ka Pehla Naam Radha Mohan.png'
import serialLogo4 from '..\/..\/Images\/Main Hoon Aparajita.png'
import serialLogo5 from '..\/..\/Images\/Rabb Se Hai Dua.png'
import serialLogo6 from '..\/..\/Images\/Maitree.png'
import serialLogo7 from '..\/..\/Images\/Meet.png'
import serialLogo8 from '..\/..\/Images\/Lag Ja Gale.jpg'
import serialLogo9 from '..\/..\/Images\/Bhabhiji Ghar Par Hain.png'
import serialLogo10 from '..\/..\/Images\/image10.svg'

import zeeLogoViewer from '..\/..\/..\/Components\/Images\/zee-viewer.svg';
import zeeLogoSony from '..\/..\/Images\/sony.svg';
import zeeLogov18 from '..\/..\/Images\/v18.svg';
import zeeLogoSunTv from '..\/..\/Images\/Sun-tv.svg';
import zeeLogoStar from '..\/..\/Images\/star.svg';
import zeeLogoDsney from '..\/..\/Images\/disney.svg'

import compatitionLogo1 from '..\/..\/Images\/ANUPAMAA.png'
import compatitionLogo2 from '..\/..\/Images\/GHUM HAI KISIKEY PYAAR MEIIN.png'
import compatitionLogo3 from '..\/..\/Images\/YEH RISHTA KYA KEHLATA HAI.png'
import compatitionLogo4 from '..\/..\/Images\/IMLI.png'
import compatitionLogo5 from '..\/..\/Images\/FALTU.png'
import compatitionLogo6 from '..\/..\/Images\/YEH HAI CHAHATEIN.png'
import compatitionLogo7 from '..\/..\/Images\/PANDYA STORE.png'
import compatitionLogo8 from '..\/..\/Images\/TAARAK MEHTA KA OOLTAH CHASHMA.png'
import compatitionLogo9 from '..\/..\/Images\/NAAGIN 6.png'
import compatitionLogo10 from '..\/..\/Images\/TERI MERI DORIYAANN.png'

import zeeLogo from '..\/..\/Images\/zee-image.svg'
import AndTv from '..\/..\/Images\/&TV.png'

import clogo1 from '..\/..\/Images\/colorslogo.png'
import clogo2 from '..\/..\/Images\/colorslogo.png'
import clogo3 from '..\/..\/Images\/starplus.png'
import clogo4 from '..\/..\/Images\/sony.png'
import clogo5 from '..\/..\/Images\/sony.png'
import clogo6 from '..\/..\/Images\/starplus.png'
import clogo7 from '..\/..\/Images\/starplus.png'
import clogo8 from '..\/..\/Images\/starplus.png'
import clogo9 from '..\/..\/Images\/sab.png'
import clogo10 from '..\/..\/Images\/colorslogo.png'


import PlotChart from "..\/..\/Charts\/PlotChart\/PlotChart";
import PlotChartStar from "..\/..\/Charts\/PlotChart\/PlotChartStar";
import PlotChartStarSun from "..\/..\/Charts\/PlotChart\/PlotChartStarSun";
import PlotChartV18 from "..\/..\/Charts\/PlotChart\/PlotChartV18";
import PlotChartSony from "..\/..\/Charts\/PlotChart\/PlotChartSony";

import ExecutiveSummaryConLinearZeeProgramsCard from ".\/ExecutiveSummaryConLinearZeeProgramsCard";
import ContentTabStackBarChart from "..\/..\/Charts\/BarChart\/ContentTabStackBarChart";
import ContentLinearViewershipLineChart from "..\/..\/Charts\/LineChart\/ContentLinearViewershipLineChart";
import ContentLinearViewershipLineChartSouthRegionalGECs from "..\/..\/Charts\/LineChart\/ContentLinearViewershipLineChartSouthRegionalGECs";
import ContentLinearViewershipLineChartHindiGEC from "..\/..\/Charts\/LineChart\/ContentLinearViewershipLineChartHindiGEC";
import ContentLinearViewershipLineChartHindiMovies from "..\/..\/Charts\/LineChart\/ContentLinearViewershipLineChartHindiMovies";
import ContentLinearViewershipLineChartHSMRegionalGECs from "..\/..\/Charts\/LineChart\/ContentLinearViewershipLineChartHSMRegionalGECs";
import ContentLinearViewershipLineChartNews from "..\/..\/Charts\/LineChart\/ContentLinearViewershipLineChartNews";
import ContentLinearViewershipLineChartNewsSouthRegionalMovies from "..\/..\/Charts\/LineChart\/ContentLinearViewershipLineChartNewsSouthRegionalMovies";
import ContentLinearViewershipLineChartKids from "..\/..\/Charts\/LineChart\/ContentLinearViewershipLineChartKids";
import ContentLinearViewershipLineChartMusic from "..\/..\/Charts\/LineChart\/ContentLinearViewershipLineChartMusic";
import ContentLinearViewershipLineChartNewsSouthRegionalNews from "..\/..\/Charts\/LineChart\/ContentLinearViewershipLineChartNewsSouthRegionalMovies";
import ContentLinearViewershipLineChartSouthRegionalMovies from "..\/..\/Charts\/LineChart\/ContentLinearViewershipLineChartSouthRegionalMovies";
import ContentLinearViewershipLineChartOthers from "..\/..\/Charts\/LineChart\/ContentLinearViewershipLineChartOthers";
import ContentLinearViewershipLineChartHSMRegionalMovies from "..\/..\/Charts\/LineChart\/ContentLinearViewershipLineChartHSMRegionalMovies";
import ContentLinearViewershipLineChartSport from "..\/..\/Charts\/LineChart\/ContentLinearViewershipLineChartSport";
import { useMediaQuery } from "@material-ui\/core";
import ExecutiveSummContLinSlotLeadership from ".\/ExecutiveSummContLinSlotLeadership";
import ExecutiveSummaryServicesConsumer from "..\/..\/..\/Services\/ExecutiveSummaryServices\/ExecutiveSummaryServicesConsumer";
import ExecutiveSummaryServicesContent from "..\/..\/..\/Services\/ExecutiveSummaryServices\/ExecutiveSummaryServicesContent";
import data from ".\/Topprogram_static_data"
import LoderLinear from ".\/LoaderLinear";
import ContentSchedulingWithHeatMapAndDropdowns from "..\/..\/ContentTab\/ContentSchedulingWithHeatMapAndDropdowns\/ContentSchedulingWithHeatMapAndDropdowns";

const ExecutiveSummaryContentLinear = (props) => {

  const { dataSetNeteorkShareZee, zeeCWTotal, zeeLWTotal, sunCWTotal, sunLWTotal, starCWTotal, starLWTotal,
    viaComCWTotal, viaComLWTotal, sonyCWTotal, sonyWTotal, disneyCWTotal, disneyLWTotal,
    dataSetNeteorkShareStar,
    dataSetNeteorkShareSuntv,
    dataSetNeteorkShareViaCom,
    dataSetNeteorkShareSony,
    dataSetNeteorkShareDisney, loadingLinear } = props

  const { dataSetNeteorkShareZee15, dataSetNeteorkShareStar15, dataSetNeteorkShareSuntv15, dataSetNeteorkShareViaCom15, dataSetNeteorkShareSony15, dataSetNeteorkShareDisney15 } = props;

  const [activeTab, setActiveTab] = useState("all-content");
  const [optionToggle, setOptionToggle] = useState(false);
  const [buttonTab, setButtonTab] = useState('Viewership')


  const [filterSelectValueWeek, setFilterSelectValueWeek] = useState('9');
  const [optionToggleWeek, setOptionToggleWeek] = useState(false);

  const [filterSelectValueTarget, setFilterSelectValueTarget] = useState('15');
  const [optionToggleTarget, setOptionToggleTarget] = useState(false);

  const testData = [3564, 3336, 3427, 3672, 3539]
  const [Loader, setLoader] = useState(false)

  //set genre trends data
  const [HSMRegionalMovies, setHSMRegionalMovies] = useState([])
  const [Sport, setSport] = useState([])
  const [Others, setOthers] = useState([])
  const [News, setNews] = useState([])
  const [Music, setMusic] = useState([])
  const [Kids, setKids] = useState([])
  const [SouthRegionalMovies, setSouthRegionalMovies] = useState([])
  const [HSMRegionalGECs, setHSMRegionalGECs] = useState([])
  const [SouthRegionalGECs, setSouthRegionalGECs] = useState([])
  const [HindiGECCW, setHindiGECCW] = useState([])
  const [hindiMoviesCWResult, setHindiMoviesCWResult] = useState([]);
  const [constTotalDataChart2, setconstTotalDataChart2] = useState([])
  const [constTotalDataChart15, setconstTotalDataChart15] = useState([])
  const [genreTrandsDate, setgenreTrandsDate] = useState(null)



  //key movement const
  const [zeeKMCW, setzeeKMCW] = useState([])
  const [zeeKMLW, setzeeKMLW] = useState([])
  const [starKMCW, setstarKMCW] = useState([])
  const [starKMLW, setstarKMLW] = useState([])
  const [sunKMCW, setsunKMCW] = useState([])
  const [sunKMLW, setsunKMLW] = useState([])
  const [viacomKMCW, setviacomKMCW] = useState([])
  const [viacomKMLW, setviacomKMLW] = useState([])
  const [sonyKMCW, setsonyKMCW] = useState([])
  const [sonyKMLW, setsonyKMLW] = useState([])

  const [channelGenre, setChannelGenre] = useState([]);
  const [network1, setNetwork1] = useState([]);
  const [network2, setNetwork2] = useState([]);
  const [network3, setNetwork3] = useState([]);
  const [network4, setNetwork4] = useState([]);
  const [network5, setNetwork5] = useState([]);
  const [network6, setNetwork6] = useState([]);
  const [network7, setNetwork7] = useState([]);
  const [network8, setNetwork8] = useState([]);

  const [keyChannelMoveSun, setKeyChannelMoveSun] = useState([]);
  const [keyChannelMoveStar, setKeyChannelMoveStar] = useState([]);
  const [keyChannelMoveZee, setKeyChannelMoveZee] = useState([]);
  const [keyChannelMoveViacom, setKeyChannelMoveViacom] = useState([]);
  const [keyChannelMoveSony, setKeyChannelMoveSony] = useState([]);


  //top progrms const
  const [ZeeTopPograms, setZeeTopPograms] = useState(null)
  const [OverallTP, setOverallTP] = useState(null)
  const [FilterTP, setFilterTP] = useState(null)
  // const of set filter data
  const [ZeeProgramFilter, setZeeProgramFilter] = useState([])
  const [OverallProgramFilter, setOverallProgramFilter] = useState([])
  const [StarProgramFilter, setStarProgramFilter] = useState([])
  const [viacomProgramFilter, setviacomProgramFilter] = useState([])
  const [SunProgramFilter, setSunProgramFilter] = useState([])
  const [EtvProgramFilter, setEtvProgramFilter] = useState([])
  const [OdishaProgramFilter, setOdishaProgramFilter] = useState([])

  const [ZeeProgramObjFilter, setZeeProgramObjFilter] = useState([])
  // const of drop down value
  const [filterSelectValue, setFilterSelectValue] = useState('');
  const [TargetMarket, setTargetMarket] = useState('')
  const [TargerData, setTargetData] = useState('')

  const [allGenerData, setAllGenerData] = useState(null);

  // useEffect(() => {
  //   if (allGenerData !== null) {
  //     props.WeekAndDate([allGenerData.HindiMoviesCW2[0].CRWEEK, allGenerData.HindiMoviesCW2[0].end_date_of_week.value])

  //   }
  // }, [allGenerData])


  //top programs 
  const LoadTopPogramsData = async () => {

    const FilterTPResult = await ExecutiveSummaryServicesContent.FilterTP()
    setFilterTP(FilterTPResult)


    const ZeeTopPogramsResult = await ExecutiveSummaryServicesContent.ZeeTopPograms()
    setZeeTopPograms(ZeeTopPogramsResult)

    const OverallTPResult = await ExecutiveSummaryServicesContent.OverallTP()
    setOverallTP(OverallTPResult)
    setFilterSelectValue("Hindi GEC (HD)")
    setTargetMarket("HSM Urban")
    setTargetData("IND 2+ YRS")
    console.log(ZeeTopPograms, "ZeeTopPograms", OverallTPResult, "OverallTPResult", FilterTP, "FilterTP")
  }

  //const filterResultZee = data.data.filter(item => item.chv_channel_genre === ('Hindi GEC - Paid'))



  useEffect(() => {
    //LoadKeyNetworkData()
    // LoadTopPogramsData()
  }, [])

  useEffect(() => {
    // LoadGenreTrendsData()

  }, [filterSelectValueTarget])

  const LoadDropDown = () => {
    let filteredArray = null;
    if (FilterTP) {
      filteredArray = FilterTP.filter((obj, index, self) => {
        return index === self.findIndex((o) => o.chv_channel_genre === obj.chv_channel_genre);
      });
      setZeeProgramObjFilter(filteredArray)
    }
  }


  useEffect(() => {
    // LoadDropDown()
  }, [FilterTP])

  useEffect(() => {

    // if (ZeeTopPograms) {
    //   console.log(TargerData, "TargerData", TargetMarket, "TargetMarket- ");

    //   const filterResultZeeTest = ZeeTopPograms.filter(item => item.chv_channel_genre === (filterSelectValue) && item.Targets === (TargerData) && item.Regions === (TargetMarket))
    //   setZeeProgramFilter(filterResultZeeTest)
    //   console.log(filterResultZeeTest, "filter zeee  test");

    // }
    // if (OverallTP) {
    //   const filterResultOverall = OverallTP.filter(item => item.chv_channel_genre === (filterSelectValue) && item.Targets === (TargerData) && item.Regions === (TargetMarket))
    //   setOverallProgramFilter(filterResultOverall)

    //   const filterResultStar = OverallTP.filter(item => item.chv_channel_genre === (filterSelectValue) && item.chv_network_name === "Star Network Ent" && item.Targets === (TargerData) && item.Regions === (TargetMarket))
    //   setStarProgramFilter(filterResultStar)

    //   const filterResultViacom = OverallTP.filter(item => item.chv_channel_genre === (filterSelectValue) && item.chv_network_name === "Viacom" && item.Targets === (TargerData) && item.Regions === (TargetMarket))
    //   setviacomProgramFilter(filterResultViacom)

    //   const filterResultSunTv = OverallTP.filter(item => item.chv_channel_genre === (filterSelectValue) && item.chv_network_name === "Sun" && item.Targets === (TargerData) && item.Regions === (TargetMarket))
    //   setSunProgramFilter(filterResultSunTv)

    //   const filterEtvResult = OverallTP.filter(item => item.chv_channel_genre === (filterSelectValue) && item.chv_network_name === "Sony Network Ent" && item.Targets === (TargerData) && item.Regions === (TargetMarket))
    //   setEtvProgramFilter(filterEtvResult)

    //   const filterResultOdisha = OverallTP.filter(item => item.chv_channel_genre === (filterSelectValue) && item.chv_network_name === "Disney" && item.Targets === (TargerData) && item.Regions === (TargetMarket))
    //   setOdishaProgramFilter(filterResultOdisha)
    // }


  }, [filterSelectValue, TargerData, TargetMarket])
  console.log(OverallProgramFilter, "OverallProgramFilter-----------------------------");
  console.log(ZeeProgramFilter, "OverallProgramFilter--------------------------------------");

  const handleOptionClick = (e, b, c) => {
    setOptionToggle(false)
    setFilterSelectValue(e)
    setTargetData(b)
    setTargetMarket(c)

  }

  //Key network data
  const LoadKeyNetworkData = async () => {

    const zeeKMCWResult = await ExecutiveSummaryServicesContent.zeeKMCW()
    const zeeKMLWResult = await ExecutiveSummaryServicesContent.zeeKMLW()
    setzeeKMCW(zeeKMCWResult[0])
    setzeeKMLW(zeeKMLWResult[0])

    const starKMCWResult = await ExecutiveSummaryServicesContent.starKMCW()
    const starKMLWResult = await ExecutiveSummaryServicesContent.starKMLW()
    setstarKMCW(starKMCWResult[0])
    setstarKMLW(starKMLWResult[0])

    const sunKMCWResult = await ExecutiveSummaryServicesContent.sunKMCW()
    const sunKMLWResult = await ExecutiveSummaryServicesContent.sunKMLW()
    setsunKMCW(sunKMCWResult[0])
    setsunKMLW(sunKMLWResult[0])

    const viacomKMCWResult = await ExecutiveSummaryServicesContent.viacomKMCW()
    const viacomKMLWResult = await ExecutiveSummaryServicesContent.viacomKMLW()
    setviacomKMCW(viacomKMCWResult[0])
    setviacomKMLW(viacomKMLWResult[0])

    const sonyKMCWResult = await ExecutiveSummaryServicesContent.sonyKMCW()
    const sonyKMLWResult = await ExecutiveSummaryServicesContent.sonyKMLW()
    setsonyKMCW(sonyKMCWResult[0])
    setsonyKMLW(sonyKMLWResult[0])

    const channelGenreResult = await ExecutiveSummaryServicesContent.channel_genre()
    setChannelGenre(channelGenreResult)
    const network1Result = await ExecutiveSummaryServicesContent.network1()
    setNetwork1(network1Result)
    const network2Result = await ExecutiveSummaryServicesContent.network2()
    setNetwork2(network2Result)
    const network3Result = await ExecutiveSummaryServicesContent.network3()
    setNetwork3(network3Result)
    const network4Result = await ExecutiveSummaryServicesContent.network4()
    setNetwork4(network4Result)
    const network5Result = await ExecutiveSummaryServicesContent.network5()
    setNetwork5(network5Result)
    const network6Result = await ExecutiveSummaryServicesContent.network6()
    setNetwork6(network6Result)
    const network7Result = await ExecutiveSummaryServicesContent.network7()
    setNetwork7(network7Result)
    const network8Result = await ExecutiveSummaryServicesContent.network8()
    setNetwork8(network8Result)

  }

  //api call  
  const LoadGenreTrendsData = async () => {

    //fist set the array empty for run the loader
    setHindiMoviesCWResult([])
    setHindiGECCW([])
    setSouthRegionalGECs([])
    setHSMRegionalGECs([])
    setSouthRegionalMovies([])
    setKids([])
    setMusic([])
    setNews([])
    setOthers([])
    setSport([])
    setHSMRegionalMovies([])

    const allGener = await ExecutiveSummaryServicesContent.getAllGenerData();
    setAllGenerData(allGener)

    const keyChannelZee = await ExecutiveSummaryServicesContent.keyChannelMovementZeeTV()
    // setKeyChannelMoveZee(keyChannelZee)
    const keyChannelStar = await ExecutiveSummaryServicesContent.keyChannelMovementStarTV()
    // setKeyChannelMoveStar(keyChannelStar)
    const keyChannelSun = await ExecutiveSummaryServicesContent.keyChannelMovementSunTV()
    // setKeyChannelMoveSun(keyChannelSun)
    const keyChannelViacom = await ExecutiveSummaryServicesContent.keyChannelMovementViacomTV()
    // setKeyChannelMoveViacom(keyChannelViacom)
    const keyChannelSony = await ExecutiveSummaryServicesContent.keyChannelMovementSonyTV()
    // setKeyChannelMoveSony(keyChannelSony)
    keyChannelZee.push({})
    keyChannelStar.map((ele) => {
      keyChannelZee.push(ele)
    })
    keyChannelZee.push({})
    keyChannelSun.map((ele) => {
      keyChannelZee.push(ele)
    })
    keyChannelZee.push({})
    keyChannelViacom.map((ele) => {
      keyChannelZee.push(ele)
    })
    keyChannelZee.push({})
    keyChannelSony.map((ele) => {
      keyChannelZee.push(ele)
    })

    setKeyChannelMoveZee(keyChannelZee)

    //now call the data as we required
    // const HindiMoviesCWResult = await ExecutiveSummaryServicesContent.HindiMoviesCW(filterSelectValueTarget)
    // setHindiMoviesCWResult(HindiMoviesCWResult)

    // const HindiGECCWResult = await ExecutiveSummaryServicesContent.HindiGECCW(filterSelectValueTarget)
    // setHindiGECCW(HindiGECCWResult)

    // const SouthRegionalGECsResult = await ExecutiveSummaryServicesContent.SouthRegionalGECs(filterSelectValueTarget)
    // setSouthRegionalGECs(SouthRegionalGECsResult)

    // const HSMRegionalGECsResult = await ExecutiveSummaryServicesContent.HSMRegionalGECs(filterSelectValueTarget)
    // setHSMRegionalGECs(HSMRegionalGECsResult)

    // const SouthRegionalMoviesResult = await ExecutiveSummaryServicesContent.SouthRegionalMovies(filterSelectValueTarget)
    // setSouthRegionalMovies(SouthRegionalMoviesResult)

    // const KidsResult = await ExecutiveSummaryServicesContent.Kids(filterSelectValueTarget)
    // setKids(KidsResult)

    // const MusicResult = await ExecutiveSummaryServicesContent.Music(filterSelectValueTarget)
    // setMusic(MusicResult)

    // const NewsResult = await ExecutiveSummaryServicesContent.News(filterSelectValueTarget)
    // setNews(NewsResult)

    // const OthersResult = await ExecutiveSummaryServicesContent.Others(filterSelectValueTarget)
    // setOthers(OthersResult)

    // const SportResult = await ExecutiveSummaryServicesContent.Sport(filterSelectValueTarget)
    // setSport(SportResult)

    // const HSMRegionalMoviesResult = await ExecutiveSummaryServicesContent.HSMRegionalMovies(filterSelectValueTarget)
    // setHSMRegionalMovies(HSMRegionalMoviesResult)
    // HSMRegionalGECs

    //total chart data of all gener 
    const CW_AVG_GRP_Total2 = [allGener.HSMRegionalGECs2[0].CW_AVG_GRP + allGener.HSMRegionalMovies2[0].CW_AVG_GRP + allGener.HindiGECCW2[0].CW_AVG_GRP + allGener.HindiMoviesCW2[0].CW_AVG_GRP + allGener.Kids2[0].CW_AVG_GRP + allGener.Music2[0].CW_AVG_GRP + allGener.News2[0].CW_AVG_GRP + allGener.Others2[0].CW_AVG_GRP + allGener.SouthRegionalGECs2[0].CW_AVG_GRP + allGener.SouthRegionalMovies2[0].CW_AVG_GRP + allGener.Sport2[0].CW_AVG_GRP]
    const LW_AVG_GRP_Total2 = [allGener.HSMRegionalGECs2[0].LW_AVG_GRP + allGener.HSMRegionalMovies2[0].LW_AVG_GRP + allGener.HindiGECCW2[0].LW_AVG_GRP + allGener.HindiMoviesCW2[0].LW_AVG_GRP + allGener.Kids2[0].LW_AVG_GRP + allGener.Music2[0].LW_AVG_GRP + allGener.News2[0].LW_AVG_GRP + allGener.Others2[0].LW_AVG_GRP + allGener.SouthRegionalGECs2[0].LW_AVG_GRP + allGener.SouthRegionalMovies2[0].LW_AVG_GRP + allGener.Sport2[0].LW_AVG_GRP]
    const L4W_AVG_GRP_Total2 = [allGener.HSMRegionalGECs2[0].L4W_AVG_GRP + allGener.HSMRegionalMovies2[0].L4W_AVG_GRP + allGener.HindiGECCW2[0].L4W_AVG_GRP + allGener.HindiMoviesCW2[0].L4W_AVG_GRP + allGener.Kids2[0].L4W_AVG_GRP + allGener.Music2[0].L4W_AVG_GRP + allGener.News2[0].L4W_AVG_GRP + allGener.Others2[0].L4W_AVG_GRP + allGener.SouthRegionalGECs2[0].L4W_AVG_GRP + allGener.SouthRegionalMovies2[0].L4W_AVG_GRP + allGener.Sport2[0].L4W_AVG_GRP]
    const L52W_AVG_GRP_Total2 = [allGener.HSMRegionalGECs2[0].L52W_AVG_GRP + allGener.HSMRegionalMovies2[0].L52W_AVG_GRP + allGener.HindiGECCW2[0].L52W_AVG_GRP + allGener.HindiMoviesCW2[0].L52W_AVG_GRP + allGener.Kids2[0].L52W_AVG_GRP + allGener.Music2[0].L52W_AVG_GRP + allGener.News2[0].L52W_AVG_GRP + allGener.Others2[0].L52W_AVG_GRP + allGener.SouthRegionalGECs2[0].L52W_AVG_GRP + allGener.SouthRegionalMovies2[0].L52W_AVG_GRP + allGener.Sport2[0].L52W_AVG_GRP]
    const L13W_AVG_GRP_Total2 = [allGener.HSMRegionalGECs2[0].L13W_AVG_GRP + allGener.HSMRegionalMovies2[0].L13W_AVG_GRP + allGener.HindiGECCW2[0].L13W_AVG_GRP + allGener.HindiMoviesCW2[0].L13W_AVG_GRP + allGener.Kids2[0].L13W_AVG_GRP + allGener.Music2[0].L13W_AVG_GRP + allGener.News2[0].L13W_AVG_GRP + allGener.Others2[0].L13W_AVG_GRP + allGener.SouthRegionalGECs2[0].L13W_AVG_GRP + allGener.SouthRegionalMovies2[0].L13W_AVG_GRP + allGener.Sport2[0].L13W_AVG_GRP]

    const Total_data_chart2 = [CW_AVG_GRP_Total2, LW_AVG_GRP_Total2, L4W_AVG_GRP_Total2, L13W_AVG_GRP_Total2, L52W_AVG_GRP_Total2]
    setconstTotalDataChart2(Total_data_chart2)

    const CW_AVG_GRP_Total15 = [allGener.HSMRegionalGECs15[0].CW_AVG_GRP + allGener.HSMRegionalMovies15[0].CW_AVG_GRP + allGener.HindiGECCW15[0].CW_AVG_GRP + allGener.HindiMoviesCW15[0].CW_AVG_GRP + allGener.Kids15[0].CW_AVG_GRP + allGener.Music15[0].CW_AVG_GRP + allGener.News15[0].CW_AVG_GRP + allGener.Others15[0].CW_AVG_GRP + allGener.SouthRegionalGECs15[0].CW_AVG_GRP + allGener.SouthRegionalMovies15[0].CW_AVG_GRP + allGener.Sport15[0].CW_AVG_GRP]
    const LW_AVG_GRP_Total15 = [allGener.HSMRegionalGECs15[0].LW_AVG_GRP + allGener.HSMRegionalMovies15[0].LW_AVG_GRP + allGener.HindiGECCW15[0].LW_AVG_GRP + allGener.HindiMoviesCW15[0].LW_AVG_GRP + allGener.Kids15[0].LW_AVG_GRP + allGener.Music15[0].LW_AVG_GRP + allGener.News15[0].LW_AVG_GRP + allGener.Others15[0].LW_AVG_GRP + allGener.SouthRegionalGECs15[0].LW_AVG_GRP + allGener.SouthRegionalMovies15[0].LW_AVG_GRP + allGener.Sport15[0].LW_AVG_GRP]
    const L4W_AVG_GRP_Total15 = [allGener.HSMRegionalGECs15[0].L4W_AVG_GRP + allGener.HSMRegionalMovies15[0].L4W_AVG_GRP + allGener.HindiGECCW15[0].L4W_AVG_GRP + allGener.HindiMoviesCW15[0].L4W_AVG_GRP + allGener.Kids15[0].L4W_AVG_GRP + allGener.Music15[0].L4W_AVG_GRP + allGener.News15[0].L4W_AVG_GRP + allGener.Others15[0].L4W_AVG_GRP + allGener.SouthRegionalGECs15[0].L4W_AVG_GRP + allGener.SouthRegionalMovies15[0].L4W_AVG_GRP + allGener.Sport15[0].L4W_AVG_GRP]
    const L515W_AVG_GRP_Total15 = [allGener.HSMRegionalGECs15[0].L52W_AVG_GRP + allGener.HSMRegionalMovies15[0].L52W_AVG_GRP + allGener.HindiGECCW15[0].L52W_AVG_GRP + allGener.HindiMoviesCW15[0].L52W_AVG_GRP + allGener.Kids15[0].L52W_AVG_GRP + allGener.Music15[0].L52W_AVG_GRP + allGener.News15[0].L52W_AVG_GRP + allGener.Others15[0].L52W_AVG_GRP + allGener.SouthRegionalGECs15[0].L52W_AVG_GRP + allGener.SouthRegionalMovies15[0].L52W_AVG_GRP + allGener.Sport15[0].L52W_AVG_GRP]
    const L13W_AVG_GRP_Total15 = [allGener.HSMRegionalGECs15[0].L13W_AVG_GRP + allGener.HSMRegionalMovies15[0].L13W_AVG_GRP + allGener.HindiGECCW15[0].L13W_AVG_GRP + allGener.HindiMoviesCW15[0].L13W_AVG_GRP + allGener.Kids15[0].L13W_AVG_GRP + allGener.Music15[0].L13W_AVG_GRP + allGener.News15[0].L13W_AVG_GRP + allGener.Others15[0].L13W_AVG_GRP + allGener.SouthRegionalGECs15[0].L13W_AVG_GRP + allGener.SouthRegionalMovies15[0].L13W_AVG_GRP + allGener.Sport15[0].L13W_AVG_GRP]

    const Total_data_chart15 = [CW_AVG_GRP_Total15, LW_AVG_GRP_Total15, L4W_AVG_GRP_Total15, L13W_AVG_GRP_Total15, L515W_AVG_GRP_Total15]
    setconstTotalDataChart15(Total_data_chart15)



    //const chartTotalData = [SouthRegionalGECsResult[0],HSMRegionalGECsResult[0],KidsResult[0],HindiMoviesCWResult[0],HindiGECCWResult[0],SportResult[0],HSMRegionalMoviesResult[0],OthersResult[0],NewsResult[0],MusicResult[0],SouthRegionalMoviesResult[0]]

    // const getTotalCW = (chartTotalData) => {
    //   let sumCW_AVG_GRP = 0
    //   for (let i = 0; i < 10; i++) {
    //     sumCW_AVG_GRP += chartTotalData[i].CW_AVG_GRP
    //   }
    //   return sumCW_AVG_GRP
    // }

    // const getTotalLW_AVG_GRP = (chartTotalData) => {
    //   let LW_AVG_GRP = 0
    //   for (let i = 0; i < 10; i++) {
    //     LW_AVG_GRP += chartTotalData[i].LW_AVG_GRP
    //   }
    //   return LW_AVG_GRP
    // }

    // const getTotalL4W_AVG_GRP = (chartTotalData) => {
    //   let L4W_AVG_GRP = 0
    //   for (let i = 0; i < 10; i++) {
    //     L4W_AVG_GRP += chartTotalData[i].L4W_AVG_GRP
    //   }
    //   return L4W_AVG_GRP
    // }

    // const getTotalL13W_AVG_GRP = (chartTotalData) => {
    //   let L13W_AVG_GRP = 0
    //   for (let i = 0; i < 10; i++) {
    //     L13W_AVG_GRP += chartTotalData[i].L13W_AVG_GRP
    //   }
    //   return L13W_AVG_GRP
    // }

    // const getTotalL52W_AVG_GRP = (chartTotalData) => {
    //   let L52W_AVG_GRP = 0
    //   for (let i = 0; i < 10; i++) {
    //     L52W_AVG_GRP += chartTotalData[i].L52W_AVG_GRP
    //   }
    //   return L52W_AVG_GRP
    // }

    //setconstTotalDataChart = [getTotalCW,getTotalLW_AVG_GRP,getTotalL4W_AVG_GRP,getTotalL13W_AVG_GRP,getTotalL52W_AVG_GRP]
  }
  const isMobile = useMediaQuery('(max-width: 765px)')

  const serieseImage = [];

  // {ZeeProgramFilter ? (
  //   <>
  //     {ZeeProgramFilter.slice(0, 10).map((item) => {
  //       serieseImage.push({
  //         img: serialLogo3,
  //         name: item.Description,
  //         logo: zeeLogo,
  //         grp: 1.4,
  //         Reach: item.Reach,
  //         tsv: 17.9,
  //       });
  //       return null;
  //     })}
  //   </>
  // ) : (
  //   <></>
  // )}


  let serieseImageCompatitonDiv = [
    {
      img: compatitionLogo1,
      logo: clogo3,
      name: 'Anupamaa ',
      grp: 3.05,
      Reach: 4.43,
      tsv: 21
    },
    {
      img: compatitionLogo2,
      logo: clogo3,
      name: 'Ghum Hai Kisikey Pyaar Mein',
      grp: 2.65,
      Reach: 3.92,
      tsv: 20.4
    },
    {
      img: compatitionLogo3,
      logo: clogo3,
      name: 'Yeh Rishta Kya Kehlata Hai',
      grp: 2.48,
      Reach: 4,
      tsv: 18.7
    },
    {
      img: compatitionLogo4,
      logo: clogo3,
      name: 'Imli',
      grp: 2.36,
      Reach: 3.8,
      tsv: 18.5
    },
    {
      img: compatitionLogo5,
      logo: clogo3,
      name: 'Faltu',
      grp: 2.13,
      Reach: 3.52,
      tsv: 18
    },
    {
      img: compatitionLogo6,
      logo: clogo3,
      name: 'Yeh Hai Chahatein',
      grp: 2.03,
      Reach: 3.56,
      tsv: 17.6
    },
    {
      img: compatitionLogo7,
      logo: clogo3,
      name: 'Pandya Store',
      grp: 1.94,
      Reach: 3.3,
      tsv: 17.6
    },
    {
      img: compatitionLogo8,
      logo: clogo9,
      name: 'Taarak Mehta Ka Ooltah Chashma',
      grp: 1.62,
      Reach: 2.84,
      tsv: 17.4
    },
    {
      img: compatitionLogo9,
      logo: clogo2,
      name: 'Naagin 6',
      grp: 1.53,
      Reach: 3.25,
      tsv: 28.8
    },
    {
      img: compatitionLogo10,
      logo: clogo3,
      name: 'Teri Meri Doriyaann',
      grp: 1.5,
      Reach: 2.52,
      tsv: 18.3
    },
  ]




  let dataWithImgAndDesc1 = [

    {
      img: compatitionLogo2,
      logo: clogo3,
      name: 'Ghum Hai Kisikey Pyaar Mein',
      grp: 2.65,
      Reach: 3.92,
      tsv: 20.4
    },
    {
      img: compatitionLogo3,
      logo: clogo3,
      name: 'Yeh Rishta Kya Kehlata Hai',
      grp: 2.48,
      Reach: 4,
      tsv: 18.7
    },
    {
      img: compatitionLogo1,
      logo: clogo3,
      name: 'Anupamaa ',
      grp: 3.05,
      Reach: 4.43,
      tsv: 21
    },

    {
      img: compatitionLogo5,
      logo: clogo3,
      name: 'Faltu',
      grp: 2.13,
      Reach: 3.52,
      tsv: 18
    },
    {
      img: compatitionLogo4,
      logo: clogo3,
      name: 'Imli',
      grp: 2.36,
      Reach: 3.8,
      tsv: 18.5
    },
    {
      img: compatitionLogo6,
      logo: clogo3,
      name: 'Yeh Hai Chahatein',
      grp: 2.03,
      Reach: 3.56,
      tsv: 17.6
    },

    {
      img: compatitionLogo8,
      logo: clogo9,
      name: 'Taarak Mehta Ka Ooltah Chashma',
      grp: 1.62,
      Reach: 2.84,
      tsv: 17.4
    },
    {
      img: compatitionLogo7,
      logo: clogo3,
      name: 'Pandya Store',
      grp: 1.94,
      Reach: 3.3,
      tsv: 17.6
    },

    {
      img: compatitionLogo10,
      logo: clogo3,
      name: 'Teri Meri Doriyaann',
      grp: 1.5,
      Reach: 2.52,
      tsv: 18.3
    },
    {
      img: compatitionLogo9,
      logo: clogo2,
      name: 'Naagin 6',
      grp: 1.53,
      Reach: 3.25,
      tsv: 28.8
    },
  ]

  let dataWithImgAndDesc2 = [
    {
      img: compatitionLogo7,
      logo: clogo3,
      name: 'Pandya Store',
      grp: 1.94,
      Reach: 3.3,
      tsv: 17.6
    },
    {
      img: compatitionLogo8,
      logo: clogo9,
      name: 'Taarak Mehta Ka Ooltah Chashma',
      grp: 1.62,
      Reach: 2.84,
      tsv: 17.4
    },
    {
      img: compatitionLogo9,
      logo: clogo2,
      name: 'Naagin 6',
      grp: 1.53,
      Reach: 3.25,
      tsv: 28.8
    },
    {
      img: compatitionLogo10,
      logo: clogo3,
      name: 'Teri Meri Doriyaann',
      grp: 1.5,
      Reach: 2.52,
      tsv: 18.3
    },
    {
      img: compatitionLogo1,
      logo: clogo3,
      name: 'Anupamaa ',
      grp: 3.05,
      Reach: 4.43,
      tsv: 21
    },
    {
      img: compatitionLogo2,
      logo: clogo3,
      name: 'Ghum Hai Kisikey Pyaar Mein',
      grp: 2.65,
      Reach: 3.92,
      tsv: 20.4
    },
    {
      img: compatitionLogo3,
      logo: clogo3,
      name: 'Yeh Rishta Kya Kehlata Hai',
      grp: 2.48,
      Reach: 4,
      tsv: 18.7
    },
    {
      img: compatitionLogo4,
      logo: clogo3,
      name: 'Imli',
      grp: 2.36,
      Reach: 3.8,
      tsv: 18.5
    },
    {
      img: compatitionLogo5,
      logo: clogo3,
      name: 'Faltu',
      grp: 2.13,
      Reach: 3.52,
      tsv: 18
    },
    {
      img: compatitionLogo6,
      logo: clogo3,
      name: 'Yeh Hai Chahatein',
      grp: 2.03,
      Reach: 3.56,
      tsv: 17.6
    },

  ]

  let dataWithImgAndDesc3 = [
    {
      img: serialLogo1,
      name: 'Kundali Bhagya',
      logo: zeeLogo,
      grp: 1.58,
      Reach: 2.53,
      tsv: 19
    },
    {
      img: serialLogo3,
      name: 'Pyaar Ka Pehla Naam Radha Mohan',
      logo: zeeLogo,
      grp: 1.4,
      Reach: 2.38,
      tsv: 17.9
    },
    {
      img: serialLogo5,
      name: 'Rabb Se Hai Dua',
      logo: zeeLogo,
      grp: 0.69,
      Reach: 1.7,
      tsv: 11.7
    },
    {
      img: serialLogo6,
      name: 'Maitree',
      logo: zeeLogo,
      grp: 0.59,
      Reach: 1.32,
      tsv: 13.6
    },
    {
      img: serialLogo7,
      name: 'Meet',
      logo: zeeLogo,
      grp: 0.5,
      Reach: 0.99,
      tsv: 14.4
    },
    {
      img: serialLogo8,
      logo: zeeLogo,
      name: 'Lag Ja Gale',
      grp: 0.41,
      Reach: 0.93,
      tsv: 13.6
    },
    {
      img: serialLogo9,
      logo: AndTv,
      name: 'Bhabhiji Ghar Par Hain',
      grp: 0.33,
      Reach: 0.64,
      tsv: 15.8
    },
    {
      img: serialLogo0,
      name: 'Bhagya Lakshmi',
      logo: zeeLogo,
      grp: 1.62,
      Reach: 2.84,
      tsv: 17.5
    },
    {
      img: serialLogo2,
      name: 'Kumkum Bhagya',
      logo: zeeLogo,
      grp: 1.6,
      Reach: 2.71,
      tsv: 18.1
    },
    {
      img: serialLogo4,
      name: 'Main Hoon Aparajita',
      logo: zeeLogo,
      grp: 0.9,
      Reach: 1.81,
      tsv: 15.2
    },
  ]

  const filterObj = [
    {
      Channel: 'Hindi GEC',
      TG: 'IND 2+ YRS',
      Market: 'HSM Urban',
    },
    {
      Channel: 'Telugu Movies',
      TG: 'AP / Telangana',
      Market: 'IND 2+ YRS',
    },
    {
      Channel: 'Hindi Movies',
      TG: 'IND 2+ YRS',
      Market: 'HSM Urban',
    },
    {
      Channel: 'Eng Movies',
      TG: 'IND AB 15-40 YRS',
      Market: 'Mega Cities',
    },
    {
      Channel: 'English Ent',
      TG: 'IND AB 15-40 YRS',
      Market: 'Mega Cities',
    },
    {
      Channel: 'Food & Lifestyle',
      TG: 'IND AB 15-50 YRS',
      Market: 'HSM Urban',
    },
    {
      Channel: 'Music',
      TG: 'IND ABC 15-30 YRS',
      Market: 'HSM',
    },
    {
      Channel: 'Hindi GEC (HD)',
      TG: 'IND 2+ YRS',
      Market: 'HSM Urban',
    },
    {
      Channel: 'Telugu GEC',
      TG: 'IND 2+ YRS',
      Market: 'AP / Telangana',
    },
    {
      Channel: 'Youth',
      TG: 'IND ABC 15-30 YRS',
      Market: 'HSM',
    },
  ]

  const handleOptionClickWeek = (value) => {
    setOptionToggleWeek(false)
    setFilterSelectValueWeek(value)
  }

  const handleOptionClickTarget = async (value) => {
    setOptionToggleTarget(false)
    setFilterSelectValueTarget(value)
    props.passTheData(value)
  }




  //   var plotData = [
  //     {category: 'Zee TV', open: 1.8, close: 1.3},
  //     {category: 'Zee Telugu', open: 2.1, close: 1.8},
  //     {category: 'Zee Cinema', open: 1, close: 0.7},
  //     {category: 'Zee Bangala', open: 1.4, close: 1.1},
  //     {category: 'Zee Kanada', open: 1.8, close: 1.5},
  //     {category: '&FLIX', open: 0, close: 0},
  //     {category: '&Pictures HD', open: 0, close: 0},
  //     {category: 'Zee Tamil HD', open: 0, close: 0.1},
  //     {category: 'Zee Biskope', open: 0.2, close: 0.3},
  //     {category: 'Zee Ganga', open: 0.2, close: 0.3},
  //     {category: 'Star Plus', open: 2.9, close: 2},

  //     {category: '', open: null , close: null},

  //   {category: 'Asianet', open: 0.9, close: 0.5},
  //   {category: 'Star Pravah', open: 2.1, close: 1.6},
  //   {category: 'Star Maa', open: 2.9, close: 2.6},
  //   {category: 'Star Jalsha', open: 1.5, close: 1.2},
  //   {category: 'Star Maa HD', open: 0.3, close: 0.4},
  //   {category: 'Asianet HD', open: 0, close: 0.1},
  //   {category: 'Star Vijay HD', open: 0.1, close: 0.2},
  //   {category: 'Nat Geo Wild HD(v)', open: 0, close: 0.1},
  //   {category: 'National Geographic HD…', open: 0, close: 0.1},

  //   {category: '', open: null , close: null},

  //   {category: 'Chhutti TV', open: 0.1, close: 0},
  //   {category: 'Chintu TV', open: 0.1, close: 0},
  //   {category: 'Gemini Movies HD', open: 0.1, close: 0},
  //   {category: 'Gemini Music HD', open: 0.1, close: 0.1},
  //   {category: 'Surya TV', open: 0.2, close: 0.3},
  //   {category: 'Sun Marathi', open: 0.2, close: 0.3},
  //   {category: 'Udaya Movies', open: 0.4, close: 0.5},
  //   {category: 'Gemini TV', open: 0.8, close: 0.9},
  //   {category: 'Gemini Movies', open: 0.9, close: 1.3},

  //   {category: '', open: null , close: null},

  //   {category: 'Colors Tamil', open: 0.3, close: 0.2},
  //   {category: 'Colors Bangla HD', open: 0.1, close: 0},
  //   {category: 'Colors Cineplex HD', open: 0.1, close: 0},
  //   {category: 'Colors Infinity HD', open: 0.1, close: 0},
  //   {category: 'Colors Infinity SD', open: 0.1, close: 0},
  //   {category: 'Colors Cineplex Bollywood', open: 0.8, close: 1},
  //   {category: 'Colors Kannada', open: 0.1, close: 1.3},
  //   {category: 'Colors Cineplex Superhits', open: 0.8, close: 1.1},
  //   {category: 'Colors Cineplex', open: 0.6, close: 1},
  //   {category: 'Colors', open: 1.8, close: 2.2},

  //   {category: '', open: null , close: null},

  //   {category: 'Sony Sab', open: 1.8, close: 1.2},
  //   {category: 'Sony TV', open: 1.1, close: 0.7},
  //   {category: 'Sony Max', open: 0.9, close: 0.6},
  //   {category: 'Sony Max 2', open: 0.4, close: 0.3},
  //   {category: 'Sony Yay(v)', open: 0.4, close: 0.3},
  //   {category: 'Sony BBC Earth HD(v)', open: 0.1, close: 0},
  //   {category: 'Sony PIX', open: 0.1, close: 0},
  //   {category: 'Sony Max HD(v)', open: 0, close: 0.1},
  //   {category: 'Sony PIX HD', open: 0, close: 0.1},
  //   {category: 'Sony TV HD', open: 0.1, close: 0.2},
  // ];



  //   var plotData = [
  //     {chennal : 'Zee TV', category: 'Zee TV ...', open: 1.8, close: 1.3, color:'green'},
  //     {chennal : 'Zee Telugu', category: 'Zee Te ...', open: 2.1, close: 1.8, color:'green'},
  //     {chennal : 'Zee Cinema', category: 'Zee Ci ...', open: 1, close: 0.7},
  //     {chennal : 'Zee Bangla', category: 'Zee Ba ...', open: 1.4, close: 1.1},
  //     {chennal : 'Zee Kanada', category: 'Zee Ka ...', open: 1.8, close: 1.5},
  //     {chennal :  '&FLIX', category: '&FLIX', open: 0, close: 0},
  //     {chennal : '&Pictures HD', category: '&Pictu ...', open: 0, close: 0},
  //     {chennal : 'Zee Tamil HD', category: 'Zee Ta ...', open: 0, close: 0.1},
  //     {chennal : 'Zee Biskope', category: 'Zee Bi ...', open: 0.2, close: 0.3},
  //     {chennal : 'Zee Ganga', category: 'Zee Ga ...', open: 0.2, close: 0.3},

  //     {chennal : '', category: '', open: null , close: null},

  //   {chennal : 'Star Plus', category: 'Star Pl ...', open: 2.9, close: 2},
  //   {chennal: 'Asianet', category: 'Asiane ...', open: 0.9, close: 0.5},
  //   {chennal: 'Star Pravah' , category: 'Star P ...', open: 2.1, close: 1.6},
  //   {chennal: 'Star Maa' , category: 'Star Ma ..', open: 2.9, close: 2.6},
  //   {chennal: 'Star Jalsha' , category: 'Star J ...', open: 1.5, close: 1.2},
  //   {chennal: 'Star Maa HD' , category: 'Star M ...', open: 0.3, close: 0.4},
  //   {chennal: 'Asianet HD' , category: 'Asianet ..', open: 0, close: 0.1},
  //   {chennal: 'Star Vijay HD' , category: 'Star V ...', open: 0.1, close: 0.2},
  //   {chennal: 'Nat Geo Wild HD(v)' , category: 'Nat Ge ...', open: 0, close: 0.1},
  //   {chennal: 'National Geographic HD…' , category: 'Nation ...', open: 0, close: 0.1},

  //   {chennal : '', category: '', open: null , close: null},

  //   {chennal: 'Chhutti TV', category: 'Chhutt ...', open: 0.1, close: 0},
  //   {chennal: 'Chintu TV'  , category: 'Chintu ...', open: 0.1, close: 0},
  //   {chennal: 'Gemini Movies HD' , category: 'Gemini H ...', open: 0.1, close: 0},
  //   {chennal: 'Gemini Music HD'  , category: 'Gemini HD ..', open: 0.1, close: 0.1},
  //   {chennal: 'Surya TV' , category: 'Surya ...', open: 0.2, close: 0.3},
  //   {chennal: 'Sun Marathi' , category: 'Sun Ma ...', open: 0.2, close: 0.3},
  //   {chennal: 'Udaya Movies' , category: 'Udaya ...', open: 0.4, close: 0.5},
  //   {chennal: 'Gemini TV' , category: 'Gemini T ..', open: 0.8, close: 0.9},
  //   {chennal: 'Gemini Movies' , category: 'Gemini M ..', open: 0.9, close: 1.3},

  //   {chennal : '', category: '', open: null , close: null},

  //   {chennal : 'Colors Tamil', category: 'Colors Ta .', open: 0.3, close: 0.2},
  //   {chennal : 'Colors Bangla HD',  category: 'Colors Ba .', open: 0.1, close: 0},
  //   {chennal : 'Colors Cineplex HD',  category: 'Colors Ci .', open: 0.1, close: 0},
  //   {chennal : 'Colors Infinity HD',  category: 'Colors In .', open: 0.1, close: 0},
  //   {chennal : 'Colors Infinity SD',   category: 'Colors SD .', open: 0.1, close: 0},
  //   {chennal : 'Colors Cineplex Bollywood',   category: 'Colors B ..', open: 0.8, close: 1},
  //   {chennal : 'Colors Kannada',   category: 'Colors K ..', open: 0.1, close: 1.3},
  //   {chennal : 'Colors Cineplex Superhits',  category: 'Colors S ..', open: 0.8, close: 1.1},
  //   {chennal : 'Colors Cineplex',  category: 'Colors C ..', open: 0.6, close: 1},
  //   {chennal : 'Colors',   category: 'Colors', open: 1.8, close: 2.2},


  //   {chennal : '', category: '', open: null , close: null},

  //   {chennal : 'Sony Sab' , category: 'Sony S ...', open: 1.8, close: 1.2},
  //   {chennal : 'Sony TV' , category: 'Sony T ...', open: 1.1, close: 0.7},
  //   {chennal : 'Sony Max' , category: 'Sony Max', open: 0.9, close: 0.6},
  //   {chennal : 'Sony Max 2' , category: 'Sony Ma ..', open: 0.4, close: 0.3},
  //   {chennal : 'Sony Yay(v)' , category: 'Sony Y ...', open: 0.4, close: 0.3},
  //   {chennal :  'Sony BBC Earth HD(v)' , category: 'Sony B ...', open: 0.1, close: 0},
  //   {chennal : 'Sony PIX' , category: 'Sony P ...', open: 0.1, close: 0},
  //   {chennal :  'Sony Max HD(v)' , category: 'Sony M ...', open: 0, close: 0.1},
  //   {chennal : 'Sony PIX HD' , category: 'Sony P ...', open: 0, close: 0.1},
  //   {chennal : 'Sony TV HD' , category: 'Sony HD ..', open: 0.1, close: 0.2},
  // ];
  // /api/excecutive-summary/sun-km

  // useEffect(() => {
  //   keyChannelMoveZee.push({});
  //   keyChannelMoveStar.map((ele) => {
  //     keyChannelMoveZee.push(ele)
  //   })
  //   keyChannelMoveZee.push({});

  //   keyChannelMoveSun.map((ele) => {
  //     keyChannelMoveZee.push(ele)
  //   })
  //   keyChannelMoveZee.push({});

  //   keyChannelMoveViacom.map((ele) => {
  //     keyChannelMoveZee.push(ele)
  //   })
  //   keyChannelMoveZee.push({});

  //   keyChannelMoveSony.map((ele) => {
  //     keyChannelMoveZee.push(ele)
  //   })

  // }, [keyChannelMoveSony])
  // keyChannelMoveViacom


  console.log('constTotalDataChart15constTotalDataChart15', constTotalDataChart15);
  console.log('constTotalDataChart15constTotalDataChart15', constTotalDataChart2);

  // star , sun , viacom, sony
  // console.log('kkkkkkkkkkkkkkkkkkkkkkkkkk', keyChannelMoveSun);

  var plotData = [{ chennal: 'Zee TV', category: 'Zee TV ...', open: 1.8, close: 1.5, bulletSettings: { fill: "#FF647C" }, },
    { chennal: 'Zee Telugu', category: 'Zee Te ...', open: 2.1, close: 2.3, bulletSettings: { fill: "#00C48C" } },
    { chennal: 'Zee Bangla', category: 'Zee TVV ...', open: 2.8, close: 2.5, bulletSettings: { fill: "#FF647C" } },
    { chennal: 'Zee', category: 'Zee TVV ...', open: 2.3, close: 1.8, bulletSettings: { fill: "#FF647C" } },
    { chennal: 'Sun', category: 'Sun ...', open: 1.3, close: 1.8, bulletSettings: { fill: "#FF647C" } },
]

  return (
    <div className="executive-summary-content-linear-syn">
      <div className="executive-summary-content-linear-tabs-and-filters-syn">
        <div className="exe-sum-con-lin-viewer-and-prog-and-perf-syn">
          <div onClick={() => setButtonTab('Viewership')} className={buttonTab === 'Viewership' ? ("exec-summ-lin-viewership-summ-active-class-syn") : ("exec-summ-lin-viewership-summ-syn")} >Viewership Summary</div>
          <div onClick={() => setButtonTab('Slot-Leadership')} className={buttonTab === 'Slot-Leadership' ? ("exec-summ-lin-viewership-summ-active-class-syn") : ("exec-summ-lin-viewership-summ-syn")} >Slot Leadership</div>
          <div onClick={() => setButtonTab('Top-Programs')} className={buttonTab === 'Top-Programs' ? ("exec-summ-lin-viewership-summ-active-class-syn") : ("exec-summ-lin-viewership-summ-syn")}>Top Programs & Performance</div>
        </div>

        {buttonTab === 'Top-Programs' && <div className="executive-summary-content-linear-filters-syn">
          <div className="exe-summ-content-linear-channel">
            <p className="exe-summ-content-linear-channel-gener-head-syn">Channel Genre</p>

            <div className='drop-down-menu'>
              <div onClick={() => setOptionToggle(true)} className="drop-down-menu-selection-box-main">
                {filterSelectValue}

                <div className='drop-down-menu-selection-box-down-arrow-icon'>
                  <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.00893 0L4 3.01432L0.991063 0L0 0.992838L4 5L8 0.992838L7.00893 0Z" fill="#808080" />
                  </svg>

                </div>
              </div>
              <div className={'drop-down-menu-options-container-n'}>
                {optionToggle === true && <>
                  {ZeeProgramObjFilter.length > 0 ? <>

                    {
                      ZeeProgramObjFilter.map((item, i) => {
                        return (
                          <div className='drop-down-menu-option' key={i}
                            onClick={() => { handleOptionClick(item.chv_channel_genre, item.type_name, item.chv_target_market) }}
                          >
                            {item.chv_channel_genre}
                          </div>
                        )
                      })
                    } </>
                    : (<></>)}
                </>
                }
              </div>
            </div>

          </div>

          <div className="exec-summ-con-lin-tg-syn">
            <p className="exe-summ-content-linear-tg-head-syn">TG</p>
            <p className="execu-summ-cont-linear-tg-val-syn">{TargetMarket}</p>
          </div>

          <div className="exec-summ-con-lin-tg-syn">
            <p className="exe-summ-content-linear-tg-head-syn">Market</p>
            <p className="execu-summ-cont-linear-tg-val-syn">{TargerData}</p>
          </div>

        </div>}


        {buttonTab === 'Viewership' &&
          <div className="executive-summary-content-linear-filters-syn">
            {/* <div className="d-flex">
                  <p className="exe-summ-content-linear-viewership-channel-gener-head-syn">Week</p>
                  <div style={{position:'relative'}}>
                    <div onClick={() => setOptionToggleWeek(true)} className="executive-summary-content-content-ranking-filter-select-options-syn select-arrow">
                    {filterSelectValueWeek}
                    </div>
                    {optionToggleWeek === true && <div className="executive-summary-content-content-ranking-filter-options-syn">
                      <p onClick={() => handleOptionClickWeek('1')} className="executive-summary-content-content-ranking-filter-option-syn">1</p>
                      <p onClick={() => handleOptionClickWeek('2')} className="executive-summary-content-content-ranking-filter-option-syn">2</p>
                      <p onClick={() => handleOptionClickWeek('3')} className="executive-summary-content-content-ranking-filter-option-syn">3</p>
                      <p onClick={() => handleOptionClickWeek('4')} className="executive-summary-content-content-ranking-filter-option-syn">4</p>
                      <p onClick={() => handleOptionClickWeek('5')} className="executive-summary-content-content-ranking-filter-option-syn">5</p>
                      <p onClick={() => handleOptionClickWeek('6')} className="executive-summary-content-content-ranking-filter-option-syn">6</p>
                      <p onClick={() => handleOptionClickWeek('7')} className="executive-summary-content-content-ranking-filter-option-syn">7</p>
                      <p onClick={() => handleOptionClickWeek('8')} className="executive-summary-content-content-ranking-filter-option-syn">8</p>
                      <p onClick={() => handleOptionClickWeek('9')} className="executive-summary-content-content-ranking-filter-option-syn" >9</p>
                    </div>}
                    </div>
              </div> */}

            <div className="d-flex mx-4">
              <p className="exe-summ-content-linear-viewership-channel-gener-head-syn">Target</p>
              <div style={{ position: 'relative' }}>
                <div onClick={() => setOptionToggleTarget(true)} className="executive-summary-content-content-ranking-filter-select-options-syn select-arrow">
                  {filterSelectValueTarget}
                </div>
                {optionToggleTarget === true && <div className="executive-summary-content-content-ranking-filter-options-syn">
                  <p onClick={() => handleOptionClickTarget('2')} className="executive-summary-content-content-ranking-filter-option-syn">2+</p>
                  <p onClick={() => handleOptionClickTarget('15')} className="executive-summary-content-content-ranking-filter-option-syn">15+</p>
                </div>}
              </div>
            </div>
          </div>
        }
      </div>
      {/* <div className="summary_date view-summary-date">Data as of Week 9, Ending 03-Mar-23</div> */}
      <div className="summary_date view-summary-date">All charts reporting performance basis GRPs as on Week 15 ending 15/04/2023</div>


      {buttonTab === 'Top-Programs' && <div className="execu-summ-con-lin-zee-prog-perent-div-syn">
        <div className="execu-summ-con-lin-zee-prog-div-syn">
          <h6 className="executive-summary-con-linear-zee-programs-heading-card-syn  zee-network">Zee Network
            {/* <div className="summary_date">Data as of Week 9, Ending 03-Mar-23</div> */}
          </h6>

          <div className="execut-summ-lin-content-single-card-with-img-syn">
            {serieseImageCompatitonDiv.length > 0 ?
              <>
                {serieseImageCompatitonDiv.slice(0, 10).map((ele, index) => {
                  let dataLength = serieseImageCompatitonDiv.length;
                  return <ExecutiveSummaryConLinearZeeProgramsCard key = {index} name={ele.Description} Channel_Name={ele.Channel_Name} isMobile={isMobile} dataLength={dataLength} grp={ele.GRP} logo={zeeLogo} Reach={ele.Reach} tsv={ele.TSV} key={index} index={index} img={Background} />
                })}
              </> : <div className="execut-summ-lin-content-single-card-with-img-syna">
                No Data Found
              </div>
            }

          </div>
        </div>

        <div className="execu-summ-con-lin-zee-prog-div-syn">
          <h6 className="executive-summary-con-linear-zee-programs-heading-card-syn ">Overall Network</h6>
          <div className="execut-summ-lin-content-single-card-with-img-syn">
            {dataWithImgAndDesc3.length > 0 ? <>
              {dataWithImgAndDesc3.slice(0, 10).map((ele, index) => {
                let dataLength = dataWithImgAndDesc3.length;
                return <ExecutiveSummaryConLinearZeeProgramsCard key = {index} name={ele.Description} Channel_Name={ele.Channel_Name} isMobile={isMobile} dataLength={dataLength} grp={ele.GRP} logo={zeeLogo} Reach={ele.Reach} tsv={ele.TSV} key={index} index={index} img={Background} />
              })}
            </> : <div className="execut-summ-lin-content-single-card-with-img-syna">
              No Data Found
            </div>
            }
          </div>
        </div>

        <div className="execu-summ-con-lin-zee-prog-div-syn">
          <h6 className="executive-summary-con-linear-zee-programs-heading-card-syn ">Star Network</h6>
          <div className="execut-summ-lin-content-single-card-with-img-syn">

            {dataWithImgAndDesc1.length > 0 ? <>
              {dataWithImgAndDesc1.slice(0, 10).map((ele, index) => {
                let dataLength = dataWithImgAndDesc1.length;
                return <ExecutiveSummaryConLinearZeeProgramsCard key = {index} name={ele.Description} Channel_Name={ele.Channel_Name} isMobile={isMobile} dataLength={dataLength} grp={ele.GRP} logo={zeeLogo} Reach={ele.Reach} tsv={ele.TSV} key={index} index={index} img={Background} />
              })}
            </> : <div className="execut-summ-lin-content-single-card-with-img-syna">
              No Data Found
            </div>
            }
          </div>
        </div>

        <div className="execu-summ-con-lin-zee-prog-div-syn">
          <h6 className="executive-summary-con-linear-zee-programs-heading-card-syn ">Viacom Network</h6>
          <div className="execut-summ-lin-content-single-card-with-img-syn">

            {dataWithImgAndDesc2.length > 0 ? <>
              {dataWithImgAndDesc2.slice(0, 10).map((ele, index) => {
                let dataLength = dataWithImgAndDesc2.length;
                return <ExecutiveSummaryConLinearZeeProgramsCard key = {index} name={ele.Description} Channel_Name={ele.Channel_Name} isMobile={isMobile} dataLength={dataLength} grp={ele.GRP} logo={zeeLogo} Reach={ele.Reach} tsv={ele.TSV} key={index} index={index} img={Background} />
              })}
            </> : <div className="execut-summ-lin-content-single-card-with-img-syna">
              No Data Found
            </div>
            }
          </div>
        </div>

        <div className="execu-summ-con-lin-zee-prog-div-syn">
          <h6 className="executive-summary-con-linear-zee-programs-heading-card-syn ">Sun Network</h6>
          <div className="execut-summ-lin-content-single-card-with-img-syn">

            {dataWithImgAndDesc2.length > 0 ? <>
              {dataWithImgAndDesc2.slice(0, 10).map((ele, index) => {
                let dataLength = dataWithImgAndDesc2.length;
                return <ExecutiveSummaryConLinearZeeProgramsCard key = {index} name={ele.Description} Channel_Name={ele.Channel_Name} isMobile={isMobile} dataLength={dataLength} grp={ele.GRP} logo={zeeLogo} Reach={ele.Reach} tsv={ele.TSV} key={index} index={index} img={Background} />
              })}
            </> : <div className="execut-summ-lin-content-single-card-with-img-syna">
              No Data Found
            </div>
            }
          </div>
        </div>

        <div className="execu-summ-con-lin-zee-prog-div-syn">
          <h6 className="executive-summary-con-linear-zee-programs-heading-card-syn ">Sony Network</h6>
          <div className="execut-summ-lin-content-single-card-with-img-syn">

            {serieseImageCompatitonDiv.length > 0 ? <>
              {serieseImageCompatitonDiv.slice(0, 10).map((ele, index) => {
                let dataLength = serieseImageCompatitonDiv.length;
                return <ExecutiveSummaryConLinearZeeProgramsCard key = {index} name={ele.Description} Channel_Name={ele.Channel_Name} isMobile={isMobile} dataLength={dataLength} grp={ele.GRP} logo={zeeLogo} Reach={ele.Reach} tsv={ele.TSV} key={index} index={index} img={Background} />
              })}
            </> : <div className="execut-summ-lin-content-single-card-with-img-syna">
              No Data Found
            </div>
            }
          </div>
        </div>

        <div className="execu-summ-con-lin-zee-prog-div-syn">
          <h6 className="executive-summary-con-linear-zee-programs-heading-card-syn ">Disney Network</h6>
          <div className="execut-summ-lin-content-single-card-with-img-syn">

            {serieseImageCompatitonDiv.length > 0 ? <>
              {serieseImageCompatitonDiv.slice(0, 10).map((ele, index) => {
                let dataLength = serieseImageCompatitonDiv.length;
                return <ExecutiveSummaryConLinearZeeProgramsCard key = {index} name={ele.Description} Channel_Name={ele.Channel_Name} isMobile={isMobile} dataLength={dataLength} grp={ele.GRP} logo={zeeLogo} Reach={ele.Reach} tsv={ele.TSV} key={index} index={index} img={Background} />
              })}
            </> : <div className="execut-summ-lin-content-single-card-with-img-syna">
              No Data Found
            </div>
            }
          </div>
        </div>

        {/* <div className="execu-summ-con-lin-zee-prog-div-syn">
          <h6 className="executive-summary-con-linear-zee-programs-heading-card-syn ">Competition</h6>
          <div className="execut-summ-lin-content-single-card-with-img-syn">

            {dataWithImgAndDesc3.map((ele, index) => {
              let dataLength = dataWithImgAndDesc3.length;
              return <ExecutiveSummaryConLinearZeeProgramsCard name={ele.name} isMobile={isMobile} dataLength={dataLength} grp={ele.grp} logo={ele.logo} Reach={ele.Reach} tsv={ele.tsv} key={index} index = {index} img={ele.img}/>
            })}
          </div>
        </div> */}
      </div>}

      {buttonTab === 'Viewership' &&

        <div className="exec-summ-lin-viewership-summary-network-share-syn">
          <div className="exec-summ-lin-viewership-summary-network-share-all-chart-div-syn">
            <h6 className="exec-summ-lin-viewership-summary-network-share-heading-syn">Network Share</h6>
            <div className="all-GRP-Numbers-reported-as-on-xx-syn"></div>
            <div className="execu-summ-linear-con-network-share-indicators-syn">
              <label className="executive-summ-con-linear-viewer-summ-hindi-gec-syn"></label>Hindi GEC
              <label className="executive-summ-con-linear-viewer-summ-hindi-movie-syn"></label>Hindi Movie
              <label className="executive-summ-con-linear-viewer-summ-south-movie-syn"></label>South GEC & Movie
              <label className="executive-summ-con-linear-viewer-summ-rhsm-gec-movie-syn"></label>RHSM GEC & Movie
              <label className="executive-summ-con-linear-viewer-summ-others-syn"></label>Others
            </div>
            <div className="dataSetNeteorkShareZeeCard-perent-div">
              {filterSelectValueTarget == '2' && <div className="dataSetNeteorkShareZeeCard">
                <ContentTabStackBarChart dataSetNeteorkShare={dataSetNeteorkShareZee} img={zeeLogoViewer} total={[zeeLWTotal, zeeCWTotal]} />
                <ContentTabStackBarChart dataSetNeteorkShare={dataSetNeteorkShareStar} img={zeeLogoStar} total={[starLWTotal, starCWTotal]} />
                <ContentTabStackBarChart dataSetNeteorkShare={dataSetNeteorkShareSuntv} img={zeeLogoSunTv} total={[sunLWTotal, sunCWTotal]} />
                <ContentTabStackBarChart dataSetNeteorkShare={dataSetNeteorkShareViaCom} img={zeeLogov18} total={[viaComLWTotal, viaComCWTotal]} />
                <ContentTabStackBarChart dataSetNeteorkShare={dataSetNeteorkShareSony} img={zeeLogoSony} total={[sonyWTotal, sonyCWTotal]} />
                <ContentTabStackBarChart dataSetNeteorkShare={dataSetNeteorkShareDisney} img={zeeLogoDsney} total={[disneyLWTotal, disneyCWTotal]} />
              </div>}
              {filterSelectValueTarget == '15' && <div className="dataSetNeteorkShareZeeCard">
                <ContentTabStackBarChart dataSetNeteorkShare={dataSetNeteorkShareZee15} img={zeeLogoViewer} total={[zeeLWTotal, zeeCWTotal]} />
                <ContentTabStackBarChart dataSetNeteorkShare={dataSetNeteorkShareStar15} img={zeeLogoStar} total={[starLWTotal, starCWTotal]} />
                <ContentTabStackBarChart dataSetNeteorkShare={dataSetNeteorkShareSuntv15} img={zeeLogoSunTv} total={[sunLWTotal, sunCWTotal]} />
                <ContentTabStackBarChart dataSetNeteorkShare={dataSetNeteorkShareViaCom15} img={zeeLogov18} total={[viaComLWTotal, viaComCWTotal]} />
                <ContentTabStackBarChart dataSetNeteorkShare={dataSetNeteorkShareSony15} img={zeeLogoSony} total={[sonyWTotal, sonyCWTotal]} />
                <ContentTabStackBarChart dataSetNeteorkShare={dataSetNeteorkShareDisney15} img={zeeLogoDsney} total={[disneyLWTotal, disneyCWTotal]} />
              </div>}
            </div>
          </div>
          <div className="exec-summ-lin-viewership-summary-network-share-all-chart-div-syn">

            <div className="execu-summ-linear-con-all-jener-card">

              <div className="exec-summ-lin-viewership-summary-network-share-all-chart-boxs">
                <h6 className="exec-summ-lin-viewership-summary-network-share-heading-syn">Genre Trends</h6>
                <div className="executive-summ-con-linear-viewer-bottom">
                  <p class="executive-summ-con-linear-viewer-Increase-box ">
                    <span className="colour-box-chart"></span>Increase from Last Wk</p>

                  <p class="executive-summ-con-linear-viewer-Decrease-box">
                    <span className="colour-box-chart-two"></span>Decrease from Last Wk</p>
                </div>
              </div>

              <div className="executive-summary-con-lin-line-chart-with-labels-container-syn">

                <div className="executive-summary-con-lin-line-chart-with-labels-container-chiled-div-syn">
                  <div className="execu-summ-linear-con-all-jener-indicators-syn">
                    <label className="exe-sum-linear-con-viewer-summ-line-chart-label-empety-syn"></label>
                    <label className="execu-summ-linear-con-all-jener-indicator-one-syn">Current Wk.</label>
                    <label className="execu-summ-linear-con-all-jener-indicator-two-syn">Last Wk.</label>
                    <label className="execu-summ-linear-con-all-jener-indicator-three-syn">4 Wk.</label>
                    <label className="execu-summ-linear-con-all-jener-indicator-four-syn">13 Wk.</label>
                    <label className="execu-summ-linear-con-all-jener-indicator-five-syn">52 Wk.</label>
                  </div>

                  <div>
                  {filterSelectValueTarget == '2' && <div>
                      <div className="executive-summ-cont-lin-viewer-all-gener-syn">
                        <ContentLinearViewershipLineChart backColor={'#CFD3F4'} chartLabel={'Total PUT'} chartData={[2,3,4,5,6]} />
                        <ContentLinearViewershipLineChartSouthRegionalGECs backColor={'#F0F1FA'} chartLabel={'South Regional GECs'} chartData={[2,3,4,5, 9]} />
                      </div>
                      <div className="executive-summ-cont-lin-viewer-all-gener-syn">
                        <ContentLinearViewershipLineChartHindiGEC backColor={'#F5F5F5'} chartLabel={'Hindi GEC'} chartData={[2,3,4,5, 7]} />
                        <ContentLinearViewershipLineChartHindiMovies backColor={'#F0F1FA'} chartLabel={'Hindi Movies'} chartData={[2,3,4,5,5]} />
                      </div>
                      <div className="executive-summ-cont-lin-viewer-all-gener-syn">
                        <ContentLinearViewershipLineChartHSMRegionalGECs backColor={'#F5F5F5'} chartLabel={'HSM Regional GECs'} chartData={[2,3,4,5,6]} />
                        <ContentLinearViewershipLineChartNewsSouthRegionalNews backColor={'#F0F1FA'} chartLabel={'News'} chartData={[2,3,4,5,7]} />
                      </div>
                      <div className="executive-summ-cont-lin-viewer-all-gener-syn">
                        <ContentLinearViewershipLineChartSouthRegionalMovies backColor={'#F5F5F5'} chartLabel={'South Regional Movies'} chartData={[2,3,4,5,7]} />
                        <ContentLinearViewershipLineChartKids backColor={'#F0F1FA'} chartLabel={'Kids'} chartData={[2,3,4,5,8]} />
                      </div>
                      <div className="executive-summ-cont-lin-viewer-all-gener-syn">
                        <ContentLinearViewershipLineChartMusic backColor={'#F5F5F5'} chartLabel={'Music'} chartData={[2,3,4,5,2]} />
                        <ContentLinearViewershipLineChartOthers backColor={'#F0F1FA'} chartLabel={'Others'} chartData={[2,3,4,5,1]} />
                      </div>
                      <div className="executive-summ-cont-lin-viewer-all-gener-syn">
                        <ContentLinearViewershipLineChartHSMRegionalMovies backColor={'#F5F5F5'} chartLabel={'HSM Regional Movies'} chartData={[2,3,4,5,3]} />
                        <ContentLinearViewershipLineChartSport backColor={'#F0F1FA'} chartLabel={'Sport'} chartData={[2,3,4,5,2]} />
                      </div>
                    </div>}
                    {filterSelectValueTarget == '15' && <div>
                      <div className="executive-summ-cont-lin-viewer-all-gener-syn">
                        <ContentLinearViewershipLineChart backColor={'#CFD3F4'} chartLabel={'Total PUT'} chartData={[200,300,400,500, 600]} />
                        <ContentLinearViewershipLineChartSouthRegionalGECs backColor={'#F0F1FA'} chartLabel={'South Regional GECs'} chartData={[200,300,400,500,300]} />
                      </div>
                      <div className="executive-summ-cont-lin-viewer-all-gener-syn">
                        <ContentLinearViewershipLineChartHindiGEC backColor={'#F5F5F5'} chartLabel={'Hindi GEC'} chartData={[200,300,400,500,100]} />
                        <ContentLinearViewershipLineChartHindiMovies backColor={'#F0F1FA'} chartLabel={'Hindi Movies'} chartData={[2,3,4,5,2]} />
                      </div>
                      <div className="executive-summ-cont-lin-viewer-all-gener-syn">
                        <ContentLinearViewershipLineChartHSMRegionalGECs backColor={'#F5F5F5'} chartLabel={'HSM Regional GECs'} chartData={[2,3,4,5,4]} />
                        <ContentLinearViewershipLineChartNewsSouthRegionalNews backColor={'#F0F1FA'} chartLabel={'News'} chartData={[2,3,4,5,6]} />
                      </div>
                      <div className="executive-summ-cont-lin-viewer-all-gener-syn">
                        <ContentLinearViewershipLineChartSouthRegionalMovies backColor={'#F5F5F5'} chartLabel={'South Regional Movies'} chartData={[2,3,4,5,5]} />
                        <ContentLinearViewershipLineChartKids backColor={'#F0F1FA'} chartLabel={'Kids'} chartData={[2,3,4,5,3]} />
                      </div>
                      <div className="executive-summ-cont-lin-viewer-all-gener-syn">
                        <ContentLinearViewershipLineChartMusic backColor={'#F5F5F5'} chartLabel={'Music'} chartData={[2,3,4,5,2]} />
                        <ContentLinearViewershipLineChartOthers backColor={'#F0F1FA'} chartLabel={'Others'} chartData={[2,3,4,5,2]} />
                      </div>
                      <div className="executive-summ-cont-lin-viewer-all-gener-syn">
                        <ContentLinearViewershipLineChartHSMRegionalMovies backColor={'#F5F5F5'} chartLabel={'HSM Regional Movies'} chartData={[2,3,4,5,1]} />
                        <ContentLinearViewershipLineChartSport backColor={'#F0F1FA'} chartLabel={'Sport'} chartData={[2,3,4,5,2]} />
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {buttonTab === 'Viewership' &&
        <div className="plotdiv">
          <div className="Key-title">
            <h6 className="exec-summ-lin-viewership-summary-network-share-heading-syn">Key Channel Movement</h6>
            <h6 className="exec-summary-content">Reporting GRP movement for the Top Channels across Networks</h6>
          </div>
          <div className="exec-summ-lin-viewership-summary-network-share-plotcard-perent-div-syn">
            <div className="exec-summ-lin-viewership-summary-network-share-plotcard-syn">
              <div className="chennalimage">
                <img src={zeeLogoViewer} />
                <img src={zeeLogoStar} />
                <img src={zeeLogoSunTv} />
                <img src={zeeLogov18} />
                <img src={zeeLogoSony} />
              </div>
              <PlotChart data={plotData} />
            </div>
          </div>
        </div>}
      {buttonTab === 'Slot-Leadership' && <div>
        {/* <ExecutiveSummContLinSlotLeadership /> */}
        <ContentSchedulingWithHeatMapAndDropdowns />
      </div>}

      {/* <div>
        {
          loadingLinear === true ? <LoderLinear /> : <></>
        }
      </div> */}

    </div>
  );
};

export default ExecutiveSummaryContentLinear;
