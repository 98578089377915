import { useEffect, useState, useRef } from 'react';
import CustomerCard from '../../Product/CustomerCard/CustomerCard';
import OverlapedBarChart from '../../Charts/BarChart/OverlapedBarChart';
import AdvertiserandBrandRanking from './AdvertiserandBrandRanking';
import ExecutiveSumCustomerDivergingSB from '../../Charts/DivergingSB/ExecutiveSumCustomerDivergingSB';
import { DropDownMenu, MultiSelectDropDownMenu } from '../../ContentTab/ContentSchedulingWithHeatMapAndDropdowns/ContentSchedulingWithHeatMapAndDropdowns';
import HorizontalBarChartForExectSummaryCustomerLinear from '../../Charts/HorizontalProgressBarChartForTable/HorizontalBarChartForExectSummaryCustomerLinear';
import ContentSchedulingWithHeatMapAndDropdowns from '../../ContentTab/ContentSchedulingWithHeatMapAndDropdowns/ContentSchedulingWithHeatMapAndDropdowns';
import { useMediaQuery } from '@material-ui/core';
import ExecutiveSummaryCustomerOTT from './ExecutiveSummaryCustomerOTT/ExecutiveSummaryCustomerOTT';
import ExecutiveSummaryServicesCustomerLinear from '../../../Services/ExecutiveSummaryServices/ExecutiveSummaryServicesCustomerLinear';
import {
  progressBarChartSampleData,
  targetTrendSampleData,
  advertiserAndBrandRankingRailsForOnePeriod,
  totalAdvertiserAndBrandRankingRevenue,
  keyImpactMetricsDataForLinear,
  valueCoOrdinatesForSBConvergence,
  politifact,
  filterOptions,
  horizontalBarchartAdGRPData,
  railsData,
  barndAndAdvertiserRankingRails,
  filterOptionsForKeyContributionAnalysisCustomerOTT,
} from './data';
import './Customer.css';
import AdvertiserAndBrandsRankingHeatmapPopupModal, { HeatMapForCompetetiveShareAnalysis } from './AdvertiserAndBrandsRankingHeatmapPopupModal';
import data from '../../Charts/HeatMap/data';
import * as d3 from 'd3';
import MultiGroupLineChart from '../../Charts/MultiGroupLineChart/MultiGroupLineChart';
import ExecutiveSummaryServicesCustomerOTT from '../../../Services/ExecutiveSummaryServices/ExecutiveSummaryServicesCustomerOTT';

const Customer = (props) => {

  // const [toggleButtonClass, setToggleButtonClass] = useState(false);
  // const [isPopupModalActive, setIsPopupModalActive] = useState(true);

  const width1057 = useMediaQuery('(max-width: 1057px)');

  // const handleChangeOfToggle = (e) => {
  //     setToggleButtonClass(e.target.checked);
  // }

  let today = new Date();
  let yesterday = today.setDate(today.getDate() - 1);

  const preparedInputDataForTargetAchievement = prepareInputDataForTargetAchievement(targetTrendSampleData);

  const [activeTab, setActiveTab] = useState('linear');
  const [activeTabForCustomerLinear, setActiveTabForCustomerLinear] = useState('internalInsights');

  const [selectedAdvertiserName, setSelctedAdvertiserName] = useState('');

  const handleSelectedAdvertiserNameChange = (selectedFilterType, advertiserName, event) => {

    console.log('*******selectedFilterType in customer**********', selectedFilterType, '***********advertiserName in customer********', advertiserName,)

    if (selectedFilterType === 'Advertiser')
      setSelctedAdvertiserName(previous => {
        if (advertiserName === previous)
          return ''
        else
          return advertiserName;
      })
    else
      setSelctedAdvertiserName('');
  }

  return (
    <>
      <div>
        <div className="executive-summary-content-tabs">
          <div onClick={() => setActiveTab('linear')} className={activeTab === 'linear' ? ("executive-summary-content-active-tab") : ("executive-summary-content-tab")}>Linear</div>
          <div onClick={() => setActiveTab('ott')} className={activeTab === 'ott' ? ("executive-summary-content-active-tab") : ("executive-summary-content-tab")}>OTT</div>
        </div>

        <div>
          {activeTab === 'linear' &&

            <div className='executive-summary-customer-linear-customer-syn'>
              <div className='executive-summary-customer-linear-tabs'>
                <button className={
                  activeTabForCustomerLinear === 'internalInsights' ?
                    'executive-summary-customer-linear-tabs-button-active' :
                    'executive-summary-customer-linear-tabs-button-inactive'
                }
                  onClick={() => setActiveTabForCustomerLinear('internalInsights')}
                >
                  Internal Insights
                </button>
                <button className={
                  activeTabForCustomerLinear === 'competetiveIntelligence' ?
                    'executive-summary-customer-linear-tabs-button-active' :
                    'executive-summary-customer-linear-tabs-button-inactive'
                }
                  onClick={() => setActiveTabForCustomerLinear('competetiveIntelligence')}
                >
                  Competitive Intelligence
                </button>
                <button className={
                  activeTabForCustomerLinear === 'keyPartners' ?
                    'executive-summary-customer-linear-tabs-button-active' :
                    'executive-summary-customer-linear-tabs-button-inactive'
                }
                  onClick={() => setActiveTabForCustomerLinear('keyPartners')}
                >
                  Key Partners
                </button>
              </div>
              {activeTabForCustomerLinear === 'internalInsights' &&
                <div className='executive-summary-customer-linear-internal-insights-date-note'>
                  <p>Revenue (INR Mn.) reported as on <i>{d3.timeFormat("%d-%m-%Y")(yesterday)}</i>.</p>
                  <p>GRP and FCT reported as on <i>07-04-2023</i>.</p>
                </div>}
              <div className='executive-summary-customer-linear-tabs-content'>
                {
                  activeTabForCustomerLinear === 'internalInsights' &&
                  <>
                    <ExecutiveSummaryCustomerTargetsAchievements
                      dataForMOMTargetAchievementContainer={{
                        dataForMTDRevenurChart: preparedInputDataForTargetAchievement.dataForMTDRevenurChart
                      }}
                      dataForCumulativeTargetAchievementContainer={{
                        dataForYTMRevenueChart: preparedInputDataForTargetAchievement.dataForYTMRevenueChart
                      }}
                    />
                    <KeyContributionAnalysis
                      width1057={width1057}
                    />
                  </>
                }
                {
                  activeTabForCustomerLinear === 'keyPartners'
                  &&
                  <>
                    <div className='executive-summary-customer-linear-internal-insights-date-note'>
                      <p>Advertiser and Brand ranking computed using Revenue. Ad share analysis computed using Ad GRP.</p>
                    </div>
                    <div className='executive-summary-linear-advertiser-and-brand-ranking-syn'>
                      <AdvertiserandBrandRanking
                        railsDataForOnePeriod={railsData}
                        totalRevenue={totalAdvertiserAndBrandRankingRevenue}
                        handleSelectedAdvertiserNameChange={handleSelectedAdvertiserNameChange}
                      />
                    </div>
                    <AdvertiserAndBrandsRankingHeatmapPopupModal
                      selectedAdvertiserName={selectedAdvertiserName}
                    />
                  </>
                }

                {
                  activeTabForCustomerLinear === 'competetiveIntelligence'
                  &&
                  <CompetitiveIntelligence />
                }
              </div>
            </div>
          }
          {activeTab === 'ott' && <ExecutiveSummaryCustomerOTT />}
        </div>
      </div>

    </>

  )
}


const KeyImpactMetricsForExecutiveSummaryCustomer = (props) => {
  const { containerData, containerTitle = 'Key Impact Metrics', containerNote = 'Ad GRP reported as of Mar 3, 2023 and Ad Revenue reported as of Mar 13, 2023' } = props;

  // console.log('containerData', containerData);

  return (
    <div className='executive-summary-linear-customer-syn' >
      <div className='executive-summary-customer-linear-key-impact-metric-title-note-container'>
        <h6>{containerTitle}</h6>
        {/* <p>{containerNote}</p> */}
      </div>
      <div className='executive-sum-customer-linear-kpi-cards-container'>
        {
          containerData.map((element, index) => {
            return (
              <div className="ExecutiveCostomer" key={index}>
                <CustomerCard data={element} />
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

const ExecutiveSummaryCustomerTargetsAchievements = (props) => {
  const { dataForMOMTargetAchievementContainer, dataForCumulativeTargetAchievementContainer, isOTT = false } = props;

  const [trendData, setTrendData] = useState(null);
  const [preparedInputDataForTargetAchievement, setPreparedInputDataForTargetAchievement] = useState(null);

  const getDataForTargetAchievementTrend = async () => {
    let trendData;
    if (!isOTT) {
      trendData = await ExecutiveSummaryServicesCustomerLinear.getDataForTrendForTargetAchievementKPIExectSumCustomerLiner();
    } else {
      trendData = await ExecutiveSummaryServicesCustomerOTT.getDataForTrendForTargetAchievementKPIExectSumCustomerLiner();
    }
    // console.log('************TrendData From API Call******************************', trendData);
    // console.log('************JSON Parsed TrendData From API Call******************************', JSON.parse(trendData));

    // setTrendData(JSON.parse(await ExecutiveSummaryServicesCustomerLinear.getDataForTrendForTargetAchievementKPIExectSumCustomerLiner()));
    setTrendData(JSON.parse(trendData));
  }

  useEffect(() => {
    getDataForTargetAchievementTrend();
  }, [])


  useEffect(() => {

    // console.log('trendData from API calling', trendData);
    if (trendData) {
      setPreparedInputDataForTargetAchievement(prepareInputDataForTargetAchievement(trendData));
    }

  }, [trendData]);

  // console.log('prepareInputDataForTargetAchievement', preparedInputDataForTargetAchievement);

  let yearForSummary, monthForSummary, dataForPercentageAchievements;
  if (preparedInputDataForTargetAchievement) {
    let dateForSummary = new Date(preparedInputDataForTargetAchievement.dataForMTDRevenurChart.achievement[23].monthYear);
    yearForSummary = dateForSummary.getFullYear();
    monthForSummary = dateForSummary.toLocaleString('default', { month: 'long' });
    dataForPercentageAchievements = getDataForPercentageAchievements(preparedInputDataForTargetAchievement);
  }


  // }, [preparedInputDataForTargetAchievement]);


  return (

    <div className='executive-summary-customer-linear-target-achivement-syn'>
      {
        preparedInputDataForTargetAchievement &&
        <div className='d-flex justify-content-between mb-2'>
          <div className='d-flex'>
            <h6 className='exe-sum-target-achivement-headiing-syn'>Target Achievement</h6>
            <svg style={{ marginLeft: '8px' }} xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-ino-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
          </div>
          <div className='executive-summary-customer-linear-target-achivement-legends-filter-container d-flex justify-content-between'>
            <div className='executive-summary-customer-linear-target-achivement-legends-filter-container-placeholder'></div>
            <div className='exe-sum-lin-label-text-indicator-syn'>
              <label className='executive-summary-linear-white-label-indicator-syn'></label>Target
              <label className='executive-summary-linear-green-label-indicator-syn'></label>Achieved Above
              <label className='executive-summary-linear-red-label-indicator-syn'></label>Achieved Below
            </div>
            {/* <div className='executive-summary-customer-linear-target-achivement-filter-container'>
                            <label className="exe-sum-add-toogle-switch-syn" style={{ 'marginTop': '-3px' }}>
                                <span class="exe-sum-toggle-on-syn">Channel</span>
                            </label>
                            <div className='executive-summary-customer-linear-target-achivement-filter-div'>
                                <p>All</p>
                                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.00893 0L4 3.01432L0.991063 0L0 0.992838L4 5L8 0.992838L7.00893 0Z" fill="#808080" />
                                </svg>

                            </div>
                        </div> */}

          </div>
        </div>
      }
      {
        preparedInputDataForTargetAchievement &&
        <div className='executive-summary-linear-taget-achivement-syn'>
          <div className='executive-summary-linear-taget-achivment-chiled-syn'>
            <div className='executive-summary-linear-taget-achiv-cards-syn'>
              <div className='executive-summary-linear-taget-achiv-card-syn'>
                <p>% Target Achievement for the month of {monthForSummary} (MTD)</p>
                <h6>{dataForPercentageAchievements.momPrecentagetargetAchievement ? dataForPercentageAchievements.momPrecentagetargetAchievement : ''}
                  {
                    dataForPercentageAchievements.momPrecentagetargetAchievement === "Data N.A." ? '' : "%"
                  }
                </h6>
              </div>
              <div className='executive-summary-linear-taget-achiv-card-syn'>
                <p>Revenue (INR Mn.) for the month of {monthForSummary} (MTD)</p>
                <h6>{dataForPercentageAchievements.mtdRevenue}</h6>
              </div>
            </div>
            <div>
              <p className='executive-sum-customer-linear-overlapped-bar-chart-heading'>Month-On-Month Absolute Target Achievement</p>
              <OverlapedBarChart
                // data={dataForMOMTargetAchievementContainer.dataForMTDRevenurChart}
                data={preparedInputDataForTargetAchievement.dataForMTDRevenurChart}
              />
            </div>
          </div>

          <div className='executive-summary-linear-bdr-syn' />

          <div className='executive-summary-linear-taget-achivment-chiled-syn'>
            <div className='executive-summary-linear-taget-achiv-cards-syn'>
              <div className='executive-summary-linear-taget-achiv-card-syn'>
                <p>% Target Achievement for the year {yearForSummary} {monthForSummary} (YTD)</p>
                <h6>{dataForPercentageAchievements.cumulativePrecentageTargetAchievement ? dataForPercentageAchievements.cumulativePrecentageTargetAchievement : ''}
                  {
                    dataForPercentageAchievements.cumulativePrecentageTargetAchievement === "Data N.A." ? '' : "%"
                  }
                </h6>
              </div>
              <div className='executive-summary-linear-taget-achiv-card-syn'>
                <p>Revenue (INR Mn.) for the year {yearForSummary} {monthForSummary} (YTD)</p>
                <h6>{dataForPercentageAchievements.ytdRevenue}</h6>
              </div>
            </div>
            <div>
              <p className='executive-sum-customer-linear-overlapped-bar-chart-heading'>Cumulative Target Achievement</p>
              <OverlapedBarChart
                // data={dataForCumulativeTargetAchievementContainer.dataForYTMRevenueChart}
                data={preparedInputDataForTargetAchievement.dataForYTMRevenueChart}
              />
            </div>
          </div>


        </div>
      }

    </div>
  )
}

const KeyContributionAnalysis = (props) => {

  const { viewBoxWidth = 680, xRange = 550, isOTT = false } = props

  const { width1057 = false, className = 'executive-summary-customer-linear-target-achivement-syn' } = props;

  // For OTT Consider AdGRP = Impressions, FCT = Clicks

  const [AdGRPData, setAdGRPData] = useState(null);
  const [FCTData, setFCTData] = useState(null);
  const [revenueData, setRevenueData] = useState(null);

  const getDataForTargetAchievementTrend = async () => {
    let AdGRPData;
    let FCTData;
    let revenueData;

    if (!isOTT) {
      AdGRPData = await ExecutiveSummaryServicesCustomerLinear.getKeyContriAnalysisAdGRPExectSumCustomerLiner();
      FCTData = await ExecutiveSummaryServicesCustomerLinear.getKeyContriAnalysisFCTExectSumCustomerLiner();
      revenueData = await ExecutiveSummaryServicesCustomerLinear.getKeyContriAnalysisRevenueExectSumCustomerLiner();
    } else {
      AdGRPData = await ExecutiveSummaryServicesCustomerOTT.getKeyContriAnalysisImpressionsExectSumCustomerOTT();
      FCTData = await ExecutiveSummaryServicesCustomerOTT.getKeyContriAnalysisClickExectSumCustomerOTT();
      revenueData = await ExecutiveSummaryServicesCustomerOTT.getKeyContriAnalysisRevenueExectSumCustomerOTT();
    }

    setAdGRPData(JSON.parse(AdGRPData));
    setFCTData(JSON.parse(FCTData))
    setRevenueData(JSON.parse(revenueData));
  }

  useEffect(() => {
    // getDataForTargetAchievementTrend();
  }, [])

  const [selectedKeyContributionAnalysisFilters, setSelectedKeyContributionAnalysisFilters] = useState({
    metricKeyContributionAnalysis: 'Revenue',
    filterTypeKeyContributionAnalysis: 'Zones'
  });

  const handleOnDropDownMenuSelectionChange = (filterName, value, event) => {
    setSelectedKeyContributionAnalysisFilters({
      ...selectedKeyContributionAnalysisFilters,
      [filterName]: value,
    });
  }

  const [toggleButtonClass, setToggleButtonClass] = useState(false);
  const handleChangeOfToggle = (e) => {
    setToggleButtonClass(e.target.checked);
  }

  const [preaparedDataForHorizontalBarChart, setPreaparedDataForHorizontalBarChart] = useState();
  const [preaparedDataForConvergingDivergingSB, setPreaparedDataForConvergingDivergingSB] = useState();

  // useEffect(() => {
  //   if (AdGRPData && FCTData && revenueData) {
  //     if (!isOTT) {
  //       if (selectedKeyContributionAnalysisFilters.metricKeyContributionAnalysis === 'AD GRP') {
  //         setPreaparedDataForHorizontalBarChart(prepareDataForKeyContributionAnalysisHorizontalBarChart(AdGRPData, selectedKeyContributionAnalysisFilters, toggleButtonClass === false ? 'mtd' : 'ytd', 'AdGRP'))
  //         // setPreaparedDataForHorizontalBarChart(prepareDataForKeyContributionAnalysisHorizontalBarChart(horizontalBarchartAdGRPData, selectedKeyContributionAnalysisFilters, toggleButtonClass === false ? 'mtd' : 'ytd', 'AdGRP'))
  //         setPreaparedDataForConvergingDivergingSB(prepareDataForKeyContributionAnalysisConverginDivergingSB(AdGRPData, selectedKeyContributionAnalysisFilters, toggleButtonClass === false ? 'mtd' : 'ytd', 'AdGRP'))
  //       } else if (selectedKeyContributionAnalysisFilters.metricKeyContributionAnalysis === 'FCT') {
  //         setPreaparedDataForHorizontalBarChart(prepareDataForKeyContributionAnalysisHorizontalBarChart(FCTData, selectedKeyContributionAnalysisFilters, toggleButtonClass === false ? 'mtd' : 'ytd', 'FCT'))
  //         setPreaparedDataForConvergingDivergingSB(prepareDataForKeyContributionAnalysisConverginDivergingSB(FCTData, selectedKeyContributionAnalysisFilters, toggleButtonClass === false ? 'mtd' : 'ytd', 'FCT'))
  //       } else {
  //         setPreaparedDataForHorizontalBarChart(prepareDataForKeyContributionAnalysisHorizontalBarChart(revenueData, selectedKeyContributionAnalysisFilters, toggleButtonClass === false ? 'mtd' : 'ytd', 'revenue'))
  //         setPreaparedDataForConvergingDivergingSB(prepareDataForKeyContributionAnalysisConverginDivergingSB(revenueData, selectedKeyContributionAnalysisFilters, toggleButtonClass === false ? 'mtd' : 'ytd', 'revenue'))
  //       }
  //     } else {
  //       if (selectedKeyContributionAnalysisFilters.metricKeyContributionAnalysis === 'Impressions') {
  //         setPreaparedDataForHorizontalBarChart(prepareDataForKeyContributionAnalysisHorizontalBarChart(AdGRPData, selectedKeyContributionAnalysisFilters, toggleButtonClass === false ? 'mtd' : 'ytd', 'impressions'))
  //         setPreaparedDataForConvergingDivergingSB(prepareDataForKeyContributionAnalysisConverginDivergingSB(AdGRPData, selectedKeyContributionAnalysisFilters, toggleButtonClass === false ? 'mtd' : 'ytd', 'impressions'))
  //       } else if (selectedKeyContributionAnalysisFilters.metricKeyContributionAnalysis === 'Clicks') {
  //         setPreaparedDataForHorizontalBarChart(prepareDataForKeyContributionAnalysisHorizontalBarChart(FCTData, selectedKeyContributionAnalysisFilters, toggleButtonClass === false ? 'mtd' : 'ytd', 'clicks'))
  //         setPreaparedDataForConvergingDivergingSB(prepareDataForKeyContributionAnalysisConverginDivergingSB(FCTData, selectedKeyContributionAnalysisFilters, toggleButtonClass === false ? 'mtd' : 'ytd', 'clicks'))
  //       } else {
  //         setPreaparedDataForHorizontalBarChart(prepareDataForKeyContributionAnalysisHorizontalBarChart(revenueData, selectedKeyContributionAnalysisFilters, toggleButtonClass === false ? 'mtd' : 'ytd', 'revenue'))
  //         setPreaparedDataForConvergingDivergingSB(prepareDataForKeyContributionAnalysisConverginDivergingSB(revenueData, selectedKeyContributionAnalysisFilters, toggleButtonClass === false ? 'mtd' : 'ytd', 'revenue'))
  //       }
  //     }
  //   }
  // }, [selectedKeyContributionAnalysisFilters, toggleButtonClass, AdGRPData, FCTData, revenueData]);


  return (
    < div className={className} >
      <div className='executive-sumary-linear-key-con-analyst-syn'>
        <div className='d-flex'>
          <h6 className='exe-sum-target-achivement-headiing-syn'>Key Contribution Analysis</h6>
          <svg style={{ marginLeft: '8px' }} xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-ino-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
        </div>
        <div>
          <label class="exe-sum-toggle-switch-syn">
            {toggleButtonClass === true ? (<span className="executive-sum-lin-checkbox-label2-syn">YTD</span>) : (<span className="executive-sum-lin-checkbox-label1-syn">MTD</span>)}

            <input onChange={handleChangeOfToggle} className='executive-sum-lin-checkbox' placeholder='on' type="checkbox" id="togBtn" />
          </label>
        </div>
      </div>

      <div className='d-flex exect-summary-customer-lin-key-contribution-analysis-filters-container' style={{ "marginLeft": "0 rem" }}>
        <DropDownMenu
          dropDownMenu={!isOTT ? filterOptions.metricKeyContributionAnalysis : filterOptionsForKeyContributionAnalysisCustomerOTT.metricKeyContributionAnalysis}
          handleOnDropDownMenuSelectionChange={handleOnDropDownMenuSelectionChange}
          isMendatory={false}
          labelClassName={'drop-down-menu-label-bold'}
        />
        {!isOTT ? <DropDownMenu
          dropDownMenu={!isOTT ? filterOptions.filterTypeKeyContributionAnalysis : filterOptionsForKeyContributionAnalysisCustomerOTT.filterTypeKeyContributionAnalysis}
          handleOnDropDownMenuSelectionChange={handleOnDropDownMenuSelectionChange}
          isMendatory={false}
          labelClassName={'drop-down-menu-label-bold'}
        /> :
          <DropDownMenu
            dropDownMenu={!isOTT ? filterOptions.filterTypeKeyContributionAnalysis : filterOptionsForKeyContributionAnalysisCustomerOTT.filterTypeKeyContributionAnalysis}
            handleOnDropDownMenuSelectionChange={handleOnDropDownMenuSelectionChange}
            isMendatory={false}
            labelClassName={'drop-down-menu-label-bold'}
            classLists={
              {
                dropDownMenuContainer: 'drop-down-menu-contaier-for-key-contribution-analysis-customer-ott',
              }
            }
          />
        }
      </div>
      <div className='d-flex justify-content-between mt-4'>
        <div className='exe-sum-key-contribution-analysis-chiled-syn'>
          {preaparedDataForHorizontalBarChart && preaparedDataForHorizontalBarChart.length &&
            <HorizontalBarChartForExectSummaryCustomerLinear
              viewBoxWidth={viewBoxWidth}
              xRange={xRange}
              data={preaparedDataForHorizontalBarChart}
            />}

          {/* <HorizontalBarChartForExectSummaryCustomerLinear
            viewBoxWidth={viewBoxWidth}
            xRange={xRange}
            data={horizontalBarchartAdGRPData}
          /> */}
        </div>

        <div className='exe-sum-key-contribution-analysis-chiled-syn'>
          {/* {politifact.chart1.data.map((element, index) => {
                        return (
                            <div className='exe-summ-key-cont-ana-chart1-syn' key={index}>
                                <label className='exe-summ-key-cont-ana-chart1-label-syn'>{element[0].speaker}</label>
                                <ExecutiveSumCustomerDivergingSB width={550} height={35} politifact={element} valueCoOrdinatesForSBConvergence={valueCoOrdinatesForSBConvergence[index]} width1057={width1057} />
                            </div>
                        )
                    })} */}
          {/* {
                        preaparedDataForConvergingDivergingSB && preaparedDataForConvergingDivergingSB.length &&
                        preaparedDataForConvergingDivergingSB.map((element, index) => {
                            return (
                                <div className='exe-summ-key-cont-ana-chart1-syn' key={index}>
                                    <label className='exe-summ-key-cont-ana-chart1-label-syn'>{!element[0].speaker ? '' : element[0].speaker.length <= 10 ? element[0].speaker : element[0].speaker.slice(0, 8) + '...'}</label>
                                    {
                                        !isOTT ?
                                            <ExecutiveSumCustomerDivergingSB width={600} height={35} politifact={element} valueCoOrdinatesForSBConvergence={valueCoOrdinatesForSBConvergence[1]} width1057={width1057} />
                                            :
                                            <ExecutiveSumCustomerDivergingSB width={600} height={26} politifact={element} valueCoOrdinatesForSBConvergence={valueCoOrdinatesForSBConvergence[1]} iswidth1057={true} isOTT={isOTT} />
                                    }
                                    <HorizontalBarChartForExectSummaryCustomerLinear
                                        viewBoxWidth={viewBoxWidth}
                                        xRange={xRange}
                                        data={preaparedDataForConvergingDivergingSB}
                                    />
                                </div>
                            )
                        })

                    } */}

          {
            preaparedDataForConvergingDivergingSB && preaparedDataForConvergingDivergingSB.length &&
            <HorizontalBarChartForExectSummaryCustomerLinear
              viewBoxWidth={viewBoxWidth}
              xRange={xRange}
              data={preaparedDataForConvergingDivergingSB}
              isOTT={true}
            />

          }

        </div>
      </div>

      <div>

      </div>
    </div >
  )

}

const CompetitiveIntelligence = (props) => {

  const [toggleButtonClass, setToggleButtonClass] = useState(false);
  const handleChangeOfToggle = (e) => {
    setToggleButtonClass(e.target.checked);
  }

  const [isShowForAdvertisers, setIsShowForAdvertisers] = useState(true);
  const [dataForCompetitiveIntelligence, setDataForCompetitiveIntelligence] = useState();

  const filterOptionsForCompetetiveIntelligence =
  {
    id: 'network',
    label: 'Network',
    isMendatory: false,
    isMultiselect: true,
    options: [
      "All",
      "Star Network Ent - CY",
      "ZEEL - CY",
      "Sony Network Ent - CY",
      "Sun - CY",
      "Viacom - CY",
      "Star Network Ent - LY",
      "ZEEL - LY",
      "Sony Network Ent - LY",
      "Sun - LY",
      "Viacom - LY",
    ]
  }

  let initialStateObject = {};

  // multiSelectDropDownOptions.forEach(element => element === 'All' ? initialStateObject[element] = true : initialStateObject[element] = false);
  filterOptionsForCompetetiveIntelligence.options.forEach(element => initialStateObject[element] = true);

  const [selectedSelectOptions, setSelectedSelectOptions] = useState(initialStateObject);
  const [previousSelectedSelectOptions, setPreviousSelectedSelectOptions] = useState(initialStateObject);

  const handleMultiDropDownSelectionChange = (action, id, state) => {
    // console.log('state', state);
    setSelectedSelectOptions(state)
  }


  const getDataForCompetitiveIntelligence = async () => {

    let trendData = await ExecutiveSummaryServicesCustomerLinear.getDataForTrendForTargetAchievementKPIExectSumCustomerLiner();
    // console.log('************TrendData From API Call******************************', trendData);
    // console.log('************JSON Parsed TrendData From API Call******************************', JSON.parse(trendData));

    // setTrendData(JSON.parse(await ExecutiveSummaryServicesCustomerLinear.getDataForTrendForTargetAchievementKPIExectSumCustomerLiner()));
    // setTrendData(JSON.parse(trendData));
  }

  useEffect(() => {
    getDataForCompetitiveIntelligence();
  }, [])

  return (
    <div className='executive-summary-customer-linear-competitive-intell-container'>
      <div className='executive-summary-customer-linear-competitive-intell-title-filter-legend-cont'>
        <div className='executive-sumary-linear-key-con-analyst-syn executive-summary-customer-linear-competitive-intell-title-filter-cont'>
          <div className='d-flex'>
            <h6 className='exe-sum-target-achivement-headiing-syn'>Competitive Intelligence</h6>
            <svg style={{ marginLeft: '8px' }} xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-ino-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
          </div>
          <div className="executive-summary-consumer-sankey-chart-toggle-filter-container executive-summary-customer-linear-compt-intell-toggle-filter-container">
            <label className={isShowForAdvertisers ? 'active-toggle' : 'in-active-toggle'}>Advertisers</label>
            <input type="checkbox" onClick={() => setIsShowForAdvertisers(!isShowForAdvertisers)}></input>
            <label className={!isShowForAdvertisers ? 'active-toggle' : 'in-active-toggle'}>Ad GRP</label>
          </div>
        </div>
        <div className='executive-summary-customer-linear-competitive-intell-dropdown-legend-cont'>
          <div className='executive-summary-customer-linear-competitive-intell-dropdown-cont'>
            <MultiSelectDropDownMenu
              handleMultiDropDownSelectionChange={handleMultiDropDownSelectionChange}
              multiSelectDropDownConfigs={filterOptionsForCompetetiveIntelligence}
            />
          </div>
          <div className='executive-summary-customer-linear-competitive-intell-title-legend-cont'></div>
        </div>
      </div>
      <MultiGroupLineChart
        isShowForAdvertisers={isShowForAdvertisers}
        selectedSelectOptions={selectedSelectOptions}
      />
    </div>
  )

}

function prepareDateObject(array, rangeMinDate, rangeMaxDate) {
  // console.log('array[0].monthYear', array[0].monthYear);
  // console.log("array in prepareDateObject", array, 'array[0]', array[0]);
  let actualMinDate = array[0].monthYear, actualMaxDate = array.slice(-1)[0].monthYear;
  let yearDiffForMinDate = Math.abs(actualMinDate.getFullYear() - rangeMinDate.getFullYear()),
    monthDiffForMinDate = actualMinDate.getMonth() - rangeMinDate.getMonth();
  let yearItetration = 0, month = rangeMinDate.getMonth(), year = rangeMinDate.getFullYear();
  for (let i = 1; i <= (yearDiffForMinDate * 12 + monthDiffForMinDate); i++) {
    month = month + 1;
    if (month <= 12) {
      array.push({
        year: year + yearItetration,
        month: month,
        // monthYear: `${year + yearItetration}-${month}`,
        monthYear: new Date(parseInt(year + yearItetration), parseInt(month) - 1, 1, 0, 0, 0),
        value: 0
      })
    } else {
      yearItetration++;
      month = 1;
      array.push({
        year: year + yearItetration,
        month: month,
        monthYear: new Date(parseInt(year + yearItetration), parseInt(month) - 1, 1, 0, 0, 0),
        value: 0
      })
    }
  }
  array
    .sort((a, b) => {
      return a.monthYear - b.monthYear
    });

  let yearDiffForMaxDate = Math.abs(rangeMaxDate.getFullYear() - actualMaxDate.getFullYear()),
    monthDiffForMaxDate = rangeMaxDate.getMonth() - actualMaxDate.getMonth();
  yearItetration = 0;
  month = rangeMaxDate.getMonth();
  year = rangeMaxDate.getFullYear();

  for (let i = 1; i <= (yearDiffForMaxDate * 12 + monthDiffForMaxDate); i++) {
    month = month + 1;
    if (month <= 12) {
      array.push({
        year: year + yearItetration,
        month: month,
        monthYear: `${year + yearItetration}-${month}`,
        value: 0
      })
    } else {
      yearItetration++;
      month = 1;
      array.push({
        year: year + yearItetration,
        month: month,
        monthYear: `${year + yearItetration}-${month}`,
        value: 0
      })
    }
  }
  array
    .sort((a, b) => {
      return a.monthYear - b.monthYear
    });

  return array;

}

function prepareInputDataForTargetAchievement(trendData) {
  let today = new Date();
  let yearValueForSecondPreviousYear = today.getFullYear() - 2;
  let monthValueForSecondPreviousYear = today.getMonth() + 2;
  let currentYear = today.getFullYear();
  let currentMonth = today.getMonth() + 1;
  let startDateForCurrentfiscalYear,
    startDateForPreviousfiscalYear, endDateForCurrentFiscalYear, endDateForPreviousFiscalYear;

  if (currentMonth > 3) {
    startDateForCurrentfiscalYear = new Date(parseInt(currentYear), parseInt(4) - 1, 1, 0, 0, 0);
    startDateForPreviousfiscalYear = new Date(parseInt(currentYear - 1), parseInt(4) - 1, 1, 0, 0, 0);
    endDateForCurrentFiscalYear = new Date(parseInt(currentYear + 1), parseInt(3) - 1, 31, 0, 0, 0)
    endDateForPreviousFiscalYear = new Date(parseInt(currentYear), parseInt(3) - 1, 31, 0, 0, 0)
  } else {
    startDateForCurrentfiscalYear = new Date(parseInt(currentYear - 1), parseInt(4) - 1, 1, 0, 0, 0);
    startDateForPreviousfiscalYear = new Date(parseInt(currentYear - 2), parseInt(4) - 1, 1, 0, 0, 0);
    endDateForCurrentFiscalYear = new Date(parseInt(currentYear), parseInt(3) - 1, 31, 0, 0, 0)
    endDateForPreviousFiscalYear = new Date(parseInt(currentYear - 1), parseInt(3) - 31, 1, 0, 0, 0)
  }

  // console.log('startDateForCurrentfiscalYear', startDateForCurrentfiscalYear);
  // console.log('trendForTarget[0].length', targetTrendSampleData.trendForTarget[0].length)

  let trendForTarget = trendData.trendForTarget[0]
    .map((item, index) => {
      // console.log('`${item.year}-${item.month}`', `${item.year}-${item.month}`, new Date(`${item.year}-${item.month}`));
      // console.log(today.getFullYear());
      let value = +(item.value / 10);
      return {
        ...item,
        value: value,
        // monthYear: `${item.year}-${item.month}`
        monthYear: new Date(parseInt(item.year), parseInt(item.month) - 1, 1, 0, 0, 0)
      }
    })
    .filter((item, index) => {
      if (item.monthYear.getTime() <= new Date(parseInt(currentYear), parseInt(currentMonth) - 1, 1, 0, 0, 0).getTime() && item.monthYear.getTime() >= new Date(parseInt(yearValueForSecondPreviousYear), parseInt(monthValueForSecondPreviousYear) - 1, 1, 0, 0, 0).getTime()) {
        return item
      }
    })
    .sort((a, b) => {
      return a.monthYear.getTime() - b.monthYear.getTime()
    });



  let trendForAchievement = trendData.trendForAchievement[0]
    .map((item, index) => {
      let value = +(item.value / 10);
      return {
        ...item,
        value: value,
        monthYear: new Date(parseInt(item.year), parseInt(item.month) - 1, 1, 0, 0, 0)
      }
    })
    .filter((item, index) => {
      if (item.monthYear.getTime() <= new Date(parseInt(currentYear), parseInt(currentMonth) - 1, 1, 0, 0, 0).getTime() && item.monthYear.getTime() >= new Date(parseInt(yearValueForSecondPreviousYear), parseInt(monthValueForSecondPreviousYear) - 1, 1, 0, 0, 0).getTime()) {
        return item
      }
    })
    .sort((a, b) => {
      return a.monthYear.getTime() - b.monthYear.getTime()
    });

  let preparedTargetArray = prepareDateObject(trendForTarget, new Date(parseInt(yearValueForSecondPreviousYear), parseInt(monthValueForSecondPreviousYear) - 1, 1, 0, 0, 0), new Date(parseInt(currentYear), parseInt(currentMonth) - 1, 1, 0, 0, 0));

  let preParedAchievementArray = prepareDateObject(trendForAchievement, new Date(parseInt(yearValueForSecondPreviousYear), parseInt(monthValueForSecondPreviousYear) - 1, 1, 0, 0, 0), new Date(parseInt(currentYear), parseInt(currentMonth) - 1, 1, 0, 0, 0));


  let dataForMTDRevenurChart = {
    target: preparedTargetArray,
    achievement: preParedAchievementArray,
  }

  let sumPreviousTargetValue = 0, sumOfPreviousAchievementValue = 0

  let totalTargetForPreviousYear = 0, totalTargetForCurrentYear = 0, totalAchievementForCurrentYear = 0, totalAchievementForPreviousYear = 0, totalAchievementForSecondForPreviousYear = 0, totalTargetForSecondForPreviousYear = 0;
  let dataForYTMRevenueChart = {
    target: preparedTargetArray.map((element, index) => {

      if (element.monthYear >= startDateForCurrentfiscalYear && element.monthYear <= endDateForCurrentFiscalYear) {
        totalTargetForCurrentYear += element.value;
        return {
          ...element,
          value: totalTargetForCurrentYear,
        }
      } else if (element.monthYear >= startDateForPreviousfiscalYear && element.monthYear <= endDateForPreviousFiscalYear) {

        totalTargetForPreviousYear += element.value;
        return {
          ...element,
          value: totalTargetForPreviousYear,
        }
      } else {
        totalTargetForSecondForPreviousYear += element.value;
        return {
          ...element,
          value: totalTargetForSecondForPreviousYear,
        }
      }
    }),
    achievement: preParedAchievementArray.map((element, index) => {
      var dateString = `${element.monthYear}`;
      var dateComponents = dateString.split(" ");
      var date = new Date(dateComponents[0]);

      if (element.monthYear >= startDateForCurrentfiscalYear && element.monthYear <= endDateForCurrentFiscalYear) {
        totalAchievementForCurrentYear += element.value;
        return {
          ...element,
          value: totalAchievementForCurrentYear,
        }
      } else if (element.monthYear >= startDateForPreviousfiscalYear && element.monthYear <= endDateForPreviousFiscalYear) {
        totalAchievementForPreviousYear += element.value;
        return {
          ...element,
          value: totalAchievementForPreviousYear,
        }
      } else {
        totalAchievementForSecondForPreviousYear += element.value;
        return {
          ...element,
          value: totalAchievementForSecondForPreviousYear,
        }
      }
    })
  }

  let achievementArrayWithFillValuesForYTMRevenueChart = dataForYTMRevenueChart.achievement.map((element, index) => {
    if (element.value >= dataForYTMRevenueChart.target[index].value) {
      return {
        ...element,
        fill: 'rgb(0, 196, 140)'
      }
    } else return {
      ...element,
      fill: 'rgb(255, 100, 124)',
    }
  })

  let achievementArrayWithFillValuesForMTDRevenueChart = dataForMTDRevenurChart.achievement.map((element, index) => {
    if (element.value >= dataForMTDRevenurChart.target[index].value) {
      return {
        ...element,

        fill: 'rgb(0, 196, 140)'
      }
    } else return {
      ...element,
      fill: 'rgb(255, 100, 124)',
    }
  })

  dataForYTMRevenueChart = {
    ...dataForYTMRevenueChart,
    achievement: achievementArrayWithFillValuesForYTMRevenueChart
  }

  dataForMTDRevenurChart = {
    ...dataForMTDRevenurChart,
    achievement: achievementArrayWithFillValuesForMTDRevenueChart,
  }

  return {
    dataForMTDRevenurChart,
    dataForYTMRevenueChart
  }
}

function getDataForPercentageAchievements(preparedInputDataForTargetAchievement) {
  let momPrecentagetargetAchievement, mtdRevenue, cumulativePrecentageTargetAchievement, ytdRevenue;
  if (preparedInputDataForTargetAchievement) {
    momPrecentagetargetAchievement = preparedInputDataForTargetAchievement.dataForMTDRevenurChart.target[23].value ? ((preparedInputDataForTargetAchievement.dataForMTDRevenurChart.achievement[23].value / preparedInputDataForTargetAchievement.dataForMTDRevenurChart.target[23].value) * 100).toFixed(0) : 'Data N.A.';

    mtdRevenue = (preparedInputDataForTargetAchievement.dataForMTDRevenurChart.achievement[23].value).toFixed(0);

    cumulativePrecentageTargetAchievement = preparedInputDataForTargetAchievement.dataForYTMRevenueChart.target[23].value ? ((preparedInputDataForTargetAchievement.dataForYTMRevenueChart.achievement[23].value / preparedInputDataForTargetAchievement.dataForYTMRevenueChart.target[23].value) * 100).toFixed(0) : 'Data N.A.';

    ytdRevenue = (preparedInputDataForTargetAchievement.dataForYTMRevenueChart.achievement[23].value).toFixed(0);
  }

  return {
    momPrecentagetargetAchievement,
    mtdRevenue,
    cumulativePrecentageTargetAchievement,
    ytdRevenue,
  }
}

function prepareDataForKeyContributionAnalysisHorizontalBarChart(array, selectFilterValue, toggleFilterValue, metric) {
  let newObj = [];
  if (selectFilterValue.filterTypeKeyContributionAnalysis === 'Zones') {
    if (toggleFilterValue === 'mtd') {
      newObj.push(array.Regions.mtdCurrentMonth);
    } else {
      newObj.push(array.Regions.ytdCurrentYear);
    }
  } else if (selectFilterValue.filterTypeKeyContributionAnalysis === 'Channel Genre') {
    if (toggleFilterValue === 'mtd') {
      newObj.push(array.Channel_Genre.mtdCurrentMonth);
    } else {
      newObj.push(array.Channel_Genre.ytdCurrentYear);
    }
  } else if (selectFilterValue.filterTypeKeyContributionAnalysis === 'Channels') {
    if (toggleFilterValue === 'mtd') {
      newObj.push(array.Channel_Name.mtdCurrentMonth);
    } else {
      newObj.push(array.Channel_Name.ytdCurrentYear);
    }
  } else if (selectFilterValue.filterTypeKeyContributionAnalysis === 'Advertiser Type') {
    if (toggleFilterValue === 'mtd') {
      newObj.push(array.advertiserType.mtdCurrentMonth);
    } else {
      newObj.push(array.advertiserType.ytdCurrentYear);
    }
  } else if (selectFilterValue.filterTypeKeyContributionAnalysis === 'Campaign Type') {
    if (toggleFilterValue === 'mtd') {
      newObj.push(array.campaignType.mtdCurrentMonth);
    } else {
      newObj.push(array.campaignType.ytdCurrentYear);
    }
  } else if (selectFilterValue.filterTypeKeyContributionAnalysis === 'Inventory Type') {
    if (toggleFilterValue === 'mtd') {
      newObj.push(array.inventoryType.mtdCurrentMonth);
    } else {
      newObj.push(array.inventoryType.ytdCurrentYear);
    }
  } else if (selectFilterValue.filterTypeKeyContributionAnalysis === 'Agency') {
    if (toggleFilterValue === 'mtd') {
      newObj.push(array.agency.mtdCurrentMonth);
    } else {
      newObj.push(array.agency.ytdCurrentYear);
    }
  }

  // console.log('newObj', newObj[0]);

  let selectedFilterType;
  if (selectFilterValue.filterTypeKeyContributionAnalysis === "Zones")
    selectedFilterType = "Regions"
  else if (selectFilterValue.filterTypeKeyContributionAnalysis === "Channel Genre")
    selectedFilterType = "Channel_Genre"
  else if (selectFilterValue.filterTypeKeyContributionAnalysis === "Channels")
    selectedFilterType = "Channel_Name"
  else if (selectFilterValue.filterTypeKeyContributionAnalysis === "Advertiser Type")
    selectedFilterType = "advertiserType"
  else if (selectFilterValue.filterTypeKeyContributionAnalysis === "Campaign Type")
    selectedFilterType = "campaignType"
  else if (selectFilterValue.filterTypeKeyContributionAnalysis === "Inventory Type")
    selectedFilterType = "inventoryType"
  else if (selectFilterValue.filterTypeKeyContributionAnalysis === "Agency")
    selectedFilterType = "agency"


  // console.log('selectedFilterType', selectedFilterType);

  let returnArray;

  if (selectFilterValue.filterTypeKeyContributionAnalysis === "Zones") {
    returnArray = newObj[0].map((element, index) => {
      // console.log('element', element);
      return {
        key: element[selectedFilterType],
        name: element[selectedFilterType],
        value: element[metric]
      }
    });

  } else {
    returnArray = newObj[0].map((element, index) => {
      // console.log('element', element);
      return {
        key: element[selectedFilterType],
        name: element[selectedFilterType],
        value: element[metric]
      }
    });
  }

  console.log('returnArray', returnArray);

  return returnArray;


}

function prepareDataForKeyContributionAnalysisConverginDivergingSB(array, selectFilterValue, toggleFilterValue, metric) {

  // console.log('toggleFilterValue', toggleFilterValue);s

  let data = [];
  let dataForHorizontalBarchartConvergingDivergingSB = [];
  if (selectFilterValue.filterTypeKeyContributionAnalysis === 'Zones') {
    prepareFilterTypeWiseData(data, 'Regions')
  } else if (selectFilterValue.filterTypeKeyContributionAnalysis === 'Channel Genre') {
    prepareFilterTypeWiseData(data, 'Channel_Genre')
  } else if (selectFilterValue.filterTypeKeyContributionAnalysis === 'Channels') {
    prepareFilterTypeWiseData(data, 'Channel_Name')
  } else if (selectFilterValue.filterTypeKeyContributionAnalysis === 'Advertiser Type') {
    prepareFilterTypeWiseData(data, 'advertiserType')
  } else if (selectFilterValue.filterTypeKeyContributionAnalysis === 'Campaign Type') {
    prepareFilterTypeWiseData(data, 'campaignType')
  } else if (selectFilterValue.filterTypeKeyContributionAnalysis === 'Inventory Type') {
    prepareFilterTypeWiseData(data, 'inventoryType')
  } else if (selectFilterValue.filterTypeKeyContributionAnalysis === 'Agency') {
    prepareFilterTypeWiseData(data, 'agency')
  }

  function prepareFilterTypeWiseData(data, filter) {
    if (toggleFilterValue === 'mtd') {
      array[filter].mtdCurrentMonth.forEach((element, index) => {
        let subDataArray = [];
        let prevElement = []
        array[filter].mtdPreviousMonth.forEach(item => {
          if (element[filter] === item[filter]) {
            prevElement = item;
            return
          }
        }
        );
        let valueToBeCompared = prevElement ? (100 - ((prevElement[metric] * 100) / element[metric])).toFixed(0) : 0;
        subDataArray.push({
          speaker: element[filter],
          ruling: "Mostly false",
          proportion: valueToBeCompared < 0 ? parseInt(valueToBeCompared) : -0,
        })
        subDataArray.push({
          speaker: element[filter],
          ruling: "Mostly false",
          proportion: valueToBeCompared >= 0 ? parseInt(valueToBeCompared) : 0,
        })
        data.push(subDataArray);
        dataForHorizontalBarchartConvergingDivergingSB.push({
          key: element[filter],
          name: element[filter],
          value: valueToBeCompared ? valueToBeCompared : 0,
        })
      })
    }

    else {
      // console.log('inside ytd') 
      array[filter].ytdCurrentYear.forEach((element, index) => {
        let subDataArray = [];
        let prevElement = [];

        array[filter].ytdPreviousYear.forEach(item => {
          if (element[filter] === item[filter]) {
            prevElement = item;
            return
          }
        }
        );

        let valueToBeCompared = prevElement ? (100 - ((prevElement[metric] * 100) / element[metric])).toFixed(0) : 0;
        subDataArray.push({
          speaker: element[filter],
          ruling: "Mostly false",
          proportion: valueToBeCompared < 0 ? parseInt(valueToBeCompared) : -0,
        })
        subDataArray.push({
          speaker: element[filter],
          ruling: "Mostly false",
          proportion: valueToBeCompared >= 0 ? parseInt(valueToBeCompared) : 0,
        })
        data.push(subDataArray);
        dataForHorizontalBarchartConvergingDivergingSB.push({
          key: element[filter],
          name: element[filter],
          value: valueToBeCompared ? valueToBeCompared : 0,
        })
      })
    }
  }

  console.log('dataForHorizontalBarchartConvergingDivergingSB', dataForHorizontalBarchartConvergingDivergingSB);

  return dataForHorizontalBarchartConvergingDivergingSB;
}

export default Customer;

export {
  KeyImpactMetricsForExecutiveSummaryCustomer,
  ExecutiveSummaryCustomerTargetsAchievements,
  prepareDateObject,
  prepareInputDataForTargetAchievement,
  KeyContributionAnalysis,
}