let dataForCompetitiveAdShareAnalysis = [
    {
        "ADVERTISER_FINAL": "Reserve Bank of India",
        "PUBLISHER_FINAL": "sonyliv",
        "month_names": "Aug2022"
    }, {
        "ADVERTISER_FINAL": "Reserve Bank of India",
        "PUBLISHER_FINAL": "sonyliv",
        "month_names": "Dec2022"
    }, {
        "ADVERTISER_FINAL": "Reserve Bank of India",
        "PUBLISHER_FINAL": "zee5",
        "month_names": "Feb2023"
    }, {
        "ADVERTISER_FINAL": "Reserve Bank of India",
        "PUBLISHER_FINAL": "disneyhotstar",
        "month_names": "Jul2022"
    }, {
        "ADVERTISER_FINAL": "Reserve Bank of India",
        "PUBLISHER_FINAL": "sonyliv",
        "month_names": "Nov2022"
    }, {
        "ADVERTISER_FINAL": "Reserve Bank of India",
        "PUBLISHER_FINAL": "zee5",
        "month_names": "Oct2022"
    }, {
        "ADVERTISER_FINAL": "Reserve Bank of India",
        "PUBLISHER_FINAL": "sonyliv",
        "month_names": "Oct2022"
    }, {
        "ADVERTISER_FINAL": "Reserve Bank of India",
        "PUBLISHER_FINAL": "sonyliv",
        "month_names": "Sep2022"
    }
]

let dataForAdInventoryFillRateDisplay = {
    "last30Days": [
        {
            "PLATFORM_NAME": "iOS",
            "value": 156518.00735022436
        },
        {
            "PLATFORM_NAME": "Android",
            "value": 50748653.02136694
        },
        {
            "PLATFORM_NAME": "Mobile",
            "value": 647371.4450539327
        },
        {
            "PLATFORM_NAME": "Others",
            "value": 198089.004092143
        },
        {
            "PLATFORM_NAME": "KaiOS",
            "value": null
        },
        {
            "PLATFORM_NAME": "Android_TV",
            "value": null
        },
        {
            "PLATFORM_NAME": "Mweb",
            "value": 4232566.0692378385
        },
        {
            "PLATFORM_NAME": "Web",
            "value": 4590483.16795574
        }
    ],
    "last30To60Days": [
        {
            "PLATFORM_NAME": "iOS",
            "value": null
        },
        {
            "PLATFORM_NAME": "Android",
            "value": 68465067.02016635
        },
        {
            "PLATFORM_NAME": "KaiOS",
            "value": null
        },
        {
            "PLATFORM_NAME": "Mobile",
            "value": 489149.8620839157
        },
        {
            "PLATFORM_NAME": "Others",
            "value": 77560.00194220604
        },
        {
            "PLATFORM_NAME": "Web",
            "value": 6683801.274407394
        },
        {
            "PLATFORM_NAME": "Mweb",
            "value": 1489002.0181256102
        },
        {
            "PLATFORM_NAME": "HTML5",
            "value": null
        },
        {
            "PLATFORM_NAME": "Android_TV",
            "value": null
        }
    ]
}

let dataForAdInventoryFillRateVideo = {
    "last30Days": [
        {
            "PLATFORM_NAME": "iOS",
            "value": 18707083.085400864
        },
        {
            "PLATFORM_NAME": "Android",
            "value": 1381156436.7723572
        },
        {
            "PLATFORM_NAME": "DAI/SSAI",
            "value": 7599278.259257984
        },
        {
            "PLATFORM_NAME": "AOSP",
            "value": 7811986.766820034
        },
        {
            "PLATFORM_NAME": "AFS",
            "value": 12521375.887457198
        },
        {
            "PLATFORM_NAME": "Web",
            "value": 15630970.027676241
        },
        {
            "PLATFORM_NAME": "Mweb",
            "value": 24445975.421071123
        },
        {
            "PLATFORM_NAME": "HTML5",
            "value": 11512665.786801107
        },
        {
            "PLATFORM_NAME": "Apple_TV",
            "value": 47547.60677668732
        },
        {
            "PLATFORM_NAME": "Android_TV",
            "value": 48006203.79365554
        },
        {
            "PLATFORM_NAME": "HTML5_SSAI",
            "value": null
        },
        {
            "PLATFORM_NAME": "Xiaomi_TV",
            "value": null
        },
        {
            "PLATFORM_NAME": "ROKU",
            "value": null
        }
    ],
    "last30To60Days": [
        {
            "PLATFORM_NAME": "iOS",
            "value": 10487310.067948615
        },
        {
            "PLATFORM_NAME": "DAI/SSAI",
            "value": 9293322.644610062
        },
        {
            "PLATFORM_NAME": "AOSP",
            "value": 5823255.668427444
        },
        {
            "PLATFORM_NAME": "Android",
            "value": 715262369.6612531
        },
        {
            "PLATFORM_NAME": "KaiOS",
            "value": null
        },
        {
            "PLATFORM_NAME": "Apple_TV",
            "value": 49482.54907011488
        },
        {
            "PLATFORM_NAME": "HTML5_SSAI",
            "value": null
        },
        {
            "PLATFORM_NAME": "HTML5",
            "value": 10012674.53011496
        },
        {
            "PLATFORM_NAME": "Web",
            "value": 12890246.985171229
        },
        {
            "PLATFORM_NAME": "Mweb",
            "value": 10656148.477441778
        },
        {
            "PLATFORM_NAME": "AFS",
            "value": 9402313.75303006
        },
        {
            "PLATFORM_NAME": "Android_TV",
            "value": 36022416.746499404
        },
        {
            "PLATFORM_NAME": "Xiaomi_TV",
            "value": null
        }
    ]
}

module.exports = {
    dataForCompetitiveAdShareAnalysis,
    dataForAdInventoryFillRateDisplay,
    dataForAdInventoryFillRateVideo,
}














