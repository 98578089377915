import React from "react";

const ProductSubscriptionFunnelMetricsContents = (props) => {
    const {funnelData} = props;
    return (
        <div className="product-subscription-funnel-metrics-contents ">
            <h6>{funnelData[0]}</h6>
            <div className='subscription-funnel-metrics-contents-divs-syn'>
                <div className="pluse-value-product-syn">
                    2% ↑ <br /> From D1
                </div>
                <div className="orange-value-product-syn">
                    2% ↑ <br /> From D7
                </div>
                <div className="minuse-value-product-syn">
                    2% ↓ <br /> From L30
                </div>
            </div>
            <div className="product-subscription-funnel-chart-value-syn">
                <h3>{funnelData[1]}</h3>
                <p>{funnelData[2]}</p>
                <p>{funnelData[3]}</p>
            </div>
        </div>
    )
}

export default ProductSubscriptionFunnelMetricsContents;