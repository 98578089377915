import axios from "axios";

const baseURLExcecutiveSummary = '/api/excecutive-summary'

export default {
    HindiMoviesCW: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/hindi-movies-cw?value=${data}`);
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    HindiGECCW: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/hindi-gec-cw?value=${data}`);
            console.log('HindiGECCW', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    SouthRegionalGECs: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/south-regional-gecs-cw?value=${data}`);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    HSMRegionalGECs: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/hsm-regional-gecs-cw?value=${data}`);
            console.log('HSMRegionalGECs', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    SouthRegionalMovies: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/south-regional-movies-cw?value=${data}`);
            console.log('SouthRegionalMovies', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    Kids: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/kids-cw?value=${data}`);
            console.log('kids-cw', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    Music: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/music-cw?value=${data}`);
            console.log('Music', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    News: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/news-cw?value=${data}`);
            console.log('News', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    Others: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/others-cw?value=${data}`);
            console.log('Others', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    Sport: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/sport-cw?value=${data}`);
            console.log('Sport', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    HSMRegionalMovies: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/hsm-regional-movies-cw?value=${data}`);
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    zeeCW: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/zee-ns-cw?value=${data}`);
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    zeeLW: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/zee-ns-lw?value=${data}`);
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    starCW: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/star-ns-cw?value=${data}`);
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    starLW: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/star-ns-lw?value=${data}`);
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    sunCW: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/sun-ns-cw?value=${data}`);
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    sunLW: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/sun-ns-lw?value=${data}`);
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    viacomCW: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/viacom-ns-cw?value=${data}`);
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    viacomLW: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/viacom-ns-lw?value=${data}`);
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    sonyCW: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/sony-ns-cw?value=${data}`);
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    sonyLW: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/sony-ns-lw?value=${data}`);
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    disneyCW: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/disney-ns-cw?value=${data}`);
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    disneyLW: async function (data) {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + `/disney-ns-lw?value=${data}`);
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    zeeKMCW: async function () {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + '/zee-km-cw');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    zeeKMLW: async function () {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + '/zee-km-lw');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    starKMCW: async function () {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + '/star-km');
            console.log('@@@@@@@@@@@@ KEY-SUN ###########', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    starKMLW: async function () {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + '/star-km-lw');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    sunKMCW: async function () {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + '/sun-km');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    sunKMLW: async function () {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + '/sun-km-lw');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    viacomKMCW: async function () {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + '/viacom-km-cw');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    viacomKMLW: async function () {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + '/viacom-km-lw');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    sonyKMCW: async function () {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + '/sony-km-cw');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    sonyKMLW: async function () {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + '/sony-km-lw');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    genreTrandsDate: async function () {
        try {
            const response = await axios.get(baseURLExcecutiveSummary + '/genre-trends-date');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    

    FilterTP: async function () {
        try {
            const response = await axios.get('/api/excecutive-summary/filter-tp');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }   
    },
    OverallTP: async function () {
        try {
            const response = await axios.get('/api/excecutive-summary/overall-tp');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    ZeeTopPograms: async function () {
        try {
            const response = await axios.get('/api/excecutive-summary/zee-tp');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    channel_genre: async function () {
        try {
            const response = await axios.get('/api/top_programs_and_performance/channel_genre');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    network1: async function () {
        try {
            const response = await axios.get('/api/top_programs_and_performance/network1');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    network2: async function () {
        try {
            const response = await axios.get('/api/top_programs_and_performance/network2');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    network3: async function () {
        try {
            const response = await axios.get('/api/top_programs_and_performance/network3');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    network4: async function () {
        try {
            const response = await axios.get('/api/top_programs_and_performance/network4');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    network5: async function () {
        try {
            const response = await axios.get('/api/top_programs_and_performance/network5');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    network6: async function () {
        try {
            const response = await axios.get('/api/top_programs_and_performance/network6');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    network7: async function () {
        try {
            const response = await axios.get('/api/top_programs_and_performance/network7');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    network8: async function () {
        try {
            const response = await axios.get('/api/top_programs_and_performance/network8');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    metric: async function () {
        try {
            const response = await axios.get('/api/excecutive-summary/metric');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    overallWatchTime: async function () {
        try {
            const response = await axios.get('/api/excecutive-summary/overall-ott');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    originalsWatchTime: async function () {
        try {
            const response = await axios.get('/api/excecutive-summary/originals');
            console.log('gnr', response);
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    moviesWatchTime: async function () {
        try {
            const response = await axios.get('/api/excecutive-summary/movies');
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },


    keyChannelMovementSunTV: async function () {
        try {
            const response = await axios.get('/api/excecutive-summary/sun-km');
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    
    keyChannelMovementStarTV: async function () {
        try {
            const response = await axios.get('/api/excecutive-summary/star-km');
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    keyChannelMovementSonyTV: async function () {
        try {
            const response = await axios.get('/api/excecutive-summary/sony-km');
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    keyChannelMovementViacomTV: async function () {
        try {
            const response = await axios.get('/api/excecutive-summary/viacom-km');
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },
    keyChannelMovementZeeTV: async function () {
        try {
            const response = await axios.get('/api/excecutive-summary/zee-km');
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    getAllGenerData: async function () {
        try {
            const response = await axios.get('/api/executive-summary-product/all-gener');
            return response.data
        } catch (error) {
            console.log(error);
            throw (error)
        }
    },

    getNetworkShareData: async function () {
        try {
            const response = await axios.get('/api/executive-summary-cashing/network-share');
            return response.data
        } catch (error){
            console.log(error);
            throw(error)
        }
    }
}