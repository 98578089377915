import axios from "axios";

export default {

    productData: async function () {
        try {
            const response = await axios.get('/api/executive-summary-product/product-cards');
            console.log('d', response)
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    }
}