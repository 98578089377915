import { useCallback, useEffect, useState } from 'react';
// import { Link } from "react-router-dom"; 


import './ILTTloginPage.css';

const ILTTloginPage = (props) => {
    return (
        
        <div className="login-page-background">

        <video id="background-video"  autoPlay loop muted >
            <source src="/Login-background-video.mp4" type='video/mp4' />
        </video>
        <h1 className='login-main-title'>Data Intelligence Hub</h1>
        <div className="form-div">
                <img src="/Logo.png" className='login-logo'/>
                <p className='login-text'>Extraordinary Together</p>
                <form className='login-page'>
                    <img src='/static/images/logos/login-email.svg' alt='Viewers Logo' className='email-image' />
                    <input type="email" name="name" id="email" placeholder='Email' /> <br />
                    <img src='/static/images/logos/password.svg' alt='Viewers Logo' className='password-image' />
                    <input type="password" name="pass" id="pass" placeholder='Password' /> <br />
                    <a href="/home" className="login">LOGIN</a>
                </form>
        </div>
        
       
        </div>

    )

}

export default ILTTloginPage;