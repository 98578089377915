import React from "react";
import happiness from '../../../../Components/Images/engagement.png'
import ProductQualityExperienceCardAdoption from "./ProductQualityExperienceCardAdoption";
import { useState ,useEffect} from "react";

const Engagement = (props) => {
  const {ProductData}= props 
  const [ WPV, setWpv ] = useState([])
  const [ AppRating, setAppRating ] = useState([])
  const [ NPS, setNPS ] = useState([])

  const LoadFilter = () => {

  const filterwpv = ProductData.filter(item => item.metric_name === ("watchtime per viewer"))
  setWpv(filterwpv)

  const filternps = ProductData.filter(item => item.metric_name === ("User bounce"))
  setNPS(filternps)
  console.log(filternps,"filternps=-=-=-=-=-=--===--=-==-=-=-=-=-=-=-=-=-=-=-=-=-=--=-=-=-=-=-=");

  const filterUserbounce = ProductData.filter(item => item.metric_name === ("app rating"))
  setAppRating(filterUserbounce)
  }

  console.log(WPV,"WPVVVVV*************  ");
  console.log(NPS,"User bounce*************  ");
  console.log(WPV,"APP*************  ");


  useEffect(()=>{
    LoadFilter()
  },[ProductData])

  // if(AppRating.length > 0){
  //   var deff = 'Data N.A.';
  //   if (AppRating[0].l60_metric_value == 0){
  //       deff = (((AppRating[0].l30_metric_value-AppRating[0].l60_metric_value)/AppRating[0].l60_metric_value)*100).toFixed(2)
  //   }
  // }
  var deff;
  if(AppRating.length > 0){
  // console.log(NPS[0].l30_metric_value,NPS[0].l60_metric_value,"--------------------------eng --------------------------");
    if((((AppRating[0].l30_metric_value-AppRating[0].l60_metric_value)/AppRating[0].l60_metric_value)*100).toFixed(2) == Infinity){
      deff = 'Data N.A.'
    }else{
      deff = (((AppRating[0].l30_metric_value-AppRating[0].l60_metric_value)/AppRating[0].l60_metric_value)*100).toFixed(2);
    }
  } 
  return (
    <div>
      <div className="Product_card_executive_summary Engagement_color">
      <div className="Product_card_executive_summary_img"><img src={happiness} /></div>
      <p className="Product_card_executive_summary_card_title">Engagement</p>
      <p className="Product_card_executive_summary_card_title_para">User Behavior</p>
      </div>
      <div className="Product_card_executive_summary_card_margin">
   
      { WPV.length > 0 &&
        <div>
          <ProductQualityExperienceCardAdoption
            diifrence="24.35"
            mydata={["Watch Time/Viewer (Mins)",WPV[0].l30_metric_value, deff, "up"]}
            toggleData={[WPV[1].l30_metric_value, WPV[2].l30_metric_value, WPV[3].l30_metric_value, 0, 0, 0, 0,WPV[4].l30_metric_value]}
            overAllData={AppRating}
            IiconText={"This metric reports the total amount of time users spend watching videos in an app divided by the total number of viewers."}

          />
        </div>
      }

{ NPS.length > 0 &&

        <div>
          <ProductQualityExperienceCardAdoption
            diifrence="24.35"
            mydata={["% Session Exits Without a Video View", `${NPS[0].l30_metric_value}%`, (((NPS[0].l30_metric_value-NPS[0].l60_metric_value)/NPS[0].l60_metric_value)*100).toFixed(2), "up"]}
            toggleData={[NPS[1].l30_metric_value, NPS[2].l30_metric_value, NPS[3].l30_metric_value, 0, 0, 0, 0, NPS[4].l30_metric_value]}
            overAllData={NPS}
            IiconText={"This metric reports the percentage of app sessions where the user exits without watching a video. This metric can help identify user engagement issues."}

          />
        </div>
}
  
      </div>
    </div>
  );
};

export default Engagement;
