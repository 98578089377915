import React from "react";
import { useEffect, useState } from "react";
import "./ProductCardOverAllChart.css";
import OverAllBarChart from "./OverAllBarChart";
import ProductQualityExperienceCardNew from "./ProductQualityExperienceCardNew";
import GoodVideoExperienceAVODSVOD from "./GoodVideoExperienceAVODSVOD";

const ProductCardOverAllChart = (props) => {

  const { goodVideoExperience, AVOD, SVOD } = props
  const [startUpPlay, setstartUpPlay] = useState("test"); //70
  const [ProductPlayData, setAndroidPlay] = useState("test"); //70

  const [startUpVideoFailure, setstartUpVideoFailure] = useState("test"); //2.39
  // const [ProductStartupVideoFailureData, setProductStartupVideoFailureData] = useState("2.39")

  const [video_start_up_time, setvideo_start_up_time] = useState("test"); //2.39
  // const [ProductStartupVideoFailureData, setProductStartupVideoFailureData] = useState("2.39")

  const [startUpExitBefor, setstartUpExitBefor] = useState("test");
  // const [ProductstartUpExitBeforData, setProductstartUpExitBeforData] = useState("2.39")

  const [PlayerVideoFailure, setPlayerVideoFailure] = useState("test"); //1
  // const [ProductPlayerVideoFailureData, setProductPlayerVideoFailureData] = useState("2.39")

  const [RebufferingRatio, setRebufferingRatio] = useState("test"); //0.70

  //platform state
  const [ProductPlay, setProductPlay] = useState(["test"]);
  const [ProductVSFT, setProductVSFT] = useState(["test"]);
  const [ProductEBVS, setProductEBVS] = useState(["test"]);
  const [ProductRR, setProductRR] = useState(["test"]);
  const [ProductVPF, setProductVPF] = useState(["test"]);

  //chart range

  //OVERALL
  const overAllQuick = ["0", "2.79", "5.58", "8.37"];
  const overAllNoError = ["0", "0.97", "1.94", "2.91"];
  const overAllAverageBitrate = ["2.06", "4.72", "5.97", "8.37"];
  const overAllNoBuffering = ["0", "0.56", "1.12", "1.69"];

  //AVOD
  const AVODQuick = ["0", "2.79", "5.58", "8.37"];
  const AVODNoError = ["0", "0.97", "1.94", "2.91"];
  const AVODAverageBitrate = ["2.06", "4.72", "5.97", "8.37"];
  const AVODNoBuffering = ["0", "0.56", "1.12", "1.69"];

  //SVOD
  const SVODQuick = ["0", "2.79", "5.58", "8.37"];
  const SVODNoError = ["0", "0.97", "1.94", "2.91"];
  const SVODAverageBitrate = ["2.06", "4.72", "5.97", "8.37"];
  const SVODNoBuffering = ["0", "0.56", "1.12", "1.69"];


  const chartRangePlay = ["0", "75.58", "80.90", "8.37"];
  const chartRangeVSFT = ["0", "0.77", "1.34", "2.1"];
  const chartRangeVST = ["2.06", "4.72", "5.97", "8.37"];
  const chartRangeCIRR = ["0.23", "0.38", "0.39", "0.72"];
  const chartRangeSPI = ["66.26", "74.68", "76.64", "88.38"];
  const chartRangeRR = ["0.62", "0.98", "1.09", "1.69"];
  const chartRangeEBVS = ["7.3", "16.8", "22.85", "25.61"];
  const chartRangeVPF = ["0.14", "0.49", "0.86", "1.50"];

  //chart color
  const chartcolorPlay = ["#FF647C", "#FFC656", "#00C48C"];
  const chartcolorVSFT = ["#00C48C", "#FFC656", "#FF647C"];
  const chartcolorVST = ["#00C48C", "#FFC656", "#FF647C"];
  const chartcolorCIRR = ["#FF647C", "#FFC656", "#00C48C"];
  const chartcolorSPI = ["#FF647C", "#FFC656", "#00C48C"];
  const chartcolorRR = ["#00C48C", "#FFC656", "#FF647C"];
  const chartcolorEBVS = ["#00C48C", "#FFC656", "#FF647C"];
  const chartcolorVPF = ["#00C48C", "#FFC656", "#FF647C"];

  const LoadPlatformData = async () => {
    //const platforn = await ILTTwentySummaryServices.Platform()
    //console.log(platforn,'data of platform');
    const platforn = [
      {
        android: "0.72540823001365462",
        iOS: "0.902063412325009",
        Others: "0.7043416316277793",
        Web: "0.45340670110978659",
      },
    ];
    const productVSFT = [
      {
        android: "0.019695324999152532",
        iOS: "0.012108655046020444",
        Others: "0.00792935294746997",
        Web: "0.016059460562121933",
      },
    ];
    const productEBVS = [
      {
        android: "0.25472956429348548",
        iOS: "0.0855822826884253",
        Others: "0.28637862239523321",
        Web: "0.53027575709163721",
      },
    ];
    const productRR = [
      {
        android: "0.025006460576721738",
        iOS: "0.0018336460180308153",
        Others: "0.013620597725485982",
        Web: "0.032882298472405209",
      },
    ];
    const productVPF = [
      {
        android: "0.017467763885793561",
        iOS: "0.0065441605040094733",
        Others: "0.0020412502055918054",
        Web: "0.0019868755732664711",
      },
    ];

    setProductPlay(platforn);
    setProductVSFT(productVSFT);
    setProductEBVS(productEBVS);
    setProductRR(productRR);
    setProductVPF(productVPF);
  };

  useEffect(() => {
    // LoadProductData()
    LoadPlatformData();
    // LoadVFT()
  }, [])
  const CloseThePopupWindow = () => {
    //  .Product_card_executive_summary_card_title.Product_card_executive_summary_card_title
  }

  const handleCloseButtonClick = () => {
    props.onClickCloseButton(false)
  }

  console.log('goodVideoExperiencegoodVideoExperiencegoodVideoExperience', goodVideoExperience[0].l30_metric_value)
  return (
    <div className="ProductCardOverAllChart_div">
      <div className="product_good_video_experience_and_close_btn_syn">
        <h5 onClick={CloseThePopupWindow}>% Good Video Experience</h5>
        <svg onClick={handleCloseButtonClick} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#808080" class="bi bi-x-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </div>
      <div className="ProductCardOverAllChart_header">

        <div className="ProductCardOverAllChart_header_title">
          <h5>{goodVideoExperience[0].l30_metric_value + '%'}   </h5>
          <p>of Zee5 viewers had a good experience</p>
        </div>
        <div className="ProductCardOverAllChart_header_barchart">

          <div className="ProductCardOverAllChart_header_barchart_flex">

            <div className="ProductCardOverAllChart_header_barchart_card">
              <div className="ProductCardOverAllChart_header_barchart_background">
                <span className="ProductCardOverAllChart_barchart_card_title">
                  Overall
                </span>
                <OverAllBarChart data={goodVideoExperience[0].l30_metric_value} width={200} height={15} />
                <span className="ProductCardOverAllChart_barchart_card_data">
                  {goodVideoExperience[0].l30_metric_value + '%'}
                </span>
              </div>
            </div>

            <div className="ProductCardOverAllChart_header_barchart_card">
              <div className="ProductCardOverAllChart_header_barchart_background">
                <span className="ProductCardOverAllChart_barchart_card_title">
                  AVOD
                </span>
                <OverAllBarChart data={AVOD[0].l30_metric_value} width={200} height={15} />
                <span className="ProductCardOverAllChart_barchart_card_data">
                  {AVOD[0].l30_metric_value}%
                </span>
              </div>
            </div>

            <div className="ProductCardOverAllChart_header_barchart_card">
              <div className="ProductCardOverAllChart_header_barchart_background">
                <span className="ProductCardOverAllChart_barchart_card_title">
                  SVOD
                </span>
                <OverAllBarChart data={SVOD[0].l30_metric_value} width={200} height={15} />
                <span className="ProductCardOverAllChart_barchart_card_data">
                  {SVOD[0].l30_metric_value}%
                </span>
              </div>
            </div>
          </div>
          <p className="productCardAllChart_session_line_text">They watched an additional xx mins/session (vs. bad sessions) which added xx million minutes of watch time.</p>

        </div>
      </div>

      <div className="ProductCardOverAllChart_chart_section">
        <div className="product-qual-experience-cont-syn">
          <div className="ProductCardOverAllChart_chart_sectionsa">
            <div className="product_start_up_exprience_div">
              <div className="legends-data">
              <h5 className="product-qual-experience-subdiv-h5">
                Overall
              </h5>
              <h5 className="product-qual-experience-subdiv-h5-legends">
              <label className="executive-summ-con-linear-viewer-summ-low-legends"></label>(Zee Lowest - Below Average)
              <label className="executive-summ-con-linear-viewer-summ-avg-legends"></label>(Zee Avg - Ind Median)
              <label className="executive-summ-con-linear-viewer-summ-medium-legends"></label>(Above Ind Median)
              </h5>
              </div>
              <div className="product-qual-experience-subdiv-tile-cont">
                <div className="tglCard">
                  <div className="product-quality-tglcard-child">
                    <ProductQualityExperienceCardNew
                      // isPercentage={false}
                      myData={goodVideoExperience[1].l30_metric_value}
                      PlatformData={ProductPlayData}
                      Range={overAllQuick}
                      diifrence="8.37"
                      Color={chartcolorPlay}
                      mydata={[
                        "Quick loading (sec)",
                        "16 Feb to 16 March",
                        `${(((goodVideoExperience[1].l30_metric_value - goodVideoExperience[1].l60_metric_value) / goodVideoExperience[1].l60_metric_value) * 100).toFixed(2)}`,
                        "up",
                      ]}
                    />
                    <ProductQualityExperienceCardNew
                      isPercentage={true}
                      myData={goodVideoExperience[2].l30_metric_value}
                      PlatformData={ProductVSFT}
                      Range={overAllNoError}
                      diifrence="2.91"
                      Color={chartcolorVSFT}
                      mydata={[
                        "No Errors", "16 Feb to 16 March",
                        `${(((goodVideoExperience[2].l30_metric_value - goodVideoExperience[2].l60_metric_value) / goodVideoExperience[2].l60_metric_value) * 100).toFixed(2)}`,
                        "up"
                      ]}
                    />
                  </div>
                  <div className="product-quality-tglcard-child">
                    <ProductQualityExperienceCardNew
                      isPercentage={false}
                      myData={goodVideoExperience[3].l30_metric_value}
                      PlatformData={ProductVPF}
                      Range={overAllAverageBitrate}
                      Color={chartcolorVST}
                      diifrence="6.31"
                      mydata={[
                        "Average Bitrate (bits/s)",
                        "16 Feb to 16 March",
                        `${(((goodVideoExperience[3].l30_metric_value - goodVideoExperience[3].l60_metric_value) / goodVideoExperience[3].l60_metric_value) * 100).toFixed(2)}`,
                        "down",
                      ]}
                    />
                    <ProductQualityExperienceCardNew
                      isPercentage={true}
                      myData={goodVideoExperience[4].l30_metric_value}
                      PlatformData={ProductVPF}
                      Range={overAllNoBuffering}
                      Color={chartcolorCIRR}
                      diifrence="1.69"
                      mydata={[
                        "No Buffering %",
                        "16 Feb to 16 March",
                        `${(((goodVideoExperience[4].l30_metric_value - goodVideoExperience[4].l60_metric_value) / goodVideoExperience[4].l60_metric_value) * 100).toFixed(2)}`,
                        "down",
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="currently-not-available-at-granularity-syn">Industry benchmarks are currently not available at AVOD/SVOD granularity and will be added post availability of the data.</div>

          <div className="product-qual-experience-subdiv-h5-avod-and-svod-syn">


          </div>

          <div className="product_start_up_exprience_avod_and_svod_div">
            <div className="product-qual-experience-subdiv-h5-svod-parant-div-syn">
              <h5 className="product-qual-experience-subdiv-h5-avodsyn-heading">
                AVOD
              </h5>
              <div className="tglCard-avod-syn">
                <GoodVideoExperienceAVODSVOD
                  myData={AVOD[1].l30_metric_value}
                  PlatformData={ProductVPF}
                  Range={chartRangeSPI}
                  diifrence="22.12"
                  Color={chartcolorSPI}
                  mydata={["Quick Loading", "16 Feb to 16 March",
                    `${(((AVOD[1].l30_metric_value - AVOD[1].l60_metric_value) / AVOD[1].l60_metric_value) * 100).toFixed(2)}`,
                    "up"]}
                />
                <GoodVideoExperienceAVODSVOD
                  myData={AVOD[2].l30_metric_value}
                  Range={chartRangeRR}
                  PlatformData={ProductRR}
                  diifrence="1.07"
                  Color={chartcolorRR}
                  mydata={["No Errors", "16 Feb to 16 March",
                    `${(((AVOD[2].l30_metric_value - AVOD[2].l60_metric_value) / AVOD[2].l60_metric_value) * 100).toFixed(2)}`,
                    "up"]}
                />
                <GoodVideoExperienceAVODSVOD
                isPercentage={true}
                  myData={AVOD[3].l30_metric_value}
                  Range={chartRangeEBVS}
                  PlatformData={ProductEBVS}
                  diifrence="18.31"
                  Color={chartcolorEBVS}
                  mydata={[
                    "Average Bitrate (bits/s)",
                    "16 Feb to 16 March",
                    `${(((AVOD[3].l30_metric_value - AVOD[3].l60_metric_value) / AVOD[3].l60_metric_value) * 100).toFixed(2)}`,
                    "down",
                  ]}
                />
                <GoodVideoExperienceAVODSVOD
                // isPercentage={false}
                  myData={AVOD[4].l30_metric_value}
                  Range={chartRangeVPF}
                  PlatformData={ProductVPF}
                  diifrence="1.36"
                  Color={chartcolorVPF}
                  mydata={["No Buffering %", "16 Feb to 16 March",
                    `${(((AVOD[4].l30_metric_value - AVOD[4].l60_metric_value) / AVOD[4].l60_metric_value) * 100).toFixed(2)}`,

                    "up"]}
                />
              </div>
            </div>
            <div className="product-line"></div>
            <div className="product-qual-experience-subdiv-h5-svod-parant-div-syn">
              <h5 className="product-qual-experience-subdiv-h5-avodsyn-heading">SVOD</h5>
              <div className="tglCard-avod-syn">
                <GoodVideoExperienceAVODSVOD
                  myData={SVOD[1].l30_metric_value}
                  PlatformData={ProductPlayData}
                  Range={chartRangePlay}
                  diifrence="24.35"
                  Color={chartcolorPlay}
                  mydata={[
                    "Quick loading (sec)",
                    "16 Feb to 16 March",
                    `${(((SVOD[1].l30_metric_value - SVOD[1].l60_metric_value) / SVOD[1].l60_metric_value) * 100).toFixed(2)}`,
                    "up",
                  ]}
                />
                <GoodVideoExperienceAVODSVOD
                  myData={SVOD[2].l30_metric_value}
                  PlatformData={ProductVSFT}
                  Range={chartRangeVSFT}
                  diifrence="2.56"
                  Color={chartcolorVSFT}
                  mydata={["No Errors", "16 Feb to 16 March",
                    `${(((SVOD[2].l30_metric_value - SVOD[2].l60_metric_value) / SVOD[2].l60_metric_value) * 100).toFixed(2)}`,
                    "up"]}
                />
                <GoodVideoExperienceAVODSVOD
                isPercentage={true}
                  myData={SVOD[3].l30_metric_value}
                  PlatformData={ProductVPF}
                  Range={chartRangeVST}
                  Color={chartcolorVST}
                  diifrence="6.31"
                  mydata={[
                    "Average Bitrate (bits/s)",
                    "16 Feb to 16 March",
                    `${(((SVOD[3].l30_metric_value - SVOD[3].l60_metric_value) / SVOD[3].l60_metric_value) * 100).toFixed(2)}`,
                    "down",
                  ]}
                />
                <GoodVideoExperienceAVODSVOD
                // isPercentage={false}
                  myData={SVOD[4].l30_metric_value}
                  PlatformData={ProductVPF}
                  Range={chartRangeCIRR}
                  Color={chartcolorCIRR}
                  diifrence="0.49"
                  mydata={[
                    "No Buffering %",
                    "16 Feb to 16 March",
                    `${(((SVOD[4].l30_metric_value - SVOD[4].l60_metric_value) / SVOD[4].l60_metric_value) * 100).toFixed(2)}`,
                    "down",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardOverAllChart;
