
const areaChartWithToolTipsData = [
    {
        date: "1900-01-01T00:00:00.000Z",
        population: 76094000
    },
    {
        date: "1901-01-01T00:00:00.000Z",
        population: 77584000
    },
    {
        date: "1902-01-01T00:00:00.000Z",
        population: 79163000
    },
    {
        date: "1903-01-01T00:00:00.000Z",
        population: 80632000
    },
    {
        date: "1904-01-01T00:00:00.000Z",
        population: 82166000
    },
    {
        date: "1905-01-01T00:00:00.000Z",
        population: 83822000
    },
    {
        date: "1906-01-01T00:00:00.000Z",
        population: 85450000
    },
    {
        date: "1907-01-01T00:00:00.000Z",
        population: 87008000
    },
    {
        date: "1908-01-01T00:00:00.000Z",
        population: 88710000
    },
    {
        date: "1909-01-01T00:00:00.000Z",
        population: 90490000
    },
    {
        date: "1910-01-01T00:00:00.000Z",
        population: 92407000
    },
    {
        date: "1911-01-01T00:00:00.000Z",
        population: 93863000
    },
    {
        date: "1912-01-01T00:00:00.000Z",
        population: 95335000
    },
    {
        date: "1913-01-01T00:00:00.000Z",
        population: 97225000
    },
    {
        date: "1914-01-01T00:00:00.000Z",
        population: 99111000
    },
    {
        date: "1915-01-01T00:00:00.000Z",
        population: 100546000
    },
    {
        date: "1916-01-01T00:00:00.000Z",
        population: 101961000
    },
    {
        date: "1917-01-01T00:00:00.000Z",
        population: 103268000
    },
    {
        date: "1918-01-01T00:00:00.000Z",
        population: 103208000
    },
    {
        date: "1919-01-01T00:00:00.000Z",
        population: 104514000
    },
    {
        date: "1920-01-01T00:00:00.000Z",
        population: 106461000
    },
    {
        date: "1921-01-01T00:00:00.000Z",
        population: 108538000
    },
    {
        date: "1922-01-01T00:00:00.000Z",
        population: 110049000
    },
    {
        date: "1923-01-01T00:00:00.000Z",
        population: 111947000
    },
    {
        date: "1924-01-01T00:00:00.000Z",
        population: 114109000
    },
    {
        date: "1925-01-01T00:00:00.000Z",
        population: 115829000
    },
    {
        date: "1926-01-01T00:00:00.000Z",
        population: 117397000
    },
    {
        date: "1927-01-01T00:00:00.000Z",
        population: 119035000
    },
    {
        date: "1928-01-01T00:00:00.000Z",
        population: 120509000
    },
    {
        date: "1929-01-01T00:00:00.000Z",
        population: 121767000
    },
    {
        date: "1930-01-01T00:00:00.000Z",
        population: 123076741
    },
    {
        date: "1931-01-01T00:00:00.000Z",
        population: 124039648
    },
    {
        date: "1932-01-01T00:00:00.000Z",
        population: 124840471
    },
    {
        date: "1933-01-01T00:00:00.000Z",
        population: 125578763
    },
    {
        date: "1934-01-01T00:00:00.000Z",
        population: 126373773
    },
    {
        date: "1935-01-01T00:00:00.000Z",
        population: 127250232
    },
    {
        date: "1936-01-01T00:00:00.000Z",
        population: 128053180
    },
    {
        date: "1937-01-01T00:00:00.000Z",
        population: 128824829
    },
    {
        date: "1938-01-01T00:00:00.000Z",
        population: 129824939
    },
    {
        date: "1939-01-01T00:00:00.000Z",
        population: 130879718
    },
    {
        date: "1940-01-01T00:00:00.000Z",
        population: 132122446
    },
    {
        date: "1941-01-01T00:00:00.000Z",
        population: 133402471
    },
    {
        date: "1942-01-01T00:00:00.000Z",
        population: 134859553
    },
    {
        date: "1943-01-01T00:00:00.000Z",
        population: 136739353
    },
    {
        date: "1944-01-01T00:00:00.000Z",
        population: 138397345
    },
    {
        date: "1945-01-01T00:00:00.000Z",
        population: 139928165
    },
    {
        date: "1946-01-01T00:00:00.000Z",
        population: 141388566
    },
    {
        date: "1947-01-01T00:00:00.000Z",
        population: 144126071
    },
    {
        date: "1948-01-01T00:00:00.000Z",
        population: 146631302
    },
    {
        date: "1949-01-01T00:00:00.000Z",
        population: 149188130
    },
    {
        date: "1950-01-01T00:00:00.000Z",
        population: 152271417
    },
    {
        date: "1951-01-01T00:00:00.000Z",
        population: 154877889
    },
    {
        date: "1952-01-01T00:00:00.000Z",
        population: 157552740
    },
    {
        date: "1953-01-01T00:00:00.000Z",
        population: 160184192
    },
    {
        date: "1954-01-01T00:00:00.000Z",
        population: 163025854
    },
    {
        date: "1955-01-01T00:00:00.000Z",
        population: 165931202
    },
    {
        date: "1956-01-01T00:00:00.000Z",
        population: 168903031
    },
    {
        date: "1957-01-01T00:00:00.000Z",
        population: 171984130
    },
    {
        date: "1958-01-01T00:00:00.000Z",
        population: 174881904
    },
    {
        date: "1959-01-01T00:00:00.000Z",
        population: 177829628
    },
    {
        date: "1960-01-01T00:00:00.000Z",
        population: 180671158
    },
    {
        date: "1961-01-01T00:00:00.000Z",
        population: 183691481
    },
    {
        date: "1962-01-01T00:00:00.000Z",
        population: 186537737
    },
    {
        date: "1963-01-01T00:00:00.000Z",
        population: 189241798
    },
    {
        date: "1964-01-01T00:00:00.000Z",
        population: 191888791
    },
    {
        date: "1965-01-01T00:00:00.000Z",
        population: 194302963
    },
    {
        date: "1966-01-01T00:00:00.000Z",
        population: 196560338
    },
    {
        date: "1967-01-01T00:00:00.000Z",
        population: 198712056
    },
    {
        date: "1968-01-01T00:00:00.000Z",
        population: 200706052
    },
    {
        date: "1969-01-01T00:00:00.000Z",
        population: 202676946
    },
    {
        date: "1970-01-01T00:00:00.000Z",
        population: 205052174
    },
    {
        date: "1971-01-01T00:00:00.000Z",
        population: 207660677
    },
    {
        date: "1972-01-01T00:00:00.000Z",
        population: 209896021
    },
    {
        date: "1973-01-01T00:00:00.000Z",
        population: 211908788
    },
    {
        date: "1974-01-01T00:00:00.000Z",
        population: 213853928
    },
    {
        date: "1975-01-01T00:00:00.000Z",
        population: 215973199
    },
    {
        date: "1976-01-01T00:00:00.000Z",
        population: 218035164
    },
    {
        date: "1977-01-01T00:00:00.000Z",
        population: 220239425
    },
    {
        date: "1978-01-01T00:00:00.000Z",
        population: 222584545
    },
    {
        date: "1979-01-01T00:00:00.000Z",
        population: 225055487
    },
    {
        date: "1980-01-01T00:00:00.000Z",
        population: 227224681
    },
    {
        date: "1981-01-01T00:00:00.000Z",
        population: 229465714
    },
    {
        date: "1982-01-01T00:00:00.000Z",
        population: 231664458
    },
    {
        date: "1983-01-01T00:00:00.000Z",
        population: 233791994
    },
    {
        date: "1984-01-01T00:00:00.000Z",
        population: 235824902
    },
    {
        date: "1985-01-01T00:00:00.000Z",
        population: 237923795
    },
    {
        date: "1986-01-01T00:00:00.000Z",
        population: 240132887
    },
    {
        date: "1987-01-01T00:00:00.000Z",
        population: 242288918
    },
    {
        date: "1988-01-01T00:00:00.000Z",
        population: 244498982
    },
    {
        date: "1989-01-01T00:00:00.000Z",
        population: 246819230
    },
    {
        date: "1990-01-01T00:00:00.000Z",
        population: 249464396
    },
    {
        date: "1991-01-01T00:00:00.000Z",
        population: 252153092
    },
    {
        date: "1992-01-01T00:00:00.000Z",
        population: 255029699
    },
    {
        date: "1993-01-01T00:00:00.000Z",
        population: 257782608
    },
    {
        date: "1994-01-01T00:00:00.000Z",
        population: 260327021
    },
    {
        date: "1995-01-01T00:00:00.000Z",
        population: 262803276
    },
    {
        date: "1996-01-01T00:00:00.000Z",
        population: 265228572
    },
    {
        date: "1997-01-01T00:00:00.000Z",
        population: 267783607
    },
    {
        date: "1998-01-01T00:00:00.000Z",
        population: 270248003
    },
    {
        date: "1999-01-01T00:00:00.000Z",
        population: 272690813
    }
]

let preParedData;

function prepareData() {
    preParedData = areaChartWithToolTipsData.map(d => {
        return {
            date: new Date(d.date),
            population: d.population
        }
    });
}

prepareData();

export default preParedData;