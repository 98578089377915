import axios from "axios";

// const baseURL = 'http://10.204.32.21/api/executive-summary-customer-linear/';
const baseURL = '/api/executive-summary-customer-linear/';

export default {

    getDataForExecutiveSummaryCustomer: async function () {
        try {
            const response = await axios.get(baseURL);
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getDataForRevenuKPIExectSumCustomerLiner: async function () {
        try {
            const response = await axios.get(baseURL + 'revenue');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getDataForTargetKPIExectSumCustomerLiner: async function () {
        try {
            const response = await axios.get(baseURL + 'target');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getDataForTrendForTargetAchievementKPIExectSumCustomerLiner: async function () {
        try {
            const response = await axios.get(baseURL + 'target-achievement-trend');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getKeyContriAnalysisAdGRPExectSumCustomerLiner: async function () {
        try {
            const response = await axios.get(baseURL + 'key-contribution-analysis-ad-grp');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getKeyContriAnalysisFCTExectSumCustomerLiner: async function () {
        try {
            const response = await axios.get(baseURL + 'key-contribution-analysis-fct');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getKeyContriAnalysisRevenueExectSumCustomerLiner: async function () {
        try {
            const response = await axios.get(baseURL + 'key-contribution-analysis-revenue');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getAdvertiserAndBrandRanking: async function () {
        try {
            const response = await axios.get(baseURL + 'advertiser-and-brand-ranking');
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getCompetetitiveAdShareAnalysis: async function (advertiserName) {
        try {
            const response = await axios.get(baseURL + 'competitive-ad-share-analysis', { params: { clientName: advertiserName } });
            return response.data;
        } catch (error) {
            console.error(error);
            throw (error);
        }
    },


}