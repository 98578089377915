import React from "react";
import { useState,useEffect } from "react";

import happiness from '../../../../Components/Images/happiness.png'
import ProductQualityExperienceCardAdoption from "./ProductQualityExperienceCardAdoption";

const Happiness = (props) => {
  const {ProductData}= props 
  const [ AppRating, setAppRating ] = useState([])
  const [ NPS, setNPS ] = useState([])
  const [ complaints, setComplaints ] = useState([])

  const LoadFilter = () => {

  const filtercomplaints = ProductData.filter(item => item.metric_name === ("complaints"))
  setComplaints(filtercomplaints)

  const filternps = ProductData.filter(item => item.metric_name === ("nps"))
  setNPS(filternps)

  const filterUserbounce = ProductData.filter(item => item.metric_name === "app rating")
  setAppRating(filterUserbounce)
  }


  useEffect(()=>{
    LoadFilter()
  },[ProductData])
  var deff;
  if(AppRating.length > 0){
  console.log((((NPS[0].l30_metric_value-NPS[0].l60_metric_value)/NPS[0].l60_metric_value)*100).toFixed(2),"----------------------------------------------------");
    if((((NPS[0].l30_metric_value-NPS[0].l60_metric_value)/NPS[0].l60_metric_value)*100).toFixed(2) == 0){
      deff = 'Data N.A.'
    }
  } 
  // console.log((((NPS[0].l30_metric_value-NPS[0].l60_metric_value)/NPS[0].l60_metric_value)*100).toFixed(2),"----------------------------------------------------");
 // console.log(overallDataIos,"----------------------------------------------------");
  // console.log(ProductData[0].metric_name,"----------------------------------------------------");

  // if(AppRating.length > 0){
  //   var deff ;
  //   if (NPS[0].l30_metric_value-NPS[0].l60_metric_value)/NPS[0].l60_metric_value == 0){
  //       deff = (((AppRating[0].l30_metric_value-AppRating[0].l60_metric_value)/AppRating[0].l60_metric_value)*100).toFixed(2)
  //   }
  // }

  return (
    <div>
      <div className="Product_card_executive_summary happiness_color">
      <div className="Product_card_executive_summary_img"><img src={happiness} /></div>
      <p className="Product_card_executive_summary_card_title">Happiness</p>
      <p className="Product_card_executive_summary_card_title_para">User Satisfaction</p>
      </div>
      <div>
      {AppRating.length > 0 && <div>



          { AppRating.length > 0 &&
          <div className="Product_card_executive_summary_card_margin">
            <ProductQualityExperienceCardAdoption
              diifrence="24.35"
              mydata={["App Rating", AppRating[0].l30_metric_value, deff, "down"]}
              toggleData={[AppRating[0].l30_metric_value, AppRating[1].l30_metric_value, 0, 0, 0, 0, 0]}
              overAllData={AppRating}
              IiconText={"This metric reflects the overall satisfaction of users with Zee5 App. App ratings are usually given on a scale of 1 to 5 in the app store or the google play store where higher ratings indicate higher satisfaction."}
            />
          </div>
          }

        { NPS.length > 0 &&
          <div>
            <ProductQualityExperienceCardAdoption
              diifrence="24.35"
              mydata={["Net Promoter Score (NPS)", NPS[0].l30_metric_value, (((NPS[0].l30_metric_value-NPS[0].l60_metric_value)/NPS[0].l60_metric_value)*100).toFixed(2), "up"]}
              toggleData={[NPS[0].l30_metric_value, 0, 0, 0, 0, 0, 0]}
              overAllData={NPS}
              IiconText={"NPS is calculated by subtracting the percentage of detractors (customers who are unlikely to recommend the app) from the percentage of promoters (customers who are very likely to recommend the app).  NPS is used to measure customer loyalty and satisfaction. "}
            />
          </div>
          }

        { complaints.length > 0 &&

          <div>
            <ProductQualityExperienceCardAdoption
              diifrence="24.35"
              mydata={["Complaints", complaints[0].l30_metric_value, (((complaints[0].l30_metric_value-complaints[0].l60_metric_value)/complaints[0].l60_metric_value)*100).toFixed(2), "up"]}
              toggleData={[complaints[0].l30_metric_value, 0, 0, 0, 0, 0, 0]}
              overAllData={complaints}
              IiconText={"This metric reports the count of total number of complaints received from users about Zee5 expereince "}
            />
          </div>

          }
        </div>}
      </div>
    </div>
  );
};

export default Happiness;
