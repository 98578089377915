import React from "react";
import GuageChart from "./GuageChart";
import ProductQualityExperienceToggle from "./ProductQualityExperienceToggle";

const ProductQualityExperienceCardNew = (props) => {
  const { mydata, myData, PlatformData, Range, diifrence, Color, isPercentage } = props;

  return (
    <div className="ProductQualityExperienceCardNew_main_div">
      <div className="product-qual-experience-subdiv-tile-left-grp">
        <div className="product-qual-experience-subdiv-tile">
          <div className="product-data">
            <p className="product-qual-experience-subdiv-tile-title">
              {" "}
              <p className="title2">{mydata[0]}</p>
              {isPercentage ? (<p className="product_exp_card_mydata">{myData}%</p>):(<p className="product_exp_card_mydata">{myData}</p>)}

              <div className="product-qual-experience-subdiv-tile-chart-value-valdiv">
                <p className="product-qual-experience-subdiv-tile-chart-value-date">
                Reporting basis Last 30D
                </p>
                <p className="product-qual-experience-subdiv-tile-chart-value-status-neg">
                  {mydata[2] > 0 ? (
                    <p className="up-line">{mydata[2]} ↑ from last 30 Days</p>
                  ) : (
                    <p className="down-line">
                      {mydata[2]} ↓ from last 30 Days{" "}
                    </p>
                  )}
                </p>
              </div>
            </p>
            <div className="product-qual-experience-subdiv-tile-chart-value-rhs">
              {myData !== "test" && (
                <GuageChart
                isPercentage = {isPercentage}
                  Color={Color}
                  diifrence={diifrence}
                  Range={Range}
                  myData={myData}
                />
              )}
            </div>
          </div>
          {/* {PlatformData !== ["test"] && (
            <ProductQualityExperienceToggle PlatformData={PlatformData} />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default ProductQualityExperienceCardNew;
