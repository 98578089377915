import axios from "axios";

// const baseURL = 'http://20.219.91.228:5000/';
const baseURL = 'http://localhost:5000';
// const baseURL = 'http://8081-cs-9a85d4e4-1053-4c89-949a-446585e10b1e.cs-asia-southeast1-bool.cloudshell.dev:5000';

export default {
    getDigitalSummary: async function () {
        try {
            const response = await axios.get('/api/digital');
            console.log('response of axios in digital', response);
            // const response = await fetch('digital');
            return response.data;
        }
        catch (error) {
            console.error(error);
            throw (error);
        }
    },

    getLinearSummary: async function () {
        try {
            const response = await axios.get('linear')
            return response.data;
        }
        catch (error) {
            console.error(error);
            throw (error);
        }
    },

    effectiveAdvertiserSpread: async function () {
        try {
            const response = await axios.get('/api/bigquery');
            return response.data;
        }
        catch (error) {
            console.error(error);
            throw (error);
        }
    },

    rank: async function () {
        try {
            const response = await axios.get('/api/content-rank');
            return response.data;
        }
        catch (error) {
            console.error(error);
            throw (error);
        }
    },

    bubble: async function () {
        try {
            const response = await axios.get('api/ott/bubble');
            return response.data;
        }
        catch (error) {
            console.error(error);
            throw (error);
        }
    }


}