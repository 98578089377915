import { useRef, useLayoutEffect, useState, useEffect } from 'react';

import * as d3 from 'd3';

import wholeDataSet, { dataForNormalHeatmap, channelDataForNetwork } from './data';
import './HeatMap.css';
import { prepareDateObject } from '../../ExecutiveSummaryTab/Customer/Customer';

const HeatMapWithDropDown = (props) => {

    const { metricValue, selectedSelectOptions, isAllDropdownsSelected = true } = props;
    const { slData, dd = true, periodOfStudy, dayOfStudy } = props;
    const [sldata, setsldata] = useState();
    const [sldataDummy, setSldataDummy] = useState([]); 

    useEffect(() => {
        setsldata(slData)
        setSldataDummy(slData) ;
    }, [slData])

    console.log('heat map data passed as props', selectedSelectOptions)

    var tempShowsArray = [];

    useEffect(() => {
        if(dayOfStudy == 'Mon'){
            const Markettype = sldataDummy.filter((element) => element.Day_Name === 'Monday')
            setsldata(Markettype)
        }
        if(dayOfStudy == 'Tues'){
            const Markettype = sldataDummy.filter((element) => element.Day_Name === 'Tuesday')
            setsldata(Markettype)
        }
        if(dayOfStudy == 'Wed'){
            const Markettype = sldataDummy.filter((element) => element.Day_Name === 'Wednesday')
            setsldata(Markettype)
        }
        if(dayOfStudy == 'Fri'){
            const Markettype = sldataDummy.filter((element) => element.Day_Name === 'Friday')
            setsldata(Markettype)
        }
        if(dayOfStudy == 'Sat'){
            const Markettype = sldataDummy.filter((element) => element.Day_Name === 'Saturday')
            setsldata(Markettype)
        }
        if(dayOfStudy == 'Sun'){
            const Markettype = sldataDummy.filter((element) => element.Day_Name === 'Sunday')
            setsldata(Markettype)
        }
        if(dayOfStudy == 'All Days(Mon-Sun)'){
            setsldata(sldataDummy)
        }
        if(dayOfStudy == 'Mon-Fri'){
            const Markettype = sldataDummy.filter((element) => element.Day_Name !== 'Sunday' && element.Day_Name !== 'Saturday')
            setsldata(Markettype)
        }
        if(dayOfStudy == 'Sat-Sun'){
            console.log('dayOfStudy',dayOfStudy);
            console.log('sldataDummy',sldataDummy);
            const Markettype = sldataDummy.filter((element) => element.Day_Name === 'Sunday' || element.Day_Name === 'Saturday' )
            console.log('Markettype',Markettype);
            setsldata(Markettype)
        }
        // , Friday, Monday, Sunday, Wednesday, Saturday, Thursday, Tuesday
    }, [dayOfStudy])

   useEffect(() => {
        const maxItem = Math.max(...sldataDummy.map(item => item.Week));
        
        if (periodOfStudy == 'Previous Week') {
            const maxItemLastweek = maxItem - 7;
            const Markettype = sldataDummy.filter((element) => element.Week <= maxItemLastweek)
            setsldata(Markettype)
        }

        if (periodOfStudy == 'Previous Month') {
            const maxItemLastweeks = maxItem - 31;
            const Markettype = sldataDummy.filter((element) => element.Week <= maxItemLastweeks)
            setsldata(Markettype)
        }

        if (periodOfStudy == 'Previous Quarter') {
            console.log('periodOfStudy', periodOfStudy);
            const maxItemLastQuarter = maxItem - 186;
            const Markettype = sldataDummy.filter((element) => element.Week <= maxItemLastQuarter)
            setsldata(Markettype)
        }

        console.log('************* sldata ***********', sldata);

   }, [periodOfStudy])


    for (let obj in selectedSelectOptions) {
        if (obj !== 'All' & selectedSelectOptions[obj] === true) {
            // settempShowsArray(obj)
            tempShowsArray.push(obj)
        }
    }

    console.log('tempShowsArray prepareDateObject', tempShowsArray);

    let data = [];
    
    if (sldata) {
        console.log('tempShowsArray',tempShowsArray);
        console.log('sldata',sldata);
        sldata.map(element => {

            tempShowsArray.forEach(item => {
                // console.log(element.Channel_Name_Barc);
                if (element.Channel_Name_Barc === item) data.push(element)
            })
        });
    }
    console.log("data after data preparation", data);


    const svgRef = useRef(null);

    useLayoutEffect(() => {
        console.log('8888888888888888888888888888888888888888888',sldata);
        
        let domain = ["00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30",
            "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"];

        let shows = tempShowsArray;

        console.log(sldata);

        const svg = d3
            .select(svgRef.current)
            .attr("viewBox", `0 0 660 ${(12 * shows.length) + 9}`)
            .classed("heat-map-svg", true)
            .append("g")
            .attr("transform", "translate(30,10)");

        svg.selectAll('*').remove();

        let xScale = d3
            .scaleBand()
            .range([0, 600])
            .domain(domain)
            .padding(0.2);

        let xAxis = d3.axisBottom(xScale)
            .tickSize(0)
            .tickValues(["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00",
                "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"]);

        svg.append("g")
            .attr("transform", "translate(20," + -10 + ")")
            .classed('heatmap-x-axis', true)
            .call(xAxis);

        let yScale = d3
            .scaleBand()
            .range([shows.length * 12, 0])
            .domain(shows)
            .padding(0.2);

        let yAxis = d3.axisLeft(yScale)
            .tickSize(50)
            .tickValues(shows);

        svg.append("g")
            .call(yAxis)
            .attr("transform", "translate(20," + 0 + ")")
            .classed("y-axis-tick", true);

        svg.select(".y-axis-tick")
            .selectAll("line")
            .attr("stroke", "#E9EFFF")
            .attr("stroke-width", "10")

        svg.select(".y-axis-tick")
            .selectAll("text")
            .attr("x", -2)
            .attr('font-size', '0.3rem')

        let heatMapBoxColors = d3
            .scaleLinear()
            .range(["#deccff", "#4F277C"])
            .domain([0.001, 0.1]);
        // console.log('min:::', d3.min(sldata, d => d.watchTime),'max:::' ,d3.max(sldata, d => d.watchTime))

        let tooltip = d3.select('.heat-map-container')
            .append("div")
            .style("opacity", 0)
            .attr("class", "tooltip");

        let mouseover = function (event) {
            tooltip.style("opacity", 1)
        }

        let mousemove = function (event) {
            tooltip
                .html(metricValue === 'GRP' ? ("<p>Chanel: " + event.target.__data__.Channel_Name_Barc + "</p><p> Metric: " + event.target.__data__.watchTime + "</p><p>Time: " + event.target.__data__.Slot_Start_Time.value.slice(11, 16) + "</p>") : ("<p>Chanel: " + event.target.__data__.Channel_Name_Barc + "</p><p> Metric: " + event.target.__data__.TSV + "</p><p>Time: " + event.target.__data__.Slot_Start_Time.value.slice(11, 16) + "</p>"))
                .style("left", event.pageX + -83 + "px")
                .style("top", event.pageY + -238 + "px");
        }

        let mouseleave = function (event) {
            tooltip.style("opacity", 0)
        }
        console.log('************DATA', data)

        svg
            .selectAll()
            .data(data)
            .enter()
            .append("rect")
            .attr("transform", "translate(20," + 0 + ")")
            .attr("x", d => xScale(d.Slot_Start_Time.value.slice(11, 16)))
            .attr("y", d => yScale(d.Channel_Name_Barc))
            .attr("width", xScale.bandwidth())
            .attr("height", yScale.bandwidth())
            .style("fill", d => metricValue === 'GRP' ? (heatMapBoxColors(d.watchTime)) : (heatMapBoxColors(d.TSV)))
            .on("mouseover", mouseover)
            .on("mousemove", mousemove)
            .on("mouseleave", mouseleave);

        svg.selectAll('.ticked')
            .select('text')
        // .style('font-size','8px !important')

    }, [isAllDropdownsSelected, data, selectedSelectOptions, sldata]);

    return (
        <div className='heat-map-with-dropdown-container heat-map-container'>
            <svg ref={svgRef}></svg>
        </div>
    )
}

export {
    HeatMapWithDropDown
}